import { createContext, PropsWithChildren, useEffect, useState } from 'react';

export type FloatingAlertTypesAllowed = 'success' | 'error' | 'warning' | undefined;

export interface FloatingAlertProviderProps {
	show: boolean;
	type: FloatingAlertTypesAllowed;
	message: string;
}

export interface FloatingAlertProviderBase extends FloatingAlertProviderProps {
	handleCloseAlert: () => void;
	handleShowAlert: ({ type, message }: Omit<FloatingAlertProviderProps, 'show'>) => void;
}

export const defaultFloatingAlertValues: FloatingAlertProviderBase = {
	type: undefined,
	message: '',
	show: false,
	handleCloseAlert: () => ({}),
	handleShowAlert: () => ({}),
};

export const FloatingAlertContext = createContext<FloatingAlertProviderBase>(
	defaultFloatingAlertValues,
);

export const FloatingAlertProvider = ({
	children,
}: PropsWithChildren<FloatingAlertProviderBase>) => {
	const [state, setState] = useState(defaultFloatingAlertValues);

	useEffect(() => {
		if (state.show) {
			setTimeout(() => {
				setState({ ...state, show: false });
			}, 5000);
		}
	}, [state.show]);

	const handleCloseAlert = () => {
		setState({ ...state, show: false });
	};

	const handleShowAlert = ({ type, message }: Omit<FloatingAlertProviderProps, 'show'>) => {
		setState({ ...state, show: true, type, message });
	};

	return (
		<FloatingAlertContext.Provider value={{ ...state, handleCloseAlert, handleShowAlert }}>
			{children}
		</FloatingAlertContext.Provider>
	);
};
