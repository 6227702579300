interface IProps {
	active?: boolean;
}

export const AnalyticsIcon = ({ active = true }: IProps) => (
	<svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="84" height="84" rx="11.5" fill={active ? '#262255' : '#B7B7B7'} />
		<path
			d="M7.85876 53.0102V46.5679C7.85876 43.6182 10.25 41.227 13.1996 41.227V41.227C16.1493 41.227 18.5405 43.6182 18.5405 46.5679V57.4275C18.5405 61.8458 22.1222 65.4275 26.5405 65.4275H27.1085C31.5268 65.4275 35.1085 61.8458 35.1085 57.4275V42.3883C35.1085 38.3173 38.4087 35.0171 42.4797 35.0171V35.0171C46.5507 35.0171 49.851 38.3173 49.851 42.3883V48.6909C49.851 53.1092 53.4327 56.691 57.851 56.691H58.4999C62.9182 56.691 66.4999 53.1092 66.4999 48.691V28.06C66.4999 25.0422 68.9463 22.5957 71.9642 22.5957V22.5957C74.9821 22.5957 77.4285 25.0422 77.4285 28.06V41.227"
			stroke="#FCFCFC"
			strokeWidth="6"
		/>
	</svg>
);
