import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { ETPayButton } from '../../components/button';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { ProcessType } from '../../models/sockets';
import { User } from '../../models/users';
import { WebSockets } from '../../services/sockets';
import { UsersServices } from '../../services/users';
import { validatePermission } from '../../utils/common';
import { defaultPagination } from '../../utils/defaults';
import { NewUserModal } from './components/modals/new-user';
import { UsersTable } from './components/table';
import { HeadContainer, TitlePage } from './styles';

interface ModalProps {
	isOpen: boolean;
}

const defaultModal: ModalProps = {
	isOpen: false,
};

const Users = () => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const [pagination, setPagination] = useState(defaultPagination);
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const [users, setUsers] = useState<User[]>([]);
	const [allowAddNewUser, setAllowAddNewUser] = useState<boolean>(true);
    const [forceRefresh, setForceRefresh] = useState(false);

	const { data, isFetching, isFetched, isLoading, refetch } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.config.users.getUserList, merchantSelected?.code, pagination],
		queryFn: () =>
			UsersServices.getUsers({
				merchantCode: merchantSelected?.code as string,
				pagination,
			}),
	});

	useEffect(() => {
		if (data) {
			setUsers(data.users.data as User[]);
			setAllowAddNewUser(!data.can_add);
		}
	}, [data]);

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleOpenModal = () => {
		setModal({ isOpen: true });
	};

	WebSockets.subscribe(({ action, data }) => {
		if (action === ProcessType.USERS) {
			setUsers(data.users as User[]);
			setAllowAddNewUser(!data.can_add);
		}
	});

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.users.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	const handleClickRefresh = () => {
        setForceRefresh(!forceRefresh);
    };

    useEffect(() => {
        if (forceRefresh) {
            refetch();
			setForceRefresh(!forceRefresh);
        }
    }, [forceRefresh, refetch]);

	return (
		<ViewWrapper title="Usuarios" isLoading={isLoading || isFetching}>
			{modal.isOpen && <NewUserModal show={modal.isOpen} handleCloseModal={handleCloseModal} refetch={handleClickRefresh}/>}
			<HeadContainer>
				<TitlePage>Usuarios</TitlePage>
				{validatePermission('SETTINGS_USERS_INVITE') && width > 576 && (
					<ETPayButton
						title="Invitar usuario"
						color="secondary"
						onClick={handleOpenModal}
						disabled={allowAddNewUser}
					/>
				)}
			</HeadContainer>

			<UsersTable
				data={users}
				isLoading={isFetching && !isFetched}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={data?.users?.total ? +data?.users?.total : 0}
				refetch={handleClickRefresh}
			/>
		</ViewWrapper>
	);
};

export { Users };
