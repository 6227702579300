import { ResponseExport, ResponseTable } from '../models/common-payments';
import {
	ExportDataParams,
	GenerateRefundParams,
	GetAllConciliations,
	ResponseGenerateRefund,
} from '../models/conciliation';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getTableData = async ({ ...rest }: GetAllConciliations) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTable>('/v1/conciliation/table', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/conciliation/export', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const generateRefund = async ({
	merchantCode,
	clabe,
	concept,
	transferId,
}: GenerateRefundParams) => {
	try {
		const params = { merchant_code: merchantCode };

		const refund_transfers = [
			{
				clabe,
				concept,
				transferId,
			},
		];

		const body = {
			refund_transfers,
		};

		const { data } = await client.post<ResponseGenerateRefund>(
			'/v1/conciliation/generate/refund',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const ConciliationServices = {
	getTableData,
	getExportData,
	generateRefund,
};
