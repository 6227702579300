export const DetailsArrowIcon = () => (
	<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m9 18 6-6-6-6"
			stroke="#B7B7B7"
			strokeWidth={1.5}
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
