import { TimesIcon } from '../../assets/svg';
import { useFloatingAlert } from '../../hooks/useFloatingAlert';
import { AlertContainer, AlertMessage, AlertWrapper, CloseButton } from './styles';

const FloatingAlert = () => {
	const { show, type, message, handleCloseAlert } = useFloatingAlert();

	return (
		<AlertContainer show={show}>
			<AlertWrapper type={type}>
				<AlertMessage>{message}</AlertMessage>
				<CloseButton onClick={handleCloseAlert}>
					<TimesIcon />
				</CloseButton>
			</AlertWrapper>
		</AlertContainer>
	);
};

export { FloatingAlert };
