import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background-color: #C5CED2
  }

  100% {
     background-color:  #F0F2F3
  }
`;

export const FunnelGraphContainer = styled.div`
	width: 100%;
`;

export const FunnelGraphDraw = styled.div`
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.sirius};
	display: flex;
	height: 170px;
	margin-bottom: 16px;
	overflow: hidden;
`;

export const FunnelGraphDrawLoading = styled.div`
	animation: ${loading} 2s linear infinite alternate;
	background-color: #eee;
	border-radius: 8px;
	display: flex;
	height: 170px;
	margin-bottom: 16px;
	overflow: hidden;
`;

export const ValueText = styled.span`
	font-size: 14px;
	margin-bottom: 5px;
	margin-left: 8px;
	overflow: hidden;
`;

export const Draw = styled.div`
	align-items: flex-end;
	display: flex;
	height: 100%;
	justify-content: flex-start;
`;

export const SuccessDraw = styled(Draw)`
	background-color: ${({ theme }) => theme.colors.principal.orion};
`;

export const PendingDraw = styled(Draw)`
	background-color: #433f5c;
`;

export const ExpiredDraw = styled(Draw)`
	background-color: #898798;
`;

export const ErrorDraw = styled(Draw)`
	background-color: #d0cfd6;
`;

export const FunnelGraphDescription = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: flex-start;
`;

export const DescriptionSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;

const DescriptionItem = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-bottom: 10px;
`;

export const PercentageDescription = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	margin-left: 4px;
`;

export const IconDescription = styled.i`
	border-radius: 8px;
	display: block;
	height: 8px;
	margin-right: 4px;
	width: 8px;
`;

export const SuccessDescriptionItem = styled(DescriptionItem)`
	${IconDescription} {
		background-color: ${({ theme }) => theme.colors.principal.orion};
	}
`;

export const PendingDescriptionItem = styled(DescriptionItem)`
	${IconDescription} {
		background-color: #433f5c;
	}
`;

export const ExpiredDescriptionItem = styled(DescriptionItem)`
	${IconDescription} {
		background-color: #898798;
	}
`;

export const ErrorDescriptionItem = styled(DescriptionItem)`
	${IconDescription} {
		background-color: #d0cfd6;
	}
`;

export const EmptyMessage = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;
