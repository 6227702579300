export const RefreshIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
		<path
			d="M13.4167 11.375C13.4167 14.6657 10.749 17.3333 7.45833 17.3333C4.16764 17.3333 1.5 14.6657 1.5 11.375C1.5 8.08429 4.16764 5.41666 7.45833 5.41666H9.53472"
			stroke="#4C6CD1"
			strokeWidth="1.5"
			strokeLinecap="square"
		/>
		<path
			d="M6.08366 0.833328L10.667 5.41666L6.08366 10"
			stroke="#4C6CD1"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
);
