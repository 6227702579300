import { SVGProps } from 'react';

export const BancoHsbcIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="42"
		height="42"
		viewBox="0 0 42 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect x="0.25" y="0.25" width="41.5" height="41.5" rx="5.75" fill="white" />
		<rect
			x="0.25"
			y="0.25"
			width="41.5"
			height="41.5"
			rx="5.75"
			stroke="#F2F2F2"
			strokeWidth="0.5"
		/>
		<g clipPath="url(#clip0_616_16138)">
			<path d="M29.013 12.6318H12.9462V28.6986H29.013V12.6318Z" fill="white" />
			<path d="M37.0463 20.6841L29.0129 12.6318V28.7175L37.0463 20.6841Z" fill="#DB0011" />
			<path d="M20.9796 20.6841L29.013 12.6318H12.9462L20.9796 20.6841Z" fill="#DB0011" />
			<path d="M4.89392 20.6841L12.9462 28.7175V12.6318L4.89392 20.6841Z" fill="#DB0011" />
			<path d="M20.9796 20.6842L12.9462 28.7176H29.013L20.9796 20.6842Z" fill="#DB0011" />
		</g>
		<defs>
			<clipPath id="clip0_616_16138">
				<rect width="32.803" height="16.7362" fill="white" transform="translate(4.59863 12.6318)" />
			</clipPath>
		</defs>
	</svg>
);
