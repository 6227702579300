import { useEffect, useState } from 'react';

import { DownloadIcon } from '../../../../assets/svg';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { DirectDebitMxServices } from '../../../../services/direct-debit-mx';
import { errorFormatter } from '../../../../utils/errors';
import RefundResume from '../refund-resume';
import {
	Button,
	ModalContainer,
	ModalFooter,
	ModalSubtitle,
	ModalTitle,
	TextButton,
} from './styles';

interface ModalMultiuse {
	setShowFinalizedModal: () => void;
	show: boolean;
	file?: FileList | FileList[];
}
interface Subscription {
	stepFlow: boolean;
	chargesEnvoys: number;
	chargesError: number;
	date: Date;
	dateFormat: string;
	file?: FileList | FileList[];
	fileError: string;
}

const ModalDomiciliation = ({ setShowFinalizedModal, show, file }: ModalMultiuse) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [stateModal, setStateModal] = useState<Subscription>({
		stepFlow: true,
		chargesEnvoys: 0,
		chargesError: 0,
		date: new Date(),
		file,
		dateFormat: '',
		fileError: '',
	});

	useEffect(() => {
		if (file && file != undefined) {
			postRequest();
		}
	}, [show]);

	const [loader, setLoader] = useState(true);

	const postRequest = async () => {
		try {
			const response = await DirectDebitMxServices.postSubscriptionCharge({
				merchantCode: merchantSelected?.code as string,
				file: file?.[0],
			});

			if (response.code === 'E_PL_INVFORM') {
				const newBlob = new Blob([response.csv]);
				const url = window.URL.createObjectURL(newBlob);

				await setStateModal({
					...stateModal,
					chargesError: response.resume.total,
					stepFlow: false,
					fileError: url,
				});
				return;
			}
			if (response.code === 'S_OK') {
				setStateModal({
					...stateModal,
					stepFlow: true,
					chargesEnvoys: response.resume.total,
					dateFormat: response.resume.date,
				});
			}
		} catch (e: any) {
			await setStateModal({
				...stateModal,
				stepFlow: false,
			});
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setShowFinalizedModal();
		} finally {
			await setLoader(false);
		}
	};

	const getCurrentDateFormatted = (): string => {
		const date = new Date();
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}${month}${year}`;
	};

	const downloadFile = () => {
		const link = document.createElement('a');

		link.href = stateModal.fileError;
		link.setAttribute('download', `errores_domiciliaciones_${getCurrentDateFormatted()}.csv`);

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
	};

	const processData = [
		{
			item: 'Cargos en revisión',
			data: stateModal.chargesEnvoys,
		},
		{
			item: 'Fecha de envío',
			data: stateModal.dateFormat,
		},
	];

	const processDataError = [
		{
			item: 'Cargos con error',
			data: stateModal.chargesError,
		},
	];

	return (
		<>
			<Modal title="Programar cargos" show={show} onClose={setShowFinalizedModal} type="medium">
				{loader ? (
					<Spinner message="Revisando el archivo" />
				) : (
					<ModalContainer>
						<ModalTitle>
							{stateModal.stepFlow
								? 'Recibimos tu solicitud'
								: 'Verifica la información de los cargos'}
						</ModalTitle>
						<ModalSubtitle align="center">
							{stateModal.stepFlow ? (
								<>
									En unos minutos, podrás ver reflejados los cargos en la sección “Suscripciones” y
									recibirás un correo confirmando el estatus de programación de los cargos
								</>
							) : (
								<>
									Encontramos cargos con errores.
									<br /> Descarga el archivo con los detalles de los errores, corrígelos y vuelve a
									cargar el archivo para completar el proceso.
								</>
							)}
						</ModalSubtitle>
						<RefundResume transferResume={stateModal.stepFlow ? processData : processDataError} />
						{!stateModal.stepFlow && (
							<ModalFooter>
								<Button color={'primary'} onClick={downloadFile}>
									<TextButton>Descargar .csv</TextButton>
									<DownloadIcon />
								</Button>
							</ModalFooter>
						)}
					</ModalContainer>
				)}
			</Modal>
		</>
	);
};

export { ModalDomiciliation };
