import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../hooks/useAuth';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { WebSockets } from '../../../../services/sockets';
import { ArrowIcon } from '../MerchantList/styles';
import { Avatar, AvatarContainer, MenuContainer, MenuContent, MenuItem } from './styles';

const Menu = () => {
	const { user, handleLogout } = useAuth();
	const [showMenu, setShowMenu] = useState(false);
	const elementRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const location = useLocation();

	useClickOutside({ elementRef, callback: () => setShowMenu(false) });

	const handleShowMenu = () => {
		setShowMenu(!showMenu);
	};

	const handleLogoutAction = () => {
		WebSockets.disconnect();
		handleLogout();
	};

	return (
		<MenuContainer ref={elementRef} onClick={handleShowMenu}>
			<AvatarContainer isActive={showMenu}>
				<Avatar letter={user?.names.charAt(0) as string} /> <ArrowIcon />
			</AvatarContainer>

			{showMenu && (
				<MenuContent>
					<MenuItem
						onClick={() => navigate('/my-profile')}
						isActive={location.pathname === '/my-profile'}
					>
						Mi Perfil
					</MenuItem>
					<MenuItem onClick={handleLogoutAction} isActive={false}>
						Cerrar sesión
					</MenuItem>
				</MenuContent>
			)}
		</MenuContainer>
	);
};

export { Menu };
