import { Dispatch, SetStateAction, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { EyeIcon } from '../../../../assets/svg';
import { MultiPayIcon } from '../../../../assets/svg/payment-links-types/MultiPayIcon';
import { OnePayIcon } from '../../../../assets/svg/payment-links-types/OnePay';
import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Select } from '../../../../components/form/select';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { Tooltip } from '../../../../components/tooltip';
import { defaultTheme } from '../../../../config/theme';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PaymentStatus, Status as StatusType } from '../../../../models/common-payments';
import { PaymentMultiuse } from '../../../../models/payment-links-multiuse';
import { ColumnInterface, Filters, Pagination, RowInterface } from '../../../../models/table';
import { PaymentLinksMultiuseServices } from '../../../../services/payment-links-multiuse';
import { getRoleLabelById, validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import { Dropdown } from '../dropdown';
import { ModalMultiuse } from '../modal';
import { NewSinglePayment, ViewsAllowed } from '../new-single-payment';
import { PaymentsMultiuse, TableDetail } from '../table-detail';
import {
	AmountContainer,
	Button,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	ProductFilterLabel,
	SelectContainer,
	StatusContainer,
	Text,
	TextContainer,
	TextPayment,
	TextWithToken,
	VoucherButton,
	VoucherContainer,
} from './styles';

interface PaymentLinkTableProps {
	data: PaymentMultiuse[];
	isLoading?: boolean;
	stateList: PaymentStatus[];
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
}

export interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

interface ShareModalState {
	show: boolean;
	selected: string;
}

interface Status {
	status: string;
	message: string;
}

interface GetStatusMessage {
	status: string;
	numpayments: number;
	endexpiredatdate: string;
	endexpiredattime: string;
	initexpiredatdate: string;
	initexpiredattime: string;
}

const PaymentLinkTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	refetch,
}: PaymentLinkTableProps) => {
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();
	const [showEditModal, setShowEditModal] = useState(false);
	const [copyTextByRow, setCopyTextByRow] = useState<{ [key: string]: string }>({});
	const [selectedRowData, setSelectedRowData] = useState<PaymentMultiuse | undefined>(undefined);
	const [showShareModal, setShowShareModal] = useState<ShareModalState>({
		show: false,
		selected: '',
	});
	const [viewType, setViewType] = useState<ViewsAllowed>('create-payment');
	const [showFinalizedModal, setShowFinalizedModal] = useState(false);
	const [commentValue, setCommentValue] = useState('');

	const paymentLinkMultiuseStates = ['actived', 'pending', 'expired', 'finalized'];

	const dropdownOptions = [
		{
			label: 'Editar',
			onClick: (rowData: PaymentMultiuse) => {
				setSelectedRowData(rowData);
				setShowEditModal(true);
				setViewType('edit-payment-link');
			},
		},
		{
			label: 'Mostrar QR',
			onClick: (rowData: PaymentMultiuse) => {
				setSelectedRowData(rowData);
				setShowShareModal({ show: true, selected: 'qr' });
				setViewType('share-payment-link');
			},
		},
		{
			label: 'Compartir enlace',
			onClick: (rowData: PaymentMultiuse) => {
				setSelectedRowData(rowData);
				setShowShareModal({ show: true, selected: 'send-link' });
				setViewType('share-payment-link');
			},
		},
		{
			label: 'Finalizar',
			onClick: (rowData: PaymentMultiuse) => {
				setSelectedRowData(rowData);
				setShowFinalizedModal(true);
			},
			colorCustom: defaultTheme.colors.extra.sword,
		},
	];

	const columns: ColumnInterface<PaymentMultiuse>[] = [
		{
			sortField: true,
			title: 'Referencia',
			propertyName: 'reference',
			cellRender: ({ merchantorderid, status }) => (
				<>
					<Text disabled={status !== 'actived' && status !== 'pending'}>{merchantorderid}</Text>
				</>
			),
		},
		{
			title: 'Tipo',
			propertyName: 'paymenttype',
			cellRender: ({ paymenttype, status }) => (
				<>
					{paymenttype.toLowerCase() === 'unique' ? (
						<OnePayIcon disabled={status !== 'actived' && status !== 'pending'} />
					) : (
						<MultiPayIcon disabled={status !== 'actived' && status !== 'pending'} />
					)}
				</>
			),
		},
		{
			title: 'Enlace de cobro',
			propertyName: 'token',
			cellRender: (payment: PaymentMultiuse) => {
				const tokenSlice = payment.token.slice(8, 41);
				const handleClick = (payment: PaymentMultiuse) => {
					setCopyTextByRow((prevCopyTextByRow) => ({
						...prevCopyTextByRow,
						[payment.token]: 'Copiado',
					}));

					setTimeout(() => {
						setCopyTextByRow((prevCopyTextByRow) => ({
							...prevCopyTextByRow,
							[payment.token]: 'Copiar',
						}));
					}, 2500);

					if (payment.status === 'actived' || payment.status === 'pending') {
						navigator.clipboard.writeText(payment.token);
					}
				};

				return (
					<TextContainer>
						<TextWithToken disabled={payment.status !== 'actived' && payment.status !== 'pending'}>
							{tokenSlice}
						</TextWithToken>
						{payment.status === 'actived' && (
							<Button onClick={() => handleClick(payment)}>
								{copyTextByRow[payment.token] || 'Copiar'}
							</Button>
						)}
					</TextContainer>
				);
			},
		},
		{
			sortField: true,
			title: 'Monto',
			align: 'right',
			propertyName: 'amount',
			cellRender: ({ amount, status }) => (
				<AmountContainer>
					<Text disabled={status !== 'actived' && status !== 'pending'}>{amount}</Text>
				</AmountContainer>
			),
		},
		{
			sortField: true,
			title: 'Cobros exitosos',
			propertyName: 'payments',
			cellRender: ({ numsuccesspayments, status }) => (
				<>
					<Text disabled={status !== 'actived' && status !== 'pending'}>
						{numsuccesspayments || 0}
					</Text>
				</>
			),
		},
		{
			title: 'Estatus',
			propertyName: 'status',
			align: 'center',
			cellRender: ({
				status,
				numpayments,
				endexpiredatdate,
				endexpiredattime,
				initexpiredatdate,
				initexpiredattime,
			}) => {
				return (
					<>
						<PaymentStatusComponent
							status={status}
							numpayments={numpayments}
							endexpiredatdate={endexpiredatdate}
							endexpiredattime={endexpiredattime}
							initexpiredatdate={initexpiredatdate}
							initexpiredattime={initexpiredattime}
						/>
					</>
				);
			},
		},
		{
			sortField: true,
			title: 'Fecha de creación',
			propertyName: 'createdatDate',
			cellRender: ({ createdatdate, createdattime, status }) => (
				<Text disabled={status !== 'actived' && status !== 'pending'}>
					<DateAndTime date={createdatdate} time={createdattime} />
				</Text>
			),
		},
		{
			title: '',
			propertyName: '',
			cellRender: (rowData: PaymentMultiuse) => (
				<Dropdown
					disabled={rowData.status === 'actived' || rowData.status === 'pending'}
					options={dropdownOptions}
					rowData={rowData}
				/>
			),
		},
	];

	const getStatusMessage = ({
		status,
		numpayments,
		endexpiredatdate,
		endexpiredattime,
		initexpiredatdate,
		initexpiredattime,
	}: GetStatusMessage) => {
		const statusMessages: Status[] = [
			{
				status: 'finalized',
				message: 'Este enlace está inactivo y no permite recibir más pagos',
			},
			{
				status: 'actived',
				message: `Este enlace podrá recibir hasta ${numpayments || 1} pagos ${
					endexpiredatdate ? `hasta el ${endexpiredatdate} ${endexpiredattime}` : ''
				}`,
			},
			{
				status: 'pending',
				message: `Este enlace podrá recibir pagos a partir del ${initexpiredatdate} ${initexpiredattime}`,
			},
			{
				status: 'expired',
				message: 'Este enlace ha finalizado sin recibir pagos exitosos',
			},
		];

		const selectedStatus = statusMessages.find((state) => state.status === status);
		return selectedStatus ? selectedStatus.message : '';
	};

	const PaymentStatusComponent = ({
		status,
		numpayments,
		endexpiredatdate,
		endexpiredattime,
		initexpiredatdate,
		initexpiredattime,
	}: {
		status: StatusType;
		numpayments: number;
		endexpiredatdate: string;
		endexpiredattime: string;
		initexpiredatdate: string;
		initexpiredattime: string;
	}) => {
		const message = getStatusMessage({
			status,
			numpayments,
			endexpiredatdate,
			endexpiredattime,
			initexpiredatdate,
			initexpiredattime,
		});

		return (
			<StatusContainer>
				<Tooltip text={message}>
					<Status status={status} />
				</Tooltip>
			</StatusContainer>
		);
	};

	const createColumnsTableDetail = (): ColumnInterface<PaymentsMultiuse>[] => {
		const commonColumns: ColumnInterface<PaymentsMultiuse>[] = [
			{
				title: 'Identificador del pago',
				propertyName: 'paymentId',
				cellRender: ({ paymentId }) => <TextPayment>{paymentId.slice(0, -54)}</TextPayment>,
			},
			{
				sortField: true,
				title: 'CLABE',
				propertyName: 'clabe',
				cellRender: ({ userClabe }) => <>{userClabe}</>,
			},
			{
				sortField: true,
				title: 'Banco',
				propertyName: 'bankName',
				cellRender: ({ bankName }) => (
					<>{bankName === 'DESCONOCIDO' && merchantSelected?.country === 'MX' ? null : bankName}</>
				),
			},
			{
				title: 'Medio',
				propertyName: 'paymentMethod',
				cellRender: ({ paymentMethod }) => <>{paymentMethod}</>,
			},
			{
				title: 'Dato de contacto',
				propertyName: 'contactInfo',
				cellRender: ({ contactInfo }) => <>{contactInfo}</>,
			},
			{
				title: 'Estatus',
				propertyName: 'status',
				cellRender: ({ status }) => <Status status={status} size="sm" />,
			},
			{
				sortField: true,
				title: 'Fecha de creación',
				propertyName: 'validateatDate',
				cellRender: ({ createdatDate, createdatTime }) => (
					<DateAndTime date={createdatDate} time={createdatTime} />
				),
			},
			{
				sortField: true,
				title: 'Fecha de expiración',
				propertyName: 'expiredatDate',
				cellRender: ({ expireDate, expireTime }) => (
					<DateAndTime date={expireDate} time={expireTime} />
				),
			},
			{
				title: 'Comprobante',
				align: 'center',
				hide: merchantSelected?.country !== 'MX',
				propertyName: 'urlCEP',
				cellRender: ({ urlCEP }) => (
					<VoucherContainer>
						{urlCEP && (
							<VoucherButton onClick={() => window.open(urlCEP)}>
								<EyeIcon />
							</VoucherButton>
						)}
					</VoucherContainer>
				),
			},
		];

		const lastStepColumn: ColumnInterface<PaymentsMultiuse> = {
			title: 'Ultimo paso',
			propertyName: 'lastStep',
			cellRender: ({ lastStep }) => <>{lastStep}</>,
		};

		const isETpay =
			merchantSelected &&
			getRoleLabelById(merchantSelected?.role_id, merchantSelected?.role_id) === 'ETpay';

		if (isETpay) {
			return [...commonColumns.slice(0, 6), lastStepColumn, ...commonColumns.slice(6)];
		}

		return commonColumns;
	};

	const columnsTableDetail = createColumnsTableDetail();

	const tableWithDetails: RowInterface<PaymentMultiuse> = (data) => {
		return data.payments.length ? (
			<TableDetail columns={columnsTableDetail} data={data.payments} />
		) : undefined;
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await PaymentLinksMultiuseServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from,
					to: filters?.dateRange?.to,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	const onClose = () => {
		setShowEditModal(false);
		setShowShareModal({ show: false, selected: '' });
		setSelectedRowData(undefined);
	};

	const handleEndPaymentLink = async () => {
		setModal((modal) => ({ ...modal, loader: true, show: true }));
		try {
			await PaymentLinksMultiuseServices.endPaymentLinkMultiuse({
				merchantCode: merchantSelected?.code as string,
				presessionToken: selectedRowData?.token.split('/').slice(-1)[0] || '',
				comment: commentValue,
			});
			setShowFinalizedModal(false);
			refetch();
			handleShowAlert({
				message: 'Enlace de cobro finalizado',
				type: 'success',
			});
			setModal((modal) => ({ ...modal, loader: false, show: false }));
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const renderSelectByType = () => (
		<SelectContainer>
			<Select
				options={[
					{ label: 'Todos', value: '', customLabel: 'Todos', show: true },
					{
						label: (
							<ProductFilterLabel>
								<OnePayIcon /> Únicos
							</ProductFilterLabel>
						),
						value: 'UNIQUE',
						customLabel: 'Únicos',
						show: true,
					},
					{
						label: (
							<ProductFilterLabel>
								<MultiPayIcon /> Recurrentes
							</ProductFilterLabel>
						),
						value: 'RECURRENT',
						customLabel: 'Recurrentes',
						show: true,
					},
				]}
				prefix="Tipo:"
				onChange={({ value }) => {
					if (onFilterChange) {
						onFilterChange({ ...filters, typeSelected: value as string });
					}
				}}
			/>
		</SelectContainer>
	);

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}
			{(showShareModal.show || showEditModal) && (
				<NewSinglePayment
					show={showEditModal || showShareModal.show}
					onClose={onClose}
					selectedToEdit={selectedRowData}
					showShareModal={showShareModal}
					viewType={viewType}
					refetch={refetch}
				/>
			)}
			{showFinalizedModal && (
				<ModalMultiuse
					show={showFinalizedModal}
					setShowFinalizedModal={() => setShowFinalizedModal(false)}
					handleEndPaymentLink={() => handleEndPaymentLink()}
					setCommentValue={setCommentValue}
					commentValue={commentValue}
					modal={modal}
				/>
			)}

			<ETPayTable<PaymentMultiuse>
				title="Todos los enlaces de cobro"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={tableWithDetails}
				customFilter={renderSelectByType()}
				hasFilters
				hasDateFilter
				allowExport={validatePermission('PL_EXPORT')}
				stateList={fillStatesWithZeros({ states: paymentLinkMultiuseStates, stateList })}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				allowCustomFilter
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				statusContent={[
					['actived', 'Activos'],
					['pending', 'Pendiente'],
					['finalized', 'Finalizado'],
					['expired', 'Expirados'],
				]}
			/>
		</>
	);
};

export { PaymentLinkTable };
