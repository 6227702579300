import styled from 'styled-components';

interface IProps {
	readonly isOpen: boolean;
}

export const BurgerContainer = styled.div<IProps>`
	cursor: pointer;
	display: none;
	height: 20px;
	justify-self: flex-end;
	position: relative;
	width: 26px;

	span {
		background-color: rgb(20 15 51);
		display: block;
		height: 3px;
		margin-top: 4px;
		transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
		width: 100%;

		&:first-of-type {
			transform: ${({ isOpen }) => isOpen && 'translate(0, 4px) rotate(-45deg)'};
		}

		&:nth-of-type(2) {
			opacity: ${({ isOpen }) => isOpen && 0};
		}

		&:last-of-type {
			transform: ${({ isOpen }) => isOpen && 'translate(0, -10px) rotate(45deg)'};
		}
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		display: block;
	}
`;
