import { Dispatch, SetStateAction, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { EyeIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { Tooltip } from '../../../../components/tooltip';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PaymentStatus, Status as StatusType } from '../../../../models/common-payments';
import { DirectDebitMx } from '../../../../models/direct-debit-mx';
import { ColumnInterface, Filters, Pagination, RowInterface } from '../../../../models/table';
import { DirectDebitMxServices } from '../../../../services/direct-debit-mx';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import { PaymentsMultiuse, TableDetail } from '../table-detail';
import {
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	ItemText,
	StatusAlign,
	StatusContainer,
	SubText,
	Text,
	TextMax,
	TextPayment,
	VoucherButton,
	VoucherContainer,
} from './styles';
interface DirectDebitMxTableProps {
	data: DirectDebitMx[];
	isLoading?: boolean;
	stateList: PaymentStatus[];
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	thisMonth?: boolean;
	today?: Date;
	pastDate?: Date;
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
}
interface Status {
	status: string;
	message: string;
}

export interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const DirectDebitMxTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	today,
	pastDate,
	totalResults,
}: DirectDebitMxTableProps) => {
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();

	const directDebitPaymentStates = ['active', 'pending', 'cancelled', 'error'];

	const columns: ColumnInterface<DirectDebitMx>[] = [
		{
			sortField: true,
			title: 'Cliente',
			propertyName: 'clientnamecep',
			cellRender: ({ clientName, clientEmail }) => (
				<ItemText>
					<Text>{clientName}</Text>
					<SubText disabled>{clientEmail}</SubText>
				</ItemText>
			),
		},
		{
			title: 'ID',
			propertyName: 'id',
			cellRender: ({ token }) => (
				<ItemText>
					<TextMax>{token}</TextMax>
				</ItemText>
			),
		},
		{
			title: 'Cuenta',
			propertyName: 'account',
			cellRender: ({ account, bankName }) => (
				<ItemText>
					<Text>{account}</Text>
					<SubText disabled>{bankName}</SubText>
				</ItemText>
			),
		},
		{
			sortField: true,
			title: 'Servicio',
			propertyName: 'servicename',
			cellRender: ({ servicename }) => (
				<ItemText>
					<Text>{servicename}</Text>
				</ItemText>
			),
		},
		{
			sortField: true,
			title: 'Frecuencia',
			propertyName: 'frequency',
			cellRender: ({ frequency }) => (
				<ItemText>
					<Text>{frequency}</Text>
				</ItemText>
			),
		},
		{
			title: 'Estatus',
			propertyName: 'finalstatus',
			align: 'center',
			cellRender: ({ status }) => {
				return (
					<>
						{status?.code && (
							<>
								{status?.code == 'error' && status?.description ? (
									<Tooltip text={status.description}>
										<PaymentStatusComponent finalstatus={status.code} />
									</Tooltip>
								) : (
									<PaymentStatusComponent finalstatus={status.code} />
								)}
							</>
						)}
					</>
				);
			},
		},
		{
			sortField: true,
			title: 'Último cargo',
			propertyName: 'updatedat',
			cellRender: ({ lastPrepaymentDate }) => (
				<ItemText>
					<Text>{lastPrepaymentDate}</Text>
				</ItemText>
			),
		},
		{
			sortField: true,
			title: 'Vence el',
			propertyName: 'enddate',
			cellRender: ({ endDate }) => (
				<ItemText>
					<Text>{endDate}</Text>
				</ItemText>
			),
		},
		{
			sortField: true,
			title: 'Contrato',
			propertyName: 'contractsigned',
			cellRender: ({ contract_url, status }) => (
				<VoucherContainer>
					{status?.code &&
						(status.code == 'active' || status.code == 'cancelled') &&
						contract_url && (
							<VoucherButton onClick={() => window.open(contract_url)}>
								<EyeIcon />
							</VoucherButton>
						)}
				</VoucherContainer>
			),
		},
	];

	const PaymentStatusComponent = ({ finalstatus }: { finalstatus: StatusType }) => {
		return (
			<StatusContainer>
				<Status status={finalstatus} />
			</StatusContainer>
		);
	};

	const columnsTableDetail: ColumnInterface<PaymentsMultiuse>[] = [
		{
			title: 'Clave de rastreo',
			propertyName: 'trackingkey',
			cellRender: ({ trackingkey }) => <TextPayment>{trackingkey}</TextPayment>,
		},
		{
			title: 'Monto',
			propertyName: 'amount',
			cellRender: ({ amount }) => <>{amount}</>,
		},
		{
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ finalstatus }) => (
				<StatusAlign>
					<PaymentStatusComponent finalstatus={finalstatus} />
				</StatusAlign>
			),
		},
		{
			title: 'Motivo',
			propertyName: 'description',
			cellRender: ({ description }) => <>{description}</>,
		},
		{
			sortField: true,
			title: 'Programado para',
			propertyName: 'paymentdate',
			cellRender: ({ paymentdate }) => (
				<ItemText>
					<Text>{paymentdate}</Text>
				</ItemText>
			),
		},
		{
			sortField: true,
			title: 'Actualizado el',
			propertyName: 'updateddate',
			cellRender: ({ updateddate }) => (
				<ItemText>
					<Text>{updateddate}</Text>
				</ItemText>
			),
		},
	];

	const tableWithDetails: RowInterface<DirectDebitMx> = (data) => {
		return data.prepayments.length ? (
			<TableDetail columns={columnsTableDetail} data={data.prepayments} />
		) : undefined;
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await DirectDebitMxServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from || pastDate,
					to: filters?.dateRange?.to || today,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
			handleCloseModal();
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}
			<ETPayTable<DirectDebitMx>
				title="Todas las suscripciones"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={tableWithDetails}
				hasFilters
				hasDateFilter
				allowExport //Duda permiso
				stateList={fillStatesWithZeros({ states: directDebitPaymentStates, stateList })}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				allowCustomFilter
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				today={today}
				pastDate={pastDate}
				statusContent={[
					['active', 'Activas'],
					['pending', 'Pendientes'],
					['cancelled', 'Canceladas'],
					['error', 'Fallidas'],
				]}
			/>
		</>
	);
};

export { DirectDebitMxTable };
