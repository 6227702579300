import { Fragment } from 'react';

import { TableBody, TableData, TableRow } from '../table/components/body/styles';
import { PlaceholderSkeleton } from './styles';

interface ETPayTableBodyInterface {
	columnsLength: number;
}

export const ETPayTableBodySkeleton = ({ columnsLength }: ETPayTableBodyInterface) => {
	return (
		<TableBody>
			{[...Array(10)].map((_, i) => (
				<Fragment key={i}>
					<TableRow>
						{[...Array(columnsLength)].map((_, j) => (
							<TableData key={j} alignContent="left">
								<PlaceholderSkeleton />
							</TableData>
						))}
					</TableRow>
				</Fragment>
			))}
		</TableBody>
	);
};
