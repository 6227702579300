import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { Alert } from '../../components/alert';
import { Input } from '../../components/input';
import { Section } from '../../components/section';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { AccountInformationServices } from '../../services/account-info';
import { validatePermission } from '../../utils/common';
import {
	AlertMessage,
	BodySection,
	InputContainer,
	InputContainerGroup,
	TitlePage,
} from './styles';

const AccountInformation = () => {
	const { merchantSelected } = useMerchant();

	const email =
		merchantSelected?.country === 'MX' ? 'operacionesmexico@etpay.com' : ' operaciones@etpay.com';

	const { data, isLoading, isFetching } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.config.accountInformation.getInformation, merchantSelected?.code],
		queryFn: () =>
			AccountInformationServices.getAccountInformationList(merchantSelected?.code as string),
	});
	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.account_info.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper title="Información de la cuenta" isLoading={isLoading || isFetching}>
			<TitlePage>Información de la cuenta</TitlePage>

			{validatePermission('SETTINGS_ACCOUNT_INFO_PUT') && (
				<Alert
					show
					hasIcon
					message={
						<AlertMessage>
							Para actualizar la información de tu cuenta comunícate con nuestro equipo de soporte{' '}
							<a href={`mailto:${email}`}>{email}</a>.
						</AlertMessage>
					}
				/>
			)}

			<BodySection>
				<Section title="Información del comercio">
					<InputContainer>
						<Input
							label={merchantSelected?.country === 'MX' ? 'Persona moral' : 'Razón social'}
							variant="large"
							value={data?.merchant_info.company_reason}
							readOnly
						/>
					</InputContainer>

					<InputContainer>
						<Input
							label={merchantSelected?.country === 'MX' ? 'RFC' : 'RUT'}
							variant="large"
							value={data?.merchant_info.rut}
							readOnly
						/>
					</InputContainer>

					<InputContainer>
						<Input
							label="Calle y número exterior"
							variant="large"
							value={data?.merchant_info.address}
							readOnly
						/>
					</InputContainer>

					<InputContainer>
						<InputContainerGroup>
							<Input
								label="Número interior"
								variant="large"
								value={data?.merchant_info.interior_number}
								readOnly
							/>

							<Input
								label={merchantSelected?.country === 'MX' ? 'Colonia' : 'Comuna'}
								variant="large"
								value={data?.merchant_info.communa}
								readOnly
							/>
						</InputContainerGroup>
					</InputContainer>

					<InputContainer>
						<InputContainerGroup>
							<Input
								label="Código postal"
								variant="large"
								value={data?.merchant_info.postal_code}
								readOnly
							/>

							<Input label="Ciudad" variant="large" value={data?.merchant_info.city} readOnly />
						</InputContainerGroup>
					</InputContainer>
				</Section>

				{merchantSelected?.show_deposit_info && (
					<Section
						title={merchantSelected?.country === 'MX' ? 'Cuenta de recaudo' : 'Cuenta de depósito'}
					>
						<InputContainer>
							<Input
								label="Beneficiario"
								variant="large"
								value={data?.deposit_info.name}
								readOnly
							/>
						</InputContainer>

						<InputContainer>
							<Input label="Banco" variant="large" value={data?.deposit_info.bank_name} readOnly />
						</InputContainer>

						<InputContainer>
							<Input
								label="Tipo de cuenta"
								variant="large"
								value={data?.deposit_info.type}
								readOnly
							/>
						</InputContainer>

						<InputContainer>
							<Input
								label="Número de cuenta"
								variant="large"
								value={data?.deposit_info.account}
								readOnly
							/>
						</InputContainer>
					</Section>
				)}
			</BodySection>
		</ViewWrapper>
	);
};

export { AccountInformation };
