import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { PayloadProps, track } from '../../../analytics';
import { ANALYTICS } from '../../../analytics/constants';
import ETPayBG from '../../../assets/images/etpay-bg-02.jpg';
import { EyeIcon, EyeIconOff } from '../../../assets/svg';
import { Alert, AlertProps } from '../../../components/alert';
import { ETPayButton } from '../../../components/button';
import { FormInput } from '../../../components/form/form-input';
import { CONSTANTS } from '../../../config/constants';
import { useAuth } from '../../../hooks/useAuth';
import { useMerchant } from '../../../hooks/useMerchant';
import { AuthLayout } from '../../../layout/auth';
import { AuthUser } from '../../../models/auth';
import { errorFormatter } from '../../../utils/errors';
import { loginValidationSchema } from '../../../utils/validations';
import {
	AlertContainer,
	AuthFormContainer,
	ResetContainer,
	ResetPassword,
	Subtitle,
	Title,
} from '../styles';

const defaultAlert: AlertProps = {
	show: false,
	message: '',
	type: 'error',
};

const Login = () => {
	const [alert, setAlert] = useState(defaultAlert);
	const { handleLogin, isLoading, user, handleLogout } = useAuth();
	const { handleGetMerchants } = useMerchant();
	localStorage.removeItem(CONSTANTS.localStorage.ETPay);
	const [showPassword, setShowPassword] = useState(false);
	const [searchParams] = useSearchParams();
	const params = new URLSearchParams(searchParams);
	const defaultValues: AuthUser = {
		username: params.get('email') as string || '',
		password: '',
	};

	const { isLoading: merchantLoading } = useQuery({
		enabled: !!user,
		queryKey: [CONSTANTS.services.merchant.getMerchantsList],
		queryFn: () => handleGetMerchants(),
		onError: (e: any) => {
			setAlert({
				show: true,
				message: errorFormatter(e.code),
				type: 'error',
			});
			handleLogout();
		},
	});

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.login.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<AuthLayout
			tabTitle="Inicia sesión"
			image={ETPayBG}
			buttonTitle="¿No tienes una cuenta?"
			link="/sign-up"
			linkLabel="Regístrate"
		>
			<AlertContainer>
				<Alert show={alert.show} message={alert.message} hasIcon type={alert.type} />
			</AlertContainer>

			<AuthFormContainer>
				<Title>Inicia sesión</Title>
				<Subtitle>¡Bienvenido de vuelta!</Subtitle>

				<Formik
					initialValues={defaultValues}
					onSubmit={async ({ username, password }) => {
						setAlert(defaultAlert);
						try {
							const userData: AuthUser = {
								username,
								password,
							};
							await handleLogin(userData);
						} catch (e: any) {
							setAlert({
								show: true,
								message: errorFormatter(e.code),
								type: 'error',
							});
						}
					}}
					validationSchema={loginValidationSchema}
				>
					{({ isValid, dirty }) => (
						<Form>
							<FormInput
								type="text"
								label="Correo electrónico"
								name="username"
								autoComplete="off"
							/>

							<FormInput
								type={showPassword ? 'text' : 'password'}
								label="Contraseña"
								name="password"
								autoComplete="new-password"
								rightIcon={showPassword ? <EyeIcon /> : <EyeIconOff />}
								rightIconClick={() => setShowPassword(!showPassword)}
							/>
							<ResetContainer>
								<ResetPassword href="/reset-password">¿Olvidaste tu contraseña?</ResetPassword>
							</ResetContainer>

							<ETPayButton
								title={isLoading || merchantLoading ? 'Cargando...' : 'Iniciar sesión'}
								loading={isLoading || merchantLoading}
								variant="effect"
								type="button"
								active={isValid && dirty}
							/>
						</Form>
					)}
				</Formik>
			</AuthFormContainer>
		</AuthLayout>
	);
};

export { Login };
