export enum MerchantActions {
	GET_MERCHANT = '[Merchant] get merchant',
	GET_ENTERPRISE = '[Merchant] get enterprise',
	SELECT_MERCHANT = '[Merchant] select merchant',
	UPDATE_ROLE = '[Merchant] update role',
	UPDATE_ROLE_CURRENT_MERCHANT = '[Merchant] update role current merchant',
	RESET_STATE = '[Merchant] Reset State',
	SET_URL_TO_DOWNLOAD_EXAMPLE = '[Merchant] set url to download example',
	SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS = '[Merchant] set url to download example payouts',
	SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS_CHILE = '[Merchant] set url to download example payouts chile',
	SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS_SBIF_CHILE = '[Merchant] set url to download banks examples of payouts chile',
	SET_URL_TO_DOWNLOAD_EXAMPLE_DIRECT_DEBIT_MX = '[Merchant] set url to download examples of debit direct mexico',
	SELECT_VIEW_ALL = '[Merchant] select view all',
}
