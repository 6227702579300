import { PropsWithChildren, useState } from 'react';

import { TooltipContainer, TooltipText } from './styles';

interface TooltipProps {
	text?: string;
	position?: 'top' | 'bottom';
	autoWidth?: boolean
}

const Tooltip = ({ children, text, position = 'top', autoWidth = false}: PropsWithChildren<TooltipProps>) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<TooltipContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
			{children}
			{isHovered && <TooltipText $position={position} $autoWidth={autoWidth}>{text}</TooltipText>}
		</TooltipContainer>
	);
};

export { Tooltip };
