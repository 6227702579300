import { Dispatch, SetStateAction, useState } from 'react';

import { RefreshIcon } from '../../../../assets/svg/RefreshIcon';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Tabs } from '../../../../components/tabs';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { DirectDebitServices } from '../../../../services/direct-debit';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { State } from '../..';
import { QRTab } from './components/qr-tab';
import { SendLinkTab } from './components/send-link-tab';
import {
	Button,
	ButtonContainer,
	ContainerInput,
	CopyButton,
	ModalBody,
	ModalContainer,
	Subtitle,
	TabsContainer,
	Title,
} from './styles';

interface ShowShareModal {
	show: boolean;
	selected: string;
}

interface NewSinglePaymentProps {
	show: boolean;
	onClose: () => void;
	showShareModal?: ShowShareModal;
	state: State;
	setState: Dispatch<SetStateAction<State>>;
}

type CopyText = 'Copiar' | 'Copiado';

const NewSinglePayment = ({
	show,
	showShareModal,
	onClose,
	state,
	setState,
}: NewSinglePaymentProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [copyText, setCopyText] = useState<CopyText>('Copiar');
	const [loading, setLoading] = useState<boolean>(false);

	const handleClose = () => {
		onClose();
		setState({ qr: '', url: '' });
	};

	const handleNewSubscription = () => async () => {
		try {
			setLoading(true);
			const data = await DirectDebitServices.newSubscription({
				merchantCode: merchantSelected?.code as string,
			});
			setState({ ...state, qr: data.dd_qr, url: data.dd_url });
			setLoading(false);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	return (
		<Modal show={show} type="medium" title="Nueva Suscripción" onClose={handleClose}>
			{(show && !state.qr) || loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<ModalBody>
						<>
							<div>
								<Title>Comparte con tu cliente</Title>
								<Subtitle>
									Muestra el código QR o comparte el enlace de suscripción con tu cliente
								</Subtitle>
							</div>
							<ContainerInput>
								<Input
									label="Enlace de suscripción"
									name="directDebit"
									defaultValue={state.url}
									variant="large"
									readOnly
									rightIcon={<CopyButton>{copyText}</CopyButton>}
									rightIconClick={async () => {
										await navigator.clipboard.writeText(state.url);
										setCopyText('Copiado');
										setTimeout(() => setCopyText('Copiar'), 2500);
									}}
								/>
							</ContainerInput>
							<TabsContainer>
								<Tabs
									tabs={[
										{
											reference: 'send-link',
											title: 'Enviar enlace',
											show: validatePermission('PL_SEND_SMS'),
										},
										{
											reference: 'qr',
											title: 'Código QR',
											show: validatePermission('PL_SHARE_LINK'),
										},
									]}
									defaultReferenceSelected={showShareModal?.selected || 'qr'}
								>
									<SendLinkTab id="send-link" url={state.url} />
									<QRTab
										id="qr"
										merchantName={merchantSelected?.name as string}
										paymentLinkUrl={state.qr}
									/>
								</Tabs>
							</TabsContainer>
							<ButtonContainer>
								<Button variant="pure" onClick={handleNewSubscription()}>
									<RefreshIcon />
									Generar nueva suscripción
								</Button>
							</ButtonContainer>
						</>
					</ModalBody>
				</ModalContainer>
			)}
		</Modal>
	);
};

export { NewSinglePayment };
