interface IProps {
	active?: boolean;
}

export const NonBankCardIcon = ({ active = true }: IProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 40 40" fill="none">
		<rect width="40" height="40" rx="4" fill="#482524" />
		<mask
			id="path-2-outside-1_17619_2073"
			maskUnits="userSpaceOnUse"
			x="9.45483"
			y="21.21"
			width="21"
			height="12"
			fill="black"
		>
			<rect fill="white" x="9.45483" y="21.21" width="21" height="12" />
			<path d="M11.5453 22.9148C11.5453 25.1148 12.4361 27.2247 14.0217 28.7804C15.6072 30.3361 17.7577 31.21 20.0001 31.21C22.2424 31.21 24.3929 30.3361 25.9785 28.7804C27.5641 27.2247 28.4548 25.1148 28.4548 22.9148L20.0001 22.9148L11.5453 22.9148Z" />
		</mask>
		<path
			d="M11.5453 22.9148C11.5453 25.1148 12.4361 27.2247 14.0217 28.7804C15.6072 30.3361 17.7577 31.21 20.0001 31.21C22.2424 31.21 24.3929 30.3361 25.9785 28.7804C27.5641 27.2247 28.4548 25.1148 28.4548 22.9148L20.0001 22.9148L11.5453 22.9148Z"
			fill="white"
		/>
		<path
			d="M11.5453 22.9148C11.5453 25.1148 12.4361 27.2247 14.0217 28.7804C15.6072 30.3361 17.7577 31.21 20.0001 31.21C22.2424 31.21 24.3929 30.3361 25.9785 28.7804C27.5641 27.2247 28.4548 25.1148 28.4548 22.9148L20.0001 22.9148L11.5453 22.9148Z"
			stroke="white"
			strokeWidth="3"
			mask="url(#path-2-outside-1_17619_2073)"
		/>
		<circle cx="25.0001" cy="12.5156" r="4.08333" fill="white" stroke="white" strokeWidth="1.5" />
	</svg>
	// <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
	// 	<rect width="85" height="85" rx="12" fill={active ? '#646174' : '#B7B7B7'} />
	// 	<path
	// 		d="M31.7704 11.0001C26.8901 11.0001 22.2098 12.976 18.7589 16.4933C15.3081 20.0105 13.3694 24.7808 13.3694 29.7549C13.3694 34.729 15.3081 39.4994 18.7589 43.0166C22.2098 46.5338 26.8901 48.5098 31.7704 48.5098L31.7704 29.7549L31.7704 11.0001Z"
	// 		fill="#FCFCFC"
	// 	/>
	// 	<path
	// 		d="M53.2387 73.8729C58.1189 73.8729 62.7993 71.897 66.2501 68.3798C69.701 64.8626 71.6396 60.0922 71.6396 55.1181C71.6396 50.144 69.701 45.3737 66.2501 41.8564C62.7993 38.3392 58.1189 36.3633 53.2387 36.3633L53.2387 55.1181L53.2387 73.8729Z"
	// 		fill="#FCFCFC"
	// 	/>
	// 	<path
	// 		d="M73.9999 31.7623C73.9999 26.8821 72.024 22.2017 68.5067 18.7508C64.9895 15.3 60.2192 13.3613 55.2451 13.3613C50.271 13.3613 45.5006 15.3 41.9834 18.7508C38.4662 22.2017 36.4902 26.8821 36.4902 31.7623L55.2451 31.7623L73.9999 31.7623Z"
	// 		fill="#FCFCFC"
	// 	/>
	// 	<path
	// 		d="M11.1261 53.2299C11.1261 58.1101 13.102 62.7905 16.6192 66.2413C20.1365 69.6922 24.9068 71.6309 29.8809 71.6309C34.855 71.6309 39.6254 69.6922 43.1426 66.2413C46.6598 62.7905 48.6357 58.1101 48.6357 53.2299L29.8809 53.2299L11.1261 53.2299Z"
	// 		fill="#FCFCFC"
	// 	/>
	// </svg>
);
