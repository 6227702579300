import { CancelIcon } from '../../../../../assets/svg/CancelIcon';
import { SuccessIcon } from '../../../../../assets/svg/SuccessIcon';
import { TypesAllowed } from '..';
import {
	Circle,
	IconContainer,
	PaymentTypeContainer,
	PaymentTypeSubTitle,
	PaymentTypeTitle,
	SelectedIconContainer,
} from '../styles';

interface PaymentTypeProps {
	type: TypesAllowed;
	title: string;
	subtitle: string;
	isSelected?: boolean;
	onClick?: () => void;
}

const StateType = ({ type, title, subtitle, isSelected = false, onClick }: PaymentTypeProps) => {
	return (
		<PaymentTypeContainer type={type} isSelected={isSelected} onClick={onClick}>
			<SelectedIconContainer>
				<Circle type={type} isSelected={isSelected} />
			</SelectedIconContainer>
			<IconContainer isSelected={isSelected}>
				{type === 'rejected' && <CancelIcon />}
				{type === 'successMerchant' && <SuccessIcon />}
			</IconContainer>

			<PaymentTypeTitle isSelected={isSelected}>{title}</PaymentTypeTitle>
			<PaymentTypeSubTitle isSelected={isSelected}>{subtitle}</PaymentTypeSubTitle>
		</PaymentTypeContainer>
	);
};

export { StateType };
