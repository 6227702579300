import { SVGProps } from 'react';

export const Lider = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="75"
			height="25"
			viewBox="0 0 75 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.7206 5.4023C6.7206 5.4023 5.19706 10.5949 4.16414 14.7799L3.72516 16.4075C3.5444 17.1566 4.03503 17.1825 4.03503 17.1825H6.90136L6.20414 19.7917H3.02794H3.05377C3.05377 19.7917 1.53022 19.8692 0.833006 19.12C0.135791 18.3708 0.316551 17.4925 0.368196 17.0533C0.419842 16.6916 1.76263 11.5766 1.76263 11.5766L3.49275 5.37646H6.7206V5.4023Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.964 5.4021L24.6399 14.8314C23.2197 19.6881 18.2875 19.7915 18.2875 19.7915C15.4728 19.9982 13.0197 18.8615 12.7615 16.3556C12.245 11.0597 17.9002 7.80464 22.8582 9.81967C23.168 8.29548 23.7103 6.90046 24.0202 5.4021H26.964ZM16.5832 13.0489C15.9893 13.9531 15.5761 15.7098 16.1959 16.8464C16.8673 18.0606 19.1655 18.0606 20.2501 17.2339C21.4379 16.3298 21.8252 13.6689 22.2901 12.0414C20.405 10.388 17.642 11.4472 16.5832 13.0489Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.6966 15.2188C28.1802 18.603 33.2673 18.0863 34.9199 16.7946C34.7392 17.5697 34.7133 18.4738 34.5326 19.2747C30.53 20.4889 25.9852 19.6105 25.5979 16.0455C25.1073 11.7312 28.6966 9.45786 32.3893 9.19953C34.5067 9.04452 36.9341 9.4062 37.5797 10.9046C37.967 11.8087 37.6313 13.0229 37.1407 13.6429C35.643 15.4513 31.6146 15.8905 28.6966 15.2188ZM31.6921 13.5654C32.9574 13.4879 35.204 13.0488 34.7908 11.8604C34.5326 11.1371 33.0865 11.0079 32.1827 11.1629C30.8141 11.4212 29.807 12.4287 29.2647 13.3846C29.9361 13.5396 30.7883 13.6171 31.6921 13.5654Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M47.3391 9.38023C47.3391 9.38023 46.5386 11.6536 46.5128 11.7311C45.6864 11.4986 45.3507 11.0594 44.2404 11.1628C41.3482 11.4469 40.6768 15.1153 40.1345 18.0604L39.8505 19.817H36.8809C37.6814 16.407 38.5335 12.7644 39.3598 9.38023C40.3153 9.38023 41.3482 9.38023 42.3036 9.38023L42.1487 10.2069C43.3624 9.38023 45.4282 8.88939 47.3391 9.38023Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.1517 9.43213C12.4029 12.868 11.4991 16.3556 10.7502 19.8173H7.70312C8.32287 17.0789 8.96844 14.3405 9.61401 11.628H8.013L8.55528 9.43213H13.1517Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.7202 6.7453C13.7202 7.80449 12.8681 8.24366 11.8093 8.24366C10.7506 8.24366 9.89844 7.57198 9.89844 6.7453C9.89844 5.91862 10.7506 5.24695 11.8093 5.24695C12.8422 5.24695 13.7202 5.91862 13.7202 6.7453Z"
				fill="white"
			/>
			<path
				d="M61.6961 8.57953C62.2642 8.57953 62.7548 8.26953 62.8064 7.88202L63.3745 1.47526C63.3745 0.829419 62.6257 0.312744 61.6961 0.312744C60.7664 0.312744 60.0176 0.829419 60.0176 1.47526L60.5857 7.88202C60.6373 8.26953 61.128 8.57953 61.6961 8.57953Z"
				fill="white"
			/>
			<path
				d="M58.237 10.5688C58.521 10.078 58.4952 9.50961 58.2112 9.27711L52.9433 5.58288C52.401 5.27288 51.5747 5.66038 51.1099 6.46123C50.6451 7.26207 50.7225 8.16626 51.2648 8.50209L57.1008 11.2146C57.4365 11.3438 57.9529 11.0596 58.237 10.5688Z"
				fill="white"
			/>
			<path
				d="M65.1576 10.5687C65.4416 11.0596 65.9323 11.3179 66.2938 11.1888L72.1297 8.47621C72.672 8.16621 72.7495 7.26203 72.2847 6.43535C71.8199 5.63451 70.9935 5.247 70.4513 5.557L65.1834 9.25123C64.8994 9.50956 64.8735 10.0779 65.1576 10.5687Z"
				fill="white"
			/>
			<path
				d="M61.6961 16.588C62.2642 16.588 62.7548 16.898 62.8064 17.2597L63.3745 23.6923C63.3745 24.3381 62.6257 24.8548 61.6961 24.8548C60.7664 24.8548 60.0176 24.3381 60.0176 23.6923L60.5857 17.2597C60.6373 16.898 61.128 16.588 61.6961 16.588Z"
				fill="white"
			/>
			<path
				d="M65.1576 14.5989C65.4416 14.108 65.9323 13.8497 66.2938 13.9788L72.1297 16.6914C72.672 17.0014 72.7495 17.9056 72.2847 18.7323C71.8199 19.5331 70.9935 19.9206 70.4513 19.6106L65.1834 15.9164C64.8994 15.6322 64.8735 15.0897 65.1576 14.5989Z"
				fill="white"
			/>
			<path
				d="M58.237 14.5989C58.521 15.0897 58.4952 15.6581 58.2112 15.9164L52.9433 19.6106C52.401 19.9206 51.5747 19.5331 51.1099 18.7323C50.6451 17.9314 50.7225 17.0273 51.2648 16.6914L57.1008 13.9789C57.4365 13.8239 57.9529 14.0822 58.237 14.5989Z"
				fill="white"
			/>
			<path
				d="M74.0394 18.7838C74.1944 18.7838 74.3493 18.8355 74.4784 18.913C74.6334 18.9905 74.7366 19.0938 74.8141 19.2488C74.8916 19.4038 74.9432 19.5588 74.9432 19.7138C74.9432 19.8688 74.8916 20.0238 74.8141 20.1788C74.7366 20.3338 74.6334 20.4372 74.4784 20.5147C74.3235 20.5922 74.1685 20.6438 74.0136 20.6438C73.8587 20.6438 73.7037 20.5922 73.5488 20.5147C73.3939 20.4372 73.2906 20.3338 73.2131 20.1788C73.1356 20.0238 73.084 19.8688 73.084 19.7138C73.084 19.5588 73.1356 19.4038 73.2131 19.2488C73.2906 19.0938 73.4197 18.9905 73.5488 18.913C73.7296 18.8355 73.8845 18.7838 74.0394 18.7838ZM74.0394 18.9388C73.9103 18.9388 73.7812 18.9646 73.6521 19.0422C73.523 19.1197 73.4455 19.1972 73.368 19.3263C73.2906 19.4555 73.2647 19.5847 73.2647 19.7138C73.2647 19.843 73.2906 19.9722 73.368 20.1013C73.4455 20.2305 73.523 20.308 73.6521 20.3855C73.7812 20.463 73.9103 20.4888 74.0394 20.4888C74.1685 20.4888 74.2977 20.463 74.4268 20.3855C74.5559 20.308 74.6334 20.2305 74.7108 20.1013C74.7883 19.9722 74.8141 19.843 74.8141 19.7138C74.8141 19.5847 74.7883 19.4555 74.7108 19.3263C74.6334 19.1972 74.5559 19.1197 74.4268 19.0422C74.2718 18.9646 74.1685 18.9388 74.0394 18.9388ZM73.6263 20.2047V19.223H73.962C74.0653 19.223 74.1685 19.223 74.2202 19.2488C74.2718 19.2747 74.3235 19.3005 74.3493 19.3522C74.3751 19.4038 74.4009 19.4555 74.4009 19.5072C74.4009 19.5847 74.3751 19.6363 74.3235 19.688C74.2718 19.7397 74.1944 19.7655 74.1169 19.7655C74.1427 19.7913 74.1685 19.7913 74.1944 19.8172C74.246 19.8688 74.2718 19.9205 74.3493 20.0238L74.4784 20.2047H74.2718L74.1944 20.0497C74.1169 19.9205 74.0653 19.843 74.0394 19.8172C74.0136 19.7913 73.962 19.7913 73.9103 19.7913H73.807V20.2047H73.6263ZM73.7812 19.6622H73.962C74.0653 19.6622 74.1169 19.6363 74.1427 19.6105C74.1685 19.5847 74.1944 19.5588 74.1944 19.5072C74.1944 19.4813 74.1944 19.4555 74.1685 19.4297C74.1427 19.4038 74.1427 19.378 74.0911 19.378C74.0653 19.378 74.0136 19.3522 73.9361 19.3522H73.7554V19.6622H73.7812Z"
				fill="white"
			/>
		</svg>
	);
};
