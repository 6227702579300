import styled from 'styled-components';

export const DragAndDropContainer = styled.div`
	margin-top: 16px;
	width: 100%;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
`;

export const ModalTitle = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 8px;
	text-align: center;
`;

export const ModalStep = styled.p`
	color: #140f33;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.6px;
	line-height: 12px;
	margin-bottom: 2px;
	text-align: center;
`;

interface Span {
	readonly align?: string;
}
export const ModalSubtitle = styled.span<Span>`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 8px;
	text-align: ${({ align }) => (align == 'center' ? 'center' : 'left')};
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 24px;
`;

export const ModalFooter = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	margin-top: 32px;
`;

export const HintInput = styled.p`
	color: ${({ theme }) => theme.colors.principal.moon};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	text-align: left;
	width: 100%;
`;

export const HintInputError = styled.p`
	color: ${({ theme }) => theme.colors.extra.sword};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	text-align: left;
	width: 100%;
`;

interface Button {
	readonly color?: string;
}

export const Button = styled.button<Button>`
	background-color: ${({ theme, color }) =>
		color === 'primary' ? theme.colors.extra.earth : theme.colors.principal.eris};
	border: ${({ theme, color }) =>
		color === 'primary'
			? `1px solid ${theme.colors.principal.pure}`
			: `1px solid ${theme.colors.principal.eris}`};
	border-radius: 8px;
	color: ${({ theme, color }) =>
		color === 'primary' ? theme.colors.principal.pure : theme.colors.principal.orion};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	padding: 15px 0;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}
`;

export const LabelButtonIcon = styled.div`
	margin-top: 6px;
`;

export const TransferDetail = styled.div`
	background: #f8f8f8;
	border-radius: 12px;
	margin-top: 32px;
	padding: 12px;
	width: 100%;
`;

export const TransferDetailContainer = styled.div`
	align-items: center;
	border-bottom: 1px solid #f2f2f2;
	display: flex;
	justify-content: space-between;
	padding: 8px 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
		padding: 8px 0 0;
	}
`;

export const TransferItem = styled.p`
	color: #636172;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

export const TransferData = styled.p`
	color: #140f33;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

interface ShowDatePickerContainerProps {
	y: 'top' | 'bottom';
	x: 'left' | 'right';
}

export const ShowDatePickerContainer = styled.div<ShowDatePickerContainerProps>`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid #e6e6e6;
	border-radius: 6px;
	bottom: ${({ y }) => (y === 'top' ? '50px' : 'inherit')};
	left: ${({ x }) => (x === 'left' ? '0' : 'inherit')};
	padding: 24px 16px;
	position: absolute;
	right: ${({ x }) => (x === 'right' ? '0' : 'inherit')};
	top: ${({ y }) => (y === 'bottom' ? '50px' : 'inherit')};
	z-index: 20;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	.react-datepicker {
		border: 0;
		border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
		padding-bottom: 20px;
		width: 100%;
	}

	.react-datepicker__header {
		background-color: transparent;
		border-bottom: 0;
		padding-top: 0;
	}

	.react-datepicker__month-container {
		display: flex;
		flex-direction: column;
		float: initial;
		font-size: 14px;
		width: 100%;

		.react-datepicker__month {
			@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
				width: 100%;
			}
		}
	}

	.react-datepicker__day-name {
		align-items: center;
		aspect-ratio: 1/1;
		color: ${({ theme }) => theme.colors.principal.orion};
		font-size: 14px;
		font-weight: 500;
		line-height: 21px;
		margin: 0;
		text-align: center;
		width: 40px;

		&::first-letter {
			text-transform: uppercase;
		}

		@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100%;
		}
	}

	.react-datepicker__day--outside-month,
	.react-datepicker__day--disabled {
		color: ${({ theme }) => theme.colors.principal.moon} !important;
	}

	.react-datepicker__month {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
		margin-top: 15px;
		width: min-content;
	}

	.react-datepicker__week,
	.react-datepicker__day-names {
		display: grid;
		grid-template-columns: repeat(7, 1fr);

		@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100%;
		}
	}

	.react-datepicker__day {
		align-items: center;
		aspect-ratio: 1/1;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.principal.vanth};
		display: flex;
		font-size: 14px;
		font-weight: 400;
		justify-content: center;
		line-height: 21px;
		margin: 0;
		position: relative;
		text-align: center;
		transition: background-color 0.2s ease-in-out;
		width: 40px;

		&:not(.react-datepicker__day--disabled):not(.react-datepicker__day--today):hover {
			background-color: ${({ theme }) => theme.colors.principal.ceres};
		}

		@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100%;
		}
	}

	.react-datepicker__day--keyboard-selected {
		background-color: ${({ theme }) => theme.colors.principal.pure};
		border: 1px dashed ${({ theme }) => theme.colors.principal.ceres};
	}

	.react-datepicker__day--today {
		background-color: ${({ theme }) => theme.colors.principal.pure};
		border: 1px solid ${({ theme }) => theme.colors.principal.orion};
		border-radius: 24px;
	}

	.react-datepicker__day--in-range,
	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range {
		border-radius: 0;

		&:not(.react-datepicker__day--disabled) {
			background-color: rgba(20 15 51 / 10%) !important;
			border: 1px solid transparent !important;
			color: ${({ theme }) => theme.colors.principal.orion} !important;
		}
	}

	.react-datepicker__day--range-start,
	.react-datepicker__day--selecting-range-start {
		border-radius: 50%;

		&:not(.react-datepicker__day--range-end):not(.react-datepicker__day--selecting-range-end) {
			border-radius: 24px 0 0 24px !important;
		}
	}

	.react-datepicker__day--range-end,
	.react-datepicker__day--selecting-range-end {
		&:not(.react-datepicker__day--range-start):not(.react-datepicker__day--selecting-range-start) {
			border-radius: 50%;

			&::before {
				aspect-ratio: 1/1;
				background-color: rgba(20 15 51 / 10%);
				border: 0;
				border-radius: 0 24px 24px 0;
				content: '';
				height: 105%;
				position: absolute;
				width: inherit;
			}
		}
	}

	.react-datepicker__day--range-end,
	.react-datepicker__day--selecting-range-end {
		&:not(.react-datepicker__day--in-selecting-range) {
			background-color: ${({ theme }) => theme.colors.principal.orion} !important;
			color: ${({ theme }) => theme.colors.principal.pure} !important;
		}
	}

	.react-datepicker__day--selected {
		&:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
			background-color: ${({ theme }) => theme.colors.principal.orion} !important;
			border-radius: 50%;
			color: ${({ theme }) => theme.colors.principal.pure} !important;
		}
	}
`;

export const StyledList = styled.ul`
	font-weight: 200;
	list-style-type: disc;
	margin: 16px auto;
	padding-left: 16px;
`;

export const StyledListItem = styled.li`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	text-align: left;
`;

export const StyledListLabel = styled.label`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
`;

export const DocumentTemplate = styled.a`
	color: ${({ theme }) => theme.colors.extra.earth};
	text-decoration: underline;
`;

export const TextButton = styled.p`
	font-size: 16px;
	margin-right: 4px;
	margin-top: 4px;
`;
