import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ColorPreview } from '../../../../assets/svg/ColorPreview';
import { ETPayButton } from '../../../../components/button';
import { Input } from '../../../../components/input';
import { Section } from '../../../../components/section';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PersonalizationServices } from '../../../../services/personalization';
import { hexaValidation, validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { ModalState, PersonalizationState } from '../..';
import { ContainerButton, DetailTitle } from '../../style';

interface ColorSectionProps {
	color: string;
	state: PersonalizationState;
	setState: (state: PersonalizationState) => void;
	setModal: Dispatch<SetStateAction<ModalState>>;
}

const ColorSection = ({ color, state, setState, setModal }: ColorSectionProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [disabledButton, setDisabledButton] = useState(true);

	useEffect(() => {
		if (state?.newColor?.toLowerCase() !== color?.toLowerCase()) {
			if (state.newColor && hexaValidation(state.newColor) && state.newColor.length === 6)
				setDisabledButton(false);
			else setDisabledButton(true);
		} else setDisabledButton(true);
	}, [state.newColor]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const regex = new RegExp('^[0-9a-fA-F]+$');
		if (value.length > 6) return;
		if (value.length === 0) return setState({ ...state, newColor: '' });

		if (regex.test(value))
			if (value.length === 6) return setState({ ...state, newColor: value.toUpperCase().trim() });
			else return setState({ ...state, newColor: value.toUpperCase().trim() });
		else return;
	};

	const handleSaveNewColor = async (changeToAllMerchants: boolean) => {
		setModal((prevState) => ({ ...prevState, loading: true, show: true }));
		try {
			if (!state.newColor) return;
			await PersonalizationServices.saveNewColor(
				merchantSelected?.code as string,
				state.newColor as string,
				changeToAllMerchants,
			);
			setModal((prevState) => ({ ...prevState, loading: false, show: false }));
			handleShowAlert({
				message: 'Color actualizado correctamente',
				type: 'success',
			});
			setDisabledButton(true);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		} finally {
			setModal((prevState) => ({ ...prevState, loading: false, show: false }));
		}
	};

	return (
		<Section title="Configuración de color">
			<DetailTitle>Configura el color de la pasarela de pago.</DetailTitle>

			<Input
				label="Color"
				variant="large"
				prefixSymbol="#"
				value={state.newColor || ''}
				rightIcon={<ColorPreview fill={`#${state.newColor || color}`} />}
				onChange={handleChange}
			/>

			{validatePermission('SETTINGS_PERSONALIZATION_PUT') && (
				<ContainerButton>
					<ETPayButton
						color="secondary"
						title="Actualizar"
						disabled={disabledButton}
						onClick={() => setModal({ show: true, type: 'color', action: handleSaveNewColor })}
					/>
				</ContainerButton>
			)}
		</Section>
	);
};

export { ColorSection };
