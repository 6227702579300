import { ChangeEvent } from 'react';

import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { ModalProps } from '../table';
import { Button, ModalContainer, ModalFooter, ModalSubtitle, ModalTitle } from './styles';

interface ModalMultiuse {
	setShowFinalizedModal: (value: boolean) => void;
	handleEndPaymentLink: () => void;
	commentValue: string;
	setCommentValue: (value: string) => void;
	show: boolean;
	modal: ModalProps;
}

const ModalMultiuse = ({
	setShowFinalizedModal,
	handleEndPaymentLink,
	commentValue,
	setCommentValue,
	show,
	modal,
}: ModalMultiuse) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setCommentValue(event.target.value);
	};

	return (
		<>
			<Modal
				title="Finalizar enlace de cobro"
				show={show}
				onClose={() => setShowFinalizedModal(false)}
			>
				{modal.loader ? (
					<Spinner />
				) : (
					<ModalContainer>
						<ModalTitle>¿Estás seguro?</ModalTitle>
						<ModalSubtitle>
							Esta acción desactivará el enlace de cobro y no podrás recibir más cobros.
						</ModalSubtitle>
						<Input
							label="Comentario (opcional)"
							value={commentValue}
							variant="large"
							onChange={handleChange}
						/>
						<ModalFooter>
							<Button color="primary" onClick={() => setShowFinalizedModal(false)}>
								Cancelar
							</Button>
							<Button onClick={handleEndPaymentLink}>Finalizar</Button>
						</ModalFooter>
					</ModalContainer>
				)}
			</Modal>
		</>
	);
};

export { ModalMultiuse };
