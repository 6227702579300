import { ChangeEvent, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { CalendarIcon, LeftAngleIcon, RightAngleIcon } from '../../../../assets/svg';
import { Options, Select } from '../../../../components/form/select';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import {
	ChangeMonthContainer,
	CurrentMonthYear,
	HeaderMonthYearSelected,
} from '../../../../components/table/components/date-filter/styles';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { DirectDebitMxServices } from '../../../../services/direct-debit-mx';
import { getMonthAndYearFormat, transformDate } from '../../../../utils/dates';
import { getInternationalDateFormat } from '../../../../utils/dates';
import { errorFormatter } from '../../../../utils/errors';
import { validateEmail } from '../../../../utils/validations';
import {
	Button,
	HintInputError,
	InputContainer,
	ModalContainer,
	ModalFooter,
	ModalSubtitle,
	ModalTitle,
	ShowDatePickerContainer,
} from './styles';
interface ModalMultiuse {
	setShowFinalizedModal: () => void;
	show: boolean;
}
interface Subscription {
	clientFullName: string;
	clientClabe: string;
	clientEmail: string;
	serviceName: string;
	maxAmount: string;
	frequency: string;
	frequencyLabel: string;
	frequencyValue: number;
	endDate: string;
	endDateFormat: Date;
}

const subscription: Subscription = {
	clientFullName: '',
	clientClabe: '',
	clientEmail: '',
	serviceName: '',
	maxAmount: '',
	frequency: 'monthly',
	frequencyLabel: 'Mensual',
	frequencyValue: 3,
	endDate: getInternationalDateFormat(new Date(new Date().setDate(new Date().getDate() + 31))),
	endDateFormat: new Date(new Date().setDate(new Date().getDate() + 31)),
};

interface OptionBadge {
	value: number;
	label: string;
	customLabel: string;
	show: boolean;
}

interface ClabeError {
	value: boolean;
	label: string;
}

const ModalDomiciliation = ({ setShowFinalizedModal, show }: ModalMultiuse) => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();

	const [state, setState] = useState<Subscription>(subscription);
	const [validate, setValidate] = useState<boolean>(false);
	const [errorClabe, setErrorClabe] = useState<ClabeError>({
		value: false,
		label: 'La CLABE debe tener 18 dígitos',
	});
	const [errorName, setErrorName] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const { handleShowAlert } = useFloatingAlert();
	const optionsBadge: OptionBadge[] = [
		{
			value: 1,
			label: 'Semanal',
			customLabel: 'Semanal',
			show: true,
		},
		{
			value: 2,
			label: 'Quincenal',
			customLabel: 'Quincenal',
			show: true,
		},
		{
			value: 3,
			label: 'Mensual',
			customLabel: 'Mensual',
			show: true,
		},
		{
			value: 4,
			label: 'Bimestral',
			customLabel: 'Bimestral',
			show: true,
		},
		{
			value: 5,
			label: 'Por uso',
			customLabel: 'Por uso',
			show: true,
		},
	];
	const [loader, setLoader] = useState(false);

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const regex = /^[0-9]*[.]?([0-9]{1,2})?$/;
		const conceptRegex = /^[a-zA-Z0-9 ]{0,40}$/;

		if (name === 'serviceName' && !conceptRegex.test(value)) {
			return;
		}
		if (name === 'maxAmount' && !regex.test(value)) {
			return;
		}
		setState({ ...state, [name]: value });
	};

	const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
		const { type } = e;
		const { value } = e.target;
		if (type === 'focus') {
			setIsEmailValid(false);
		}

		if (type === 'blur') {
			setIsEmailValid(value.length > 0 ? !validateEmail(value) : false);
		}
	};

	const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const validCharsRegex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{0,50}$/;
		if (name === 'clientFullName' && !validCharsRegex.test(value)) {
			return;
		}
		setErrorName(validateName(value));
		setState({ ...state, [name]: value });
	};

	const handleChangeClabe = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const conceptRegex = /^[0-9 ]{0,18}$/;
		if (name == 'clientClabe') {
			if (value.length <= 3) {
				setErrorClabe({
					value: value.length == 0 ? false : true,
					label: '',
				});
				setState({ ...state, [name]: value });
				return;
			}
			if (!conceptRegex.test(value)) {
				return;
			}
			if (value.length >= 3 && value.length <= 17) {
				setErrorClabe({
					value: true,
					label: 'La CLABE debe tener 18 dígitos',
				});
				setState({ ...state, [name]: value });
				return;
			}
			if (!calcControlDigit(value)) {
				setErrorClabe({
					value: true,
					label: 'La CLABE es inválida',
				});
			} else {
				setErrorClabe({
					value: false,
					label: 'La CLABE debe tener 18 dígitos',
				});
			}

			setState({ ...state, [name]: value });
		}
	};

	const handleChangeSelect = (option: Options) => {
		const { label } = option;
		switch (label) {
			case 'Semanal':
				setState({ ...state, frequency: 'weekly', frequencyLabel: 'Semanal', frequencyValue: 1 });
				break;
			case 'Quincenal':
				setState({
					...state,
					frequency: 'biweekly',
					frequencyLabel: 'Quincenal',
					frequencyValue: 2,
				});
				break;
			case 'Mensual':
				setState({ ...state, frequency: 'monthly', frequencyLabel: 'Mensual', frequencyValue: 3 });
				break;
			case 'Bimestral':
				setState({
					...state,
					frequency: 'bimonthly',
					frequencyLabel: 'Bimestral',
					frequencyValue: 4,
				});
				break;
			case 'Por uso':
				setState({
					...state,
					frequency: 'by_use',
					frequencyLabel: 'Por uso',
					frequencyValue: 5,
				});
				break;
		}
	};

	async function postRequest() {
		await setLoader(true);
		const info = {
			merchant_code: merchantSelected?.code || '',
			subscriptions: [
				{
					client_full_name: state.clientFullName,
					client_email: state.clientEmail,
					service_name: state.serviceName,
					max_amount: parseInt(state.maxAmount) || 0,
					frequency: state.frequency,
					end_date: state.endDate,
					...(state.clientClabe && { client_clabe: state.clientClabe }),
				},
			],
		};

		try {
			const data = await DirectDebitMxServices.sendSubscriptionByEmail(info);
			if (data.code === 'S_OK') {
				await handleShowAlert({
					type: 'success',
					message: 'Suscripción enviada exitosamente',
				});
			}
			setShowFinalizedModal();
		} catch (e: any) {
			await setLoader(false);
			if (e.malformed) {
				await handleShowAlert({
					type: 'error',
					message: e.malformed[0].errorMsg,
				});
			} else {
				await handleShowAlert({
					type: 'error',
					message: errorFormatter(e.code),
				});
			}
		}
	}

	const calcControlDigit = (clabe: string) => {
		if (clabe.length !== 18) return false;

		const clabeWeights = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];
		const clabeList = clabe.split('');
		const clabeInt = clabeList.map((i: string) => Number(i));
		const weighted = [];

		for (let i = 0; i < 18 - 1; i++) {
			weighted.push((clabeInt[i] * clabeWeights[i]) % 10);
		}

		const summed = weighted.reduce((curr, next) => curr + next) % 10;
		const controlDigit = (10 - summed) % 10;

		return controlDigit === clabeInt[17];
	};

	function validateState(state: Subscription): boolean {
		for (const key in state) {
			if (key != 'clientClabe') {
				if (
					Object.prototype.hasOwnProperty.call(state, key) &&
					state[key as keyof Subscription] === ''
				) {
					return false; // Si encuentra una cadena vacía, devuelve false
				}
			}
		}
		if (
			errorClabe.value ||
			parseFloat(state.maxAmount) > 50000 ||
			errorName ||
			parseFloat(state.maxAmount) < 1 ||
			!validateEmail(state.clientEmail)
		) {
			return false;
		}
		return true; // Si no encuentra ninguna cadena vacía, devuelve true
	}

	const validateName = (value: string) => {
		if (value) {
			const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
			const words = value.trim().split(/\s+/);

			if (words.length >= 2 && nameRegex.test(value)) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	useEffect(() => {
		setValidate(validateState(state));
	}, [state]);

	const onChangeDate = (date: Date, property: 'from' | 'to') => {
		if (property === 'from') {
			setState({ ...state, endDate: getInternationalDateFormat(date), endDateFormat: date });
		}
		setShowCalendar(false);
	};

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && showCalendar) {
				setShowCalendar(false);
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<>
			<Modal title="Nueva suscripción" show={show} onClose={setShowFinalizedModal} type="medium">
				{loader ? (
					<Spinner />
				) : (
					<ModalContainer>
						<ModalTitle>Información de la suscripción</ModalTitle>
						<ModalSubtitle>
							Completa el formulario con la información del cliente y los detalles del servicio a
							domiciliar. Le enviaremos un correo con el enlace para completar el proceso de
							suscripción.
						</ModalSubtitle>
						<InputContainer>
							<div>
								<Input
									label="Nombre del cliente"
									name="clientFullName"
									variant="large"
									value={state.clientFullName}
									onChange={handleChangeName}
								/>
								{errorName && (
									<HintInputError>Debe contener al menos un nombre y un apellido</HintInputError>
								)}
							</div>
							<div>
								<Input
									label="CLABE del cliente (opcional) "
									name="clientClabe"
									variant="large"
									value={state.clientClabe}
									onChange={handleChangeClabe}
								/>
								{errorClabe.value && <HintInputError>{errorClabe.label}</HintInputError>}
							</div>
							<div>
								<Input
									label="Correo electrónico del cliente"
									name="clientEmail"
									variant="large"
									value={state.clientEmail}
									onChange={handleChangeInput}
									onFocus={handleFocus}
									onBlur={handleFocus}
								/>
								{isEmailValid && (
									<HintInputError>Debe ser un correo electrónico válido</HintInputError>
								)}
							</div>
							<div>
								<Input
									label={
										width > 400
											? 'Fecha de vencimiento de la suscripción'
											: 'Fecha de vencimiento...'
									}
									value={transformDate(state.endDateFormat)}
									variant="large"
									rightIcon={<CalendarIcon />}
									disabled={!true}
									rightIconClick={() => setShowCalendar(!showCalendar)}
									onClick={() => setShowCalendar(!showCalendar)}
									onChange={() => setShowCalendar(!showCalendar)}
								/>

								{showCalendar && (
									<ShowDatePickerContainer y="bottom" x="left">
										<ReactDatePicker
											inline
											locale="es"
											selected={state.endDateFormat}
											minDate={new Date(new Date().setDate(new Date().getDate() + 31))}
											onChange={(date) => onChangeDate(date as Date, 'from')}
											renderCustomHeader={(params) => (
												<>
													<HeaderMonthYearSelected>
														<ChangeMonthContainer
															onClick={() => {
																if (!params.prevMonthButtonDisabled) params.decreaseMonth();
															}}
															allowClick={!params.prevMonthButtonDisabled}
														>
															<LeftAngleIcon />
														</ChangeMonthContainer>

														<CurrentMonthYear>
															{getMonthAndYearFormat(params.date)}
														</CurrentMonthYear>

														<ChangeMonthContainer
															onClick={() => {
																if (!params.nextMonthButtonDisabled) params.increaseMonth();
															}}
															allowClick={!params.nextMonthButtonDisabled}
														>
															<RightAngleIcon />
														</ChangeMonthContainer>
													</HeaderMonthYearSelected>
												</>
											)}
										/>
									</ShowDatePickerContainer>
								)}
							</div>
							<div>
								<Input
									label="Servicio a domiciliar"
									name="serviceName"
									variant="large"
									value={state.serviceName}
									onChange={handleChangeInput}
								/>
							</div>
							<div>
								<Select
									titleLabel="Frecuencia del cargo"
									size="large"
									defaultOption={state.frequencyValue}
									options={optionsBadge}
									onChange={handleChangeSelect}
									orientation={'top'}
								></Select>
							</div>
							<div>
								<Input
									label="Monto máximo por cargo"
									name="maxAmount"
									variant="large"
									prefixSymbol="$"
									value={state.maxAmount}
									onChange={handleChangeInput}
								/>
								{parseFloat(state.maxAmount) > 50000 && (
									<HintInputError>El monto máximo debe ser menor a $50,000</HintInputError>
								)}
								{parseFloat(state.maxAmount) < 1 && (
									<HintInputError>El monto deber ser mayor a $1.00</HintInputError>
								)}
							</div>
						</InputContainer>
						<ModalFooter>
							<Button
								color={validate ? 'primary' : 'secundary'}
								onClick={postRequest}
								disabled={!validate}
							>
								Crear suscripción
							</Button>
						</ModalFooter>
					</ModalContainer>
				)}
			</Modal>
		</>
	);
};

export { ModalDomiciliation };
