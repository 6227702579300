import { ChangeEvent, useEffect, useState } from 'react';

import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { RefundsData } from '../../../../models/refund';
import { RefundsServices } from '../../../../services/refund';
import { fullDateFormat } from '../../../../utils/dates';
import { errorFormatter, ErrorType } from '../../../../utils/errors';
import { cleanNumber } from '../../../../utils/numbers';
import { validateEmail } from '../../../../utils/validations';
import { PayoutType } from './components/payout-type';
import {
	Button,
	ButtonsContainer,
	HintInput,
	InputContainer,
	ModalBody,
	ModalContainer,
	PayoutTypeSection,
	Section,
	Subtitle,
	Title,
	TitlesContainer,
	TitleSection,
	TransferData,
	TransferDetail,
	TransferDetailContainer,
	TransferItem,
} from './styles';

interface ModalProps {
	show: boolean;
	onClose: () => void;
	selectedRowData?: RefundsData;
	setShowAlert: (value: boolean) => void;
}

export interface Values {
	amount: string;
	clabe: string;
	email: string;
	concept: string;
	reference: string;
	paymentToken: string;
	trackingKey: string;
	requestDate: string;
	status: string;
	loading: boolean;
	buttonDisabled: boolean;
}

export const defaultValues: Values = {
	amount: '',
	clabe: '',
	email: '',
	concept: '',
	reference: '',
	paymentToken: '',
	trackingKey: '',
	requestDate: '',
	status: '',
	loading: false,
	buttonDisabled: true,
};

export type TypesAllowed = 'full' | 'partial';

const RefundsModal = ({ show, onClose, selectedRowData }: ModalProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [typeSelected, setTypeSelected] = useState<TypesAllowed>('full');
	const [showResumeModal, setShowResumeModal] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [state, setState] = useState<Values>(() =>
		selectedRowData?.amount
			? {
					...defaultValues,
					amount: selectedRowData?.amount.split('$')[1],
					paymentToken: selectedRowData?.paymentId,
					clabe: selectedRowData?.userClabe,
					email: selectedRowData?.uncensoredEmail,
					reference: selectedRowData.reference,
			  }
			: defaultValues,
	);

	const handleCloseModal = () => {
		onClose();
		setShowResumeModal(false);
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		if (name === 'amount') {
			const regex = /^[0-9,.]*$/;
			if (!regex.test(value)) {
				return;
			}

			const parts = value.split('.');
			if (parts.length === 2 && parts[1].length > 2) {
				const truncatedDecimal = parts[1].slice(0, 2);
				const newValue = parts[0] + '.' + truncatedDecimal;
				setState({ ...state, [name]: newValue });
				return;
			}
		}

		setState({ ...state, [name]: value });
	};

	const handleSelectType = (type: 'full' | 'partial') => {
		setTypeSelected(type);
		if (typeSelected === 'partial' && state.amount !== selectedRowData?.amount) {
			setState({ ...state, amount: selectedRowData?.amount.split('$')[1] || '' });
		}
	};

	const handleContinue = async () => {
		setState((state) => ({ ...state, loading: true }));
		try {
			const response = await RefundsServices.getGenerateRefund({
				merchantCode: merchantSelected?.code as string,
				subType: typeSelected,
				paymentToken: state.paymentToken,
				email: state.email || undefined,
				concept: state.concept,
				amount: +cleanNumber(state.amount),
			});

			if (response?.error_refund && response?.error_refund.length > 0) {
				const errorCode = response.error_refund[0].errorCode as ErrorType;
				onClose();

				handleShowAlert({
					message: errorFormatter(errorCode),
					type: 'error',
				});
				return;
			}

			setState((state) => ({
				...state,
				trackingKey: response.resume.trackingKey,
				requestDate: response.resume.requestDate,
				status: response.resume.status,
				loading: false,
			}));
			setShowResumeModal(true);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.error_refund[0].errorCode),
				type: 'error',
			});
			onClose();
		}
	};

	const transferResume = [
		{
			item: 'Monto',
			data: `$${state.amount}`,
		},
		{
			item: 'Clave de rastreo',
			data: state.trackingKey,
		},
		{
			item: 'Concepto',
			data: state.concept,
		},
		{
			item: 'CLABE beneficiario',
			data: state.clabe,
		},
		{
			item: 'Correo electrónico',
			data: state.email,
		},
		{
			item: 'Fecha de solicitud',
			data: state.requestDate && fullDateFormat(state.requestDate),
		},
		{
			item: 'Estatus',
			data: 'En proceso',
		},
	];

	const handleVerifyDisabledButton = () => {
		const amountParse = cleanNumber(state.amount);

		if (isNaN(parseFloat(amountParse))) {
			return true;
		}

		const validatingAmount =
			parseFloat(amountParse) >=
			((selectedRowData && parseFloat(selectedRowData.amountToSearch)) || 0);

		if (
			(validatingAmount && typeSelected === 'partial') ||
			parseFloat(amountParse) <= 0 ||
			state.concept === ''
		)
			return true;
		return false;
	};

	useEffect(() => {
		const buttonDisabled = handleVerifyDisabledButton();
		setState({ ...state, buttonDisabled });
	}, [state.amount, state.concept]);

	const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
		const { type } = e;
		const { value } = e.target;
		if (type === 'focus') {
			setIsEmailValid(false);
		}

		if (type === 'blur') {
			setIsEmailValid(value.length > 0 ? !validateEmail(value) : false);
		}
	};

	return (
		<Modal title="Generar devolución" show={show} onClose={handleCloseModal} type="medium">
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<ModalBody>
						<TitlesContainer>
							{show && !showResumeModal && <Section>DEVOLUCIÓN</Section>}
							<Title>
								{show && !showResumeModal
									? 'Verifica la información'
									: 'Devolución enviada, en proceso de validación'}
							</Title>
							<Subtitle>
								{show && !showResumeModal
									? `Pago ${selectedRowData?.reference}`
									: 'Recibirás una notificación con el estatus en las próximas horas o consulta el estatus en la tabla.'}
							</Subtitle>
						</TitlesContainer>
						{show && !showResumeModal ? (
							<>
								<div>
									<TitleSection>Selecciona el tipo</TitleSection>

									<PayoutTypeSection>
										<PayoutType
											type="full"
											title="Total"
											subtitle="Devuelve el monto total del pago"
											isSelected={typeSelected === 'full'}
											onClick={() => {
												handleSelectType('full');
											}}
										/>
										<PayoutType
											type="partial"
											title="Parcial"
											subtitle="Devuelve solo una parte del pago"
											isSelected={typeSelected === 'partial'}
											onClick={() => {
												handleSelectType('partial');
											}}
										/>
									</PayoutTypeSection>
								</div>
								<InputContainer>
									<div>
										<Input
											label="Monto"
											name="amount"
											value={state.amount}
											variant="large"
											onChange={handleChangeInput}
											disabled={typeSelected === 'full'}
											prefixSymbol="$"
										/>
										<HintInput>Monto maximo {selectedRowData?.amount}</HintInput>
									</div>

									<Input
										label="CLABE beneficiario"
										name="clabe"
										disabled
										value={state.clabe}
										variant="large"
										onChange={handleChangeInput}
									/>

									<div>
										<Input
											label="Correo electrónico del beneficiario (opcional)"
											name="email"
											value={state.email}
											variant="large"
											onChange={handleChangeInput}
											onFocus={handleFocus}
											onBlur={handleFocus}
										/>
										<HintInput></HintInput>
										<HintInput error={isEmailValid}>
											{!isEmailValid ? (
												<>Lo usaremos para notificar el estatus de la devolución</>
											) : (
												<>Debe ser un correo electrónico válido</>
											)}
										</HintInput>
									</div>

									<div>
										<Input
											label="Concepto"
											name="concept"
											value={state.concept}
											variant="large"
											onChange={handleChangeInput}
										/>
										<HintInput>Máximo 40 caracteres</HintInput>
									</div>
								</InputContainer>

								<ButtonsContainer>
									<Button variant="cancel" onClick={onClose}>
										Cancelar
									</Button>
									<Button
										variant="send"
										onClick={handleContinue}
										disabled={state.buttonDisabled || !validateEmail(state.email)}
									>
										Enviar solicitud
									</Button>
								</ButtonsContainer>
							</>
						) : (
							<TransferDetail>
								{transferResume.map(({ item, data }) => (
									<>
										<TransferDetailContainer key={item}>
											<TransferItem>{item}</TransferItem>
											<TransferData>{data}</TransferData>
										</TransferDetailContainer>
									</>
								))}
							</TransferDetail>
						)}
					</ModalBody>
				</ModalContainer>
			)}
		</Modal>
	);
};

export { RefundsModal };
