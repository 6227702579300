import { SelectIcon } from '../../../../../assets/svg/SelectIcon';
import {
	PayoutTypeContainer,
	PayoutTypeSubTitle,
	PayoutTypeTitle,
	SelectedIconContainer,
} from '../styles';

interface PayoutTypeProps {
	isDisabled?: boolean;
	comingSoon?: boolean;
	type?: 'full' | 'partial';
	title: string;
	subtitle: string;
	isSelected?: boolean;
	onClick?: () => void;
}

const PayoutType = ({
	isDisabled = false,
	title,
	subtitle,
	isSelected = false,
	onClick,
}: PayoutTypeProps) => {
	return (
		<PayoutTypeContainer isDisabled={isDisabled} isSelected={isSelected} onClick={onClick}>
			<SelectedIconContainer>{!isSelected ? null : <SelectIcon />}</SelectedIconContainer>
			<PayoutTypeTitle isSelected={isSelected}>{title}</PayoutTypeTitle>
			<PayoutTypeSubTitle isSelected={isSelected}>{subtitle}</PayoutTypeSubTitle>
		</PayoutTypeContainer>
	);
};

export { PayoutType };
