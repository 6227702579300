import { ChangeEvent, useEffect, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Input } from '../../../../components/input';
import { Section } from '../../../../components/section';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { Contact, TypeAllowed } from '../../../../models/contacts';
import { ContactServices } from '../../../../services/contacts';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { validateEmail } from '../../../../utils/validations';
import { ButtonContainer, HintInputError, InputContainer, InputContainerGroup } from '../../style';

interface ContainerProps {
	title: string;
	data?: Contact;
	type: TypeAllowed;
}

const ContainerSection = ({ title, data, type }: ContainerProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [state, setState] = useState<Contact>();
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		if (data) setState(data);
	}, [data]);

	const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (state) setState({ ...state, [name]: value });
	};

	useEffect(() => {
		if (
			JSON.stringify(state) !== JSON.stringify(data) &&
			state?.email &&
			validateEmail(state.email)
		)
			setShowButton(true);
		else setShowButton(false);
	}, [state]);

	const handleSaveContact = async () => {
		try {
			await ContactServices.saveContact({
				merchantCode: merchantSelected?.code as string,
				body: state as Contact,
				type,
			});
			handleShowAlert({
				message: 'Contacto guardado correctamente',
				type: 'success',
			});
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
		const { type } = e;
		const { value } = e.target;
		if (type === 'focus') {
			setIsEmailValid(false);
		}

		if (type === 'blur') {
			setIsEmailValid(value.length > 0 ? !validateEmail(value) : false);
		}
	};

	return (
		<Section title={title}>
			<InputContainer>
				<InputContainerGroup>
					<Input
						label="Nombre(s)"
						name="names"
						variant="large"
						onChange={handleChangeValue}
						value={state?.names}
						defaultValue={data?.names}
					/>

					<Input
						label="Apellidos"
						name="lastnames"
						variant="large"
						onChange={handleChangeValue}
						value={state?.lastnames}
						defaultValue={data?.lastnames}
					/>
				</InputContainerGroup>
			</InputContainer>

			<InputContainer>
				<Input
					label="Cargo"
					name="position"
					variant="large"
					onChange={handleChangeValue}
					value={state?.position}
					defaultValue={data?.position}
				/>
			</InputContainer>

			<InputContainer>
				<Input
					label="Correo electrónico"
					name="email"
					variant="large"
					onChange={handleChangeValue}
					value={state?.email}
					defaultValue={data?.email}
					onFocus={handleFocus}
					onBlur={handleFocus}
				/>
				{isEmailValid && <HintInputError>Debe ser un correo electrónico válido</HintInputError>}
			</InputContainer>

			<InputContainer>
				<Input
					label="Número telefónico"
					name="phone"
					variant="large"
					onChange={handleChangeValue}
					value={state?.phone}
					defaultValue={data?.phone}
				/>
			</InputContainer>

			{validatePermission('SETTINGS_CONTACT_INFO_PUT') && (
				<ButtonContainer>
					<ETPayButton
						title="Guardar"
						color="secondary"
						disabled={!showButton}
						onClick={handleSaveContact}
					/>
				</ButtonContainer>
			)}
		</Section>
	);
};

export { ContainerSection };
