import { useState } from 'react';

import { CheckboxContainer, Input, Label } from './styles';

interface CheckboxProps {
	label?: string;
	name: string;
	checked?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ label, name, checked, onChange }: CheckboxProps) => {
	const [checkedState, setCheckedState] = useState(checked);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedState(event.target.checked);
		onChange(event);
	};

	return (
		<CheckboxContainer>
			<Input
				id={name}
				name={name}
				type="checkbox"
				checked={checkedState}
				onChange={handleInputChange}
			/>
			{label && <Label>{label}</Label>}
		</CheckboxContainer>
	);
};

export { Checkbox };
