import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { Alert } from '../../components/alert';
import { ChartFunnel } from '../../components/graphs/funnel-graph';
import { Section } from '../../components/section';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { RANGE_DATES } from '../../models/home';
import { HomeServices } from '../../services/home';
import { AmountsBarGraph } from './components/amounts-bar-graph';
import { BankList } from './components/bank-list';
import { ConvertionBarGraph } from './components/convertion-bar-graph';
import { HomeHeader } from './components/header';
import { ResumeSection } from './components/resume-section';
import { AlertContainer, ResumeContainer, SectionContainer } from './styles';

const defaultAlert = {
	show: false,
	percentage: 0,
	message: '',
};

export interface Options {
	label: string;
	value: RANGE_DATES;
}

const OPTIONS: Options[] = [
	{
		label: 'Últimas 24 horas',
		value: 'TODAY',
	},
	{
		label: 'Esta semana',
		value: 'THIS_WEEK',
	},
	{
		label: 'Este mes',
		value: 'THIS_MONTH',
	},
	{
		label: 'Últimos 14 días',
		value: 'LAST_14_DAYS',
	},
	{
		label: 'Últimos 30 días',
		value: 'LAST_30_DAYS',
	},
];

const Home = () => {
	const { merchantSelected, handleGetEnterpriseSelected, isEnterpriseSelected } = useMerchant();
	const { colors } = useTheme();
	const navigate = useNavigate();
	const [range, setRange] = useState<RANGE_DATES>('TODAY');
	const [label, setLabel] = useState<string>('');
	const [showAlert, setShowAlert] = useState(defaultAlert);
	const currentPlan = handleGetEnterpriseSelected()?.plan_name;

	const { data: statistics, isLoading, isFetching } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		queryKey: [
			CONSTANTS.services.home.getStatistics,
			merchantSelected?.code,
			isEnterpriseSelected,
			range,
		],
		queryFn: () =>
			HomeServices.getStatistics(merchantSelected?.code as string, isEnterpriseSelected, range),
		onSuccess: ({ notice_trx }) => {
			const { count_month_trx, max_month_trx } = notice_trx;
			const percentage = max_month_trx ? (count_month_trx * 100) / max_month_trx : 0;

			let message = '';
			if (percentage >= 90) {
				message = `Estás al ${
					100 - Math.round(percentage)
				}% de alcanzar el número de pagos mensuales incluidos en tu plan.`;
			}
			if (percentage === 100) {
				message = 'Has alcanzado el límite de número de pagos mensuales incluidos en tu plan.';
			}
			if (percentage > 100) {
				message = 'Has sobrepasado el número de pagos mensuales incluidos en tu plan.';
			}

			if (max_month_trx) {
				setShowAlert({
					show: percentage >= 90,
					percentage: percentage === 100 ? 100 : 100 - percentage,
					message,
				});
			}
		},
	});

	const { data: graphics } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.home.getGraphData, merchantSelected?.code, isEnterpriseSelected],
		queryFn: () => HomeServices.getGraphics(merchantSelected?.code as string, isEnterpriseSelected),
	});

	const goToUpgradePlan = () => {
		navigate('/subscription/manage-plan');
	};

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.home.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	const handleSelectRange = (range: RANGE_DATES) => {
		setRange(range);
	};

	return (
		<ViewWrapper title="Inicio" isLoading={isLoading || isFetching}>
			<>
				{showAlert.show && currentPlan !== 'Basic' && currentPlan !== 'Enterprise' && (
					<AlertContainer>
						<Alert
							show={showAlert.show}
							type="warning"
							action={goToUpgradePlan}
							actionText="Mejora tu plan"
							message={showAlert.message}
						/>
					</AlertContainer>
				)}

				<HomeHeader
					collectedValue={statistics?.head?.amountCollected.value}
					successfulPayments={statistics?.head?.success.value}
					currency={statistics?.merchantInfo?.currency}
					merchantInfo={statistics?.merchantInfo}
					handleSelectRange={handleSelectRange}
					options={OPTIONS}
					value={range}
					label={label}
					setLabel={setLabel}
				/>

				<ResumeContainer>
					<ResumeSection data={statistics?.head} />
				</ResumeContainer>

				<Section title="Evolución del monto recaudado">
					<AmountsBarGraph
						data={graphics?.amounts}
						barColor={colors.principal.alamak}
						tooltipColor={colors.principal['light-alamak']}
					/>
				</Section>

				<SectionContainer>
					<Section title="Cobros por banco">
						<BankList data={statistics?.banksSuccessPayment} loading={isLoading} />
					</Section>

					<Section title="Estatus de los cobros">
						<ChartFunnel data={statistics?.statusData} loading={isLoading} />
					</Section>
				</SectionContainer>

				<Section title="Evolución de la conversión">
					<ConvertionBarGraph
						data={graphics?.conversion}
						barColor="#433F5C"
						tooltipColor="#E8E7EB"
					/>
				</Section>
			</>
		</ViewWrapper>
	);
};

export { Home };
