import styled from 'styled-components';

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const Content = styled.div`
	background: #fff;
	border: 1px solid #e6e6e6;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	padding: 24px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		display: none;
	}
`;

export const MovilMessageDisplay = styled.div`
	display: none;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		align-items: center;
		background: ${({ theme }) => theme.colors.principal.sirius};
		border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
		border-radius: 12px;
		display: flex;
		justify-content: center;
		padding: 12px 16px;
		width: 100%;
	}
`;

export const CardGroup = styled.div`
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(3, 1fr); /* Tres columnas iguales */

	@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
		grid-template-columns: repeat(
			auto-fit,
			minmax(300px, 1fr)
		); /* Adaptar a dos columnas cuando la pantalla es menor */
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		grid-template-columns: 1fr; /* Una columna cuando la pantalla es muy pequeña */
	}
`;

export const Card = styled.div`
	align-items: center;
	border: solid 1px #f2f2f2;
	border-radius: 12px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: auto;
	justify-content: flex-start;
	min-width: 320px;
	padding: 16px;
	width: 100%;
`;

export const Circle = styled.div`
	align-items: center;
	background: #f3f6ff;
	border-radius: 25px;
	color: #426dd8;
	display: flex;
	height: 25px;
	justify-content: center;
	padding: 10px;
	width: 25px;
`;

export const TitleCard = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 18px;
	font-weight: 500;
	line-height: 27.18px;
	margin-bottom: 6px;
	margin-top: 4px;
	text-align: center;
	text-align: left;
`;

export const TextCard = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 16px;
	text-align: center;
`;

export const FileContainer = styled.div`
	background: #f3f6ff;
	border-radius: 12px;
	display: flex;
	gap: 10px;
	padding: 14px 16px 5px;
	width: 100%;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	width: 100%;
`;

export const NameFile = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: 14.81px;
`;

export const SizeFile = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: 14.81px;
`;

export const DocumentDone = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	margin-top: 8px;
`;

export const ContainerButton = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 26px;
	max-width: 30%;
	min-width: 320px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
		justify-content: flex-start;
	}
`;

type ButtonVariant = 'earth' | 'pure';
interface ButtonProps {
	variant?: ButtonVariant;
}

export const Button = styled.button<ButtonProps>`
	align-items: center;
	background-color: ${({ theme, variant }) =>
		variant === 'earth'
			? theme.colors.extra.earth
			: variant === 'pure' && theme.colors.principal.pure};
	border: ${({ theme, variant }) =>
		variant === 'pure' ? `1px solid ${theme.colors.extra.earth}` : 'none'};
	border-radius: 8px;
	color: ${({ theme, variant }) =>
		variant === 'earth'
			? theme.colors.principal.pure
			: variant === 'pure' && theme.colors.extra.earth};
	cursor: pointer;
	display: flex;
	font-size: 16px;
	gap: 4px;
	justify-content: center;
	margin-top: 16px;
	outline: none;
	padding: 8px 14px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}

	svg {
		margin-right: 12px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const TextButton = styled.p`
	align-items: end;
	display: flex;
	font-size: 16px;
	height: 22px;
	justify-content: center;
`;
