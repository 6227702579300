import { SVGProps } from 'react';

export const BancoFalabellaIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="42"
		height="42"
		viewBox="0 0 42 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_616_16137)">
			<rect width="42" height="42" rx="6" fill="white" />
		</g>
		<rect
			x="0.25"
			y="0.25"
			width="41.5"
			height="41.5"
			rx="5.75"
			stroke="#F2F2F2"
			strokeWidth="0.5"
		/>
		<g clipPath="url(#clip1_616_16137)">
			<path
				d="M30.5752 18.9627C29.8961 18.51 29.1491 18.1704 28.3569 17.9214C28.1079 17.8309 26.8855 17.6045 26.8402 17.6045C26.5686 17.5819 26.2969 17.5593 26.0479 17.5593C24.486 17.5593 21.9055 18.0799 19.1891 18.8269C19.6871 19.3023 22.0639 21.996 24.8256 21.996C27.8815 21.996 29.6019 20.4115 30.5752 18.9627Z"
				fill="#0C4531"
			/>
			<path
				d="M30.5753 18.9627C28.6059 21.8149 24.803 22.7204 21.8149 20.9095H21.7923C21.0906 20.4794 20.1851 19.755 19.1891 18.8495C13.7111 20.2983 7.55396 23.0826 7.55396 26.229C7.55396 30.9827 21.2943 34.8083 26.0253 34.8083C30.779 34.8083 34.6046 30.9601 34.6046 26.2064C34.6046 23.2863 33.0653 20.5246 30.5753 18.9627Z"
				fill="#007935"
			/>
			<path
				d="M26.048 17.6724C26.3196 17.6724 26.5913 17.6951 26.8403 17.7177C26.8855 17.7177 26.9082 17.7177 26.9535 17.7403C27.2251 17.763 27.4741 17.8082 27.7457 17.8535C28.7417 18.0572 29.7151 18.4421 30.5753 18.9853C30.6432 18.8948 30.7111 18.8043 30.7564 18.7137C32.6352 15.6351 31.6618 11.6059 28.5833 9.74966C25.4821 7.84819 15.024 4.92809 13.1452 8.00665C11.6965 10.3835 15.8163 15.6804 19.1891 18.8269C21.9055 18.1025 24.4634 17.6724 26.0027 17.6724H26.048Z"
				fill="#C2D100"
			/>
		</g>
		<defs>
			<clipPath id="clip0_616_16137">
				<rect width="42" height="42" rx="6" fill="white" />
			</clipPath>
			<clipPath id="clip1_616_16137">
				<rect width="33.9547" height="34" fill="white" transform="translate(4 4)" />
			</clipPath>
		</defs>
	</svg>
);
