import styled from 'styled-components';

export const CheckboxContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	gap: 10px;
`;

export const Input = styled.input`
	cursor: pointer;
	height: 16px;
	width: 16px;
`;

export const Label = styled.label`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 17px;
	margin-top: 2px;
`;
