import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Card = styled.div`
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	padding: 4px 26px;
	width: 100%;
`;

export const Title = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 18px;
	font-weight: 400;
	letter-spacing: -0.014px;
`;

export const StatusContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 11px;
`;

export const Status = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 94%;
`;

export const Spinner = styled.div`
	animation: ${spin} 1s linear infinite;
	animation-delay: -0.5s;
	border: 3px solid ${({ theme }) => theme.colors.principal.ceres};
	border-left-color: ${({ theme }) => theme.colors.principal.vanth};
	border-radius: 100%;
	height: 20px;
	width: 20px;
`;
