import { useEffect, useState } from 'react';

import { PhoneMockCL, PhoneMockMX } from '../../../../assets/svg';
import { Section } from '../../../../components/section';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PreviewContainer } from '../../style';

interface PreviewSectionProps {
	color: string;
	logoUrl: string;
	file?: File;
}

const PreviewSection = ({ color, logoUrl, file }: PreviewSectionProps) => {
	const { merchantSelected } = useMerchant();
	const [fileBase64, setFileBase64] = useState<string | null>(null);

	useEffect(() => {
		if (!file) return;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			setFileBase64(reader.result as string);
		};
	}, [file]);

	return (
		<Section title="Previsualización">
			<PreviewContainer>
				{merchantSelected?.country === 'CL' ? (
					<PhoneMockCL fill={color ? `#${color}` : '#141685'} logo={logoUrl} />
				) : (
					<PhoneMockMX fill={color ? `#${color}` : '#141685'} logo={fileBase64 || logoUrl} />
				)}
			</PreviewContainer>
		</Section>
	);
};

export { PreviewSection };
