import styled from 'styled-components';

import { Input } from '../../../input';

export const FiltersContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 54px;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 0 20px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
		width: 100%;
	}
`;

interface SectionProps {
	focus?: boolean;
}

export const Section = styled.div<SectionProps>`
	display: flex;
	gap: 6px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	${({ theme, focus }) =>
		focus &&
		`div:first-child > svg > path{
			stroke: ${theme.colors.principal.orion};
		}

		circle:last-child {
			stroke: ${theme.colors.principal.orion};
		}
	`}
`;

export const FilterSection = styled.div`
	align-items: center;
	display: flex;
	padding: 0;
	width: 100%;
`;

interface FilterValueProps {
	readonly statusWithCircle?: boolean;
	readonly active?: boolean;
}

export const FilterValue = styled.span<FilterValueProps>`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-radius: 20px;
	color: ${({ theme }) => theme.colors.principal.orion};
	flex-direction: row;
	font-size: 12px;
	justify-content: center;
	line-height: 12px;
	padding: 6px 8px;
	position: relative;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	${({ statusWithCircle }) =>
		statusWithCircle &&
		`&::after {
		background-color: #ee7d7a;
		border-radius: 50%;
		content: '';
		height: 10px;
		position: absolute;
		top: -3px;
		right: -3px;
		width: 10px;
	}`}
`;

interface FilterProps {
	readonly active: boolean;
	readonly allowClick?: boolean;
}

export const Filter = styled.div<FilterProps>`
	align-items: center;
	background-color: ${({ active, theme }) =>
		active ? theme.colors.principal.pure : theme.colors.principal.eris};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-left: none;
	color: ${({ theme, allowClick }) => theme.colors.principal[allowClick ? 'orion' : 'moon']};
	cursor: ${({ active, allowClick }) => (allowClick && !active ? 'pointer' : 'default')};
	display: flex;
	font-size: 14px;
	font-weight: 400;
	gap: 8px;
	line-height: 21px;
	max-height: 38px;
	padding: 8px 14px;
	pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
	transition: background-color 0.2s ease-in-out;
	width: max-content;

	&:first-of-type {
		border-bottom-left-radius: 8px;
		border-left: 1px solid ${({ theme }) => theme.colors.principal.ceres};
		border-top-left-radius: 8px;
	}

	&:last-of-type {
		border-bottom-right-radius: 8px;
		border-top-right-radius: 8px;
	}

	${FilterValue} {
		background-color: ${({ active, theme }) => active && theme.colors.principal.vanth};
		color: ${({ active, theme, allowClick }) =>
			active ? theme.colors.principal.pure : theme.colors.principal[allowClick ? 'orion' : 'moon']};
	}
`;

export const FilterInput = styled(Input)`
	min-width: 101px;

	&:focus {
		border-color: ${({ theme }) => theme.colors.principal.orion};

		path {
			stroke: ${({ theme }) => theme.colors.principal.orion};
		}
	}
`;
