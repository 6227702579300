import { ChangeEvent, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { ConciliationServices } from '../../../../services/conciliation';
import { errorFormatter } from '../../../../utils/errors';
import Resume from './components/resume';
import {
	Button,
	ButtonContainer,
	InputContainer,
	ModalBody,
	Subtitle,
	Title,
	Type,
} from './styles';

interface DefaultSelected {
	transferId: string;
	amount: string;
	clabe: string;
	trackingKey: string;
	name: string;
}

interface ModalConciliation {
	show: boolean;
	data: DefaultSelected;
	onClose: () => void;
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
}

interface ResumeData {
	item: string;
	data: string;
}

interface State {
	amount: string;
	beneficiary: string;
	clabe: string;
	concept: string;
	loading: boolean;
	requestDate: string;
	status: string;
	trackingKey: string;
}

interface CustomDateTimeFormatOptions {
	day: 'numeric' | '2-digit';
	month: 'numeric' | '2-digit' | 'short' | 'long';
	year?: 'numeric' | '2-digit';
	hour: 'numeric' | '2-digit';
	minute: 'numeric' | '2-digit';
	hour12: boolean;
}

const defaultState: State = {
	amount: '',
	beneficiary: '',
	clabe: '',
	concept: '',
	loading: false,
	requestDate: '',
	status: '',
	trackingKey: '',
};

const ModalConciliation = ({ show, data, onClose, refetch }: ModalConciliation) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [step, setStep] = useState(1);
	const [state, setState] = useState<State>(defaultState);

	const formatDateAndTime = () => {
		const todayDate = new Date();
		const options: CustomDateTimeFormatOptions = {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: false,
		};

		const formattedDate = todayDate.toLocaleString('es-CL', options);
		const formatDateAndTime = formattedDate.split(',');

		const date = formatDateAndTime[0].trim();
		const time = formatDateAndTime[1].trim();

		return date + ' ' + time;
	};

	const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
	
		if (alphanumericRegex.test(value) || value === "") {
			if (state) {
				setState({ ...state, [name]: value });
			}
		}
	};
	

	const handleContinue = async () => {
		setState({ ...state, loading: true });
		try {
			const response = await ConciliationServices.generateRefund({
				merchantCode: merchantSelected?.code as string,
				transferId: data.transferId,
				clabe: data.clabe,
				concept: state.concept,
			});
			setState({
				...state,
				clabe: data.clabe,
				amount: response.resume.amount,
				trackingKey: response.resume.trackingKey,
				requestDate: response.resume.requestDate,
				status: response.resume.status,
				concept: response.resume.concept,
			});
			setStep(2);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setState((state) => ({ ...state, loading: false }));
		}
	};

	const formatResumeData = () => {
		const resumeData: ResumeData[] = [
			{
				item: 'Monto',
				data: `$${state.amount}`,
			},
			{
				item: 'Clave de rastreo',
				data: state.trackingKey,
			},
			{
				item: 'Concepto',
				data: state.concept,
			},
			{
				item: 'CLABE beneficiario',
				data: state.clabe,
			},
			{
				item: 'Fecha de solicitud',
				data: formatDateAndTime(),
			},
			{
				item: 'Estatus',
				data: state.status,
			},
		];
		return resumeData;
	};

	return (
		<Modal
			title="Generar devolución"
			show={show}
			onClose={() => {
				onClose();
				refetch();
			}}
			type="medium"
		>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalBody>
					{step === 1 && <Type>DEVOLUCIÓN</Type>}
					<Title>
						{(step === 1 && 'Verifica la información') ||
							(step === 2 && 'Solicitud en proceso de validación')}
					</Title>
					<Subtitle>
						{(step === 1 && `Clave de rastreo: ${data.trackingKey}`) ||
							(step === 2 &&
								'Recibirás una notificación con el estatus en las próximas horas o consulta el estatus en la tabla.')}
					</Subtitle>
					{step === 1 && (
						<>
							<InputContainer>
								<Input
									readOnly
									label="Monto a devolver"
									disabled
									value={data.amount}
									variant="large"
								></Input>
								<Input
									readOnly
									label="CLABE beneficiario"
									disabled
									value={data.clabe}
									variant="large"
								></Input>
								<Input
									name="concept"
									label="Concepto (máx. 40 caracteres)"
									value={state.concept}
									variant="large"
									maxLength={40}
									onChange={handleChangeValue}
								></Input>
							</InputContainer>
							<ButtonContainer>
								<Button onClick={() => onClose()}>Cancelar</Button>
								<Button
									onClick={() => handleContinue()}
									disabled={state.concept === ''}
									conciliationColor
								>
									Enviar solicitud
								</Button>
							</ButtonContainer>
						</>
					)}
					{step === 2 && formatResumeData && <Resume resume={formatResumeData()} />}
				</ModalBody>
			)}
		</Modal>
	);
};

export { ModalConciliation };
