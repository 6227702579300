interface IconProps {
	active?: boolean;
	color?: string;
}

export const PLIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="path-1-outside-1_10998_4167"
			maskUnits="userSpaceOnUse"
			x="12.1458"
			y="7.55713"
			width="9"
			height="14"
			fill="black"
		>
			<rect fill="white" x="12.1458" y="7.55713" width="9" height="14" />
			<path d="M14.1863 19.6668C15.5016 19.6668 16.7631 19.1342 17.6932 18.1862C18.6232 17.2383 19.1458 15.9526 19.1458 14.6119C19.1458 13.2713 18.6232 11.9856 17.6932 11.0377C16.7631 10.0897 15.5016 9.55713 14.1863 9.55713L14.1863 14.6119L14.1863 19.6668Z" />
		</mask>
		<path
			d="M14.1863 19.6668C15.5016 19.6668 16.7631 19.1342 17.6932 18.1862C18.6232 17.2383 19.1458 15.9526 19.1458 14.6119C19.1458 13.2713 18.6232 11.9856 17.6932 11.0377C16.7631 10.0897 15.5016 9.55713 14.1863 9.55713L14.1863 14.6119L14.1863 19.6668Z"
			fill={active ? color : 'white'}
		/>
		<path
			d="M14.1863 19.6668C15.5016 19.6668 16.7631 19.1342 17.6932 18.1862C18.6232 17.2383 19.1458 15.9526 19.1458 14.6119C19.1458 13.2713 18.6232 11.9856 17.6932 11.0377C16.7631 10.0897 15.5016 9.55713 14.1863 9.55713L14.1863 14.6119L14.1863 19.6668Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="3"
			mask="url(#path-1-outside-1_10998_4167)"
		/>
		<mask
			id="path-2-outside-2_10998_4167"
			maskUnits="userSpaceOnUse"
			x="2.54797"
			y="3.47852"
			width="9"
			height="14"
			fill="black"
		>
			<rect fill="white" x="2.54797" y="3.47852" width="9" height="14" />
			<path d="M9.52511 5.33281C8.20509 5.33281 6.93914 5.86727 6.00574 6.81862C5.07235 7.76996 4.54797 9.06026 4.54797 10.4057C4.54797 11.7511 5.07235 13.0414 6.00574 13.9927C6.93914 14.9441 8.20509 15.4785 9.52511 15.4785L9.52511 10.4057L9.52511 5.33281Z" />
		</mask>
		<path
			d="M9.52511 5.33281C8.20509 5.33281 6.93914 5.86727 6.00574 6.81862C5.07235 7.76996 4.54797 9.06026 4.54797 10.4057C4.54797 11.7511 5.07235 13.0414 6.00574 13.9927C6.93914 14.9441 8.20509 15.4785 9.52511 15.4785L9.52511 10.4057L9.52511 5.33281Z"
			fill={active ? color : 'white'}
		/>
		<path
			d="M9.52511 5.33281C8.20509 5.33281 6.93914 5.86727 6.00574 6.81862C5.07235 7.76996 4.54797 9.06026 4.54797 10.4057C4.54797 11.7511 5.07235 13.0414 6.00574 13.9927C6.93914 14.9441 8.20509 15.4785 9.52511 15.4785L9.52511 10.4057L9.52511 5.33281Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="3"
			mask="url(#path-2-outside-2_10998_4167)"
		/>
	</svg>
);
