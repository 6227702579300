import { Status } from '../../../../models/common-payments';
import { ColumnInterface } from '../../../../models/table';
import {
	Table,
	TableBody,
	TableContainer,
	TableData,
	TableHead,
	TableHeader,
	TableRow,
} from './styles';

interface TableDetailProps {
	data: PaymentsMultiuse[];
	columns?: ColumnInterface<PaymentsMultiuse>[];
}

export interface PaymentsMultiuse {
	reference: string;
	productType: string;
	amount: string;
	bankName: string;
	status: Status;
	contactInfo: string;
	email: string;
	paymentId: string;
	paymentMethod: string;
	clabe: string;
	userClabe: string;
	createdatDate: string;
	createdatTime: string;
	validatedatDate: string;
	validatedatTime: string;
	expireDate: string;
	expireTime: string;
	lastStep: string;
	idx: number;
	urlCEP?: string;
}

const TableDetail = ({ data, columns }: TableDetailProps) => (
	<TableContainer>
		<Table>
			<TableHead>
				<TableRow>
					{columns?.map((column: ColumnInterface<PaymentsMultiuse>, i) => (
						<TableHeader key={i}>{column.title}</TableHeader>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map((item: PaymentsMultiuse, i) => (
					<TableRow key={i}>
						{columns?.map((column: ColumnInterface<PaymentsMultiuse>, j) => (
							<TableData key={j}>{column.cellRender(item)}</TableData>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
);

export { TableDetail };
