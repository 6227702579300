import { useState } from 'react';

import { ArrowIcon } from '../../../../assets/svg';
import { ColumnInterface, ETPayTableHeaderInterface } from '../../../../models/table';
import { Checkbox } from '../../../form/checkbox';
import { SortByContainer, TableHead, TableHeadTitle, TableHeadTitles } from './styles';

interface OderByInterface {
	property?: string;
	order?: 'asc' | 'desc';
}

const defaultOrderBy: OderByInterface = {
	property: undefined,
	order: undefined,
};

const ETPayTableHeader = <Entity,>({
	dataCount,
	columns,
	filters,
	onFilterChange,
	hasCheckbox,
	checkedSelect,
	isLoading,
	handleCheckboxClicked,
}: ETPayTableHeaderInterface<Entity>) => {
	const [orderBy, setOrderBy] = useState<OderByInterface>(defaultOrderBy);

	const handleOrderBy = (column: ColumnInterface<Entity>) => {
		const order = orderBy.order === undefined || orderBy.order === 'desc' ? 'asc' : 'desc';
		setOrderBy({
			property: column.propertyName,
			order,
		});

		if (onFilterChange) {
			onFilterChange({
				...filters,
				orderBy: order,
				key: column.propertyName,
			});
		}
	};

	const handleHover = (column: ColumnInterface<Entity>) => {
		if (!filters?.key && !filters?.orderBy) {
			setOrderBy({
				...defaultOrderBy,
				property: column.propertyName,
			});
		}
	};

	return (
		<TableHead>
			<TableHeadTitles>
				{hasCheckbox && (
					<TableHeadTitle hasCheckbox={hasCheckbox}>
						<Checkbox
							name="all"
							onChange={handleCheckboxClicked}
							checked={!isLoading && checkedSelect.length === dataCount}
						/>
					</TableHeadTitle>
				)}
				{columns.map(
					(column, i) =>
						!column.hide && (
							<TableHeadTitle key={i} alignContent={column.align ?? 'left'} hasCheckbox={false}>
								<SortByContainer
									defaultSort={
										filters?.key === undefined &&
										(column.propertyName === 'createdatDate' ||
											column.propertyName === 'suscribeatDate' ||
											column.propertyName === 'paymentAtDate')
									}
									allowSort={!!column.sortField}
									isActive={filters?.key === column.propertyName}
									ordered={orderBy.property === column.propertyName && orderBy.order === 'asc'}
									onMouseEnter={() => handleHover(column)}
									onClick={() => column.sortField && handleOrderBy(column)}
								>
									{column.title}
									{column.sortField && <ArrowIcon />}
								</SortByContainer>
							</TableHeadTitle>
						),
				)}
			</TableHeadTitles>
		</TableHead>
	);
};

export { ETPayTableHeader };
