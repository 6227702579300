import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Icon, Label, LabelContainer, Month, Tooltip, TooltipInfo, Value } from './styles';

interface Option {
	label: string;
	value: string;
	color?: string;
	month?: string;
}

interface TooltipComposedChartProps extends TooltipProps<ValueType, NameType> {
	options: Option[];
}

const TooltipComposedChart = ({ active, payload, options }: TooltipComposedChartProps) => {
	return active && payload && payload.length ? (
		<Tooltip>
			{options?.map(({ label, value, color, month }) => (
				<>
					<Month>{month}</Month>
					<TooltipInfo key={value}>
						<LabelContainer>
							<Icon color={color} />
							<Label>{label}</Label>
						</LabelContainer>
						<div>
							<Value>{value}</Value>
						</div>
					</TooltipInfo>
				</>
			))}
		</Tooltip>
	) : null;
};

export default TooltipComposedChart;
