import { useState } from 'react';

import { RadioButtonCheck } from '../../../../../assets/svg/RadioButtonCheck';
import { RadioButtonEmpty } from '../../../../../assets/svg/RadioButtonEmpty';
import { Modal } from '../../../../../components/modal';
import {
	ButtonBack,
	ButtonReject,
	ContainerBody,
	ContainerFooter,
	FeaturesList,
	FeaturesListItem,
	ModalContainer,
	ModalSubTitle,
	OptionContainer,
} from './styles';

interface ModalOutboundProp {
	onClose: () => void;
	onRejected: () => void;
	selectReject: (val: number) => void;
}

const options = [
	{ label: 'El comprobante es inválido', value: 1, show: true },
	{ label: 'El abono está hecho en una cuenta incorrecta', value: 2, show: true },
	{ label: 'El monto del abono no coincide con el comprobante', value: 3, show: true },
];

const ModalRejectDeposit = ({ onClose, onRejected, selectReject }: ModalOutboundProp) => {
	const [selected, setSelected] = useState<number>();

	return (
		<Modal title="Pago de salida" show type="medium" onClose={onClose}>
			<ModalContainer>
				<ModalSubTitle>Selecciona la razón</ModalSubTitle>

				<ContainerBody>
					<FeaturesList>
						{options.map((option) => (
							<OptionContainer
								key={option.label}
								onClick={() => {
									setSelected(option.value);
									selectReject(option.value);
								}}
							>
								{option.value !== selected ? (
									<RadioButtonEmpty width={24} height={24} />
								) : (
									<RadioButtonCheck width={24} height={24} />
								)}
								<FeaturesListItem key={option.label as string}>{option.label}</FeaturesListItem>
							</OptionContainer>
						))}
					</FeaturesList>
				</ContainerBody>

				<ContainerFooter>
					<ButtonBack onClick={onClose}>Volver</ButtonBack>
					<ButtonReject
						onClick={() => {
							if (selected) {
								onClose();
								onRejected();
							}
						}}
					>
						Rechazar
					</ButtonReject>
				</ContainerFooter>
			</ModalContainer>
		</Modal>
	);
};

export default ModalRejectDeposit;
