import styled from 'styled-components';

import { TypeAllowed } from '.';

export const ModalContainer = styled.div`
	background-color: rgba(0 0 0 / 50%);
	height: 100%;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
`;

interface ModalProps {
	readonly show: boolean;
	readonly type: TypeAllowed;
}

export const ModalContent = styled.div<ModalProps>`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-radius: 12px;
	box-shadow: 2px 0 34px rgba(88 87 87 / 14%);
	display: ${({ show }) => (show ? 'block' : 'none')};
	left: 50%;
	max-height: 90vh;
	opacity: ${({ show }) => (show ? 1 : 0)};
	overflow-y: hidden;
	padding: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: ${({ type }) => (type === 'small' ? 408 : type === 'medium' ? 520 : 720)}px;
	z-index: 110;

	@media (max-width: 768px) {
		width: 90%;
	}
`;

export const ModalHeader = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.sirius};
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	display: flex;
	justify-content: center;
	padding-bottom: 18px;
	padding-top: 18px;
	position: relative;
`;

export const ModalTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
`;

export const TimesContainer = styled.div`
	cursor: pointer;
	position: absolute;
	right: 16px;
	top: 20px;
`;

export const ModalBody = styled.div`
	border-bottom: 1px solid transparent;
	display: flex;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;
`;

export const ModalBodyWrapper = styled.div`
	max-height: 80vh;
	overflow-y: auto;
	padding: 24px 24px 32px;

	&:nth-of-type(1) {
		background-color: transparent;
		border-bottom-left-radius: 12px;
		width: 100%;
	}

	&:nth-of-type(2) {
		background-color: ${({ theme }) => theme.colors.principal.air};
		border-bottom-right-radius: 12px;
		min-width: 315px;
	}
`;
