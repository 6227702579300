export const PlanCheckIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="8.25" fill="white" stroke="#646174" strokeWidth="1.5" />
		<path
			d="M15 10.2041L11 14.2041L9 12.2041"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="square"
		/>
	</svg>
);
