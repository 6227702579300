import { EqualIcon, LessIcon, MoreIcon } from '../../assets/svg';
import { ResumeCardType as AnalyticsResumeCardType } from '../../models/analytics';
import { ResumeCardType as HomeResumeCardType } from '../../models/home';
import {
	Card,
	CardBody,
	CardFooterInformation,
	CardTitle,
	Description,
	Text,
	TextContainer,
} from './styles';

interface ResumeCardProps {
	type: HomeResumeCardType | AnalyticsResumeCardType;
	value?: string;
	icon?: number;
	resume?: string;
	prefix?: string;
	suffix?: string;
	text?: string;
}

export type translateType = 'less' | 'more' | 'equal';

const ResumeCard = ({
	type,
	value,
	icon,
	resume,
	prefix,
	suffix,
	text = 'que el periodo anterior',
}: ResumeCardProps) => {
	const typeMap = new Map<number, translateType>([
		[60, 'less'],
		[61, 'equal'],
		[62, 'more'],
	]);

	return (
		<Card>
			<CardTitle>{type}</CardTitle>
			<CardBody withoutValue={!value}>
				{!value && prefix}
				{value ?? '0'}
				{!value && suffix}
			</CardBody>

			<CardFooterInformation>
				{icon === 60 && <LessIcon />}
				{icon === 61 && <EqualIcon />}
				{icon === 62 && <MoreIcon />}

				<TextContainer>
					<Text type={typeMap.get(icon as number) || 'equal'}>{resume}</Text>
					{!!value && <Description>{text}</Description>}
				</TextContainer>
			</CardFooterInformation>
		</Card>
	);
};

export { ResumeCard };
