import styled from 'styled-components';

interface SelectWrapperProps {
	readonly withError: boolean;
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
	margin-bottom: ${({ withError }) => (withError ? '16px' : '0')};
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

interface SelectProps {
	readonly orientation: 'top' | 'bottom';
	readonly sizeHeight?: 'min' | 'max';
}

export const SelectOptions = styled.ul<SelectProps>`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: inherit;
	bottom: ${({ orientation }) => (orientation === 'top' ? '100%' : 'auto')};
	left: 0;
	list-style: none;
	margin: 0;
	max-height: ${({ sizeHeight }) => (sizeHeight === 'min' ? '220px' : 'auto')};
	opacity: 0;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	right: 0;
	top: ${({ orientation }) => (orientation === 'top' ? 'auto' : '100%')};
	transform: scaleY(0);
	transform-origin: ${({ orientation }) => (orientation === 'top' ? 'bottom' : 'top')};
	user-select: none;
	visibility: hidden;
	z-index: 10;
`;

export const SelectLabel = styled.div`
	color: ${({ theme }) => theme.colors.principal.vanth};
	padding-right: 15px;
`;

interface SelectOptionProps {
	readonly isSelected: boolean;
}

export const SelectOption = styled.li<SelectOptionProps>`
	background-color: ${({ theme, isSelected }) => isSelected && theme.colors.principal.ceres};
	border-bottom: 1px solid rgba(255 255 255 / 20%);
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
	overflow: hidden;
	transition: transform 0.5s ease;

	&:first-child {
		border-bottom: none;
	}

	&:hover,
	&:focus {
		background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.principal.ceres};

		&:first-of-type {
			border-radius: 8px 8px 0 0;
		}

		&:last-of-type {
			border-radius: 0 0 8px 8px;
		}
	}
`;

interface SelectContainerProps {
	readonly isOpen: boolean;
	readonly size: 'medium' | 'large';
	readonly hasBorder: boolean;
	readonly hasError: boolean;
	readonly disabled?: boolean;
}

export const SelectContainer = styled.div<SelectContainerProps>`
	align-items: center;
	background-color: ${({ theme, disabled }) =>
		disabled ? theme.colors.principal.eris : 'transparent'};
	border: ${({ theme, hasBorder, hasError }) =>
		hasBorder
			? `1px solid ${hasError ? theme.colors.extra.sword : theme.colors.principal.ceres}`
			: 'none'};
	border-radius: 8px;
	box-sizing: border-box;
	color: #444;
	cursor: pointer;
	display: flex;
	height: ${({ size }) => (size === 'medium' ? '38px' : '48px')};
	padding: ${({ hasBorder }) => hasBorder && '8px 14px'};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	position: relative;
	user-select: none;
	width: 100%;

	&::before {
		border-color: #a0a0a0 transparent;
		border-style: solid;
		border-width: ${({ isOpen }) => (isOpen ? '0 6px 6px' : '6px 6px 0')};
		content: '';
		margin-top: -2px;
		position: absolute;
		right: 10px;
		top: 50%;
		width: 0;
	}

	${SelectOptions} {
		${({ isOpen }) =>
			isOpen &&
			`
			opacity: 1;
      transform: scaleY(1);
      visibility: visible;
    `}

		${SelectOption} {
			min-height: ${({ size }) => (size === 'medium' ? '40px' : '50px')};
			padding: ${({ size }) => (size === 'medium' ? '10px 14px' : '12px')};
		}
	}

	${SelectLabel} {
		font-size: ${({ size }) => (size === 'medium' ? '14px' : '16px')};
	}
`;

export const SelectContainerAndTitle = styled.div<SelectContainerProps>`
	align-items: flex-start;
	background-color: ${({ theme, disabled }) =>
		disabled ? theme.colors.principal.eris : 'transparent'};
	border: ${({ theme, hasBorder, hasError }) =>
		hasBorder
			? `1px solid ${hasError ? theme.colors.extra.sword : theme.colors.principal.ceres}`
			: 'none'};
	border-radius: 8px;
	box-sizing: border-box;
	color: #444;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: ${({ size }) => (size === 'medium' ? '38px' : '48px')};
	justify-content: flex-start;
	padding: ${({ hasBorder }) => hasBorder && '8px 14px'};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	position: relative;
	user-select: none;
	width: 100%;

	&::before {
		border-color: #a0a0a0 transparent;
		border-style: solid;
		border-width: ${({ isOpen }) => (isOpen ? '0 6px 6px' : '6px 6px 0')};
		content: '';
		margin-top: -2px;
		position: absolute;
		right: 10px;
		top: 50%;
		width: 0;
	}

	${SelectOptions} {
		${({ isOpen }) =>
			isOpen &&
			`
			opacity: 1;
      transform: scaleY(1);
      visibility: visible;
    `}

		${SelectOption} {
			min-height: ${({ size }) => (size === 'medium' ? '40px' : '50px')};
			padding: ${({ size }) => (size === 'medium' ? '10px 14px' : '12px')};
		}
	}

	${SelectLabel} {
		font-size: ${({ size }) => (size === 'medium' ? '14px' : '16px')};
	}
`;

export const TitleText = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	line-height: 14px;
	padding-right: 5px;
`;

export const PrefixText = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 21px;
	padding-right: 5px;
`;

export const ErrorMsg = styled.span`
	color: #ff7576;
	display: block;
	font-size: 12px;
	line-height: 1;
	margin-top: 6px;
	text-align: left;
`;
