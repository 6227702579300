import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 50%;
`;

export const ContainerWrapper = styled.div`
	display: flex;
	gap: 16px;
	height: 100%;
	width: 100%;
	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 144px;
	}
`;

export const Item = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-left: 14px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
	}

	&::after {
		border-radius: 8px;
		bottom: 0;
		content: '';
		height: 8px;
		left: 0;
		margin: auto;
		position: absolute;
		top: 0;
		width: 8px;
	}
`;

interface ItemProps {
	readonly bg?: string;
}

export const List = styled.div<ItemProps>`
	width: 100%;

	${Item} {
		&::after {
			background-color: ${({ bg }) => bg};
		}
	}
`;

export const Value = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
`;

export const EmptyMessageWrapper = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
`;

export const EmptyMessage = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;
