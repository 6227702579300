import styled from 'styled-components';

export const ContainerFilterTime = styled.div`
	position: relative;
`;

export const FilterTimeLabel = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.eris};
	border-right: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	display: flex;
	font-size: 14px;
	gap: 6px;
	line-height: 21px;
	padding: 8px 12px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: space-between;
		text-align: center;
		width: 100%;
	}
`;

export const FilterTimeInput = styled.div`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
	line-height: 21px;
	padding: 8px 12px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-align: center;
		width: 100%;
	}
`;

export const ContainerShow = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 6px;
	display: flex;
`;

export const SelectOptions = styled.ul`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	left: 0;
	list-style: none;
	margin: 0;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	top: 100%;
	transform-origin: top;
	user-select: none;
	width: max-content;
	z-index: 10;
`;

interface SelectOptionProps {
	readonly isSelected: boolean;
}

export const SelectOption = styled.li<SelectOptionProps>`
	background-color: ${({ theme, isSelected }) => isSelected && theme.colors.principal.ceres};
	border-bottom: 1px solid rgba(255 255 255 / 20%);
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
	display: ${({ isSelected }) => isSelected && 'none'};
	overflow: hidden;
	padding: 10px 12px;
	transition: transform 0.5s ease;

	&:first-child {
		border-bottom: none;
	}

	&:hover,
	&:focus {
		background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.principal.ceres};

		&:first-of-type {
			border-radius: 8px 8px 0 0;
		}

		&:last-of-type {
			border-radius: 0 0 8px 8px;
		}
	}
`;
