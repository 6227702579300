import { SVGProps } from 'react';

export const DotsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		cursor="pointer"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx="11" cy="11" r="2" transform="rotate(90 11 11)" fill="#646174" />
		<circle cx="11" cy="4" r="2" transform="rotate(90 11 4)" fill="#646174" />
		<circle cx="11" cy="18" r="2" transform="rotate(90 11 18)" fill="#646174" />
	</svg>
);
