import { SVGProps } from 'react';

export const BancoLaPolarIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="43"
		height="42"
		viewBox="0 0 43 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_9056_44668)">
			<g clipPath="url(#clip1_9056_44668)">
				<path d="M43.0769 -1H-0.0769043V42.672H43.0769V-1Z" fill="#E41D1E" />
				<path
					d="M33.8808 36.4932C33.2833 36.4932 32.6993 36.3139 32.2025 35.978C31.7058 35.6421 31.3186 35.1646 31.09 34.606C30.8614 34.0474 30.8016 33.4327 30.9182 32.8397C31.0348 32.2467 31.3225 31.702 31.7451 31.2745C32.1676 30.847 32.7058 30.5559 33.2918 30.4381C33.8778 30.3202 34.4852 30.3808 35.0371 30.6123C35.5891 30.8437 36.0608 31.2356 36.3927 31.7384C36.7245 32.2412 36.9016 32.8323 36.9014 33.4369C36.9005 34.2473 36.582 35.0242 36.0157 35.5972C35.4494 36.1702 34.6816 36.4924 33.8808 36.4932Z"
					fill="white"
				/>
				<path
					d="M35.649 6.33219H35.848C35.9522 6.33219 36.0318 6.29191 36.0318 6.17108C36.0318 6.05913 35.9557 6.0159 35.851 6.0159H35.649V6.33219ZM36.023 6.86526L35.8012 6.54601H35.649V6.86823H35.4149V5.80207H35.8527C36.1044 5.80207 36.2753 5.92942 36.2753 6.17108C36.2753 6.34403 36.1899 6.4619 36.0552 6.50277L36.3151 6.86823L36.023 6.86526ZM35.8205 7.24671C36.0596 7.24562 36.2885 7.14882 36.4573 6.97746C36.6261 6.80611 36.721 6.57413 36.7213 6.33219C36.7153 6.09453 36.6178 5.86864 36.4496 5.70269C36.2814 5.53674 36.0557 5.44386 35.8208 5.44386C35.5859 5.44386 35.3603 5.53674 35.1921 5.70269C35.0238 5.86864 34.9263 6.09453 34.9204 6.33219C34.9207 6.57403 35.0155 6.80592 35.1841 6.97726C35.3528 7.14859 35.5816 7.24546 35.8205 7.24671ZM35.8205 5.24708C36.0324 5.24825 36.2391 5.31288 36.4146 5.43283C36.5902 5.55277 36.7267 5.72265 36.807 5.92102C36.8872 6.1194 36.9076 6.33739 36.8656 6.54749C36.8236 6.75759 36.721 6.95038 36.5708 7.10154C36.4206 7.25271 36.2296 7.35547 36.0217 7.39686C35.8139 7.43825 35.5986 7.41642 35.403 7.33412C35.2074 7.25182 35.0403 7.11274 34.9227 6.93442C34.8052 6.75611 34.7424 6.54655 34.7424 6.33219C34.7426 6.18923 34.7706 6.04772 34.8249 5.91575C34.8792 5.78378 34.9587 5.66396 35.0589 5.56315C35.1591 5.46234 35.2779 5.38253 35.4086 5.32829C35.5393 5.27406 35.6793 5.24646 35.8205 5.24708Z"
					fill="white"
				/>
				<path d="M6.07263 36.4493V5.22339H12.2111V30.2111H24.597V36.4493H6.07263Z" fill="white" />
				<path
					d="M27.6657 5.25H18.4135V11.4627H27.6657C28.4796 11.474 29.2563 11.8092 29.8279 12.3956C30.3995 12.982 30.7199 13.7726 30.7199 14.5963C30.7199 15.42 30.3995 16.2106 29.8279 16.797C29.2563 17.3835 28.4796 17.7186 27.6657 17.7299H18.3948V23.942H27.6657C32.7577 23.942 36.901 19.7497 36.901 14.596C36.901 9.44235 32.7577 5.25 27.6657 5.25Z"
					fill="white"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_9056_44668">
				<rect width="43" height="42" rx="6" fill="white" />
			</clipPath>
			<clipPath id="clip1_9056_44668">
				<rect width="43.1538" height="44" fill="white" transform="translate(-0.0769043 -1)" />
			</clipPath>
		</defs>
	</svg>
);
