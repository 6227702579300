import { SVGProps } from 'react';

export const PcFactory = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="95"
			height="21"
			viewBox="0 0 95 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_7727_52096)">
				<path
					d="M33.5207 3.75854H25.711C24.7898 3.75854 24.043 4.5021 24.043 5.41924V16.1007C24.043 16.2026 24.1264 16.2856 24.2287 16.2856H26.7384C26.8408 16.2856 26.9242 16.2026 26.9242 16.1007V11.9904C26.9242 11.8281 27.0569 11.696 27.2199 11.696H31.9133C32.4895 11.696 32.9559 11.2318 32.9559 10.6581V9.4125C32.9559 9.3106 32.8725 9.22759 32.7701 9.22759H27.2237C27.0607 9.22759 26.928 9.09546 26.928 8.93316V6.55155C26.928 6.38172 27.0645 6.24585 27.2351 6.24585H32.6564C33.2364 6.24585 33.7065 5.77783 33.7065 5.20036V3.94349C33.7065 3.84158 33.6231 3.75854 33.5207 3.75854Z"
					fill="white"
				/>
				<path
					d="M38.954 6.68384C37.3767 6.68384 36.0877 7.23115 35.3182 8.22756C35.1855 8.4012 35.0907 8.60877 35.102 8.82768C35.1172 9.26175 35.4167 9.59387 35.811 9.67315L37.1644 9.94865C37.2516 9.96754 37.3426 9.91846 37.3691 9.83921C37.6156 9.08057 38.2525 8.74086 39.3141 8.8126C40.0152 8.85788 40.5651 9.45046 40.5651 10.1638V10.6205H37.9606C35.538 10.6205 34.0898 11.7264 34.0898 13.5758C34.0898 15.395 35.4433 16.5689 37.536 16.5689C38.6392 16.5689 39.5757 16.2443 40.3224 15.6102L40.5613 15.4064V16.1122C40.5613 16.2103 40.6407 16.2896 40.7394 16.2896C41.1337 16.2858 42.0323 16.2858 42.0361 16.2858C42.6958 16.2858 43.2302 15.7536 43.2302 15.0969V10.345C43.2302 8.05396 41.6304 6.68384 38.954 6.68384ZM40.5613 12.6398C40.5613 13.8438 39.4618 14.5647 38.4003 14.5647C37.4942 14.5647 36.9104 14.1193 36.9104 13.4248C36.9104 12.7417 37.4715 12.285 38.3057 12.285H40.5651V12.6398H40.5613Z"
					fill="white"
				/>
				<path
					d="M62.7433 13.8926C62.6977 13.87 62.6446 13.87 62.5956 13.8889C62.22 14.0361 61.7991 14.1267 61.4695 14.1267C60.5177 14.1267 60.0365 13.6096 60.0365 12.5867V9.40873C60.0365 9.24644 60.169 9.11434 60.332 9.11434H61.9849C62.4778 9.11434 62.8797 8.71425 62.8797 8.2236V7.15545C62.8797 7.05355 62.7965 6.9705 62.6939 6.9705H60.0365V3.94349C60.0365 3.84158 59.9529 3.75854 59.8508 3.75854H58.3646C57.7505 3.75854 57.2537 4.25299 57.2537 4.86443V6.9705H55.692C55.5894 6.9705 55.5059 7.05355 55.5059 7.15545V8.92562C55.5059 9.02752 55.5894 9.11057 55.692 9.11057H56.992C57.1363 9.11057 57.2575 9.22759 57.2575 9.37477V12.8887C57.2575 14.9118 58.9028 16.5649 60.9347 16.5687C61.7763 16.5725 62.474 16.4026 63.2777 16.0025C63.3575 15.9648 63.3954 15.8742 63.3688 15.7874L62.8417 14.0021C62.8227 13.953 62.7889 13.9153 62.7433 13.8926Z"
					fill="white"
				/>
				<path
					d="M69.2641 6.72522C66.3032 6.72522 64.0703 8.8351 64.0703 11.6281C64.0703 14.4362 66.3032 16.5499 69.2641 16.5499C72.1908 16.5499 74.4008 14.4324 74.4008 11.6281C74.4008 8.83133 72.1946 6.72522 69.2641 6.72522ZM69.2641 14.2588C68.0396 14.2588 66.7354 13.3379 66.7354 11.6281C66.7354 9.98249 68.0206 8.99739 69.2641 8.99739C70.4771 8.99739 71.732 9.98249 71.732 11.6281C71.732 13.2737 70.4809 14.2588 69.2641 14.2588Z"
					fill="white"
				/>
				<path
					d="M82.9138 6.85737C82.8345 6.84606 82.6867 6.83472 82.5386 6.83472C81.174 6.83472 80.101 7.47258 79.3392 8.73322L79.0699 9.18236C79.0699 9.18236 79.0699 7.67641 79.0699 7.14799C79.0699 7.04986 78.9901 6.96681 78.888 6.96681H77.9552C77.0342 6.96681 76.2871 7.71038 76.2871 8.62755V16.0932C76.2871 16.1951 76.3707 16.2781 76.4728 16.2781H78.888C78.9901 16.2781 79.0737 16.1951 79.0737 16.0932V13.421C79.0737 10.9375 80.2412 9.68059 82.5424 9.68059H82.7813C82.88 9.68059 82.9594 9.60511 82.9632 9.50698C82.986 8.82381 83.0734 7.09895 83.0734 7.04609C83.0696 6.97816 83.0278 6.87626 82.9138 6.85737Z"
					fill="white"
				/>
				<path
					d="M54.4734 13.0436L52.271 12.5681C52.1798 12.5492 52.0929 12.602 52.0587 12.6926C51.691 13.6589 50.9326 14.1911 49.9204 14.1911C48.476 14.1911 47.4677 13.1418 47.4677 11.6358C47.4677 10.1299 48.476 9.08057 49.9204 9.08057C50.944 9.08057 51.691 9.6203 52.0169 10.6016C52.0435 10.6847 52.1419 10.7338 52.2368 10.7111L54.4697 10.1789C54.519 10.1676 54.5646 10.1336 54.5912 10.0883C54.614 10.0468 54.6216 9.99774 54.6064 9.95247C53.9846 7.97095 52.1419 6.69519 49.9166 6.69519C46.9975 6.69519 44.7988 8.82015 44.7988 11.6396C44.7988 14.459 46.9975 16.584 49.9166 16.584C52.1571 16.584 54.0412 15.2516 54.6102 13.2701C54.6216 13.2248 54.6178 13.1795 54.5912 13.138C54.5722 13.089 54.5266 13.055 54.4734 13.0436Z"
					fill="white"
				/>
				<path
					d="M10.8881 3.49452C10.6417 2.89062 10.3043 2.35466 9.87584 1.89042C9.44747 1.4224 8.93185 1.04497 8.33287 0.750568C7.73765 0.459944 7.08561 0.312744 6.37668 0.312744H1.61123C0.792351 0.312744 0.128906 0.973254 0.128906 1.78851V3.18125C0.128906 3.50584 0.394284 3.77005 0.720318 3.77005H5.61845C6.46008 3.77005 7.21072 4.3966 7.25618 5.23072C7.30168 6.12146 6.59274 6.85745 5.70565 6.85745H1.61502C0.792351 6.85745 0.128906 7.52174 0.128906 8.33701V10.3148V11.9377C0.128906 12.9228 1.34964 13.3871 2.0093 12.6549L4.10958 10.3185H6.47903C7.18797 10.3185 7.83625 10.1789 8.42767 9.90335C9.01905 9.62404 9.52327 9.25418 9.94029 8.78615C10.3536 8.31812 10.6796 7.78216 10.9108 7.18204C11.1421 6.57814 11.2596 5.95917 11.2596 5.32131C11.2558 4.7023 11.1345 4.09841 10.8881 3.49452Z"
					fill="white"
				/>
				<path
					d="M8.12152 16.569C8.36793 17.1729 8.70535 17.7089 9.13376 18.1731C9.56214 18.6411 10.0777 19.0186 10.6767 19.3129C11.2758 19.6036 11.9278 19.7508 12.6368 19.7508H17.4022C18.2211 19.7508 18.8845 19.0903 18.8845 18.275V16.8823C18.8845 16.5577 18.6191 16.2935 18.2931 16.2935H13.395C12.5534 16.2935 11.8027 15.6669 11.7572 14.8328C11.7117 13.9421 12.4207 13.2061 13.3078 13.2061H17.4022C18.2211 13.2061 18.8883 12.5456 18.8883 11.7265V9.74876V8.1258C18.8883 7.1407 17.6675 6.67644 17.0079 7.40866L14.9 9.74876H12.5306C11.8216 9.74876 11.1734 9.8884 10.582 10.1639C9.99055 10.4432 9.48633 10.8131 9.06931 11.2811C8.65609 11.7492 8.33003 12.2851 8.09877 12.8853C7.86751 13.4891 7.75 14.1081 7.75 14.746C7.7538 15.3612 7.87511 15.9651 8.12152 16.569Z"
					fill="white"
				/>
				<path
					d="M85.5317 20.6226L87.4918 16.1085L83.6589 7.19723C83.6095 7.08022 83.6931 6.94812 83.8218 6.94812H86.4189C86.4983 6.94812 86.5742 6.99717 86.6008 7.07268L88.6783 12.1869C88.7695 12.4096 89.0878 12.4134 89.1827 12.1944L91.4042 7.07645C91.4383 6.99717 91.5139 6.94812 91.6013 6.94812H94.168C94.2739 6.94812 94.3461 7.05379 94.3043 7.15192L88.4622 20.7698C88.4356 20.834 88.3711 20.8755 88.3031 20.8755H85.6984C85.5659 20.8755 85.4785 20.7434 85.5317 20.6226Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7727_52096">
					<rect
						width="94.1902"
						height="20.5626"
						fill="white"
						transform="translate(0.128906 0.312744)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
