import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DirectDebitIcon } from '../../assets/svg/DirectDebitIcon';
import { Breadcrumb } from '../../components/breadcrumb';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { DirectDebitRenditionServices } from '../../services/direct-debit-rendition';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { DirectDebitTable } from './components/table';
import { HeadContainer, TableFilterHeader, TitlePage } from './styles';

const DirectDebitRendition = () => {
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);

	const today = new Date();
	const pastDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

	const {
		data: table,
		isFetching,
		isFetched,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.directDebit.rendition.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			DirectDebitRenditionServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from || pastDate,
						to: filters?.dateRange?.to || today,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	const dictionary = new Map([
		['direct-debit', 'Débito Directo'],
		['rendition', 'Rendición'],
	]);

	return (
		<ViewWrapper title="Débito Directo">
			<HeadContainer>
				<TitlePage>
					<DirectDebitIcon />
				</TitlePage>
				<Breadcrumb dictionary={dictionary} />
			</HeadContainer>

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			<DirectDebitTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				stateList={table?.typeOfStatus || []}
				pastDate={pastDate}
				today={today}
			/>
		</ViewWrapper>
	);
};

export { DirectDebitRendition };
