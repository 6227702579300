import { SVGProps } from 'react';

export const DocumentIcon = ({ ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		{...rest}
	>
		<rect
			x="5"
			y="2"
			width="14"
			height="18"
			rx="3"
			stroke="#636172"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path d="M9 8H15" stroke="#636172" strokeWidth="2" strokeLinecap="round" />
		<path d="M9 12H15" stroke="#636172" strokeWidth="2" strokeLinecap="round" />
		<path d="M9 16H12" stroke="#636172" strokeWidth="2" strokeLinecap="round" />
	</svg>
);
