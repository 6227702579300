import { Dispatch, SetStateAction, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { EyeIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { Payment, PaymentStatus } from '../../../../models/common-payments';
import { ColumnInterface, Filters, Pagination, RowInterface } from '../../../../models/table';
import { ConciliationServices } from '../../../../services/conciliation';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import { ModalConciliation } from '../modal';
import {
	AmountContainer,
	Container,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	Section,
	Title,
	Value,
	VoucherButton,
} from './styles';

interface ConciliationTableProps {
	data: Payment[];
	isLoading?: boolean;
	stateList: PaymentStatus[];
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	dateCustomToFilter: () => { pastDate: Date; today: Date };
	thisMonth: boolean;
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

export interface DefaultSelected {
	transferId: string;
	amount: string;
	clabe: string;
	trackingKey: string;
	name: string;
}

const defaultSelected: DefaultSelected = {
	transferId: '',
	amount: '',
	clabe: '',
	trackingKey: '',
	name: '',
};

const ConciliationTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	dateCustomToFilter,
	thisMonth,
	refetch,
}: ConciliationTableProps) => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const [modalRefund, setModalRefund] = useState<boolean>(false);
	const [selectedRowData, setSelectedRowData] = useState<DefaultSelected>(defaultSelected);
	const { handleShowAlert } = useFloatingAlert();

	const handleClick = (rowData: Payment) => () => {
		setModalRefund(true);
		setSelectedRowData({
			amount: rowData.amount,
			clabe: rowData.destinationClabe,
			trackingKey: rowData.trackingKey,
			name: rowData.clientName,
			transferId: rowData.transferId || '',
		});
	};

	const columns: ColumnInterface<Payment>[] = [
		{
			title: 'Clave de rastreo',
			propertyName: 'trackingKey',
			cellRender: ({ trackingKey }) => <>{trackingKey}</>,
		},
		{
			title: 'Nombre',
			propertyName: 'clientName',
			align: 'left',
			cellRender: ({ clientName }) => <>{clientName}</>,
		},
		{
			sortField: true,
			title: 'Monto',
			align: 'right',
			propertyName: 'amount',
			cellRender: ({ amount }) => <AmountContainer>{amount}</AmountContainer>,
		},
		{
			sortField: true,
			title: 'Banco',
			propertyName: 'bankName',
			cellRender: ({ bankName }) => <>{bankName}</>,
		},
		{
			title: 'Motivo',
			propertyName: 'descriptionError',
			cellRender: ({ descriptionError }) => <>{descriptionError}</>,
		},
		{
			sortField: true,
			title: 'Fecha',
			propertyName: 'createdat',
			cellRender: ({ createdatDate, createdatTime }) => (
				<DateAndTime date={createdatDate} time={createdatTime} />
			),
		},
		{
			title: '',
			align: 'center',
			propertyName: 'status',
			cellRender: (rowData: Payment) => (
				<>
					<Container>
						{rowData.status === 'return' ? (
							<>
								{merchantSelected?.role_id === 2 && (
									<ETPayButton title="Devolver" onClick={handleClick(rowData)} />
								)}
							</>
						) : (
							<>
								{rowData.status === 'error' ? (
									<>
										{merchantSelected?.role_id === 2 && (
											<ETPayButton title="Devolver" onClick={handleClick(rowData)} />
										)}
									</>
								) : (
									<Status status={rowData.status} />
								)}
							</>
						)}
					</Container>
				</>
			),
		},
		{
			title: 'Comprobante',
			align: 'center',
			hide: merchantSelected?.country !== 'MX',
			propertyName: 'urlCEP',
			cellRender: ({ urlCEP }) => (
				<Container>
					{urlCEP && (
						<VoucherButton onClick={() => window.open(urlCEP)}>
							<EyeIcon />
						</VoucherButton>
					)}
				</Container>
			),
		},
	];

	const detailSection: RowInterface<Payment> = (data) => {
		return (
			<DetailsContainer>
				<Section>
					<Content>
						<Title>CLABE ordenante</Title>
						<Value>{data.destinationClabe}</Value>
					</Content>
					<Content>
						<Title>Referencia numérica</Title>
						<Value>{data.transferId}</Value>
					</Content>
				</Section>
			</DetailsContainer>
		);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });
		try {
			const response = await ConciliationServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from || dateCustomToFilter().pastDate,
					to: filters?.dateRange?.to || dateCustomToFilter().today,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
		setModalRefund(false);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			{modalRefund && (
				<ModalConciliation
					show={modalRefund}
					data={selectedRowData}
					onClose={handleCloseModal}
					refetch={refetch}
				/>
			)}

			<ETPayTable<Payment>
				title="Transferencias no conciliadas"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={detailSection}
				hasFilters
				hasDateFilter
				allowExport={validatePermission('CONCILIATION_EXPORT')}
				stateList={width >= 768 ? fillStatesWithZeros({ states: ['success'], stateList }) : []}
				statusContent={width >= 768 ? [['success', 'Todos']] : []}
				allowCustomFilter
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				today={dateCustomToFilter().today}
				pastDate={dateCustomToFilter().pastDate}
				thisMonth={thisMonth}
			/>
		</>
	);
};

export { ConciliationTable };
