import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface SidebarProps {
	readonly $hasRoutes?: boolean;
}

export const SidebarContainer = styled.ul<SidebarProps>`
	padding: ${({ $hasRoutes }) => ($hasRoutes ? '0' : '16px 12px')};
	width: 100%;
`;

export const LinkContainer = styled.li`
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-bottom: 8px;

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.colors.principal.orion};
	}
`;

interface LinkItemsProps {
	readonly $active: boolean;
	readonly $colorIcon?: string;
	readonly $name: string;
}

export const LinkItem = styled(Link)<LinkItemsProps>`
	align-items: center;
	background-color: ${({ $active, theme }) =>
		$active ? theme.colors.principal.eris : 'transparent'};
	border-radius: 6px;
	color: ${({ $active, theme }) =>
		$active ? theme.colors.principal.orion : theme.colors.principal.vanth};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 10px 0 10px 16px;
	transition-duration: 0.2s;
	transition-property: color, padding;
	transition-timing-function: ease-in-out;

	&:hover {
		color: ${({ theme }) => theme.colors.principal.orion};

		svg {
			rect,
			path,
			circle {
				fill: ${({ $name, $colorIcon }) =>
					$name === 'analytics' || $name === 'payouts-cl' || $name === 'outbound' || $name === 'refunds'
						? 'transparent'
						: $colorIcon};
				stroke: ${({ $colorIcon }) => $colorIcon};
			}
		}
	}

	svg {
		margin-right: 6px;

		rect,
		path,
		circle {
			transition-duration: 0.2s;
			transition-property: fill, stroke;
			transition-timing-function: ease-in-out;
		}
	}
`;

export const ButtonItem = styled.button`
	align-items: center;
	background-color: transparent;
	border: 0;
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	display: flex;
	font-family: ${({ theme }) => theme.fonts.stack};
	font-size: 16px;
	font-weight: 400;
	justify-content: flex-start;
	outline: 0;
	padding: 16px 0 8px 16px;
	transition: color 0.2s ease-in-out;

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.colors.principal.orion};
	}
`;

export const OnlyMobileContainer = styled.div`
	display: none;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		display: block;
	}
`;

export const TitleSection = styled.span`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 11px;
	font-weight: 500;
	justify-content: flex-start;
	padding: 10px 0 10px 16px;
`;

export const TitleInteractiveSection = styled.ul`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 11px;
	font-weight: 500;
	justify-content: flex-start;
	padding: 10px 0 10px 16px;
`;

export const BackIconContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	margin-right: 8px;
`;

export const ModuleContainer = styled.ul`
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
	padding: 0;
	width: 100%;
`;

interface ModuleTitleProps {
	readonly $active: boolean;
	readonly $colorIcon?: string;
	readonly $name: string;
}

export const ModuleTitle = styled.p<ModuleTitleProps>`
	align-items: center;
	background-color: transparent;
	border-radius: 4px;
	color: ${({ $active, theme }) =>
		$active ? theme.colors.principal.orion : theme.colors.principal.vanth};
	display: flex;
	flex-direction: row;
	font-size: 16px;
	justify-content: flex-start;
	padding: 8px 0 8px 16px;
	transition-duration: 0.2s;
	transition-property: color, padding;
	transition-timing-function: ease-in-out;

	&:hover {
		color: ${({ theme }) => theme.colors.principal.orion};

		svg {
			rect,
			path,
			circle {
				fill: ${({ $name, $colorIcon }) =>
					$name === 'outbound' || $name === 'refunds' ? 'transparent' : $colorIcon};
				stroke: ${({ $colorIcon }) => $colorIcon};
			}
		}
	}

	svg {
		margin-right: 8px;

		rect,
		path,
		circle {
			transition-duration: 0.2s;
			transition-property: fill, stroke;
			transition-timing-function: ease-in-out;
		}
	}
`;

interface ModuleItemsProps {
	readonly $active: boolean;
}

export const ModuleItem = styled(Link)<ModuleItemsProps>`
	align-items: center;
	background-color: ${({ $active, theme }) =>
		$active ? theme.colors.principal.eris : 'transparent'};
	border-radius: 4px;
	color: ${({ $active, theme }) =>
		$active ? theme.colors.principal.orion : theme.colors.principal.vanth};
	display: flex;
	padding: 9px 0 9px 46px;
	position: relative;
	width: 100%;

	&::before {
		background-color: #b8b8b8;
		content: '';
		height: 22px;
		left: 26px;
		padding-bottom: 17px;
		position: absolute;
		top: -10px;
		width: 0.5px;
		z-index: 1;
	}
`;
