import { CommonTableParams, ResponseExport } from '../models/common-payments';
import {
	BalanceResponse,
	DepositAmountParams,
	DepositAmountResponse,
	DepositVoucherParams,
	ExportDataBeneficiaryParams,
	ExportDataParams,
	GenerateOutbound,
	ImageDepositPayout,
	MerchantCodeParam,
	PayloadDepositUpdate,
	ResponseGenerateOutbound,
	ResponseOtp,
	ResponseTableBeneficiary,
	ResponseTableDeposit,
	ResponseTableOutbound,
	ResponseUpdateDepositPayout,
	SaveBeneficiaryParams,
	SendBatchOutbound,
	SendOutboundParams,
	SendOutboundResponse,
	StatsResponse,
	StatsTablePayout,
} from '../models/payouts';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getAllOutbound = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableOutbound>('/v1/payouts/outbound/table?', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportDataOutbound = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/payouts/outbound/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const generateOutbound = async ({
	merchantCode,
	amount,
	messageToAddressee,
	rut,
	name,
	email,
	bankSBIFNumber,
	bankAccount,
	reference,
}: GenerateOutbound) => {
	try {
		const params = { merchant_code: merchantCode };

		const outbound_transfers = [
			{
				amount,
				messageToAddressee,
				rut,
				name,
				email,
				bankSBIFNumber,
				bankAccount,
				reference,
			},
		];

		const body = {
			outbound_transfers,
		};

		const { data } = await client.post<ResponseGenerateOutbound>(
			'/v1/payouts/cl/otp/outbound',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendCodeOutbound = async ({ merchantCode, code, token }: SendOutboundParams) => {
	try {
		const params = { merchant_code: merchantCode };

		const body = {
			otp: code,
			payoutsToken: token,
		};

		const { data } = await client.post<SendOutboundResponse>(
			'/v1/payouts/cl/generate/outbound',
			body,
			{
				params,
				headers: { 'X-Pyouts-Token': token },
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getCodeOutbound = async ({ merchantCode }: MerchantCodeParam) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseOtp>('/v1/payouts/cl/otp/csv/outbound', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getBalance = async ({ merchantCode }: MerchantCodeParam) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<BalanceResponse>('v1/payouts/cl/balance/outbound', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getStats = async ({ merchantCode }: MerchantCodeParam) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<StatsResponse>('/v1/payouts/cl/stats/outbound', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendBatchOutbound = async ({ merchantCode, file }: SendBatchOutbound) => {
	try {
		const params = { merchant_code: merchantCode };
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/payouts/cl/resume/csv/outbound', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'File-Extension': ext,
			},
		});

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getAllBeneficiary = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableBeneficiary>(
			'/v1/payouts/outbound/beneficiary',
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportDataBeneficiary = async ({ merchantCode, page }: ExportDataBeneficiaryParams) => {
	try {
		const params = {
			merchant_code: merchantCode,
			page: page,
		};

		const { data } = await client.get('/v1/payouts/outbound/export-beneficiary', {
			params: {
				...params,
				page: params.page.page,
				size: params.page.size,
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const saveBeneficiary = async ({ merchantCode, beneficiary }: SaveBeneficiaryParams) => {
	try {
		const params = { merchant_code: merchantCode };

		const body = {
			rut: beneficiary.rut,
			name: beneficiary.name,
			email: beneficiary.email,
			sbif: beneficiary.sbif,
			account: beneficiary.account,
			merchant_id: beneficiary.merchantId,
		};

		const { data } = await client.post<SendOutboundResponse>(
			'/v1/payouts/outbound/insert-beneficiary',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getAllDeposit = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableDeposit>('/v1/payouts/outbound/deposit/', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getPendingDeposit = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get('/v1/payouts/outbound/pending-deposit/', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendDepositAmount = async ({
	merchantCode,
	amount,
	email,
	merchantId,
}: DepositAmountParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			amount,
			merchant_id: merchantId,
			email,
		};
		const { data } = await client.post<DepositAmountResponse>(
			'/v1/payouts/outbound/insert-deposit/',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getAllDepositMerchants = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableBeneficiary>(
			'/v1/payouts/outbound/all-deposit/',

			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendVoucherFile = async ({
	merchantCode,
	file,
	id,
	status,
	merchantId,
}: DepositVoucherParams) => {
	try {
		const params = { merchant_code: merchantCode, status, id, merchant_id: merchantId };
		const ext = (file as File).type;

		const { data } = await client.patch('/v1/payouts/outbound/update-deposit/', file, {
			params,
			headers: {
				'content-type': 'multipart/form-data',
				'file-extension': ext,
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getAllDepositDone = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableBeneficiary>(
			'/v1/payouts/outbound/all-deposit-done/',
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const updateDepositPayout = async (payload: PayloadDepositUpdate) => {
	try {
		// const params = { merchant_code: merchantCode };
		const error_reason_string = payload.error_reason ? `&error_reason=${payload.error_reason}` : '';
		const { data } = await client.patch<ResponseUpdateDepositPayout>(
			`/v1/payouts/outbound/update-deposit/?merchant_code=${payload.merchant_code}&status=${payload.status}&id=${payload.id}&merchant_id=${payload.merchant_id}${error_reason_string}`,
			null,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'File-Extension': 'application/pdf',
				},
			},
		);
		return data;
	} catch (error: any) {
		// throw error.response.data;
		return error;
	}
};

const allDepositStats = async (merchant_code: string) => {
	try {
		const { data } = await client.get<StatsTablePayout>(
			`/v1/payouts/outbound/all-deposit-stats/?merchant_code=${merchant_code}`,
		);

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getUrlImageDeposit = async (merchant_code: string, id: number) => {
	try {
		const { data } = await client.get<ImageDepositPayout>(
			`/v1/payouts/outbound/export-deposit/?merchant_code=${merchant_code}&id=${id}`,
		);

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const PayoutsService = {
	generateOutbound,
	getAllBeneficiary,
	getAllDeposit,
	getAllDepositMerchants,
	getAllOutbound,
	getBalance,
	getCodeOutbound,
	getExportDataBeneficiary,
	getExportDataOutbound,
	getPendingDeposit,
	getStats,
	saveBeneficiary,
	sendBatchOutbound,
	sendCodeOutbound,
	sendVoucherFile,
	sendDepositAmount,
	updateDepositPayout,
	allDepositStats,
	getAllDepositDone,
	getUrlImageDeposit,
};
