import styled from 'styled-components';

export const TableContainer = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 6px;
	box-shadow: 0 1px 2px -2px rgba(20 15 51 /4%), 0 1px 4px 0 rgba(20 15 51 /8%);
	margin: 8px 0 34px;
	overflow-x: auto;
	width: 100%;
`;

export const Table = styled.table`
	border: none;
	border-collapse: collapse;
	margin: 0;
	width: 100%;
`;

export const TableHead = styled.thead`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	margin: 0;
`;

export const TableRow = styled.tr`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-top: 1px solid ${({ theme }) => theme.colors.principal.ceres};

	&:nth-child(odd) {
		background-color: ${({ theme }) => theme.colors.principal.vanth};
	}

	&:first-child {
		border: none;
	}

	&:first-child {
		background-color: ${({ theme }) => theme.colors.principal.pure};
	}
`;

export const TableBody = styled.tbody`
	width: 100%;

	${TableRow} {
		&:nth-of-type(odd) {
			background-color: ${({ theme }) => theme.colors.principal.air};
		}
	}
`;

export const TableHeader = styled.th`
	color: ${({ theme }) => theme.colors.extra.cygnus};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	padding: 16px;
	text-align: start;
	white-space: nowrap;
`;

export const TableData = styled.td`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	line-height: 12px;
	padding: 8px 16px;
`;
