import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const Steps = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 32px;
	text-align: center;

	a {
		text-decoration: underline;
	}
`;

export const ContainerInput = styled.div`
	width: 100%;

	input {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		&:read-only {
			background-color: #f7f6ff;
			border: none;
			padding-right: 70px;
		}
	}
`;

export const ModalFooter = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

export const Button = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	outline: none;
	padding: 8px 14px;

	svg {
		margin-right: 12px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const CopyButton = styled.span`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.pure};
	display: flex;
	font-size: 12px;
	font-weight: 400;
	height: 28px;
	justify-content: center;
	line-height: 12px;
	padding: 6px;
`;

export const TabsContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;

export const TabBody = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const QRTitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0;
	text-align: center;
`;

export const QRSubtitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	margin: 0;
	text-align: center;
`;

export const QRCodeContainer = styled.div`
	background-color: #f7f7ff;
	border-radius: 12px;
	margin: 16px 0;
	padding: 23px;

	img {
		height: 114px;
		width: 114px;
	}
`;

export const ScanQRText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
`;

export const ShareView = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

export const BackButtonContainer = styled.div`
	align-items: center;
	align-self: flex-start;
	cursor: pointer;
	display: flex;
	margin-bottom: 24px;
	width: fit-content;

	svg {
		height: 12px;
		stroke: ${({ theme }) => theme.colors.principal.vanth};
	}
`;

export const BackText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-left: 10px;
`;

export const SendLinkButtonContainer = styled.div`
	margin-top: 24px;
	width: 100%;

	button {
		align-items: center;
		display: flex;
		height: 48px;
		width: 100%;
	}
`;
