import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const FinishContainer = styled.div`
	min-height: 200px;
	text-align: center;
	width: 100%;
`;

export const FinishWrapper = styled.div`
	margin-top: 32px;
`;

export const FinishTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	line-height: 30px;
`;

export const FinishSubtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	line-height: 18px;
`;

export const Steps = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 32px;
	text-align: center;
`;

export const HintInput = styled.p`
	color: ${({ theme }) => theme.colors.principal.moon};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	width: 100%;
`;

export const ModalFooter = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

export const Button = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.omega};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.orion};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	outline: none;
	padding: 8px 14px;

	svg {
		margin-right: 12px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const HintModal = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-top: 16px;
`;
