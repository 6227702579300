import styled from 'styled-components';

interface TooltipTextProp {
	readonly $position: string;
	readonly $autoWidth: boolean;
}

export const TooltipContainer = styled.div`
	cursor: pointer;
	display: inline-block;
	position: relative;
`;

export const TooltipText = styled.p<TooltipTextProp>`
	background: #e8e7eb;
	border-radius: 8px;
	color: #140f33;
	font-size: 14px;
	font-weight: 400;
	padding: 8px 12px;
	position: absolute;
	text-align: start;
	width: max-content;
	${({ $position }) => ($position === 'top' ? 'bottom: 140%;' : 'top: 140%;')}
	${({ $autoWidth }) => ($autoWidth  ? '' : 'max-width: 200px')}
	z-index: 1;
`;
