export const CrossIcon = ({ ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		cursor="pointer"
		{...rest}
	>
		<path
			d="M17 7L7 17"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M7 7L17 17"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
