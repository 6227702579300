import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DownloadIcon } from '../../assets/svg';
import { DirectDebitIcon } from '../../assets/svg/DirectDebitIcon';
import { SuccessIcon } from '../../assets/svg/SuccessIcon';
import { Breadcrumb } from '../../components/breadcrumb';
import { DragAndDrop } from '../../components/drag-and-drop';
import { Select } from '../../components/form/select';
import { Modal } from '../../components/modal';
import { Spinner } from '../../components/spinner';
import { ViewWrapper } from '../../components/view-wrapper';
import { useFloatingAlert } from '../../hooks/useFloatingAlert';
import { useMerchant } from '../../hooks/useMerchant';
import { DirectDebitManagementServices } from '../../services/direct-debit-management';
import { csvFormat } from '../../utils/dates';
import { errorFormatter } from '../../utils/errors';
import Card from './components/card';
import CardDataExample from './components/card/components/card-data-example';
import CardContainer from './components/card-container';
import { CardStatus } from './components/card-disabled';
import {
	Button,
	ButtonContainer,
	CardGroup,
	Content,
	DownloadLink,
	HeadContainer,
	ModalBody,
	ModalDate,
	ModalTitle,
	SelectContainer,
	TitlePage,
} from './styles';

interface DefaultState {
	files?: FileList | FileList[];
	load?: boolean;
	date?: string;
}

const defaultState: DefaultState = {
	files: undefined,
	load: false,
	date: '',
};

interface DefaultStatus {
	date: string | null;
	status: string | null;
}

const defaultStatus: DefaultStatus = {
	date: null,
	status: null,
};

interface Option {
	value: string;
	label: string;
	show: boolean;
}

const DirectDebitManagement = () => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined);
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
	const [state, setState] = useState(defaultState);
	const [errorFile, setErrorFile] = useState<boolean>(false);
	const [sizeFile, setSizeFile] = useState<string>('');
	const [status, setStatus] = useState(defaultStatus);
	const [csvWithErrors, setCsvWithErrors] = useState<string>('');
	const [contractDate, setContractDate] = useState<string>('');
	const [optionsSelect, setOptionsSelect] = useState<Option[] | undefined>(undefined);
	const [modal, setModal] = useState<boolean>(false);
	const [cleanState, setCleanState] = useState(false);

	const handleChange = (files: FileList | FileList[]) => {
		setState({ ...state, files });
	};

	const handleVerifyDisabledButton = () => {
		if (state.files?.length === 0 || state.files === undefined || errorFile || state.load)
			return true;
		return false;
	};

	const dictionary = new Map([
		['direct-debit', 'Débito Directo'],
		['management', 'Gestión de cobros'],
	]);

	const handleClick = () => async () => {
		if (downloadLink === undefined) {
			setShowProgressBar(true);
			try {
				const data = await DirectDebitManagementServices.getChargesCsv({
					merchantCode: merchantSelected?.code as string,
				});
				if (data && data.code) {
					handleShowAlert({
						type: 'error',
						message: errorFormatter(data.code),
					});
					setShowProgressBar(false);
					return;
				}
				const newBlob = new Blob([data]);
				const url = window.URL.createObjectURL(newBlob);
				setSizeFile(String(newBlob.size));
				data && setShowProgressBar(false);
				setDownloadLink(url);
			} catch (e: any) {
				handleShowAlert({
					type: 'error',
					message: errorFormatter(e.code),
				});
			}
		}
	};

	const {
		data: payrolls,
		refetch,
		isSuccess,
		isFetching,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		queryKey: [merchantSelected?.code],
		queryFn: () =>
			DirectDebitManagementServices.getPayrolls({
				merchantCode: merchantSelected?.code as string,
			}),
	});

	useEffect(() => {
		if (isSuccess && payrolls) {
			setStatus({ date: payrolls.last_in_progress, status: 'in_progress' });
			const options = payrolls?.pending_dates?.map((item) => {
				return { value: String(item.key), label: item.value, show: true };
			});
			setContractDate(String(payrolls.pending_dates[0].key));
			setOptionsSelect(options);
			setState((state) => ({ ...state, date: payrolls.pending_dates[0].value as string }));
			if (isFetching) setOptionsSelect(undefined);
		}
	}, [isSuccess, payrolls, isFetching]);

	const handleContinueAction = async () => {
		try {
			setErrorFile(false);
			if (state.files) {
				setModal(true);
				setState((state) => ({ ...state, load: true }));
				const data = await DirectDebitManagementServices.uploadCharges({
					merchantCode: merchantSelected?.code as string,
					file: state.files?.[0],
					contractDate: contractDate,
				});

				if (data.url) {
					setErrorFile(true);
					setCsvWithErrors(data.url);
					setState({ ...state, load: false });
					setModal(false);
				}

				if (data.code === 'S_OK') {
					setState((state) => ({ ...state, load: false })), setCleanState(true);
				}
			}
		} catch (e: any) {
			setErrorFile(true);
			setModal(false);
			setState((state) => ({ ...state, load: false }));
		}
	};

	const handleCloseModal = () => {
		setModal(false);
		setCleanState(false);
		refetch();
	};

	return (
		<>
			{modal && !errorFile && (
				<Modal title="Procesar cobros" show={modal} onClose={handleCloseModal}>
					{state.load ? (
						<Spinner message="Procesando tu solicitud" />
					) : (
						<ModalBody>
							<SuccessIcon color="#1B9D06" />
							<ModalTitle>Solicitud realizada correctamente</ModalTitle>
							<ModalDate>{`Fecha de cobro: ${state.date}`}</ModalDate>
						</ModalBody>
					)}
				</Modal>
			)}

			<ViewWrapper title="Gestión de cobros">
				<HeadContainer>
					<TitlePage>
						<DirectDebitIcon />
					</TitlePage>
					<Breadcrumb dictionary={dictionary} />
				</HeadContainer>
				<Content>
					{status.date && status.status && (
						<CardStatus title={status.date} status={status.status} />
					)}
					<CardContainer>
						{optionsSelect && (
							<SelectContainer>
								<Select
									titleLabel="Fecha de cobro"
									size="large"
									options={optionsSelect || []}
									orientation="bottom"
									onChange={({ value, label }) => {
										if (label) {
											setState((state) => ({ ...state, date: label as string }));
											setContractDate(value as string);
										}
									}}
								/>
							</SelectContainer>
						)}
						<CardGroup>
							<Card
								title="Obtén el universo"
								description="Este es el documento más actualizado"
								step={1}
								disclaimer
							>
								<CardDataExample
									nameFile={
										contractDate ? `universo_${csvFormat(contractDate)}.csv` : `universo_DDMMAA.csv`
									}
									sizeFile={sizeFile && `${sizeFile}KB`}
									allowDownload={!!downloadLink}
									progressBar={showProgressBar}
								/>
								{downloadLink === undefined ? (
									<Button onClick={handleClick()} disabled={showProgressBar}>
										Generar documento
									</Button>
								) : (
									<DownloadLink
										href={downloadLink}
										download={`universo_${csvFormat(contractDate)}.csv`}
									>
										Descargar
										<DownloadIcon />
									</DownloadLink>
								)}
							</Card>
							<Card
								title="Ingresa los datos"
								description="Recuerda respetar las cabeceras originales"
								step={2}
							>
								<CardDataExample data />
							</Card>
							<Card
								title="Sube los cobros"
								description="Comprobaremos que todo está correcto antes de continuar"
								step={3}
								fileUpload={state.files !== undefined}
							>
								<DragAndDrop
									id="upload-batch-payments"
									accept={['.csv']}
									maxSize={2100000}
									onChange={handleChange}
									errorFile={errorFile}
									setErrorFile={setErrorFile}
									downloadErrorFile={csvWithErrors}
									cleanState={cleanState}
								/>
							</Card>
						</CardGroup>
						<ButtonContainer>
							<Button
								disabled={handleVerifyDisabledButton()}
								onClick={() => handleContinueAction()}
							>
								Procesar cobros
							</Button>
						</ButtonContainer>
					</CardContainer>
				</Content>
			</ViewWrapper>
		</>
	);
};

export { DirectDebitManagement };
