import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { FinishProcessSuccessIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { NewWithdrawalResponse } from '../../../../models/eatm';
import { EatmServices } from '../../../../services/eatm';
import { errorFormatter } from '../../../../utils/errors';
import { onlyNumberRegex, onlyNumberRegexWithComma } from '../../../../utils/validations';
import { QRSection } from './qr-code';
import {
	Button,
	FinishContainer,
	FinishSubtitle,
	FinishTitle,
	FinishWrapper,
	HintInput,
	HintModal,
	ModalContainer,
	ModalFooter,
	Steps,
	Subtitle,
	Title,
} from './styles';

interface NewWithdrawalProps {
	show: boolean;
	onClose: () => void;
	maxAmount?: string;
	amount?: string;
	verifyWithdrawal?: boolean;
	withToken?: string;
}

interface Values {
	phoneNumber: string;
	amount: string;
	verificationCode: string;
}

const defaultValues: Values = {
	phoneNumber: '',
	amount: '',
	verificationCode: '',
};

const NewWithdrawal = ({
	show,
	onClose,
	maxAmount,
	amount,
	verifyWithdrawal = false,
	withToken,
}: NewWithdrawalProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [step, setStep] = useState(verifyWithdrawal ? 4 : 1);
	const [state, setState] = useState<Values>(defaultValues);
	const [amountToWithdrawal] = useState(amount || '');
	const [withdrawalInfo, setWithdrawalInfo] = useState<NewWithdrawalResponse | null>(null);
	const [loading, setLoading] = useState(false);
	const [endStep, setEndStep] = useState<'success'>();

	const handleCloseModal = () => {
		setState(defaultValues);
		setStep(1);
		setState(defaultValues);
		onClose();
	};

	const handleNext = () => {
		if (step === 4) return;
		setStep(step + 1);
	};

	const handleBack = () => {
		if (step === 1) return;
		setStep(step - 1);
	};

	const handleGoToNext = async () => {
		if (step === 1) handleNext();
		if (step === 2) {
			setLoading(true);
			try {
				const response = await EatmServices.withdrawal({
					merchantCode: merchantSelected?.code as string,
					amount: state.amount,
					phoneNumber: state.phoneNumber,
				});
				setWithdrawalInfo(response);
				handleNext();
			} catch (e: any) {
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
			} finally {
				setLoading(false);
			}
		}
		if (step === 3) handleNext();
		if (step === 4) {
			setLoading(true);
			try {
				await EatmServices.verifyCode({
					merchantCode: merchantSelected?.code as string,
					sessionToken: withToken || (withdrawalInfo?.session_token as string),
					code: state.verificationCode,
				});
				setEndStep('success');
			} catch (e: any) {
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
			} finally {
				setLoading(false);
			}
		}
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleKeyDownValidatePhoneNumber = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegex.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
		if (state.phoneNumber.length === 10 && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegexWithComma.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const handleKeyDownValidateCode = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegex.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
		if (state.verificationCode.length === 6 && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const resendCodeHandler = async () => {
		try {
			await EatmServices.resendCode({
				merchantCode: merchantSelected?.code as string,
				sessionToken: withToken || (withdrawalInfo?.session_token as string),
			});
			handleShowAlert({
				message: 'El código de verificación ha sido reenviado',
				type: 'success',
			});
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const doNotDelivered = async () => {
		try {
			await EatmServices.verifyWithdrawal({
				merchantCode: merchantSelected?.code as string,
				sessionToken: withToken || (withdrawalInfo?.session_token as string),
				verification: false,
			});
			handleCloseModal();
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const delivered = async () => {
		try {
			await EatmServices.verifyWithdrawal({
				merchantCode: merchantSelected?.code as string,
				sessionToken: withToken || (withdrawalInfo?.session_token as string),
				verification: true,
			});
			handleCloseModal();
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	return (
		<Modal
			title="Nuevo retiro"
			show={show}
			onClose={handleCloseModal}
			allowCloseModal={endStep !== 'success'}
		>
			<ModalContainer>
				{endStep === 'success' ? (
					<FinishContainer>
						<FinishProcessSuccessIcon />

						<FinishWrapper>
							<FinishTitle>Retiro verificado</FinishTitle>
							<FinishSubtitle>
								Entrega {amountToWithdrawal || withdrawalInfo?.data.amount} a tu cliente.
							</FinishSubtitle>

							<ModalFooter>
								<Button onClick={delivered} disabled={loading}>
									Efectivo entregado
								</Button>
							</ModalFooter>
							<HintModal onClick={doNotDelivered}>No pude entregar el efectivo</HintModal>
						</FinishWrapper>
					</FinishContainer>
				) : (
					<>
						<Steps>paso {step} de 4</Steps>

						<Title>
							{step === 1 && 'Ingresa el número celular'}
							{step === 2 && 'Ingresa el monto'}
							{step === 3 && 'Comparte el QR con tu cliente'}
							{step === 4 && 'Verifica el retiro'}
						</Title>

						<Subtitle>
							{step === 1 &&
								'Ingresa el número celular del cliente. En este número recibirá el código de verificación.'}
							{step === 2 && `Monto máximo por retiro: ${maxAmount}`}
							{step === 3 &&
								'Muestra el código QR a tu cliente para que lo escanee y continue el proceso desde su celular.'}
							{step === 4 && 'Ingresa el código de verificación de seis dígitos.'}
						</Subtitle>

						{step === 3 && (
							<Subtitle>
								Una vez que realice la transacción, recibirá un mensaje de texto con un código que
								te permitirá validar el retiro.
							</Subtitle>
						)}

						{step === 1 && (
							<Input
								label="Número celular"
								name="phoneNumber"
								variant="large"
								value={state.phoneNumber}
								prefixSymbol="52"
								onChange={handleChangeInput}
								onKeyDown={handleKeyDownValidatePhoneNumber}
							/>
						)}

						{step === 2 && (
							<>
								<Input
									label="Monto"
									name="amount"
									variant="large"
									value={state.amount}
									prefixSymbol="$"
									onChange={handleChangeInput}
									onKeyDown={handleKeyDownValidateAmount}
								/>
								<HintInput>Ingresa el monto sin centavos</HintInput>
							</>
						)}

						{step === 3 && withdrawalInfo && (
							<QRSection
								amount={withdrawalInfo?.data.amount}
								qr={withdrawalInfo?.atm_qr}
								commission={withdrawalInfo?.data.commission}
							/>
						)}

						{step === 4 && (
							<Input
								label="Código de verificación"
								name="verificationCode"
								variant="large"
								value={state.verificationCode}
								onChange={handleChangeInput}
								onKeyDown={handleKeyDownValidateCode}
							/>
						)}

						<ModalFooter>
							{step === 2 && (
								<ETPayButton title="Regresar" onClick={handleBack} disabled={loading} />
							)}
							<Button
								onClick={handleGoToNext}
								disabled={
									(step === 1 && state.phoneNumber.length < 10) ||
									(step === 2 && !state.amount.length) ||
									(step === 4 && state.verificationCode.length < 6) ||
									loading
								}
							>
								{step === 1 && !loading && 'Siguiente'}
								{step === 2 && !loading && 'Generar QR'}
								{step === 2 && loading && 'Generando QR...'}
								{(step === 3 || step === 4) && !loading && 'Verificar'}
								{step === 4 && loading && 'Verificando...'}
							</Button>
						</ModalFooter>

						{step === 4 && (
							<HintModal onClick={resendCodeHandler}>Reenviar código de verificación</HintModal>
						)}
					</>
				)}
			</ModalContainer>
		</Modal>
	);
};

export { NewWithdrawal };
