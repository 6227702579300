import { Dispatch, SetStateAction } from 'react';

import { SelectRange } from '../../../../components/select-range';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { MerchantInfo, RANGE_DATES } from '../../../../models/home';
import {
	Container,
	Content,
	SpecialText,
	TextDescription,
	TimeZone,
	TimeZoneContainer,
} from './styles';

interface Options {
	label: string;
	value: RANGE_DATES;
}

interface HomeHeaderProps {
	collectedValue?: string;
	successfulPayments?: string;
	currency?: string;
	merchantInfo?: MerchantInfo;
	handleSelectRange: (arg: RANGE_DATES) => void;
	options: Options[];
	value?: RANGE_DATES;
	label?: string;
	setLabel?: Dispatch<SetStateAction<string>>;
}

const HomeHeader = ({
	collectedValue,
	successfulPayments,
	currency,
	merchantInfo,
	handleSelectRange,
	options,
	value,
}: HomeHeaderProps) => {
	const { width } = useScreenSize();

	const rangeMessage = new Map([
		['TODAY', 'últimas 24 horas'],
		['THIS_WEEK', 'esta semana'],
		['THIS_MONTH', 'este mes'],
		['LAST_14_DAYS', 'últimos 14 días'],
		['LAST_30_DAYS', 'últimos 30 días'],
	]);

	const formatTitle = () => {
		if (value === 'TODAY') return 'las';
		if (value === 'THIS_WEEK' || value === 'THIS_MONTH') return '';
		if (value === 'LAST_14_DAYS' || value === 'LAST_30_DAYS') return 'los';
	};

	return (
		<Container>
			<Content>
				<TextDescription>
					{collectedValue ? (
						<>
							En {formatTitle()} <SpecialText>{value && rangeMessage.get(value)},</SpecialText> se
							han recaudado {''}
							<SpecialText>
								{collectedValue} {currency},
							</SpecialText>{' '}
							y se han realizado {''}
							<SpecialText>{successfulPayments} cobros exitosos.</SpecialText>
						</>
					) : (
						<SpecialText>
							No se han recibido cobros en {formatTitle()} {value && rangeMessage.get(value)}.
						</SpecialText>
					)}
				</TextDescription>
			</Content>

			<Content>
				<TimeZoneContainer>
					{width > 576 && <TimeZone>{merchantInfo?.timeZone}</TimeZone>}

					<SelectRange<RANGE_DATES> onSelect={handleSelectRange} options={options} value={value} />
				</TimeZoneContainer>
			</Content>
		</Container>
	);
};

export { HomeHeader };
