import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { defaultTheme } from './config/theme';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

interface tagManagerArgsProps {
	gtmId: string;
}

const tagManagerArgs: tagManagerArgsProps = {
	gtmId: process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : '',
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={defaultTheme}>
				<App />
			</ThemeProvider>
		</QueryClientProvider>
	</Provider>,
);
reportWebVitals();
