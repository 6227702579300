import { QRCodeContainer, QRSubtitle, QRTitle, ScanQRText, TabBody } from '../styles';

interface QRTabProps {
	id: string;
	merchantName: string;
	paymentLinkUrl: string;
}

const QRTab = ({ id, merchantName, paymentLinkUrl }: QRTabProps) => {
	return (
		<TabBody id={id}>
			<QRTitle>Suscríbete</QRTitle>
			<QRSubtitle>a {merchantName}</QRSubtitle>

			<QRCodeContainer>
				<img src={paymentLinkUrl} alt="QR de suscripción" />
			</QRCodeContainer>

			<ScanQRText>Escanea el código</ScanQRText>
		</TabBody>
	);
};

export { QRTab };
