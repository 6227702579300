import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const ModalBody = styled.div`
	display: grid;
	gap: 32px;
	width: 100%;
`;

export const Header = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const TitleSpan = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
`;

export const TitleSection = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	line-height: 12px;
	margin: 0 0 8px;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
`;

export const ModalFooter = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

type ButtonProps = {
	readonly variant: 'cancel' | 'send';
};

export const Button = styled.button<ButtonProps>`
	align-items: center;
	background-color: ${({ theme, variant }) =>
		(variant === 'cancel' && theme.colors.principal.pure) ||
		(variant === 'send' && theme.colors.extra.earth)};
	border: 1px solid
		${({ theme, variant }) =>
			variant === 'cancel' ? theme.colors.principal.ceres : theme.colors.extra.earth};
	border-radius: 8px;
	color: ${({ theme, variant }) =>
		(variant === 'cancel' && theme.colors.principal.vanth) ||
		(variant === 'send' && theme.colors.principal.pure)};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	outline: none;
	padding: 15px 14px;
	width: 100%;

	svg {
		margin-right: 12px;
	}

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const ContainerInputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const ContainerInput = styled.div`
	span {
		background-color: #002eff80;
	}
`;

export const CopyButton = styled.span`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.pure};
	display: flex;
	font-size: 12px;
	font-weight: 400;
	height: 28px;
	justify-content: center;
	line-height: 12px;
	padding: 6px;
`;

export const InputGroup = styled.div`
	display: flex;
	gap: 16px;
`;

export const PaymentTypeSection = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
`;

type Type = 'management' | 'rejected' | 'successMerchant';

interface PaymentTypeContainerProps {
	isSelected?: boolean;
	type?: Type;
}

export const PaymentTypeContainer = styled.div<PaymentTypeContainerProps>`
	align-items: center;
	background-color: ${({ type }) =>
		(type === 'rejected' && '#FDEFEF') ||
		(type === 'successMerchant' && '#E7F7EF') ||
		(type === 'management' && '#F3F6FF')};
	border-radius: 12px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	outline: ${({ isSelected, type }) =>
		(isSelected && type === 'rejected' && '1.5px solid #482524') ||
		(isSelected && type === 'successMerchant' && '1.5px solid #72DBAA')};
	padding: 15px 8px;
	position: relative;
	text-align: center;
	width: 100%;
`;

export const SelectedIconContainer = styled.div`
	position: absolute;
	right: 6px;
	top: 7px;
`;

interface IconContainerProps {
	isSelected: boolean;
}

export const IconContainer = styled.div<IconContainerProps>`
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 45px;
	justify-content: center;
	margin-bottom: 6px;
	opacity: ${({ isSelected }) => !isSelected && 0.7};
	width: 45px;
`;

interface TextsProps {
	isSelected: boolean;
}

export const PaymentTypeTitle = styled.p<TextsProps>`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	line-height: 12px;
	margin-bottom: 3px;
`;

export const PaymentTypeSubTitle = styled.p<TextsProps>`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 11px;
	line-height: 13px;
`;

export const InputContainer = styled.div`
	position: 'relative';
	width: '100%';
`;

export const Circle = styled.div<PaymentTypeContainerProps>`
	align-items: center;
	border: ${({ type }) =>
		(type === 'rejected' && '1.5px solid #482524') ||
		(type === 'successMerchant' && '1.5px solid #72DBAA')};
	border-radius: 100%;
	display: flex;
	height: 18px;
	justify-content: center;
	width: 18px;

	&::before {
		background-color: ${({ type, isSelected }) =>
			(type === 'rejected' && isSelected && '#482524') ||
			(type === 'successMerchant' && isSelected && '#72DBAA')};
		border-radius: 100%;
		content: '';
		height: 6px;
		width: 6px;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
`;

export const Disclaimer = styled.div`
	background-color: #e9e1fb;
	border-radius: 12px;
	color: #602ad2;
	padding: 14px;
	width: 100%;
`;

export const DisclaimerTitle = styled.p`
	color: #602ad2;
	font-size: 11px;
	line-height: 13px;
	margin-bottom: 3px;
`;

export const DisclaimerParagraph = styled.p`
	color: #602ad2;
	font-size: 12px;
	line-height: 14px;
`;
