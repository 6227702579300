import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { PieTooltipContainer, TooltipLabel } from './styles';

interface TooltipPieProps extends TooltipProps<ValueType, NameType> {
	tooltipColor?: string;
	total: number;
}

const TooltipPie = ({ active, payload, tooltipColor = '#E8E7EB', total }: TooltipPieProps) => {
	if (active && payload && payload.length) {
		return (
			<PieTooltipContainer tooltipColor={tooltipColor}>
				<TooltipLabel>{Math.round(((payload[0].value as number) * 100) / total)}%</TooltipLabel>
			</PieTooltipContainer>
		);
	}

	return null;
};

export { TooltipPie };
