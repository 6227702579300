import { AuthLoginResponse, CreateUserParams } from '../models/auth';
import { CommonTableParams } from '../models/common-payments';
import {
	ChangeRoleParams,
	DeleteUserParams,
	GetUserResponse,
	ResendInvitationParams,
} from '../models/users';
import { client } from '../utils/api';
import { tableParamsConstructor } from '../utils/common';

const createUser = async (userData: CreateUserParams) => {
	try {
		const body = {
			names: userData.name,
			lastnames: userData.lastName,
			email: userData.email,
			password: userData.password,
		};
		const { data } = await client.post<AuthLoginResponse>('/v1/settings/users/new-account', body);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getUsers = async ({ ...rest }: CommonTableParams) => {
	try {
		const paramsConstructor = tableParamsConstructor(rest);
		const params = { ...paramsConstructor, page: paramsConstructor.page - 1 };
		const { data } = await client.get<GetUserResponse>('/v1/settings/users', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

interface InviteUserParams {
	merchantCode: string;
	merchantId: number;
	names: string;
	lastNames: string;
	email: string;
	roleId: number;
}

const inviteUser = async ({
	merchantCode,
	merchantId,
	names,
	lastNames,
	email,
	roleId,
}: InviteUserParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			merchant_id: merchantId,
			names,
			lastnames: lastNames,
			email,
			role_id: roleId,
		};
		const { data } = await client.post('/v1/settings/users/invite', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const resendInvitation = async ({ id, merchantCode, email }: ResendInvitationParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { cognito_temp_id: id, email };
		const { data } = await client.post('/v1/settings/users/resend-invitation', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const changeRole = async ({ id, merchantCode, role }: ChangeRoleParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { cognito_id: id, role_id: role };
		const { data } = await client.post('/v1/settings/users/change-role', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const deleteUser = async ({ merchantCode, id, email }: DeleteUserParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { cognito_id: id, email };
		const { data } = await client.delete('/v1/settings/users/delete', { data: body, params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const UsersServices = {
	createUser,
	getUsers,
	inviteUser,
	resendInvitation,
	changeRole,
	deleteUser,
};
