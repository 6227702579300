import { AuthBaseInterface } from '../../models/auth';
import { AuthActions } from './actions';

export interface ReducerActionsInterface {
	type: AuthActions;
	payload: AuthBaseInterface;
}

const initialState: AuthBaseInterface = {};

export const authReducer = (
	state = initialState,
	action: ReducerActionsInterface,
): AuthBaseInterface => {
	switch (action.type) {
		case AuthActions.LOGIN:
			return { ...state, user: action.payload.user };
		case AuthActions.SET_TIME_TO_EXPIRE:
			return { ...state, timeToExpire: action.payload.timeToExpire };
		case AuthActions.LOGIN_ERROR:
			return { ...initialState };
		case AuthActions.RESET_STATE:
			return initialState;
		default:
			return state;
	}
};
