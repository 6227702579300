interface UploadIconProp {
	uploadSuccess?: boolean;
	hasError?: boolean;
}

export const UploadIcon = ({ uploadSuccess = false, hasError = false }: UploadIconProp) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="29" height="40" viewBox="0 0 29 40" fill="none">
		<path
			d="M1 3C1 1.89543 1.89543 1 3 1H20.7574C21.2878 1 21.7965 1.21071 22.1716 1.58579L27.4142 6.82843C27.7893 7.2035 28 7.71221 28 8.24264V37C28 38.1046 27.1046 39 26 39H3C1.89543 39 1 38.1046 1 37V3Z"
			fill="white"
			stroke={(hasError && '#FF433E') || (uploadSuccess ? '#4C6CD1' : '#B8B8BB')}
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		{uploadSuccess && !hasError ? (
			<>
				<path
					d="M7.80825 21.3426L8.6774 20.8481L7.80825 21.3426L10.9425 26.8511C11.3421 27.5534 12.0898 27.9853 12.8978 27.9806C13.7059 27.9759 14.4484 27.5354 14.8399 26.8284L21.2031 15.3367C21.801 14.2569 21.4103 12.897 20.3306 12.2991C19.2509 11.7012 17.8909 12.0919 17.293 13.1716L12.8583 21.1805L11.6929 19.1323C11.0825 18.0596 9.71812 17.6847 8.6454 18.2951C7.57268 18.9055 7.19788 20.2699 7.80825 21.3426Z"
					stroke="#426DD8"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</>
		) : !hasError ? (
			<path
				d="M7.08579 20.4142L7.79289 19.7071L7.08579 20.4142C7.86684 21.1953 9.13316 21.1953 9.91421 20.4142L9.21951 19.7195L9.91421 20.4142L12.5 17.8284L12.5 27C12.5 28.1046 13.3954 29 14.5 29C15.6046 29 16.5 28.1046 16.5 27L16.5 17.8284L19.0858 20.4142C19.8668 21.1953 21.1332 21.1953 21.9142 20.4142C22.6953 19.6332 22.6953 18.3668 21.9142 17.5858L15.9142 11.5858C15.1332 10.8047 13.8668 10.8047 13.0858 11.5858L7.08579 17.5858L7.79289 18.2929L7.08579 17.5858C6.30474 18.3668 6.30474 19.6332 7.08579 20.4142Z"
				fill="white"
				stroke="#B8B8BB"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		) : null}
		{hasError && (
			<>
				<path
					d="M9.52484 26.6364L10.2319 25.9293L9.52484 26.6364C9.95442 27.066 10.6509 27.066 11.0805 26.6364L10.3788 25.9348L11.0805 26.6364L14.5 23.2169L17.9195 26.6364C17.9195 26.6364 17.9195 26.6364 17.9195 26.6364C18.3491 27.066 19.0456 27.066 19.4752 26.6364C19.4752 26.6364 19.4752 26.6364 19.4752 26.6364L21.1364 24.9752C21.566 24.5456 21.566 23.8491 21.1364 23.4195L21.1364 23.4195L17.7169 20L21.1364 16.5805L20.4337 15.8778L21.1364 16.5805C21.566 16.1509 21.566 15.4544 21.1364 15.0248L20.4293 15.7319L21.1364 15.0248L19.4752 13.3636C19.0456 12.934 18.3491 12.934 17.9195 13.3636L14.5 16.7831L11.0805 13.3636L10.4106 14.0335L11.0805 13.3636C10.6509 12.934 9.95441 12.934 9.52484 13.3636L7.8636 15.0248L8.57071 15.732L7.8636 15.0248C7.43403 15.4544 7.43402 16.1509 7.8636 16.5805L11.2831 20L7.8636 23.4195L8.56849 24.1244L7.8636 23.4195C7.43403 23.8491 7.43402 24.5456 7.86361 24.9752L9.52484 26.6364ZM12.6266 19.9293L12.6157 19.9402L12.6266 19.9293Z"
					stroke="#FF433E"
					strokeWidth="2"
				/>
			</>
		)}
	</svg>
);
