import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { EqualIcon, LessIcon, MoreIcon } from '../../../../assets/svg';
import { CustomizedTick } from '../../../../components/graphs/components/customized-tick';
import { GraphicsTwoPeriods } from '../../../../models/analytics';
import { dateFormat, fullDateFormat } from '../../../../utils/dates';
import { CustomTooltipContainer, CustomTooltipTitle, PercentageDetails } from '../../styles';

interface Props {
	data: GraphicsTwoPeriods[];
}

const PaymentsCount = ({ data }: Props) => {
	const handlePrettyRangeHourFormat = (range?: string) => {
		if (!range) return '';
		return fullDateFormat(range);
	};

	const handlePrettyRangeDayFormat = (range?: string) => {
		if (!range) return '';
		const date = new Date(range);

		return dateFormat(date);
	};

	const handlePrettyRangeWeekFormat = (rangeX: string, rangeY: string) => {
		const formatRangeX = rangeX.split(' ');
		const formatRangeY = rangeY.split(' ');

		const formatDate = (date: string) => {
			const newDate = new Date(date);

			const format = dateFormat(newDate);

			return format;
		};

		const formatDateX = formatRangeX.map(formatDate);
		const formatDateY = formatRangeY.map(formatDate);
		const result = formatDateX.join(' - ') + ' vs ' + formatDateY.join(' - ');

		return result;
	};

	const graphEmpty = () => {
		const currentIsEmpty = data?.every(({ current_period_y }) => current_period_y === 0);
		const pastIsEmpty = data?.every(({ past_period_y }) => past_period_y === 0);

		return currentIsEmpty && pastIsEmpty;
	};

	return (
		<ResponsiveContainer width="100%" height={260}>
			<LineChart width={500} height={300} data={data}>
				<XAxis
					type="category"
					dataKey="current_period_x"
					allowDecimals={false}
					tick={(props: any) => {
						const [from, to] = props.payload.value.split(' ');
						const fromDate = new Date(from);
						const toDate = new Date(to);

						let newFrom, newTo;
						if (!isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
							newFrom = dateFormat(fromDate);
							newTo = dateFormat(toDate);
						}

						return (
							<text
								style={{ fontSize: 12 }}
								width="40"
								orientation="right"
								height="200"
								type="number"
								x={props.x}
								y={props.y}
								stroke="none"
								fill="#666"
								textAnchor="middle"
							>
								<tspan x={props.x} dy="1em">
									{to === undefined ? (
										<>
											{from} {to ? `- ${to}` : ''}
										</>
									) : (
										<>
											{newFrom} {newTo ? `- ${newTo}` : ''}
										</>
									)}
								</tspan>
							</text>
						);
					}}
				/>
				<YAxis
					type="number"
					allowDecimals={false}
					width={40}
					dx={-35}
					tick={(props) => <CustomizedTick {...props} position="left" symbol="" fontSize={12} />}
				/>
				<Tooltip
					content={(props: any) => {
						const _ = props?.payload ? props?.payload[0]?.payload : null;
						const areEqual = _?.current_period_y === _?.past_period_y;
						const bothZero = _?.current_period_y === 0 && _?.past_period_y === 0;

						const percentage = (_?.current_period_y * 100) / _?.past_period_y;

						const percentageToShow =
							bothZero || percentage === 0
								? 0
								: percentage > 100
								? percentage - 100
								: 100 - percentage;

						const type =
							percentage === 100 || areEqual ? 'equal' : percentage > 100 ? 'more' : 'less';

						const icon =
							type === 'equal' ? <EqualIcon /> : type === 'more' ? <MoreIcon /> : <LessIcon />;

						return _ ? (
							<CustomTooltipContainer>
								<CustomTooltipTitle>
									{_?.group_by === 'hour' && (
										<>
											{handlePrettyRangeHourFormat(_?.current_period_x_full)}
											<span>vs</span>
											{handlePrettyRangeHourFormat(_?.past_period_x_full)}
										</>
									)}
									{_?.group_by === 'day' && (
										<>
											{handlePrettyRangeDayFormat(_?.current_period_x_full)}
											<span>vs</span>
											{handlePrettyRangeDayFormat(_?.past_period_x_full)}
										</>
									)}
									{_?.group_by === 'week' && (
										<>
											{handlePrettyRangeWeekFormat(_?.current_period_x_full, _?.past_period_x_full)}
										</>
									)}
								</CustomTooltipTitle>

								<PercentageDetails type={type}>
									<p>
										Número de pagos: <span>{_?.current_period_y_formatted || 0}</span>
										{!bothZero && (
											<span>
												{icon}
												{_?.past_period_y === 0 ||
												(_?.past_period_y !== 0 && _?.current_period_y === 0)
													? null
													: `${percentageToShow.toFixed(1)}%`}
											</span>
										)}
									</p>
								</PercentageDetails>
							</CustomTooltipContainer>
						) : null;
					}}
					cursor={{ fill: 'transparent' }}
					wrapperStyle={{ outline: 'none' }}
				/>
				{!graphEmpty() && <Line dataKey="current_period_y" stroke="#140F33" />}

				{!graphEmpty() && <Line dataKey="past_period_y" stroke="#140F3366" strokeDasharray="5 5" />}
			</LineChart>
		</ResponsiveContainer>
	);
};

export { PaymentsCount };
