export const SearchIcon = ({ ...rest }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M13.3333 12.9167L16.6666 16.2501"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<circle cx="9.16659" cy="9.58333" r="5.08333" stroke="#646174" strokeWidth="1.5" />
	</svg>
);
