import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { CircleCheckIcon } from '../../assets/svg/CircleCheckIcon';
import { Lider } from '../../assets/svg/companies/lider';
import { Movistar } from '../../assets/svg/companies/movistar';
import { PcFactory } from '../../assets/svg/companies/pc-factory';
import { Sencillito } from '../../assets/svg/companies/sencillito';
import { Servipag } from '../../assets/svg/companies/servipag';
import { Wom } from '../../assets/svg/companies/wom';
import { ETPayButton } from '../../components/button';
import { ViewWrapper } from '../../components/view-wrapper';
import { useAuth } from '../../hooks/useAuth';
import { useMerchant } from '../../hooks/useMerchant';
import {
	AuthLayoutButtonsHeader,
	AuthLayoutButtonsP,
	AuthLayoutContainer,
	AuthLayoutContent,
	AuthLayoutForm,
	AuthLayoutHeader,
	AuthLayoutLogo,
	AuthLayoutWrapper,
	ButtonCallToAction,
	Description,
	FeaturesList,
	FeaturesListItem,
	FeaturesTitle,
	FooterContainer,
	FooterTitle,
	Title,
	WithoutContextFeatures,
	WithoutContextFooter,
	WithoutContextHeader,
	WithoutContextWrapper,
} from './styles';

interface LayoutProps {
	link?: string;
	tabTitle: string;
	linkLabel?: string;
	buttonTitle?: string;
	image: string;
	hasContext?: boolean;
}

const AuthLayout = ({
	link,
	tabTitle,
	linkLabel,
	buttonTitle,
	children,
	image,
	hasContext = true,
}: PropsWithChildren<LayoutProps>) => {
	const navigate = useNavigate();
	const { user } = useAuth();
	const { merchants } = useMerchant();

	useEffect(() => {
		if (user && merchants) {
			navigate('/');
		}
	}, [user, merchants]);

	const handleLinkClick = () => {
		if (link) navigate(link);
	};

	const goToFormSection = () => {
		window.scrollTo({
			top: document.body.scrollHeight,
			behavior: 'smooth',
		});
	};

	return (
		<ViewWrapper title={tabTitle}>
			<AuthLayoutContainer hasContext={hasContext} backgroundImage={image}>
				{!hasContext && (
					<WithoutContextWrapper>
						<WithoutContextHeader>
							<Title>Agenda una demostración gratuita con un experto</Title>
							<Description>
								ETpay es la plataforma que dispone de todas las herramientas para que tu negocio
								pueda recibir y gestionar los pagos por transferencia de tus clientes.
							</Description>
						</WithoutContextHeader>

						<ButtonCallToAction onClick={goToFormSection}>
							Solicitar una demostración
						</ButtonCallToAction>

						<WithoutContextFeatures>
							<FeaturesTitle>Te ayudamos a:</FeaturesTitle>
							<FeaturesList>
								<FeaturesListItem>
									<div>
										<CircleCheckIcon width={24} height={24} />
									</div>
									Reducir comisión
								</FeaturesListItem>
								<FeaturesListItem>
									<div>
										<CircleCheckIcon width={24} height={24} />
									</div>
									Mejorar la conversión en tus cobros
								</FeaturesListItem>
								<FeaturesListItem>
									<div>
										<CircleCheckIcon width={24} height={24} />
									</div>
									Obtener el 100% de los fondos de forma inmediata
								</FeaturesListItem>
								<FeaturesListItem>
									<div>
										<CircleCheckIcon width={24} height={24} />
									</div>
									Reducir el fraude en tus ventas
								</FeaturesListItem>
							</FeaturesList>
						</WithoutContextFeatures>

						<WithoutContextFooter>
							<FooterTitle>Confían en nosotros:</FooterTitle>
							<FooterContainer>
								<Movistar />
								<PcFactory />
								<Lider />
								<Servipag />
								<Wom />
								<Sencillito />
							</FooterContainer>
						</WithoutContextFooter>
					</WithoutContextWrapper>
				)}

				<AuthLayoutWrapper>
					<AuthLayoutContent>
						<AuthLayoutHeader hasContext={hasContext}>
							<a href="https://www.etpay.com/">
								<AuthLayoutLogo />
							</a>

							<AuthLayoutButtonsHeader>
								{buttonTitle && <AuthLayoutButtonsP>{buttonTitle}</AuthLayoutButtonsP>}
								{link && linkLabel && <ETPayButton onClick={handleLinkClick} title={linkLabel} />}
							</AuthLayoutButtonsHeader>
						</AuthLayoutHeader>

						<AuthLayoutForm id="form-container">{children}</AuthLayoutForm>
					</AuthLayoutContent>
				</AuthLayoutWrapper>
			</AuthLayoutContainer>
		</ViewWrapper>
	);
};

export { AuthLayout };
