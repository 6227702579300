import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Tabs } from '../../../../components/tabs';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PaymentMultiuse } from '../../../../models/payment-links-multiuse';
import { PaymentLinksMultiuseServices } from '../../../../services/payment-links-multiuse';
import { validatePermission } from '../../../../utils/common';
import { getInternationalDateFormat } from '../../../../utils/dates';
import { errorFormatter } from '../../../../utils/errors';
import { CreatePaymentLink, defaultValues, Values } from './components/create-payment-link';
import { QRTab } from './components/qr-tab';
import { SendLinkTab } from './components/send-link-tab';
import {
	Button,
	ContainerInput,
	CopyButton,
	ModalBody,
	ModalContainer,
	ModalFooter,
	Subtitle,
	TabsContainer,
	Title,
	TitleSpan,
} from './styles';

interface ShowShareModal {
	show: boolean;
	selected: string;
}

interface NewSinglePaymentProps {
	show: boolean;
	onClose: () => void;
	selectedToEdit?: PaymentMultiuse;
	showShareModal?: ShowShareModal;
	viewType: ViewsAllowed;
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
}

export interface RangeDate {
	from: Date;
	to: Date;
}

export interface RangeTime {
	from: string;
	to: string;
}

export type ViewsAllowed = 'create-payment' | 'share-payment-link' | 'edit-payment-link';
export type TypesAllowed = 'unique' | 'recurrent';
export type ExpirationTypesAllowed = 'date' | 'num_payments';

interface Response {
	pl_qr: string;
	pl_url: string;
}

const defaultResponse: Response = { pl_qr: '', pl_url: '' };
type CopyText = 'Copiar' | 'Copiado';

type Options = {
	timeZone: 'America/Mexico_City' | 'America/Santiago';
	hour: '2-digit';
	minute: '2-digit';
	hour12: false;
};

const NewSinglePayment = ({
	show,
	onClose,
	selectedToEdit,
	showShareModal,
	viewType,
	refetch,
}: NewSinglePaymentProps) => {
	const d = new Date();
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [view, setView] = useState<ViewsAllowed>(viewType);
	const [showConfig, setShowConfig] = useState<boolean>(false);
	const [writeInput, setWriteInput] = useState<boolean>(false);
	const [typeSelected, setTypeSelected] = useState<TypesAllowed>('unique');
	const [expirationType, setExpirationType] = useState<ExpirationTypesAllowed>('date');
	const [state, setState] = useState<Values>(() =>
		selectedToEdit?.merchantorderid
			? {
					...defaultValues,
					reference: selectedToEdit?.merchantorderid,
					description: selectedToEdit?.description,
			  }
			: defaultValues,
	);
	const [copyText, setCopyText] = useState<CopyText>('Copiar');
	const [response, setResponse] = useState<Response>(defaultResponse);
	const [range, setRange] = useState<RangeDate>(() => ({
		from: d,
		to: add(d, { days: 1 }),
	}));

	const selectTimeZone = () => {
		const options: Options = {
			timeZone: 'America/Mexico_City',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		};

		const mexicoTime = new Intl.DateTimeFormat('en-US', options).format(d);
		options.timeZone = 'America/Santiago';
		const chileTime = new Intl.DateTimeFormat('en-US', options).format(d);

		if (merchantSelected?.country == 'MX') {
			return mexicoTime;
		}
		if (merchantSelected?.country == 'CL') {
			return chileTime;
		}
		return '';
	};

	const [rangeTime, setRangeTime] = useState(() => ({
		from: selectTimeZone(),
		to: selectTimeZone(),
	}));

	const handleCloseModal = () => {
		setState(defaultValues);
		onClose();
		refetch();
	};

	const handleContinueAction = async () => {
		setState({ ...state, loading: true });
		const tz = d.getTimezoneOffset();
		const from = `${getInternationalDateFormat(range.from)}T${rangeTime.from}`;
		const to = `${getInternationalDateFormat(range.to)}T${rangeTime.to}`;

		const _from = `${format(new Date(from), "yyyy-MM-dd'T'HH:mm:ss")}.${tz}Z`;
		const _to = `${format(new Date(to), "yyyy-MM-dd'T'HH:mm:ss")}.${tz}Z`;

		try {
			const { pl_multiuse_qr, pl_multiuse_url } =
				await PaymentLinksMultiuseServices.newPaymentLinkMultiuse({
					merchantCode: merchantSelected?.code as string,
					paymentType: typeSelected,
					expirationType,
					amount: +state.amount.replaceAll(',', ''),
					reference: state.reference,
					description: state.description,
					numPayments:
						typeSelected === 'recurrent' && expirationType === 'num_payments'
							? +state.successfulPayments
							: undefined,
					from:
						typeSelected === 'recurrent' && expirationType === 'num_payments' ? undefined : _from,
					to: typeSelected === 'recurrent' && expirationType === 'num_payments' ? undefined : _to,
				});
			setResponse({
				pl_qr: pl_multiuse_qr,
				pl_url: pl_multiuse_url,
			});
			setState({ ...state, loading: false });
			setView('share-payment-link');
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const handleEditPaymentLink = async () => {
		setState((state) => ({ ...state, loading: true }));
		try {
			await PaymentLinksMultiuseServices.editPaymentLinkMultiuse({
				merchantCode: merchantSelected?.code as string,
				reference: state.reference,
				token: selectedToEdit?.token.split('/').slice(-1)[0],
				description: state.description,
			});
			setView('share-payment-link');
			handleShowAlert({
				message: 'Enlace de cobro modificado',
				type: 'success',
			});
			setState((state) => ({ ...state, loading: false }));
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const handleVerifyDisabledButton = () => {
		if (viewType === 'edit-payment-link') {
			setShowConfig(true);
			if (state.description !== '' && writeInput) {
				return false;
			}
		}
		if (typeSelected === 'unique' || (typeSelected === 'recurrent' && expirationType === 'date')) {
			if (state.amount !== '' && state.reference !== '') {
				return false;
			}
		}
		if (!showConfig && typeSelected === 'recurrent') {
			if (
				state.amount !== '' &&
				state.reference !== '' &&
				state.successfulPayments !== '' &&
				expirationType === 'num_payments'
			)
				return false;
		}

		return true;
	};

	useEffect(() => {
		const buttonDisabled = handleVerifyDisabledButton();
		setState({ ...state, buttonDisabled });
	}, [
		state.amount,
		state.reference,
		state.successfulPayments,
		state.buttonDisabled,
		writeInput,
		typeSelected,
		showConfig,
		expirationType,
	]);

	return (
		<Modal
			show={show}
			type="medium"
			title={selectedToEdit ? 'Editar enlace de cobro' : 'Crear enlace de cobro'}
			onClose={handleCloseModal}
		>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<ModalBody>
						{show && (view === 'create-payment' || view === 'edit-payment-link') && (
							<CreatePaymentLink
								state={state}
								setState={setState}
								range={range}
								setRange={setRange}
								showConfig={showConfig}
								setShowConfig={setShowConfig}
								typeSelected={typeSelected}
								setTypeSelected={setTypeSelected}
								expiration={expirationType}
								setExpiration={setExpirationType}
								rangeTime={rangeTime}
								setRangeTime={setRangeTime}
								selectedToEdit={selectedToEdit}
								viewType={viewType}
								setWriteInput={setWriteInput}
							/>
						)}

						{(view === 'share-payment-link' || (showShareModal && showShareModal.show)) && (
							<>
								<div>
									<Title>
										{state.reference || selectedToEdit?.merchantorderid}
										<TitleSpan> por </TitleSpan>$
										{state.amount || selectedToEdit?.amount.split('$')[1]}
									</Title>
									<Subtitle>Comparte el enlace para comenzar a recibir pagos.</Subtitle>
								</div>
								<ContainerInput>
									<Input
										label="Enlace de cobro"
										name="paymentLink"
										defaultValue={selectedToEdit ? selectedToEdit.token : response.pl_url}
										variant="large"
										readOnly
										rightIcon={<CopyButton>{copyText}</CopyButton>}
										rightIconClick={async () => {
											await navigator.clipboard.writeText(
												selectedToEdit ? selectedToEdit.token : response.pl_url,
											);
											setCopyText('Copiado');
											setTimeout(() => setCopyText('Copiar'), 2500);
										}}
									/>
								</ContainerInput>
								<TabsContainer>
									<Tabs
										tabs={[
											{
												reference: 'send-link',
												title: 'Enviar enlace',
												show: validatePermission('PL_SEND_SMS'),
											},
											{
												reference: 'qr',
												title: 'Código QR',
												show: validatePermission('PL_SHARE_LINK'),
											},
										]}
										defaultReferenceSelected={showShareModal?.selected || 'qr'}
									>
										<SendLinkTab
											id="send-link"
											paymentLinkUrl={selectedToEdit ? selectedToEdit.token : response.pl_url}
										/>
										<QRTab
											id="qr"
											amount={state.amount || selectedToEdit?.amount.split('$')[1] || ''}
											merchantName={merchantSelected?.name as string}
											paymentLinkUrl={selectedToEdit?.token_qr || response.pl_qr}
										/>
									</Tabs>
								</TabsContainer>
							</>
						)}
					</ModalBody>

					{((selectedToEdit && view === 'edit-payment-link') || view === 'create-payment') && (
						<ModalFooter>
							<Button
								onClick={selectedToEdit ? handleEditPaymentLink : handleContinueAction}
								disabled={state.buttonDisabled}
							>
								{selectedToEdit ? 'Actualizar enlace de cobro' : 'Crear enlace de cobro'}
							</Button>
						</ModalFooter>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export { NewSinglePayment };
