import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { PayloadProps, track } from '../../../analytics';
import { ANALYTICS } from '../../../analytics/constants';
// import { analytics } from '../../../analytics/constants';
// import { PayloadProps, track } from '../../../analytics/index';
import ETPayBG from '../../../assets/images/etpay-bg-01.jpg';
import { EyeIcon, EyeIconOff } from '../../../assets/svg';
import { Alert, AlertProps } from '../../../components/alert';
import { ETPayButton } from '../../../components/button';
import { FormInput } from '../../../components/form/form-input';
import { CONSTANTS } from '../../../config/constants';
import { useAuth } from '../../../hooks/useAuth';
import { useMerchant } from '../../../hooks/useMerchant';
import { AuthLayout } from '../../../layout/auth';
import { AuthUser, CreateUserParams } from '../../../models/auth';
import { UsersServices } from '../../../services/users';
import { errorFormatter } from '../../../utils/errors';
import { createAccountValidationSchema } from '../../../utils/validations';
import {
	AlertContainer,
	AuthFormContainer,
	FooterLink,
	FooterText,
	FormGroup,
	Subtitle,
	Title,
} from '../styles';

const defaultAlert: AlertProps = {
	show: false,
	message: '',
	type: 'error',
};

const defaultEmptyValues: CreateUserParams = {
	name: '',
	lastName: '',
	email: '',
	password: '',
};

const CreateAccount = () => {
	const [alert, setAlert] = useState(defaultAlert);
	const { handleLogin, user } = useAuth();
	const { handleGetMerchants } = useMerchant();
	const [state, setState] = useState({ loading: false });
	const [showPassword, setShowPassword] = useState(false);
	const [searchParams] = useSearchParams();
	const params = new URLSearchParams(searchParams);
	const userData = {
		email: params.get('email') as string,
		name: params.get('names') as string,
		lastName: params.get('lastnames') as string,
		password: '',
	};

	const defaultValues = { ...defaultEmptyValues, ...userData };

	const { isLoading: merchantLoading } = useQuery({
		enabled: !!user,
		queryKey: [CONSTANTS.services.merchant.getMerchantsList],
		queryFn: () => handleGetMerchants(),
	});

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.create_account.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);
	return (
		<AuthLayout
			tabTitle="Crear usuario"
			image={ETPayBG}
			buttonTitle="¿Ya tienes una cuenta?"
			link="/login"
			linkLabel="Inicia sesión"
		>
			<AuthFormContainer>
				<AlertContainer>
					<Alert show={alert.show} message={alert.message} hasIcon type={alert.type} />
				</AlertContainer>

				<Title>Crear usuario</Title>
				<Subtitle>
					Comienza ahora a mejorar las ventas de tu comercio. ¡Regístrate es fácil y gratis!
				</Subtitle>

				<Formik
					initialValues={defaultValues}
					onSubmit={async (values) => {
						setState({ loading: true });
						setAlert(defaultAlert);
						const userData: AuthUser = {
							username: values.email,
							password: values.password,
						};
						try {
							await UsersServices.createUser(values);
							await handleLogin(userData);
						} catch (e: any) {
							setAlert({
								show: true,
								message: errorFormatter(e.code),
								type: 'error',
							});
						} finally {
							setState({ loading: false });
						}
					}}
					validationSchema={createAccountValidationSchema}
				>
					{({ isValid, dirty }) => (
						<Form>
							<FormGroup>
								<FormInput type="text" label="Nombre(s)" name="name" readOnly />
								<FormInput type="text" label="Apellidos" name="lastName" readOnly />
							</FormGroup>

							<FormInput type="text" label="Correo electrónico" name="email" readOnly />

							<FormInput
								type={showPassword ? 'text' : 'password'}
								label="Contraseña"
								name="password"
								autoComplete="new-password"
								rightIcon={showPassword ? <EyeIcon /> : <EyeIconOff />}
								rightIconClick={() => setShowPassword(!showPassword)}
							/>

							<ETPayButton
								title={state.loading || merchantLoading ? 'Creando...' : 'Crear usuario'}
								loading={state.loading || merchantLoading}
								variant="effect"
								disabled={!isValid || !dirty}
								type="submit"
							/>
						</Form>
					)}
				</Formik>

				<FooterText>
					Al hacer clic en “Crear usuario”, aceptas los
					<FooterLink
						href="https://www.etpay.com/terms-conditions"
						target="_blank"
						rel="noreferrer noopener"
					>
						Términos y Condiciones
					</FooterLink>
					y la
					<FooterLink
						href="https://www.etpay.com/privacy-policy"
						target="_blank"
						rel="noreferrer noopener"
					>
						Política de Privacidad
					</FooterLink>
					de ETpay.
				</FooterText>
			</AuthFormContainer>
		</AuthLayout>
	);
};

export { CreateAccount };
