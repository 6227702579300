import styled from 'styled-components';

export const InputContainer = styled.div`
	position: relative;
	width: 100%;
`;

interface LabelProps {
	readonly hasValue: boolean;
	readonly isFocus: boolean;
}

export const Label = styled.label<LabelProps>`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: ${({ hasValue, isFocus }) =>
		hasValue ? '12px' : isFocus ? '12px' : '16px'};
	left: 14px;
	line-height: 12px;
	margin: auto 0;
	pointer-events: none;
	position: absolute;
	top: ${({ hasValue, isFocus }) => (hasValue ? '8px' : isFocus ? '8px' : '17px')};
	transition: all 0.3s ease;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

interface IconContainerProps {
	readonly position: 'left' | 'right';
	variant: 'medium' | 'large';
	onClick?: () => void;
}

export const IconContainer = styled.div<IconContainerProps>`
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
	left: ${({ position }) => (position === 'left' ? '13px' : 'unset')};
	margin: 0;
	position: absolute;
	right: ${({ position }) => (position === 'right' ? '13px' : 'unset')};
	top: ${({ variant }) => (variant === 'medium' ? '8px' : '10px')};
`;

interface PrefixContainerProps {
	variant: 'medium' | 'large';
}

export const PrefixContainer = styled.div<PrefixContainerProps>`
	font-size: 13px;
	left: 14px;
	position: absolute;
	top: ${({ variant }) => (variant === 'medium' ? '8px' : '18px')};
`;

interface InputElementProps {
	readonly hasLeftIcon: boolean;
	readonly hasRightIcon: boolean;
	readonly variant: 'medium' | 'large';
	readonly hasPrefix?: number;
	readonly isFile?: boolean;
}

export const InputElement = styled.input<InputElementProps>`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	height: ${({ variant }) => (variant === 'medium' ? '38px' : '48px')};
	opacity: ${({ isFile }) => (isFile ? '0' : '1')};
	outline: 0;
	overflow: hidden;
	padding-bottom: ${({ variant }) => (variant === 'medium' ? '8px' : '0')};
	padding-left: ${({ hasLeftIcon, hasPrefix }) =>
		hasLeftIcon ? '40px' : hasPrefix ? `calc(${hasPrefix}px + 13px)` : '14px'};
	padding-right: ${({ hasRightIcon }) => (hasRightIcon ? '40px' : '14px')};
	padding-top: 8px;
	text-overflow: ellipsis;
	transition: border 0.2s ease-in-out;
	white-space: nowrap;
	width: 100%;

	&::placeholder {
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	&:focus {
		border-color: ${({ theme }) => theme.colors.principal.vanth};

		&::placeholder {
			opacity: 1;
		}

		~ ${Label} {
			font-size: 12px;
			top: 8px;
		}
	}

	&:disabled,
	&:read-only {
		background-color: ${({ theme }) => theme.colors.principal.eris};
		cursor: default;
		pointer-events: none;
	}
`;

interface InputFileElementProps {
	readonly hasLeftIcon: boolean;
	readonly hasRightIcon: boolean;
	readonly hasFile: boolean;
}

export const InputFileElement = styled.div<InputFileElementProps>`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	cursor: pointer;
	height: 48px;
	padding-bottom: 0;
	padding-left: ${({ hasLeftIcon }) => (hasLeftIcon ? '40px' : '14px')};
	padding-right: ${({ hasRightIcon }) => (hasRightIcon ? '70px' : '14px')};
	padding-top: 14px;
	text-overflow: ellipsis;
	transition: border 0.2s ease-in-out;
	white-space: nowrap;
	width: 100%;

	~ ${Label} {
		font-size: ${({ hasFile }) => (hasFile ? '12px' : '16px')};
		left: ${({ hasLeftIcon }) => (hasLeftIcon ? '40px' : 'inherit')};
		top: ${({ hasFile }) => (hasFile ? '8px' : '14x')};
	}
`;
