import { Container, Date, Time } from './styles';

interface DateAndTimeProps {
	date?: string;
	time?: string;
}

const DateAndTime = ({ date, time }: DateAndTimeProps) => {
	return (
		<Container>
			<Date>{date}</Date>
			<Time>{time}</Time>
		</Container>
	);
};

export { DateAndTime };
