import styled from 'styled-components';

import { AlertTypeAllowed } from '.';

const backgroundColorAlertMap = {
	success: '#E3F8EE',
	warning: '#FFF1D5',
	error: '#FFE3E4',
	default: '#FCFCFC',
};

const borderColorAlertMap = {
	success: '#E3F8EE',
	warning: '#FFF1D5',
	error: '#FFE3E4',
	default: '#E6E6E6',
};

const backgroundColorButtonMap = {
	success: '#72DBAA',
	warning: '#FFBE5C',
	error: '#FF7576',
	default: '#E6E6E6',
};

interface AlertContainerProps {
	readonly type: AlertTypeAllowed;
	readonly show: boolean;
}

export const AlertContainer = styled.div<AlertContainerProps>`
	align-items: center;
	background-color: ${({ type }) => backgroundColorAlertMap[type]};
	border: 1px solid ${({ type }) => borderColorAlertMap[type]};
	border-radius: 12px;
	display: flex;
	gap: 12px;
	justify-content: space-between;
	min-height: 62px;
	mix-blend-mode: multiply;
	opacity: ${({ show }) => (show ? 1 : 0)};
	padding: 12px 16px;
	transition: all 0.3s ease-in-out;
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	width: 100%;
	z-index: 100;
`;

export const AlertWrapper = styled.div`
	align-items: center;
	display: flex;

	svg {
		margin-right: 12px;
	}
`;

export const AlertMessage = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
`;

interface CallToActionProps {
	readonly $type: AlertTypeAllowed;
}

export const CallToAction = styled.button<CallToActionProps>`
	align-items: center;
	background-color: ${({ $type }) => backgroundColorButtonMap[$type]};
	border: none;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	gap: 8px;
	justify-content: center;
	padding: 8px 14px;
`;
