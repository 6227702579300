import {
	TransferData,
	TransferDetail,
	TransferDetailContainer,
	TransferItem,
} from '../modal/styles';

interface TransferResume {
	item: string;
	data: string | number;
}

interface RefundResume {
	transferResume: TransferResume[];
}

const RefundResume = ({ transferResume }: RefundResume) => {
	return (
		<TransferDetail>
			{transferResume.map(({ item, data }: TransferResume) => (
				<TransferDetailContainer key={item}>
					<TransferItem>{item}</TransferItem>
					<TransferData>{data}</TransferData>
				</TransferDetailContainer>
			))}
		</TransferDetail>
	);
};

export default RefundResume;