import { ChangeEvent, useState } from 'react';

import { Input } from '../../../../../components/input';
import { useFloatingAlert } from '../../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../../hooks/useMerchant';
import { DirectDebitServices } from '../../../../../services/direct-debit';
import { Button, ButtonContainer, TabBody } from '../styles';

interface SendLinkByValues {
	email: string;
	whatsapp: string;
	sms: string;
	loading: boolean;
}

const defaultSendLinkBy: SendLinkByValues = {
	email: '',
	whatsapp: '',
	sms: '',
	loading: false,
};

interface SendLinkProps {
	url: string;
	id: string;
}

const SendLinkTab = ({ id, url }: SendLinkProps) => {
	const { handleShowAlert } = useFloatingAlert();
	const { merchantSelected } = useMerchant();
	const [sendLinkBy, setSendLinkBy] = useState<SendLinkByValues>(defaultSendLinkBy);

	const handleChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setSendLinkBy({ ...sendLinkBy, [name]: value });
	};

	const handleSendLinkByEmail = async () => {
		setSendLinkBy({ ...sendLinkBy, loading: true });

		try {
			await DirectDebitServices.sendSubscriptionByEmail({
				merchantCode: merchantSelected?.code as string,
				email: sendLinkBy.email,
				url: url,
				logo: merchantSelected?.merchant_logo as string,
			});
			handleShowAlert({ message: 'Correo electrónico enviado', type: 'success' });
		} catch (error) {
			handleShowAlert({
				message: 'No pudimos enviar el enlace de pago',
				type: 'error',
			});
		} finally {
			setSendLinkBy({ ...sendLinkBy, loading: false });
		}
	};

	const handleVerifyDisabledButton = () => {
		if (sendLinkBy.email === '') return true;
		return false;
	};

	return (
		<>
			<TabBody id={id}>
				<>
					<Input
						label="Correo electrónico del cliente"
						placeholder="nombre.cliente@correo.com"
						name="email"
						value={sendLinkBy.email}
						onChange={handleChangeInput}
						variant="large"
					/>

					<ButtonContainer>
						<Button
							onClick={handleSendLinkByEmail}
							disabled={handleVerifyDisabledButton()}
							variant="earth"
						>
							{sendLinkBy.loading ? 'Enviando...' : 'Enviar'}
						</Button>
					</ButtonContainer>
				</>
			</TabBody>
		</>
	);
};

export { SendLinkTab };
