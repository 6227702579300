import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { OutboundIcon } from '../../assets/svg/OutboundIcon';
import { Breadcrumb } from '../../components/breadcrumb';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { PayoutsService } from '../../services/payouts';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { DepositMerchantTable } from './components/table';
import { HeadContainer, TableFilterHeader, TitlePage } from './styles';

export interface AlertData {
	concept: string;
	amount: string;
	reference: string;
}

const PayoutsDepositMerchants = () => {
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [total, setTotal] = useState<number>(0);
	const [pagination, setPagination] = useState(defaultPagination);
	const [stateSelected, setStateSelected] = useState<string>('pending_ops');
	const [loadingTop, setLoadingTop] = useState<boolean>(false);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.quick_transfer.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	const dictionary = new Map([
		['payouts', 'Salidas'],
		['deposit-merchants', 'Abonos de merchants'],
	]);

	const {
		data: table,
		isFetching,
		isFetched,
		refetch,
		isLoading,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.payoutsChile.getTableBeneficiary,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () => {
			if (stateSelected === 'pending_ops') {
				return PayoutsService.getAllDepositMerchants({
					merchantCode: merchantSelected?.code as string,
					pagination,
					filters: {
						...filters,
						dateRange: {
							from: filters?.dateRange?.from,
							to: filters?.dateRange?.to,
						},
					},
				});
			} else if (stateSelected === 'processing') {
				return PayoutsService.getAllDepositMerchants({
					merchantCode: merchantSelected?.code as string,
					pagination,
					filters: {
						...filters,
						dateRange: {
							from: filters?.dateRange?.from,
							to: filters?.dateRange?.to,
						},
						stateSelected: 'processing',
					},
				});
			} else if (stateSelected === 'done') {
				return PayoutsService.getAllDepositDone({
					merchantCode: merchantSelected?.code as string,
					pagination,
					filters: {
						...filters,
						dateRange: {
							from: filters?.dateRange?.from,
							to: filters?.dateRange?.to,
						},
					},
				});
			}
		},
		onSuccess: (data) => {
			if (data) {
				setTotal(data.total);
			}
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
		refetch();
	}, [stateSelected]);

	return (
		<ViewWrapper title="Beneficiarios" isLoading={isLoading || isFetching}>
			<HeadContainer>
				<TitlePage>
					<OutboundIcon />
				</TitlePage>
				<Breadcrumb dictionary={dictionary} isLoading={loadingTop} />
			</HeadContainer>

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			<DepositMerchantTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				stateList={[]}
				refetch={refetch}
				setStateSelected={setStateSelected}
				stateSelected={stateSelected}
				setLoadingBreadcrumb={setLoadingTop}
			/>
		</ViewWrapper>
	);
};

export { PayoutsDepositMerchants };
