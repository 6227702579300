import { HTMLAttributes } from 'react';

import { Container, TextareaElement } from './styles';

interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
	name: string;
	value: string;
	rows?: number;
}

const Textarea = ({ ...props }: TextareaProps) => {
	return (
		<Container>
			<TextareaElement {...props}>{props.value}</TextareaElement>
		</Container>
	);
};

export { Textarea };
