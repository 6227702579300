import {
	ItemLoading,
	ListLoading,
	NameLoading,
	ValueLoading,
} from '../../../all-payments/components/banks-pie-chart/styles';
import { EmptyMessage } from '../../../payment-links/components/banks-pie-chart/styles';
import { Item, ItemTitle, List } from './styles';

interface IGraphBanksProps {
	data?: {
		[key: string]: string;
	};
	loading?: boolean;
}

const BankList = ({ data, loading }: IGraphBanksProps) => {
	const hasData = data && Object.keys(data).length > 0;

	return loading ? (
		<>
			{[...Array(5)].map((_, i) => (
				<ListLoading key={i}>
					<ItemLoading>
						<NameLoading />
						<ValueLoading />
					</ItemLoading>
				</ListLoading>
			))}
		</>
	) : hasData ? (
		<List>
			{data &&
				Object.entries(data).map(
					([key, val], i) =>
						(i <= 3 && (
							<Item key={key}>
								{key}
								<ItemTitle>{val}</ItemTitle>
							</Item>
						)) ||
						(key === 'others' && (
							<Item key={key}>
								Otros<ItemTitle>{val}</ItemTitle>
							</Item>
						)),
				)}
		</List>
	) : (
		<EmptyMessage>No se han recibido cobros</EmptyMessage>
	);
};
export { BankList };
