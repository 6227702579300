import { EAtmIcon, PaymentLinksIcon, QuickTransferIcon } from '../assets/svg';
import { NonBankCardIcon } from '../assets/svg/NonBankCardIcon';
import { FilterStatus, WithAllFiltersAndPagination } from './table';

export const productTypeMap = new Map<ProductType, JSX.Element>([
	['PL', <PaymentLinksIcon key="payment-link-icon" />],
	['QT', <QuickTransferIcon key="quick-transfer-icon" />],
	['QM', <QuickTransferIcon key="quick-transfer-icon" />],
	['eATM', <EAtmIcon key="eatm-icon" />],
	['TNB', <NonBankCardIcon key="non-bank-card-icon" />],
]);

export interface GraphBanks {
	name: string;
	value: number;
	value_formatted?: string;
}

export type ProductType = 'PL' | 'QT' | 'QM' | 'eATM' | 'TNB';
export type PrimitiveStatus = 'pending' | 'success' | 'error' | 'expired';
export type PaymentMethodAllowed = 'SPEI' | 'CoDi' | 'Hites' | 'Tapp';
export type Status =
	| 'pending'
	| 'success'
	| 'error'
	| 'expired'
	| 'timeout'
	| 'waiting_codi'
	| 'authentication_error'
	| 'bank_error'
	| 'transaction_error'
	| 'active'
	| 'actived'
	| 'finalized'
	| 'Capturada'
	| 'Liquidada'
	| 'manual_collection'
	| 'management'
	| 'sent_retry'
	| 'pending_retry'
	| 'success_merchant'
	| 'enabled'
	| 'disabled'
	| 'failed'
	| 'completed'
	| 'cancelled'
	| 'process'
	| 'liquidated'
	| 'schedule'
	| 'in_progress'
	| 'return'
	| 'pending_voucher'
	| 'pending_ops'
	| 'processing';

export interface Payment {
	idx: number;
	reference: string;
	productType: ProductType;
	phone?: string;
	amount: string;
	amountWFee?: string;
	bankName: string;
	bankCode: string;
	cantidadCuotas?: string;
	codAutorizacion?: string;
	status: Status;
	paymentId: string;
	transferId?: string;
	lastStep: string;
	clabe: string;
	userClabe: string;
	paymentMethod: PaymentMethodAllowed;
	sessionToken?: string;
	createdatDate: string;
	createdatTime: string;
	validatedatDate?: string;
	validatedatTime?: string;
	verifyCode?: boolean;
	expiredate: string;
	urlCEP?: string;
	validationMailPdf?: string;
	row_num: number;
	typeError?: string;
	rut: string;
	accountType: string;
	account: string;
	email?: string;
	btstep: string;
	paymentConcept: string;
	trackingKey: string;
	codiTransferID: string;
	clientName: string;
	destinationClabe: string;
	amountToSearch: string;
	descriptionError: string;
}

export interface GraphicsFormat {
	x: string;
	y: string | number;
	x_formatted: string;
	y_formatted: string;
	y_abbr?: string;
}

export interface ResponseGraphics {
	amounts?: GraphicsFormat[];
	data: GraphBanks[];
	others?: GraphBanks[];
	total: number;
}

export type PaymentStatus = FilterStatus;

export interface ResponseTable {
	tableInfo: Payment[];
	total: number;
	typeOfStatus: PaymentStatus[];
}

export interface ResponseExport {
	url: string;
}

export interface CommonTableParams extends WithAllFiltersAndPagination {
	merchantCode: string;
	from?: string;
	to?: string;
}
