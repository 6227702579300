import styled from 'styled-components';

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
`;

export const ModalTitle = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 8px;
	text-align: center;
`;

export const ModalSubtitle = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 8px;
	text-align: center;
`;

export const ModalFooter = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	margin-top: 32px;
`;

interface Button {
	readonly color?: string;
}

export const Button = styled.button<Button>`
	background-color: ${({ theme, color }) =>
		color === 'primary' ? theme.colors.principal.pure : theme.colors.principal.orion};
	border: ${({ theme, color }) =>
		color === 'primary'
			? `1px solid ${theme.colors.principal.ceres}`
			: theme.colors.principal.orion};
	border-radius: 8px;
	color: ${({ theme, color }) =>
		color === 'primary' ? theme.colors.principal.vanth : theme.colors.principal.pure};
	cursor: pointer;
	padding: 15px 0;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}
`;
