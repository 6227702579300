import styled from 'styled-components';

interface DropdownContainerProps {
	isOpen: boolean;
	disabled: boolean;
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
	cursor: ${({ disabled }) => !disabled && 'pointer'};
	position: relative;
	width: 100%;

	div > svg:first-child {
		path {
			stroke: ${({ theme }) => theme.colors.principal.moon};
		}

		circle {
			stroke: ${({ theme }) => theme.colors.principal.moon};
		}
	}

	input {
		background-color: ${({ isOpen }) => isOpen && '#fcfcfc'};
		border-color: ${({ isOpen, disabled, theme }) =>
			isOpen && !disabled && theme.colors.principal.moon};
		border-radius: ${({ isOpen, disabled }) => isOpen && !disabled && '8px 8px 0 0'};

		&:focus {
			background-color: #fcfcfc;
			border-color: ${({ disabled }) => !disabled && '#b7b7b7'};
		}
	}
`;

export const List = styled.ul`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.moon};
	border-radius: 0 0 8px 8px;
	list-style: none;
	max-height: 135px;
	overflow-y: scroll;
	padding: 0 1px;
	position: absolute;
	top: 47px;
	width: 100%;
	z-index: 2;

	::-webkit-scrollbar {
		margin-right: 2px;
		width: 6px;
	}

	::-webkit-scrollbar-track {
		background: ${({ theme }) => theme.colors.principal.eris};
		border: 1px solid #fff;
		border-radius: 6px;
		margin-bottom: 4px;
		margin-right: 2px;
		margin-top: 4px;
	}

	::-webkit-scrollbar-thumb {
		background: ${({ theme }) => theme.colors.principal.vanth};
		border: 1px solid #fff;
		border-radius: 6px;
		margin-bottom: 4px;
		margin-left: 2px;
		margin-top: 2px;

		&:hover {
			background: ${({ theme }) => theme.colors.principal.orion};
		}
	}

	hr {
		margin: 0;
	}
`;

export const Item = styled.li`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.moon};
	display: flex;
	flex-direction: row;
	font-size: 16px;
	gap: 8px;
	line-height: 18px;
	padding: 13px 8px;
	width: 100%;

	&:hover {
		background-color: #fef8ed;
		border-radius: 6px;
	}

	svg {
		width: revert-layer;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;
