import styled, { DefaultTheme } from 'styled-components';

import { FloatingAlertTypesAllowed } from '../../context/floating-alert';

interface AlertContainerProps {
	readonly show: boolean;
}

export const AlertContainer = styled.div<AlertContainerProps>`
	display: flex;
	justify-content: center;
	opacity: ${({ show }) => (show ? 1 : 0)};
	position: absolute;
	top: 16px;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	width: 100%;
	z-index: 1000;
`;

interface AlertWrapperProps {
	readonly type: FloatingAlertTypesAllowed;
}

const alertTypes = (theme: DefaultTheme, type: FloatingAlertTypesAllowed) => {
	switch (type) {
		case 'success':
			return theme.colors.principal['light-alamak'];
		case 'error':
			return '#FFE3E4';
		case 'warning':
			return '#FFF1D5';
		default:
			return theme.colors.principal['light-alamak'];
	}
};

export const AlertWrapper = styled.div<AlertWrapperProps>`
	background-color: ${({ theme, type }) => alertTypes(theme, type)};
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	justify-content: center;
	max-width: 100%;
	min-width: 360px;
	padding: 12px 16px;
	position: relative;
	width: fit-content;
`;

export const AlertMessage = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	padding-right: 30px;
`;

export const CloseButton = styled.div`
	cursor: pointer;
	display: flex;
	position: absolute;
	right: 16px;
	top: 10px;

	svg {
		width: 20px;
	}
`;
