import { client } from '../utils/api';

const getPersonalization = async (merchantCode: string) => {
	const params = { merchant_code: merchantCode };
	try {
		const { data } = await client.get('/v1/settings/personalization', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const saveNewColor = async (merchantCode: string, color: string, changeToAllMerchants: boolean) => {
	const params = { merchant_code: merchantCode, allow_to_all_merchants: changeToAllMerchants };
	const body = { color };
	try {
		const { data } = await client.patch('/v1/settings/merchant-config', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const saveNewLogo = async (merchantCode: string, file: File, changeToAllMerchants: boolean) => {
	const params = { merchant_code: merchantCode, allow_to_all_merchants: changeToAllMerchants };
	const ext = (file as File).type;
	const body = file;

	try {
		const { data } = await client.patch('/v1/settings/merchant-config', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'File-Extension': ext,
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const PersonalizationServices = {
	getPersonalization,
	saveNewColor,
	saveNewLogo,
};
