import styled, { css, keyframes } from 'styled-components';

const animation = () => css`
	animation: ${loading} 1.6s infinite linear;
`;

const loading = keyframes`
  0% {
    background-position: -100px;
  }
	
  40%, 100% {
    background-position: 140px;
  }
`;

export const PlaceholderSkeleton = styled.div`
	${animation};
	background-image: linear-gradient(90deg, #ddd 0, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	border-radius: 4px;
	height: 1rem;
	width: 80%;
`;
