import {
	TransferData,
	TransferDetail,
	TransferDetailContainer,
	TransferItem,
} from '../modal/styles';

interface TransferResume {
	item: string;
	data: string | number;
}

interface OutboundResume {
	transferResume: TransferResume[];
	error?: boolean;
}

const OutboundResume = ({ transferResume, error }: OutboundResume) => {
	return (
		<TransferDetail error={error}>
			{transferResume.map(({ item, data }: TransferResume) => (
				<TransferDetailContainer key={item}>
					<TransferItem>{item}</TransferItem>
					<TransferData>{data}</TransferData>
				</TransferDetailContainer>
			))}
		</TransferDetail>
	);
};

export default OutboundResume;
