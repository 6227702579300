import React, { PropsWithChildren, ReactNode } from 'react';

import { SectionContainer, SectionHeader, SectionTitle } from './styles';

interface SectionProps {
	title: string;
	rightSection?: ReactNode | JSX.Element;
}

const Section = ({ children, title, rightSection }: PropsWithChildren<SectionProps>) => {
	return (
		<SectionContainer>
			<SectionHeader>
				<SectionTitle>{title}</SectionTitle>
				{rightSection}
			</SectionHeader>
			{children}
		</SectionContainer>
	);
};

export { Section };
