import { config } from '../config';
import { Process, Suscriptor } from '../models/sockets';
import { getHeaders } from '../utils/get-headers';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WebSockets {
	let ws: WebSocket | undefined;
	let suscriptor: Suscriptor | undefined;

	export const init = (fn?: () => void) => {
		const { headers } = getHeaders();
		const url = `${config.socket_connection}?Authorization=${headers.Authorization}`;
		ws = new WebSocket(url);

		if (ws && config.env === 'development') console.log('websocket connected');

		if (fn) ws.onopen = fn;

		ws.onmessage = (messageEvent: MessageEvent<string>) => {
			if (suscriptor) {
				const event = JSON.parse(messageEvent.data) as Process;
				suscriptor(event);
			}
		};
	};

	export const connected = () => {
		return ws && ws.readyState && ws.readyState === ws.OPEN;
	};

	export const subscribe = (newSuscriptor: Suscriptor) => {
		suscriptor = newSuscriptor;
	};

	export const disconnect = () => {
		if (ws) {
			ws.close();
			ws = undefined;
		}
	};
}
