import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { EyeIcon } from '../../../../assets/svg';
import { Mail } from '../../../../assets/svg/Mail';
import { MailSuccess } from '../../../../assets/svg/MailSuccess';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { Deposit, PayloadDepositUpdate, StatsTablePayout } from '../../../../models/payouts';
import { ColumnInterface, Filters, FilterStatus, Pagination } from '../../../../models/table';
import { PayoutsService } from '../../../../services/payouts';
import { Dropdown } from '../dropdown';
import ModalRejectDeposit from '../modal/reject-deposit';
import {
	CellContainer,
	DropdownButton,
	LabelCompleted,
	LabelFailed,
	TableButton,
	TopMenu,
	TopMenuContainer,
	TopMenuNumber,
	TopMenuOption,
} from './styles';

interface RefundsTableProps {
	data: any[];
	isLoading?: boolean;
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	today?: Date;
	pastDate?: Date;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	stateList: FilterStatus[];
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
	setStateSelected: Dispatch<SetStateAction<string>>;
	stateSelected: string;
	setLoadingBreadcrumb?: Dispatch<SetStateAction<boolean>>;
}

interface Bank {
	name: string;
	code: string;
}

export interface Values {
	account: string;
	amount: string;
	bank: Bank;
	code: string;
	codeOtp: string;
	email: string;
	loading: boolean;
	message: string;
	name: string;
	reference: string;
	requestDate: string;
	rut: string;
	token: string;
}

export const defaultValues: Values = {
	account: '',
	amount: '',
	bank: { name: '', code: '' },
	code: '',
	codeOtp: '',
	email: '',
	loading: false,
	message: '',
	name: '',
	reference: '',
	requestDate: '',
	rut: '',
	token: '',
};

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const defaultRejected: PayloadDepositUpdate = {
	merchant_code: '',
	status: '',
	id: 0,
	merchant_id: '',
};

const defaultStatsTable: StatsTablePayout = {
	total_done: 0,
	total_pending: 0,
	total_processing: 0,
};

const DepositMerchantTable = ({
	data,
	isLoading,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	setStateSelected,
	stateSelected,
	refetch,
	filters,
	setLoadingBreadcrumb,
}: RefundsTableProps) => {
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const [rejected, setRejected] = useState<PayloadDepositUpdate>(defaultRejected);
	const [stats, setStats] = useState<StatsTablePayout>(defaultStatsTable);
	const { handleShowAlert } = useFloatingAlert();

	const handleAllDepositStats = async () => {
		const response = await PayoutsService.allDepositStats(merchantSelected?.code as string);

		setStats(response);
	};

	const handleUpdateDepositRadar = async (payload: PayloadDepositUpdate) => {
		const response = await PayoutsService.updateDepositPayout(payload);

		handleAllDepositStats();
		refetch();

		return response;
	};

	const handleRejectDeposit = async () => {
		const response = await handleUpdateDepositRadar(rejected);

		if (response.code === 'S_OK') {
			handleShowAlert({ type: 'success', message: 'Se rechazó correctamente' });
		} else {
			handleShowAlert({ type: 'error', message: 'Ocurrio un problema' });
		}
	};

	const handleSelectedReject = (reason: number) => {
		setRejected({ ...rejected, error_reason: reason, status: 'failed' });
	};

	const handleStatusView = (status: string) => {
		if (status === 'failed') {
			return <LabelFailed>Fallido</LabelFailed>;
		} else if (status === 'completed') {
			return <LabelCompleted>Exitoso</LabelCompleted>;
		}
	};

	const dropdownOptions = [
		{
			label: 'Verificar',
			onClick: async (rowData: any) => {
				const payloadDeposit = {
					merchant_code: merchantSelected?.code,
					status: 'completed',
					id: rowData.id,
					merchant_id: rowData.merchant_id,
				} as PayloadDepositUpdate;

				const response = await handleUpdateDepositRadar(payloadDeposit);

				if (response.code === 'S_OK') {
					handleShowAlert({ type: 'success', message: 'Se verificó correctamente' });
				} else {
					handleShowAlert({ type: 'error', message: 'Ocurrio un problema' });
				}
			},
		},
		{
			label: 'Rechazar',
			colorCustom: 'red',
			onClick: (rowData: any) => {
				setModal({ ...modal, show: true });

				const payloadDeposit = {
					merchant_code: merchantSelected?.code,
					status: rowData.status,
					id: rowData.id,
					merchant_id: rowData.merchant_id,
				} as PayloadDepositUpdate;

				setRejected(payloadDeposit);
			},
		},
	];

	const callGetUrlImageDeposit = async (id: number) => {
		setLoadingBreadcrumb && setLoadingBreadcrumb(true);
		try {
			const data = await PayoutsService.getUrlImageDeposit(merchantSelected?.code as string, id);
			window.open(data.url, '_blank');
		} catch (e) {
			handleShowAlert({ type: 'error', message: 'Ocurrio un problema' });
		}

		setLoadingBreadcrumb && setLoadingBreadcrumb(false);
	};

	const columns: ColumnInterface<any>[] = [
		{
			sortField: true,
			title: 'Merchant',
			propertyName: 'merchant_payout',
			cellRender: ({ merchant_payout }) => <>{merchant_payout}</>,
		},
		{
			sortField: true,
			title: 'Monto',
			propertyName: 'amount',
			cellRender: ({ amount }) => <>{amount}</>,
		},
		{
			sortField: true,
			title: 'Fecha de peticion',
			propertyName: 'created_at_date',
			cellRender: ({ created_at_date }) => <>{created_at_date}</>,
		},
		{
			title: '',
			propertyName: 'transfer',
			cellRender: (rowData) => (
				<>
					<CellContainer>
						<TableButton
							onClick={() => {
								callGetUrlImageDeposit(rowData.id);
							}}
						>
							Comprobante
							<EyeIcon></EyeIcon>
						</TableButton>
						{stateSelected !== 'done' && (
							<TableButton
								onClick={async () => {
									const payloadDeposit = {
										merchant_code: merchantSelected?.code,
										status: 'processing',
										id: rowData.id,
										merchant_id: rowData.merchant_id,
									} as PayloadDepositUpdate;

									const response = await handleUpdateDepositRadar(payloadDeposit);

									if (response.code === 'S_OK') {
										handleShowAlert({ type: 'success', message: 'Se envió a radar correctamente' });
									} else {
										handleShowAlert({ type: 'error', message: 'Ocurrio un problema' });
									}
								}}
								disabled={stateSelected === 'pending_ops' ? false : true}
							>
								Radar
								{stateSelected === 'pending_ops' ? <Mail></Mail> : <MailSuccess></MailSuccess>}
							</TableButton>
						)}
						{stateSelected !== 'done' && (
							<DropdownButton>
								<Dropdown options={dropdownOptions} rowData={rowData} />
							</DropdownButton>
						)}

						{stateSelected === 'done' && handleStatusView(rowData.status)}
					</CellContainer>
				</>
			),
		},
	];

	const onCloseModal = () => {
		setModal(defaultModal);
	};

	useEffect(() => {
		handleAllDepositStats();
	}, []);

	return (
		<>
			{modal.show && (
				<ModalRejectDeposit
					onClose={() => onCloseModal()}
					onRejected={() => handleRejectDeposit()}
					selectReject={(val) => handleSelectedReject(val)}
				></ModalRejectDeposit>
			)}
			<ETPayTable<Deposit>
				title=""
				optionsTop={
					<TopMenuContainer>
						<TopMenu>
							<TopMenuOption
								isSelected={stateSelected === 'pending_ops' ? true : false}
								onClick={() => setStateSelected('pending_ops')}
							>
								Por Verificar <TopMenuNumber>{stats.total_pending}</TopMenuNumber>
							</TopMenuOption>
							<TopMenuOption
								isSelected={stateSelected === 'processing' ? true : false}
								onClick={() => setStateSelected('processing')}
							>
								En trámite<TopMenuNumber>{stats.total_processing}</TopMenuNumber>
							</TopMenuOption>
							<TopMenuOption
								isSelected={stateSelected === 'done' ? true : false}
								onClick={() => setStateSelected('done')}
							>
								Hechos
							</TopMenuOption>
						</TopMenu>
					</TopMenuContainer>
				}
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				onFilterChange={onFilterChange}
				filters={filters}
				totalResults={totalResults}
				pagination={pagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				setPagination={setPagination}
				hasPagination={(totalResults as number) > pagination.size ? true : false}
			/>
		</>
	);
};

export { DepositMerchantTable };
