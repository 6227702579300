import { Filters, Pagination } from '../models/table';

export const defaultFilters: Filters = {
	dateRange: {
		from: null,
		to: null,
	},
	stateSelected: '',
	typeSelected: '',
	search: '',
};

export const defaultPagination: Pagination = {
	size: 10,
	page: 1,
};
