import styled from 'styled-components';

import { AlignType } from '../../../../models/table';

export const TableHeadTitles = styled.tr`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
`;

interface TableHeadTitleProps {
	readonly alignContent?: AlignType;
	readonly hasCheckbox: boolean;
}

export const TableHeadTitle = styled.th<TableHeadTitleProps>`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 400;
	height: 44px;
	line-height: 1;
	padding: ${({ hasCheckbox }) => hasCheckbox && '0 0 0 16px !important'};
	text-align: ${({ alignContent, hasCheckbox }) => (hasCheckbox ? 'left' : alignContent)};
	width: fit-content;

	svg {
		margin-left: 4px;
		width: 12px;
	}

	div {
		justify-content: ${({ alignContent }) => alignContent};
	}
`;

export const TableHead = styled.thead`
	${TableHeadTitle} {
		padding: 0 16px;
	}
`;

interface SortByContainerProps {
	readonly allowSort: boolean;
	readonly ordered: boolean;
	readonly defaultSort: boolean;
	readonly isActive: boolean;
}

export const SortByContainer = styled.div<SortByContainerProps>`
	cursor: ${({ allowSort }) => (allowSort ? 'pointer' : 'default')};
	display: flex;

	&:hover {
		svg {
			opacity: ${({ allowSort }) => (allowSort ? '1' : '0')};
		}
	}

	svg {
		opacity: ${({ defaultSort, isActive }) => (isActive || defaultSort ? '1' : '0')};
		transform: ${({ ordered }) => (ordered ? 'rotate(-180deg)' : 'rotate(0deg)')};
		transition: opacity, transform 0.2s ease-in-out;
	}
`;
