import { ContainerProgress, Progress } from './styles';

const ProgressBar = () => {
	return (
		<ContainerProgress>
			<Progress />
		</ContainerProgress>
	);
};

export default ProgressBar;
