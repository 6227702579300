import styled from 'styled-components';

export const Container = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	height: 100vh;
	overflow: hidden;
	position: relative;
`;

export const Header = styled.header`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	height: 60px;
	min-width: 200px;
	padding: 0 24px;
	position: relative;
	width: 100%;
	z-index: 5;
`;

export const LogoContainer = styled.div`
	justify-self: center;
`;

interface SidebarContainerProps {
	readonly $isOpen: boolean;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-right: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	display: flex;
	height: 100%;
	justify-content: flex-start;
	max-width: 280px;
	min-width: 280px;
	overflow-y: auto;
	z-index: 4;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		border-left: 1px solid ${({ theme }) => theme.colors.principal.ceres};
		border-top: 1px solid ${({ theme }) => theme.colors.principal.ceres};
		position: absolute;
		right: 0;
		transform: translate(100%);
		transition: 0.3s ease;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		transform: translate(100%);
		width: 100%;
	}

	${({ $isOpen, theme }) =>
		$isOpen &&
		`
		@media (max-width: ${theme.breakpoints.lg}) {
			transform: translate(0);
			transition: 0.3s ease;
			z-index: 10;
		}`};
`;

export const MainSection = styled.div`
	align-items: flex-start;
	display: flex;
	height: calc(100vh - 60px);
	justify-content: flex-start;
	position: relative;
	width: 100%;
`;

export const BodyContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.sirius};
	height: 100%;
	overflow-y: hidden;
	padding: 24px 32px 56px;
	position: relative;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: 16px 20px;
	}
`;
