import styled from 'styled-components';

interface PieTooltipProps {
	readonly tooltipColor: string;
}

export const PieTooltipContainer = styled.div<PieTooltipProps>`
	align-items: center;
	background-color: ${({ tooltipColor }) => tooltipColor};
	border-radius: 8px;
	display: flex;
	min-width: 30px;
	padding: 8px 12px;
	width: fit-content;

	svg {
		&:focus {
			outline: 0 !important;
		}
	}
`;

export const TooltipLabel = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
`;
