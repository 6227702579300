import { Dispatch, SetStateAction, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { Outbound } from '../../../../models/payouts';
import {
	ColumnInterface,
	Filters,
	FilterStatus,
	Pagination,
	RowInterface,
} from '../../../../models/table';
import { PayoutsService } from '../../../../services/payouts';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import {
	AmountContainer,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	Section,
	StatusContainer,
	Title,
	Value,
} from './styles';

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

interface OutboundTableProps {
	data: Outbound[];
	isLoading?: boolean;
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	stateList: FilterStatus[];
	customDate: {
		today: Date;
		pastDate: Date;
	};
}

const OutboundTable = ({
	data,
	isLoading,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	stateList,
	customDate,
}: OutboundTableProps) => {
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();

	const columns: ColumnInterface<Outbound>[] = [
		{
			sortField: true,
			title: 'Referencia',
			propertyName: 'reference',
			cellRender: ({ reference }) => <>{reference}</>,
		},
		{
			sortField: true,
			title: 'Rut',
			propertyName: 'Rut',
			cellRender: ({ rut }) => <>{rut}</>,
		},
		{
			sortField: true,
			title: 'Monto',
			align: 'right',
			propertyName: 'payoutAmount',
			cellRender: ({ payoutAmount }) => <AmountContainer>{payoutAmount}</AmountContainer>,
		},
		{
			sortField: true,
			title: 'Banco',
			propertyName: 'bankName',
			cellRender: ({ bankName }) => <>{bankName}</>,
		},
		{
			sortField: true,
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<StatusContainer>
					<Status status={status} />
				</StatusContainer>
			),
		},
		{
			sortField: true,
			title: 'Fecha de creación',
			propertyName: 'createAt',
			cellRender: ({ createdatDate, createdatTime }) => (
				<DateAndTime date={createdatDate} time={createdatTime} />
			),
		},
		{
			sortField: true,
			title: 'Fecha de operación',
			propertyName: 'createAtPayout',
			cellRender: ({ payoutDate, payoutTime }) => (
				<DateAndTime date={payoutDate} time={payoutTime} />
			),
		},
	];

	const detailSection: RowInterface<Outbound> = (data) => {
		return (
			<DetailsContainer $hasError={!!data.error}>
				{data.error && (
					<Section>
						<Content>
							<Title>Descripción del Fallo</Title>
							<Value>{data.error}</Value>
						</Content>
					</Section>
				)}
				<Section>
					<Content>
						<Title>Nombre del beneficiario</Title>
						<Value>{data.name}</Value>
					</Content>
					<Content>
						<Title>Correo electrónico</Title>
						<Value>{data.email}</Value>
					</Content>
					<Content>
						<Title>Número de cuenta</Title>
						<Value>{data.bankAccount}</Value>
					</Content>
				</Section>
				<Section>
					<Content>
						<Title>Mensaje</Title>
						<Value>{data.message}</Value>
					</Content>
				</Section>
			</DetailsContainer>
		);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await PayoutsService.getExportDataOutbound({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from || customDate.pastDate,
					to: filters?.dateRange?.to || customDate.today,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	const states = ['completed', 'failed', 'pending'];

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			<ETPayTable<Outbound>
				title="Todos las salidas"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={detailSection}
				hasFilters
				hasDateFilter
				allowExport={validatePermission('QT_EXPORT')}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				stateList={fillStatesWithZeros({ states, stateList })}
				statusContent={[
					['completed', 'Exitosos'],
					['pending', 'En proceso'],
					['failed', 'Fallidos'],
				]}
				today={customDate.today}
				pastDate={customDate.pastDate}
			/>
		</>
	);
};

export { OutboundTable };
