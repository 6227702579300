import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

export const loginValidationSchema = Yup.object({
	username: Yup.string().trim().email('Dirección de correo inválida.').required('Campo requerido'),
	password: Yup.string().trim().required('Campo requerido').min(8, ''),
});

export const onlyNumberRegex = new RegExp(/^[0-9]*$/);
export const onlyNumberRegexWithComma = new RegExp(/^[0-9]*[,]?[0-9]*$/);
export const onlyNumberRegexWithDot = new RegExp(/^[0-9]*[.]?[0-9]*$/);
export const onlyNumberRegexWithCommaAndDot = new RegExp(/^[0-9]*[.,]?[0-9]*$/);
export const onlyStringEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateEmail = (email: string): boolean => {
	return onlyStringEmail.test(String(email).toLowerCase());
};

export const addPrefixSymbol = ({ value, symbol }: { value?: string; symbol: string }) => {
	if (value) return value.replace(onlyNumberRegex, `${symbol}${value}`);
	return value;
};

export const verifyEmailSchema = Yup.object({
	email: Yup.string().trim().email('Dirección de correo inválida').required('Campo requerido'),
});

export const verifyCodeSchema = Yup.object({
	code: Yup.string().trim().required('Campo requerido'),
});

export const passResetSchema = Yup.object({
	password: Yup.string()
		.trim()
		.required('Campo requerido')
		.minLowercase(1, 'La contraseña debe tener al menos 1 minúscula')
		.minUppercase(1, 'La contraseña debe tener al menos 1 mayúscula')
		.minNumbers(1, 'La contraseña debe tener al menos 1 número')
		.minSymbols(1, 'La contraseña debe tener al menos 1 carácter especial')
		.min(8, 'La contraseña debe tener al menos 8 caracteres'),
	confirmPassword: Yup.string()
		.trim()
		.required('Campo requerido')
		.oneOf([Yup.ref('password')], 'Las contraseñas no coinciden,'),
});

export const signUpSchema = Yup.object({
	name: Yup.string().trim().required('Campo requerido'),
	lastName: Yup.string().trim().required('Campo requerido'),
	email: Yup.string().trim().email('Dirección de correo inválida').required('Campo requerido'),
	phone: Yup.string().trim().required('Campo requerido').min(9, 'Mínimo 9 dígitos'),
	companyName: Yup.string().trim().required('Campo requerido'),
});

export const createAccountValidationSchema = Yup.object({
	name: Yup.string().trim().required('Campo requerido'),
	lastName: Yup.string().trim().required('Campo requerido'),
	email: Yup.string().trim().email('Dirección de correo inválida').required('Campo requerido'),
	password: Yup.string()
		.trim()
		.required('Campo requerido')
		.minLowercase(1, 'La contraseña debe tener al menos 1 minúscula')
		.minUppercase(1, 'La contraseña debe tener al menos 1 mayúscula')
		.minNumbers(1, 'La contraseña debe tener al menos 1 número')
		.minSymbols(1, 'La contraseña debe tener al menos 1 carácter especial')
		.min(8, 'La contraseña debe tener al menos 8 caracteres'),
});

export const ChangeContactSchema = Yup.object({
	name: Yup.string().trim().required('Campo requerido'),
	lastName: Yup.string().trim().required('Campo requerido'),
	email: Yup.string().trim().email('Dirección de correo inválida').required('Campo requerido'),
	phone: Yup.string().trim().required('Campo requerido').min(10, 'Ingrese número de 10 dígitos'),
	charge: Yup.string().trim().required('Campo requerido'),
});

export const InviteNewUserSchema = Yup.object({
	name: Yup.string().trim().required('Campo requerido'),
	lastName: Yup.string().trim().required('Campo requerido'),
	email: Yup.string().trim().email('Dirección de correo inválida').required('Campo requerido'),
});
