import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { PayloadProps, track } from '../../../analytics';
import { ANALYTICS } from '../../../analytics/constants';
// import { analytics } from '../../../analytics/constants';
// import { PayloadProps, track } from '../../../analytics/index';
import ETPayBG from '../../../assets/images/etpay-bg-02.jpg';
import { EyeIcon, EyeIconOff } from '../../../assets/svg';
import { Alert, AlertProps } from '../../../components/alert';
import { ETPayButton } from '../../../components/button';
import { FormInput } from '../../../components/form/form-input';
import { CONSTANTS } from '../../../config/constants';
import { useAuth } from '../../../hooks/useAuth';
import { useMerchant } from '../../../hooks/useMerchant';
import { AuthLayout } from '../../../layout/auth';
import { AuthServices } from '../../../services/auth';
import { errorFormatter } from '../../../utils/errors';
import { passResetSchema, verifyCodeSchema, verifyEmailSchema } from '../../../utils/validations';
import { AlertContainer, AuthFormContainer, Subtitle, Title } from '../styles';

type Steps = 'reset-password' | 'code-sent' | 'new-password';

interface ResetPasswordForm {
	email: string;
	code: string;
	password: string;
	confirmPassword: string;
	loading: boolean;
}

const defaultValues: ResetPasswordForm = {
	email: '',
	code: '',
	password: '',
	confirmPassword: '',
	loading: false,
};

interface PasswordValues {
	password: boolean;
	confirmPassword: boolean;
}

const defaultPasswordValues: PasswordValues = {
	password: false,
	confirmPassword: false,
};

const defaultAlert: AlertProps = {
	show: false,
	message: '',
	type: 'error',
};

const ResetPassword = () => {
	const [steps, setSteps] = useState<Steps>('reset-password');
	const { handleLogin, user } = useAuth();
	const { handleGetMerchants } = useMerchant();
	const [alert, setAlert] = useState(defaultAlert);
	const [state, setState] = useState<ResetPasswordForm>(defaultValues);
	const [showPassword, setShowPassword] = useState<PasswordValues>(defaultPasswordValues);

	const { isLoading } = useQuery({
		enabled: !!user,
		queryKey: [CONSTANTS.services.merchant.getMerchantsList],
		queryFn: () => handleGetMerchants(),
	});

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.reset_password.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<AuthLayout
			tabTitle="Restablecer contraseña"
			image={ETPayBG}
			buttonTitle="¿Ya tienes una cuenta?"
			link="/login"
			linkLabel="Inicia sesión"
		>
			<AlertContainer>
				<Alert show={alert.show} message={alert.message} hasIcon type={alert.type} />
			</AlertContainer>

			<AuthFormContainer>
				{steps === 'reset-password' && (
					<>
						<Title>Restablecer contraseña</Title>
						<Subtitle>
							Te enviaremos un código de verificación a la dirección de correo asociada a tu cuenta.
						</Subtitle>
						<Subtitle>
							Es posible que termine en la carpeta de correo no deseado, por favor revísala también.
						</Subtitle>

						<Formik
							initialValues={defaultValues}
							onSubmit={async ({ email }) => {
								setState({ ...state, loading: true });
								setAlert(defaultAlert);
								try {
									await AuthServices.verifyEmail(email);
									setSteps('code-sent');
								} catch (e: any) {
									setAlert({
										show: true,
										message: errorFormatter(e.code),
										type: 'error',
									});
								} finally {
									setState({ ...state, loading: false });
								}
							}}
							validationSchema={verifyEmailSchema}
						>
							{({ isValid, dirty, handleChange }) => (
								<Form>
									<FormInput
										type="text"
										label="Correo electrónico"
										name="email"
										autoComplete="off"
										onChange={(e) => {
											handleChange(e);
											setState({ ...state, email: e.target.value });
										}}
									/>

									<ETPayButton
										title={state.loading ? 'Enviando...' : 'Enviar'}
										loading={state.loading}
										variant="effect"
										type="submit"
										active={isValid && dirty}
									/>
								</Form>
							)}
						</Formik>
					</>
				)}

				{steps === 'code-sent' && (
					<>
						<Title>Verifica tu identidad</Title>
						<Subtitle>
							Para proteger tu cuenta, hemos enviado un correo electrónico con un código de
							verificación a {state.email}
						</Subtitle>

						<Formik
							initialValues={defaultValues}
							onSubmit={async ({ code }) => {
								setState({ ...state, loading: true });
								setAlert(defaultAlert);
								try {
									await AuthServices.verifyCode({ email: state.email, code });
									setSteps('new-password');
								} catch (e: any) {
									setAlert({
										show: true,
										message: errorFormatter(e.code),
										type: 'error',
									});
								} finally {
									setState({ ...state, loading: false });
								}
							}}
							validationSchema={verifyCodeSchema}
						>
							{({ isValid, dirty, handleChange }) => (
								<Form>
									<FormInput
										type="text"
										label="Código de verificación"
										name="code"
										autoComplete="off"
										onChange={(e) => {
											handleChange(e);
											setState({ ...state, code: e.target.value });
										}}
									/>

									<ETPayButton
										title={state.loading ? 'Verificando...' : 'Verificar'}
										loading={state.loading}
										variant="effect"
										type="submit"
										active={isValid && dirty}
									/>
								</Form>
							)}
						</Formik>
					</>
				)}

				{steps === 'new-password' && (
					<>
						<Title>Restablecer contraseña</Title>
						<Subtitle>
							Elige una contraseña que resulte difícil de adivinar y que sea única para esta cuenta.
						</Subtitle>

						<Formik
							initialValues={defaultValues}
							onSubmit={async ({ password, confirmPassword }) => {
								setState({ ...state, loading: true });
								setAlert(defaultAlert);
								try {
									await AuthServices.sendNewPassword({
										email: state.email,
										code: state.code,
										password,
										confirmPassword,
									});
									await handleLogin({
										username: state.email,
										password,
									});
								} catch (e: any) {
									setAlert({
										show: true,
										message: errorFormatter(e.code),
										type: 'error',
									});
								} finally {
									setState({ ...state, loading: false });
								}
							}}
							validationSchema={passResetSchema}
						>
							{({ isValid, dirty, handleChange }) => (
								<Form>
									<FormInput
										type={showPassword.password ? 'text' : 'password'}
										label="Contraseña"
										name="password"
										autoComplete="off"
										rightIcon={showPassword.password ? <EyeIcon /> : <EyeIconOff />}
										rightIconClick={() =>
											setShowPassword({ ...showPassword, password: !showPassword.password })
										}
										onChange={(e) => {
											handleChange(e);
											setState({ ...state, password: e.target.value });
										}}
									/>

									<FormInput
										type={showPassword.confirmPassword ? 'text' : 'password'}
										label="Confirmar contraseña"
										name="confirmPassword"
										autoComplete="off"
										rightIcon={showPassword.confirmPassword ? <EyeIcon /> : <EyeIconOff />}
										rightIconClick={() =>
											setShowPassword({
												...showPassword,
												confirmPassword: !showPassword.confirmPassword,
											})
										}
										onChange={(e) => {
											handleChange(e);
											setState({ ...state, confirmPassword: e.target.value });
										}}
									/>

									<ETPayButton
										title={
											state.loading
												? 'Iniciando sesión...'
												: isLoading
												? 'Redireccionando'
												: 'Guardar'
										}
										loading={state.loading}
										variant="effect"
										type="submit"
										active={isValid && dirty}
									/>
								</Form>
							)}
						</Formik>
					</>
				)}
			</AuthFormContainer>
		</AuthLayout>
	);
};

export { ResetPassword };
