interface SuccesIconProps {
	color?: string;
}
export const SuccessIcon = ({ color = '#72DBAA' }: SuccesIconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
		<circle cx="21.668" cy="21.5" r="21.1417" fill={color} stroke={color} strokeWidth="0.716667" />
		<path
			d="M30.6257 15.7667L18.6812 27.9501L12.709 21.8584"
			stroke="white"
			strokeWidth="5.73333"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
