import { useState } from 'react';

import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useMerchant } from '../../../../hooks/useMerchant';
import { CountriesAllowed } from '../../../../models/merchant';
import { Deposit } from '../../../../models/payouts';
import { ColumnInterface } from '../../../../models/table';
import { formatNumber } from '../../../../utils/numbers';
import { StatusContainer } from '../../styles';
import { Download, ExportModalContent, ExportModalDescription, ExportModalTitle } from './styles';

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

interface Bank {
	name: string;
	code: string;
}

export interface Values {
	account: string;
	amount: string;
	bank: Bank;
	code: string;
	codeOtp: string;
	email: string;
	loading: boolean;
	message: string;
	name: string;
	reference: string;
	requestDate: string;
	rut: string;
	token: string;
}

export const defaultValues: Values = {
	account: '',
	amount: '',
	bank: { name: '', code: '' },
	code: '',
	codeOtp: '',
	email: '',
	loading: false,
	message: '',
	name: '',
	reference: '',
	requestDate: '',
	rut: '',
	token: '',
};

const DepositTable = ({
	data,
	isLoading,
	pagination,
	setPagination,
	totalResults,
	onFilterChange,
	filters,
}: any) => {
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { merchantSelected } = useMerchant();

	const columns: ColumnInterface<Deposit>[] = [
		{
			sortField: true,
			title: 'Monto abonado',
			propertyName: 'amount',
			cellRender: ({ amount }) => (
				<>
					$
					{formatNumber({
						value: amount as number,
						country: merchantSelected?.country as CountriesAllowed,
					})}
				</>
			),
		},
		{
			sortField: true,
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<>
					<StatusContainer>
						<Status status={status} />
					</StatusContainer>
				</>
			),
		},
		{
			sortField: true,
			title: 'Fecha de creación',
			propertyName: 'createdatDate',
			cellRender: ({ created_at_date: createdatDate, created_at_time: createdatTime }) => (
				<DateAndTime date={createdatDate} time={createdatTime} />
			),
		},
	];

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<Download href={modal.url} download="beneficiarios.csv">
								Descargar reporte
							</Download>
						</ExportModalContent>
					)}
				</Modal>
			)}

			<ETPayTable<Deposit>
				title="Últimos depósitos"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				onFilterChange={onFilterChange}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				setPagination={setPagination}
				filters={filters}
			/>
		</>
	);
};

export { DepositTable };
