import { addDays } from 'date-fns';
import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { LeftAngleIcon, RightAngleIcon } from '../../../../assets/svg';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useMerchant } from '../../../../hooks/useMerchant';
import { DateFilterProps, DateRange } from '../../../../models/table';
import {
	calendarFormat,
	getDistanceBetweenDates,
	getMonthAndYearFormat,
	isNDaysAgo,
	transformFilterDate,
} from '../../../../utils/dates';
import { ETPayButton } from '../../../button';
import {
	ChangeMonthContainer,
	ContainerFilterTime,
	ContainerShow,
	CurrentMonthYear,
	DatePickerHeader,
	ErrorMessage,
	FilterArrowIcon,
	FilterTimeInput,
	FilterTimeLabel,
	HeaderMonthYearSelected,
	ShowDatePickerButton,
	ShowDatePickerContainer,
} from './styles';

interface HasError {
	message: string;
	isError: boolean;
}

const defaultHasError: HasError = {
	message: '',
	isError: false,
};

const DateFilter = ({
	pagination,
	setPagination,
	filters,
	onFilterChange,
	today,
	pastDate,
	thisMonth,
	maxDate
}: DateFilterProps) => {
	const { merchantSelected } = useMerchant();
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [range, setRange] = useState<DateRange>({
		from: filters?.dateRange?.from,
		to: filters?.dateRange?.to,
	});
	const [hasError, setHasError] = useState<HasError>(defaultHasError);
	const elementRef = useRef<HTMLDivElement>(null);

	const handleShowDatePicker = () => {
		setShowDatePicker(!showDatePicker);
		setHasError({ ...defaultHasError });
	};
	const onChange = (dates: [Date | null, Date | null]) => {
		setHasError({ ...defaultHasError });
		const [start, end] = dates;
		const validateDays = (merchantSelected && merchantSelected?.query_days_back - 1) || 6;

		if (start) setRange({ from: start, to: end });

		if (start === end) {
			setRange({ from: start, to: null });
		}
		if (end && start && end < addDays(start, validateDays)) {
			setRange({ from: start, to: end });
		}
		if (end && start && end > addDays(start, validateDays)) {
			setRange({ from: start, to: addDays(start, validateDays) });
			setHasError({
				message: `El periodo máximo de consulta es de ${merchantSelected?.query_days_back} días.`,
				isError: true,
			});
		}
	};

	const handleApply = () => {
		if (!!range.from && !!range.to && onFilterChange && setPagination && filters && pagination) {
			onFilterChange({ ...filters, dateRange: { from: range.from, to: range.to } });
			setPagination({ ...pagination, page: 1 });
			setShowDatePicker(false);
		}
	};

	useClickOutside({ elementRef, callback: () => setShowDatePicker(false) });

	return (
		<>
			<ContainerFilterTime ref={elementRef}>
				<ContainerShow>
					<FilterTimeLabel>
						{getDistanceBetweenDates({
							from: range.from || pastDate,
							to: range.to || today,
							thirtyDays: isNDaysAgo(30, range.to || today, range.from || pastDate),
							sevenDays: isNDaysAgo(7, range.to || today, range.from || pastDate),
							thisMonth: thisMonth
						})}
					</FilterTimeLabel>

					<FilterTimeInput onClick={handleShowDatePicker}>
						{transformFilterDate({
							from: range.from || pastDate,
							to: range.to || today,
						})}
						<FilterArrowIcon />
					</FilterTimeInput>
				</ContainerShow>

				{showDatePicker && (
					<ShowDatePickerContainer y="bottom" x="right">
						<DatePicker
							locale="es"
							selected={range.to}
							startDate={range.from}
							endDate={range.to}
							onChange={onChange}
							maxDate={maxDate || new Date()}
							selectsRange
							inline
							renderCustomHeader={(params) => (
								<>
									<HeaderMonthYearSelected>
										<ChangeMonthContainer
											onClick={() => {
												if (!params.prevMonthButtonDisabled) params.decreaseMonth();
											}}
											allowClick={!params.prevMonthButtonDisabled}
										>
											<LeftAngleIcon />
										</ChangeMonthContainer>

										<CurrentMonthYear>{getMonthAndYearFormat(params.date)}</CurrentMonthYear>

										<ChangeMonthContainer
											onClick={() => {
												if (!params.nextMonthButtonDisabled) params.increaseMonth();
											}}
											allowClick={!params.nextMonthButtonDisabled}
										>
											<RightAngleIcon />
										</ChangeMonthContainer>
									</HeaderMonthYearSelected>

									{hasError.isError && <ErrorMessage>{hasError.message}</ErrorMessage>}

									<DatePickerHeader>
										{range.from && range.to ? (
											<>
												<span>{calendarFormat(range.from)}</span>
												<span>-</span>
												<span>{calendarFormat(range.to)}</span>
											</>
										) : (
											<span>{calendarFormat(new Date())}</span>
										)}
									</DatePickerHeader>
								</>
							)}
						/>

						<ShowDatePickerButton>
							<ETPayButton title="Cancelar" onClick={handleShowDatePicker} color="text" />
							<ETPayButton title="Aplicar" onClick={handleApply} color="secondary" />
						</ShowDatePickerButton>
					</ShowDatePickerContainer>
				)}
			</ContainerFilterTime>
		</>
	);
};

export { DateFilter };
