import styled from 'styled-components';

export const LoadingContent = styled.div`
	backdrop-filter: blur(6px);
	height: 100%;
	left: 120px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		left: 0;
	}
`;

export const CenterContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
`;

export const SpinnerContainer = styled.div`
	align-items: center;
	background: ${({ theme }) => theme.colors.principal.pure};
	border: #e6e6e6;
	border-radius: 12px;
	box-shadow: 0 4px 24px 0 rgb(100 97 116 / 20%);
	display: flex;
	padding: 42px 31px;
	position: absolute;
	top: 40%;
	width: 266px;

	p {
		color: ${({ theme }) => theme.colors.principal.orion};
	}
`;
