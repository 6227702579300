interface ColorPreviewProps {
	fill?: string;
}

export const ColorPreview = ({ fill }: ColorPreviewProps) => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="2" y="2" width="18" height="18" rx="2" fill={fill} />
		<rect
			opacity="0.2"
			x="2.75"
			y="2.75"
			width="16.5"
			height="16.5"
			rx="1.25"
			stroke="#140F33"
			strokeWidth="1.5"
		/>
	</svg>
);
