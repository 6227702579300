import styled from 'styled-components';

export const ErrorBoundaryContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 100%;
`;

export const ErrorBoundaryTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 38px;
	font-weight: 500;
	line-height: 110%;
	margin-bottom: 24px;
`;

export const ErrorBoundarySubtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 40px;
`;

export const ErrorBoundaryButtonContainer = styled.div`
	width: 200px;
`;
