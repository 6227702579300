import { ElementType, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LeftAngleIcon, RightArrowIcon } from '../../../../assets/svg';
import { Separator } from '../../../../components/separator';
import { useAuth } from '../../../../hooks/useAuth';
import { useMerchant } from '../../../../hooks/useMerchant';
import { CountriesAllowed, Products } from '../../../../models/merchant';
import { navigation } from '../../../../routes/navigation';
import { getRoleLabelById } from '../../../../utils/common';
import {
	BackIconContainer,
	ButtonItem,
	LinkContainer,
	LinkItem,
	ModuleContainer,
	ModuleItem,
	ModuleTitle,
	OnlyMobileContainer,
	SidebarContainer,
	TitleInteractiveSection,
	TitleSection,
} from './styles';

interface SidebarProps {
	closeMenu: () => void;
}

const Sidebar = ({ closeMenu }: SidebarProps) => {
	const { merchantSelected, isEnterpriseSelected } = useMerchant();
	const flowBanksOrStp = true;
	const [navigationState, setNavigationState] = useState(navigation);
	const [interactiveMenu, setInteractiveMenu] = useState<string | null>(null);
	const { handleLogout } = useAuth();

	const { pathname } = useLocation();
	const lastPathname = pathname.substring(1).split('/').length - 1;
	const _pathname = pathname.substring(1).split('/')[lastPathname];
	const [itemActive, setItemActive] = useState<string>(_pathname);

	useEffect(() => {
		setItemActive(_pathname);
	}, [merchantSelected, pathname, isEnterpriseSelected]);
	const updatePayoutsShow = () => {
		setNavigationState((prevNavigation) => {
			return prevNavigation.map((navItem) => {
				if (navItem.id === 'payouts-cl') {
					return {
						...navItem,
						show: merchantSelected?.country === 'CL',
					};
				}
				if (navItem.id === 'outbound') {
					return {
						...navItem,
						show: merchantSelected?.country === 'MX',
					};
				}
				if (navItem.id === 'management') {
					return {
						...navItem,
						children: navItem.children.map((childItem) => {
							if (childItem.id === 'refunds' && childItem.productName === 'payouts') {
								if (!flowBanksOrStp) {
									const moduleBank = childItem.modules?.find(
										(moduleChild) => moduleChild.id === 'consult_refunds',
									);
									const updatedModule = childItem.modules?.map((moduleChild) => {
										return {
											...moduleChild,
											show: false,
										};
									});
									return {
										...childItem,
										navigation: moduleBank?.navigation,
										rolesAllowed: moduleBank?.rolesAllowed,
										modules: updatedModule,
										show: merchantSelected?.country === 'MX',
									};
								}

								const updatedModule = childItem.modules?.map((moduleChild) => {
									return {
										...moduleChild,
										show: moduleChild.id === 'refunds_banks' ? false : true,
									};
								});

								return {
									...childItem,
									navigation: undefined,
									rolesAllowed: undefined,
									modules: updatedModule,
									show: merchantSelected?.country === 'MX',
								};
							}
							return childItem;
						}),
					};
				}
				return navItem;
			});
		});
	};

	useEffect(() => {
		updatePayoutsShow();
	}, [merchantSelected]);

	return (
		<SidebarContainer>
			{navigationState.map((section, i) => {
				const hasChildrenToShow = section.children.some((child) => child.show);

				const hasChildrenToShowModule = section.children.some((child) => child.modules);
				const hasPayouts =
					merchantSelected?.products.payouts &&
					section.children.some((child) => child.id === 'payouts-cl');

				const someChildrenWithPermissions = section.children.some(
					(ele) =>
						ele.rolesAllowed?.includes(
							getRoleLabelById(
								merchantSelected?.role_id as number,
								merchantSelected?.role_id as number,
							),
						) && (ele.productName ? merchantSelected?.products[ele.productName] : true),
				);

				const InteractiveTitle = section.interactiveMenu ? TitleInteractiveSection : TitleSection;

				const CustomElement: ElementType = section.children.some(
					(child) => child.modules && child.navigation,
				)
					? LinkItem
					: ModuleTitle;

				return (
					section.show &&
					(interactiveMenu === null || interactiveMenu === section.id) && (
						<Fragment key={i}>
							{!isEnterpriseSelected &&
							section.title &&
							hasChildrenToShow &&
							someChildrenWithPermissions ? (
								<InteractiveTitle
									onClick={() => {
										if (section.id === 'settings' && interactiveMenu === null) {
											section.interactiveMenu && setInteractiveMenu(section.id);
										} else if (interactiveMenu === 'settings') {
											setInteractiveMenu(null);
										}
									}}
									style={{ cursor: section.id === 'settings' ? 'pointer' : 'initial' }}
								>
									{section.interactiveMenu && interactiveMenu && (
										<BackIconContainer
											onClick={() => {
												section.interactiveMenu && setInteractiveMenu(null);
											}}
										>
											<LeftAngleIcon />
										</BackIconContainer>
									)}

									{section.title}

									{section.interactiveMenu && !interactiveMenu && (
										<div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
											<RightArrowIcon color="#b7b7b7" />
										</div>
									)}
								</InteractiveTitle>
							) : hasChildrenToShowModule && hasPayouts ? (
								<InteractiveTitle>
									{section.interactiveMenu && interactiveMenu && (
										<BackIconContainer
											onClick={() => {
												section.interactiveMenu && setInteractiveMenu(null);
											}}
										>
											<LeftAngleIcon />
										</BackIconContainer>
									)}
									{section.title}
									{section.interactiveMenu && !interactiveMenu && (
										<div
											style={{ marginLeft: 'auto', cursor: 'pointer' }}
											onClick={() => {
												section.interactiveMenu && setInteractiveMenu(section.id);
											}}
										>
											<RightArrowIcon color="#b7b7b7" />
										</div>
									)}
								</InteractiveTitle>
							) : null}

							{section.children.map((item) => {
								const isActive = item.navigation ? itemActive.includes(item.navigation) : false;
								const show =
									typeof item.show === 'function'
										? item.show(merchantSelected?.country as CountriesAllowed)
										: item.show;
								const allowShowByMerchantCountrySelected = item.productName
									? merchantSelected?.products[item.productName]
									: true;
								const hasMoreThanOneProductActive =
									merchantSelected?.products &&
									[
										'quick_transfer',
										'payment_link',
										'business_transfer',
										'payment_link_multiuso',
										'non_bank_cards',
									].reduce(
										(acc, ele) =>
											merchantSelected?.products[ele as keyof Products] ? acc + 1 : acc,
										0,
									) > 1;

								const allowShowByRole = item.rolesAllowed
									? item.rolesAllowed.includes(
											getRoleLabelById(
												merchantSelected?.role_id as number,
												merchantSelected?.role_id as number,
											),
									  )
									: true;

								if (item.id === 'home' && allowShowByRole) {
									return (
										<Fragment key={item.id}>
											<LinkContainer>
												<LinkItem
													$active={itemActive === 'enterprise' || itemActive === ''}
													$colorIcon={item.colorIcon}
													to={isEnterpriseSelected ? '/enterprise' : '/'}
													$name={item.id}
													onClick={(event) => {
														event.stopPropagation();
														closeMenu();
													}}
												>
													{item.icon && (
														<item.icon
															active={itemActive === 'enterprise' || itemActive === ''}
															color={item.colorIcon || undefined}
														/>
													)}
													{item.name}
												</LinkItem>
											</LinkContainer>

											<Separator />
										</Fragment>
									);
								}

								return item.id === 'all-payments' ? (
									!section.interactiveMenu &&
									allowShowByRole &&
									(hasMoreThanOneProductActive || isEnterpriseSelected) ? (
										<LinkContainer key={item.id}>
											<LinkItem
												$colorIcon={item.colorIcon}
												$name={item.id}
												$active={isActive}
												to={`/${
													isEnterpriseSelected ? `enterprise/${item.navigation}` : item.navigation
												}`}
												onClick={(event) => {
													event.stopPropagation();
													closeMenu();
												}}
											>
												{item.icon && <item.icon active={isActive} color={item.colorIcon} />}
												{item.name}
											</LinkItem>
										</LinkContainer>
									) : null
								) : show &&
								  allowShowByMerchantCountrySelected &&
								  allowShowByRole &&
								  !isEnterpriseSelected &&
								  !item.modules &&
								  !section.interactiveMenu ? (
									<LinkContainer
										key={item.id}
										onClick={(event) => {
											event.stopPropagation();
											closeMenu();
										}}
									>
										<LinkItem
											to={`/${
												isEnterpriseSelected ? `enterprise/${item.navigation}` : item.navigation
											}`}
											$colorIcon={item.colorIcon}
											$active={isActive}
											$name={item.id}
											onClick={(event) => {
												event.stopPropagation();
												closeMenu();
											}}
										>
											{item.icon && <item.icon active={isActive} color={item.colorIcon} />}
											{item.name}
										</LinkItem>
									</LinkContainer>
								) : (
									show &&
									allowShowByMerchantCountrySelected &&
									allowShowByRole &&
									!isEnterpriseSelected &&
									(section.children.some((child) => child.modules) ? (
										<ModuleContainer key={item.id}>
											<CustomElement
												{...(item.navigation && { to: item.navigation })}
												$colorIcon={item.colorIcon}
												$name={item.id}
												$active={item.navigation ? itemActive.includes(item.navigation) : false}
											>
												{item.icon && (
													<item.icon
														active={
															item.navigation
																? itemActive.includes(item.navigation) ||
																  !!item.modules?.some(
																		(module) =>
																			module.navigation.split('/')[
																				module.navigation.split('/').length - 1
																			] === itemActive,
																  )
																: false
														}
														color={item.colorIcon}
													/>
												)}
												{item.name}
											</CustomElement>

											{item.modules &&
												item.modules.length > 0 &&
												item.modules.map((module) => {
													const isActive =
														module.navigation.split('/')[
															module.navigation.split('/').length - 1
														] === itemActive;

													const allowShowByRoleModule = module.rolesAllowed
														? module.rolesAllowed.includes(
																getRoleLabelById(
																	merchantSelected?.role_id as number,
																	merchantSelected?.role_id as number,
																),
														  )
														: true;

													const show =
														typeof module.show === 'function'
															? module.show(merchantSelected?.country as CountriesAllowed)
															: module.show;

													return show && allowShowByRoleModule ? (
														<ModuleItem
															key={module.id}
															to={module.navigation}
															$active={isActive}
															onClick={(event) => {
																event.stopPropagation();
																closeMenu();
															}}
														>
															{module.name}
														</ModuleItem>
													) : null;
												})}
										</ModuleContainer>
									) : (
										interactiveMenu === section.id && (
											<ModuleContainer key={item.id}>
												<LinkItem
													to={`/${
														isEnterpriseSelected ? `enterprise/${item.navigation}` : item.navigation
													}`}
													$colorIcon={item.colorIcon}
													$active={isActive}
													$name={item.id}
													onClick={(event) => {
														event.stopPropagation();
														closeMenu();
													}}
												>
													{item.icon && (
														<item.icon
															active={itemActive === item.navigation}
															color={item.colorIcon}
														/>
													)}
													{item.name}
												</LinkItem>
											</ModuleContainer>
										)
									))
								);
							})}

							{!isEnterpriseSelected &&
								navigation.length - 1 !== i &&
								section.show &&
								section.title &&
								hasChildrenToShow &&
								someChildrenWithPermissions && <Separator />}
						</Fragment>
					)
				);
			})}

			<OnlyMobileContainer>
				<Separator />
				<LinkItem
					$name="Mi perfil"
					$active={itemActive === 'my-profile'}
					to="/my-profile"
					onClick={(event) => {
						event.stopPropagation();
						closeMenu();
					}}
				>
					Mi perfil
				</LinkItem>
				<Separator />
				<ButtonItem onClick={handleLogout}>Cerrar sesión</ButtonItem>
			</OnlyMobileContainer>
		</SidebarContainer>
	);
};

export { Sidebar };
