import styled, { css, keyframes } from 'styled-components';

const animation = () => css`
	animation: ${loading} 1.6s infinite linear;
`;

const loading = keyframes`
  0% {
    background-position: -100px;
  }

  40%, 100% {
    background-position: 140px;
  }
`;

export const Wrapper = styled.div`
	width: 50%;
`;

export const ContainerWrapper = styled.div`
	display: flex;
	gap: 16px;
	height: 100%;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 144px;
	}
`;

export const Item = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-left: 14px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
	}

	&::after {
		border-radius: 8px;
		bottom: 0;
		content: '';
		height: 8px;
		left: 0;
		margin: auto;
		position: absolute;
		top: 0;
		width: 8px;
	}
`;

interface ItemProps {
	readonly bg?: string;
}

export const List = styled.div<ItemProps>`
	width: 100%;

	${Item} {
		&::after {
			background-color: ${({ bg }) => bg};
		}
	}
`;

export const Value = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
`;

export const ListLoading = styled.div<ItemProps>`
	width: 100%;
`;

export const ItemLoading = styled.p`
	align-items: center;
	background-color: white;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-left: 14px;
	position: relative;
`;

export const NameLoading = styled.span`
	${animation};
	background-color: #eee;
	background-image: linear-gradient(90deg, #ddd 0, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	border-radius: 4px;
	height: 16px;
	width: 200px;
`;

export const ValueLoading = styled.span`
	${animation};
	background-image: linear-gradient(90deg, #ddd 0, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	border-radius: 4px;
	height: 16px;
	width: 50px;
`;
