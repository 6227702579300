import { useScreenSize } from '../../../../hooks/useScreenSize';
import { ChartBar } from '../../bar';
import { CustomizedTick } from '../customized-tick';
import LoadingDataGraph from './LoadingDataGraph.json';

const dataLoadingMap = LoadingDataGraph.fakeDataGraph.map((item) => ({
	x: item.x,
	y: item.y,
	x_formatted: item.x_formatted,
	y_formatted: item.y_formatted,
	y_abbr: item.y_abbr,
}));

interface BarGraphProps {
	barBackground?: string;
}

const AmountsBarGraphLoading = ({ barBackground = '#fcfcfc' }: BarGraphProps) => {
	const { width } = useScreenSize();

	const dataSliceLoading =
		width > 768
			? dataLoadingMap
			: width > 576
			? dataLoadingMap?.slice(dataLoadingMap.length - 13)
			: dataLoadingMap?.slice(dataLoadingMap.length - 8);

	return (
		<ChartBar
			data={dataSliceLoading}
			barColor={'#c6ccd0'}
			barBackground={barBackground}
			customTick={(props) => <CustomizedTick {...props} position="left" symbol="$" />}
			tooltipColor=""
			domain={[]}
		/>
	);
};

export { AmountsBarGraphLoading };
