import { SVGProps } from 'react';

export const DownIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		{...props}
	>
		<path
			d="M16.5 8.25L11 13.75L5.5 8.25"
			stroke="#140F33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
