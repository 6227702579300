import { ChangeEvent, useEffect, useState } from 'react';

import { ETPayTableProps } from '../../models/table';
import { ETPayButton } from '../button';
import { ETPayTableBodySkeleton } from '../skeleton-placeholder';
import { ETPayTableBody } from './components/body';
import { DateFilter } from './components/date-filter';
import { ETPayEmptyBody } from './components/empty-body';
import { ETPayFilters } from './components/filters';
import { ETPayTableHeader } from './components/header';
import { ETPAyPagination } from './components/pagination';
import {
	DateFilterExportWrapper,
	Table,
	TableContainer,
	TableHeader,
	TableTitle,
	TableWrapper,
} from './styles';

const ETPayTable = <Entity,>({
	title,
	secondaryAction,
	data,
	emptyMessage,
	isLoading,
	columns,
	customFilter,
	row,
	hasCheckbox = false,
	checkboxSelectedCallback,
	hasFilters = false,
	hasDateFilter = false,
	stateList,
	statusContent,
	filters,
	onFilterChange,
	allowCustomFilter,
	hasSearch = false,
	allowExport = false,
	exportAction,
	hasPaginationSelect = true,
	hasPagination = false,
	setPagination,
	totalResults,
	pagination,
	today,
	pastDate,
	statusWithCircle,
	thisMonth,
	optionsTop,
	maxDate,
}: ETPayTableProps<Entity>) => {
	const [checkboxSelected, setCheckboxSelected] = useState<string[]>([]);
	const totalColumns = hasCheckbox ? columns.length + 1 : columns.length;

	const handleCheckboxClicked = (e: ChangeEvent<HTMLInputElement>) => {
		const { name } = e.target;
		if (name === 'all') {
			if (checkboxSelected.length === data.length) {
				setCheckboxSelected([]);
			} else {
				setCheckboxSelected(data.map((_, i) => `checkbox-${i}`));
			}
			return;
		}

		if (checkboxSelected.includes(name)) {
			setCheckboxSelected(checkboxSelected.filter((item) => item !== name));
		} else {
			setCheckboxSelected([...checkboxSelected, name]);
		}
	};

	useEffect(() => {
		if (checkboxSelectedCallback) {
			checkboxSelectedCallback(checkboxSelected);
		}
	}, [checkboxSelected]);

	return (
		<TableContainer>
			{optionsTop && optionsTop}

			{(title || hasFilters || hasDateFilter) && (
				<TableHeader>
					{title && <TableTitle>{title}</TableTitle>}
					{secondaryAction && secondaryAction}

					<DateFilterExportWrapper>
						{hasFilters && hasDateFilter && (
							<DateFilter
								filters={filters}
								onFilterChange={onFilterChange}
								pagination={pagination}
								setPagination={setPagination}
								today={today}
								pastDate={pastDate}
								thisMonth={thisMonth}
								maxDate={maxDate}
							/>
						)}

						{allowExport && exportAction && (
							<ETPayButton title="Exportar" onClick={exportAction} color="secondary" />
						)}
					</DateFilterExportWrapper>
				</TableHeader>
			)}

			{hasFilters && (
				<ETPayFilters
					stateList={stateList}
					filters={filters}
					onFilterChange={onFilterChange}
					pagination={pagination}
					setPagination={setPagination}
					hasSearch={hasSearch}
					isLoading={isLoading}
					customFilter={customFilter}
					allowCustomFilter={allowCustomFilter}
					statusContent={statusContent}
					statusWithCircle={statusWithCircle}
				/>
			)}

			<TableWrapper>
				<Table cellSpacing="0" cellPadding="0">
					<ETPayTableHeader<Entity>
						dataCount={data.length}
						columns={columns}
						isLoading={isLoading}
						hasCheckbox={hasCheckbox}
						checkedSelect={checkboxSelected}
						filters={filters}
						onFilterChange={onFilterChange}
						handleCheckboxClicked={handleCheckboxClicked}
					/>
					{isLoading ? (
						<ETPayTableBodySkeleton columnsLength={totalColumns} />
					) : (
						<ETPayTableBody
							hasCheckbox={hasCheckbox}
							data={data}
							columns={columns}
							row={row}
							checkboxSelected={checkboxSelected}
							handleCheckboxClicked={handleCheckboxClicked}
						/>
					)}
				</Table>

				<ETPayEmptyBody<Entity>
					data={data}
					isLoading={isLoading}
					filters={filters}
					emptyMessage={emptyMessage}
				/>

				{hasPagination && (
					<ETPAyPagination
						isLoading={isLoading}
						totalResults={totalResults ?? 0}
						pagination={pagination}
						hasPaginationSelect={hasPaginationSelect}
						setPagination={setPagination}
					/>
				)}
			</TableWrapper>
		</TableContainer>
	);
};

export { ETPayTable };
