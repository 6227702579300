import Cookies from 'js-cookie';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CONSTANTS } from '../config/constants';
import { AuthUser } from '../models/auth';
import { AuthServices } from '../services/auth';
import { AuthActions } from '../store/auth/actions';
import { MerchantActions } from '../store/merchants/actions';
import { RootReducerInterface } from '../store/store';
import { encode } from '../utils/common';
import { tracker } from '../utils/open-replay';

export const useAuth = () => {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		isLoading: false,
		isError: false,
	});

	const { user, timeToExpire } = useSelector((state: RootReducerInterface) => state.auth);

	const handleLogin = async ({ username, password }: AuthUser): Promise<void> => {
		setState({ ...state, isLoading: true });

		try {
			const response = await AuthServices.login({ username, password });

			dispatch({
				type: AuthActions.SET_TIME_TO_EXPIRE,
				payload: { timeToExpire: response.tokenExp },
			});

			if (response) {
				Cookies.set(CONSTANTS.cookies.authorization, encode(response.auth.Authorization));
				Cookies.set(CONSTANTS.cookies.accessToken, encode(response.auth.accessToken));
				Cookies.set(CONSTANTS.cookies.refreshToken, encode(response.auth.refreshToken));
				Cookies.set(CONSTANTS.cookies.user, encode(JSON.stringify(response.user)));

				dispatch({ type: AuthActions.LOGIN, payload: { user: response.user } });
			}
		} catch (error: any) {
			setState({ ...state, isError: true });
			dispatch({ type: AuthActions.LOGIN_ERROR });
			throw error;
		} finally {
			setState({ ...state, isLoading: false });
		}
	};

	const handleLogout = async () => {
		try {
			// await AuthServices.logout();

			Cookies.remove(CONSTANTS.cookies.authorization);
			Cookies.remove(CONSTANTS.cookies.accessToken);
			Cookies.remove(CONSTANTS.cookies.refreshToken);
			Cookies.remove(CONSTANTS.cookies.user);

			dispatch({ type: AuthActions.RESET_STATE });
			dispatch({ type: MerchantActions.RESET_STATE });

			tracker.stop();
		} catch (error: any) {
			console.error(error);
		}
	};

	return {
		handleLogin,
		handleLogout,
		isLoading: state.isLoading,
		isError: state.isError,
		user,
		timeToExpire,
	};
};
