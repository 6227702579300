import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { CheckIcon } from '../../assets/svg/CheckIcon';
import { DocumentIcon } from '../../assets/svg/DocumentIcon';
import { OutboundIcon } from '../../assets/svg/OutboundIcon';
import { SpinnerCircularIcon } from '../../assets/svg/SpinnerCircular';
import { Breadcrumb } from '../../components/breadcrumb';
import { DragAndDrop } from '../../components/drag-and-drop';
import { Input } from '../../components/input';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useAuth } from '../../hooks/useAuth';
import { useFloatingAlert } from '../../hooks/useFloatingAlert';
import { useMerchant } from '../../hooks/useMerchant';
import { CountriesAllowed } from '../../models/merchant';
import { PayoutsService } from '../../services/payouts';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { errorFormatter } from '../../utils/errors';
import { formatNumber } from '../../utils/numbers';
import { DepositTable } from './components/table';
import {
	Balance,
	BalanceLabel,
	Button,
	ButtonContainer,
	Card,
	CardAction,
	CardBody,
	CardContainer,
	CardContent,
	CardContentDescription,
	CardContentTitle,
	CardContentTitleContainer,
	CardDeposit,
	CardDepositTitle,
	CardFormContainer,
	CardInfo,
	CardProcess,
	CardProcessBody,
	HeadContainer,
	Hours,
	IconContainer,
	InfoContainer,
	Step,
	Text,
	TitlePage,
} from './styles';

export interface AlertData {
	concept: string;
	amount: string;
	reference: string;
	id: string;
}

interface DefaultState {
	files?: FileList | FileList[];
	loadFile?: boolean;
	id: string | undefined;
	amount: string | undefined;
	loadAmount: boolean;
	step: number | undefined;
}

const defaultState: DefaultState = {
	files: undefined,
	loadFile: false,
	id: undefined,
	amount: undefined,
	loadAmount: false,
	step: undefined,
};

const PayoutsDeposit = () => {
	const { user } = useAuth();
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [filters, setFilters] = useState(defaultFilters);
	const [total, setTotal] = useState<number>(0);
	const [pagination, setPagination] = useState(defaultPagination);
	const [state, setState] = useState(defaultState);
	const [loadData, setLoadData] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showCheck, setShowCheck] = useState(false);
	const [cleanDragAndDrop, setCleanDragAndDrop] = useState(false);
	const [loadingTop, setLoadingTop] = useState<boolean>(false);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.quick_transfer.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	const dictionary = new Map([
		['payouts', 'Salidas'],
		['deposit', 'Mis abonos'],
	]);

	const {
		data: table,
		isFetching: isFetchingTable,
		isFetched,
		refetch,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.payoutsChile.getTableBeneficiary,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			PayoutsService.getAllDeposit({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	const { data: balance, isFetching: isFetchingBalance } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		queryKey: [merchantSelected?.code],
		queryFn: () =>
			PayoutsService.getBalance({
				merchantCode: merchantSelected?.code as string,
			}),
	});

	const { data: pendingDeposit } = useQuery({
		queryFn: () =>
			PayoutsService.getPendingDeposit({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
	});

	const formatBalance = (balance: number) => {
		const rounded = Math.round(balance);
		return `$${formatNumber({
			value: rounded,
			country: merchantSelected?.country as CountriesAllowed,
		})}`;
	};

	const inputHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setState((prevState) => ({
			...prevState,
			amount: value,
		}));
	};

	const handleChange = (files: FileList | FileList[]) => {
		setState((prevState) => ({
			...prevState,
			loadFile: true,
			files,
		}));
	};

	const handleSendDepositAmount = async () => {
		if (merchantSelected && user && state.amount !== undefined) {
			setShowSpinner(true);
			setState((prevState) => ({
				...prevState,
				step: 1,
			}));
			try {
				const response = await PayoutsService.sendDepositAmount({
					merchantCode: merchantSelected?.code as string,
					merchantId: merchantSelected?.id,
					email: user?.email,
					amount: state.amount,
				});
				setState((prevState) => ({
					...prevState,
					id: response.resume.id,
					loadAmount: true,
				}));
				handleShowAlert({
					message: 'Monto a abonar cargado con exito',
					type: 'success',
				});
				setShowSpinner(false);
				setShowCheck(true);
				setCleanDragAndDrop(false);
			} catch (e: any) {
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
				setShowSpinner(false);
			}
		}
	};

	useEffect(() => {
		state.amount === '' &&
			setState((prevState) => ({
				...prevState,
				amount: undefined,
				loadAmount: false,
			}));
	}, [state.amount]);

	const handleClickSendVoucher = async () => {
		setState((prevState) => ({
			...prevState,
			step: 2,
		}));
		try {
			setLoadData(true);
			if (!state.files) {
				setState({ ...state, loadFile: false });
				return;
			}
			if (merchantSelected) {
				await PayoutsService.sendVoucherFile({
					merchantCode: merchantSelected.code as string,
					file: state.files[0],
					status: 'pending_ops',
					id: state.id,
					merchantId: merchantSelected.id,
				});
			}
			handleShowAlert({
				message: 'Comprobante cargado con exito',
				type: 'success',
			});
			setState(defaultState);
			setLoadData(false);
			setCleanDragAndDrop(true);
			setShowCheck(false);
			refetch();
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setLoadData(false);
		} finally {
			setState((prevState) => ({ ...prevState, load: false }));
		}
	};

	const spinnerMessage = (step: number) => {
		if (step === 1) return 'Cargando monto a abonar';
		if (step === 2) return 'Cargando el comprobante';
	};

	const pendingDepositSlice = pendingDeposit?.tableInfo?.slice(0, 5);

	const callGetUrlImageDeposit = async (id: number) => {
		setLoadingTop(true);
		try {
			const data = await PayoutsService.getUrlImageDeposit(merchantSelected?.code as string, id);

			window.open(data.url, '_blank');
		} catch (e) {
			handleShowAlert({ type: 'error', message: 'Ocurrio un problema' });
		}
		setLoadingTop(false);
	};

	return (
		<ViewWrapper
			title="Mis abonos"
			isLoading={loadData || isFetchingTable || isFetchingBalance}
			message={spinnerMessage(state.step || 0)}
		>
			<HeadContainer>
				<TitlePage>
					<OutboundIcon />
				</TitlePage>
				<Breadcrumb dictionary={dictionary} isLoading={loadingTop} />
			</HeadContainer>

			<CardContainer>
				<Card>
					<BalanceLabel>Crédito disponible</BalanceLabel>
					<Balance>{formatBalance(balance?.resume.balance || 0)}</Balance>
				</Card>
				<CardProcess>
					<BalanceLabel>Abonos en proceso</BalanceLabel>

					{pendingDepositSlice?.map((deposit: any) => (
						<CardProcessBody key={deposit.id}>
							<CardInfo>
								<InfoContainer>
									<Text>{formatBalance(deposit?.amount || 0)}</Text>
									<br />
								</InfoContainer>
								<InfoContainer>
									<Text>{deposit.created_at_date}</Text>
									<Hours>{deposit.created_at_time}</Hours>
								</InfoContainer>
							</CardInfo>
							<CardAction>
								<IconContainer onClick={() => callGetUrlImageDeposit(deposit.id)}>
									<DocumentIcon />
								</IconContainer>
							</CardAction>
						</CardProcessBody>
					))}
				</CardProcess>
			</CardContainer>

			<CardDeposit>
				<CardDepositTitle>Nuevo abono</CardDepositTitle>
				<CardBody>
					<CardContent>
						<CardContentTitleContainer>
							<Step>1</Step>
							<CardFormContainer>
								<CardContentTitle>Monto a abonar</CardContentTitle>
								<CardContentDescription>
									Introduce la cantidad que vas a transferir desde tu banco
								</CardContentDescription>
								<Input
									value={state.amount || ''}
									label="Monto a abonar"
									placeholder="$100.000"
									variant="large"
									onChange={inputHandleChange}
									onBlur={handleSendDepositAmount}
									rightIcon={
										<>
											{showSpinner && <SpinnerCircularIcon size={30} />}
											{showCheck && <CheckIcon />}
										</>
									}
								/>
							</CardFormContainer>
						</CardContentTitleContainer>
					</CardContent>

					<CardContent disabled={!showCheck}>
						<CardContentTitleContainer>
							<Step>2</Step>
							<CardFormContainer>
								<CardContentTitle>Sube el comprobante</CardContentTitle>
								<CardContentDescription>
									Con él, podremos validar que la transferencia ha sido satisfactoria.
								</CardContentDescription>
								<DragAndDrop
									id="upload-voucher"
									accept={['.png', '.jpg', '.pdf']}
									maxSize={3000000}
									onChange={handleChange}
									disabled={!state.loadAmount}
									cleanState={cleanDragAndDrop}
								/>
							</CardFormContainer>
						</CardContentTitleContainer>
					</CardContent>
				</CardBody>
				<ButtonContainer>
					<Button
						onClick={() => handleClickSendVoucher()}
						disabled={!state.loadAmount || !state.loadFile}
					>
						Procesar abono
					</Button>
				</ButtonContainer>
			</CardDeposit>

			<DepositTable
				data={table?.tableInfo || []}
				isLoading={isFetchingTable && !isFetched}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				filters={filters}
				refetch={refetch}
			/>
		</ViewWrapper>
	);
};

export { PayoutsDeposit };
