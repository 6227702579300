export const FeatureInactiveIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="8.25" stroke="#B7B7B7" strokeWidth="1.5" />
		<path
			d="M14 10L10 14"
			stroke="#B7B7B7"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M10 10L14 14"
			stroke="#B7B7B7"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
