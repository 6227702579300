import ReactGA from 'react-ga4';

declare global {
	interface Window {
		dataLayer: Record<string, any>[];
	}
}
/**
 *
 * @param {Object} payload
 * @param {"GTM" | "GA" | undefined} payload.source
 * @param {"screen" | "event"} payload.type
 * @param {String | undefined} payload.pageView
 * @param {String | undefined} payload.event
 * @param {String | undefined} payload.value
 * @param {String | undefined} payload.paymentToken
 */

export interface PayloadProps {
	source?: string;
	type: string;
	pageView?: string;
	event?: string;
	value?: string;
}
export const track = ({ source, type, pageView, event, value }: PayloadProps) => {
	if (type === 'screen') {
		setPageView(pageView, source);
	} else {
		setEvent(event, value, source);
	}
};

/**
 *
 * @param {String} pageView
 * @param {"GTM" | "GA" | undefined} source
 * @param {String} paymentToken
 */
export const setPageView = (pageView?: string, source?: string) => {
	if (!source) {
		setPageViewGTM(pageView);
		setPageViewGA(pageView);
	} else if (source === 'GTM') {
		setPageViewGTM(pageView);
	} else if (source === 'GA') {
		setPageViewGA(pageView);
	}
};

/**
 *
 * @param {String} pageView
 * @param {String} paymentToken
 */
const setPageViewGTM = (pageView?: string) => {
	setTimeout(() => {
		window.dataLayer.push({
			event: 'pageview',
			page: {
				url: document.location.origin + document.location.pathname + document.location.search,
				title: pageView,
			},
		});
	}, 1000);
};

/**
 *
 * @param {String} pageView
 */
const setPageViewGA = (pageView?: string) => {
	ReactGA.send({
		hitType: 'pageview',
		title: pageView,
	});
};

/**
 *
 * @param {String} event
 * @param {String | undefined} value
 * @param {"GTM" | "GA" | undefined} source
 */
export const setEvent = (event?: string, value?: string, source?: string) => {
	if (!source) {
		setEventGTM(event, value);
		setEventGA(event, value);
	} else if (source === 'GTM') {
		setEventGTM(event, value);
	} else if (source === 'GA') {
		setEventGA(event, value);
	}
};

/**
 *
 * @param {String} event
 * @param {String | undefined} value
 */
const setEventGTM = (event?: string, value = '') => {
	window.dataLayer.push({
		event: 'event',
		eventProps: {
			action: event,
			value,
		},
	});
};

/**
 *
 * @param {String} event
 * @param {String | undefined} value
 */
const setEventGA = (event?: string, value?: any) => {
	ReactGA.event({
		category: 'event',
		action: event ? event : '',
		value,
	});
};
