import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { OutboundIcon } from '../../assets/svg/OutboundIcon';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { OutboundService } from '../../services/outbound';
import { getRoleLabelById, validatePermission, validateRoleId } from '../../utils/common';
import { transformDate } from '../../utils/dates';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { AmountAllowed, AmountSubTitle } from '../eatm/styles';
import ModalOutbound from './components/modal';
import { NewBatchPayment } from './components/new-batch-payment';
import { OutboundTable } from './components/table';
import {
	ButtonHead,
	ContainerButton,
	HeadContainer,
	SpanAmount,
	TableFilterHeader,
	TitlePage,
} from './styles';
export interface Values {
	amount: string;
	concept: string;
	clabe: string;
	beneficiary: string;
	email: string;
	code: string;
	token: string;
	reference: string;
	loading: boolean;
	status: string;
	trackingKey: string;
	currency: string;
}

export const defaultValues: Values = {
	amount: '',
	concept: '',
	clabe: '',
	beneficiary: '',
	email: '',
	code: '',
	token: '',
	reference: '',
	loading: false,
	status: '',
	trackingKey: '',
	currency: 'MXN',
};

const Outbound = () => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const flowBanksOrStp = true;

	const [cashAllowed, setCashAllowed] = useState('');
	const [cashDate, setCashDate] = useState('');
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState<number>(0);
	const [show, setShow] = useState<boolean>(false);
	const [showMultiPayout, setShowMultiPayout] = useState<boolean>(false);
	const [state, setState] = useState<Values>(defaultValues);

	const today = new Date();
	const yesterday = transformDate(new Date(new Date().setDate(new Date().getDate() - 1)));

	const pastDate = flowBanksOrStp
		? new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)
		: undefined;

	const {
		data: table,
		isFetching,
		isFetched,
		isLoading,
		refetch,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.outbound.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			OutboundService.getAllOutbound({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from || pastDate,
						to: filters?.dateRange?.to || today,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	const { isLoading: isLoadingCash } = useQuery({
		enabled: !!merchantSelected,
		queryKey: [
			CONSTANTS.services.outbound.getCashAllowed,
			merchantSelected?.code,
			merchantSelected?.provider,
			yesterday,
		],
		queryFn: () =>
			OutboundService.getCashAllowed(
				merchantSelected?.code as string
			),
		onSuccess: (response) => {
			if (response) {
				setCashAllowed(response.balanceDetail.balance);
				setCashDate(response.balanceDetail.updatedAtFormat);
			}
		},
	});

	const onClose = () => {
		setShow(false);
		setShowMultiPayout(false);
		setState(defaultValues);
		refetch();
	};

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	const roleIsAllowed =
		merchantSelected &&
		validateRoleId(getRoleLabelById(merchantSelected?.role_id, merchantSelected?.role_id))
			?.label === 'Propietario';

	return (
		<ViewWrapper title="Salidas" isLoading={isLoading || isLoadingCash || isFetching}>
			<HeadContainer>
				<TitlePage>
					<OutboundIcon /> Salidas
				</TitlePage>
				<ContainerButton>
					{validatePermission('PAYOUTS_GENERATE') && roleIsAllowed && (
						<AmountAllowed>
							{flowBanksOrStp ? (
								'Saldo disponible:'
							) : (
								<>
									<SpanAmount>
										Saldo acumulado <br />
										del día anterior:
									</SpanAmount>
								</>
							)}{' '}
							{isLoadingCash ? 'cargando...' : `${cashAllowed}`}
							<AmountSubTitle>Actualizado el {cashDate}</AmountSubTitle>
						</AmountAllowed>
					)}
					{width > 576 && roleIsAllowed && flowBanksOrStp && (
						<ButtonHead onClick={() => setShowMultiPayout(true)} variant="pure">
							Pagos de salida masivos
						</ButtonHead>
					)}
					{roleIsAllowed && (
						<ButtonHead onClick={() => setShow(true)} variant="saturn">
							Nuevo pago de salida
						</ButtonHead>
					)}
				</ContainerButton>
			</HeadContainer>

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			{show && <ModalOutbound onClose={onClose} setState={setState} state={state} />}

			{showMultiPayout && <NewBatchPayment onClose={onClose} show={showMultiPayout} />}

			<OutboundTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				today={today}
				pastDate={pastDate}
				maxDate={today}
				stateList={table?.typeOfStatus || []}
			/>
		</ViewWrapper>
	);
};

export { Outbound };
