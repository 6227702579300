export const CONSTANTS = {
	localStorage: {
		ETPay: 'RVRQYXk=',
	},
	cookies: {
		authorization: 'QXV0aG9yaXphdGlvbg==',
		accessToken: 'YWNjZXNzLXRva2Vu',
		refreshToken: 'cmVmcmVzaC10b2tlbg==',
		user: 'dXNlcg==',
	},
	services: {
		home: {
			getGraphData: 'getHomeGraphData',
			getStatistics: 'getHomeStatistics',
		},
		merchant: {
			getMerchantsList: 'getMerchantsList',
		},
		allPayments: {
			getGraphData: 'getAllPaymentsGraphData',
			getTableInfo: 'getAllPaymentsTableInfo',
		},
		quickTransfer: {
			getGraphData: 'getQuickTransferGraphData',
			getTableInfo: 'getQuickTransferTableInfo',
		},
		paymentLinks: {
			getGraphData: 'getPaymentLinksGraphData',
			getTableInfo: 'getPaymentLinksTableInfo',
		},
		paymentLinksMultiuse: {
			getTableInfo: 'getPaymentLinksMultiuseTableInfo',
		},
		businessTransfer: {
			getGraphData: 'getBusinessTransferGraphData',
			getTableInfo: 'getBusinessTransferTableInfo',
		},
		nonBankCards: {
			getGraphData: 'getNonBankCardsGraphData',
			getTableInfo: 'getNonBankCardsTableInfo',
		},
		conciliation: {
			getTableInfo: 'getConciliationTableInfo',
		},
		analytics: {
			getGraphData: 'getAnalyticsGraphData',
			getGraphInfo: 'getAnalyticsGraphInfo',
		},
		eatm: {
			getGraphData: 'getEAtmGraphData',
			getTableInfo: 'getEAtmTableInfo',
			getCashAllowed: 'getEAtmCashAllowed',
		},
		directDebitMx: {
			getTableInfo: 'getDirectMxDebitTableInfo',
		},
		directDebit: {
			getGraphData: 'getDirectDebitGraphData',
			getTableInfo: 'getDirectDebitTableInfo',
			rendition: {
				getTableInfo: 'getDirectDebitRenditionTableInfo',
			},
		},
		refund: {
			payments: {
				getTableInfo: 'getRefundPaymentsTableInfo',
			},
			returns: {
				getTableInfo: 'getRefundReturnsTableInfo',
			},
			getCashAllowed: 'getEAtmCashAllowed',
		},
		outbound: {
			getTableInfo: 'getOutboundTableInfo',
			getCashAllowed: 'getEAtmCashAllowed',
		},
		payoutsChile: {
			getTableInfo: 'getPayoutsChileTableInfo',
			getBalance: 'getBalance',
			getStats: 'getStats',
			getTableBeneficiary: 'getTableBeneficiary',
		},
		profile: {
			getInformation: 'getProfileInformation',
		},
		config: {
			accountInformation: {
				getInformation: 'getAccountInformation',
			},
			contacts: {
				getInformation: 'getContactInformation',
			},
			personalization: {
				getInformation: 'getPersonalizationInformation',
			},
			users: {
				getUserList: 'getUserList',
			},
			subscription: {
				getPlans: 'getSubscriptionPlans',
				getMyCurrentPlan: 'getMyCurrentPlan',
			},
		},
	},
};
