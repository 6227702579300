import { QRCodeContainer, QRContainer, QRSubtitle, QRTitle, ScanQRText } from './styles';

interface QRSectionProps {
	amount: string;
	commission: string;
	qr: string;
}

const QRSection = ({ amount, commission, qr }: QRSectionProps) => {
	return (
		<QRContainer>
			<QRTitle>Retiro de {amount}</QRTitle>
			<QRSubtitle>Esta transacción tiene una comisión de {commission} + IVA.</QRSubtitle>

			<QRCodeContainer>
				<img src={qr} alt="QR del retiro" />
			</QRCodeContainer>

			<ScanQRText>Escanea el código para continuar.</ScanQRText>
		</QRContainer>
	);
};

export { QRSection };
