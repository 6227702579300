import { SVGProps } from 'react';

export const Wom = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="53"
			height="29"
			viewBox="0 0 53 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M52.0699 14.2682C52.3122 14.7099 52.1743 15.0484 51.7007 15.1975L49.0294 16.0293C48.6262 16.1613 48.2737 16.0523 48.1063 15.6851L43.8832 6.78748C43.7683 6.57522 43.5832 6.63259 43.612 6.86779L45.0543 16.638C45.1126 17.039 44.9043 17.3258 44.5005 17.4526L42.5677 18.0544C42.1697 18.1812 41.8345 18.0664 41.6504 17.7102L37.1965 8.88138C37.0816 8.66912 36.9022 8.72649 36.9253 8.9617L38.5978 18.6625C38.6734 19.0577 38.4421 19.3509 38.0388 19.4771L35.3728 20.3152C34.8997 20.4587 34.5887 20.263 34.531 19.7639L32.7598 4.60753C32.7079 4.20596 32.9156 3.91912 33.3194 3.79291L36.4805 2.80046C36.8849 2.67425 37.1959 2.80046 37.4042 3.1504L40.3748 8.25034C40.4908 8.45686 40.669 8.40523 40.6465 8.16429L40.1383 2.29563C40.11 1.88832 40.2946 1.60722 40.6927 1.48102L43.8601 0.488564C44.2634 0.362356 44.5928 0.482827 44.7832 0.838504L52.0699 14.2682Z"
				fill="white"
			/>
			<path
				d="M0.386712 14.9221C0.144399 14.4804 0.282864 14.1419 0.755953 13.9927L3.42717 13.1609C3.83103 13.0347 4.18296 13.1437 4.35027 13.5051L8.57345 22.4085C8.68884 22.615 8.86769 22.5577 8.84461 22.323L7.40227 12.5528C7.35035 12.1513 7.55227 11.8644 7.95613 11.7382L9.8831 11.1359C10.287 11.0096 10.6216 11.1244 10.812 11.4858L15.2602 20.3152C15.3755 20.5211 15.5544 20.4643 15.5313 20.2291L13.8582 10.5283C13.7832 10.1325 14.014 9.83993 14.4178 9.71372L17.089 8.8819C17.5564 8.73274 17.8679 8.92779 17.9256 9.42689L19.6968 24.589C19.7487 24.9843 19.541 25.2711 19.1372 25.3979L15.9756 26.3904C15.5717 26.516 15.2659 26.3904 15.0525 26.0404L12.0812 20.9405C11.9658 20.734 11.787 20.7908 11.8101 21.026L12.3178 26.9004C12.3466 27.3077 12.162 27.583 11.7581 27.7087L8.59653 28.7011C8.19268 28.8279 7.86382 28.7126 7.67343 28.3517L0.386712 14.9221Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.212 13.4189C20.0331 11.8528 19.8658 7.56747 25.1852 5.89808C30.4988 4.23443 31.2944 8.32471 31.4738 9.89084L32.1488 15.8054C32.3282 17.3664 32.495 21.6574 27.1762 23.3211C21.8626 24.9841 21.0716 20.895 20.8928 19.334L20.212 13.4189ZM24.6025 16.873C24.8102 18.6915 25.0525 19.7695 26.7141 19.2474C28.3699 18.7317 28.3583 17.5843 28.1506 15.7595L27.7583 12.3525C27.5506 10.5282 27.3083 9.44968 25.6525 9.97172C23.9909 10.4938 24.0082 11.6411 24.2159 13.4654L24.6025 16.873Z"
				fill="white"
			/>
		</svg>
	);
};
