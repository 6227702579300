import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const ModalBody = styled.div`
	display: grid;
	width: 100%;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 32px;
	text-align: center;
`;

type ButtonVariant = 'earth' | 'pure';

interface ButtonProps {
	variant?: ButtonVariant;
}

export const Button = styled.button<ButtonProps>`
	align-items: center;
	background-color: ${({ theme, variant }) =>
		variant === 'earth'
			? theme.colors.extra.earth
			: variant === 'pure' && theme.colors.principal.pure};
	border: ${({ theme, variant }) =>
		variant === 'pure' ? `1px solid ${theme.colors.extra.earth}` : 'none'};
	border-radius: 8px;
	color: ${({ theme, variant }) =>
		variant === 'earth'
			? theme.colors.principal.pure
			: variant === 'pure' && theme.colors.extra.earth};
	cursor: pointer;
	display: flex;
	font-size: 16px;
	justify-content: center;
	outline: none;
	padding: 8px 14px;

	&:hover {
		opacity: 0.7;
	}

	svg {
		margin-right: 12px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const ContainerInput = styled.div`
	input {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		&:read-only {
			background-color: #f7f6ff;
			border: none;
			padding-right: 70px;
		}
	}
`;

export const CopyButton = styled.span`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.earth};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.pure};
	display: flex;
	font-size: 12px;
	font-weight: 400;
	height: 28px;
	justify-content: center;
	line-height: 12px;
	padding: 6px;
`;

export const TabsContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;

export const TabBody = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const QRTitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0;
	text-align: center;
`;

export const QRSubtitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	margin: 0;
	text-align: center;
`;

export const QRCodeContainer = styled.div`
	background-color: #f7f7ff;
	border-radius: 12px;
	margin: 16px 0;
	padding: 23px;

	img {
		height: 114px;
		width: 114px;
	}
`;

export const ScanQRText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
`;

export const ButtonContainer = styled.div`
	margin-top: 24px;
	width: 100%;

	button {
		align-items: center;
		display: flex;
		height: 48px;
		width: 100%;
	}
`;
