import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { ContactServices } from '../../services/contacts';
import { ContainerSection } from './components/containers';
import { BodySection, TitlePage } from './style';

const Contacts = () => {
	const { merchantSelected } = useMerchant();

	const { data, isLoading, isFetching } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.config.contacts.getInformation, merchantSelected?.code],
		queryFn: () => ContactServices.getContactInformation(merchantSelected?.code as string),
	});

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.contacts.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);
	return (
		<ViewWrapper title="Contactos" isLoading={isLoading || isFetching}>
			<TitlePage>Contactos</TitlePage>

			<BodySection>
				<ContainerSection
					title="Contacto comercial"
					type="commercial"
					data={data?.res?.COMMERCIAL}
				/>
				<ContainerSection title="Contacto técnico" type="technical" data={data?.res?.TECHNICAL} />
				<ContainerSection title="Contacto facturación" type="billing" data={data?.res?.BILLING} />
			</BodySection>
		</ViewWrapper>
	);
};

export { Contacts };
