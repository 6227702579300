import styled from 'styled-components';

export const TableFilterHeader = styled.div`
	display: flex;
	padding-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
		position: relative;
	}
`;

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: normal;
		flex-direction: column;
		gap: 16px;
	}
`;

export const GenerateReturnsButton = styled.button`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: ${({ theme }) => `solid 1px ` + theme.colors.principal.ceres};
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.orion};
	cursor: pointer;
	height: 37px;
	min-width: 100px;
	outline: 0;
	padding: 0 12px;
	transition: background-color 0.2s ease-in-out;
	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.principal.ceres}CC;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const AlertContainer = styled.div`
	margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
`;
interface ButtonProp {
	outboundColor?: boolean;
}

export const Button = styled.button<ButtonProp>`
	align-items: center;
	background: ${({ theme, outboundColor }) =>
		outboundColor ? theme.colors.extra.saturn : theme.colors.principal.pure};
	border: ${({ outboundColor }) => (outboundColor ? '0' : '1px')} solid
		${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	color: ${({ theme, outboundColor }) =>
		outboundColor ? theme.colors.principal.pure : theme.colors.principal.vanth};
	cursor: pointer;
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	padding: 15px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const DownloadLink = styled.a`
	align-items: center;
	background: ${({ theme }) => theme.colors.extra.saturn};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	cursor: pointer;
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	padding: 15px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}
`;

export const DragAndDropContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;

export const List = styled.ul`
	list-style: disc;
	margin-bottom: 16px;
	margin-left: 25px;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const ModalTitle = styled.p`
	color: #140f33;
	font-size: 20px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 8px;
	text-align: center;
`;

interface ModalSubtitleProp {
	align?: 'start' | 'center' | 'end';
}

export const ModalSubtitle = styled.div<ModalSubtitleProp>`
	color: #646174;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	text-align: ${({ align }) => (align ? align : 'center')};

	a {
		color: ${({ theme }) => theme.colors.principal.orion};
		font-weight: 500;
		text-decoration: underline;
	}
`;

export const ModalStep = styled.p`
	color: #140f33;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.6px;
	line-height: 12px;
	margin-bottom: 2px;
	text-align: center;
`;
