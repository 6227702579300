import { ETPayButton } from '../button';
import { ButtonContainer, Detail, ManagePlanContainer, Title, Wrapper } from './styles';

interface BoxesProps {
	title: string;
	description: string | null;
}

interface ManagePlanProps {
	boxes: BoxesProps[];
	buttonAction?: () => void;
}

const ManagePlan = ({ boxes, buttonAction }: ManagePlanProps) => {
	return (
		<ManagePlanContainer>
			{boxes.map((box, i) => (
				<Wrapper key={i}>
					<Title>{box.title}</Title>
					<Detail>{box.description}</Detail>
				</Wrapper>
			))}

			<Wrapper>
				<ButtonContainer>
					<ETPayButton variant="effect" title="Administrar plan" onClick={buttonAction} />
				</ButtonContainer>
			</Wrapper>
		</ManagePlanContainer>
	);
};

export { ManagePlan };
