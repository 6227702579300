import styled from 'styled-components';

export const LegendContainer = styled.ul`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 0 45px;
	width: 100%;
`;

export const LegendItem = styled.li`
	align-items: center;
	display: flex;
`;

interface LegendIconProp {
	color: string;
}
export const LegendIcon = styled.span<LegendIconProp>`
	background: ${({ color }) => color};
	border-radius: 100%;
	display: block;
	height: 10px;
	width: 10px;
`;
