import styled from 'styled-components';

export const SectionFeatures = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	width: 100%;
`;

export const TitleFeature = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 11px;
	line-height: 13px;
	margin-bottom: 10px;
`;

interface FeatureProps {
	readonly isActive: boolean;
}

export const Feature = styled.p<FeatureProps>`
	color: ${({ theme, isActive }) => theme.colors.principal[isActive ? 'vanth' : 'moon']};
	display: flex;
	font-size: 14px;
	gap: 7px;
	line-height: 21px;

	&:not(:first-child) {
		margin-top: 5px;
	}
`;
