import { Children, cloneElement, ReactElement, useState } from 'react';

import { TabBody, TabsContainer, TabsHeader, TabTitleContainer } from './styles';

interface TabProp {
	title: string;
	reference: string;
	show: boolean;
}

interface TabsProps {
	tabs: TabProp[];
	children: ReactElement | ReactElement[];
	defaultReferenceSelected?: string;
}

const Tabs = ({ tabs, children, defaultReferenceSelected }: TabsProps) => {
	const arrayChildren = Children.toArray(children);
	const [referenceSelected, setSelectedTabIndex] = useState<string>(
		defaultReferenceSelected || tabs[0].reference,
	);

	const handleOnClick = (reference: string) => {
		setSelectedTabIndex(reference);
	};

	return (
		<TabsContainer>
			<TabsHeader>
				{tabs.map(
					(tab) =>
						tab.show && (
							<TabTitleContainer
								key={tab.reference}
								$active={tab.reference === referenceSelected}
								onClick={() => handleOnClick(tab.reference)}
							>
								{tab.title}
							</TabTitleContainer>
						),
				)}
			</TabsHeader>

			<TabBody>
				{Children.map(arrayChildren, (child) => {
					const childElement = child as ReactElement;
					if (childElement.props.id === referenceSelected) {
						return cloneElement(childElement, { ...childElement.props });
					}
				})}
			</TabBody>
		</TabsContainer>
	);
};

export { Tabs };
