import {
	endOfWeek,
	format,
	formatDistance,
	getYear,
	isSameDay,
	isSameMonth,
	isThisMonth,
	isYesterday,
	startOfMonth,
	startOfWeek,
	sub,
} from 'date-fns';

import { DateRange } from '../models/table';

export const transformDate = (date?: Date) => format(date ?? new Date(), 'dd/MM/yyyy');
export const csvFormat = (date: string) => {
	const [year, month, day] = date.split('-');
	return format(new Date(Number(year), Number(month) - 1, Number(day)), 'ddMMyy');
};

export const calendarFormat = (date: Date) => format(date, 'd MMM yyyy');
export const dateFormat = (date: Date) => format(date, 'dd MMM yyyy');
export const getMonthAndYearFormat = (date: Date) => format(date, 'LLLL yyyy');
export const fullDateFormat = (date: string) => format(new Date(date), 'dd MMM yyyy HH:mm');

export const transformFilterDate = ({ from, to }: DateRange) => {
	if (from && to) {
		const distance = formatDistance(from, to);
		if (distance === 'menos de un minuto') return format(to, 'dd LLL');
		if (distance === '1 día') return format(to, 'dd LLL');

		if (getYear(from) === getYear(to)) {
			return `${format(from, 'dd LLL')} - ${format(to, 'dd LLL yyyy')}`;
		} else {
			return `${format(from, 'dd LLL yyyy')} - ${format(to, 'dd LLL yyyy')}`;
		}
	}
	return format(new Date(), 'dd LLL');
};

export const isNDaysAgo = (n: number, to?: Date, from?: Date) => {
	if (to && from) {
		const thirtyDaysAgo = new Date(to.getTime() - n * 24 * 60 * 60 * 1000);
		return from.getTime() === thirtyDaysAgo.getTime();
	}
};

export const isCurrentMonth = (to: Date, from: Date) => {
	return isSameMonth(to, from) && isThisMonth(to) && isThisMonth(from);
};

export const getDistanceBetweenDates = ({
	from,
	to,
	thirtyDays,
	sevenDays,
	thisMonth,
}: DateRange) => {
	if (from && to && !thirtyDays && !sevenDays && !thisMonth) {
		if (isSameDay(from, to) && isYesterday(new Date(to))) {
			return 'Día de ayer';
		}
		return 'Personalizado:';
	} else if (thisMonth) {
		return 'Este mes';
	} else if (thirtyDays) {
		return 'Últimos 30 días';
	} else if (sevenDays) {
		return 'Últimos 7 días';
	} else {
		return 'Últimas 24 hrs';
	}
};

export const prettyRangeFormat = (date: string) => {
	return format(new Date(date), 'dd/MMM/yy');
};

export const getInternationalDateFormat = (date: Date) => {
	return format(new Date(date), 'yyyy-MM-dd');
};

export const getDateRange = <T>(interval: T) => {
	const today = new Date();
	const yesterday = sub(today, { days: 1 });
	const _format = 'dd/MMM/yy';

	switch (interval) {
		case 'YESTERDAY':
			return format(yesterday, _format);
		case 'THIS_WEEK': {
			const firstDate = startOfWeek(today);
			const lastDate = new Date(today.setDate(today.getDate() - 1));
			return `${format(firstDate, _format)} - ${format(lastDate, _format)}`;
		}
		case 'THIS_MONTH': {
			const firstDate = startOfMonth(today);
			const dateEqual = format(firstDate, _format) === format(today, _format);
			const conditionToReturn = dateEqual
				? format(today, _format)
				: `${format(firstDate, _format)} - ${format(today, _format)}`;
			return conditionToReturn;
		}
		case 'LAST_WEEK': {
			const lastWeek = sub(today, { weeks: 1 });
			const firstDate = startOfWeek(lastWeek);
			const lastDate = endOfWeek(lastWeek);
			return `${format(firstDate, _format)} - ${format(lastDate, _format)}`;
		}
		case 'LAST_14_DAYS': {
			const last14Days = sub(today, { days: 14 });
			return `${format(last14Days, _format)} - ${format(yesterday, _format)}`;
		}
		case 'LAST_30_DAYS': {
			const last30Days = sub(today, { days: 30 });
			return `${format(last30Days, _format)} - ${format(yesterday, _format)}`;
		}
		case 'LAST_120_DAYS': {
			const last120Days = sub(today, { days: 120 });
			return `${format(last120Days, _format)} - ${format(yesterday, _format)}`;
		}
		case 'LAST_180_DAYS': {
			const last180Days = sub(today, { days: 180 });
			return `${format(last180Days, _format)} - ${format(yesterday, _format)}`;
		}
		default:
			return format(yesterday, _format);
	}
};

export const validateDateValue = (value: string) => {
	let newTime = value;
	if (!value) newTime = '00:00';
	const [hours, minutes] = value.split(':');
	if (!hours) newTime = '00:00';
	if (hours && !minutes) newTime = `${hours}:00`;
	if (Number(hours) > 23) newTime = '00:00';
	if (Number(hours) < 0) newTime = '00:00';
	if (Number(hours) === 24) newTime = '00:00';
	if (Number(minutes) > 59) newTime = `${hours}:59`;
	if (Number(minutes) < 0) newTime = `${hours}:00`;
	if (minutes?.length === 1) newTime = `${hours}:0${minutes}`;
	return newTime;
};
