import { GetMerchantsResponse } from '../models/merchant';
import { client } from '../utils/api';

const geMerchantsByUser = async () => {
	try {
		const { data } = await client.get<GetMerchantsResponse>('/v1/user-merchants');
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const MerchantService = {
	geMerchantsByUser,
};
