interface IProps {
	active?: boolean;
	width?: string;
	height?: string;
}

export const QuickTransferIcon = ({ active = true, width, height }: IProps) => (
	<svg
		width={width || '85'}
		height={height || '85'}
		viewBox="0 0 85 85"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="85" height="85" rx="12" fill={active ? '#FF7576' : '#B7B7B7'} />
		<path
			d="M24.7715 33.9887C24.7715 38.6019 26.6394 43.0262 29.9641 46.2882C33.2889 49.5502 37.7982 51.3828 42.5001 51.3828C47.2021 51.3828 51.7114 49.5502 55.0362 46.2882C58.3609 43.0262 60.2288 38.6019 60.2288 33.9887L42.5001 33.9887L24.7715 33.9887Z"
			fill="#FCFCFC"
		/>
		<path
			d="M23.7106 55.9197C23.7106 60.8089 25.6902 65.4979 29.2139 68.9551C32.7376 72.4123 37.5167 74.3545 42.5 74.3545C47.4832 74.3545 52.2623 72.4123 55.786 68.9551C59.3097 65.4979 61.2893 60.8089 61.2893 55.9197L42.5 55.9197L23.7106 55.9197Z"
			fill="#FCFCFC"
		/>
		<circle cx="42.5005" cy="20.6231" r="8.62306" fill="#FCFCFC" />
	</svg>
);
