export const SelectIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="8.25" fill="#130F31" stroke="#E6E6E6" strokeWidth="1.5" />
		<path
			d="M15.4101 9.93079L10.8633 14.4775L8.58997 12.2042"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="square"
		/>
	</svg>
);
