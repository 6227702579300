import styled from 'styled-components';

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 4px;
	justify-content: flex-end;

	> button {
		min-width: fit-content;
	}
`;

export const StatusContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 12px;
	justify-content: center;
	line-height: 12px;
	min-width: 68px;
	padding: 6px;
	width: fit-content;
`;

export const RoleName = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 21px;
`;
