import { AccountInfo } from '../models/setting';
import { client } from '../utils/api';

const getAccountInformationList = async (merchantCode: string) => {
	const params = { merchant_code: merchantCode };

	try {
		const { data } = await client.get<AccountInfo>('/v1/settings/account-info', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const AccountInformationServices = {
	getAccountInformationList,
};
