import { SVGProps } from 'react';

export const Movistar = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="107"
			height="21"
			viewBox="0 0 107 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_7727_52085)">
				<path
					d="M22.1797 0.518267C23.0547 0.384087 24.0254 0.364919 24.8031 0.851103C25.9261 1.50806 26.5673 2.69826 27.0102 3.86579C28.2554 7.23249 28.3741 10.9146 27.8516 14.4329C27.7346 15.3338 27.3839 16.3515 26.4858 16.761C25.9544 16.937 25.2742 16.89 24.9058 16.4264C24.3106 15.7189 24.4984 14.7431 24.548 13.9014C24.7411 12.5718 24.8155 11.223 24.7535 9.88298C24.7481 9.27133 24.5391 8.60914 24.0059 8.24842C23.556 7.91385 22.9059 7.97832 22.4595 8.28502C21.7032 8.76772 21.2072 9.54492 20.8016 10.3186C19.8061 12.3348 19.2552 14.5218 18.513 16.6321C18.141 17.6131 17.5405 18.5472 16.6495 19.1449C15.5814 19.8855 14.2245 20.0807 12.9456 20.0353C11.3124 19.9587 9.84217 18.9497 8.98483 17.6149C7.9167 15.9158 6.96548 14.1471 5.92215 12.4341C5.59091 11.9358 5.14453 11.298 4.4537 11.3712C3.72744 11.4374 3.45288 12.2372 3.47591 12.8541C3.52728 14.9679 4.56706 16.8934 4.93728 18.9515C5.06659 19.5858 4.88237 20.3821 4.23228 20.6697C3.83549 20.7237 3.41923 20.7725 3.02598 20.6714C2.44852 20.4553 2.14916 19.8681 1.89054 19.3575C0.988923 17.2315 0.427403 14.9748 0.138672 12.6938C0.179413 11.5245 0.0678176 10.3395 0.31935 9.18594C0.662994 7.30394 1.18909 5.31215 2.60794 3.92156C3.83018 2.70348 5.93455 2.34102 7.41718 3.30119C8.85552 4.28228 9.74474 5.8053 10.6889 7.21332C11.3815 8.29722 12.028 9.5728 13.2999 10.0938C14.1767 10.5138 15.3015 10.2559 15.9463 9.55014C16.4086 9.08139 16.6371 8.45579 16.8815 7.86157C17.4005 6.5825 17.9178 5.30518 18.4864 4.04702C19.1329 2.43512 20.4065 0.93649 22.1797 0.518267Z"
					fill="white"
				/>
				<path
					d="M85.7827 4.57682C86.4452 4.52628 87.1112 4.55591 87.7737 4.57856C87.8233 5.23378 87.733 5.89422 87.8339 6.54595C88.5123 6.5233 89.1943 6.50413 89.8727 6.55641C89.8905 7.13669 89.9542 7.72395 89.8285 8.29726C89.1518 8.28506 88.4769 8.28855 87.802 8.30075C87.7702 9.70179 87.7949 11.1063 87.7861 12.5074C87.7932 13.1016 87.7843 13.7847 88.2449 14.2343C88.7019 14.6647 89.3892 14.5985 89.9737 14.6508C90.057 15.252 90.064 15.8654 89.9507 16.4631C88.84 16.4753 87.6178 16.4544 86.7109 15.7329C85.8961 15.0951 85.664 14.0199 85.6374 13.0458C85.6215 10.4319 85.6481 7.8163 85.6268 5.20241C85.6729 5.01072 85.5188 4.64478 85.7827 4.57682Z"
					fill="white"
				/>
				<path
					d="M35.6083 7.28827C36.6127 6.40129 38.0439 6.29151 39.3317 6.33856C40.3803 6.33507 41.4325 6.61912 42.2597 7.26388C43.1862 6.31765 44.6227 6.31416 45.8627 6.33856C46.9946 6.33856 48.2044 6.66965 48.9679 7.53747C49.5311 8.18745 49.6357 9.07618 49.6534 9.89868C49.6605 11.6361 49.6534 13.3734 49.6569 15.1125C49.6534 15.5639 49.7065 16.0274 49.5613 16.463C48.9023 16.4352 48.2434 16.4927 47.588 16.4299L47.5189 16.3323C47.448 14.0687 47.5827 11.7981 47.4445 9.53622C47.4569 8.80956 46.7731 8.27633 46.0788 8.22753C45.287 8.17874 44.3659 8.08464 43.7193 8.63704C43.2375 9.02738 43.2942 9.6948 43.2641 10.2472C43.2428 12.0456 43.2517 13.8457 43.257 15.644C43.2127 15.8967 43.3403 16.2574 43.1188 16.4421C42.4475 16.4648 41.7673 16.4892 41.0977 16.4125C41.0977 14.1175 41.1526 11.8225 41.041 9.52925C41.0375 8.75554 40.2528 8.23625 39.5248 8.21533C38.857 8.18223 38.1307 8.12995 37.5284 8.46801C37.1387 8.68061 36.9032 9.10232 36.8925 9.53622C36.7402 11.8399 36.8837 14.1506 36.7951 16.4561C36.1167 16.4561 35.4382 16.4508 34.7616 16.4613C34.6943 16.1389 34.6642 15.8113 34.6766 15.4837C34.6996 13.5616 34.6588 11.6395 34.696 9.7192C34.7067 8.84267 34.9086 7.89121 35.6083 7.28827Z"
					fill="white"
				/>
				<path
					d="M54.1136 6.53725C55.6724 6.23403 57.4012 6.1713 58.8661 6.87008C60.2442 7.56538 60.5914 9.22084 60.6481 10.6114C60.6694 12.138 60.7632 13.8074 59.913 15.1579C59.123 16.3759 57.5128 16.6565 56.1524 16.6251C54.8487 16.66 53.3661 16.477 52.452 15.4628C51.4636 14.25 51.4725 12.591 51.4707 11.115C51.5097 9.90743 51.6195 8.58654 52.39 7.59152C52.8152 7.05306 53.4546 6.71673 54.1136 6.53725ZM55.001 8.26764C54.3846 8.3966 53.9329 8.92809 53.8124 9.52057C53.591 10.8031 53.591 12.1223 53.8053 13.4048C53.9276 13.9102 54.2198 14.4382 54.7513 14.609C55.5448 14.8669 56.4199 14.8529 57.2276 14.6612C57.8157 14.5427 58.1895 14.006 58.3241 13.4641C58.565 12.1467 58.5544 10.7927 58.3223 9.47526C58.1647 8.8671 57.6758 8.33212 57.0221 8.24325C56.3526 8.16483 55.6653 8.15263 55.001 8.26764Z"
					fill="white"
				/>
				<path
					d="M78.0019 6.83C79.4527 6.11728 81.1567 6.30896 82.7049 6.53724C82.905 6.61392 83.3054 6.54421 83.3443 6.82826C83.3691 7.30747 83.3638 7.79017 83.3532 8.27113C83.2912 8.29552 83.1672 8.34606 83.1052 8.37046C81.9839 8.17877 80.8308 7.98186 79.6936 8.1596C79.2472 8.23453 78.6786 8.49767 78.6857 9.01696C78.5847 9.45958 78.9762 9.76802 79.3305 9.93879C80.5775 10.5017 81.991 10.763 83.0928 11.6065C84.0157 12.2983 84.1078 13.5965 83.8138 14.6177C83.3479 15.9142 81.9078 16.5293 80.6005 16.599C79.357 16.6548 78.0515 16.6217 76.8895 16.1477C76.6681 15.6109 76.808 14.9801 76.8275 14.4086C78.2375 14.7431 79.752 15.1091 81.178 14.6543C81.9078 14.4643 82.1752 13.4205 81.5429 12.9709C80.2781 12.2077 78.7034 12.0595 77.4989 11.183C76.1084 10.0799 76.4148 7.61591 78.0019 6.83Z"
					fill="white"
				/>
				<path
					d="M92.4061 6.93622C92.3423 6.68354 92.624 6.53716 92.8347 6.52671C94.2926 6.33328 95.7911 6.21478 97.2419 6.52845C98.1701 6.76021 99.0788 7.31959 99.4791 8.20831C99.9857 9.28349 99.7926 10.4946 99.8298 11.6395C99.805 12.7687 99.9591 13.9711 99.449 15.0253C98.9566 16.0361 97.7627 16.456 96.7052 16.5902C95.3855 16.6704 93.9702 16.686 92.7816 16.0343C90.826 14.9574 90.9022 11.6813 92.9003 10.6863C94.3741 9.92128 96.1011 10.1705 97.6847 10.3482C97.7272 9.61633 97.6086 8.70321 96.8345 8.37386C95.4121 7.83191 93.8781 8.28499 92.4114 8.27279C92.4078 7.82668 92.3901 7.38058 92.4061 6.93622ZM93.8391 14.2935C94.4307 14.9243 95.3855 14.8772 96.1844 14.8493C96.7246 14.8302 97.3482 14.6071 97.566 14.0774C97.7503 13.4431 97.6582 12.7722 97.6883 12.1204C96.6804 11.8625 95.5998 11.7998 94.576 11.9967C93.5557 12.1797 93.1571 13.5633 93.8391 14.2935Z"
					fill="white"
				/>
				<path
					d="M102.483 7.35454C103.604 6.24276 105.337 6.27064 106.814 6.37345C106.857 6.96245 106.844 7.55319 106.803 8.14219C105.987 8.27985 105.037 7.98187 104.331 8.5151C103.895 8.82703 103.849 9.40383 103.835 9.88827C103.794 12.063 103.835 14.2395 103.813 16.4143C103.121 16.5032 102.423 16.43 101.728 16.4683C101.558 14.6978 101.702 12.9064 101.654 11.1273C101.688 9.84993 101.482 8.3513 102.483 7.35454Z"
					fill="white"
				/>
				<path
					d="M61.8262 6.54941C62.5737 6.53721 63.3336 6.45531 64.0723 6.60343C64.2512 9.29923 65.027 11.9497 66.2386 14.3684C66.4459 14.1053 66.5752 13.7951 66.7009 13.4884C67.6504 11.3067 68.3076 8.97859 68.4386 6.59995C69.1738 6.46054 69.9284 6.53199 70.6723 6.55464C70.5731 9.98407 69.11 13.1957 67.5406 16.1999C67.4307 16.524 67.0428 16.4421 66.7807 16.4596C66.2315 16.4421 65.6789 16.4962 65.1351 16.423C65.0908 16.3689 65.0022 16.2609 64.9579 16.2069C63.3177 13.227 61.9201 9.98755 61.8262 6.54941Z"
					fill="white"
				/>
				<path
					d="M72.4453 6.5581C73.1538 6.50582 73.8677 6.51279 74.578 6.55113C74.6081 9.82546 74.6276 13.1068 74.5709 16.3794C73.9155 16.5658 73.1769 16.3968 72.4913 16.4613C72.4541 13.1625 72.3992 9.85857 72.4453 6.5581Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7727_52085">
					<rect width="106.99" height="20.5626" fill="white" transform="translate(0 0.312744)" />
				</clipPath>
			</defs>
		</svg>
	);
};
