import { SVGProps } from 'react';

export const LessIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11 5.5v10.083M16.5 11 11 16.5 5.5 11"
			stroke="#FF7576"
			strokeWidth={1.5}
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
