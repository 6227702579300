import { useDispatch, useSelector } from 'react-redux';

import { CountriesAllowed, Enterprise, Merchant } from '../models/merchant';
import { MerchantService } from '../services/merchants';
import { MerchantActions } from '../store/merchants/actions';
import { RootReducerInterface } from '../store/store';

export const useMerchant = () => {
	const dispatch = useDispatch();
	const {
		merchants,
		enterprises,
		merchantSelected,
		enterpriseSelected,
		urlToDownloadExample,
		urlToDownloadExamplePayouts,
		urlToDownloadExamplePayoutsChile,
		urlToDownloadExamplePayoutsSbifChile,
		isEnterpriseSelected,
		directDebitBatchMx,
	} = useSelector((state: RootReducerInterface) => state.merchant);

	const handleGetMerchants = async () => {
		const { enterprises, filesExample } = await MerchantService.geMerchantsByUser();

		dispatch({
			type: MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE,
			payload: { urlToDownloadExample: filesExample.plBatch },
		});

		dispatch({
			type: MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS,
			payload: { urlToDownloadExamplePayouts: filesExample.payoutsBatch },
		});

		dispatch({
			type: MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS_CHILE,
			payload: { urlToDownloadExamplePayoutsChile: filesExample.payoutsBatchCl },
		});

		dispatch({
			type: MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS_SBIF_CHILE,
			payload: { urlToDownloadExamplePayoutsSbifChile: filesExample.payoutsSBIFCl },
		});

		dispatch({
			type: MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_DIRECT_DEBIT_MX,
			payload: { directDebitBatchMx: filesExample.directDebitBatchMx },
		});

		const _merchants = enterprises
			.reduce((acc: Merchant[], enterprise: Enterprise) => {
				return [...acc, ...enterprise.merchants];
			}, [])
			.sort((a: Merchant, b: Merchant) => a.name.localeCompare(b.name));

		const getEnterprisesSummary = enterprises.map((enterprise: Enterprise) => {
			const { merchants, ...rest } = enterprise;
			return { ...rest, merchants };
		});

		dispatch({
			type: MerchantActions.GET_ENTERPRISE,
			payload: { enterprises: getEnterprisesSummary },
		});

		if (_merchants) {
			dispatch({
				type: MerchantActions.GET_MERCHANT,
				payload: { merchants: _merchants },
			});
		}
	};

	const handleSelectActiveMerchant = (merchant: Merchant) => {
		const _merchant: Merchant = {
			...merchant,
			country: merchant.country.toUpperCase() as CountriesAllowed,
		};
		dispatch({
			type: MerchantActions.SELECT_MERCHANT,
			payload: {
				merchantSelected: _merchant,
				isEnterpriseSelected: undefined,
			},
		});
	};

	const handleGetEnterpriseSelected = () =>
		enterprises?.find((enterprise: Enterprise) =>
			enterprise.merchants.some((merchant: any) => merchant.name === merchantSelected?.name),
		);

	const handleUpdateRole = (rolID: string, merchantCode: string) => {
		const merchant = merchants?.find((merchant: Merchant) => merchant.code === merchantCode);
		if (!merchant) return;

		const newMerchantList = merchants?.map((merchant: Merchant) => {
			if (merchant.code === merchantCode) {
				return { ...merchant, role_id: +rolID };
			}
			return merchant;
		});

		dispatch({
			type: MerchantActions.UPDATE_ROLE,
			payload: { merchants: newMerchantList },
		});

		if (merchantSelected?.code === merchantCode) {
			dispatch({
				type: MerchantActions.UPDATE_ROLE_CURRENT_MERCHANT,
				payload: {
					merchantSelected: { ...merchant, role_id: +rolID },
					isEnterpriseSelected: undefined,
				},
			});
		}
	};

	const handleSelectViewAll = (enterpriseId: number) => {
		dispatch({
			type: MerchantActions.SELECT_VIEW_ALL,
			payload: { isEnterpriseSelected: enterpriseId },
		});
	};

	return {
		urlToDownloadExample,
		urlToDownloadExamplePayouts,
		urlToDownloadExamplePayoutsChile,
		urlToDownloadExamplePayoutsSbifChile,
		directDebitBatchMx,
		merchants,
		enterprises,
		merchantSelected,
		enterpriseSelected,
		handleGetMerchants,
		handleSelectActiveMerchant,
		handleGetEnterpriseSelected,
		handleUpdateRole,
		handleSelectViewAll,
		isEnterpriseSelected,
	};
};
