export enum UserStatusEnum {
	Inactive = 'INACTIVE',
	Invited = 'INVITED',
	Accepted = 'ACCEPTED',
	Pending = 'PENDING',
	Expired = 'EXPIRED',
	Deleted = 'DELETED',
}

export type UserStatus = 'INACTIVE' | 'INVITED' | 'ACCEPTED' | 'PENDING' | 'EXPIRED' | 'DELETED';

export type RolesAllowed =
	| 'ETpay'
	| 'Propietario'
	| 'Operaciones'
	| 'Devoluciones'
	| 'Conciliación'
	| 'Lector'
	| 'Super Admin'
	| 'Payment Links'
	| 'Propietario Operaciones';

export const RolesOptions = [
	{ value: 1, label: 'ETpay', show: true },
	{ value: 2, label: 'Propietario', show: true },
	{ value: 3, label: 'Operaciones', show: true },
	{ value: 4, label: 'Devoluciones', show: true },
	{ value: 5, label: 'Conciliación', show: true },
	{ value: 8, label: 'Payment Links', show: true },
	{ value: 6, label: 'Lector', show: true },
	{ value: 7, label: 'Super Admin', show: false },
	{ value: 9, label: 'Propietario Operaciones', show: true },
];

export interface User {
	cognito_id: string;
	connection_id: string | null;
	email: string;
	names: string;
	lastnames: string;
	role_id: number;
	role_name: RolesAllowed;
	status: UserStatus;
}

export interface GetUserResponse {
	can_add: boolean;
	users: { data: User[]; total: string };
}

export interface ResendInvitationParams {
	merchantCode: string;
	id: string;
	email: string;
}

export interface ChangeRoleParams {
	merchantCode: string;
	id: string;
	role: number;
}

export interface DeleteUserParams {
	merchantCode: string;
	id: string;
	email: string;
}

export type Permissions =
	| 'ALL_PAYMENTS_EXPORT'
	| 'ALL_PAYMENTS_READ'
	| 'ANALYTICS'
	| 'ATM_EXPORT'
	| 'ATM_NEW'
	| 'ATM_READ'
	| 'ATM_RESEND'
	| 'ATM_UPDATE'
	| 'CONCILIATION_EXPORT'
	| 'CONCILIATION_READ'
	| 'PAYOUTS_GENERATE'
	| 'PAYOUTS_READ'
	| 'PAYOUTS_EXPORTS'
	| 'PAYOUTS_DEPOSIT'
	| 'PL_CREATE_BATCH'
	| 'PL_EXPORT'
	| 'PL_MULTIUSE_EDIT'
	| 'PL_MULTIUSE_END'
	| 'PL_MULTIUSE_EXPORT'
	| 'PL_MULTIUSE_LINK'
	| 'PL_MULTIUSE_NEW'
	| 'PL_MULTIUSE_NEW_SESSION'
	| 'PL_MULTIUSE_READ'
	| 'PL_MULTIUSE_SMS'
	| 'PL_NEW'
	| 'PL_READ'
	| 'PL_SEND_SMS'
	| 'PL_SHARE_LINK'
	| 'QT_EXPORT'
	| 'QT_READ'
	| 'BT_EXPORT'
	| 'BT_READ'
	| 'TNB_EXPORT'
	| 'TNB_READ'
	| 'REFUNDS_EXPORT'
	| 'SETTINGS_ACCOUNT_INFO_PUT'
	| 'SETTINGS_ACCOUNT_INFO_READ'
	| 'SETTINGS_CONTACT_INFO_PUT'
	| 'SETTINGS_CONTACT_INFO_READ'
	| 'SETTINGS_PERSONALIZATION_PUT'
	| 'SETTINGS_PERSONALIZATION_READ'
	| 'SETTINGS_USERS_CHANGE_ROLE'
	| 'SETTINGS_USERS_DELETE'
	| 'SETTINGS_USERS_INVITE'
	| 'SETTINGS_USERS_INVITE_DELETE'
	| 'SETTINGS_USERS_INVITE_RESEND'
	| 'SETTINGS_USERS_LIST';
