import { MouseEventHandler } from 'react';
import styled from 'styled-components';

interface ContainerProps {
	readonly toEnd?: boolean;
	readonly $hasError?: boolean;
}

export const Content = styled.div<ContainerProps>`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	display: flex;
	flex-direction: column;
	${({ toEnd, $hasError }) => toEnd && `grid-column: ${$hasError ? '5/5' : '4/4'} `}
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;

export const AmountContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 16px;
`;

export const StatusContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const Value = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Section = styled.div`
	display: grid;
	gap: 32px;

	&:nth-of-type(2) {
		padding-top: 16px;
	}
`;

interface SectionProps {
	readonly $hasError?: boolean;
}

export const DetailsContainer = styled.div<SectionProps>`
	background-color: ${({ theme }) => theme.colors.principal.air};
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	margin: 8px 0 15px;
	padding: 16px 20px;
	width: 100%;

	${Section} {
		grid-template-columns: ${({ $hasError }) => (!$hasError ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)')};

		&:last-of-type {
			${Content} {
				&:first-of-type {
					grid-column: ${({ $hasError }) => (!$hasError ? '1/4' : '1/5')};
				}
			}
		}
	}
`;

export const ExportModalContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
	width: 100%;
`;

export const ExportModalTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0 0 8px;
`;

export const ExportModalDescription = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin: 0 0 28px;
`;

export const VoucherContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const VoucherButton = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	height: 42px;
	justify-content: center;
	width: 42px;
`;

interface TextProp {
	readonly disabled?: boolean;
	readonly onClick?: MouseEventHandler<HTMLSpanElement>;
}

export const Button = styled.button`
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border: 0;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.air};
	cursor: pointer;
	padding: 6px;
`;

export const TextContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: fit-content;
`;

export const ItemText = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;
`;

export const Text = styled.span<TextProp>`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.principal.moon : theme.colors.principal.orion};
	cursor: default;
	display: block;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	p {
		color: ${({ theme, disabled }) =>
			disabled ? theme.colors.principal.moon : theme.colors.principal.orion};
	}
`;

export const TextMax = styled.span<TextProp>`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.principal.moon : theme.colors.principal.orion};
	cursor: default;
	display: block;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 80px;

	p {
		color: ${({ theme, disabled }) =>
			disabled ? theme.colors.principal.moon : theme.colors.principal.orion};
	}

	&::after {
		background: white;
		border: 1px solid #ccc;
		color: black;
		content: attr(data-fulltext);
		display: none;
		left: 0;
		padding: 5px;
		position: absolute;
		top: 100%;
		white-space: normal;
		width: auto;
		z-index: 1;
	}

	&:hover {
		align-items: center;
		background: #E8E7EB;
		border-radius: 4px;
		display: flex;
		font-size: 12px;
		height: 100%;
		position: absolute;
		top: 0;
		width: 500px;
		z-index: 10;
	}
`;

export const SubText = styled.span<TextProp>`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.principal.moon : theme.colors.principal.vanth};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	display: block;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	p {
		color: ${({ theme, disabled }) =>
			disabled ? theme.colors.principal.moon : theme.colors.principal.orion};
	}
`;

export const StatusAlign = styled.div`
	display: flex;
`;

export const TextWithToken = styled.span<TextProp>`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.principal.moon : theme.colors.principal.orion};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	font-size: 14px;
	max-width: 300px;
	min-width: 255px;
`;

export const TextPayment = styled.span`
	max-width: 20px;
	text-overflow: ellipsis;
`;

export const SelectContainer = styled.div`
	div {
		min-width: 130px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const ProductFilterLabel = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;

	svg {
		height: 16px;
		width: 16px;
	}
`;
