import React from 'react';
import { SVGProps } from 'react';

export const ETPayLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 98 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#a)">
			<path
				d="M44.184 13.142H33.862c.199 2.77 1.39 3.855 3.982 3.855 1.933 0 2.769-.486 3.404-2.142l2.654.97c-.98 2.4-3.26 3.537-6.088 3.537-4.212 0-6.947-2.798-6.947-7.11 0-4.311 2.829-7.166 6.81-7.166 3.865 0 6.55 2.656 6.55 6.852-.013.432-.013.662-.043 1.204Zm-10.298-2.14h7.384c-.115-2.627-1.19-3.627-3.573-3.627-2.312 0-3.553 1.141-3.81 3.626ZM49.954 7.974v8.508h3.771v2.599H50.85c-2.308 0-3.836-1.513-3.836-3.798v-7.31h-2.914V5.378h2.914V1.179h2.941v4.198h4.212v2.597h-4.212ZM69.803 12.23c0 4.283-2.481 7.137-6.463 7.137-2.336 0-4.067-1.178-5.02-3.168v7.738H55.38V5.377h2.942v2.911c.952-2.027 2.74-3.198 5.107-3.198 3.922 0 6.375 2.856 6.375 7.14Zm-2.977 0c0-3.054-1.39-4.482-4.3-4.482S58.2 9.176 58.2 12.23c0 3.054 1.39 4.482 4.328 4.482 2.937 0 4.305-1.43 4.305-4.482h-.006ZM82.928 10.058v9.023h-2.914v-3.055c-.75 2.226-2.451 3.34-4.847 3.34-2.799 0-4.53-1.427-4.53-3.825 0-2.947 2.656-4.37 7.301-4.37h2.076v-1.084c0-1.713-.952-2.542-2.943-2.542-1.759 0-3.115.914-3.087 3.143l-2.796-.571c.057-3.025 2.393-5.025 5.942-5.025 3.662-.002 5.798 1.835 5.798 4.966Zm-2.914 2.97h-1.933c-2.596 0-4.442.77-4.442 2.312 0 1.142.808 1.857 2.423 1.857 2.106 0 3.952-1.399 3.952-4.169ZM97.67 5.377l-6.23 15.645c-.838 2.084-1.933 2.913-4.472 2.913h-2.977v-2.6h5.031l.173-.57-.057.056-6.029-15.444h3.254l2.999 8.643.982 3.198 1.038-3.22 3.03-8.621h3.259Z"
				fill="#140F33"
			/>
			<path
				d="M11.975 0a12.068 12.068 0 0 0-5.072 1.111 11.949 11.949 0 0 0-4.118 3.134A11.797 11.797 0 0 0 .397 8.81a11.721 11.721 0 0 0-.207 5.137H23.76a11.747 11.747 0 0 0-.722-6.628 11.842 11.842 0 0 0-2.595-3.847 11.983 11.983 0 0 0-3.885-2.57A12.083 12.083 0 0 0 11.975 0ZM4.444 9.822a7.721 7.721 0 0 1 2.792-4.106 7.858 7.858 0 0 1 4.737-1.586c1.713 0 3.377.557 4.738 1.586a7.72 7.72 0 0 1 2.792 4.106H4.443ZM14.115 19.279l-.066.016a6.93 6.93 0 0 1-.409.1l-.063.014c-.159.033-.318.06-.48.084l-.116.014c-.123.017-.248.03-.375.04l-.143.01c-.156.01-.313.017-.472.017h-.018c-.169 0-.335 0-.502-.018h-.097a8.788 8.788 0 0 1-.465-.05h-.028a7.821 7.821 0 0 1-4.726-2.502l-3.11 2.75a11.973 11.973 0 0 0 4.04 2.922 12.085 12.085 0 0 0 4.892 1.035h.1c.176 0 .35 0 .527-.014.054 0 .105 0 .159-.012.157-.01.314-.021.47-.037l.115-.014c.171-.02.342-.041.51-.069l.078-.013c.183-.03.363-.061.542-.1l.058-.012c.184-.042.367-.085.55-.132l.057-.018c.178-.047.357-.1.532-.157l.077-.025c.167-.055.334-.112.496-.175l.11-.041c.148-.06.297-.12.446-.185l.145-.065c.131-.059.26-.12.387-.185l.199-.094.321-.173.234-.133.253-.153c.093-.06.188-.118.28-.18.059-.037.116-.078.174-.117.114-.076.225-.155.334-.236l.075-.057c.616-.462 1.185-.98 1.701-1.55l-3.108-2.75a7.842 7.842 0 0 1-3.684 2.265Z"
				fill="#72DBAA"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h97.6v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
