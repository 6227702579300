interface IBorderBarProps {
	fill: string;
	x: number;
	y: number;
	width: number;
	height: number;
}

const BorderBar = ({ fill, x, y, width, height }: IBorderBarProps) => {
	return <rect x={x} y={y} width={width} height={height} rx="6" fill={fill} />;
};

export { BorderBar };
