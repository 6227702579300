import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { track } from '../../analytics';
import { ANALYTICS } from '../../analytics/constants';
import { EAtmIcon, SettingsIcon } from '../../assets/svg';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { ProcessType } from '../../models/sockets';
import { EatmServices } from '../../services/eatm';
import { WebSockets } from '../../services/sockets';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { EatmHeader } from './components/header';
import { NewWithdrawal } from './components/new-withdrawal';
import { EatmTransferTable } from './components/table';
import { UpdateCash } from './components/update-cash';
import {
	AmountAllowed,
	HeadContainer,
	IconSettingContainer,
	NewWithdrawalButton,
	NewWithdrawalContainer,
	TableFilterHeader,
	TitlePage,
} from './styles';

type EatmType = 'update-cash' | 'create-new-withdrawal';

interface Modal {
	show: boolean;
	type?: EatmType;
}

const defaultModal: Modal = {
	show: false,
	type: undefined,
};

const Eatm = () => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [cashAllowed, setCashAllowed] = useState('');
	const [maxCashAllowed, setMaxCashAllowed] = useState('');
	const [modal, setModal] = useState(defaultModal);
	const [verifyWithdrawal, setVerifyWithdrawal] = useState(false);
	const [token, setToken] = useState('');
	const [amountToWithdrawal, setAmountToWithdrawal] = useState('');
	const [total, setTotal] = useState(0);

	const { data: graphs, isLoading: isLoadingGraph } = useQuery({
		enabled: !!merchantSelected && width > 576,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.eatm.getGraphData, merchantSelected?.code],
		queryFn: () => EatmServices.getGraphicsData(merchantSelected?.code as string),
	});

	const { isLoading: isLoadingCash } = useQuery({
		enabled: !!merchantSelected,
		queryKey: [CONSTANTS.services.eatm.getCashAllowed, merchantSelected?.code],
		queryFn: () => EatmServices.getCashAllowed(merchantSelected?.code as string),
		onSuccess: (response) => {
			if (response) {
				setCashAllowed(response.data.available_cash);
				setMaxCashAllowed(response.data.max_withdrawal);
			}
		},
	});

	const {
		data: table,
		isFetching,
		isFetched,
		refetch,
		isLoading: isLoadingTable,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.quickTransfer.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			EatmServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		track({
			type: 'screen',
			pageView: ANALYTICS.eatm.page_view,
			source: 'GTM',
		});
	}, []);

	WebSockets.subscribe(({ action, data }) => {
		if (action === ProcessType.UPDATE_CASH_ALLOWED) {
			setCashAllowed(data.available_cash);
			setMaxCashAllowed(data.max_withdrawal);
		}
	});

	const handleShowModal = (type: EatmType) => {
		setModal({ show: true, type });
	};

	const onClose = () => {
		setModal(defaultModal);
		setAmountToWithdrawal('');
		setToken('');
		setVerifyWithdrawal(false);
		refetch();
	};

	return (
		<ViewWrapper
			title="eATM"
			isLoading={isLoadingCash || isLoadingGraph || isLoadingTable || isFetching}
		>
			<>
				{modal.show && modal.type === 'update-cash' && (
					<UpdateCash
						show={modal.show}
						onClose={onClose}
						defaultAmountValue={cashAllowed}
						defaultMaxAmountValue={maxCashAllowed}
					/>
				)}
				{modal.show && modal.type === 'create-new-withdrawal' && (
					<NewWithdrawal
						show={modal.show}
						onClose={onClose}
						maxAmount={maxCashAllowed}
						amount={amountToWithdrawal}
						verifyWithdrawal={verifyWithdrawal}
						withToken={token}
					/>
				)}

				<HeadContainer>
					<TitlePage>
						<EAtmIcon /> eATM
					</TitlePage>

					<NewWithdrawalContainer>
						<AmountAllowed>
							Efectivo disponible: {isLoadingCash ? 'cargando...' : cashAllowed}
							<IconSettingContainer onClick={() => handleShowModal('update-cash')}>
								<SettingsIcon color="#ffffff" />
							</IconSettingContainer>
						</AmountAllowed>
						<NewWithdrawalButton onClick={() => handleShowModal('create-new-withdrawal')}>
							Nuevo retiro
						</NewWithdrawalButton>
					</NewWithdrawalContainer>
				</HeadContainer>

				<TableFilterHeader>
					<DateFilter
						filters={filters}
						onFilterChange={setFilters}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</TableFilterHeader>

				{width > 576 && <EatmHeader data={graphs} loading={isLoadingGraph} />}

				<EatmTransferTable
					data={table?.tableInfo || []}
					isLoading={isFetching && !isFetched}
					stateList={table?.typeOfStatus || []}
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
					totalResults={total}
					showModalAction={({ token, amount }) => {
						handleShowModal('create-new-withdrawal');
						setVerifyWithdrawal(true);
						setToken(token);
						setAmountToWithdrawal(amount);
					}}
				/>
			</>
		</ViewWrapper>
	);
};

export { Eatm };
