interface RadioButtonCheckProps {
	width: number;
	height: number;
}
export const RadioButtonCheck = ({ width, height }: RadioButtonCheckProps) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12" cy="12" r="8.25" fill="#140F33" stroke="#140F33" strokeWidth="1.5" />
		<path
			d="M15.4101 9.93164L10.8633 14.4784L8.58997 12.205"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="square"
		/>
	</svg>
);
