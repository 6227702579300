import styled from 'styled-components';

export const EmptyBodyTable = styled.div`
	text-align: center;
`;

export const EmptyBodyTableText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 18px;
	line-height: 28px;
`;
