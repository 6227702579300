import { useEffect, useState } from 'react';

import { DownloadIcon } from '../../assets/svg';
import { DirectDebitIcon } from '../../assets/svg/DirectDebitIcon';
import { FileIcon } from '../../assets/svg/FileIcon';
import { DragAndDrop } from '../../components/drag-and-drop';
import { ViewWrapper } from '../../components/view-wrapper';
import { useMerchant } from '../../hooks/useMerchant';
import { DirectDebitMxServices } from '../../services/direct-debit-mx';
import ProgressBar from '../direct-debit-management/components/progress-bar';
import { ModalDomiciliation } from './components/modal';
import {
	Button,
	Card,
	CardGroup,
	Circle,
	Content,
	FileContainer,
	HeadContainer,
	InfoContainer,
	MovilMessageDisplay,
	NameFile,
	TextButton,
	TextCard,
	TitleCard,
	TitlePage,
} from './styles';
interface InfoCard {
	step: number;
	title: string;
	text: string[];
}

const DirectDebitMxManagement = () => {
	const { merchantSelected } = useMerchant();
	const [todayDate, setTodayDate] = useState<string>('');
	const [showModal, setShowModal] = useState<boolean>(false);
	const [statusCardOne, setStatusCardOne] = useState({
		stateCard: 'init',
		file: '',
		loading: false,
	});
	const [finalFile, setFinalFile] = useState<FileList | FileList[] | undefined>(undefined);
	const [clearFile, setClearFile] = useState<boolean>(false);
	const infoCards: InfoCard[] = [
		{
			step: 1,
			title: 'Descarga',
			text: ['El archivo contiene el detalle de todas las suscripciones activas al día de hoy.'],
		},
		{
			step: 2,
			title: 'Edita',
			text: [
				'En las últimas columnas añade el monto (máximo $49,999) y la fecha de cobro (dd/mm/aaaa o aaaa-mm-dd) a las suscripciones a las que deseas programar cargos y elimina las demás.',
				'Para múltiples cargos a un cliente, duplica la información de la suscripción.',
				'Programa los cargos con al menos un día de anticipación; los cargos en fines de semana o festivos se procesarán el siguiente día hábil.',
			],
		},
		{
			step: 3,
			title: 'Sube el archivo',
			text: ['Revisaremos la información de los cargos para asegurarnos que no haya errores.'],
		},
	];

	const getCurrentDateFormatted = (): string => {
		const date = new Date();
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}${month}${year}`;
	};

	const generateFile = async () => {
		await setStatusCardOne({ ...statusCardOne, loading: true });
		try {
			const dataPost = {
				merchant: {
					code: merchantSelected?.code as string,
				},
			};
			const response = await DirectDebitMxServices.getExportTemplateDownload(dataPost);

			if (response?.$metadata?.code === 'SuccessGenerateCsvCharges') {
				const base64Data = response.files[0].blob;
				const byteCharacters = atob(base64Data);
				const byteArray = new Uint8Array(
					byteCharacters.split('').map((char) => char.charCodeAt(0)),
				);
				const newBlob = new Blob([byteArray]);

				const url = window.URL.createObjectURL(newBlob);
				await downloadFile(url);
				await setStatusCardOne({ ...statusCardOne, loading: false, stateCard: 'ready', file: url });
			} else {
				setStatusCardOne({
					stateCard: 'init',
					file: '',
					loading: false,
				});
			}
		} catch (error) {
			setStatusCardOne({
				stateCard: 'init',
				file: '',
				loading: false,
			});
		}
	};

	const downloadFile = (url: string) => {
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', `domiciliaciones_${todayDate}.csv`);

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
	};

	const handleFileModal = async (files: FileList | FileList[]) => {
		if (files && files.length > 0) {
			const file = files[0];

			if (file instanceof File && file.type === 'text/csv') {
				await setFinalFile(files);
			} else {
				await setFinalFile(undefined);
			}
		} else {
			await setFinalFile(undefined);
		}
	};

	const onClose = async () => {
		await setClearFile(true);
		await setFinalFile(undefined);
		await setShowModal(false);
		await setClearFile(false);
	};

	useEffect(() => {
		setTodayDate(getCurrentDateFormatted);
	}, [todayDate]);

	return (
		<>
			<ViewWrapper title="Programar cargos">
				<HeadContainer>
					<TitlePage>
						<DirectDebitIcon />
						Programar cargos
					</TitlePage>
				</HeadContainer>

				{showModal && (
					<ModalDomiciliation setShowFinalizedModal={onClose} show={true} file={finalFile} />
				)}

				<Content>
					<CardGroup>
						{infoCards.map((card, index) => (
							<Card key={index}>
								<Circle>{card.step}</Circle>
								<TitleCard>{card.title}</TitleCard>
								{card.text.map((text, idx) => (
									<TextCard key={idx}>{text}</TextCard>
								))}
								{card.step === 1 && (
									<>
										<FileContainer>
											<FileIcon />
											<InfoContainer>
												<NameFile>domiciliaciones_{todayDate}.csv</NameFile>
												{statusCardOne.loading && <ProgressBar />}
											</InfoContainer>
										</FileContainer>
										<Button variant="earth" onClick={generateFile} disabled={statusCardOne.loading}>
												<TextButton>Descargar archivo</TextButton>
												<DownloadIcon />
										</Button>
									</>
								)}
								{card.step === 3 && (
									<>
										<DragAndDrop
											id="batch-direct-debits"
											accept={['.csv']}
											maxSize={2100000}
											onChange={handleFileModal}
											cleanState={clearFile}
										/>
										<Button
											variant="earth"
											disabled={!finalFile}
											onClick={() => setShowModal(true)}
										>
											Programar cargos
										</Button>
									</>
								)}
							</Card>
						))}
					</CardGroup>
				</Content>
				<MovilMessageDisplay>
					Para programar cargos es necesario abrir la versión de escritorio de nuestra plataforma.
				</MovilMessageDisplay>
			</ViewWrapper>
		</>
	);
};

export { DirectDebitMxManagement };
