import styled from 'styled-components';

export const Card = styled.div`
	background: ${({ theme }) => theme.colors.principal.pure};
	border: ${({ theme }) => `1px solid ${theme.colors.principal.ceres}`};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	padding: 21px 24px;
	position: relative;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-wrap: wrap;
	}
`;
