import { useState } from 'react';

import { DownloadIcon } from '../../../../assets/svg';
import { DragAndDrop } from '../../../../components/drag-and-drop';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { DirectDebitMxServices } from '../../../../services/direct-debit-mx';
import { errorFormatter } from '../../../../utils/errors';
import {
	Button,
	DocumentTemplate,
	DragAndDropContainer,
	LabelButtonIcon,
	ModalContainer,
	ModalSubtitle,
	ModalTitle,
	StyledList,
	StyledListItem,
	StyledListLabel,
} from '../modal/styles';
import RefundResume from '../refund-resume';

interface MultiDomiciliationModal {
	show: boolean;
	onClose: () => void;
}

interface DefaultState {
	loading: boolean;
	files?: FileList | FileList[];
	buttonDisabled: boolean;
	code: string;
	token: string;
	requestDate: string;
	totalTransfers: number;
	status: string;
	error: boolean;
	href: string;
	totalErrors?: number;
}

const defaultState: DefaultState = {
	loading: false,
	files: undefined,
	buttonDisabled: true,
	code: '',
	token: '',
	requestDate: '',
	status: '',
	totalTransfers: 0,
	totalErrors: 0,
	error: false,
	href: '',
};

const MultiDomiciliationModal = ({ show, onClose }: MultiDomiciliationModal) => {
	const { merchantSelected, directDebitBatchMx } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [steps, setSteps] = useState(1);
	const [state, setState] = useState(defaultState);
	const [btnValid, setBtnValid] = useState(true);

	const handleCloseModal = () => {
		setState(defaultState);
		setSteps(1);
		onClose();
	};

	const handleContinueAction = async () => {
		setState({ ...state, loading: true });
		try {
			if (!state.files) {
				setState({ ...state, loading: false });
				return;
			}

			const data = await DirectDebitMxServices.getExportMulti({
				merchantCode: merchantSelected?.code as string,
				file: state.files?.[0],
			});
			if (data.code === 'E_PL_INVFORM') {
				const newBlob = new Blob([data.csv]);
				const url = window.URL.createObjectURL(newBlob);

				await setState({
					...state,
					files: undefined,
					buttonDisabled: true,
					totalErrors: data.resume.total,
					error: true,
					href: url,
				});
				await handleShowAlert({
					message: errorFormatter(data.code),
					type: 'error',
				});
				await setSteps(3);
				return;
			}
			if (data.code === 'S_OK') {
				setSteps(2);
				setState({
					...state,
					totalTransfers: data.resume.total,
					requestDate: data.resume.date,
				});
			}
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setState({ ...state, files: undefined, buttonDisabled: true, error: true, href: e.url });
			setSteps(1);
		} finally {
			setState((prevState) => ({ ...prevState, loading: false }));
		}
	};

	const handleChange = async (files: FileList | FileList[]) => {
		await setBtnValid(true);
		const hasFiles = files.length > 0;
		await setState({ ...state, files, buttonDisabled: !hasFiles, error: false, href: '' });
	};

	const processData = [
		{
			item: 'Suscripciones enviadas',
			data: state.totalTransfers,
		},
		{
			item: 'Fecha de envío',
			data: state.requestDate,
		},
	];

	const processDataError = [
		{
			item: 'Suscripciones con errores',
			data: state.totalErrors || 0,
		},
	];

	const handleDownload = () => {
		const { href } = state;
		const a = document.createElement('a');
		a.href = href;
		a.download = 'error-suscripciones-template-ETpay.csv';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	return (
		<Modal title="Suscripciones masivas" show={show} type="medium" onClose={handleCloseModal}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<ModalTitle>
						{steps === 1 && 'Sube el archivo .csv'}
						{steps === 2 && 'Suscripciones enviadas exitosamente'}
						{steps === 3 && 'Suscripciones con errores'}
					</ModalTitle>
					<ModalSubtitle align={steps != 1 ? 'center' : 'left'}>
						{steps === 1 && (
							<>
								Descarga{' '}
								<DocumentTemplate href={directDebitBatchMx} target="_blank">
									aquí
								</DocumentTemplate>{' '}
								la plantilla y rellena con la información de las suscripciones. Enviaremos un correo
								a cada cliente con el enlace para completar el proceso de suscripción. <br />
								<StyledList>
									<StyledListItem>
										Nombre del cliente{' '}
										<StyledListLabel>(al menos un nombre y un apellido),</StyledListLabel>
									</StyledListItem>
									<StyledListItem>
										CLABE interbancaria <StyledListLabel>del cliente (opcional),</StyledListLabel>
									</StyledListItem>
									<StyledListItem>
										Correo electrónico <StyledListLabel>del cliente,</StyledListLabel>
									</StyledListItem>
									<StyledListItem>
										Servicio a domiciliar<StyledListLabel>,</StyledListLabel>
									</StyledListItem>
									<StyledListItem>
										Fecha de vencimiento <StyledListLabel>(dd/mm/aaaa o aaaa-mm-dd),</StyledListLabel>
									</StyledListItem>
									<StyledListItem>
										Frecuencia del cargo:{' '}
										<StyledListLabel>
											semanal, quincenal, mensual, bimestral o por uso,
										</StyledListLabel>
									</StyledListItem>
									<StyledListItem>
										Monto máximo por cargo <StyledListLabel>(máximo $49,999.99).</StyledListLabel>{' '}
									</StyledListItem>
								</StyledList>
								No edites los nombres de los encabezados de las columnas; esto hará que el archivo
								sea ilegible.
							</>
						)}
						{steps === 2 && (
							<>
								Hemos enviado un correo a cada cliente con el enlace para completar el proceso de
								suscripción. Puedes consultar el estatus de las suscripciones en la sección
								“Suscripciones”.
							</>
						)}
						{steps === 3 && (
							<>
								Encontramos errores en algunas suscripciones.
								<br /> Descarga el archivo con los detalles de los errores, corrígelos y vuelve a
								cargar el archivo para completar el proceso.
							</>
						)}
					</ModalSubtitle>

					{steps === 1 && (
						<>
							<DragAndDropContainer>
								<DragAndDrop
									id="upload-batch-payments"
									accept={['.csv']}
									maxSize={2100000}
									onChange={handleChange}
								/>
							</DragAndDropContainer>
							{!state.buttonDisabled && (
								<Button
									style={{ marginTop: '32px' }}
									color="primary"
									onClick={() => handleContinueAction()}
									disabled={!btnValid}
								>
									Siguiente
								</Button>
							)}
						</>
					)}

					{steps === 2 && <RefundResume transferResume={processData} />}
					{steps === 3 && (
						<>
							<RefundResume transferResume={processDataError} />{' '}
							<Button style={{ marginTop: '32px' }} color="primary" onClick={handleDownload}>
								<DownloadIcon /> <LabelButtonIcon>Descargar .csv</LabelButtonIcon>
							</Button>
						</>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export { MultiDomiciliationModal };
