export const CancelIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
		<circle cx="22.334" cy="21.5" r="21.5" fill="#EE7D7A" />
		<path
			d="M28.546 15.7667L16.6016 27.9501"
			stroke="white"
			strokeWidth="5.73333"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.6005 15.7667L28.5449 27.9501"
			stroke="white"
			strokeWidth="5.73333"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
