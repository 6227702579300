import styled from 'styled-components';

export const TableFilterHeader = styled.div`
	display: flex;
	padding-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
		position: relative;
	}
`;

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const AlertContainer = styled.div`
	margin-bottom: 16px;
`;

export const StatusContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 34px;
`;

export const Card = styled.div`
	background-color: ${({ theme: { colors } }) => colors.principal.pure};
	border: 1px solid ${({ theme: { colors } }) => colors.principal.ceres};
	border-radius: 12px;
	margin-bottom: 27px;
	padding: 16px 12px;
	width: 100%;
`;

export const BalanceLabel = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.abell};
	font-size: 18px;
	font-weight: 400;
	letter-spacing: -0.014px;
	line-height: 28px;
`;

export const Balance = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.abell};
	font-size: 35px;
	font-weight: 500;
	letter-spacing: -0.028px;
	line-height: 110%;
	margin-bottom: 12px;
`;

export const CardDeposit = styled(Card)`
	margin-bottom: 32px;
	padding: 13px 24px;
`;

export const CardDepositTitle = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.orion};
	font-size: 18px;
	font-weight: 400;
	letter-spacing: -0.014px;
	margin-bottom: 12px;
`;

export const CardBody = styled.div`
	display: flex;
	gap: 34px;
`;

interface CardContentProps {
	disabled?: boolean;
}

export const CardContent = styled(Card)<CardContentProps>`
	margin: 0;
	opacity: ${({ disabled }) => disabled && 0.4};
	padding: 14px;
`;

export const CardContentTitleContainer = styled.div`
	display: flex;
	gap: 14px;
`;

export const CardContentTitle = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 18px;
	margin-bottom: 8px;
`;

export const CardContentDescription = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.vanth};
	font-size: 16px;
	font-style: normal;
	line-height: 18px;
	margin-bottom: 24px;
`;

export const CardFormContainer = styled.div`
	display: flex;
	flex-direction: column;

	& form > div > div > p > span {
		color: ${({ theme }) => theme.colors.extra.saturn};
	}
`;

export const Step = styled.span`
	background-color: #f3f6ff;
	border-radius: 100%;
	color: ${({ theme: { colors } }) => colors.extra.saturn};
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	height: 25px;
	letter-spacing: 0.3px;
	padding: 6px 9px;
	width: 25px;
`;

export const Button = styled.button`
	background: ${({ theme }) => theme.colors.extra.saturn};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	cursor: pointer;
	gap: 8px;
	margin-top: 32px;
	padding: 10px 38px;

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

export const CardProcess = styled(Card)`
	display: flex;
	flex-direction: column;
	padding: 13px 24px;
	width: 100%;

	& > div:last-child {
		border: none;
	}
`;

export const CardProcessBody = styled.div`
	align-items: center;
	border-bottom: 1px solid #e6e6e6;
	display: flex;
	gap: 32px;
	width: 100%;
`;

export const CardInfo = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const CardAction = styled.div`
	align-items: center;
	display: flex;
`;

export const IconContainer = styled.div`
	cursor: pointer;
	padding: 10px 10px 5px 5px;

	& > #deleted > path {
		fill: #636172;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: 29px;
`;

export const Text = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.orion};
	font-size: 14px;
	font-weight: 400;
	line-height: 94%;
`;

export const Hours = styled.p`
	color: ${({ theme: { colors } }) => colors.principal.vanth};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
`;
