import { InputHTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

import {
	IconContainer,
	InputContainer,
	InputElement,
	InputFileElement,
	Label,
	PrefixContainer,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	prefixSymbol?: string;
	leftIcon?: ReactNode;
	leftIconClick?: () => void;
	rightIcon?: ReactNode;
	rightIconClick?: () => void;
	variant?: 'medium' | 'large';
	labelFocus?: boolean;
}

const Input = ({
	label,
	prefixSymbol,
	name,
	value,
	leftIcon,
	rightIcon,
	leftIconClick,
	rightIconClick,
	variant = 'medium',
	labelFocus = false,
	...props
}: InputProps) => {
	const prefixElement = useRef<HTMLDivElement>(null);
	const [isFocused, setIsFocused] = useState(false);
	const [widthPrefix, setWidthPrefix] = useState(0);
	const inputFileRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (prefixElement.current) {
			setWidthPrefix(prefixElement.current.offsetWidth);
		}
	}, [prefixElement, isFocused, value]);

	return (
		<InputContainer>
			{leftIcon && (
				<IconContainer position="left" onClick={leftIconClick} variant={variant}>
					{leftIcon}
				</IconContainer>
			)}

			{prefixSymbol && (isFocused || value !== '') && (
				<PrefixContainer ref={prefixElement} variant={variant}>
					{prefixSymbol}
				</PrefixContainer>
			)}

			{props.type !== 'file' ? (
				<InputElement
					{...props}
					name={name}
					hasLeftIcon={!!leftIcon}
					hasRightIcon={!!rightIcon}
					hasPrefix={widthPrefix}
					placeholder={props.placeholder || undefined}
					variant={variant}
					value={value}
					defaultValue={props.defaultValue}
					isFile={props.type === 'file'}
					onFocus={(e) => {
						if (props.onFocus) {
							props.onFocus(e);
						}
						setIsFocused(true);
					}}
					onBlur={(e) => {
						if (props.onBlur) {
							props.onBlur(e);
						}
						setIsFocused(false);
					}}
				/>
			) : (
				<>
					<InputFileElement
						hasLeftIcon={!!leftIcon}
						placeholder={props.placeholder || undefined}
						hasRightIcon={!!rightIcon}
						hasFile={!!value || !!props.defaultValue}
						onClick={() => {
							if (inputFileRef.current) {
								inputFileRef.current.click();
							}
						}}
					>
						{((value || props.defaultValue) && value) || props.defaultValue}
					</InputFileElement>
					<input
						type="file"
						accept={props.accept}
						multiple={props.multiple}
						ref={inputFileRef}
						value={value}
						onChange={props.onChange}
						hidden
					/>
				</>
			)}

			{label && (
				<Label htmlFor={name} hasValue={!!value || !!props.defaultValue} isFocus={labelFocus}>
					{label}
				</Label>
			)}

			{rightIcon && (
				<IconContainer position="right" onClick={rightIconClick} variant={variant}>
					{rightIcon}
				</IconContainer>
			)}
		</InputContainer>
	);
};

export { Input };
