import { useTheme } from 'styled-components';

import { useMerchant } from '../../../hooks/useMerchant';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { StatusData } from '../../../models/home';
import {
	DescriptionSection,
	EmptyMessage,
	ErrorDescriptionItem,
	ErrorDraw,
	ExpiredDescriptionItem,
	ExpiredDraw,
	FunnelGraphContainer,
	FunnelGraphDescription,
	FunnelGraphDraw,
	FunnelGraphDrawLoading,
	IconDescription,
	PendingDescriptionItem,
	PendingDraw,
	PercentageDescription,
	SuccessDescriptionItem,
	SuccessDraw,
	ValueText,
} from './styles';

interface FunnelGraphProps {
	data?: StatusData;
	loading?: boolean;
}

const ChartFunnel = ({ data, loading }: FunnelGraphProps) => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const { breakpoints } = useTheme();

	const _breakpoints = +breakpoints.md.split('px')[0];

	return loading ? (
		<FunnelGraphContainer>
			<FunnelGraphDrawLoading />
			<FunnelGraphDescription>
				<DescriptionSection>
					<SuccessDescriptionItem>
						<IconDescription /> Exitoso
						<PercentageDescription>{data?.success}%</PercentageDescription>
					</SuccessDescriptionItem>
					<PendingDescriptionItem>
						<IconDescription /> {merchantSelected?.country === 'CL' ? 'Pendiente' : 'En proceso'}
						<PercentageDescription>{data?.pending}%</PercentageDescription>
					</PendingDescriptionItem>
				</DescriptionSection>

				<DescriptionSection>
					<ExpiredDescriptionItem>
						<IconDescription /> Expirado
						<PercentageDescription>{data?.expired}%</PercentageDescription>
					</ExpiredDescriptionItem>
					<ErrorDescriptionItem>
						<IconDescription /> Fallido
						<PercentageDescription>{data?.error}%</PercentageDescription>
					</ErrorDescriptionItem>
				</DescriptionSection>
			</FunnelGraphDescription>
		</FunnelGraphContainer>
	) : data ? (
		<FunnelGraphContainer>
			<FunnelGraphDraw>
				<SuccessDraw style={{ width: `${data?.success}%` }}>
					{width > _breakpoints && <ValueText>{data?.success}%</ValueText>}
				</SuccessDraw>
				<PendingDraw style={{ width: `${data?.pending}%` }}>
					{width > _breakpoints && <ValueText>{data?.pending}%</ValueText>}
				</PendingDraw>
				<ExpiredDraw style={{ width: `${data?.expired}%` }}>
					{width > _breakpoints && <ValueText>{data?.expired}%</ValueText>}
				</ExpiredDraw>
				<ErrorDraw style={{ width: `${data?.error}%` }}>
					{width > _breakpoints && <ValueText>{data?.error}%</ValueText>}
				</ErrorDraw>
			</FunnelGraphDraw>

			<FunnelGraphDescription>
				<DescriptionSection>
					<SuccessDescriptionItem>
						<IconDescription /> Exitoso
						<PercentageDescription>{data?.success}%</PercentageDescription>
					</SuccessDescriptionItem>
					<PendingDescriptionItem>
						<IconDescription /> {merchantSelected?.country === 'CL' ? 'Pendiente' : 'En proceso'}
						<PercentageDescription>{data?.pending}%</PercentageDescription>
					</PendingDescriptionItem>
				</DescriptionSection>

				<DescriptionSection>
					<ExpiredDescriptionItem>
						<IconDescription /> Expirado
						<PercentageDescription>{data?.expired}%</PercentageDescription>
					</ExpiredDescriptionItem>
					<ErrorDescriptionItem>
						<IconDescription /> Fallido
						<PercentageDescription>{data?.error}%</PercentageDescription>
					</ErrorDescriptionItem>
				</DescriptionSection>
			</FunnelGraphDescription>
		</FunnelGraphContainer>
	) : (
		<FunnelGraphContainer>
			<EmptyMessage>No se han recibido cobros</EmptyMessage>
		</FunnelGraphContainer>
	);
};

export { ChartFunnel };
