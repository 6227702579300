import { SVGProps } from 'react';

export const CheckIcon = ({ ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<g clipPath="url(#clip0_16843_13559)">
			<circle cx="15" cy="15" r="9.09091" fill="#140F33" stroke="#140F33" strokeWidth="1.81818" />
			<path
				d="M19 12.832L13.6667 17.832L11 15.332"
				stroke="white"
				strokeWidth="2.42424"
				strokeLinecap="square"
			/>
		</g>
		<defs>
			<clipPath id="clip0_16843_13559">
				<rect width="20" height="20" fill="white" transform="translate(5 5)" />
			</clipPath>
		</defs>
	</svg>
);
