import styled, { DefaultTheme } from 'styled-components';

import { PrimitiveStatus, Status } from '../../models/common-payments';
import { SizeAllowed } from '.';

interface StatusProps {
	readonly type: PrimitiveStatus;
	readonly size?: SizeAllowed;
}

const getColor = (type: PrimitiveStatus | Status, theme: DefaultTheme, size?: string) => {
	switch (type) {
		case 'success':
			return {
				bg: theme.colors.principal['light-alamak'],
				color: size === 'sm' ? theme.colors.principal.vanth : theme.colors.principal.orion,
			};
		case 'pending':
			return { bg: theme.colors.extra.omega, color: theme.colors.principal.orion };
		case 'error':
			return { bg: theme.colors.extra.canes, color: theme.colors.principal.pure };
		case 'expired':
			return { bg: theme.colors.principal.ceres, color: theme.colors.principal.orion };
		case 'sent_retry':
			return { bg: '#F3F6FF', color: theme.colors.extra.earth };
		case 'management':
			return { bg: '#FDEFEF', color: '#482524' };
		default:
			return { bg: theme.colors.principal.ceres, color: theme.colors.principal.vanth };
	}
};

export const StatusContainer = styled.div<StatusProps>`
	align-items: center;
	background-color: ${({ type, theme }) => getColor(type, theme).bg};
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	min-height: 24px;
	padding: 6px;
	width: max-content;
`;

export const Label = styled.span<StatusProps>`
	align-items: flex-end;
	color: ${({ type, theme, size }) => getColor(type, theme, size).color};
	display: flex;
	font-size: ${({ size }) => (size === 'sm' ? '11px' : '12px')};
	font-weight: 400;
	line-height: 12px;
	vertical-align: baseline;
`;
