import styled from 'styled-components';

export const TableFilterHeader = styled.div`
	display: flex;
	padding-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
		position: relative;
	}
`;

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: normal;
		flex-direction: column;
		gap: 16px;
	}
`;

export const ContainerButtons = styled.div`
	align-items: center;
	display: flex;
	gap: 12px;
	justify-content: space-between;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: normal;
		flex-direction: column;
		gap: 16px;
	}
`;

export const GenerateReturnsButton = styled.button`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: ${({ theme }) => `solid 1px ` + theme.colors.principal.ceres};
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.orion};
	cursor: pointer;
	height: 37px;
	min-width: 100px;
	outline: 0;
	padding: 0 12px;
	transition: background-color 0.2s ease-in-out;
	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.principal.ceres}CC;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const AlertContainer = styled.div`
	margin-bottom: 16px;
`;
