interface IconProps {
	active?: boolean;
	color?: string;
}

export const OutboundIcon = ({ active, color }: IconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 20 19" fill="none">
		<path
			d="M3.96559 17.8937L14.8594 6.99994L15.4806 16.3391"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
		<path
			d="M5.14055 12.6213L16.0343 1.72744L8.86914 1.10626"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
	</svg>
);
