interface IconProps {
	active?: boolean;
	color?: string;
}

export const AnalyticsIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.80176 14.3795V12.6759C2.80176 11.8959 3.43406 11.2636 4.21406 11.2636V11.2636C4.99405 11.2636 5.62635 11.8959 5.62635 12.6759V15.5476C5.62635 16.7159 6.57347 17.663 7.74181 17.663H7.892C9.06033 17.663 10.0075 16.7159 10.0075 15.5476V11.5707C10.0075 10.4942 10.8801 9.62153 11.9566 9.62153V9.62153C13.0331 9.62153 13.9058 10.4942 13.9058 11.5707V13.2373C13.9058 14.4057 14.8529 15.3528 16.0213 15.3528H16.1929C17.3612 15.3528 18.3083 14.4057 18.3083 13.2373V7.78186C18.3083 6.98384 18.9553 6.33691 19.7533 6.33691V6.33691C20.5513 6.33691 21.1982 6.98384 21.1982 7.78186V11.2636"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.58659"
		/>
	</svg>
);
