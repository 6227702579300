import { Dispatch, SetStateAction, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PaymentStatus } from '../../../../models/common-payments';
import { DirectDebit } from '../../../../models/direct-debit';
import { ColumnInterface, Filters, Pagination } from '../../../../models/table';
import { DirectDebitServices } from '../../../../services/direct-debit';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import {
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	StatusContainer,
} from './styles';

interface DirectDebitTableProps {
	data: DirectDebit[];
	isLoading?: boolean;
	stateList: PaymentStatus[];
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const DirectDebitTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
}: DirectDebitTableProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [modal, setModal] = useState<ModalProps>(defaultModal);

	const columns: ColumnInterface<DirectDebit>[] = [
		{
			sortField: true,
			title: 'RUT',
			propertyName: 'rut',
			cellRender: ({ rut }) => <>{rut}</>,
		},
		{
			sortField: true,
			title: 'Correo electrónico',
			propertyName: 'email',
			cellRender: ({ email }) => <>{email}</>,
		},
		{
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<StatusContainer>
					<Status status={status} />
				</StatusContainer>
			),
		},
		{
			sortField: true,
			title: 'Banco',
			propertyName: 'bankName',
			cellRender: ({ bank_name }) => <>{bank_name !== 'DESCONOCIDO' && bank_name}</>,
		},
		{
			sortField: true,
			title: 'Fecha de suscripción',
			propertyName: 'suscribeatDate',
			cellRender: ({ subscribed_at }) => <>{subscribed_at}</>,
		},
	];

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });
		try {
			const response = await DirectDebitServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from,
					to: filters?.dateRange?.to,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	const paymentLinkMultiuseStates = ['enabled', 'pending', 'disabled'];

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			<ETPayTable<DirectDebit>
				title="Todas las suscripciones"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				hasFilters
				allowExport={validatePermission('QT_EXPORT')}
				stateList={fillStatesWithZeros({ states: paymentLinkMultiuseStates, stateList })}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				statusContent={[
					['enabled', 'Activas'],
					['pending', 'Pendientes'],
					['disabled', 'Desactivadas'],
				]}
			/>
		</>
	);
};

export { DirectDebitTable };
