import { ResumeCard } from '../../../../components/resume-card';
import { Head, ResumeCardType } from '../../../../models/analytics';

interface ResumeSectionProps {
	data?: Head;
}

const ResumeSection = ({ data }: ResumeSectionProps) => {
	return (
		<>
			<ResumeCard
				type={ResumeCardType.AVG_TICKET}
				value={data?.avg_ticket ? data.avg_ticket.value : undefined}
				icon={data?.avg_ticket ? data.avg_ticket.icon : undefined}
				resume={data?.avg_ticket ? data.avg_ticket.resume : undefined}
				prefix="$"
			/>

			<ResumeCard
				type={ResumeCardType.CLIENTS}
				value={data?.num_clients ? data.num_clients.value : undefined}
				icon={data?.num_clients ? data.num_clients.icon : undefined}
				resume={data?.num_clients ? data.num_clients.resume : undefined}
			/>

			<ResumeCard
				type={ResumeCardType.NEW_CLIENTS}
				value={data?.new_clients ? data.new_clients.value : undefined}
				icon={data?.new_clients ? data.new_clients.icon : undefined}
				resume={data?.new_clients ? data.new_clients.resume : undefined}
			/>

			<ResumeCard
				type={ResumeCardType.RECURRENT_CLIENTS}
				value={data?.recurrent_clients ? data.recurrent_clients.value : undefined}
				icon={data?.recurrent_clients ? data.recurrent_clients.icon : undefined}
				resume={data?.recurrent_clients ? data.recurrent_clients.resume : undefined}
			/>
		</>
	);
};

export { ResumeSection };
