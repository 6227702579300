import { FileIcon } from '../../../../../../assets/svg/FileIcon';
import ProgressBar from '../../../progress-bar';
import {
	DataContainer,
	DocumentDone,
	FileContainer,
	InfoContainer,
	Item,
	ItemContainer,
	NameFile,
	SizeFile,
} from '../../styles';

interface Card {
	data?: boolean;
	nameFile?: string;
	sizeFile?: string;
	allowDownload?: boolean;
	progressBar?: boolean;
}

const CardDataExample = ({ data, nameFile, sizeFile, allowDownload, progressBar }: Card) => {
	return (
		<DataContainer itemData={data}>
			{data && (
				<ItemContainer>
					<Item>merchant_order_id</Item>
					<Item>monto</Item>
					<Item>descripción</Item>
				</ItemContainer>
			)}
			{nameFile && (
				<FileContainer>
					<div>
						<FileIcon />
					</div>
					<InfoContainer>
						<NameFile>{nameFile}</NameFile>
						<SizeFile>{sizeFile}</SizeFile>
						{progressBar && <ProgressBar />}
						{allowDownload && <DocumentDone>Documento listo</DocumentDone>}
					</InfoContainer>
				</FileContainer>
			)}
		</DataContainer>
	);
};

export default CardDataExample;
