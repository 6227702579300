import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const ModalBody = styled.div`
	width: 100%;
`;

export const TitlesContainer = styled.div`
	margin-bottom: 32px;
	width: 100%;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const Section = styled.p`
	color: #140f33;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.6px;
	line-height: 12px;
	text-align: center;
`;

export const Subtitle = styled.p`
	color: #646174;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
`;

export const PayoutTypeSection = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
`;

export const TitleSection = styled.p`
	color: #646174;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-bottom: 8px;
`;

export const Steps = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
`;

export const ModalFooter = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

type ButtonProps = {
	variant?: 'cancel' | 'send';
};

export const Button = styled.button<ButtonProps>`
	align-items: center;
	background-color: ${({ theme, variant }) =>
		(variant === 'cancel' && theme.colors.principal.pure) ||
		(variant === 'send' && theme.colors.extra.canes)};
	border: 1px solid
		${({ theme, variant }) =>
			variant === 'cancel' ? theme.colors.principal.ceres : theme.colors.extra.canes};
	border-radius: 8px;
	color: ${({ theme, variant }) =>
		(variant === 'cancel' && theme.colors.principal.vanth) ||
		(variant === 'send' && theme.colors.principal.pure)};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	outline: none;
	padding: 15px 8px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const TabsContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;

export const TabBody = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
	width: '100%';
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 47px;
`;

export const HintInput = styled.p`
	color: ${({ theme }) => theme.colors.principal.moon};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	width: 100%;
`;

interface PayoutTypeContainerProps {
	isDisabled: boolean;
	isSelected: boolean;
}

export const PayoutTypeContainer = styled.div<PayoutTypeContainerProps>`
	align-items: center;
	background-color: ${({ theme, isDisabled }) =>
		theme.colors.principal[isDisabled ? 'eris' : 'pure']};
	border: 1px solid
		${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'vanth' : 'ceres']};
	border-radius: 12px;
	cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 36px 54px;
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
	position: relative;
	text-align: center;
	width: 100%;
`;

export const SelectedIconContainer = styled.div`
	position: absolute;
	right: 3px;
	top: 3px;
`;

interface IconContainerProps {
	show: boolean;
	isSelected: boolean;
}

export const IconContainer = styled.div`
	align-items: center;
	border: 1px solid
		${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'orion' : 'vanth']};
	border-radius: 50%;
	display: flex;
	height: 45px;
	justify-content: center;
	opacity: ${({ show }: IconContainerProps) => (show ? 1 : 0)};
	width: 45px;
`;

interface TextsProps {
	isSelected: boolean;
}

export const PayoutTypeTitle = styled.p<TextsProps>`
	color: ${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'orion' : 'vanth']};
	font-size: 14px;
	line-height: 21px;
	margin-top: 8px;
`;

export const PayoutTypeSubTitle = styled.p<TextsProps>`
	color: ${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'orion' : 'vanth']};
	font-size: 11px;
	line-height: 13px;
`;

export const TransferDetail = styled.div`
	background: #f8f8f8;
	border-radius: 12px;
	padding: 12px;
	width: 100%;
`;

export const TransferDetailContainer = styled.div`
	align-items: center;
	border-bottom: 1px solid #f2f2f2;
	display: flex;
	justify-content: space-between;
	padding: 8px 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
		padding: 8px 0 0;
	}
`;

export const TransferItem = styled.p`
	color: #636172;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

export const TransferData = styled.p`
	color: #140f33;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

export const DragAndDropContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;
export const ModalTitle = styled.p`
	color: #140f33;
	font-size: 20px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 8px;
	text-align: center;
`;

interface ModalSubtitleProp {
	align?: 'start' | 'center' | 'end';
}

export const ModalSubtitle = styled.div<ModalSubtitleProp>`
	color: #646174;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 32px;
	text-align: ${({ align }) => (align ? align : 'center')};

	a {
		color: ${({ theme }) => theme.colors.principal.orion};
		font-weight: 500;
		text-decoration: underline;
	}
`;
