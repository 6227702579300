import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { DirectDebitIcon } from '../../assets/svg/DirectDebitIcon';
import { ResumeCard } from '../../components/resume-card';
import { Section } from '../../components/section';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { ResumeCardType } from '../../models/home';
import { DirectDebitServices } from '../../services/direct-debit';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { NewSinglePayment } from './components/new-single-payment';
import { DirectDebitChart } from './components/subscriptions-charts';
import { DirectDebitTable } from './components/table';
import {
	ButtonsContainer,
	HeadContainer,
	IndicatorsContainer,
	NewSuscriptionButton,
	RightSection,
	SectionContainer,
	TitlePage,
} from './styles';

export interface State {
	qr: string;
	url: string;
}

const defaultValues: State = {
	qr: '',
	url: '',
};

const DirectDebit = () => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);
	const [modal, setModal] = useState(false);
	const [state, setState] = useState<State>(defaultValues);

	const { data: graphs } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.directDebit.getGraphData, merchantSelected?.code],
		queryFn: () =>
			DirectDebitServices.getGraphicsData({ merchantCode: merchantSelected?.code as string }),
	});

	const { data: statistics } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.directDebit.getTableInfo, merchantSelected?.code],
		queryFn: () =>
			DirectDebitServices.getStatistics({ merchantCode: merchantSelected?.code as string }),
	});

	const {
		data: table,
		isFetching,
		isFetched,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.paymentLinks.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			DirectDebitServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.quick_transfer.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	const handleNewSubscription = () => async () => {
		setModal(true);
		try {
			const data = await DirectDebitServices.newSubscription({
				merchantCode: merchantSelected?.code as string,
			});
			setState({ ...state, qr: data.dd_qr, url: data.dd_url });
		} catch (e: any) {
			setModal(false);
		}
	};

	const onClose = () => {
		setModal(false);
	};

	const loadData = graphs?.enabled_graphic?.map((item, i) => {
		return {
			...item,
			y_amount: Number(graphs?.amount_graphic[i]?.y),
			date: graphs?.amount_graphic[i]?.x_formatted_full,
		};
	});

	return (
		<ViewWrapper title="Débito Directo">
			{modal && (
				<NewSinglePayment show={modal} onClose={onClose} state={state} setState={setState} />
			)}

			<HeadContainer>
				<TitlePage>
					<DirectDebitIcon /> Débito Directo
				</TitlePage>

				<ButtonsContainer>
					<NewSuscriptionButton onClick={handleNewSubscription()}>
						Nueva Suscripción
					</NewSuscriptionButton>
				</ButtonsContainer>
			</HeadContainer>
			<SectionContainer>
				{width > 578 && loadData && (
					<>
						<Section
							title="Monto Recaudado y Suscripciones activas"
							rightSection={<RightSection>Últimos 8 meses</RightSection>}
						>
							<DirectDebitChart data={loadData} />
						</Section>
						<IndicatorsContainer>
							<ResumeCard
								key={1}
								type={ResumeCardType.NEWS_SUBSCRIPTIONS}
								value={statistics?.new_subscriptions.value}
								icon={statistics?.new_subscriptions.icon}
								resume={statistics?.new_subscriptions.percentage}
								text="que el mes anterior"
							/>
							<ResumeCard
								key={2}
								type={ResumeCardType.ENABLED_SUBSCRIPTIONS}
								value={statistics?.enabled.value}
								icon={statistics?.enabled.icon}
								resume={statistics?.enabled.percentage}
								text="que el mes anterior"
							/>
						</IndicatorsContainer>
					</>
				)}
			</SectionContainer>

			<DirectDebitTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				stateList={table?.typeOfStatus || []}
			/>
		</ViewWrapper>
	);
};

export { DirectDebit };
