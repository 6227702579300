import { FeatureActiveIcon, FeatureInactiveIcon } from '../../../../assets/svg';
import { RolesAllowed } from '../../../../models/users';
import { FEATURES, hasFeaturesByRole } from '../../../../utils/features';
import { Feature, SectionFeatures, TitleFeature } from './styles';

interface FeaturesSectionProps {
	role: RolesAllowed;
}

const FeaturesSection = ({ role }: FeaturesSectionProps) => {
	return (
		<>
			{FEATURES.map((feature) => (
				<SectionFeatures key={feature.name}>
					<TitleFeature>{feature.name}</TitleFeature>
					{feature.features.map((item) => (
						<Feature key={item} isActive={!!hasFeaturesByRole(role, item)}>
							{hasFeaturesByRole(role, item) ? <FeatureActiveIcon /> : <FeatureInactiveIcon />}
							{item}
						</Feature>
					))}
				</SectionFeatures>
			))}
		</>
	);
};

export { FeaturesSection };
