import styled from 'styled-components';

export const List = styled.ul`
	width: 100%;
`;

export const Item = styled.li`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 14px;
	font-weight: 400;
	height: 38px;
	justify-content: space-between;
	line-height: 21px;

	&::first-letter {
		text-transform: uppercase;
	}

	&:not(:last-child) {
		border-bottom: 0.5px solid ${({ theme }) => theme.colors.principal.ceres};
		margin-bottom: 4px;
	}
`;

export const ItemTitle = styled.span`
	background-color: ${({ theme }) => theme.colors.principal.vanth};
	border-radius: 20px;
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	padding: 6px 8px;
`;
