interface IconProps {
	active?: boolean;
	color?: string;
}

export const QTIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.01956 10.7753C8.01956 11.8111 8.43893 12.8044 9.18541 13.5368C9.93189 14.2692 10.9443 14.6807 12 14.6807C13.0557 14.6807 14.0681 14.2692 14.8146 13.5368C15.5611 12.8044 15.9805 11.8111 15.9805 10.7753L12 10.7753L8.01956 10.7753Z"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
		<path
			d="M7.78141 17.152C7.78141 18.2497 8.22587 19.3025 9.01701 20.0787C9.80816 20.8549 10.8812 21.291 12 21.291C13.1189 21.291 14.1919 20.8549 14.983 20.0787C15.7742 19.3025 16.2186 18.2497 16.2186 17.152L12 17.152L7.78141 17.152Z"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
		<circle
			cx="12.0001"
			cy="6.37405"
			r="1.93606"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
	</svg>
);
