import { CommonParam, PayrollsResponse, UploadChargesParams } from '../models/direct-debit';
import { client } from '../utils/api';

const getChargesCsv = async ({ merchantCode }: CommonParam) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get('/v1/dd/get-charges', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getPayrolls = async ({ merchantCode }: CommonParam) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<PayrollsResponse>('/v1/dd/uploaded-payrolls', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const uploadCharges = async ({ merchantCode, file, contractDate }: UploadChargesParams) => {
	try {
		const params = {
			merchant_code: merchantCode,
			contract_date: contractDate,
		};
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/dd/send-charges', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'File-Extension': ext,
			},
		});

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const DirectDebitManagementServices = {
	getChargesCsv,
	getPayrolls,
	uploadCharges,
};
