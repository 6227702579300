import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
	active?: boolean;
}

export const ArrowIcon = ({ active, ...rest }: IProps) => (
	<svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
		<path
			d="M10.5673 1.31641L6.19996 5.68371L1.83266 1.31641"
			stroke={active ? '#140F33' : '#646174'}
			strokeWidth={1.5}
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
