import { useTheme } from 'styled-components';

import { ResponseGraphics as Graphics } from '../../../../models/common-payments';
import { AmountsBarGraph } from '../amount-graphic';
import { BanksPieChart } from '../banks-pie-chart';
import {
	HeaderContainer,
	SectionContainer,
	SectionContent,
	SectionDate,
	SectionHeader,
	SectionTitle,
} from './styles';

interface AllPaymentsHeaderProps {
	data?: Graphics;
}

const AllPaymentsHeader = ({ data }: AllPaymentsHeaderProps) => {
	const { colors } = useTheme();
	const COLORS = [colors.principal.orion, '#433f5c', '#898798', '#d0cfd6', '#e8e7eb', '#a648f9'];

	return (
		<HeaderContainer>
			<SectionContainer>
				<SectionHeader>
					<SectionTitle>Monto recaudado</SectionTitle>
					<SectionDate>Últimas 24 horas</SectionDate>
				</SectionHeader>

				<SectionContent>
					<AmountsBarGraph data={data?.amounts} barColor={COLORS[1]} tooltipColor="#e8e7eb" />
				</SectionContent>
			</SectionContainer>

			<SectionContainer>
				<SectionHeader>
					<SectionTitle>Cobros exitosos por banco</SectionTitle>
					<SectionDate>Últimas 24 horas</SectionDate>
				</SectionHeader>

				<SectionContent>
					<BanksPieChart data={data} colors={COLORS} tooltipColor="#e8e7eb" />
				</SectionContent>
			</SectionContainer>
		</HeaderContainer>
	);
};

export { AllPaymentsHeader };
