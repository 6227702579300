import { CommonTableParams } from '../models/common-payments';
import {
	ExportDataParams,
	ExportExcel,
	ExportExcelModal,
	ExportTemplateDownload,
	ResponseExport,
	ResponseTableDirectDebitMx,
	SendEmailParams,
} from '../models/direct-debit-mx';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getTableData = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableDirectDebitMx>('/v1/mx/direct-debit/table', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/mx/direct-debit/exports', { params }); // TODO: change endpoint
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendSubscriptionByEmail = async ({ merchant_code, ...resp }: SendEmailParams) => {
	try {
		const body = resp;
		const { data } = await client.post('v1/mx/direct-debit/generate', body, {
			params: { merchant_code },
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportMulti = async ({ merchantCode, file }: ExportExcel) => {
	try {
		const params = { merchant_code: merchantCode };
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/mx/direct-debit/generate/massive', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'file-extension': ext,
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const postSubscriptionCharge = async ({ merchantCode, file }: ExportExcelModal) => {
	try {
		const params = { merchant_code: merchantCode };
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/mx/direct-debit/validate/massive/charges', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'file-extension': ext,
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportTemplateDownload = async ({ ...rest }: ExportTemplateDownload) => {
	try {
		const { data } = await client.get(
			`/v1/mx/direct-debit/${rest.merchant.code}/generate/csv/charges`,
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const DirectDebitMxServices = {
	getTableData,
	getExportData,
	sendSubscriptionByEmail,
	postSubscriptionCharge,
	getExportMulti,
	getExportTemplateDownload,
};
