import { Dispatch, SetStateAction, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Select } from '../../../../components/form/select';
import { ETPayTable } from '../../../../components/table';
import { useAuth } from '../../../../hooks/useAuth';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { ColumnInterface, Pagination } from '../../../../models/table';
import { User, UserStatus, UserStatusEnum } from '../../../../models/users';
import { UsersServices } from '../../../../services/users';
import { reduceRoles, validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { EditUser } from '../modals/edit-user';
import { RemoveUser } from '../modals/remove-user';
import { ButtonsContainer, RoleName, StatusContainer } from './styles';

interface UsersTableProps {
	data: User[];
	isLoading?: boolean;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	refetch?: () => void;
}

interface ModalProps {
	show: boolean;
	id: string | null;
	roleId?: number;
	email?: string;
}

const defaultModalProps: ModalProps = {
	show: false,
	id: null,
	roleId: undefined,
	email: undefined,
};

const UsersTable = ({
	data,
	isLoading,
	pagination,
	setPagination,
	totalResults,
	refetch,
}: UsersTableProps) => {
	const { user } = useAuth();
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [resendButtonLoading, setResendButtonLoading] = useState<string[]>([]);
	const [showModal, setShowModal] = useState<ModalProps>(defaultModalProps);

	const handleResendEmail = async (id: string, email: string) => {
		setResendButtonLoading([id]);
		try {
			await UsersServices.resendInvitation({
				merchantCode: merchantSelected?.code as string,
				id,
				email,
			});
			handleShowAlert({
				type: 'success',
				message: 'Invitación reenviada',
			});
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		} finally {
			setResendButtonLoading(resendButtonLoading.filter((resendButton) => resendButton !== id));
		}
	};

	const StatusTransform = new Map<UserStatus, string>([
		['PENDING', 'Pendiente'],
		['ACCEPTED', 'Aceptado'],
		['EXPIRED', 'Expirado'],
		['INACTIVE', 'Inactivo'],
		['INVITED', 'Invitado'],
		['DELETED', 'Eliminado'],
	]);

	const columns: ColumnInterface<User>[] = [
		{
			title: 'Nombre',
			propertyName: 'names',
			cellRender: ({ names, lastnames }) => (
				<>
					{names} {lastnames}
				</>
			),
		},
		{
			title: 'Correo electrónico',
			propertyName: 'email',
			cellRender: ({ email }) => <>{email}</>,
		},
		{
			title: 'Estatus',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<>
					{status !== UserStatusEnum.Accepted && (
						<StatusContainer>{StatusTransform.get(status)}</StatusContainer>
					)}
				</>
			),
		},
		{
			title: 'Rol',
			propertyName: 'role_name',
			cellRender: ({ role_id, role_name, status, cognito_id, email }) => (
				<>
					{status !== UserStatusEnum.Accepted || email === user?.email ? (
						<RoleName>{role_name}</RoleName>
					) : (
						<div style={{ width: 150 }}>
							{validatePermission('SETTINGS_USERS_CHANGE_ROLE') ? (
								<Select
									options={reduceRoles(
										merchantSelected?.role_id as number,
										user?.email.includes('@etpay'),
									)}
									defaultOption={role_id}
									hasBorder={false}
									onChange={(e) => {
										setShowModal({ show: true, id: cognito_id, roleId: e.value as number });
									}}
								/>
							) : (
								<RoleName>{role_name}</RoleName>
							)}
						</div>
					)}
				</>
			),
		},
		{
			title: '',
			propertyName: '',
			cellRender: ({ status, cognito_id, email }) => (
				<>
					<ButtonsContainer>
						{status === UserStatusEnum.Pending &&
							validatePermission('SETTINGS_USERS_INVITE_RESEND') && (
								<ETPayButton
									title={resendButtonLoading.includes(cognito_id) ? 'Cargando...' : 'Reenviar'}
									onClick={() => handleResendEmail(cognito_id, email)}
									disabled={resendButtonLoading.includes(cognito_id)}
								/>
							)}
						{status !== UserStatusEnum.Deleted && validatePermission('SETTINGS_USERS_DELETE') && (
							<ETPayButton
								title={resendButtonLoading.includes(cognito_id) ? 'Cargando...' : 'Eliminar'}
								onClick={() => setShowModal({ show: true, id: cognito_id, email })}
								disabled={email === user?.email || resendButtonLoading.includes(cognito_id)}
							/>
						)}
					</ButtonsContainer>
				</>
			),
		},
	];


	const handleCloseModal = () => {
		setShowModal(defaultModalProps);
	};

	return (
		<>
			{showModal.show && showModal.id && showModal.roleId && (
				<EditUser
					show={showModal.show}
					handleCloseModal={handleCloseModal}
					userSelected={{ id: showModal.id, roleId: showModal.roleId }}
				/>
			)}

			{showModal.show && showModal.id && showModal.email && (
				<RemoveUser
					show={showModal.show}
					handleCloseModal={handleCloseModal}
					userSelected={{ id: showModal.id, email: showModal.email }}
					refetch={refetch}
				/>
			)}

			<ETPayTable<User>
				data={data}
				emptyMessage="No hay usuarios registrados en este comercio"
				isLoading={!!isLoading}
				columns={columns}
				hasPagination
				hasPaginationSelect
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</>
	);
};

export { UsersTable };
