import { ChangeEvent, useState } from 'react';

import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { DirectDebitRendition } from '../../../../models/direct-debit';
import { DirectDebitRenditionServices } from '../../../../services/direct-debit-rendition';
import { errorFormatter } from '../../../../utils/errors';
import { StateType } from './components/state-type';
import {
	Button,
	ButtonContainer,
	ContainerInput,
	ContainerInputs,
	CopyButton,
	Disclaimer,
	DisclaimerParagraph,
	DisclaimerTitle,
	Header,
	InputGroup,
	ModalBody,
	ModalContainer,
	PaymentTypeSection,
	Subtitle,
	Title,
	TitleSection,
} from './styles';

export type TypesAllowed = 'rejected' | 'successMerchant';

interface RetryPaymentProps {
	onClose: () => void;
	manageState?: DirectDebitRendition;
	openNext: () => void;
	isLast: boolean;
}

const defaultState = {
	observation: '',
	statePayment: '',
};

const RetryPayment = ({ onClose, manageState, openNext, isLast }: RetryPaymentProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [state, setState] = useState(defaultState);
	const [copyText, setCopyText] = useState({ rut: 'Copiar', email: 'Copiar' });
	const [typeSelected, setTypeSelected] = useState<TypesAllowed | null>(null);
	const [loading, setLoading] = useState(false);

	const handleSelectType = (type: TypesAllowed) => {
		setTypeSelected(type);
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const saveAndNext = async () => {
		setLoading(true);

		try {
			await DirectDebitRenditionServices.prePayment({
				merchantCode: merchantSelected?.code as string,
				amount: manageState?.amount as string,
				bank_name: manageState?.bank_name as string,
				created_at: manageState?.createdat_helper as string,
				email: manageState?.email as string,
				rut: manageState?.rut as string,
				observation: state.observation,
				status: typeSelected as string,
			});

			setState(defaultState);
			setLoading(false);
			setTypeSelected(null);
			if (!isLast) openNext();
			else onClose();

			handleShowAlert({
				type: 'success',
				message: 'Cobro actualizado',
			});
		} catch (e: any) {
			setLoading(false);
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	return (
		<Modal show={true} type="medium" title="Gestión Manual" onClose={onClose}>
			{loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<ModalBody>
						<div>
							<Header>Reintento de cobro</Header>
							<Title>Verifica la información</Title>
							<Subtitle>
								Antes de modificar manualmente, contacta con el cliente para saber el estado de su
								pago
							</Subtitle>
						</div>

						<ContainerInputs>
							<ContainerInput>
								<Input
									label="RUT"
									name="paymentLink"
									defaultValue={manageState?.rut}
									variant="large"
									readOnly
									rightIcon={<CopyButton>{copyText.rut}</CopyButton>}
									rightIconClick={async () => {
										await navigator.clipboard.writeText(manageState?.rut || '');
										setCopyText({ ...copyText, rut: 'Copiado' });
										setTimeout(() => setCopyText({ ...copyText, rut: 'Copiar' }), 2500);
									}}
								/>
							</ContainerInput>

							<ContainerInput>
								<Input
									label="Correo electrónico"
									name="paymentLink"
									defaultValue={manageState?.email}
									variant="large"
									readOnly
									rightIcon={<CopyButton>{copyText.email}</CopyButton>}
									rightIconClick={async () => {
										await navigator.clipboard.writeText(manageState?.email || '');
										setCopyText({ ...copyText, email: 'Copiado' });
										setTimeout(() => setCopyText({ ...copyText, email: 'Copiar' }), 2500);
									}}
								/>
							</ContainerInput>

							<InputGroup>
								<Input label="Monto" defaultValue={manageState?.amount} variant="large" readOnly />
								<Input
									label="Banco"
									defaultValue={manageState?.bank_name}
									variant="large"
									readOnly
								/>
							</InputGroup>

							<Input
								name="observation"
								label="Observación (max. 50 caracteres)"
								placeholder="Cliente realiza pago mediante transferencia..."
								variant="large"
								value={state.observation}
								onChange={handleChangeInput}
								maxLength={50}
							/>
						</ContainerInputs>

						<div>
							<TitleSection>Estado del cobro</TitleSection>
							<PaymentTypeSection>
								<StateType
									type="rejected"
									title="Cancelado"
									subtitle="El cliente no quiere continuar con el servicio"
									isSelected={typeSelected === 'rejected'}
									onClick={() => {
										handleSelectType('rejected');
									}}
								/>

								<StateType
									type="successMerchant"
									title="Cobrado manualmente"
									subtitle="Se realizó fuera del flujo de ETpay"
									isSelected={typeSelected === 'successMerchant'}
									onClick={() => {
										handleSelectType('successMerchant');
									}}
								/>
							</PaymentTypeSection>
						</div>

						<ButtonContainer>
							<Button variant="cancel" onClick={onClose}>
								Cancelar
							</Button>

							<Button variant="send" onClick={saveAndNext} disabled={typeSelected === null}>
								{isLast ? 'Guardar' : 'Guardar y pasar a siguiente'}
							</Button>
						</ButtonContainer>

						<Disclaimer>
							<DisclaimerTitle>Recuerda:</DisclaimerTitle>
							<DisclaimerParagraph>
								Este proceso se puede demorar unos minutos en actualizar la tabla de contenido
							</DisclaimerParagraph>
						</Disclaimer>
					</ModalBody>
				</ModalContainer>
			)}
		</Modal>
	);
};

export { RetryPayment };
