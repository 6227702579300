import { SVGProps } from 'react';

export const LeftArrowIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.5 11L6.41667 11"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M11 16.5L5.5 11L11 5.5"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
