interface Payload {
	coordinate: number;
	isShow: boolean;
	offset: number;
	tickCoord: number;
	value: number;
}

interface CustomizedTickProps {
	payload: Payload;
	x?: string;
	y?: string;
	symbol?: string;
	position?: 'left' | 'right';
	fontSize?: number;
	dx?: number;
}

const CustomizedTick = ({
	payload,
	x,
	y,
	symbol,
	position = 'left',
	fontSize,
	...props
}: CustomizedTickProps) => {
	return (
		<text
			width="40"
			orientation="right"
			height="200"
			type="number"
			x={x}
			y={y}
			stroke="none"
			fill="#666"
			textAnchor="start"
			fontSize={fontSize}
		>
			<tspan x={x} dx={props.dx}>
				{position === 'left'
					? `${symbol}${formatAbbr({ count: payload.value })}`
					: `${formatAbbr({ count: payload.value })}${symbol}`}
			</tspan>
		</text>
	);
};

interface FormatAbbrProps {
	count: number;
	withAbbr?: boolean;
	decimals?: number;
}

export const formatAbbr = ({ count, withAbbr = true, decimals = 0 }: FormatAbbrProps) => {
	const COUNT_ABBRS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
	const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
	let countAbbreviated = parseFloat((count / Math.pow(1000, i)).toFixed(decimals)).toString();
	return withAbbr && (i > 0 ? (countAbbreviated += `${COUNT_ABBRS[i]}`) : count);
};

export { CustomizedTick };
