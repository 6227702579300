import styled from 'styled-components';

import { getColor } from '../../components/resume-card/styles';

export const HeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const ResumeContainer = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
		flex-wrap: wrap;
		gap: 16px;
		margin-bottom: 12px;
	}
`;

export const SectionContainer = styled.section`
	display: flex;
	gap: 32px;
	width: 100%;
`;

export const RightSection = styled.div`
	color: #7d7d7d;
	display: flex;
	gap: 40px;
`;

export const CurrentRange = styled.div`
	position: relative;

	&::before {
		border-bottom: 2px solid #140f33;
		content: '';
		height: max-content;
		left: -25px;
		position: absolute;
		top: 50%;
		width: 20px;
	}
`;

export const PreviousRange = styled.div`
	position: relative;

	&::before {
		border-bottom: 2px dotted #140f3366;
		content: '';
		height: max-content;
		left: -25px;
		position: absolute;
		top: 50%;
		width: 20px;
	}
`;

export const CustomTooltipContainer = styled.div`
	background-color: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 8px 12px;
`;

export const CustomTooltipTitle = styled.p`
	display: flex;
	font-size: 14px;
	gap: 4px;
	text-align: center;

	span {
		font-size: 12px;
	}
`;

interface CustomTooltipItemProps {
	type: 'less' | 'more' | 'equal';
}

export const PercentageDetails = styled.div<CustomTooltipItemProps>`
	display: flex;
	font-size: 12px;
	margin-top: 13px;

	p {
		align-items: center;
		display: flex;
		font-size: 14px;
		gap: 8px;

		span {
			align-items: center;
			display: flex;

			&:first-child {
				font-weight: 600;
			}

			&:last-child {
				color: ${({ theme, type }) => getColor(type, theme)};
			}
		}
	}
`;
