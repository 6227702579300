import styled from 'styled-components';

export const HeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const BodySection = styled.div`
	margin-top: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		width: 50%;
	}
`;

export const InputContainer = styled.div`
	margin-top: 16px;
`;

export const InputContainerGroup = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	margin-top: 16px;
`;

export const RestorePassword = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	font-size: 14px;
	padding-top: 4px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 32px;
`;
