import { useTheme } from 'styled-components';

import { ChartPie } from '../../../../components/graphs/pie';
import { ResponseGraphics } from '../../../../models/common-payments';
import {
	ContainerWrapper,
	EmptyMessage,
	Item,
	ItemLoading,
	List,
	ListLoading,
	NameLoading,
	Value,
	ValueLoading,
	Wrapper,
} from './styles';

interface ClientsChartProps {
	data?: ResponseGraphics;
	loading?: boolean;
}

const LabelsMap = new Map([
	['new_clients', 'Nuevos'],
	['usual_clients', 'Frecuentes'],
]);

const ClientsChart = ({ data, loading }: ClientsChartProps) => {
	const { colors } = useTheme();
	const COLORS = ['#140F33', colors.principal.vanth];
	const hasData = data && data.data.length > 0;

	return (
		<ContainerWrapper>
			{hasData && (
				<Wrapper>
					<ChartPie data={data} colors={COLORS} tooltipColor="#e8e7eb" />
				</Wrapper>
			)}
			<Wrapper>
				{loading ? (
					<>
						{[...Array(2)].map((_, i) => (
							<ListLoading key={i}>
								<ItemLoading>
									<NameLoading />
									<ValueLoading />
								</ItemLoading>
							</ListLoading>
						))}
					</>
				) : hasData ? (
					data.data.map((item, index) => (
						<List key={item.name} bg={COLORS[index % COLORS.length]}>
							<Item>
								{LabelsMap.get(item.name)}
								<Value>{item.value_formatted}</Value>
							</Item>
						</List>
					))
				) : (
					<EmptyMessage>No se han registrado cobros en este periodo</EmptyMessage>
				)}
			</Wrapper>
		</ContainerWrapper>
	);
};

export { ClientsChart };
