import OpenReplay from '@openreplay/tracker';

import { config } from '../config';

export const tracker = new OpenReplay({
	projectKey: config.open_replay_project_key,
	__DISABLE_SECURE_MODE: config.env === 'development',
	onStart: () => {
		if (config.env === 'development') {
			console.log('OpenReplay started');
		}
	},
});
