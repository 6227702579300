import { QRCodeContainer, QRSubtitle, QRTitle, ScanQRText, TabBody } from '../styles';

interface QRTabProps {
	id: string;
	amount: string;
	merchantName: string;
	paymentLinkUrl: string;
}

const QRTab = ({ id, amount, merchantName, paymentLinkUrl }: QRTabProps) => {
	return (
		<TabBody id={id}>
			<QRTitle>Pagar ${amount}</QRTitle>
			<QRSubtitle>a {merchantName}</QRSubtitle>

			<QRCodeContainer>
				<img src={paymentLinkUrl} alt="QR del pago" />
			</QRCodeContainer>

			<ScanQRText>Escanea el código para pagar</ScanQRText>
		</TabBody>
	);
};

export { QRTab };
