import { ChangeEvent, useState } from 'react';

interface RutValidationHook {
	rut: string;
	isValid: boolean;
	handleRutChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useRut: () => RutValidationHook = () => {
	const [rut, setRut] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(true);

	const formatRut = (input: string): string => {
		const cleanedRut = input.replace(/[^\dkK]/g, '');

		const rutNumber = cleanedRut.slice(0, -1);
		const rutVerifier = cleanedRut.slice(-1).toUpperCase();

		const formattedRutNumber = rutNumber.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		let formattedRut = `${formattedRutNumber}-${rutVerifier}`;
		if (formattedRut === '-') formattedRut = '';

		return formattedRut;
	};

	const validateRut = (input: string) => {
		const cleanedRut = input.replace(/[^\dkK]/g, '');

		const rutNumber = cleanedRut.slice(0, -1);
		const rutVerifier = cleanedRut.slice(-1).toUpperCase();

		const calculatedVerifier =
			Array.from(rutNumber)
				.reverse()
				.reduce((acc, digit, index) => {
					const digitValue = parseInt(digit, 10);
					return acc + digitValue * ((index % 6) + 2);
				}, 0) % 11;

		const expectedVerifier =
			calculatedVerifier === 0
				? '0'
				: calculatedVerifier === 1
				? 'K'
				: (11 - calculatedVerifier).toString();

		const isValidRut = expectedVerifier === rutVerifier;
		if (rut.split('.').join('').split('-').join('').length > 6) {
			setIsValid(isValidRut);
		} else if (rut.length > 1) {
			setIsValid(true);
		}

		return isValidRut;
	};

	const handleRutChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputRut = event.target.value;
		const formattedRut = formatRut(inputRut);

		setRut(formattedRut);

		if (formattedRut.length > 0) {
			validateRut(formattedRut);
		}
	};

	return { rut, isValid, handleRutChange };
};

export default useRut;
