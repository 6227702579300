import styled from 'styled-components';

interface ContainerProps {
	readonly toEnd?: boolean;
	readonly $hasError?: boolean;
}

export const Content = styled.div<ContainerProps>`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	display: flex;
	flex-direction: column;
	${({ toEnd, $hasError }) => toEnd && `grid-column: ${$hasError ? '5/5' : '4/4'} `}
`;

export const AmountContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 16px;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;

export const Value = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Section = styled.div`
	display: grid;
	gap: 32px;

	&:not(:first-of-type) {
		padding-top: 16px;
	}
`;

interface SectionProps {
	readonly $hasError?: boolean;
}

export const DetailsContainer = styled.div<SectionProps>`
	background-color: ${({ theme }) => theme.colors.principal.air};
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	margin: 8px 0 15px;
	padding: 16px 20px;
	width: 100%;

	${Section} {
		grid-template-columns: ${({ $hasError }) => (!$hasError ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)')};

		&:nth-of-type(2),
		&:nth-of-type(3) {
			${Content} {
				&:first-of-type {
					grid-column: ${({ $hasError }) => (!$hasError ? '1/4' : '1/5')};
				}
			}
		}
	}
`;

export const ExportModalContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
	width: 100%;
`;

export const ExportModalTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0 0 8px;
`;

export const ExportModalDescription = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin: 0 0 28px;
`;

export const VoucherContainer = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	height: 42px;
	justify-content: center;
	width: 42px;
`;

export const ProductContainer = styled.div`
	margin-left: 15px;
`;
