import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { DateFilter } from '../../components/table/components/date-filter';
import { TableFilterHeader } from '../../components/table/styles';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { AllPaymentsServices } from '../../services/all-payments';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { AllPaymentsHeader } from './components/header';
import { AllPaymentsTable } from './components/table';
import { TitlePage } from './styles';

const AllPayments = () => {
	const { width } = useScreenSize();
	const { merchantSelected, isEnterpriseSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);

	const { data: graphs, isLoading: isLoadingGraph } = useQuery({
		enabled: !!merchantSelected && width > 576,
		useErrorBoundary: true,
		queryKey: [
			CONSTANTS.services.allPayments.getGraphData,
			merchantSelected?.code,
			isEnterpriseSelected,
		],
		queryFn: () =>
			AllPaymentsServices.getGraphicsData(merchantSelected?.code as string, isEnterpriseSelected),
	});

	const {
		data: table,
		isFetching,
		isFetched,
		isLoading: isLoadingTable,
		isRefetching,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.allPayments.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			AllPaymentsServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				enterpriseCode: isEnterpriseSelected,
				pagination,
				filters: {
					...filters,
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.all_payments.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper
			title="Todos los cobros"
			isLoading={isLoadingTable || isLoadingGraph || isRefetching}
		>
			<TitlePage>Todos los cobros</TitlePage>
			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>
			{width > 576 && <AllPaymentsHeader data={graphs} />}

			<AllPaymentsTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				stateList={table?.typeOfStatus || []}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
			/>
		</ViewWrapper>
	);
};

export { AllPayments };
