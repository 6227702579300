import { useRef } from 'react';

import { useClickOutside } from '../../../../hooks/useClickOutside';
import { BurgerContainer } from '../styles';

interface IBurgerProps {
	menuOpen: boolean;
	handleToggle: () => void;
	handleCloseMenu: () => void;
}

export const Burger = ({ menuOpen, handleToggle, handleCloseMenu }: IBurgerProps) => {
	const elementRef = useRef<HTMLDivElement>(null);

	useClickOutside({ elementRef, callback: () => handleCloseMenu() });

	return (
		<BurgerContainer onClick={handleToggle} isOpen={menuOpen} ref={elementRef}>
			<span />
			<span />
			<span />
		</BurgerContainer>
	);
};
