import { ResponseExport } from '../models/common-payments';
import {
	CreateNewPaymentLinkMultiuseParams,
	EditPaymentLinkMultiuse,
	EndPaymentLinkMultiuse,
	ExportDataParams,
	GetAllPaymentMultiuseLinks,
	PaymentLinkMultiuseResponse,
	PaymentLinkMultiuseResponseCode,
	PaymentLinkMultiuseShareLink,
	ResponseTablePaymentLinksMultiuse,
	SendPaymentLinkMultiuseSMS,
	SendPaymentMultiuseLinkBy,
} from '../models/payment-links-multiuse';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getMultiuseTableData = async ({ ...rest }: GetAllPaymentMultiuseLinks) => {
	try {
		const params = tableParamsConstructor(rest);
		const newParams = Object.assign({}, params);
		if (rest.filters?.typeSelected) newParams.type = rest.filters?.typeSelected;
		const { data } = await client.get<ResponseTablePaymentLinksMultiuse>('/v1/pl-multiuse/table', {
			params: newParams,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/pl-multiuse/export', { params }); // TODO: change endpoint
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const newPaymentLinkMultiuse = async ({
	merchantCode,
	reference,
	amount,
	description,
	expirationType,
	numPayments,
	from,
	to,
	paymentType,
}: CreateNewPaymentLinkMultiuseParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			payment_type: paymentType.toUpperCase(),
			expiration_type: expirationType.toUpperCase(),
			order_amount: amount,
			merchant_order_id: reference,
			description: description,
			num_payments: numPayments,
			init_expired_at: from,
			end_expired_at: to,
		};
		const { data } = await client.post<PaymentLinkMultiuseResponse>('/v1/pl-multiuse/new', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const editPaymentLinkMultiuse = async ({
	token,
	reference,
	description,
	merchantCode,
}: EditPaymentLinkMultiuse) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			presession_token: token,
			description: description,
			merchant_order_id: reference,
		};
		const { data } = await client.put<PaymentLinkMultiuseResponseCode>(
			`/v1/pl-multiuse/edit`,
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendPaymentMultiuseLinkBy = async ({
	merchantCode,
	wayToShare,
	paymentLink,
	email,
	phone,
	presessionToken,
}: SendPaymentMultiuseLinkBy) => {
	try {
		const params = {
			merchant_code: merchantCode,
			way_of_share: wayToShare,
		};
		const body = {
			payment_link: paymentLink,
			email,
			phone,
			presession_token: presessionToken,
		};
		const { data } = await client.post<PaymentLinkMultiuseShareLink>(
			'/v1/pl-multiuse/share-link',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendPaymentLinkMultiuseSMS = async ({
	merchantCode,
	phoneNumber,
	presessionToken,
}: SendPaymentLinkMultiuseSMS) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			presession_token: presessionToken,
			phone: phoneNumber,
		};
		const { data } = await client.post<PaymentLinkMultiuseShareLink>(
			'/v1/pl-multiuse/send-sms',
			body,
			{ params },
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const endPaymentLinkMultiuse = async ({
	merchantCode,
	presessionToken,
	comment,
}: EndPaymentLinkMultiuse) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			presession_token: presessionToken,
			comment: comment,
		};
		const { data } = await client.post<PaymentLinkMultiuseResponseCode>(
			'/v1/pl-multiuse/end',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const PaymentLinksMultiuseServices = {
	getMultiuseTableData,
	getExportData,
	newPaymentLinkMultiuse,
	editPaymentLinkMultiuse,
	sendPaymentMultiuseLinkBy,
	sendPaymentLinkMultiuseSMS,
	endPaymentLinkMultiuse,
};
