import React, { SVGProps } from 'react';

export const DeletedIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21 12.7988C21 17.7694 16.9706 21.7988 12 21.7988C7.02944 21.7988 3 17.7694 3 12.7988C3 7.82827 7.02944 3.79883 12 3.79883C16.9706 3.79883 21 7.82827 21 12.7988ZM15.4 9.33087C15.7944 9.71751 15.8007 10.3506 15.414 10.745L13.4005 12.7988L15.4141 14.8527C15.8007 15.2471 15.7945 15.8802 15.4001 16.2669C15.0057 16.6535 14.3726 16.6472 13.9859 16.2529L12.0001 14.2273L10.0142 16.2529C9.62755 16.6472 8.99441 16.6535 8.60004 16.2669C8.20567 15.8802 8.1994 15.2471 8.58604 14.8527L10.5996 12.7988L8.58609 10.745C8.19945 10.3506 8.20572 9.71751 8.60009 9.33087C8.99446 8.94423 9.6276 8.9505 10.0142 9.34487L12.0001 11.3704L13.9859 9.34487C14.3725 8.9505 15.0057 8.94423 15.4 9.33087Z"
				fill="#B7B7B7"
			/>
		</svg>
	);
};
