interface IProps {
	active?: boolean;
}

export const ConciliationIcon = ({ active = true }: IProps) => (
	<svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="85" height="85" rx="12" fill={active ? '#4D2323' : '#B7B7B7'} />
		<path d="M42 11.166V72.8327" stroke="#FCFCFC" strokeWidth="6" />
		<path d="M72.8334 42L11.1667 42" stroke="#FCFCFC" strokeWidth="6" />
		<path d="M63.8024 20.1973L20.1974 63.8022" stroke="#FCFCFC" strokeWidth="6" />
		<path d="M63.8024 63.8027L20.1974 20.1978" stroke="#FCFCFC" strokeWidth="6" />
	</svg>
);
