import { useState } from 'react';

import { DragAndDrop } from '../../../../components/drag-and-drop';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { RefundsServices } from '../../../../services/refund';
import { errorFormatter } from '../../../../utils/errors';
import {
	Button,
	DragAndDropContainer,
	ModalContainer,
	ModalSubtitle,
	ModalTitle,
} from '../modal/styles';
import RefundResume from '../refund-resume';

interface MultiRefundModalProps {
	show: boolean;
	onClose: () => void;
}

interface DefaultState {
	loading: boolean;
	files?: FileList | FileList[];
	buttonDisabled: boolean;
	code: string;
	token: string;
	amount: number;
	requestDate: string;
	totalTransfers: number;
	status: string;
	error: boolean;
	href: string;
	totalErrors?: number;
}

const defaultState: DefaultState = {
	loading: false,
	files: undefined,
	buttonDisabled: true,
	code: '',
	token: '',
	requestDate: '',
	status: '',
	totalTransfers: 0,
	totalErrors: 0,
	amount: 0,
	error: false,
	href: '',
};

const MultiRefundModal = ({ show, onClose }: MultiRefundModalProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [steps, setSteps] = useState(1);
	const [state, setState] = useState(defaultState);
	const [btnValid, setBtnValid] = useState(true);

	const handleCloseModal = () => {
		setState(defaultState);
		setSteps(1);
		onClose();
	};

	const handleContinueAction = async () => {
		setState({ ...state, loading: true });
		try {
			if (!state.files) {
				setState({ ...state, loading: false });
				return;
			}
			const data = await RefundsServices.getExportExcel({
				merchantCode: merchantSelected?.code as string,
				file: state.files?.[0],
			});

			if (data.code === 'S_OK') {
				setSteps(2);
				setState({
					...state,
					totalTransfers: data.resume.totalTransfers,
					amount: data.resume.totalAmount,
					requestDate: data.resume.requestDate,
				});
			}
		} catch (e: any) {
			if (e.code === 'S_OK') {
				await setState({
					...state,
					totalTransfers: e.resume.totalTransfers,
					totalErrors: e.resume.totalErrors,
					amount: e.resume.totalAmount,
					requestDate: e.resume.requestDate,
					files: undefined,
					buttonDisabled: true,
					error: true,
					href: e.url,
				});
				await setSteps(3);
			} else {
				await handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
				setState({ ...state, files: undefined, buttonDisabled: true, error: true, href: e.url });
				await setSteps(1);
			}
		} finally {
			setState((prevState) => ({ ...prevState, loading: false }));
		}
	};

	const handleChange = async (files: FileList | FileList[]) => {
		await setBtnValid(true);
		const hasFiles = files.length > 0;
		await setState({ ...state, files, buttonDisabled: !hasFiles, error: false, href: '' });
	};

	const processData = [
		{
			item: 'Número de pagos de salida',
			data: state.totalTransfers,
		},
		{
			item: 'Monto total',
			data: `$${state.amount}`,
		},
		{
			item: 'Fecha de solicitud',
			data: state.requestDate,
		},
	];

	const processDataError = [
		{
			item: 'Devoluciones exitosas',
			data: state.totalTransfers,
		},
		{
			item: 'Devoluciones con error',
			data: state.totalErrors || 0,
		},
		{
			item: 'Monto total devoluciones con error',
			data: `$${state.amount}`,
		},
	];

	return (
		<Modal title="Devoluciones" show={show} type="medium" onClose={handleCloseModal}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<ModalTitle>
						{steps === 1 && 'Sube la información de los pagos'}
						{steps === 2 && 'En proceso'}
						{steps === 3 && 'Detectamos un problema'}
					</ModalTitle>
					{steps != 1 && (
						<ModalSubtitle align={steps === 1 ? 'start' : 'center'}>
							{steps === 2 &&
								'Los pagos de salida están en proceso de autorización bancaria, consulta su estatus en la sección “Salidas”.'}
							{steps === 3 &&
								'Detectamos algunas devoluciones con errores. Descarga el archivo y corrígelos.'}
						</ModalSubtitle>
					)}

					{steps === 1 && (
						<>
							<DragAndDropContainer>
								<DragAndDrop
									id="upload-batch-payments"
									accept={['.xlsx']}
									maxSize={2100000}
									onChange={handleChange}
									errorFile={state.error}
									downloadErrorFile={state.href}
								/>
							</DragAndDropContainer>
							{!state.buttonDisabled && (
								<Button
									style={{ marginTop: '32px' }}
									variant="send"
									onClick={() => handleContinueAction()}
									disabled={!btnValid}
								>
									Siguiente
								</Button>
							)}
						</>
					)}

					{steps === 2 && <RefundResume transferResume={processData} />}
					{steps === 3 && (
						<>
							<RefundResume transferResume={processDataError} />{' '}
							<Button
								style={{ marginTop: '32px' }}
								variant="send"
								onClick={() => window.open(state.href, '_blank')}
							>
								Descargar .xlsx
							</Button>
						</>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export { MultiRefundModal };
