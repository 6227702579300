import { ETPayButton } from '../button';
import {
	ErrorBoundaryButtonContainer,
	ErrorBoundaryContainer,
	ErrorBoundarySubtitle,
	ErrorBoundaryTitle,
} from './styles';

const ErrorBoundary = () => {
	const handleReloadPage = () => {
		window.location.reload();
	};

	return (
		<ErrorBoundaryContainer>
			<ErrorBoundaryTitle>Información no disponible</ErrorBoundaryTitle>
			<ErrorBoundarySubtitle>
				Si el error continúa, ponte en contacto con soporte.
			</ErrorBoundarySubtitle>

			<ErrorBoundaryButtonContainer>
				<ETPayButton title="Recargar la página" variant="effect" onClick={handleReloadPage} />
			</ErrorBoundaryButtonContainer>
		</ErrorBoundaryContainer>
	);
};

export { ErrorBoundary };
