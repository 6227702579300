import { useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { SubscriptionService } from '../../../../services/subscription';
import { errorFormatter } from '../../../../utils/errors';
import { ModalContainer, ModalFooter, Subtitle, Title } from './styles';

interface ModalManagePlanProps {
	show: boolean;
	handleCloseModal: () => void;
	planId: number;
}

type ViewsAllowed = 'default' | 'success';

const ModalManagePlan = ({ show, handleCloseModal, planId }: ModalManagePlanProps) => {
	const { handleGetEnterpriseSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [view, setView] = useState<ViewsAllowed>('default');
	const [loading, setLoading] = useState(false);

	const handleChangeRole = async () => {
		const enterprise = handleGetEnterpriseSelected();
		setLoading(true);

		try {
			await SubscriptionService.changePlan(enterprise?.id as number, planId);
			setView('success');
		} catch (error: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(error.data),
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal title="Administrar plan" show={show} onClose={handleCloseModal}>
			{view === 'default' ? (
				<ModalContainer>
					<Title>¿Quieres actualizar tu plan?</Title>
					<Subtitle>
						Nuestro equipo de ventas recibirá tu solicitud y se pondrá en contacto contigo a la
						brevedad.
					</Subtitle>
					<ModalFooter>
						<ETPayButton title="Cancelar" onClick={handleCloseModal} />
						<ETPayButton
							title="Si, continuar"
							color="secondary"
							onClick={handleChangeRole}
							disabled={loading}
						/>
					</ModalFooter>
				</ModalContainer>
			) : (
				<ModalContainer>
					<Title>Recibimos tu solicitud</Title>
					<Subtitle>
						Tu solicitud está siendo procesada, nuestro equipo de ventas se pondrá en contacto
						contigo a la brevedad.
					</Subtitle>
				</ModalContainer>
			)}
		</Modal>
	);
};

export { ModalManagePlan };
