import { ResumeCard } from '../../../../components/resume-card';
import { Head, ResumeCardType } from '../../../../models/home';

interface ResumeSectionProps {
	data?: Head;
}

const ResumeSection = ({ data }: ResumeSectionProps) => {
	return (
		<>
			<ResumeCard
				type={ResumeCardType.AMOUNT}
				value={data?.amountCollected.value}
				icon={data?.amountCollected.icon}
				resume={data?.amountCollected.resume}
				prefix="$"
			/>
			<ResumeCard
				type={ResumeCardType.CONVERSION}
				value={data?.convertion.value}
				icon={data?.convertion.icon}
				resume={data?.convertion.resume}
				suffix="%"
			/>
			<ResumeCard
				type={ResumeCardType.SUCCESSFUL}
				value={data?.success.value}
				icon={data?.success.icon}
				resume={data?.success.resume}
			/>
			{data?.successToReview && (
				<ResumeCard
					type={ResumeCardType.PENDING}
					value={data?.successToReview.value}
					icon={data?.successToReview.icon}
					resume={data?.successToReview.resume}
				/>
			)}
		</>
	);
};

export { ResumeSection };
