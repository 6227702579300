import { add } from 'date-fns';
import {
	ChangeEvent,
	Dispatch,
	FormEvent,
	KeyboardEvent,
	SetStateAction,
	useRef,
	useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';

import { CalendarIcon, LeftAngleIcon, RightAngleIcon } from '../../../../../assets/svg';
import { Select } from '../../../../../components/form/select';
import { Input } from '../../../../../components/input';
import {
	ChangeMonthContainer,
	CurrentMonthYear,
	HeaderMonthYearSelected,
	ShowDatePickerContainer,
} from '../../../../../components/table/components/date-filter/styles';
import { Textarea } from '../../../../../components/textarea';
import { useClickOutside } from '../../../../../hooks/useClickOutside';
import { PaymentMultiuse } from '../../../../../models/payment-links-multiuse';
import { getMonthAndYearFormat, validateDateValue } from '../../../../../utils/dates';
import { onlyNumberRegexWithDot } from '../../../../../utils/validations';
import { ExpirationTypesAllowed, RangeDate, RangeTime, TypesAllowed, ViewsAllowed } from '..';
import {
	ContainerFilterTime,
	HintInput,
	HintWithAction,
	InputContainer,
	PaymentTypeSection,
	TitleSection,
} from '../styles';
import { PaymentType } from './payment-type';

export interface Values {
	amount: string;
	successfulPayments: string;
	reference: string;
	description: string;
	buttonDisabled: boolean;
	loading: boolean;
}

export const defaultValues: Values = {
	amount: '',
	successfulPayments: '',
	reference: '',
	description: '',
	buttonDisabled: true,
	loading: false,
};

interface CreatePaymentLinkProps {
	state: Values;
	range: RangeDate;
	showConfig: boolean;
	typeSelected: TypesAllowed;
	rangeTime: RangeTime;
	expiration: ExpirationTypesAllowed;
	viewType: ViewsAllowed;
	selectedToEdit?: PaymentMultiuse;
	setState: Dispatch<SetStateAction<Values>>;
	setRange: Dispatch<SetStateAction<RangeDate>>;
	setShowConfig: Dispatch<SetStateAction<boolean>>;
	setTypeSelected: Dispatch<SetStateAction<TypesAllowed>>;
	setRangeTime: Dispatch<SetStateAction<RangeTime>>;
	setExpiration: Dispatch<SetStateAction<ExpirationTypesAllowed>>;
	setWriteInput: (value: boolean) => void;
}

const PaymentTypeOptions = [
	{ label: 'Por fecha', value: 'date', show: true },
	{ label: 'Por número de pagos exitosos', value: 'num_payments', show: true },
];

const CreatePaymentLink = ({
	state,
	range,
	showConfig,
	typeSelected,
	rangeTime,
	expiration,
	selectedToEdit,
	setState,
	setRange,
	setShowConfig,
	setTypeSelected,
	viewType,
	setRangeTime,
	setExpiration,
	setWriteInput,
}: CreatePaymentLinkProps) => {
	const fromElementRef = useRef<HTMLDivElement>(null);
	const toElementRef = useRef<HTMLDivElement>(null);
	const [showCalendar, setShowCalendar] = useState({
		from: false,
		to: false,
	});

	useClickOutside({
		elementRef: fromElementRef,
		callback: () =>
			setShowCalendar({
				...showCalendar,
				from: false,
			}),
	});

	useClickOutside({
		elementRef: toElementRef,
		callback: () =>
			setShowCalendar({
				...showCalendar,
				to: false,
			}),
	});

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegexWithDot.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const regex = /^[0-9]*[.]?([0-9]{1,2})?$/;

		if (name === 'amount' && !regex.test(value)) {
			return;
		}
		if (viewType === 'edit-payment-link') {
			setWriteInput(true);
		}
		setState({ ...state, [name]: value });
	};

	const handleChangeTextarea = (e: FormEvent<HTMLTextAreaElement>) => {
		const { name, value } = e.target as HTMLTextAreaElement;
		setState({ ...state, [name]: value });
		if (viewType === 'edit-payment-link') {
			setWriteInput(true);
		}
	};

	const handleSelectType = (type: 'unique' | 'recurrent') => {
		setTypeSelected(type);
	};

	const validateBlurHandler = (e: ChangeEvent<HTMLInputElement>, property: 'from' | 'to') => {
		const { value } = e.target;
		const time = validateDateValue(value);
		setRangeTime({ ...rangeTime, [property]: time });
	};

	const onChangeTimeInput = (e: ChangeEvent<HTMLInputElement>, property: 'from' | 'to') => {
		const { value } = e.target;
		setRangeTime({ ...rangeTime, [property]: value });
	};

	const onChangeDate = (date: Date, property: 'from' | 'to') => {
		if (property === 'from') {
			setRange({
				from: date as Date,
				to: add(date as Date, { days: 1 }),
			});
		} else {
			setRange({
				...range,
				to: date as Date,
			});
		}
		setShowCalendar({ ...showCalendar, [property]: false });
	};

	const handleShowConfiguration = () => {
		setShowConfig(!showConfig);
	};

	return (
		<>
			<Input
				label="Monto"
				name="amount"
				variant="large"
				value={selectedToEdit ? selectedToEdit?.amount.split('$')[1] : state.amount}
				prefixSymbol="$"
				placeholder="0.00"
				disabled={!!selectedToEdit}
				onChange={handleChangeInput}
				onKeyDown={handleKeyDownValidateAmount}
			/>

			<div>
				<Input
					label="Referencia"
					name="reference"
					value={state.reference}
					placeholder="Ej. nombre del cliente, referencia del cobro"
					onChange={handleChangeInput}
					variant="large"
				/>
				<HintInput>Tus clientes verán este nombre durante el proceso de pago</HintInput>
			</div>

			<Textarea
				name="description"
				value={state.description || ''}
				onChange={handleChangeTextarea}
				placeholder="Descripción (opcional)"
				rows={4}
			/>

			<div>
				<TitleSection>Selecciona el tipo de cobro</TitleSection>

				<PaymentTypeSection>
					<PaymentType
						type="unique"
						title="Cobro único"
						subtitle="Para recibir un solo pago"
						isSelected={
							(viewType === 'edit-payment-link' &&
								selectedToEdit?.paymenttype.toLowerCase() === 'unique') ||
							(viewType === 'create-payment' && typeSelected === 'unique')
						}
						isDisabled={!!selectedToEdit}
						onClick={() => {
							handleSelectType('unique');
							setShowConfig(false);
						}}
					/>
					<PaymentType
						isDisabled={!!selectedToEdit}
						type="recurrent"
						title="Cobro recurrente"
						subtitle="Para recibir varios pagos de uno o más clientes"
						isSelected={
							typeSelected === 'recurrent' ||
							selectedToEdit?.paymenttype.toLowerCase() === 'recurrent'
						}
						onClick={() => {
							handleSelectType('recurrent');
							setShowConfig(false);
						}}
					/>
				</PaymentTypeSection>
			</div>

			<div>
				{(typeSelected === 'unique' && viewType === 'create-payment') ||
				(typeSelected === 'unique' && viewType === 'edit-payment-link') ? (
					<Input
						label="Tipo de expiración"
						name="reference"
						value={
							selectedToEdit && selectedToEdit?.endexpiredatdate !== null
								? 'Por fecha'
								: viewType === 'create-payment'
								? 'Por fecha'
								: 'Por número de pagos exitosos'
						}
						variant="large"
						disabled
					/>
				) : null}

				{typeSelected === 'recurrent' && (
					<>
						<Select
							titleLabel='Tipo de expiración'
							size="large"
							options={PaymentTypeOptions}
							onChange={(e) => setExpiration(e.value as ExpirationTypesAllowed)}
						/>
					</>
				)}

				{((typeSelected === 'recurrent' && expiration === 'date') ||
					(typeSelected === 'unique' &&
						viewType === 'edit-payment-link' &&
						selectedToEdit?.initexpiredatdate !== null) ||
					(typeSelected === 'unique' && viewType === 'create-payment')) && (
					<HintWithAction showingConfig={showConfig}>
						{!showConfig ? (
							<>
								El enlace expirará por defecto a las 24 horas.
								<span onClick={handleShowConfiguration}>Configurar</span>
							</>
						) : (
							'Si no estableces una fecha, expirará por defecto a las 24 horas'
						)}
					</HintWithAction>
				)}

				{(viewType === 'edit-payment-link' &&
					selectedToEdit?.payments &&
					selectedToEdit?.numpayments &&
					selectedToEdit?.paymenttype.toLowerCase() === 'recurrent') ||
				(typeSelected === 'recurrent' && expiration === 'num_payments') ? (
					<div style={{ marginTop: 16 }}>
						<Input
							label="Número de pagos exitosos"
							name="successfulPayments"
							value={state.successfulPayments || selectedToEdit?.numpayments || ''}
							onChange={handleChangeInput}
							onKeyDown={handleKeyDownValidateAmount}
							variant="large"
							disabled={
								viewType === 'edit-payment-link' &&
								selectedToEdit?.payments &&
								!!selectedToEdit?.numpayments
							}
						/>
					</div>
				) : null}
			</div>

			{showConfig &&
				((typeSelected === 'unique' && viewType === 'create-payment') ||
					(viewType === 'edit-payment-link' && selectedToEdit?.endexpiredatdate) ||
					(typeSelected === 'recurrent' && expiration === 'date')) && (
					<>
						<ContainerFilterTime ref={fromElementRef}>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
								<InputContainer>
									<Input
										label="Fecha de inicio"
										value={
											selectedToEdit
												? selectedToEdit.initexpiredatdate
												: range.from.toLocaleDateString()
										}
										variant="large"
										rightIcon={<CalendarIcon />}
										disabled={!!selectedToEdit}
										rightIconClick={() => {
											!selectedToEdit &&
												setShowCalendar({ ...showCalendar, from: !showCalendar.from });
										}}
										onClick={() => {
											setShowCalendar({ ...showCalendar, from: !showCalendar.from });
										}}
										onChange={() => setShowCalendar({ ...showCalendar, from: !showCalendar.from })}
									/>

									{showCalendar.from && (
										<ShowDatePickerContainer y="top" x="left">
											<ReactDatePicker
												inline
												locale="es"
												selected={range.from}
												minDate={new Date()}
												onChange={(date) => onChangeDate(date as Date, 'from')}
												renderCustomHeader={(params) => (
													<>
														<HeaderMonthYearSelected>
															<ChangeMonthContainer
																onClick={() => {
																	if (!params.prevMonthButtonDisabled) params.decreaseMonth();
																}}
																allowClick={!params.prevMonthButtonDisabled}
															>
																<LeftAngleIcon />
															</ChangeMonthContainer>

															<CurrentMonthYear>
																{getMonthAndYearFormat(params.date)}
															</CurrentMonthYear>

															<ChangeMonthContainer
																onClick={() => {
																	if (!params.nextMonthButtonDisabled) params.increaseMonth();
																}}
																allowClick={!params.nextMonthButtonDisabled}
															>
																<RightAngleIcon />
															</ChangeMonthContainer>
														</HeaderMonthYearSelected>
													</>
												)}
											/>
										</ShowDatePickerContainer>
									)}
								</InputContainer>
								<Input
									label="Hora de inicio"
									value={selectedToEdit ? selectedToEdit.initexpiredattime : rangeTime.from}
									variant="large"
									rightIcon={<CalendarIcon />}
									disabled={!!selectedToEdit}
									onClick={() => {
										setShowCalendar({ ...showCalendar, from: false });
									}}
									onChange={(e) => onChangeTimeInput(e, 'from')}
									onBlur={(e) => validateBlurHandler(e, 'from')}
								/>
							</div>
						</ContainerFilterTime>

						<ContainerFilterTime ref={toElementRef}>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
								<InputContainer>
									<Input
										label="Fecha de vencimiento"
										value={
											selectedToEdit
												? selectedToEdit.endexpiredatdate
												: range.to.toLocaleDateString()
										}
										variant="large"
										disabled={!!selectedToEdit}
										rightIcon={<CalendarIcon />}
										rightIconClick={() => {
											!selectedToEdit && setShowCalendar({ ...showCalendar, to: !showCalendar.to });
										}}
										onClick={() => {
											setShowCalendar({ ...showCalendar, to: !showCalendar.to });
										}}
										onChange={() => setShowCalendar({ ...showCalendar, to: !showCalendar.to })}
									/>

									{showCalendar.to && (
										<ShowDatePickerContainer y="top" x="left">
											<ReactDatePicker
												inline
												locale="es"
												selected={range.to}
												minDate={add(range.from, { days: 1 })}
												onChange={(date) => onChangeDate(date as Date, 'to')}
												renderCustomHeader={(params) => (
													<>
														<HeaderMonthYearSelected>
															<ChangeMonthContainer
																onClick={() => {
																	if (!params.prevMonthButtonDisabled) params.decreaseMonth();
																}}
																allowClick={!params.prevMonthButtonDisabled}
															>
																<LeftAngleIcon />
															</ChangeMonthContainer>

															<CurrentMonthYear>
																{getMonthAndYearFormat(params.date)}
															</CurrentMonthYear>

															<ChangeMonthContainer
																onClick={() => {
																	if (!params.nextMonthButtonDisabled) params.increaseMonth();
																}}
																allowClick={!params.nextMonthButtonDisabled}
															>
																<RightAngleIcon />
															</ChangeMonthContainer>
														</HeaderMonthYearSelected>
													</>
												)}
											/>
										</ShowDatePickerContainer>
									)}
								</InputContainer>

								<Input
									label="Hora de vencimiento"
									value={selectedToEdit ? selectedToEdit.endexpiredattime : rangeTime.to}
									variant="large"
									rightIcon={<CalendarIcon />}
									disabled={!!selectedToEdit}
									onClick={() => {
										setShowCalendar({ ...showCalendar, to: false });
									}}
									onChange={(e) => onChangeTimeInput(e, 'to')}
									onBlur={(e) => validateBlurHandler(e, 'to')}
								/>
							</div>
						</ContainerFilterTime>
					</>
				)}
		</>
	);
};

export { CreatePaymentLink };
