import { ProfileResponse, UpdateProfile } from '../models/profile';
import { client } from '../utils/api';

const getProfileInformation = async (merchantCode: string) => {
	const params = { merchant_code: merchantCode };

	try {
		const { data } = await client.get<ProfileResponse>('/v1/profile', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const updateInfo = async ({ merchantCode, profile }: UpdateProfile) => {
	const params = { merchant_code: merchantCode };

	try {
		const { data } = await client.put<ProfileResponse>('/v1/profile', profile, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const ProfileServices = {
	getProfileInformation,
	updateInfo,
};
