import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SearchIcon } from '../../../../assets/svg';
import { Input } from '../../../../components/input';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useMerchant } from '../../../../hooks/useMerchant';
import {
	ArrowIcon,
	EnterpriseName,
	FilterContainer,
	Logo,
	LogoContainer,
	MerchantItem,
	MerchantListContainer,
	MerchantListContent,
	MerchantName,
	MerchantTitleContainer,
	ShowAll,
	TitleContainer,
} from './styles';

interface MerchantListProps {
	isOpen: boolean;
}

const MerchantList = ({ isOpen }: MerchantListProps) => {
	const {
		merchants,
		enterprises,
		merchantSelected,
		handleSelectActiveMerchant,
		isEnterpriseSelected,
		handleSelectViewAll,
	} = useMerchant();
	const [closeList, setCloseList] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState('');
	const elementRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useClickOutside({
		elementRef,
		callback: () => {
			setCloseList(false);
			setSearchTerm('');
		},
	});

	useEffect(() => {
		if (!isEnterpriseSelected && merchants && !merchantSelected) {
			handleSelectActiveMerchant(merchants[0]);
		}
	}, [merchants, isEnterpriseSelected]);

	const handleToggleMenu = () => {
		setCloseList(!closeList);
	};

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value.toLowerCase());
	};

	return (
		<MerchantListContainer isOpen={isOpen} ref={elementRef}>
			{!merchants ? (
				'Cargando merchants...'
			) : (
				<TitleContainer isOpen={closeList}>
					<MerchantTitleContainer onClick={handleToggleMenu}>
						{!isEnterpriseSelected && merchantSelected?.merchant_logo ? (
							<LogoContainer>
								<Logo src={merchantSelected.merchant_logo} alt={merchantSelected?.name} />
							</LogoContainer>
						) : (
							<MerchantName>
								{isEnterpriseSelected
									? enterprises?.find((ele) => ele.id === isEnterpriseSelected)?.name
									: merchantSelected?.name}
							</MerchantName>
						)}
						<ArrowIcon />
					</MerchantTitleContainer>
				</TitleContainer>
			)}

			<MerchantListContent isOpen={closeList}>
				{enterprises &&
					(enterprises.length > 2 ||
						enterprises.some((enterprise) => enterprise.merchants.length > 5)) && (
						<FilterContainer>
							<Input leftIcon={<SearchIcon />} placeholder="Buscar" onChange={handleSearchChange} />
						</FilterContainer>
					)}

				{enterprises &&
					enterprises
						.filter((enterprise) =>
							enterprise.merchants.find((merchant) =>
								merchant.name.toLocaleLowerCase().includes(searchTerm),
							),
						)
						.map((enterprise) => (
							<ul key={enterprise.name}>
								{enterprises.length > 1 && <EnterpriseName>{enterprise.name}</EnterpriseName>}

								{enterprise.merchants.map((merchant) => (
									<MerchantItem
										key={merchant.name}
										isSelected={!isEnterpriseSelected && merchantSelected?.code === merchant.code}
										onClick={() => {
											if (!isEnterpriseSelected && merchantSelected?.code === merchant.code) return;
											handleSelectActiveMerchant(merchant);
											setCloseList(false);
											navigate('/');
										}}
									>
										{merchant.name}
									</MerchantItem>
								))}

								{enterprise.merchants.length > 1 && (
									<ShowAll
										isSelected={isEnterpriseSelected === enterprise.id}
										onClick={() => {
											if (pathname === '/enterprise') return;
											handleSelectViewAll(enterprise.id);
											setCloseList(false);
											navigate('/');
										}}
									>
										Ver todos
									</ShowAll>
								)}
							</ul>
						))}
			</MerchantListContent>
		</MerchantListContainer>
	);
};

export { MerchantList };
