import { AuthLoginResponse, AuthUser, getCountryCodeById, SignUpParams } from '../models/auth';
import { client } from '../utils/api';

const signUp = async ({
	name,
	lastName,
	email,
	phone,
	companyName,
	countrySelected,
	paymentSelection,
	planSelected,
}: SignUpParams) => {
	try {
		const body = {
			names: name,
			lastnames: lastName,
			email,
			phone,
			enterprise_name: companyName,
			country: getCountryCodeById(countrySelected),
			num_payments: paymentSelection,
			plan_id: planSelected,
		};
		const { data } = await client.post<AuthLoginResponse>('/v1/contact', body);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const login = async (userData: AuthUser) => {
	try {
		const { data } = await client.post<AuthLoginResponse>('/v1/auth/login', userData);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const verifyEmail = async (email: string) => {
	try {
		const { data } = await client.post('/v1/auth/verification-email', { email });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

interface VerifyCodeParams {
	email: string;
	code: string;
}

const verifyCode = async ({ email, code }: VerifyCodeParams) => {
	try {
		const body = { email, verification_code: code };
		const { data } = await client.post('/v1/auth/verify-code', body);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

interface SendNewPasswordParams {
	email: string;
	code: string;
	password: string;
	confirmPassword: string;
}

const sendNewPassword = async ({
	email,
	code,
	password,
	confirmPassword,
}: SendNewPasswordParams) => {
	const body = {
		verification_code: code,
		email,
		password,
		confirmPassword: confirmPassword,
	};

	try {
		const { data } = await client.post('/v1/auth/forgot-password', body);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const logout = async () => {
	try {
		await client.post('/v1/auth/logout');
	} catch (error: any) {
		throw error.response.data;
	}
};

export const AuthServices = {
	login,
	signUp,
	verifyEmail,
	verifyCode,
	sendNewPassword,
	logout,
};
