import { useRef, useState } from 'react';

import { DotsIcon } from '../../../../assets/svg/DotsIcon';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { PaymentMultiuse } from '../../../../models/payment-links-multiuse';
import { DropdownContainer, DropdownItem, DropdownList } from './styles';

interface Options {
	label: string;
	onClick: (rowData: PaymentMultiuse) => void;
	colorCustom?: string;
}

interface DropdownProps {
	options: Options[];
	disabled?: boolean;
	rowData: PaymentMultiuse;
}

const Dropdown = ({ options, disabled, rowData }: DropdownProps) => {
	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
	const modal = useRef<HTMLDivElement>(null);

	const toggleDropdown = () => {
		setIsDropdownVisible(!isDropdownVisible);
	};

	const handleItemClick = (onClick: (rowData: PaymentMultiuse) => void) => {
		onClick(rowData);
		setIsDropdownVisible(false);
	};

	useClickOutside({ elementRef: modal, callback: () => setIsDropdownVisible(false) });

	return (
		<>
			{disabled && <DotsIcon onClick={toggleDropdown} />}
			{isDropdownVisible && (
				<DropdownContainer ref={modal}>
					<DropdownList>
						{options?.map(({ label, onClick, colorCustom }) => (
							<DropdownItem
								key={label}
								onClick={() => handleItemClick(onClick)}
								colorCustom={colorCustom}
							>
								{label}
							</DropdownItem>
						))}
					</DropdownList>
				</DropdownContainer>
			)}
		</>
	);
};

export { Dropdown };
