import { useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { ModalContainer, ModalFooter, Subtitle, Title } from './styles';

export interface ConfirmationModalProps {
	show: boolean;
	onCloseModal: () => void;
	type: 'logotipo' | 'color';
	action: (arg: boolean) => void;
	loading?: boolean;
}

const ConfirmationModal = ({
	show,
	onCloseModal,
	type,
	action,
	loading,
}: ConfirmationModalProps) => {
	const [withCheckbox, setWithCheckbox] = useState(false);

	const handleCallAction = () => {
		action(withCheckbox);
	};

	const handleCloseModal = () => {
		setWithCheckbox(false);
		onCloseModal();
	};

	return (
		<Modal
			title={type === 'logotipo' ? 'Actualizar logotipo' : 'Actualizar color'}
			show={show}
			onClose={handleCloseModal}
		>
			{loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<Title>¿Estás seguro?</Title>
					<Subtitle>
						{type === 'logotipo'
							? 'Esta acción cambiará el logotipo de la pasarela de pago y tus comunicaciones.'
							: 'Esta acción cambiará el color de la pasarela de pago.'}
					</Subtitle>

					<Subtitle>
						{type === 'logotipo'
							? 'El cambio puede tardar en verse reflejado.'
							: 'El cambio se verá reflejado en los cobros generados a partir de este momento.'}
					</Subtitle>

					<ModalFooter>
						<ETPayButton color="primary" title="Cancelar" onClick={handleCloseModal} />
						<ETPayButton color="secondary" title="Si, actualizar" onClick={handleCallAction} />
					</ModalFooter>
				</ModalContainer>
			)}
		</Modal>
	);
};

export { ConfirmationModal };
