import es from 'date-fns/esm/locale/es';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { defaultFloatingAlertValues, FloatingAlertProvider } from './context/floating-alert';
import { useAuth } from './hooks/useAuth';
import { ProcessType } from './models/sockets';
import { routesConfig } from './routes/routes';
import { WebSockets } from './services/sockets';
import { tracker } from './utils/open-replay';

const router = createBrowserRouter(routesConfig);
setDefaultOptions({ locale: es });
registerLocale('es', es);

const App = () => {
	const { user, handleLogout } = useAuth();
	const { pathname } = window.location;

	useEffect(() => {
		if (user?.email) tracker.setUserID(user.email);
		tracker.start();
	}, [pathname]);

	WebSockets.subscribe(({ action }) => {
		if (action === ProcessType.DELETE_USER) {
			handleLogout();
		}
	});

	return (
		<FloatingAlertProvider {...defaultFloatingAlertValues}>
			<RouterProvider router={router} />
		</FloatingAlertProvider>
	);
};

export default App;
