import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 224px;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;

export const AmountContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 16px;
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
`;

export const VoucherButton = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	height: 42px;
	justify-content: center;
	width: 42px;
`;

export const Value = styled.p`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;

export const Section = styled.div`
	display: grid;
	gap: 32px;

	&:not(:first-of-type) {
		${Value} {
			&::first-letter {
				text-transform: uppercase;
			}
		}
	}

	&:nth-of-type(2) {
		padding-top: 16px;
	}
`;

export const DetailsContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.air};
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	margin: 8px 0 15px;
	padding: 16px 20px;
	width: 100%;

	${Section} {
		grid-template-columns: repeat(7, 1fr);
	}
`;

export const IconContainer = styled.div`
	svg {
		height: 24px;
		width: 24px;
	}
`;

export const ExportModalContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
	width: 100%;
`;

export const ExportModalTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0 0 8px;
`;

export const ExportModalDescription = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin: 0 0 28px;
`;
