import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { DirectDebitIcon } from '../../assets/svg/DirectDebitIcon';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { Filters } from '../../models/table';
import { DirectDebitMxServices } from '../../services/direct-debit-mx';
import { getRoleLabelById, validateRoleId } from '../../utils/common';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { ModalDomiciliation } from './components/modal';
import { MultiDomiciliationModal } from './components/multi-modal';
import { DirectDebitMxTable } from './components/table';
import { ButtonHead, ContainerButton, HeadContainer, TableFilterHeader, TitlePage } from './styles';

const customDefaultFilters: Filters = {
	...defaultFilters,
	typeSelected: '',
};

const DirectDebitMx = () => {
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(customDefaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);
	const [show, setShow] = useState<boolean>(false);
	const [showMulti, setShowMulti] = useState<boolean>(false);
	const today = new Date();
	const pastDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
	const {
		data: table,
		isFetching,
		isFetched,
		isLoading,
		refetch,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.directDebitMx.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			DirectDebitMxServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					typeSelected: filters.typeSelected,
					dateRange: {
						from: filters?.dateRange?.from || pastDate,
						to: filters?.dateRange?.to || today,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(customDefaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.direct_debit_mx.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	const roleIsAllowed =
		merchantSelected &&
		(validateRoleId(getRoleLabelById(merchantSelected?.role_id, merchantSelected?.role_id))
			?.label === 'Propietario' ||
			validateRoleId(getRoleLabelById(merchantSelected?.role_id, merchantSelected?.role_id))
				?.label === 'ETpay');

	const onClose = () => {
		setShow(false);
		setShowMulti(false);
		refetch();
	};

	return (
		<ViewWrapper title="Suscripciones" isLoading={isFetching || isLoading}>
			<>
				<HeadContainer>
					<TitlePage>
						<DirectDebitIcon></DirectDebitIcon> Suscripciones
					</TitlePage>

					<ContainerButton>
						{roleIsAllowed && (
							<>
								<ButtonHead onClick={() => setShowMulti(true)} variant="pure">
									Suscripciones masivas
								</ButtonHead>
								<ButtonHead onClick={() => setShow(true)} variant="earth">
									Nueva suscripción
								</ButtonHead>
							</>
						)}
					</ContainerButton>
				</HeadContainer>

				{show && <ModalDomiciliation setShowFinalizedModal={onClose} show={true} />}
				{showMulti && <MultiDomiciliationModal onClose={onClose} show={true} />}
				<TableFilterHeader>
					<DateFilter
						filters={filters}
						onFilterChange={setFilters}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</TableFilterHeader>
				<DirectDebitMxTable
					data={table?.tableInfo || []}
					isLoading={isFetching && !isFetched}
					stateList={table?.statusInfo || []}
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
					totalResults={total}
					refetch={refetch}
					today={today}
					pastDate={pastDate}
				/>
			</>
		</ViewWrapper>
	);
};

export { DirectDebitMx };
