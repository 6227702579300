import styled from 'styled-components';

export const QRContainer = styled.div`
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 32px;
`;

export const QRTitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0;
	text-align: center;
`;

export const QRSubtitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 12px;
	line-height: 12px;
	margin: 0;
	text-align: center;
	width: 70%;
`;

export const QRCodeContainer = styled.div`
	background-color: #fffbf2;
	border-radius: 12px;
	margin: 16px 0;
	padding: 23px;

	img {
		height: 114px;
		width: 114px;
	}
`;

export const ScanQRText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
`;
