import Cookies from 'js-cookie';

import { CONSTANTS } from '../config/constants';
import { decode } from './common';

export const getHeaders = () => {
	return {
		headers: {
			Authorization: decode(Cookies.get(CONSTANTS.cookies.authorization) || ''),
			accessToken: decode(Cookies.get(CONSTANTS.cookies.accessToken) || ''),
			refreshToken: decode(Cookies.get(CONSTANTS.cookies.refreshToken) || ''),
		},
	};
};
