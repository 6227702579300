import { ReactElement } from 'react';

import { ErrorAlertIcon, InfoIcon, SuccessAlertIcon, WarningAlertIcon } from '../../assets/svg';
import { CrossIcon } from '../../assets/svg/CrossIcon';
import { AlertContainer, AlertMessage, AlertWrapper, CallToAction } from './styles';

export type AlertTypeAllowed = 'error' | 'success' | 'warning' | 'default';

export interface AlertProps {
	show: boolean;
	message: string | ReactElement;
	type?: AlertTypeAllowed;
	hasIcon?: boolean;
	action?: () => void;
	actionText?: string;
	close?: () => void;
}

const Alert = ({
	show,
	message,
	type = 'default',
	hasIcon = false,
	actionText,
	action = undefined,
	close,
}: AlertProps) => {
	const Icons = new Map([
		['default', <InfoIcon key="info-icon" />],
		['error', <ErrorAlertIcon key="error-icon" />],
		['success', <SuccessAlertIcon key="success-icon" />],
		['warning', <WarningAlertIcon key="waning-icon" />],
	]);

	return (
		<AlertContainer show={show} type={type}>
			<AlertWrapper>
				{hasIcon && Icons.get(type)}
				{typeof message === 'string' ? <AlertMessage>{message}</AlertMessage> : message}
			</AlertWrapper>

			{action && (
				<CallToAction $type={type} onClick={action}>
					{actionText}
				</CallToAction>
			)}

			{close && (
				<div onClick={close}>
					<CrossIcon />
				</div>
			)}
		</AlertContainer>
	);
};

export { Alert };
