import { SVGProps } from 'react';

export const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.7038 4.58333H5.29638C4.39636 4.58333 3.66675 5.31294 3.66675 6.21296V17.6204C3.66675 18.5204 4.39636 19.25 5.29638 19.25H16.7038C17.6038 19.25 18.3334 18.5204 18.3334 17.6204V6.21296C18.3334 5.31294 17.6038 4.58333 16.7038 4.58333Z"
			stroke={props.color || '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.6667 2.75V6.41667"
			stroke={props.color || '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.33325 2.75V6.41667"
			stroke={props.color || '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.66675 9.16667H18.3334"
			stroke={props.color || '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
