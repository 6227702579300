interface RadioButtonEmptyProps {
	width: number;
	height: number;
}
export const RadioButtonEmpty = ({ width, height }: RadioButtonEmptyProps) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<circle cx="12" cy="12" r="8.25" stroke="#140F33" strokeWidth="1.5" />
		</g>
	</svg>
);
