import { Fragment, useEffect, useRef, useState } from 'react';

import { DetailsArrowIcon } from '../../../../assets/svg';
import { ETPayTableBodyInterface } from '../../../../models/table';
import { Checkbox } from '../../../form/checkbox';
import {
	ExpandableRow,
	ExpandButton,
	ExpandedDataContainer,
	TableBody,
	TableData,
	TableRow,
} from './styles';

const ETPayTableBody = <Entity,>({
	hasCheckbox,
	columns,
	data,
	row,
	checkboxSelected,
	handleCheckboxClicked,
}: ETPayTableBodyInterface<Entity>) => {
	const [expanded, setExpanded] = useState<number>(-1);
	const [selectExpanded, setSelectExpanded] = useState<number>(0);
	const wrapperRef = useRef<HTMLTableSectionElement>(null);

	const handleExpand = (index: number) => {
		expanded === index ? setExpanded(-1) : setExpanded(index);
	};

	const handleClickOutside = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (wrapperRef.current && !wrapperRef.current.contains(target)) {
			setExpanded(-1);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<TableBody ref={wrapperRef}>
			{data.map((item, i) => (
				<Fragment key={i}>
					<TableRow
						rowChecked={checkboxSelected.includes(`checkbox-${i}`)}
						selectExpanded={selectExpanded}
						expanded={expanded}
					>
						{columns.map(
							(column, j) =>
								column &&
								!column.hide && (
									<Fragment key={column.title}>
										{j === 0 && hasCheckbox && (
											<TableData hasCheckbox={hasCheckbox}>
												<Checkbox
													name={`checkbox-${i}`}
													onChange={handleCheckboxClicked}
													checked={checkboxSelected.includes(`checkbox-${i}`)}
												/>
											</TableData>
										)}
										<TableData alignContent={column.align}>
											{j === 0 && row && row(item) !== undefined ? (
												<ExpandedDataContainer>
													<ExpandButton
														onClick={() => {
															handleExpand(i);
															setSelectExpanded(i + 1);
														}}
														isExpanded={expanded === i}
													>
														<DetailsArrowIcon />
													</ExpandButton>
													{!column.hide && column.cellRender && column.cellRender(item)}
												</ExpandedDataContainer>
											) : (
												!column.hide && column.cellRender && column.cellRender(item)
											)}
										</TableData>
									</Fragment>
								),
						)}
					</TableRow>
					{row && expanded === i && (
						<ExpandableRow>
							<TableData colSpan={columns.length}>{row(item)}</TableData>
						</ExpandableRow>
					)}
				</Fragment>
			))}
		</TableBody>
	);
};

export { ETPayTableBody };
