import styled from 'styled-components';

import { ArrowIcon } from '../../../../assets/svg';

export const ContainerFilterTime = styled.div`
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		white-space: nowrap;
		width: 100%;
	}
`;

export const FilterTimeLabel = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.eris};
	border-right: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: default;
	font-size: 14px;
	line-height: 21px;
	padding: 8px 12px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-align: center;
		width: 100%;
	}
`;

export const FilterTimeInput = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	cursor: pointer;
	display: flex;
	font-size: 14px;
	gap: 6px;
	line-height: 21px;
	padding: 8px 12px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: center;
		width: 100%;
	}
`;

export const FilterArrowIcon = styled(ArrowIcon)`
	height: 12px;
	width: 12px;
`;

interface ShowDatePickerContainerProps {
	y: 'top' | 'bottom';
	x: 'left' | 'right';
}

export const ShowDatePickerContainer = styled.div<ShowDatePickerContainerProps>`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid #e6e6e6;
	border-radius: 6px;
	bottom: ${({ y }) => (y === 'top' ? '50px' : 'inherit')};
	left: ${({ x }) => (x === 'left' ? '0' : 'inherit')};
	padding: 24px 16px;
	position: absolute;
	right: ${({ x }) => (x === 'right' ? '0' : 'inherit')};
	top: ${({ y }) => (y === 'bottom' ? '50px' : 'inherit')};
	z-index: 20;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	.react-datepicker {
		border: 0;
		border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
		padding-bottom: 20px;
		width: 100%;
	}

	.react-datepicker__header {
		background-color: transparent;
		border-bottom: 0;
		padding-top: 0;
	}

	.react-datepicker__month-container {
		display: flex;
		flex-direction: column;
		float: initial;
		font-size: 14px;
		width: 100%;

		.react-datepicker__month {
			@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
				width: 100%;
			}
		}
	}

	.react-datepicker__day-name {
		align-items: center;
		aspect-ratio: 1/1;
		color: ${({ theme }) => theme.colors.principal.orion};
		font-size: 14px;
		font-weight: 500;
		line-height: 21px;
		margin: 0;
		text-align: center;
		width: 40px;

		&::first-letter {
			text-transform: uppercase;
		}

		@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100%;
		}
	}

	.react-datepicker__day--outside-month,
	.react-datepicker__day--disabled {
		color: ${({ theme }) => theme.colors.principal.moon} !important;
	}

	.react-datepicker__month {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
		margin-top: 15px;
		width: min-content;
	}

	.react-datepicker__week,
	.react-datepicker__day-names {
		display: grid;
		grid-template-columns: repeat(7, 1fr);

		@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100%;
		}
	}

	.react-datepicker__day {
		align-items: center;
		aspect-ratio: 1/1;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.principal.vanth};
		display: flex;
		font-size: 14px;
		font-weight: 400;
		justify-content: center;
		line-height: 21px;
		margin: 0;
		position: relative;
		text-align: center;
		transition: background-color 0.2s ease-in-out;
		width: 40px;

		&:not(.react-datepicker__day--disabled):not(.react-datepicker__day--today):hover {
			background-color: ${({ theme }) => theme.colors.principal.ceres};
		}

		@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100%;
		}
	}

	.react-datepicker__day--keyboard-selected {
		background-color: ${({ theme }) => theme.colors.principal.pure};
		border: 1px dashed ${({ theme }) => theme.colors.principal.ceres};
	}

	.react-datepicker__day--today {
		background-color: ${({ theme }) => theme.colors.principal.pure};
		border: 1px solid ${({ theme }) => theme.colors.principal.orion};
		border-radius: 24px;
	}

	.react-datepicker__day--in-range,
	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range {
		border-radius: 0;

		&:not(.react-datepicker__day--disabled) {
			background-color: rgba(20 15 51 / 10%) !important;
			border: 1px solid transparent !important;
			color: ${({ theme }) => theme.colors.principal.orion} !important;
		}
	}

	.react-datepicker__day--range-start,
	.react-datepicker__day--selecting-range-start {
		border-radius: 50%;

		&:not(.react-datepicker__day--range-end):not(.react-datepicker__day--selecting-range-end) {
			border-radius: 24px 0 0 24px !important;
		}
	}

	.react-datepicker__day--range-end,
	.react-datepicker__day--selecting-range-end {
		&:not(.react-datepicker__day--range-start):not(.react-datepicker__day--selecting-range-start) {
			border-radius: 50%;

			&::before {
				aspect-ratio: 1/1;
				background-color: rgba(20 15 51 / 10%);
				border: 0;
				border-radius: 0 24px 24px 0;
				content: '';
				height: 105%;
				position: absolute;
				width: inherit;
			}
		}
	}

	.react-datepicker__day--range-end,
	.react-datepicker__day--selecting-range-end {
		&:not(.react-datepicker__day--in-selecting-range) {
			background-color: ${({ theme }) => theme.colors.principal.orion} !important;
			color: ${({ theme }) => theme.colors.principal.pure} !important;
		}
	}

	.react-datepicker__day--selected {
		&:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
			background-color: ${({ theme }) => theme.colors.principal.orion} !important;
			border-radius: 50%;
			color: ${({ theme }) => theme.colors.principal.pure} !important;
		}
	}
`;

export const DatePickerHeader = styled.div`
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 16px;
	font-weight: 400;
	gap: 12px;
	justify-content: space-evenly;
	line-height: 18px;
	margin-bottom: 25px;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
`;

export const ContainerShow = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 6px;
	display: flex;
`;

export const ShowDatePickerButton = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 20px;

	button {
		width: 132px;
	}
`;

export const CancelarFilterDate = styled.button`
	background-color: transparent;
	border: 0;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	justify-content: center;
	padding: 8px, 12px;
	transition: background-color 0.2s ease-in-out;
	width: 132px;

	&:hover {
		background-color: ${({ theme }) => theme.colors.principal.ceres};
	}
`;

export const AplicarFilterDate = styled.button`
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border: 0;
	border-radius: 8px;
	color: white;
	cursor: pointer;
	height: 37px;
	outline: 0;
	padding: 0 12px;
	transition: background-color 0.2s ease-in-out;
	width: 132px;

	&:hover {
		background-color: ${({ theme }) => theme.colors.extra.corvus};
	}
`;

export const ErrorMessage = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.eris};
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	line-height: 12px;
	margin-bottom: 15px;
	mix-blend-mode: multiply;
	padding: 12px 16px;
`;

export const HeaderMonthYearSelected = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

export const CurrentMonthYear = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;

	&::first-letter {
		text-transform: uppercase;
	}
`;

interface ChangeMonthContainerProps {
	readonly allowClick: boolean;
}

export const ChangeMonthContainer = styled.div<ChangeMonthContainerProps>`
	cursor: ${({ allowClick }) => (allowClick ? 'pointer' : 'default')};
	display: flex;
	padding: 4px;

	svg {
		path {
			stroke: ${({ theme, allowClick }) => theme.colors.principal[allowClick ? 'orion' : 'moon']};
		}
	}
`;
