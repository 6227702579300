import { ResponseExport, ResponseGraphics, ResponseTable } from '../models/common-payments';
import { ExportDataParams, GetQuickTransfer } from '../models/quick-transfer';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getGraphicsData = async (merchantCode: string) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseGraphics>('/v1/bt/graphics', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getAllPayments = async ({ ...rest }: GetQuickTransfer) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTable>('/v1/bt/table', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/bt/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const BusinessTransferServices = {
	getGraphicsData,
	getAllPayments,
	getExportData,
};
