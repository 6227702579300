interface IconProps {
	active?: boolean;
	color?: string;
}

export const DDIcon = ({ active, color }: IconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 19 21" fill="none">
		<mask
			id="path-1-outside-1_11897_44038"
			maskUnits="userSpaceOnUse"
			x="0.205078"
			y="5.32568"
			width="15"
			height="15"
			fill="black"
		>
			<rect fill="white" x="0.205078" y="5.32568" width="15" height="15" />
			<path d="M7.74823 7.23939C6.65189 7.23939 5.58018 7.56449 4.66862 8.17358C3.75705 8.78267 3.04657 9.64839 2.62702 10.6613C2.20748 11.6741 2.0977 12.7887 2.31159 13.864C2.52547 14.9392 3.05341 15.9269 3.82863 16.7021C4.60385 17.4774 5.59155 18.0053 6.66681 18.2192C7.74208 18.4331 8.85662 18.3233 9.8695 17.9037C10.8824 17.4842 11.7481 16.7737 12.3572 15.8621C12.9663 14.9506 13.2914 13.8789 13.2914 12.7825L7.74823 12.7825L7.74823 7.23939Z" />
		</mask>
		<path
			d="M7.74823 7.23939C6.65189 7.23939 5.58018 7.56449 4.66862 8.17358C3.75705 8.78267 3.04657 9.64839 2.62702 10.6613C2.20748 11.6741 2.0977 12.7887 2.31159 13.864C2.52547 14.9392 3.05341 15.9269 3.82863 16.7021C4.60385 17.4774 5.59155 18.0053 6.66681 18.2192C7.74208 18.4331 8.85662 18.3233 9.8695 17.9037C10.8824 17.4842 11.7481 16.7737 12.3572 15.8621C12.9663 14.9506 13.2914 13.8789 13.2914 12.7825L7.74823 12.7825L7.74823 7.23939Z"
			fill={active ? color : '#FFFF'}
		/>
		<path
			d="M7.74823 7.23939C6.65189 7.23939 5.58018 7.56449 4.66862 8.17358C3.75705 8.78267 3.04657 9.64839 2.62702 10.6613C2.20748 11.6741 2.0977 12.7887 2.31159 13.864C2.52547 14.9392 3.05341 15.9269 3.82863 16.7021C4.60385 17.4774 5.59155 18.0053 6.66681 18.2192C7.74208 18.4331 8.85662 18.3233 9.8695 17.9037C10.8824 17.4842 11.7481 16.7737 12.3572 15.8621C12.9663 14.9506 13.2914 13.8789 13.2914 12.7825L7.74823 12.7825L7.74823 7.23939Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="2.23687"
			mask="url(#path-1-outside-1_11897_44038)"
		/>
		<path
			d="M17.8639 9.50563H18.4231V8.94642C18.4231 8.04932 18.2464 7.161 17.9031 6.33219C17.5598 5.50337 17.0566 4.75029 16.4223 4.11595C15.7879 3.4816 15.0348 2.97841 14.206 2.6351L13.992 3.15175L14.206 2.6351C13.3772 2.2918 12.4889 2.1151 11.5918 2.1151H11.0326V2.67432V8.94642V9.50563H11.5918H17.8639Z"
			fill={active ? color : '#FFFF'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.11843"
		/>
	</svg>
);
