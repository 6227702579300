import React from 'react';

export const Logo = () => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.7787 6.43626e-06C10.055 -0.00180187 8.35192 0.377451 6.78996 1.11095C5.228 1.84444 3.84527 2.91427 2.73955 4.24477C1.63383 5.57527 0.832129 7.13395 0.391133 8.8106C-0.049863 10.4872 -0.119382 12.2409 0.187493 13.9476H23.3698C23.4921 13.2569 23.5541 12.5569 23.5553 11.8554C23.5558 10.2989 23.2516 8.75752 22.66 7.3193C22.0684 5.88109 21.2011 4.57421 20.1075 3.47331C19.0139 2.37241 17.7155 1.49904 16.2864 0.903088C14.8574 0.307134 13.3256 0.000264353 11.7787 6.43626e-06ZM4.3714 9.82222C4.81444 8.18738 5.77949 6.74449 7.11784 5.71589C8.45619 4.68728 10.0936 4.13005 11.7777 4.13005C13.4618 4.13005 15.0992 4.68728 16.4375 5.71589C17.7759 6.74449 18.7409 8.18738 19.184 9.82222H4.3714Z"
			fill="#72DBAA"
		/>
		<path
			d="M13.8834 19.2792L13.819 19.2949C13.6862 19.3323 13.5535 19.3657 13.4168 19.3951L13.3543 19.4089C13.1982 19.4423 13.042 19.4698 12.8819 19.4933L12.7686 19.5071C12.6476 19.5248 12.5246 19.5366 12.3996 19.5464L12.2591 19.5582C12.1048 19.568 11.9506 19.5739 11.7944 19.5739H11.7768C11.6109 19.5739 11.4469 19.5739 11.2829 19.5562H11.1872C11.033 19.5444 10.8807 19.5267 10.7304 19.5071H10.703C8.90826 19.2518 7.26134 18.3652 6.05447 17.0044L2.99512 19.7546C4.10027 20.9992 5.45412 21.9949 6.96809 22.6767C8.48207 23.3585 10.1221 23.7109 11.7807 23.711H11.8784C12.0521 23.711 12.2239 23.711 12.3977 23.6973C12.4504 23.6973 12.5012 23.6973 12.5539 23.6855C12.7081 23.6756 12.8623 23.6639 13.0166 23.6481L13.1298 23.6344C13.2977 23.6147 13.4656 23.5931 13.6316 23.5656L13.7077 23.5519C13.8873 23.5224 14.065 23.491 14.2407 23.4517L14.2973 23.4399C14.4789 23.3987 14.6585 23.3554 14.8381 23.3083L14.8948 23.2906C15.0705 23.2435 15.2462 23.1904 15.418 23.1335L15.4941 23.1079C15.6581 23.0529 15.8221 22.9959 15.9822 22.9331L16.0896 22.8918C16.236 22.8329 16.3825 22.772 16.5289 22.7072L16.6714 22.6424C16.8003 22.5834 16.9272 22.5225 17.0521 22.4577L17.2473 22.3634L17.5636 22.1905L17.794 22.0569L18.042 21.9037C18.1337 21.8448 18.2274 21.7859 18.3172 21.725C18.3758 21.6876 18.4324 21.6464 18.4891 21.6071C18.601 21.5311 18.7103 21.4526 18.817 21.3714L18.8912 21.3144C19.4968 20.8526 20.0571 20.3336 20.5644 19.7644L17.507 17.0142C16.5353 18.0975 15.2792 18.8827 13.8834 19.2792Z"
			fill="#72DBAA"
		/>
	</svg>
);
