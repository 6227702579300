import styled from 'styled-components';

interface GraphicsTooltipProps {
	readonly tooltipColor: string;
}

export const GraphicsTooltipContainer = styled.div<GraphicsTooltipProps>`
	align-items: center;
	background-color: ${({ tooltipColor }) => tooltipColor};
	border-radius: 8px;
	display: flex;
	min-width: 30px;
	padding: 8px 12px;
	width: fit-content;
`;

export const TooltipLabel = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
`;
