import axios, { AxiosError, AxiosResponse } from 'axios';

import { config } from '../config';
import { AuthActions } from '../store/auth/actions';
import { MerchantActions } from '../store/merchants/actions';
import { store } from '../store/store';
import { getHeaders } from './get-headers';

const client = axios.create({
	baseURL: `${config.base_url}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

client.defaults.headers.common['Content-Type'] = 'application/json';
client.defaults.headers.common.Accept = 'application/json';

client.interceptors.request.use(
	(request) => {
		const { headers } = getHeaders();
		const currentTime = new Date().getTime();
		const timeToExpire = store.getState().auth.timeToExpire;

		if (headers.Authorization) {
			request.headers.Authorization = headers.Authorization;
			request.headers.accessToken = headers.accessToken;
			request.headers.refreshToken = headers.refreshToken;
		}

		if (timeToExpire && currentTime >= timeToExpire) {
			store.dispatch({
				type: AuthActions.RESET_STATE,
				payload: {},
			});
			store.dispatch({ type: MerchantActions.RESET_STATE, payload: {} });
		}

		return request;
	},
	(error: AxiosError) => Promise.reject(error),
);

client.interceptors.response.use(
	(response: AxiosResponse) => response,
	async (error) => Promise.reject(error),
);

export { client };
