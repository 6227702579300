import { ExportDataParams } from '../models/all-payments';
import { CommonTableParams, ResponseExport } from '../models/common-payments';
import {
	GenerateOutbound,
	GenerateOutboundSantander,
	ResponseCashAllowed,
	ResponseGenerateOutbound,
	ResponseOtp,
	ResponseSantanderB,
	ResponseTableOutbound,
	SendBatchOutbound,
	SendCodeOutbound,
} from '../models/outbound';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getAllOutbound = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableOutbound>('/v1/payouts/outbound/table', {
			params,
			headers: {
				'file-extension': 'text/csv',
				'content-type': 'multipart/form-data',
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const generateOutbound = async ({
	merchantCode,
	clabe,
	beneficiary,
	amount,
	email,
	concept,
	reference,
}: GenerateOutbound) => {
	try {
		const params = { merchant_code: merchantCode };

		const outbound_transfers = [
			{
				clabe,
				beneficiary,
				amount,
				email,
				concept,
				reference,
			},
		];

		const body = {
			outbound_transfers,
		};

		const { data } = await client.post<ResponseOtp>('/v1/payouts/otp/outbound', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const generateOutboundSantander = async ({
	merchantCode,
	clabe,
	currency,
	beneficiary,
	amount,
	trackingKey,
	concept,
}: GenerateOutboundSantander) => {
	try {
		const params = { merchant_code: merchantCode };

		const outbound_transfers = [
			{
				clabe,
				currency,
				beneficiary,
				amount,
				tracking_key: trackingKey,
				concept,
			},
		];

		const body = {
			outbound_transfers,
		};

		const { data } = await client.post<ResponseSantanderB>(
			'/v1/payouts/generate/operational',
			body,
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendCodeOutbound = async ({ merchantCode, code, token }: SendCodeOutbound) => {
	try {
		const params = { merchant_code: merchantCode };

		const body = {
			otp: code,
			payoutsToken: token,
		};

		const { data } = await client.post<ResponseGenerateOutbound>(
			'/v1/payouts/generate/outbound',
			body,
			{
				params,
				headers: { 'X-Pyouts-Token': token },
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/payouts/outbound/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendBatchOutbound = async ({ merchantCode, file }: SendBatchOutbound) => {
	try {
		const params = { merchant_code: merchantCode };
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/payouts/resume/csv/outbound', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'File-Extension': ext,
			},
		});

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getCodeOutbound = async ({ merchantCode }: Omit<SendCodeOutbound, 'code' | 'token'>) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseOtp>('/v1/payouts/otp/csv/outbound', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getCashAllowed = async (merchantCode: string, to?: string, from?: string) => {
	try {
		const params = { merchant_code: merchantCode, to, from };
		const { data } = await client.get<ResponseCashAllowed>('/v1/payouts/balance', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const OutboundService = {
	getAllOutbound,
	generateOutbound,
	generateOutboundSantander,
	getExportData,
	getCashAllowed,
	getCodeOutbound,
	sendCodeOutbound,
	sendBatchOutbound,
};
