interface IconProps {
	active?: boolean;
	color?: string;
}

export const EATMIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.7967 11.9047V12.61L15.5006 12.6533C15.5921 12.6589 15.6842 12.6618 15.7769 12.6618C18.2283 12.6618 20.2155 10.6746 20.2155 8.22323C20.2155 5.77188 18.2283 3.78467 15.7769 3.78467C13.3256 3.78467 11.3384 5.77188 11.3384 8.22323C11.3384 8.31598 11.3412 8.40816 11.3469 8.49968L11.3902 9.20356H12.0955H14.7967V11.9047Z"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
		<path
			d="M12.6588 15.5012L12.6159 14.7967H11.9102H9.20344V12.0899V11.3842L8.49898 11.3413C8.40854 11.3358 8.31745 11.333 8.2258 11.333C5.77296 11.333 3.78455 13.3214 3.78455 15.7743C3.78455 18.2271 5.77296 20.2155 8.2258 20.2155C10.6786 20.2155 12.6671 18.2271 12.6671 15.7743C12.6671 15.6826 12.6643 15.5916 12.6588 15.5012Z"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
	</svg>
);
