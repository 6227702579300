import { CountriesAllowed } from '../models/merchant';

interface FormatNumberOptions {
	value: number;
	country: CountriesAllowed;
	options?: Intl.NumberFormatOptions;
}

export const formatNumber = ({ value, country, options }: FormatNumberOptions) => {
	const countryFormat = new Map<CountriesAllowed, string>([
		['MX', 'es-MX'],
		['CL', 'es-CL'],
	]);

	return new Intl.NumberFormat(countryFormat.get(country), options).format(value);
};

export const removeDecimalAndSign = (amount: string): string =>
	String(Math.floor(+amount.replace('$', '')));

export const cleanNumber = (numberString: string): string => {
	const numberWithoutCommas = numberString.replace(/,/g, '');
	return numberWithoutCommas;
};