import { CurrentPlanResponse, Plan } from '../models/plans';
import { client } from '../utils/api';

const getCurrentPlan = async (merchantCode: string) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<CurrentPlanResponse>('/v1/settings/planes/my-plan', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getPlans = async (countryCode: string, enterpriseId: number) => {
	try {
		const params = { country: countryCode, enterprise_id: enterpriseId };
		const { data } = await client.get<Plan[]>('/v1/settings/planes', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const changePlan = async (enterpriseId: number, planId: number) => {
	try {
		const params = { enterprise_id: enterpriseId };
		const body = { new_plan_id: planId };
		const { data } = await client.post('/v1/settings/planes/change', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const SubscriptionService = {
	getCurrentPlan,
	changePlan,
	getPlans,
};
