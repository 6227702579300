import styled, { DefaultTheme } from 'styled-components';

import { translateType } from '.';

export const Card = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	padding: 16px;
	width: 100%;

	&:not(:last-child) {
		margin-right: 32px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
		width: calc(50% - 40px);

		&:not(:last-child) {
			margin-right: 0;
		}
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const CardTitle = styled.p`
	font-size: 18px;
	margin: 0;
`;

interface CardBodyProps {
	readonly withoutValue: boolean;
}

export const CardBody = styled.div<CardBodyProps>`
	color: ${({ withoutValue, theme }) => withoutValue && theme.colors.principal.ceres};
	font-size: 34px;
	font-weight: 500;
	line-height: 1;
	margin-bottom: ${({ withoutValue }) => (withoutValue ? '0' : '10px')};
	width: max-content;

	@media (max-width: ${({ theme }) => theme.breakpoints.xg}) {
		font-size: 30px;
	}
`;

export const getColor = (type: translateType, theme: DefaultTheme) => {
	switch (type) {
		case 'less':
			return theme.colors.extra.sword;
		case 'more':
			return theme.colors.extra.success;
		case 'equal':
			return theme.colors.principal.orion;
		default:
			return theme.colors.principal.orion;
	}
};

export const CardFooterInformation = styled.div`
	display: flex;
	font-size: 14px;
	line-height: 1;
`;

export const TextContainer = styled.div`
	margin-left: 2px;
	margin-right: 4px;
`;

interface TextProps {
	readonly type: translateType;
}

export const Text = styled.p<TextProps>`
	color: ${({ type, theme }) => getColor(type, theme)};
	font-size: 14px;
	line-height: 21px;
`;

export const Description = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 21px;
`;
