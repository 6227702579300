import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { PayoutsIcon } from '../../assets/svg/PayoutsIcon';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { Filters } from '../../models/table';
import { OutboundService } from '../../services/outbound';
import { RefundsServices } from '../../services/refund';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { AmountAllowed, AmountSubTitle } from '../eatm/styles';
import { MultiRefundModal } from './components/multi-refund-modal';
import { RefundsTable } from './components/table';
import {
	ContainerButtons,
	GenerateReturnsButton,
	HeadContainer,
	TableFilterHeader,
	TitlePage,
} from './styles';

const statusFilters: Filters = {
	dateRange: {
		from: null,
		to: null,
	},
	search: '',
};

export interface AlertData {
	concept: string;
	amount: string;
	reference: string;
}

const today = new Date();
const pastDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

const ConsultRefund = () => {
	const { merchantSelected } = useMerchant();
	const flowBanksOrStp = true;
	const [cashAllowed, setCashAllowed] = useState('');
	const [cashDate, setCashDate] = useState('');
	const [filters, setFilters] = useState(statusFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState<number>(0);
	const [showMultiRefund, setShowMultiRefund] = useState<boolean>(false);

	const {
		data: table,
		isFetching,
		isFetched,
		isLoading: isLoadingTable,
		refetch,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.refund.payments.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			RefundsServices.getAllPayments({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from || pastDate,
						to: filters?.dateRange?.to || today,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	const { isLoading: isLoadingCash } = useQuery({
		enabled: !!merchantSelected,
		queryKey: [
			CONSTANTS.services.refund.getCashAllowed,
			merchantSelected?.code,
			merchantSelected?.provider,
		],
		queryFn: () => OutboundService.getCashAllowed(merchantSelected?.code as string),
		onSuccess: (response) => {
			if (response) {
				setCashAllowed(response.balanceDetail.balance);
				setCashDate(response.balanceDetail.updatedAtFormat);
			}
		},
	});

	const onClose = () => {
		setShowMultiRefund(false);
		refetch();
	};

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.quick_transfer.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper
			title="Consultar devoluciones"
			isLoading={isLoadingTable || isLoadingCash || isFetching}
		>
			<HeadContainer>
				<TitlePage>
					<PayoutsIcon />
					{flowBanksOrStp ? 'Consultar devoluciones' : 'Devoluciones'}
				</TitlePage>
				<ContainerButtons>
					{' '}
					{merchantSelected?.role_id === 1 ||
						(merchantSelected?.role_id === 2 && (
							<AmountAllowed>
								<>Saldo disponible: {isLoadingCash ? 'cargando...' : `${cashAllowed}`}</>
								<AmountSubTitle>Actualizado el {cashDate}</AmountSubTitle>
							</AmountAllowed>
						))}
					{!flowBanksOrStp && (
						<GenerateReturnsButton onClick={() => setShowMultiRefund(true)}>
							Generar devoluciones
						</GenerateReturnsButton>
					)}
				</ContainerButtons>
			</HeadContainer>

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			{showMultiRefund && <MultiRefundModal onClose={onClose} show={showMultiRefund} />}

			<RefundsTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				today={today}
				pastDate={pastDate}
				stateList={table?.typeOfStatus || []}
			/>
		</ViewWrapper>
	);
};

export { ConsultRefund };
