export const SuccessAlertIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 4L20.6603 19H3.33975L12 4Z"
			stroke="#72DBAA"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path d="M12 16.5L12 15.5" stroke="#72DBAA" strokeWidth="1.5" />
		<path d="M12 14.5L12 9.5" stroke="#72DBAA" strokeWidth="1.5" />
	</svg>
);
