import React from 'react';
import { SVGProps } from 'react';

export const ETPayIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M12.0838 2.00001C10.6101 1.99848 9.15398 2.31838 7.81851 2.93708C6.48304 3.55578 5.30081 4.45817 4.35542 5.58045C3.41003 6.70272 2.72458 8.01746 2.34753 9.4317C1.97048 10.8459 1.91104 12.3251 2.17342 13.7647H21.9943C22.0988 13.1822 22.1519 12.5917 22.1528 12C22.1533 10.6871 21.8932 9.38693 21.3874 8.1738C20.8816 6.96067 20.14 5.85833 19.205 4.92972C18.27 4.00112 17.1598 3.26444 15.938 2.76175C14.7161 2.25907 13.4065 2.00022 12.0838 2.00001V2.00001ZM5.75065 10.285C6.12945 8.90602 6.95457 7.68895 8.09885 6.82133C9.24314 5.9537 10.6431 5.48368 12.083 5.48368C13.5229 5.48368 14.9229 5.9537 16.0672 6.82133C17.2114 7.68895 18.0366 8.90602 18.4154 10.285H5.75065Z"
			fill="#E6E6E6"
		/>
		<path
			d="M13.8832 18.2618L13.8281 18.2751C13.7146 18.3065 13.6011 18.3347 13.4843 18.3596L13.4308 18.3712C13.2973 18.3993 13.1638 18.4225 13.0269 18.4424L12.9301 18.454C12.8266 18.4689 12.7214 18.4789 12.6146 18.4871L12.4944 18.4971C12.3625 18.5054 12.2306 18.5103 12.0971 18.5103H12.0821C11.9402 18.5103 11.8 18.5103 11.6598 18.4954H11.578C11.4461 18.4855 11.3159 18.4706 11.1874 18.454H11.164C9.62945 18.2387 8.22135 17.4908 7.18947 16.343L4.57373 18.6628C5.51863 19.7126 6.67617 20.5525 7.97062 21.1275C9.26506 21.7026 10.6672 21.9999 12.0854 22H12.1689C12.3174 22 12.4643 22 12.6129 21.9884C12.658 21.9884 12.7014 21.9884 12.7465 21.9784C12.8783 21.9702 13.0102 21.9602 13.1421 21.947L13.2389 21.9354C13.3824 21.9188 13.526 21.9006 13.6679 21.8774L13.733 21.8658C13.8866 21.8409 14.0385 21.8144 14.1887 21.7813L14.2371 21.7713C14.3923 21.7365 14.5459 21.7001 14.6995 21.6603L14.7479 21.6454C14.8981 21.6056 15.0484 21.5609 15.1953 21.5128L15.2604 21.4913C15.4006 21.4449 15.5408 21.3968 15.6777 21.3438L15.7695 21.309C15.8947 21.2593 16.0199 21.2079 16.1451 21.1533L16.2669 21.0986C16.3771 21.0489 16.4856 20.9975 16.5924 20.9428L16.7594 20.8633L17.0298 20.7175L17.2268 20.6048L17.4388 20.4755C17.5172 20.4258 17.5973 20.3761 17.6741 20.3248C17.7242 20.2933 17.7726 20.2585 17.821 20.2253C17.9167 20.1613 18.0102 20.095 18.1015 20.0265L18.1649 19.9784C18.6827 19.589 19.1617 19.1512 19.5954 18.6711L16.9814 16.3513C16.1505 17.265 15.0766 17.9273 13.8832 18.2618V18.2618Z"
			fill="#E6E6E6"
		/>
	</svg>
);
