import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { GraphicsTooltipContainer, TooltipLabel } from './styles';

interface GraphicsTooltipProps extends TooltipProps<ValueType, NameType> {
	tooltipColor: string;
	valueToShow: string;
}

const GraphicsTooltip = ({ active, payload, tooltipColor, valueToShow }: GraphicsTooltipProps) => {
	return active && payload && payload.length ? (
		<GraphicsTooltipContainer tooltipColor={tooltipColor}>
			<TooltipLabel>{payload[0].payload?.[valueToShow] || '$0'}</TooltipLabel>
		</GraphicsTooltipContainer>
	) : null;
};

export { GraphicsTooltip };
