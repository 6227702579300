interface IconProps {
	active?: boolean;
	color?: string;
}

export const BTIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="path-1-outside-1_10998_4175"
			maskUnits="userSpaceOnUse"
			x="2.77722"
			y="2.64502"
			width="8"
			height="12"
			fill="black"
		>
			<rect fill="white" x="2.77722" y="2.64502" width="8" height="12" />
			<path d="M8.69956 4.64949C7.65929 4.64949 6.66163 5.07068 5.92605 5.82041C5.19047 6.57014 4.77722 7.58698 4.77722 8.64726C4.77722 9.70753 5.19047 10.7244 5.92605 11.4741C6.66163 12.2238 7.65929 12.645 8.69956 12.645L8.69956 8.64726L8.69956 4.64949Z" />
		</mask>
		<path
			d="M8.69956 4.64949C7.65929 4.64949 6.66163 5.07068 5.92605 5.82041C5.19047 6.57014 4.77722 7.58698 4.77722 8.64726C4.77722 9.70753 5.19047 10.7244 5.92605 11.4741C6.66163 12.2238 7.65929 12.645 8.69956 12.645L8.69956 8.64726L8.69956 4.64949Z"
			fill={active ? color : 'white'}
		/>
		<path
			d="M8.69956 4.64949C7.65929 4.64949 6.66163 5.07068 5.92605 5.82041C5.19047 6.57014 4.77722 7.58698 4.77722 8.64726C4.77722 9.70753 5.19047 10.7244 5.92605 11.4741C6.66163 12.2238 7.65929 12.645 8.69956 12.645L8.69956 8.64726L8.69956 4.64949Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="2.29897"
			mask="url(#path-1-outside-1_10998_4175)"
		/>
		<mask
			id="path-2-outside-2_10998_4175"
			maskUnits="userSpaceOnUse"
			x="13.198"
			y="10.355"
			width="8"
			height="12"
			fill="black"
		>
			<rect fill="white" x="13.198" y="10.355" width="8" height="12" />
			<path d="M15.2757 20.3505C16.3159 20.3505 17.3136 19.9293 18.0492 19.1796C18.7848 18.4299 19.198 17.413 19.198 16.3527C19.198 15.2925 18.7848 14.2756 18.0492 13.5259C17.3136 12.7762 16.3159 12.355 15.2757 12.355L15.2757 16.3527L15.2757 20.3505Z" />
		</mask>
		<path
			d="M15.2757 20.3505C16.3159 20.3505 17.3136 19.9293 18.0492 19.1796C18.7848 18.4299 19.198 17.413 19.198 16.3527C19.198 15.2925 18.7848 14.2756 18.0492 13.5259C17.3136 12.7762 16.3159 12.355 15.2757 12.355L15.2757 16.3527L15.2757 20.3505Z"
			fill={active ? color : 'white'}
		/>
		<path
			d="M15.2757 20.3505C16.3159 20.3505 17.3136 19.9293 18.0492 19.1796C18.7848 18.4299 19.198 17.413 19.198 16.3527C19.198 15.2925 18.7848 14.2756 18.0492 13.5259C17.3136 12.7762 16.3159 12.355 15.2757 12.355L15.2757 16.3527L15.2757 20.3505Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="2.29897"
			mask="url(#path-2-outside-2_10998_4175)"
		/>
		<mask
			id="path-3-outside-3_10998_4175"
			maskUnits="userSpaceOnUse"
			x="10.0045"
			y="3.15283"
			width="12"
			height="8"
			fill="black"
		>
			<rect fill="white" x="10.0045" y="3.15283" width="12" height="8" />
			<path d="M20 9.07517C20 8.0349 19.5789 7.03724 18.8291 6.30166C18.0794 5.56608 17.0626 5.15283 16.0023 5.15283C14.942 5.15283 13.9252 5.56608 13.1754 6.30166C12.4257 7.03724 12.0045 8.0349 12.0045 9.07517L16.0023 9.07517L20 9.07517Z" />
		</mask>
		<path
			d="M20 9.07517C20 8.0349 19.5789 7.03724 18.8291 6.30166C18.0794 5.56608 17.0626 5.15283 16.0023 5.15283C14.942 5.15283 13.9252 5.56608 13.1754 6.30166C12.4257 7.03724 12.0045 8.0349 12.0045 9.07517L16.0023 9.07517L20 9.07517Z"
			fill={active ? color : 'white'}
		/>
		<path
			d="M20 9.07517C20 8.0349 19.5789 7.03724 18.8291 6.30166C18.0794 5.56608 17.0626 5.15283 16.0023 5.15283C14.942 5.15283 13.9252 5.56608 13.1754 6.30166C12.4257 7.03724 12.0045 8.0349 12.0045 9.07517L16.0023 9.07517L20 9.07517Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="2.29897"
			mask="url(#path-3-outside-3_10998_4175)"
		/>
		<mask
			id="path-4-outside-4_10998_4175"
			maskUnits="userSpaceOnUse"
			x="1.99548"
			y="13.8726"
			width="12"
			height="8"
			fill="black"
		>
			<rect fill="white" x="1.99548" y="13.8726" width="12" height="8" />
			<path d="M3.99996 15.9502C3.99996 16.9905 4.42115 17.9882 5.17087 18.7237C5.9206 19.4593 6.93745 19.8726 7.99772 19.8726C9.05799 19.8726 10.0748 19.4593 10.8246 18.7237C11.5743 17.9882 11.9955 16.9905 11.9955 15.9502L7.99772 15.9502L3.99996 15.9502Z" />
		</mask>
		<path
			d="M3.99996 15.9502C3.99996 16.9905 4.42115 17.9882 5.17087 18.7237C5.9206 19.4593 6.93745 19.8726 7.99772 19.8726C9.05799 19.8726 10.0748 19.4593 10.8246 18.7237C11.5743 17.9882 11.9955 16.9905 11.9955 15.9502L7.99772 15.9502L3.99996 15.9502Z"
			fill={active ? color : 'white'}
		/>
		<path
			d="M3.99996 15.9502C3.99996 16.9905 4.42115 17.9882 5.17087 18.7237C5.9206 19.4593 6.93745 19.8726 7.99772 19.8726C9.05799 19.8726 10.0748 19.4593 10.8246 18.7237C11.5743 17.9882 11.9955 16.9905 11.9955 15.9502L7.99772 15.9502L3.99996 15.9502Z"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="2.29897"
			mask="url(#path-4-outside-4_10998_4175)"
		/>
	</svg>
);
