import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
`;

export const ContainerWrapper = styled.div`
	display: flex;
	gap: 16px;
	height: 100%;
	width: 100%;
	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 144px;
	}
`;

export const Item = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-left: 14px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
	}

	&::after {
		border-radius: 8px;
		bottom: 0;
		content: '';
		height: 8px;
		left: 0;
		margin: auto;
		position: absolute;
		top: 0;
		width: 8px;
	}
`;

interface ItemProps {
	readonly bg?: string;
}

export const List = styled.div<ItemProps>`
	width: 100%;

	${Item} {
		&::after {
			background-color: ${({ bg }) => bg};
		}
	}
`;

export const Value = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
`;

export const ListLoading = styled.div<ItemProps>`
	width: 100%;
`;

const loadingAnimation = keyframes`
  0% {
	background-color: #C5CED2
  }

  100% {
	background-color: #F0F2F3
  }
`;

export const ItemLoading = styled.p`
	align-items: center;
	background-color: white;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-left: 14px;
	position: relative;
`;

export const NameLoading = styled.span`
	animation: ${loadingAnimation} 2s linear infinite alternate;
	background-color: #eee;
	border-radius: 4px;
	height: 14px;
	width: 60%;
`;

export const ValueLoading = styled.span`
	animation: ${loadingAnimation} 2s linear infinite alternate;
	background-color: #eee;
	border-radius: 4px;
	height: 14px;
	min-width: 10%;
`;

export const EmptyMessageWrapper = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
`;

export const EmptyMessage = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
`;
