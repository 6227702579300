import { PlanAllowed } from './plans';

export interface AuthUser {
	username: string;
	password: string;
}

export interface AuthLogin {
	Authorization: string;
	accessToken: string;
	refreshToken: string;
}

export interface User {
	cognito_id: string;
	email: string;
	lastnames: string;
	names: string;
	phone: string;
	photo?: string | null;
	rut: string;
}

export interface AuthLoginResponse {
	auth: AuthLogin;
	code: string;
	tokenExp: number;
	user: User;
}

export interface AuthBaseInterface {
	user?: User;
	timeToExpire?: number;
}

interface BaseCreateUserSignUpParams {
	name: string;
	lastName: string;
	email: string;
}

export interface SignUpParams extends BaseCreateUserSignUpParams {
	phone: string;
	companyName: string;
	countrySelected: number;
	paymentSelection: number;
	planSelected: PlanAllowed;
}

export interface CreateUserParams extends BaseCreateUserSignUpParams {
	password: string;
}

const countryMap = new Map<number, string>([
	[1, 'CL'],
	[2, 'MX'],
]);

export const getCountryCodeById = (id: number) => {
	return countryMap.get(id);
};
