import { AnalyticsIcon } from '../assets/svg/navigation/analytics';
import { BTIcon } from '../assets/svg/navigation/bt';
import { ConciliationIcon } from '../assets/svg/navigation/conciliation';
import { DDIcon } from '../assets/svg/navigation/dd';
import { EATMIcon } from '../assets/svg/navigation/eatm';
import { HomeIcon } from '../assets/svg/navigation/home';
import { OutboundIcon } from '../assets/svg/navigation/outbound';
import { OverviewIcon } from '../assets/svg/navigation/overview';
import { PLIcon } from '../assets/svg/navigation/pl';
import { QTIcon } from '../assets/svg/navigation/qt';
import { RefundIcon } from '../assets/svg/navigation/refund';
import { TNBIcon } from '../assets/svg/navigation/tnb';
import { CountriesAllowed, ProductsAllowed } from '../models/merchant';
import { RolesAllowed } from '../models/users';

export enum NavigationPage {
	HOME = '',
	ALL_PAYMENTS = 'all-payments',
	QUICK_TRANSFER = 'quick-transfer',
	PAYMENTS_LINKS = 'payment-links',
	PAYMENTS_LINKS_MULTIUSE = 'payment-links-multiuse',
	DIRECT_DEBIT_MX = 'direct-debit-mx',
	DIRECT_DEBIT_MX_MANAGEMENT = 'direct-debit-mx/management',
	DIRECT_DEBIT_MX_SUBSCRIPTIONS = 'direct-debit-mx/subscriptions',
	DIRECT_DEBIT = 'direct-debit',
	DIRECT_DEBIT_MANAGEMENT = 'direct-debit/management',
	DIRECT_DEBIT_RENDITION = 'direct-debit/rendition',
	BUSINESS_TRANSFER = 'business-transfer',
	NON_BANK_CARDS = 'non-bank-cards',
	EATM = 'eatm',
	CONCILIATION = 'conciliation',
	REFUNDS = 'refunds',
	REFUNDS_BANKS = 'refunds-banks',
	OUTBOUND = 'outbound',
	PAYOUTS_CL_OUTBOUND = 'payouts',
	PAYOUTS_CL_DEPOSIT = 'payouts/deposit',
	PAYOUTS_CL_BENEFICIARY = 'payouts/beneficiary',
	PAYOUTS_CL_DEPOSIT_MERCHANTS = 'payouts/deposit-merchants',
	CONSULT_REFUNDS = 'consult-refunds',
	ANALYTICS = 'analytics',
	SETTINGS = 'settings',
	ACCOUNT_INFO = 'account-info',
	USERS = 'users',
	CONTACTS = 'contacts',
	PERSONALIZATION = 'personalization',
	SUBSCRIPTION = 'subscription',
}

interface Module {
	id: string;
	name: string;
	title?: string;
	navigation: NavigationPage;
	show: boolean | ((arg: CountriesAllowed) => boolean);
	rolesAllowed: RolesAllowed[];
}

interface NavigationItem {
	id: string;
	name: string;
	title?: string;
	productName?: ProductsAllowed;
	navigation?: NavigationPage;
	icon?: ({ active, color }: { active: boolean; color?: string }) => JSX.Element;
	colorIcon?: string;
	belongTo?: NavigationPage;
	show: boolean | ((arg: CountriesAllowed) => boolean);
	rolesAllowed?: RolesAllowed[];
	modules?: Module[];
	redirect?: boolean;
}

interface Navigation {
	id: string;
	interactiveMenu?: boolean;
	title?: string;
	children: NavigationItem[];
	show: boolean;
}

export const navigation: Navigation[] = [
	{
		id: 'home',
		show: true,
		children: [
			{
				id: 'home',
				name: 'Inicio',
				icon: HomeIcon,
				colorIcon: '#646174',
				navigation: NavigationPage.HOME,
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
				],
				show: true,
			},
		],
	},
	{
		id: 'charge',
		show: true,
		title: 'Cobros',
		children: [
			{
				id: 'all-payments',
				name: 'Vista general',
				icon: OverviewIcon,
				colorIcon: '#646174',
				navigation: NavigationPage.ALL_PAYMENTS,
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
				],
				show: true,
			},
			{
				id: 'quick-transfer',
				name: 'Quick Transfer',
				productName: 'quick_transfer',
				navigation: NavigationPage.QUICK_TRANSFER,
				icon: QTIcon,
				colorIcon: '#FF7576',
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
				],
				show: true,
			},
			{
				id: 'payment-links',
				name: 'Payment Links',
				productName: 'payment_link',
				navigation: NavigationPage.PAYMENTS_LINKS,
				icon: PLIcon,
				colorIcon: '#857BF9',
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
					'Payment Links',
				],
				show: true,
			},
			{
				id: 'payment_link_multiuso',
				name: 'Payment Links',
				productName: 'payment_link_multiuso',
				navigation: NavigationPage.PAYMENTS_LINKS_MULTIUSE,
				icon: PLIcon,
				colorIcon: '#857BF9',
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
					'Payment Links',
				],
				show: true,
			},
			{
				id: 'direct_debit_mx',
				name: 'Domiciliación',
				productName: 'direct_debit_mx',
				icon: DDIcon,
				colorIcon: '#4C6CD1',
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
					'Payment Links',
				],
				show: true,
				redirect: true,
				modules: [
					{
						id: 'direct_debit_mx_subscription',
						name: 'Suscripciones',
						navigation: NavigationPage.DIRECT_DEBIT_MX_SUBSCRIPTIONS,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario'],
					},
					{
						id: 'direct_debit_mx_management',
						name: 'Programar cargos',
						navigation: NavigationPage.DIRECT_DEBIT_MX_MANAGEMENT,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario'],
					},
				],
			},
			{
				id: 'direct_debit',
				name: 'Débito Directo',
				productName: 'direct_debit',
				navigation: NavigationPage.DIRECT_DEBIT,
				icon: DDIcon,
				colorIcon: '#4C6CD1',
				show: true,
				redirect: true,
				modules: [
					{
						id: 'refunds',
						name: 'Gestión de cobros',
						navigation: NavigationPage.DIRECT_DEBIT_MANAGEMENT,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario'],
					},
					{
						id: 'consult_refunds',
						name: 'Rendición',
						navigation: NavigationPage.DIRECT_DEBIT_RENDITION,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario', 'Operaciones'],
					},
				],
			},
			{
				id: 'business-transfer',
				name: 'Business Transfer',
				productName: 'business_transfer',
				navigation: NavigationPage.BUSINESS_TRANSFER,
				icon: BTIcon,
				colorIcon: '#646174',
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
					'Payment Links',
				],
				show: true,
			},
			{
				id: 'non_bank_cards',
				name: 'Tarjetas no Bancarias',
				productName: 'tnb',
				navigation: NavigationPage.NON_BANK_CARDS,
				icon: TNBIcon,
				colorIcon: '#482524',
				rolesAllowed: ['ETpay'],
				show: true,
			},
		],
	},
	{
		id: 'withdrawals',
		title: 'Retiros',
		show: true,
		children: [
			{
				id: 'eATM',
				name: 'eATM',
				productName: 'eatm',
				navigation: NavigationPage.EATM,
				icon: EATMIcon,
				colorIcon: '#FFBE5C',
				rolesAllowed: [
					'ETpay',
					'Propietario',
					'Operaciones',
					'Devoluciones',
					'Conciliación',
					'Lector',
				],
				show: true,
			},
		],
	},
	{
		id: 'outbound',
		title: 'Pagos',
		show: true,
		children: [
			{
				id: 'outbound',
				name: 'Salidas',
				productName: 'payouts',
				navigation: NavigationPage.OUTBOUND,
				icon: OutboundIcon,
				colorIcon: '#684103',
				rolesAllowed: ['ETpay', 'Propietario', 'Devoluciones'],
				show: true,
			},
		],
	},
	{
		id: 'payouts-cl',
		show: true,
		title: 'Pagos',
		children: [
			{
				id: 'payouts-cl',
				name: 'Salidas',
				productName: 'payouts',
				icon: OutboundIcon,
				colorIcon: '#684103',
				show: true,
				navigation: NavigationPage.PAYOUTS_CL_OUTBOUND,
				redirect: true,
				modules: [
					{
						id: 'payouts-beneficiary',
						name: 'Agenda de beneficiarios',
						navigation: NavigationPage.PAYOUTS_CL_BENEFICIARY,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario', 'Operaciones', 'Propietario Operaciones'],
					},
					{
						id: 'payouts-deposit',
						name: 'Mis abonos',
						navigation: NavigationPage.PAYOUTS_CL_DEPOSIT,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario', 'Operaciones', 'Propietario Operaciones'],
					},
					{
						id: 'payouts-deposit-merchants',
						name: 'Abonos de merchants',
						navigation: NavigationPage.PAYOUTS_CL_DEPOSIT_MERCHANTS,
						show: true,
						rolesAllowed: ['Propietario Operaciones'],
					},
				],
			},
			{
				id: 'payouts',
				name: 'payouts',
				productName: 'payouts',
				rolesAllowed: ['ETpay', 'Propietario', 'Operaciones'],
				show: false,
			},
		],
	},
	{
		id: 'management',
		title: 'Gestión',
		show: true,
		children: [
			{
				id: 'refunds',
				name: 'Devoluciones',
				productName: 'payouts',
				icon: RefundIcon,
				colorIcon: '#5C2A48',
				show: true,
				modules: [
					{
						id: 'refunds',
						name: 'Devolver cobros',
						navigation: NavigationPage.REFUNDS,
						show: true,
						rolesAllowed: ['Propietario'],
					},
					{
						id: 'consult_refunds',
						name: 'Consultar devoluciones',
						navigation: NavigationPage.CONSULT_REFUNDS,
						show: true,
						rolesAllowed: ['ETpay', 'Propietario', 'Operaciones', 'Devoluciones'],
					},
					{
						id: 'refunds_banks',
						name: 'Devoluciones',
						navigation: NavigationPage.REFUNDS_BANKS,
						show: false,
						rolesAllowed: ['ETpay', 'Propietario', 'Devoluciones'],
					},
				],
			},
			{
				id: 'conciliation',
				name: 'Conciliación',
				productName: 'conciliation',
				navigation: NavigationPage.CONCILIATION,
				icon: ConciliationIcon,
				colorIcon: '#4D2323',
				rolesAllowed: ['ETpay', 'Propietario', 'Operaciones', 'Devoluciones', 'Conciliación'],
				show: true,
			},
			{
				id: 'analytics',
				name: 'Analytics',
				productName: 'analytics',
				navigation: NavigationPage.ANALYTICS,
				icon: AnalyticsIcon,
				colorIcon: '#140F33',
				rolesAllowed: ['ETpay', 'Propietario'],
				show: true,
			},
			{
				id: 'refunds',
				name: 'refunds',
				productName: 'outbound',
				rolesAllowed: ['ETpay', 'Propietario', 'Operaciones'],
				show: false,
			},
		],
	},
	{
		id: 'settings',
		show: true,
		title: 'Ajustes',
		interactiveMenu: true,
		children: [
			{
				id: 'account-info',
				name: 'Información de la cuenta',
				navigation: NavigationPage.ACCOUNT_INFO,
				belongTo: NavigationPage.SETTINGS,
				rolesAllowed: ['ETpay', 'Propietario', 'Propietario Operaciones'],
				show: true,
			},
			{
				id: 'users',
				name: 'Usuarios',
				navigation: NavigationPage.USERS,
				belongTo: NavigationPage.SETTINGS,
				rolesAllowed: ['ETpay', 'Propietario', 'Propietario Operaciones'],
				show: true,
			},
			{
				id: 'contacts',
				name: 'Contactos',
				navigation: NavigationPage.CONTACTS,
				belongTo: NavigationPage.SETTINGS,
				rolesAllowed: ['ETpay', 'Propietario'],
				show: true,
			},
			{
				id: 'personalization',
				name: 'Personalización',
				navigation: NavigationPage.PERSONALIZATION,
				belongTo: NavigationPage.SETTINGS,
				rolesAllowed: ['ETpay', 'Propietario'],
				show: (country: CountriesAllowed) => country === 'MX',
			},
			{
				id: 'subscription',
				name: 'Suscripción',
				navigation: NavigationPage.SUBSCRIPTION,
				belongTo: NavigationPage.SETTINGS,
				rolesAllowed: ['ETpay', 'Propietario'],
				show: true,
			},
		],
	},
];
