import { Logo } from '../../assets/svg';
import { SpinnerContainer, SpinnerWrapper, Text } from './styles';

interface SpinnerProps {
	message?: string;
}

export const Spinner = ({ message = 'Cargando la información' }: SpinnerProps) => {
	return (
		<SpinnerContainer>
			<SpinnerWrapper>
				<Logo />
				<Text>{message}</Text>
			</SpinnerWrapper>
		</SpinnerContainer>
	);
};
