import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
		flex-direction: column;
	}
`;

export const SectionContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	margin-bottom: 32px;
	padding: 16px 16px 6px;
	width: 50%;

	@media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
		width: 100%;
	}

	&:first-of-type {
		margin-right: 16px;
	}

	&:last-of-type {
		margin-left: 16px;

		@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
			margin-left: 0;
		}
	}
`;

export const SectionHeader = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`;

export const SectionTitle = styled.p`
	font-size: 18px;
	font-weight: 400;
`;

export const SectionDate = styled.p`
	background-color: ${({ theme }) => theme.colors.principal.eris};
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	line-height: 1;
	padding: 8px 12px;
`;

export const SectionContent = styled.div`
	display: flex;

	.recharts-bar-rectangle:last-child > rect {
		fill-opacity: 0.5;
	}
`;
