import { Dispatch, SetStateAction, useState } from 'react';

import { CameraIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { Input } from '../../../../components/input';
import { Section } from '../../../../components/section';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PersonalizationServices } from '../../../../services/personalization';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { ModalState, PersonalizationState } from '../..';
import { ContainerButton, DetailTitle, InfoUpload } from '../../style';

interface LogoSectionProps {
	fileName?: string;
	state: PersonalizationState;
	setState: (state: PersonalizationState) => void;
	setModal: Dispatch<SetStateAction<ModalState>>;
}

const LogoSection = ({ fileName, state, setState, setModal }: LogoSectionProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [disabledButton, setDisabledButton] = useState(true);
	const [error, setError] = useState<boolean>(false);

	const handleSaveNewLogo = async (changeToAllMerchants: boolean) => {
		setModal((prevState) => ({ ...prevState, loading: true, show: true }));
		try {
			if (!state.newLogo) return;
			await PersonalizationServices.saveNewLogo(
				merchantSelected?.code as string,
				state.newLogo,
				changeToAllMerchants,
			);
			setModal((prevState) => ({ ...prevState, loading: false, show: false }));
			handleShowAlert({
				message: 'Logo actualizado correctamente',
				type: 'success',
			});
			setDisabledButton(true);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		} finally {
			setModal((prevState) => ({ ...prevState, loading: false, show: false }));
		}
	};

	return (
		<Section title="Logotipo">
			<DetailTitle>Configura el logotipo de la pasarela de pago y comunicaciones.</DetailTitle>

			<Input
				label="Añadir imagen"
				name="Logo"
				variant="large"
				accept=".png, .svg"
				leftIcon={<CameraIcon />}
				type="file"
				multiple={false}
				defaultValue={state?.newLogo?.name || fileName}
				onChange={(e) => {
					setError(false);
					const file = e.target.files?.[0];

					if (file && file.size > 1048576) {
						setError(true);
						return;
					}
					setState({ ...state, newLogo: file });
					setDisabledButton(false);
				}}
			/>

			<InfoUpload>Solo archivos PNG o SVG.</InfoUpload>
			<InfoUpload hasError={error}>El tamaño del archivo no puede ser mayor a 1MB</InfoUpload>

			{validatePermission('SETTINGS_PERSONALIZATION_PUT') && (
				<ContainerButton>
					<ETPayButton
						color="secondary"
						title="Actualizar"
						disabled={disabledButton}
						onClick={() => setModal({ show: true, type: 'logotipo', action: handleSaveNewLogo })}
					/>
				</ContainerButton>
			)}
		</Section>
	);
};

export { LogoSection };
