import { useField } from 'formik';
import { InputHTMLAttributes, ReactNode } from 'react';

import { ErrorMsg, IconContainer, InputContainer, InputElement, InputLabel } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	name: string;
	leftIcon?: ReactNode;
	leftIconClick?: () => void;
	rightIcon?: ReactNode;
	rightIconClick?: () => void;
	variant?: 'small' | 'medium' | 'large';
}

const FormInput = ({ label, ...props }: InputProps) => {
	const [field, meta] = useField(props);

	return (
		<InputContainer>
			{props.leftIcon && (
				<IconContainer position="left" onClick={props.leftIconClick}>
					{props.leftIcon}
				</IconContainer>
			)}

			<InputElement {...field} {...props} hasError={!!meta.error && !!meta.touched} />

			{props.rightIcon && (
				<IconContainer position="right" onClick={props.rightIconClick}>
					{props.rightIcon}
				</IconContainer>
			)}

			<InputLabel htmlFor={props.name} hasValue={meta.value && meta.value.length > 0}>
				{label}
			</InputLabel>

			<ErrorMsg name={props.name} component="span" />
		</InputContainer>
	);
};

export { FormInput };
