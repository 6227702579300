import trackerRedux from '@openreplay/tracker-redux';
import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { CONSTANTS } from '../config/constants';
import { decode, encode } from '../utils/common';
import { tracker } from '../utils/open-replay';
import { authReducer } from './auth/reducers';
import { merchantReducer } from './merchants/reducers';

const rootReducer = combineReducers({
	auth: authReducer,
	merchant: merchantReducer,
});

type RootReducerInterface = ReturnType<typeof rootReducer>;

const saveToLocalStorage = (state: RootReducerInterface): void => {
	try {
		const serializedState = JSON.stringify(state);
		const encodeSerializedState = encode(serializedState);
		localStorage.setItem(CONSTANTS.localStorage.ETPay, encodeSerializedState);
	} catch (e) {
		console.warn(e);
	}
};

const loadLocalStorageData = (): RootReducerInterface | undefined => {
	try {
		const serializedState = localStorage.getItem(CONSTANTS.localStorage.ETPay);
		if (serializedState === null) {
			return undefined;
		}
		const decodeSerializedState = decode(serializedState);
		const serializedData = JSON.parse(decodeSerializedState);
		return serializedData;
	} catch (e) {
		console.error(e);
		return undefined;
	}
};

const openReplayMiddleware = tracker.use(trackerRedux());
const middleware = [thunk, openReplayMiddleware];
const composeEnhancers = composeWithDevTools({
	trace: true,
});

const store = createStore(
	rootReducer,
	loadLocalStorageData(),
	composeEnhancers(applyMiddleware(...middleware)),
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export { rootReducer, store };
export type { RootReducerInterface };
