import { Dispatch, SetStateAction, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { OutboundIcon } from '../../../../assets/svg/navigation/outbound';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { Beneficiary } from '../../../../models/payouts';
import {
	ColumnInterface,
	Filters,
	FilterStatus,
	Pagination,
	RowInterface,
} from '../../../../models/table';
import { PayoutsService } from '../../../../services/payouts';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import ModalPayouts from '../modal';
import {
	CellContainer,
	Content,
	DetailsContainer,
	Download,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	Section,
	Title,
	TransferButton,
	Value,
} from './styles';

interface RefundsTableProps {
	data: Beneficiary[];
	isLoading?: boolean;
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	today?: Date;
	pastDate?: Date;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	stateList: FilterStatus[];
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

interface Bank {
	name: string;
	code: string;
}

export interface Values {
	account: string;
	amount: string;
	bank: Bank;
	code: string;
	codeOtp: string;
	email: string;
	loading: boolean;
	message: string;
	name: string;
	reference: string;
	requestDate: string;
	rut: string;
	token: string;
}

export const defaultValues: Values = {
	account: '',
	amount: '',
	bank: { name: '', code: '' },
	code: '',
	codeOtp: '',
	email: '',
	loading: false,
	message: '',
	name: '',
	reference: '',
	requestDate: '',
	rut: '',
	token: '',
};

const BeneficiaryTable = ({
	data,
	isLoading,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	refetch,
}: RefundsTableProps) => {
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();
	const [modalPayouts, setModalPayouts] = useState<boolean>(false);
	const [state, setState] = useState<Values>(defaultValues);

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });
		try {
			const response = await PayoutsService.getExportDataBeneficiary({
				merchantCode: merchantSelected?.code as string,
				page: pagination,
			});

			const url = window.URL.createObjectURL(new Blob([response.csv]));
			if (response.csv) setModal({ loader: false, show: true, url: url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const onClose = () => {
		setModalPayouts(false);
		setState(defaultValues);
		refetch();
	};

	const columns: ColumnInterface<Beneficiary>[] = [
		{
			sortField: true,
			title: 'Nombre',
			propertyName: 'name',
			cellRender: ({ name }) => <>{name}</>,
		},
		{
			sortField: true,
			title: 'Rut',
			propertyName: 'rut',
			cellRender: ({ rut }) => <>{rut}</>,
		},
		{
			title: 'Banco',
			propertyName: 'bankName',
			cellRender: ({ bankName }) => <>{bankName}</>,
		},
		{
			sortField: true,
			title: 'N°Cuenta',
			propertyName: 'account',
			cellRender: ({ account }) => <>{account && account.replace(/\d(?=\d{4})/g, '*')}</>,
		},
		{
			align: 'center',
			title: 'Transferir ahora',
			propertyName: 'transfer',
			cellRender: (rowData) => (
				<>
					<CellContainer>
						<TransferButton
							onClick={() => {
								setModalPayouts(true);
								setState({
									...state,
									rut: rowData.rut,
									name: rowData.name,
									email: rowData.email,
									account: rowData.account,
									bank: {
										name: rowData.bankName,
										code: rowData.bankSbifNumber,
									},
								});
							}}
						>
							<OutboundIcon />
						</TransferButton>
					</CellContainer>
				</>
			),
		},
	];

	const detailSection: RowInterface<Beneficiary> = (data) => {
		return (
			<DetailsContainer>
				<Section>
					<Content>
						<Title>Nombre del beneficiario</Title>
						<Value>{data.name}</Value>
					</Content>
					<Content>
						<Title>Correo electrónico</Title>
						<Value>{data.email}</Value>
					</Content>
				</Section>
			</DetailsContainer>
		);
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<Download href={modal.url} download="beneficiarios.csv">
								Descargar reporte
							</Download>
						</ExportModalContent>
					)}
				</Modal>
			)}

			{modalPayouts && <ModalPayouts onClose={onClose} state={state} setState={setState} />}

			<ETPayTable<Beneficiary>
				title="Beneficiarios"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={detailSection}
				allowExport={validatePermission('PAYOUTS_EXPORTS')}
				exportAction={handleExportAction}
				onFilterChange={onFilterChange}
				hasFilters
				hasSearch
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				setPagination={setPagination}
			/>
		</>
	);
};

export { BeneficiaryTable };
