export const RightAngleIcon = () => (
	<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.81641 1.63269L6.18371 5.99999L1.81641 10.3673"
			stroke="#140F33"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
