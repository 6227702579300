interface IconProps {
	active?: boolean;
	color?: string;
}

export const HomeIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			x="3.25"
			y="10.25"
			width="3.5"
			height="3.5"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<rect
			x="10.25"
			y="7.25"
			width="3.5"
			height="6.5"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<rect
			x="3.25"
			y="16.25"
			width="7.5"
			height="3.5"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<rect
			x="13.25"
			y="16.25"
			width="7.5"
			height="3.5"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<rect
			x="17.25"
			y="4.25"
			width="3.5"
			height="9.5"
			fill={active ? color : 'white'}
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
);
