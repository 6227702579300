import { useState } from 'react';

import { ETPAyPagination } from '../../../../components/table/components/pagination';
import { Status } from '../../../../models/common-payments';
import { ColumnInterface } from '../../../../models/table';
import {
	Table,
	TableBody,
	TableContainer,
	TableData,
	TableHead,
	TableHeader,
	TableRow,
} from './styles';

interface TableDetailProps {
	data: PaymentsMultiuse[];
	columns?: ColumnInterface<PaymentsMultiuse>[];
}

export interface PaymentsMultiuse {
	trackingkey?: string;
	clabe?: string;
	email?: string;
	reference?: string;
	bankname?: string;
	amount?: string;
	description?: string;
	paymentdate?: string;
	paymenttime?: string;
	finalstatus: Status;
	urlDoc?: string;
	updateddate?: string;
	updatedtime?: string;
}

const TableDetail = ({ data, columns }: TableDetailProps) => {
	const [pagination, setPagination] = useState({ size: 5, page: 1 });

	const startIndex = (pagination.page - 1) * pagination.size;
	const endIndex = pagination.page * pagination.size;

	const dataFinal = data.slice(startIndex, endIndex);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{columns?.map((column: ColumnInterface<PaymentsMultiuse>, i) => (
							<TableHeader key={i}>{column.title}</TableHeader>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{dataFinal.map((item: PaymentsMultiuse, i) => (
						<TableRow key={i}>
							{columns?.map((column: ColumnInterface<PaymentsMultiuse>, j) => (
								<TableData key={j}>{column.cellRender(item)}</TableData>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
			<ETPAyPagination
				isLoading={false}
				totalResults={data.length ?? 0}
				hasPaginationSelect={false}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</TableContainer>
	);
};

export { TableDetail };
