import { SVGProps } from 'react';

export const Sencillito = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="105"
			height="21"
			viewBox="0 0 105 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M89.9453 13.6304V13.5768C89.9862 11.599 90.7828 9.71585 92.1652 8.32932C93.5476 6.94279 95.4062 6.16274 97.3442 6.15569C99.2823 6.14864 101.146 6.91514 102.538 8.29158C103.93 9.66802 104.74 11.5453 104.795 13.5228V13.5768C104.791 14.5663 104.594 15.5451 104.215 16.4559C103.836 17.3666 103.283 18.1909 102.588 18.8805C101.892 19.5701 101.069 20.111 100.167 20.4715C99.2638 20.8321 98.2996 21.005 97.3305 20.9802C96.3696 21.005 95.4135 20.8333 94.5186 20.4751C93.6237 20.1169 92.8081 19.5794 92.1198 18.8945C91.4316 18.2095 90.8846 17.3909 90.5112 16.4868C90.1377 15.5828 89.9453 14.6115 89.9453 13.6304Z"
				fill="white"
			/>
			<path
				d="M83.2363 9.10992H80.7051V6.0612H83.2363C83.2363 5.16867 83.2676 4.37008 83.2248 3.57619C83.2056 3.47395 83.2074 3.36873 83.2303 3.26727C83.2532 3.16581 83.2965 3.07034 83.3576 2.98695C83.4187 2.90357 83.4961 2.83411 83.585 2.78302C83.6738 2.73192 83.7722 2.70032 83.8737 2.69023C84.6404 2.4934 85.3961 2.25383 86.3106 1.9856V6.01423H89.811V9.07704H86.3456C86.3456 10.4567 86.3456 11.7438 86.3456 13.0319C86.3456 14.0414 86.3262 15.0519 86.3584 16.06C86.3985 17.3227 86.7832 17.7328 88.0295 17.8399C88.5983 17.8868 89.1741 17.8483 89.8115 17.8483V20.6537C88.703 20.7091 87.5923 20.7014 86.4845 20.6307C84.6487 20.4188 83.4899 19.251 83.3707 17.3678C83.2252 15.066 83.2708 12.7496 83.2358 10.4393C83.2312 10.0241 83.2363 9.60974 83.2363 9.10992Z"
				fill="white"
			/>
			<path
				d="M0.458984 18.7994L1.88337 16.5916C3.18483 17.6524 4.78216 18.265 6.44601 18.3414C7.81793 18.3414 8.60905 17.7491 8.60905 16.8072V16.7532C8.60905 15.6497 7.13174 15.2725 5.49658 14.7614C3.43939 14.169 1.14472 13.3075 1.14472 10.5886V10.5345C1.14472 7.84236 3.33399 6.20056 6.10314 6.20056C7.92382 6.23553 9.69768 6.79542 11.2199 7.81558L9.95382 10.131C8.78075 9.34095 7.42605 8.87689 6.02352 8.78469C4.78415 8.78469 4.07219 9.37705 4.07219 10.185V10.2386C4.07219 11.289 5.57528 11.7197 7.2109 12.2585C9.24186 12.9049 11.537 13.8468 11.537 16.4051V16.4586C11.537 19.4472 9.26855 20.9279 6.36731 20.9279C4.22254 20.8987 2.1466 20.1508 0.458984 18.7994Z"
				fill="white"
			/>
			<path
				d="M12.7227 13.6033V13.5492C12.7227 9.48396 15.5447 6.14587 19.5275 6.14587C23.959 6.14587 26.2007 9.69958 26.2007 13.7921C26.2007 14.088 26.174 14.3845 26.1478 14.7072H15.9138C16.2567 17.0226 17.8661 18.3149 19.9233 18.3149C20.6337 18.3187 21.337 18.1696 21.9872 17.8774C22.6374 17.5852 23.2201 17.1565 23.6971 16.6191L25.5702 18.3149C24.8864 19.1778 24.0162 19.8675 23.0278 20.3299C22.0393 20.7923 20.9594 21.0149 19.8727 20.9803C15.8328 20.9798 12.7227 17.9917 12.7227 13.6033ZM23.0358 12.5529C22.8245 10.4531 21.6114 8.81127 19.5013 8.81127C17.5499 8.81127 16.178 10.3455 15.8876 12.5529H23.0358Z"
				fill="white"
			/>
			<path
				d="M28.1001 6.44182H31.2917V8.62243C32.1882 7.30335 33.481 6.14587 35.6436 6.14587C38.7823 6.14587 40.602 8.29971 40.602 11.5838V20.6566H37.4104V12.5811C37.4104 10.3732 36.3293 9.10816 34.43 9.10816C32.5835 9.10816 31.2913 10.4272 31.2913 12.6351V20.6566H28.0996L28.1001 6.44182Z"
				fill="white"
			/>
			<path
				d="M42.3717 13.6302V13.5767C42.3593 12.5997 42.5383 11.63 42.8981 10.7245C43.2579 9.81906 43.7912 8.99607 44.4668 8.30387C45.1425 7.61167 45.9467 7.06419 46.8325 6.69357C47.7182 6.32295 48.6676 6.13666 49.6248 6.14562C50.6727 6.10474 51.7163 6.3035 52.6792 6.72737C53.6422 7.15123 54.5001 7.78947 55.1902 8.5954L53.1855 10.776C52.2098 9.72563 51.1812 8.99892 49.599 8.99892C47.2776 8.99892 45.5633 11.0452 45.5633 13.5222V13.5762C45.5633 16.1068 47.2777 18.1253 49.7306 18.1253C51.2337 18.1253 52.3415 17.4259 53.3443 16.3755L55.2694 18.3142C54.3122 19.4801 53.0284 20.3191 51.5904 20.7186C50.1524 21.118 48.6291 21.0588 47.225 20.5489C45.821 20.0389 44.6034 19.1027 43.7358 17.8658C42.8683 16.629 42.3922 15.1508 42.3717 13.6298V13.6302Z"
				fill="white"
			/>
			<path d="M57.0605 6.44202H60.2517V20.6564H57.0605V6.44202Z" fill="white" />
			<path d="M63.2871 0.417358H66.4787V20.6568H63.2871V0.417358Z" fill="white" />
			<path d="M69.5117 0.417358H72.7034V20.6568H69.5117V0.417358Z" fill="white" />
			<path d="M75.7363 6.44202H78.928V20.6564H75.7363V6.44202Z" fill="white" />
			<path
				d="M79.2961 2.64172C79.293 2.90783 79.2383 3.17068 79.1351 3.41511C79.032 3.65953 78.8824 3.88069 78.6951 4.06583C78.5078 4.25098 78.2865 4.39644 78.0438 4.49383C77.8012 4.59123 77.5421 4.63863 77.2814 4.63329C77.0207 4.62796 76.7637 4.57 76.525 4.46276C76.2864 4.35552 76.0709 4.20112 75.8911 4.00847C75.7112 3.81581 75.5705 3.58871 75.477 3.34027C75.3835 3.09183 75.3392 2.82695 75.3465 2.56093C75.373 2.03767 75.5979 1.54545 75.9736 1.18847C76.3493 0.83148 76.8464 0.637734 77.3596 0.648233C77.8729 0.658732 78.3619 0.872652 78.7234 1.24472C79.0848 1.61679 79.2902 2.1178 79.2961 2.64172Z"
				fill="white"
			/>
			<path
				d="M60.5227 2.6416C60.5175 2.90634 60.4613 3.16744 60.3572 3.41C60.2532 3.65255 60.1033 3.87182 59.9163 4.05526C59.7292 4.23871 59.5085 4.38275 59.2669 4.47916C59.0253 4.57557 58.7675 4.62246 58.5081 4.61716C58.2487 4.61185 57.993 4.55445 57.7553 4.44824C57.5177 4.34203 57.3029 4.18908 57.1231 3.99813C56.9434 3.80718 56.8023 3.58197 56.7079 3.33535C56.6134 3.08873 56.5675 2.82554 56.5727 2.5608C56.5972 2.03607 56.8213 1.5418 57.1973 1.18312C57.5734 0.824441 58.0716 0.629651 58.5862 0.640176C59.1008 0.650701 59.591 0.865711 59.9526 1.23948C60.3143 1.61325 60.5188 2.11628 60.5227 2.6416Z"
				fill="white"
			/>
			<path
				d="M101.61 15.1158H100.932V14.8875C100.932 14.2937 100.935 13.6999 100.926 13.1062C100.938 12.9831 100.909 12.8596 100.845 12.755C100.78 12.6503 100.683 12.5706 100.57 12.5284C100.452 12.4754 100.322 12.4619 100.197 12.4897C100.071 12.5175 99.958 12.5851 99.8728 12.6829C99.7554 12.8318 99.6838 13.013 99.6671 13.2034C99.6381 13.7526 99.651 14.3041 99.6473 14.8551C99.6473 14.9392 99.6473 15.0237 99.6473 15.1167H98.9805V11.9337H99.6303L99.663 12.2296C100.357 11.6744 100.902 11.6993 101.44 12.3179C101.585 12.1233 101.784 11.9777 102.01 11.9001C102.237 11.8225 102.482 11.8165 102.712 11.8828C102.942 11.9492 103.148 12.0848 103.301 12.272C103.455 12.4592 103.549 12.6893 103.573 12.9324C103.583 13.039 103.582 13.147 103.582 13.2546C103.582 13.8691 103.582 14.4835 103.582 15.112H102.906C102.906 14.8814 102.906 14.655 102.906 14.4285C102.906 13.9917 102.913 13.5553 102.9 13.1189C102.906 13.0397 102.897 12.96 102.872 12.8848C102.847 12.8095 102.807 12.7404 102.754 12.6816C102.702 12.6227 102.638 12.5755 102.567 12.5429C102.497 12.5102 102.42 12.4928 102.342 12.4917C102.261 12.4807 102.18 12.486 102.101 12.5072C102.023 12.5285 101.949 12.5653 101.885 12.6156C101.82 12.6659 101.766 12.7287 101.725 12.8004C101.685 12.8721 101.658 12.9512 101.647 13.0334C101.621 13.2028 101.608 13.3743 101.61 13.5459C101.607 14.0654 101.61 14.5859 101.61 15.1158Z"
				fill="#140F33"
			/>
			<path
				d="M98.6554 13.519C98.6567 13.8537 98.5606 14.1812 98.3795 14.4601C98.1983 14.739 97.9401 14.9568 97.6376 15.0859C97.3351 15.2149 97.0019 15.2495 96.6802 15.1852C96.3584 15.1209 96.0626 14.9605 95.8302 14.7245C95.5977 14.4885 95.4391 14.1874 95.3744 13.8594C95.3097 13.5313 95.3418 13.191 95.4666 12.8816C95.5915 12.5721 95.8035 12.3075 96.0758 12.1211C96.3481 11.9347 96.6684 11.8349 96.9963 11.8345C97.2141 11.832 97.4302 11.8738 97.632 11.9574C97.8339 12.0411 98.0174 12.1649 98.1718 12.3217C98.3262 12.4785 98.4485 12.6652 98.5316 12.8707C98.6146 13.0763 98.6567 13.2967 98.6554 13.519ZM97.0023 14.5304C97.1322 14.5318 97.261 14.5065 97.3811 14.456C97.5012 14.4054 97.61 14.3307 97.7012 14.2363C97.7924 14.1418 97.8639 14.0296 97.9116 13.9063C97.9594 13.7829 97.9822 13.6511 97.9789 13.5185C97.9784 13.3871 97.9525 13.257 97.9027 13.1358C97.853 13.0145 97.7803 12.9044 97.6888 12.8119C97.5974 12.7193 97.489 12.646 97.3698 12.5961C97.2506 12.5463 97.123 12.521 96.9942 12.5215C96.8654 12.522 96.738 12.5484 96.6192 12.5992C96.5004 12.65 96.3926 12.7242 96.3019 12.8175C96.2112 12.9108 96.1393 13.0215 96.0905 13.1431C96.0417 13.2648 96.0169 13.3951 96.0174 13.5265C96.0151 13.6591 96.039 13.7908 96.0877 13.9138C96.1364 14.0367 96.2089 14.1484 96.3008 14.2421C96.3927 14.3358 96.5022 14.4097 96.6228 14.4592C96.7433 14.5087 96.8724 14.5329 97.0023 14.5304Z"
				fill="#140F33"
			/>
			<path
				d="M95.2774 12.6678L94.7251 12.9938C94.6468 12.8649 94.541 12.7556 94.4157 12.6741C94.2903 12.5926 94.1486 12.5409 94.001 12.5228C93.8535 12.5048 93.7038 12.5209 93.5631 12.5699C93.4225 12.6189 93.2944 12.6995 93.1885 12.8059C93.0037 13.0054 92.9032 13.2709 92.9085 13.5454C92.9138 13.8198 93.0246 14.0811 93.217 14.273C93.662 14.6835 94.27 14.598 94.7302 14.0475L95.2825 14.3702C95.1124 14.6887 94.8429 14.9399 94.5168 15.0839C94.1908 15.228 93.8268 15.2565 93.483 15.165C93.1253 15.0756 92.8071 14.8669 92.5788 14.572C92.3505 14.2771 92.2252 13.9129 92.2227 13.537C92.2202 13.1611 92.3407 12.7952 92.5651 12.4971C92.7895 12.1991 93.1049 11.9861 93.4613 11.8918C93.8054 11.7938 94.1719 11.8171 94.5014 11.9579C94.8309 12.0987 95.1043 12.3488 95.2774 12.6678Z"
				fill="#140F33"
			/>
			<path
				d="M91.2071 14.7228C91.2089 14.5988 91.2581 14.4805 91.344 14.3929C91.43 14.3053 91.546 14.2554 91.6675 14.2537C91.789 14.2521 91.9062 14.2988 91.9945 14.384C92.0827 14.4693 92.1349 14.5862 92.14 14.7101C92.1426 14.7737 92.1326 14.8373 92.1108 14.8969C92.0889 14.9566 92.0555 15.0112 92.0126 15.0574C91.9698 15.1037 91.9182 15.1407 91.8611 15.1663C91.8039 15.1918 91.7423 15.2054 91.6799 15.2063C91.6175 15.2071 91.5556 15.1952 91.4978 15.1712C91.44 15.1472 91.3875 15.1116 91.3434 15.0665C91.2993 15.0214 91.2646 14.9678 91.2411 14.9087C91.2177 14.8497 91.2061 14.7865 91.2071 14.7228Z"
				fill="#140F33"
			/>
		</svg>
	);
};
