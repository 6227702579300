import styled from 'styled-components';

interface PlanContainerProps {
	readonly planSelected: boolean;
}

export const PlanContainer = styled.div<PlanContainerProps>`
	background-color: ${({ planSelected, theme }) =>
		theme.colors.principal[planSelected ? 'light-alamak' : 'pure']};
	border: 1px solid
		${({ planSelected, theme }) => theme.colors.principal[planSelected ? 'alamak' : 'eris']};
	border-radius: 12px;
	padding: 24px 20px;
	width: 350px;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PlanTitle = styled.h3`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 24px;
	font-weight: 500;
	line-height: 32px;
	margin: 0 0 24px;
`;

export const PlanSubtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 16px;
`;

export const PlanPriceContainer = styled.p`
	align-items: flex-end;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	margin: 0 0 9px;
`;

export const Price = styled.span`
	font-size: 38px;
	font-weight: 500;
`;

export const UnitPrice = styled.span`
	font-size: 20px;
	line-height: 28px;
`;

export const BodyContainer = styled.div`
	align-self: flex-start;
	min-height: 140px;
`;

export const FeaturesTitle = styled.h4`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
`;

export const Features = styled.ul`
	display: flex;
	flex-direction: column-reverse;
	list-style: none;
	margin: 0;
	padding: 0;

	div {
		&:last-child {
			margin-bottom: 16px;
		}
	}
`;

interface FeatureProps {
	readonly isAvailable: boolean;
}

export const Feature = styled.li<FeatureProps>`
	align-items: center;
	color: ${({ theme, isAvailable }) => theme.colors.principal[isAvailable ? 'vanth' : 'moon']};
	display: flex;

	span {
		display: ${({ isAvailable }) => !isAvailable && 'none'};
		font-size: 14px;
		line-height: 21px;
		margin-left: 8px;
	}

	&:not(:first-child) {
		margin-top: 10px;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 24px;

	button {
		font-size: 16px;
		padding: 17px 0;
		width: 100%;
	}
`;
