import styled from 'styled-components';

export const Tooltip = styled.div`
	background: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	min-width: 200px;
	padding: 6px 8px 17px;
`;

export const TooltipInfo = styled.div`
	display: flex;
	gap: 4px;
	justify-content: space-between;
	width: 100%;
`;

export const LabelContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
`;

export const Month = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 10px;
`;

export const Label = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
`;

export const Value = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
`;

interface IconProps {
	color?: string;
}

export const Icon = styled.div<IconProps>`
	background-color: ${({ color }) => color};
	border-radius: 100%;
	height: 8px;
	width: 8px;
`;
