import { ExportDataParams, GetAllPaymentsParams } from '../models/all-payments';
import { ResponseExport, ResponseGraphics, ResponseTable } from '../models/common-payments';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getGraphicsData = async (merchantCode: string, enterpriseCode?: number) => {
	try {
		const params = Object();
		if (!enterpriseCode) params.merchant_code = merchantCode;
		if (enterpriseCode) params.enterprise_id = enterpriseCode;

		const { data } = await client.get<ResponseGraphics>('/v1/all-payments/graphics', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getTableData = async ({ enterpriseCode, ...rest }: GetAllPaymentsParams) => {
	try {
		const params = tableParamsConstructor(rest);
		if (enterpriseCode) {
			delete params.merchant_code;
			params.enterprise_id = enterpriseCode;
		}
		const { data } = await client.get<ResponseTable>('/v1/all-payments/table', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/all-payments/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const AllPaymentsServices = {
	getGraphicsData,
	getTableData,
	getExportData,
};
