import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	text-align: center;

	&:last-of-type {
		margin-bottom: 32px;
		margin-top: 16px;
	}
`;

export const CreateContainer = styled.div`
	display: flex;

	div {
		&:last-of-type {
			background-color: ${({ theme }) => theme.colors.principal.air};
		}
	}
`;

export const ModalFooter = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

export const MainSection = styled.div`
	${Subtitle} {
		text-align: left;
	}
`;

export const FormGroup = styled.div`
	display: flex;
	gap: 16px;
`;

export const SummarySection = styled.div`
	width: 100%;
`;

export const ButtonContainer = styled.div`
	margin-top: 32px;
`;

export const FeatureSectionTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
`;
