import { RefObject, useEffect, useRef } from 'react';

interface UseClickOutside {
	elementRef: RefObject<HTMLElement>;
	callback: () => void;
}

const useClickOutside = ({ elementRef, callback }: UseClickOutside) => {
	const callbackRef = useRef(callback);
	callbackRef.current = callback;

	useEffect(() => {
		const handleClickOutside = (e: globalThis.MouseEvent) => {
			if (elementRef.current && !elementRef.current.contains(e.target as Node)) {
				callbackRef.current();
			}
		};

		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [callbackRef, elementRef]);
};

export { useClickOutside };
