export type Suscriptor = (evento: Process) => void;

export enum ProcessType {
	USERS = 'users',
	DELETE_USER = 'delete-user',
	UPDATE_ROLE = 'update-role',
	UPDATE_CASH_ALLOWED = 'atm-available-cash',
}

export interface Process {
	action: ProcessType;
	data?: any;
}
