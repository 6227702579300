import styled from 'styled-components';

export const TitlePage = styled.h1`
	font-size: 32px;
	font-weight: 400;
	padding-bottom: 16px;
`;

export const BodySection = styled.div`
	margin-top: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		width: 50%;
	}
`;

export const InputContainerGroup = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
	gap: 16px;
`;

export const InputContainer = styled.div`
	margin-top: 16px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 32px;
`;
export const HintInputError = styled.p`
	color: ${({ theme }) => theme.colors.extra.sword};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	text-align: left;
	width: 100%;
`;

