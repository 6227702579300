import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import Dropdown from '../../../../components/dropdown';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import useRut from '../../../../hooks/useRut';
import { CountriesAllowed } from '../../../../models/merchant';
import { PayoutsService } from '../../../../services/payouts';
import { errorFormatter } from '../../../../utils/errors';
import { formatNumber } from '../../../../utils/numbers';
import OutboundResume from '../outboundResume';
import SendCode from '../sendCode';
import { Values } from '../table';
import {
	Button,
	ButtonContainer,
	HintInput,
	InputContainer,
	ModalContainer,
	ModalStep,
	ModalSubtitle,
	ModalTitle,
	ResumeContainer,
	SelectAndInputContainer,
} from './styles';

interface ModalOutboundProp {
	onClose: () => void;
	setState: Dispatch<SetStateAction<Values>>;
	state: Values;
}

interface TransferResumeItem {
	item: string;
	data: string;
}

const ModalPayouts = ({ onClose, state, setState }: ModalOutboundProp) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const { rut } = useRut();
	const { user } = useAuth();
	const [steps, setSteps] = useState<number>(1);

	const handleVerifyDisabledButton = {
		firstStep: () => {
			if (state.amount !== '' && state.message !== '') return false;
			return true;
		},
		thirdStep: () => {
			if (state?.codeOtp?.length === 6) return false;
			return true;
		},
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleContinue = async (step: number) => {
		setState({ ...state, loading: true });
		try {
			const response = await PayoutsService.generateOutbound({
				merchantCode: merchantSelected?.code as string,
				amount: +state.amount,
				messageToAddressee: state.message,
				rut: state.rut.split('.').join(''),
				name: state.name,
				email: state.email,
				bankAccount: state.account,
				bankSBIFNumber: state.bank.code,
				reference: state.reference,
			});
			setState((prevState) => ({
				...prevState,
				token: response.token,
				loading: false,
				reference: state.reference || response.reference,
			}));
			setSteps(step);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setState({ ...state, loading: false });
		}
	};

	const handleContinueSendCode = async () => {
		setState((state) => ({ ...state, loading: true }));
		try {
			const response = await PayoutsService.sendCodeOutbound({
				merchantCode: merchantSelected?.code as string,
				code: state.codeOtp,
				token: state.token,
			});

			setState({ ...state, requestDate: response.resume.requestDate });
			setSteps(4);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setState((state) => ({ ...state, loading: false }));
		}
	};

	const userResume: TransferResumeItem[] = [
		{
			item: 'Rut',
			data: state.rut,
		},
		{
			item: 'Nombre beneficiario',
			data: state.name,
		},
		{
			item: 'Correo beneficiario',
			data: state.email,
		},
	];

	const bankResume: TransferResumeItem[] = [
		{
			item: 'Banco',
			data: state.bank.name,
		},
		{
			item: 'Número de cuenta',
			data: state.account,
		},
		{
			item: 'Monto',
			data: `$${formatNumber({
				value: +state.amount as number,
				country: merchantSelected?.country as CountriesAllowed,
			})}`,
		},
		{
			item: 'Mensaje',
			data: state.message,
		},
	];

	const referenceResume: TransferResumeItem[] = [
		{
			item: 'Referencia única',
			data: state.reference,
		},
	];

	const codeResume: TransferResumeItem[] = [
		{
			item: 'Correo enviado a',
			data: user?.email ? user.email : '',
		},
	];

	const dateResume: TransferResumeItem[] = [
		{
			item: 'Referencia única',
			data: state.reference,
		},
		{
			item: 'Fecha de solicitud',
			data: state.requestDate,
		},
	];

	return (
		<Modal title="Pago de salida" show type="medium" onClose={onClose}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					{(steps === 1 || steps === 2 || steps === 3) && <ModalStep>PASO {steps} DE 3</ModalStep>}
					<ModalTitle>
						{steps === 1 && 'Completa la información del pago'}
						{steps === 2 && 'Verifica la información del pago'}
						{steps === 3 && 'Autoriza el pago de salida'}
						{steps === 4 && 'En proceso'}
					</ModalTitle>
					<ModalSubtitle>
						{steps === 2 &&
							'Las operaciones realizadas en días no laborales fin de semana o feriados bancarios se procesarán hasta el siguiente día hábil.'}
						{steps === 3 &&
							'Enviamos un código de seguridad al correo electrónico asociado a tu cuenta, ingrésalo para autorizar la operación.'}
						{steps === 4 &&
							'El pago de salida está en proceso de autorización bancaria, consulta su estatus en la sección “Salidas”.'}
					</ModalSubtitle>

					{steps === 1 && (
						<>
							<InputContainer>
								<div>
									<Input label="RUT" name="rut" variant="large" value={state.rut} disabled />
								</div>

								<div>
									<Input
										label="Nombre del beneficiario (máx. 40 caracteres)"
										name="name"
										variant="large"
										value={state.name}
										onChange={handleChangeInput}
										placeholder="Juan Perez"
										disabled
									/>
								</div>

								<div>
									<Input
										label="Correo electrónico del beneficiario"
										name="email"
										variant="large"
										value={state.email}
										onChange={handleChangeInput}
										placeholder="juanperez@correo.com"
										disabled
									/>
									<HintInput>Lo usaremos para notificar la confirmación del pago</HintInput>
								</div>

								<SelectAndInputContainer>
									<Dropdown disabled state={state.bank.name} />
									<Input
										label="Número de cuenta"
										name="account"
										variant="large"
										value={state.account}
										onChange={handleChangeInput}
										placeholder="0000.0000.0000.0000"
										disabled
									/>
								</SelectAndInputContainer>

								<div>
									<Input
										label="Monto"
										name="amount"
										variant="large"
										value={state.amount}
										onChange={handleChangeInput}
										prefixSymbol="$"
										placeholder="00"
									/>
								</div>

								<div>
									<Input
										label="Referencia única (Opcional)"
										name="reference"
										variant="large"
										value={state.reference}
										onChange={handleChangeInput}
									/>
								</div>

								<div>
									<Input
										label="Mensaje (máx. 40 caracteres)"
										name="message"
										variant="large"
										value={state.message}
										onChange={handleChangeInput}
										maxLength={40}
									/>
								</div>
							</InputContainer>

							<Button
								onClick={() => {
									setState({ ...state, rut: rut });
									handleContinue(2);
								}}
								disabled={handleVerifyDisabledButton.firstStep()}
								outboundColor
							>
								Siguiente
							</Button>
						</>
					)}

					{steps === 2 && (
						<>
							<ResumeContainer>
								<OutboundResume transferResume={userResume} />
								<OutboundResume transferResume={bankResume} />
								<OutboundResume transferResume={referenceResume} />
							</ResumeContainer>

							<ButtonContainer>
								<Button
									onClick={() => {
										setSteps(1);
									}}
								>
									Regresar
								</Button>
								<Button
									onClick={() => {
										setSteps(3);
									}}
									disabled={handleVerifyDisabledButton.firstStep()}
									outboundColor
								>
									Siguiente
								</Button>
							</ButtonContainer>
						</>
					)}

					{steps === 3 && (
						<ResumeContainer>
							<OutboundResume transferResume={codeResume} />
							<SendCode
								value={state.codeOtp}
								name="codeOtp"
								handleChangeInput={handleChangeInput}
								handleVerifyDisabledButton={handleVerifyDisabledButton.thirdStep}
								handleContinueSendCode={handleContinueSendCode}
								handleContinue={handleContinue}
							/>
						</ResumeContainer>
					)}

					{steps === 4 && (
						<ResumeContainer>
							<OutboundResume transferResume={userResume} />
							<OutboundResume transferResume={bankResume} />
							<OutboundResume transferResume={dateResume} />
						</ResumeContainer>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export default ModalPayouts;
