import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { PaymentLinksIcon } from '../../assets/svg';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { Filters } from '../../models/table';
import { PaymentLinksMultiuseServices } from '../../services/payment-links-multiuse';
import { validatePermission } from '../../utils/common';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { NewSinglePayment } from './components/new-single-payment';
import { PaymentLinkTable } from './components/table';
import {
	ButtonsContainer,
	HeadContainer,
	NewPaymentLinkButton,
	TableFilterHeader,
	TitlePage,
} from './styles';

const customDefaultFilters: Filters = {
	...defaultFilters,
	typeSelected: '',
};

const PaymentLinksBeta = () => {
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(customDefaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);
	const [modal, setModal] = useState(false);
	const {
		data: table,
		isFetching,
		isFetched,
		isLoading,
		refetch,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.paymentLinksMultiuse.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			PaymentLinksMultiuseServices.getMultiuseTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					typeSelected: filters.typeSelected,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(customDefaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	const onClose = () => {
		setModal(false);
	};

	const handleShowModal = () => {
		setModal(true);
	};

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.payment_links.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper title="Payment Links" isLoading={isFetching || isLoading}>
			<>
				{modal && validatePermission('PL_NEW') && (
					<NewSinglePayment
						show={modal}
						onClose={onClose}
						viewType="create-payment"
						refetch={refetch}
					/>
				)}

				<HeadContainer>
					<TitlePage>
						<PaymentLinksIcon /> Payment Links
					</TitlePage>

					<ButtonsContainer>
						<NewPaymentLinkButton onClick={() => handleShowModal()}>
							Nuevo cobro
						</NewPaymentLinkButton>
					</ButtonsContainer>
				</HeadContainer>

				<TableFilterHeader>
					<DateFilter
						filters={filters}
						onFilterChange={setFilters}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</TableFilterHeader>

				<PaymentLinkTable
					data={table?.tableInfo || []}
					isLoading={isFetching && !isFetched}
					stateList={table?.typeOfStatus || []}
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
					totalResults={total}
					refetch={refetch}
				/>
			</>
		</ViewWrapper>
	);
};

export { PaymentLinksBeta };
