import { Graphics, RANGE_DATES, Statistics } from '../models/home';
import { client } from '../utils/api';

const getStatistics = async (
	merchantCode?: string,
	enterpriseCode?: number,
	interval?: RANGE_DATES,
) => {
	try {
		const params = Object();
		if (!enterpriseCode) params.merchant_code = merchantCode;
		if (enterpriseCode) params.enterprise_id = enterpriseCode;
		if (interval) params.interval = interval;

		const { data } = await client.get<Statistics>('/v1/home/statistics', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getGraphics = async (merchantCode: string, enterpriseCode?: number) => {
	try {
		const params = Object();
		if (!enterpriseCode) params.merchant_code = merchantCode;
		else params.enterprise_id = enterpriseCode;

		const { data } = await client.get<Graphics>('/v1/home/graphics', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const HomeServices = {
	getStatistics,
	getGraphics,
};
