import { GraphicsFormat } from './common-payments';

export interface BanksSuccessPayment {
	[key: string]: string;
}

export interface AmountCollected {
	value: string;
	resume: string;
	icon: number;
}

export interface Head {
	amountCollected: AmountCollected;
	convertion: AmountCollected;
	success: AmountCollected;
	successToReview?: AmountCollected;
}

export interface MerchantInfo {
	date: string;
	timeZone: string;
	currency: string;
}

export interface StatusData {
	success: number;
	expired: number;
	pending: number;
	error: number;
	others: number;
}

export interface NoticeTransaction {
	count_month_trx: number;
	max_month_trx: number;
}

export interface Statistics {
	banksSuccessPayment: BanksSuccessPayment;
	head?: Head;
	merchantInfo: MerchantInfo;
	statusData: StatusData;
	notice_trx: NoticeTransaction;
}

export type RANGE_DATES = 'TODAY' | 'THIS_WEEK' | 'THIS_MONTH' | 'LAST_14_DAYS' | 'LAST_30_DAYS';

export interface Graphics {
	conversion: GraphicsFormat[];
	amounts: GraphicsFormat[];
}

export enum ResumeCardType {
	AMOUNT = 'Monto recaudado',
	SUCCESSFUL = 'Cobros exitosos',
	PENDING = 'Cobros por revisar',
	CONVERSION = 'Conversión',
	NEWS_SUBSCRIPTIONS = 'Suscripciones Nuevas',
	ENABLED_SUBSCRIPTIONS = 'Suscripciones Activas',
}
