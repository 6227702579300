import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { ETPayButton } from '../../components/button';
import { PlanSection } from '../../components/plan';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { SubscriptionService } from '../../services/subscription';
import { ModalManagePlan } from './components/modal';
import {
	BodyWrapper,
	CancelPlanContainer,
	EnterpriseContainer,
	EnterpriseDescription,
	EnterpriseTitle,
	EnterpriseWrapper,
	TitlePage,
} from './styles';

interface ModalProps {
	isOpen: boolean;
	planId?: number;
}

const defaultModal: ModalProps = {
	isOpen: false,
	planId: undefined,
};

const ManagePlan = () => {
	const { merchantSelected, handleGetEnterpriseSelected } = useMerchant();
	const [modal, setModal] = useState(defaultModal);
	const enterprise = handleGetEnterpriseSelected();

	const { data } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.config.subscription.getPlans, merchantSelected?.code],
		queryFn: () =>
			SubscriptionService.getPlans(merchantSelected?.country as string, enterprise?.id as number),
	});

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const showModal = (planId: number) => {
		setModal({ isOpen: true, planId });
	};

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.manage_plan.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<>
			{modal.isOpen && modal.planId && (
				<ModalManagePlan
					show={modal.isOpen}
					handleCloseModal={handleCloseModal}
					planId={modal.planId}
				/>
			)}

			<ViewWrapper title="Administrar plan">
				<TitlePage>Administrar plan</TitlePage>

				<BodyWrapper>
					<PlanSection
						planName="Basic"
						data={data}
						showModal={() => showModal(data?.find((plan) => plan.title === 'Basic')?.id as number)}
					/>
					<PlanSection
						planName="Starter"
						data={data}
						showModal={() =>
							showModal(data?.find((plan) => plan.title === 'Starter')?.id as number)
						}
					/>
					<PlanSection
						planName="Plus"
						data={data}
						showModal={() => showModal(data?.find((plan) => plan.title === 'Plus')?.id as number)}
					/>
				</BodyWrapper>

				<EnterpriseContainer>
					<EnterpriseWrapper>
						<EnterpriseTitle>Enterprise</EnterpriseTitle>
						<EnterpriseDescription>
							Diseñado a la medida de tu negocio. Para grandes volúmenes de pago y transacciones de
							altos montos.
						</EnterpriseDescription>
					</EnterpriseWrapper>

					<EnterpriseWrapper>
						<ETPayButton
							title={
								data?.find((plan) => plan.title === 'Enterprise')?.selected
									? 'Plan actual'
									: 'Contactar con ventas'
							}
							variant="effect"
							onClick={() =>
								showModal(data?.find((plan) => plan.title === 'Enterprise')?.id as number)
							}
							disabled={data?.find((plan) => plan.title === 'Enterprise')?.selected}
						/>
					</EnterpriseWrapper>
				</EnterpriseContainer>

				<CancelPlanContainer>
					<ETPayButton
						variant="default"
						title="Cancelar plan"
						color="text"
						onClick={() => showModal(-1)}
					/>
				</CancelPlanContainer>
			</ViewWrapper>
		</>
	);
};

export { ManagePlan };
