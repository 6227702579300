import { useTheme } from 'styled-components';

import { LeftArrowIcon, RightArrowIcon } from '../../../../assets/svg';
import { useMerchant } from '../../../../hooks/useMerchant';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { CountriesAllowed } from '../../../../models/merchant';
import { ETPAyPaginationProps } from '../../../../models/table';
import { formatNumber } from '../../../../utils/numbers';
import { Options, Select } from '../../../form/select';
import {
	PaginationButton,
	PaginationButtonsContainer,
	PaginationContainer,
	PaginationText,
	PaginationTextContainer,
	PaginationTextNumber,
	SelectContainer,
} from './styles';

const ETPAyPagination = ({
	totalResults,
	pagination,
	isLoading,
	hasPaginationSelect,
	setPagination,
}: ETPAyPaginationProps) => {
	const { width } = useScreenSize();
	const { breakpoints } = useTheme();
	const { merchantSelected } = useMerchant();
	const isFirstPage = pagination?.page === 1;
	const currentSize = pagination && pagination?.page * pagination?.size;
	const hasNotNextPage = currentSize && currentSize >= totalResults;

	const handleNextPage = () => {
		setPagination &&
			pagination &&
			setPagination({ ...pagination, page: pagination.page + 1, size: pagination.size });
	};

	const handlePreviousPage = () => {
		setPagination &&
			pagination &&
			setPagination({ ...pagination, page: pagination.page - 1, size: pagination.size });
	};

	const options = [
		{ label: '10 resultados', value: 10, show: true },
		{ label: '25 resultados', value: 25, show: true },
		{ label: '50 resultados', value: 50, show: true },
		{ label: '100 resultados', value: 100, show: true },
	];

	const handleSetPagination = (option: Options) => {
		setPagination && setPagination({ ...pagination, page: 1, size: +option.value });
	};

	return (
		<PaginationContainer hasSelect={hasPaginationSelect}>
			{width > +breakpoints.md.split('px')[0] && hasPaginationSelect && (
				<SelectContainer>
					{pagination && totalResults > pagination?.size && (
						<Select
							options={options}
							orientation="top"
							onChange={handleSetPagination}
							defaultOption={pagination?.size as number}
						/>
					)}
				</SelectContainer>
			)}

			<PaginationTextContainer>
				<PaginationText>
					{isLoading ? (
						<span>Cargando...</span>
					) : (
						<>
							Mostrando
							<PaginationTextNumber>
								{formatNumber({
									value: hasNotNextPage ? totalResults : (currentSize as number),
									country: merchantSelected?.country as CountriesAllowed,
								})}
							</PaginationTextNumber>
							de
							<PaginationTextNumber>
								{formatNumber({
									value: totalResults as number,
									country: merchantSelected?.country as CountriesAllowed,
								})}
							</PaginationTextNumber>
						</>
					)}
				</PaginationText>

				{(!isFirstPage || !hasNotNextPage) && (
					<PaginationButtonsContainer>
						{!isFirstPage && (
							<PaginationButton onClick={handlePreviousPage}>
								<LeftArrowIcon />
							</PaginationButton>
						)}

						{!hasNotNextPage && (
							<PaginationButton onClick={handleNextPage}>
								<RightArrowIcon />
							</PaginationButton>
						)}
					</PaginationButtonsContainer>
				)}
			</PaginationTextContainer>
		</PaginationContainer>
	);
};
export { ETPAyPagination };
