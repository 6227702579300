export interface SpinnerCircularProps {
	brandColor?: string;
	size?: number;
}

export const SpinnerCircularIcon = ({
	brandColor = '#140f33',
	size = 56,
	...rest
}: SpinnerCircularProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
			{...rest}
		>
			<circle
				cx="50"
				cy="50"
				r="30"
				stroke={brandColor}
				opacity="0.1"
				strokeWidth="8"
				fill="none"
			></circle>
			<circle
				cx="50"
				cy="50"
				r="30"
				stroke={brandColor}
				strokeWidth="8"
				strokeLinecap="round"
				fill="none"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					repeatCount="indefinite"
					dur="1.923076923076923s"
					values="0 50 50;180 50 50;720 50 50"
					keyTimes="0;0.5;1"
				></animateTransform>
				<animate
					attributeName="stroke-dasharray"
					repeatCount="indefinite"
					dur="1.923076923076923s"
					values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
					keyTimes="0;0.5;1"
				></animate>
			</circle>
		</svg>
	);
};
