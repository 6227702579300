export const RightIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 15" fill="none">
		<path
			d="M5.83301 11L9.33301 7.5L5.83301 4"
			stroke="#B7B7B7"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
