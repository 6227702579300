import { SVGProps } from 'react';

export const LoaderEtpayLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="35"
		height="35"
		viewBox="0 0 94 94"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M46.8332 16.5249C42.4119 16.5203 38.0436 17.48 34.0372 19.3361C30.0308 21.1922 26.4841 23.8994 23.6479 27.2662C20.8118 30.6331 18.7554 34.5773 17.6243 38.82C16.4931 43.0627 16.3148 47.5003 17.1019 51.819H76.5645C76.8781 50.0714 77.0372 48.3 77.0402 46.5249C77.0415 42.5861 76.2612 38.6857 74.7438 35.0463C73.2264 31.4069 71.0017 28.0999 68.1966 25.3141C65.3916 22.5283 62.0612 20.3182 58.3956 18.8102C54.7301 17.3021 50.8011 16.5256 46.8332 16.5249ZM27.8336 41.3799C28.97 37.243 31.4454 33.5917 34.8782 30.9889C38.3111 28.386 42.511 26.9759 46.8307 26.9759C51.1504 26.9759 55.3503 28.386 58.7832 30.9889C62.216 33.5917 64.6914 37.243 65.8278 41.3799H27.8336Z"
			fill="#72DBAA"
		/>
		<path
			d="M46.828 66.056C41.2793 66.056 35.8719 63.6937 32.1502 59.5539L24.303 66.5133C27.1377 69.6626 30.6103 72.1823 34.4936 73.9075C38.377 75.6328 42.5835 76.5247 46.838 76.5249C47.4951 76.5249 48.1749 76.6028 48.8211 76.4603C56.5625 75.9739 64.1736 72.2877 69.3681 66.5381L61.5259 59.5787C57.8202 63.6543 52.3311 66.056 46.828 66.056Z"
			fill="#72DBAA"
		/>
	</svg>
);
