import { GetInfoResponse, SaveContactParams } from '../models/contacts';
import { client } from '../utils/api';

const getContactInformation = async (merchantCode: string) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<GetInfoResponse>('/v1/settings/contact-info', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const saveContact = async ({ merchantCode, body, type }: SaveContactParams) => {
	try {
		const params = { merchant_code: merchantCode, type: type.toUpperCase() };
		const { data } = await client.patch('/v1/settings/account-info', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const ContactServices = {
	getContactInformation,
	saveContact,
};
