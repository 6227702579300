import styled from 'styled-components';

export const TitlePage = styled.h1`
	font-size: 32px;
	padding-bottom: 16px;
`;

export const Container = styled.div`
	display: flex;
	gap: 32px;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const DetailTitle = styled.div`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	padding-bottom: 24px;
`;

interface InfoUpdateProps {
	readonly hasError?: boolean;
}

export const InfoUpload = styled.div<InfoUpdateProps>`
	color: ${({ theme, hasError }) =>
		hasError ? theme.colors.extra.sword : theme.colors.principal.moon};
	font-size: 12px;
	line-height: 18px;
`;

export const ContainerButton = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 32px;
`;

export const PreviewContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 25px 0 52px;
`;
