import { SVGProps } from 'react';

export const WhatsAppIcon = ({ color = '#140F33' }: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19.0914 4.90117C17.2151 3.03127 14.7186 2.00094 12.0611 2C6.58285 2 2.12461 6.4375 2.12225 11.8917C2.12131 13.6155 2.57351 15.313 3.42928 16.8033L2 22L7.34045 20.6056C8.78247 21.3812 10.4124 21.7914 12.0611 21.7919C17.5389 21.7919 21.9976 17.3544 21.9995 11.8997C22.0009 9.25645 20.9682 6.77108 19.0914 4.90117ZM12.0578 19.9492C10.6068 19.9487 9.18322 19.5607 7.94133 18.8273L7.60855 18.6309L4.63199 19.408L5.4264 16.52L5.20974 16.177C4.40023 14.8953 3.97258 13.4139 3.97305 11.8931C3.97494 7.45467 7.60431 3.8436 12.064 3.8436C14.2239 3.84453 16.255 4.6827 17.782 6.20447C19.309 7.72623 20.1497 9.74883 20.1487 11.8997C20.1468 16.3386 16.5189 19.9497 12.0611 19.9497V20.0436L12.0578 19.9492Z"
			fill={color}
		/>
		<path
			d="M17.1051 14.1812L17.091 14.1577L14.9145 13.063L14.9041 13.0587C14.704 12.9864 14.4552 12.8962 14.24 13.2175C14.0894 13.4431 13.6471 13.9646 13.4687 14.168C13.3658 14.285 13.277 14.3207 13.0491 14.207C13.0066 14.1859 12.9514 14.1619 12.882 14.1318C12.527 13.9787 11.8667 13.6935 11.0916 13.0056C10.3958 12.3878 9.91579 11.6295 9.74208 11.3326C9.63824 11.1555 9.70621 11.0747 9.83507 10.9469C9.91201 10.8703 9.99981 10.7641 10.0843 10.6617C10.1263 10.6109 10.1683 10.5597 10.2089 10.5128C10.3213 10.3821 10.3708 10.2816 10.4336 10.1543C10.4445 10.1322 10.4558 10.1096 10.4676 10.0852C10.5738 9.87379 10.5068 9.69103 10.4473 9.57216C10.4076 9.49323 10.1612 8.89561 9.96299 8.41592C9.8582 8.16268 9.75955 7.9226 9.69158 7.76051C9.50655 7.31794 9.30027 7.28035 9.1058 7.28317C9.08881 7.28317 9.07181 7.28364 9.05577 7.2827C8.89953 7.27518 8.71922 7.27377 8.5795 7.27377C8.45441 7.27377 8.13344 7.30666 7.854 7.61064C7.83984 7.62614 7.82379 7.64306 7.80633 7.66138C7.55568 7.92777 6.96802 8.5517 6.96802 9.7164C6.96802 10.8266 7.68219 11.895 7.99136 12.3051C7.99891 12.3155 8.01307 12.3352 8.03242 12.3634C8.73007 13.3594 10.2032 15.1607 12.2315 16.0323C12.7975 16.2756 13.244 16.4255 13.6424 16.5514C14.0007 16.6646 14.3344 16.7004 14.6242 16.7004C14.8602 16.7004 15.0674 16.6769 15.2345 16.652C15.7047 16.582 16.7459 16.0506 16.9697 15.4267C17.1637 14.8826 17.1967 14.3338 17.1051 14.1812Z"
			fill={color}
		/>
	</svg>
);
