import { KeyboardEvent, useState } from 'react';

import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { EatmServices } from '../../../../services/eatm';
import { errorFormatter } from '../../../../utils/errors';
import { onlyNumberRegex } from '../../../../utils/validations';
import { Button, InputGroup, ModalContainer, ModalFooter, Subtitle } from './styles';

interface UpdateCashProps {
	show: boolean;
	onClose: () => void;
	defaultAmountValue?: string;
	defaultMaxAmountValue?: string;
}

const UpdateCash = ({
	show,
	onClose,
	defaultAmountValue,
	defaultMaxAmountValue,
}: UpdateCashProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [loading, setLoading] = useState(false);

	const splitInputValue = {
		amountValue: () => defaultAmountValue?.split('$')[1] || '',
		maxAmountValue: () => defaultMaxAmountValue?.split('$')[1] || '',
	};

	const [amount, setAmount] = useState(splitInputValue.amountValue);
	const [maxAmount, setMaxAmount] = useState(splitInputValue.maxAmountValue);

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegex.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const handleChangeInput = {
		amount: (e: any) => setAmount(e.target.value),
		maxAmount: (e: any) => setMaxAmount(e.target.value),
	};

	const handleCloseModal = () => {
		setAmount('');
		setMaxAmount('');
		setLoading(false);
		onClose();
	};

	const handleContinueAction = async () => {
		setLoading(true);
		try {
			await EatmServices.updateCashAllowed({
				merchantCode: merchantSelected?.code as string,
				cashAllowed: amount,
				maxCashAllowed: maxAmount,
			});
			handleShowAlert({
				message: 'Cantidad disponible actualizada correctamente.',
				type: 'success',
			});
			handleCloseModal();
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal title="Configuración" show={show} onClose={handleCloseModal}>
			<ModalContainer>
				<Subtitle>
					Controla los retiros de acuerdo a la cantidad de efectivo disponible en tu comercio.
				</Subtitle>
				<InputGroup>
					<Input
						label="Efectivo disponible"
						name="amount"
						variant="large"
						value={amount}
						prefixSymbol="$"
						onChange={handleChangeInput.amount}
						onKeyDown={handleKeyDownValidateAmount}
					/>
					<Input
						label="Monto máximo por retiro "
						name="maxAmount"
						variant="large"
						value={maxAmount}
						prefixSymbol="$"
						onChange={handleChangeInput.maxAmount}
						onKeyDown={handleKeyDownValidateAmount}
					/>
				</InputGroup>
				<ModalFooter>
					<Button onClick={handleContinueAction} disabled={!amount || loading}>
						{loading ? 'Actualizando...' : ' Actualizar'}
					</Button>
				</ModalFooter>
			</ModalContainer>
		</Modal>
	);
};

export { UpdateCash };
