import styled from 'styled-components';

export const TabsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const TabsHeader = styled.ul`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
`;

interface TabTitleProps {
	readonly $active?: boolean;
}

export const TabTitleContainer = styled.li<TabTitleProps>`
	align-items: center;
	background-color: ${({ theme, $active }) =>
		$active ? theme.colors.principal.pure : 'rgba(20 15 51 / 5%)'};
	border: 1px solid
		${({ theme, $active }) => ($active ? theme.colors.principal.ceres : 'transparent')};
	border-bottom-color: ${({ theme, $active }) =>
		$active ? 'transparent' : theme.colors.principal.ceres};
	color: ${({ theme }) => theme.colors.principal.orion};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	font-weight: 400;
	justify-content: center;
	line-height: 18px;
	padding: 12px 24px 16px;
	text-align: center;
	transition: background-color 0.2s ease-in-out;
	width: 100%;

	&:first-of-type {
		border-top-left-radius: 12px;
	}

	&:last-of-type {
		border-top-right-radius: 12px;
	}
`;

export const TabBody = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	border-left: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-right: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	padding: 32px 16px;
	width: 100%;
`;
