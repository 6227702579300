import { FC, lazy, LazyExoticComponent } from 'react';

import { Options } from '../components/form/select';
import { CommonTableParams } from '../models/common-payments';
import { Filters } from '../models/table';
import { Permissions, RolesAllowed, RolesOptions } from '../models/users';
import { transformDate } from './dates';

export const lazyComponent = (
	name: string,
	importer: Promise<Record<string, FC>>,
): LazyExoticComponent<FC> =>
	lazy(async () => {
		const component = await importer;
		return { default: component[name] };
	});

export const encode = (text: string) => window.btoa(encodeURIComponent(text));
export const decode = (text: string) => decodeURIComponent(window.atob(text));

export const formatBytes = (bytes: number, decimals = 2) => {
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

export const getPrefixPhoneCountryCode = (country?: string) => (country === 'MX' ? '+52' : '+56');

export const tableParamsConstructor = (params: Readonly<CommonTableParams>) => {
	const { merchantCode, pagination, filters } = params;
	const { page, size } = pagination;

	const clearParams = Object();
	if (merchantCode) clearParams['merchant_code'] = merchantCode;
	if (filters?.dateRange?.from) clearParams['from'] = transformDate(filters?.dateRange.from);
	if (filters?.dateRange?.to) clearParams['to'] = transformDate(filters?.dateRange.to);
	if (filters?.stateSelected) clearParams['status'] = filters?.stateSelected;
	if (filters?.preSessionStateSelected)
		clearParams['status_presession'] = filters?.preSessionStateSelected;
	if (filters?.search) clearParams['searcher'] = filters?.search;
	if (page) clearParams['page'] = page;
	if (size) clearParams['size'] = size;
	if (filters?.productSelected) clearParams['product'] = filters?.productSelected;
	if (filters?.key) clearParams['key'] = filters?.key;
	if (filters?.orderBy) clearParams['orderBy'] = filters?.orderBy.toLocaleUpperCase();

	return clearParams;
};

interface ExportParamsConstructor extends Pick<Filters, 'dateRange' | 'search' | 'stateSelected'> {
	merchantCode: string;
}

export const exportParamsConstructor = (params: Readonly<ExportParamsConstructor>) => {
	const { merchantCode, dateRange, search, stateSelected } = params;

	const clearParams = Object();
	if (merchantCode) clearParams['merchant_code'] = merchantCode;
	if (dateRange?.from) clearParams['from'] = transformDate(dateRange.from);
	if (dateRange?.to) clearParams['to'] = transformDate(dateRange.to);
	if (search) clearParams['searcher'] = search;
	if (stateSelected) clearParams['status'] = stateSelected;

	return clearParams;
};

export const hexaValidation = (hex: string): boolean => {
	const regex = new RegExp(/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Faf0-9]{4})$/);
	return regex.test(hex);
};

export const validateRoleId = (role: RolesAllowed) => {
	return RolesOptions.find((_role) => _role.label === role);
};

export const validatePermission = (permission: Permissions) => {
	const permissions: Permissions[] = [
		'ALL_PAYMENTS_EXPORT',
		'ALL_PAYMENTS_READ',
		'ANALYTICS',
		'ATM_EXPORT',
		'ATM_NEW',
		'ATM_READ',
		'ATM_RESEND',
		'ATM_UPDATE',
		'CONCILIATION_EXPORT',
		'CONCILIATION_READ',
		'PAYOUTS_GENERATE',
		'PAYOUTS_READ',
		'PAYOUTS_EXPORTS',
		'PAYOUTS_DEPOSIT',
		'PL_CREATE_BATCH',
		'PL_EXPORT',
		'PL_MULTIUSE_EDIT',
		'PL_MULTIUSE_END',
		'PL_MULTIUSE_EXPORT',
		'PL_MULTIUSE_LINK',
		'PL_MULTIUSE_NEW',
		'PL_MULTIUSE_NEW_SESSION',
		'PL_MULTIUSE_READ',
		'PL_MULTIUSE_SMS',
		'PL_NEW',
		'PL_READ',
		'PL_SEND_SMS',
		'PL_SHARE_LINK',
		'QT_EXPORT',
		'QT_READ',
		'BT_EXPORT',
		'BT_READ',
		'TNB_EXPORT',
		'TNB_READ',
		'REFUNDS_EXPORT',
		'SETTINGS_ACCOUNT_INFO_PUT',
		'SETTINGS_ACCOUNT_INFO_READ',
		'SETTINGS_CONTACT_INFO_PUT',
		'SETTINGS_CONTACT_INFO_READ',
		'SETTINGS_PERSONALIZATION_PUT',
		'SETTINGS_PERSONALIZATION_READ',
		'SETTINGS_USERS_CHANGE_ROLE',
		'SETTINGS_USERS_DELETE',
		'SETTINGS_USERS_INVITE',
		'SETTINGS_USERS_INVITE_DELETE',
		'SETTINGS_USERS_INVITE_RESEND',
		'SETTINGS_USERS_LIST',
	];

	return permissions.includes(permission);
};

export const reduceRoles = (roleId: number, isEtpay?: boolean) => {
	if (roleId !== 1 && !isEtpay) {
		return RolesOptions.reduce(
			(acc: Options[], item: Options) =>
				item.label !== 'ETpay' && item.label !== 'Devoluciones' && item.show ? [...acc, item] : acc, // TODO: Exclude Devoluciones role in the future
			[],
		);
	}

	return RolesOptions.reduce(
		(acc: Options[], item: Options) =>
			item.label !== 'Devoluciones' && item.show ? [...acc, item] : acc, // TODO: Exclude Devoluciones role in the future
		[],
	);
};

export const createRoleOptions = (roleId: number, isEtpay?: boolean) => {
	const roleOptions: Options[] = [{ label: 'Selecciona un rol', value: '', show: false }];

	return roleOptions.concat(reduceRoles(roleId, isEtpay));
};

export const getRoleIdByLabel = (label: string, roleId: number) =>
	createRoleOptions(roleId).find((option) => option.label === label)?.value as number;

export const getRoleLabelById = (id: number, roleId: number, isEtpay?: boolean): RolesAllowed =>
	createRoleOptions(roleId, isEtpay).find((option) => option.value === id)?.label as RolesAllowed;

export function validateEmail(email: string) {
	const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
	return regex.test(email);
}
