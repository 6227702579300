import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { OutboundIcon } from '../../assets/svg/OutboundIcon';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { CountriesAllowed } from '../../models/merchant';
import { PayoutsService } from '../../services/payouts';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { formatNumber } from '../../utils/numbers';
import ModalOutbound from './components/modal';
import { NewBatchPayment } from './components/new-batch-payment';
import { OutboundTable } from './components/table';
import {
	ButtonHead,
	CardAmount,
	CardGroup,
	CardInfo,
	CardTitle,
	CardValue,
	ContainerButton,
	HeadContainer,
	TableFilterHeader,
	TitlePage,
} from './styles';

export interface Values {
	account: string;
	amount: string;
	bank: { name: string; code: string };
	codeOtp: string;
	email: string;
	loading: boolean;
	message: string;
	name: string;
	reference: string;
	rut: string;
	token: string;
	requestDate: string;
	merchantId: number;
	save: boolean;
}

export const defaultValues: Values = {
	account: '',
	amount: '',
	bank: { name: '', code: '' },
	codeOtp: '',
	email: '',
	loading: false,
	merchantId: 0,
	message: '',
	name: '',
	reference: '',
	requestDate: '',
	rut: '',
	save: false,
	token: '',
};

const PayoutsChile = () => {
	const { merchantSelected } = useMerchant();
	const { width } = useScreenSize();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState<number>(0);
	const [show, setShow] = useState<boolean>(false);
	const [showMultiPayout, setShowMultiPayout] = useState<boolean>(false);
	const [state, setState] = useState<Values>(defaultValues);
	const [balance, setBalance] = useState('0');
	const [stats, setStats] = useState({ total: 0, totalAmount: '' });
	const customDate = {
		today: new Date(),
		pastDate: new Date(
			new Date().getTime() -
				(merchantSelected ? merchantSelected.query_days_back : 30) * 24 * 60 * 60 * 1000,
		),
	};

	const {
		data: table,
		isFetching,
		isFetched,
		refetch,
		isLoading,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.payoutsChile.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			PayoutsService.getAllOutbound({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from || customDate.pastDate,
						to: filters?.dateRange?.to || customDate.today,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	const { isLoading: isLoadingBalance } = useQuery({
		enabled: !!merchantSelected,
		queryKey: [CONSTANTS.services.payoutsChile.getBalance, merchantSelected?.code],
		queryFn: () => PayoutsService.getBalance({ merchantCode: merchantSelected?.code as string }),
		onSuccess: (response) => {
			if (response) {
				setBalance(
					formatNumber({
						value: response.resume.balance as number,
						country: merchantSelected?.country as CountriesAllowed,
					}),
				);
			}
		},
	});

	const { isLoading: isLoadingStats } = useQuery({
		enabled: !!merchantSelected,
		queryKey: [CONSTANTS.services.payoutsChile.getStats, merchantSelected?.code],
		queryFn: () => PayoutsService.getStats({ merchantCode: merchantSelected?.code as string }),
		onSuccess: (response) => {
			if (response) {
				setStats({
					total: response.total,
					totalAmount: formatNumber({
						value: response.total_amount as number,
						country: merchantSelected?.country as CountriesAllowed,
					}),
				});
			}
		},
	});

	const onClose = () => {
		setShow(false);
		setShowMultiPayout(false);
		setState(defaultValues);
		refetch();
	};

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	return (
		<ViewWrapper
			title="Salidas"
			isLoading={isLoading || isFetching || isLoadingBalance || isLoadingStats}
		>
			<HeadContainer>
				<TitlePage>
					<OutboundIcon /> Salidas
				</TitlePage>

				<ContainerButton>
					{width > 576 && (
						<ButtonHead onClick={() => setShowMultiPayout(true)} variant="pure">
							Pagos de salida masivos
						</ButtonHead>
					)}

					<ButtonHead onClick={() => setShow(true)} variant="saturn">
						Nuevo pago de salida
					</ButtonHead>
				</ContainerButton>
			</HeadContainer>

			{width > 576 && (
				<div style={{ display: 'flex', gap: '45px', width: '100%', marginBottom: '27px' }}>
					<CardGroup>
						<CardInfo>
							<CardTitle>En proceso</CardTitle>
							<CardValue>{stats ? stats.total : '0'}</CardValue>
							<CardAmount>${stats ? stats.totalAmount : '0'}</CardAmount>
						</CardInfo>
						<CardInfo>
							<CardTitle>Saldo contable</CardTitle>
							<CardValue>${balance ? balance : '0'}</CardValue>
						</CardInfo>
					</CardGroup>
				</div>
			)}

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			{show && <ModalOutbound onClose={onClose} setState={setState} state={state} />}

			{showMultiPayout && <NewBatchPayment onClose={onClose} show={showMultiPayout} />}

			<OutboundTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				stateList={table?.typeOfStatus || []}
				customDate={customDate}
			/>
		</ViewWrapper>
	);
};

export { PayoutsChile };
