import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';

import {
	BancoBciIcon,
	BancoBiceIcon,
	BancoChinaConstructionIcon,
	BancoConsorcioIcon,
	BancoCoopeuchIcon,
	BancoCopecPayIcon,
	BancoDoBrasilIcon,
	BancoEdwardsIcon,
	BancoEstadoIcon,
	BancoFalabellaIcon,
	BancoGlobal66Icon,
	BancoHsbcIcon,
	BancoInternacionalIcon,
	BancoItauIcon,
	BancoJpMorganIcon,
	BancoLaPolarIcon,
	BancoLosHeroesIcon,
	BancoMercadoPagoIcon,
	BancoOfChinaIcon,
	BancoRabobankIcon,
	BancoRipleyIcon,
	BancoSantanderIcon,
	BancoScotiabankIcon,
	BancoSecurityIcon,
	BancoTappIcon,
	BancoTenpoIcon,
} from '../../../../assets/svg/banks';
import { BancoBtgIcon } from '../../../../assets/svg/banks/bancoBtg';
import Dropdown from '../../../../components/dropdown';
import { Checkbox } from '../../../../components/form/checkbox';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import useRut from '../../../../hooks/useRut';
import { CountriesAllowed } from '../../../../models/merchant';
import { PayoutsService } from '../../../../services/payouts';
import { validateEmail } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { formatNumber } from '../../../../utils/numbers';
import { onlyNumberRegex } from '../../../../utils/validations';
import { Values } from '../..';
import OutboundResume from '../outboundResume';
import SendCode from '../sendCode';
import {
	Button,
	ButtonContainer,
	CheckboxContainer,
	HintInput,
	InputContainer,
	InputContainerWithError,
	ModalContainer,
	ModalStep,
	ModalSubtitle,
	ModalTitle,
	ResumeContainer,
	SelectAndInputContainer,
} from './styles';

interface ModalOutboundProp {
	onClose: () => void;
	setState: Dispatch<SetStateAction<Values>>;
	state: Values;
}

interface TransferResumeItem {
	item: string;
	data: string;
}

const ModalOutbound = ({ onClose, state, setState }: ModalOutboundProp) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const { rut, isValid: rutIsValid, handleRutChange } = useRut();
	const { user } = useAuth();
	const [steps, setSteps] = useState<number>(1);

	const handleVerifyDisabledButton = {
		firstStep: () => {
			if (
				state.amount !== '' &&
				state.message !== '' &&
				rutIsValid &&
				state.name !== '' &&
				state.email !== '' &&
				validateEmail(state.email) &&
				state.bank.name !== ''
			)
				return false;
			return true;
		},
		thirdStep: () => {
			if (state.codeOtp.length === 6) return false;
			return true;
		},
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleContinue = async (step: number) => {
		setState({ ...state, loading: true, rut: rut });
		try {
			const response = await PayoutsService.generateOutbound({
				merchantCode: merchantSelected?.code as string,
				amount: +state.amount,
				messageToAddressee: state.message,
				rut: rut.split('.').join(''),
				name: state.name,
				email: state.email,
				bankAccount: state.account,
				bankSBIFNumber: state.bank.code,
				reference: state.reference,
			});
			setState((prevState) => ({
				...prevState,
				token: response.token,
				loading: false,
				reference: state.reference || response.reference,
			}));
			setSteps(step);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setState({ ...state, loading: false });
		}
	};

	const handleContinueSendCode = async () => {
		setState((state) => ({ ...state, loading: true }));
		try {
			const response = await PayoutsService.sendCodeOutbound({
				merchantCode: merchantSelected?.code as string,
				code: state.codeOtp,
				token: state.token,
			});

			if (state.save) {
				const save = await PayoutsService.saveBeneficiary({
					merchantCode: merchantSelected?.code as string,
					beneficiary: {
						rut: state.rut,
						name: state.name,
						email: state.email,
						sbif: state.bank.code,
						account: state.account,
						merchantId: state.merchantId,
					},
				});
				save.code === 'S_OK' &&
					handleShowAlert({
						message: 'El beneficiario se ha guardado con éxito',
						type: 'success',
					});
			}

			setState({ ...state, requestDate: response.resume.requestDate });
			setSteps(4);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setState((state) => ({ ...state, loading: false }));
		}
	};

	const userResume: TransferResumeItem[] = [
		{
			item: 'Rut',
			data: state.rut,
		},
		{
			item: 'Nombre beneficiario',
			data: state.name,
		},
		{
			item: 'Correo beneficiario',
			data: state.email,
		},
	];

	const bankResume: TransferResumeItem[] = [
		{
			item: 'Banco',
			data: state.bank.name,
		},
		{
			item: 'Número de cuenta',
			data: state.account,
		},
		{
			item: 'Monto',
			data: `$${formatNumber({
				value: +state.amount as number,
				country: merchantSelected?.country as CountriesAllowed,
			})}`,
		},
		{
			item: 'Mensaje',
			data: state.message,
		},
	];

	const referenceResume: TransferResumeItem[] = [
		{
			item: 'Referencia única',
			data: state.reference,
		},
	];

	const codeResume: TransferResumeItem[] = [
		{
			item: 'Correo enviado a',
			data: user?.email ? user.email : '',
		},
	];

	const dateResume: TransferResumeItem[] = [
		{
			item: 'Referencia única',
			data: state.reference,
		},
		{
			item: 'Fecha de solicitud',
			data: state.requestDate,
		},
	];

	const handleKeyDownValidateNumber = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegex.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const paymentTypeOptions = [
		{
			label: 'Banco BCI/MACH',
			value: '016',
			show: true,
			icon: <BancoBciIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Bice',
			value: '028',
			show: true,
			icon: <BancoBiceIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Consorcio',
			value: '053',
			show: true,
			icon: <BancoConsorcioIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco de Chile - Edwards Citi',
			value: '001',
			show: true,
			icon: <BancoEdwardsIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco del Estado de Chile',
			value: '012',
			show: true,
			icon: <BancoEstadoIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Falabella',
			value: '051',
			show: true,
			icon: <BancoFalabellaIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Internacional',
			value: '009',
			show: true,
			icon: <BancoInternacionalIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Itaú',
			value: '039',
			show: true,
			icon: <BancoItauIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Ripley',
			value: '055',
			show: true,
			icon: <BancoRipleyIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Santander-Banefe',
			value: '037',
			show: true,
			icon: <BancoSantanderIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco Security',
			value: '049',
			show: true,
			icon: <BancoSecurityIcon width="18px" height="18px" />,
		},
		{
			label: 'Coopeuch',
			value: '672',
			show: true,
			icon: <BancoCoopeuchIcon width="18px" height="18px" />,
		},
		{
			label: 'CopecPay',
			value: '741',
			show: true,
			icon: <BancoCopecPayIcon width="18px" height="18px" />,
		},
		{
			label: 'Global66',
			value: '738',
			show: true,
			icon: <BancoGlobal66Icon width="18px" height="18px" />,
		},
		{ label: 'HSBC', value: '031', show: true, icon: <BancoHsbcIcon width="18px" height="18px" /> },
		{
			label: 'La Polar Prepago',
			value: '697',
			show: true,
			icon: <BancoLaPolarIcon width="18px" height="18px" />,
		},
		{
			label: 'Mercado Pago EmisoraS.A.',
			value: '875',
			show: true,
			icon: <BancoMercadoPagoIcon width="18px" height="18px" />,
		},
		{
			label: 'Prepago Los Héroes',
			value: '729',
			show: true,
			icon: <BancoLosHeroesIcon width="18px" height="18px" />,
		},
		{
			label: 'Rabobank',
			value: '045',
			show: true,
			icon: <BancoRabobankIcon width="18px" height="18px" />,
		},
		{
			label: 'Scotiabank',
			value: '014',
			show: true,
			icon: <BancoScotiabankIcon width="18px" height="18px" />,
		},
		{
			label: 'TAPP Caja LosAndes',
			value: '732',
			show: true,
			icon: <BancoTappIcon width="18px" height="18px" />,
		},
		{
			label: 'Tenpo PrepagoS',
			value: '730',
			show: true,
			icon: <BancoTenpoIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco BTG Pactual Chile',
			value: '059',
			show: true,
			icon: <BancoBtgIcon width="18px" height="18px" />,
		},
		{
			label: 'Banco do Brasil',
			value: '017',
			show: true,
			icon: <BancoDoBrasilIcon width="18px" height="18px" />,
		},
		{
			label: 'Bank of China',
			value: '061',
			show: true,
			icon: <BancoOfChinaIcon width="18px" height="18px" />,
		},
		{
			label: 'China Construction Bank AG Chile',
			value: '061',
			show: true,
			icon: <BancoChinaConstructionIcon width="26px" />,
		},
		{
			label: 'JP Morgan ChaseBank',
			value: '041',
			show: true,
			icon: <BancoJpMorganIcon width="18px" height="18px" />,
		},
	];

	return (
		<Modal title="Pago de salida" show type="medium" onClose={onClose}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					{(steps === 1 || steps === 2 || steps === 3) && <ModalStep>PASO {steps} DE 3</ModalStep>}
					<ModalTitle>
						{steps === 1 && 'Completa la información del pago'}
						{steps === 2 && 'Verifica la información del pago'}
						{steps === 3 && 'Autoriza el pago de salida'}
						{steps === 4 && 'En proceso'}
					</ModalTitle>
					<ModalSubtitle>
						{steps === 3 &&
							'Enviamos un código de seguridad al correo electrónico asociado a tu cuenta, ingrésalo para autorizar la operación.'}
						{steps === 4 &&
							'El pago de salida está en proceso de autorización bancaria, consulta su estatus en la sección “Salidas”.'}
					</ModalSubtitle>

					{steps === 1 && (
						<>
							<InputContainer>
								<InputContainerWithError error={!rutIsValid}>
									<Input
										label="RUT"
										name="rut"
										variant="large"
										value={rut}
										onChange={handleRutChange}
										placeholder="00.000.000-0"
									/>
									{!rutIsValid && <HintInput error>Rut inválido</HintInput>}
								</InputContainerWithError>

								<div>
									<Input
										label="Nombre del beneficiario (máx. 40 caracteres)"
										name="name"
										variant="large"
										value={state.name}
										onChange={handleChangeInput}
										placeholder="Juan Perez"
										maxLength={40}
									/>
								</div>

								<div>
									<Input
										label="Correo electrónico del beneficiario"
										name="email"
										variant="large"
										value={state.email}
										onChange={handleChangeInput}
										placeholder="juanperez@correo.com"
										type="email"
									/>
									<HintInput>Lo usaremos para notificar la confirmación del pago</HintInput>
								</div>

								<div>
									<Input
										label="Monto"
										name="amount"
										variant="large"
										value={state.amount}
										onChange={handleChangeInput}
										prefixSymbol="$"
										placeholder="00"
										onKeyDown={handleKeyDownValidateNumber}
										autoComplete="off"
									/>
								</div>

								<SelectAndInputContainer>
									<Dropdown
										options={paymentTypeOptions}
										onChange={(e: any) => {
											setState({
												...state,
												bank: {
													name: String(e.label),
													code: String(e.value),
												},
											});
										}}
										state={state.bank.name}
										clearState={() =>
											setState((prevState: any) => ({
												...prevState,
												bank: { code: '', name: '' },
											}))
										}
									/>
									<Input
										label="Número de cuenta"
										name="account"
										variant="large"
										value={state.account}
										onChange={handleChangeInput}
										onKeyDown={handleKeyDownValidateNumber}
										autoComplete="off"
									/>
								</SelectAndInputContainer>

								<div>
									<Input
										label="Referencia única (Opcional)"
										name="reference"
										variant="large"
										value={state.reference}
										onChange={handleChangeInput}
									/>
								</div>

								<div>
									<Input
										label="Mensaje (máx. 40 caracteres)"
										name="message"
										variant="large"
										value={state.message}
										onChange={handleChangeInput}
										maxLength={40}
									/>
								</div>
							</InputContainer>

							<Button
								onClick={() => {
									setState({ ...state, rut: rut });
									handleContinue(2);
								}}
								disabled={handleVerifyDisabledButton.firstStep()}
								outboundColor
							>
								Siguiente
							</Button>
						</>
					)}

					{steps === 2 && (
						<>
							<ResumeContainer margin="8px 0 0">
								<OutboundResume transferResume={userResume} />
								<OutboundResume transferResume={bankResume} />
								<OutboundResume transferResume={referenceResume} />
							</ResumeContainer>

							<CheckboxContainer>
								<Checkbox
									name="all"
									onChange={() => {
										setState({
											...state,
											save: !state.save,
											merchantId: merchantSelected?.id as number,
										});
									}}
									checked={state.save}
									label="Guardar beneficiario en mi agenda"
								/>
							</CheckboxContainer>

							<ButtonContainer>
								<Button
									onClick={() => {
										setSteps(1);
									}}
								>
									Regresar
								</Button>
								<Button
									onClick={() => {
										setSteps(3);
									}}
									disabled={handleVerifyDisabledButton.firstStep()}
									outboundColor
								>
									Siguiente
								</Button>
							</ButtonContainer>
						</>
					)}

					{steps === 3 && (
						<ResumeContainer>
							<OutboundResume transferResume={codeResume} />
							<SendCode
								value={state.codeOtp}
								name="codeOtp"
								handleChangeInput={handleChangeInput}
								handleVerifyDisabledButton={handleVerifyDisabledButton.thirdStep}
								handleContinueSendCode={handleContinueSendCode}
								handleContinue={handleContinue}
							/>
						</ResumeContainer>
					)}

					{steps === 4 && (
						<ResumeContainer>
							<OutboundResume transferResume={userResume} />
							<OutboundResume transferResume={bankResume} />
							<OutboundResume transferResume={dateResume} />
						</ResumeContainer>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export default ModalOutbound;
