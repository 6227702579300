import { useTheme } from 'styled-components';

import { CalendarIcon } from '../../../../../assets/svg';
import { SelectIcon } from '../../../../../assets/svg/SelectIcon';
import { TrophyIcon } from '../../../../../assets/svg/TrophyIcon';
import {
	ComingSoonContainer,
	IconContainer,
	PaymentTypeContainer,
	PaymentTypeSubTitle,
	PaymentTypeTitle,
	SelectedIconContainer,
} from '../styles';

interface PaymentTypeProps {
	isDisabled?: boolean;
	comingSoon?: boolean;
	type?: 'unique' | 'recurrent';
	title: string;
	subtitle: string;
	isSelected?: boolean;
	onClick?: () => void;
}

const PaymentType = ({
	isDisabled = false,
	comingSoon = false,
	type,
	title,
	subtitle,
	isSelected = false,
	onClick,
}: PaymentTypeProps) => {
	const { colors } = useTheme();
	return (
		<PaymentTypeContainer isDisabled={isDisabled} isSelected={isSelected} onClick={onClick}>
			<SelectedIconContainer>
				{isDisabled && comingSoon ? (
					<ComingSoonContainer>Próximamente</ComingSoonContainer>
				) : !isSelected ? null : (
					<SelectIcon />
				)}
			</SelectedIconContainer>

			<IconContainer isSelected={isSelected} show={!isDisabled || !comingSoon}>
				{isDisabled && comingSoon ? null : type === 'unique' ? (
					<TrophyIcon
						{...(isSelected
							? { color: colors.principal.orion }
							: { color: colors.principal.vanth })}
					/>
				) : (
					<CalendarIcon
						{...(isSelected
							? { color: colors.principal.orion }
							: { color: colors.principal.vanth })}
					/>
				)}
			</IconContainer>

			<PaymentTypeTitle isSelected={isSelected}>{title}</PaymentTypeTitle>
			<PaymentTypeSubTitle isSelected={isSelected}>{subtitle}</PaymentTypeSubTitle>
		</PaymentTypeContainer>
	);
};

export { PaymentType };
