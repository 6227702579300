import styled from 'styled-components';

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	justify-content: center;

	width: 100%;
`;

export const ModalSubTitle = styled.div`
	color: #140f33;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: center;
`;

export const ContainerBody = styled.div`
	align-items: center;
	align-self: stretch;
	background: #f8f8f8;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: center;
	padding: 12px;
`;

export const FeaturesList = styled.ul`
	align-self: stretch;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 12px;
`;

export const FeaturesListItem = styled.li`
	align-items: center;
	display: flex;
	gap: 8px;

	div {
		height: 24px;
		width: 24px;
	}

	&:not(:last-child) {
		margin-bottom: 6px;
	}
`;

export const OptionContainer = styled.div`
	cursor: pointer;
	display: flex;
	gap: 4px;
`;

export const ContainerFooter = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
`;

export const ButtonBack = styled.div`
	align-items: center;
	background: #fff;
	border: 1px solid #e6e6e6;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	flex: 1 0 0;
	gap: 8px;
	height: 48px;
	justify-content: center;
	padding: 8px 14px;
`;

export const ButtonReject = styled.div`
	align-items: center;
	background: #ff433e;
	border-radius: 8px;
	color: white;
	cursor: pointer;
	display: flex;
	flex: 1 0 0;
	gap: 8px;
	height: 48px;
	justify-content: center;
	padding: 8px 14px;
`;
