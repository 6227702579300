export const MailSuccess = () => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.4 4.58398H17.6C18.5075 4.58398 19.25 5.30586 19.25 6.18815V15.8131C19.25 16.6954 18.5075 17.4173 17.6 17.4173H4.4C3.4925 17.4173 2.75 16.6954 2.75 15.8131V6.18815C2.75 5.30586 3.4925 4.58398 4.4 4.58398Z"
			stroke="#B7B7B7"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.3333 5.5L11 11.9167L3.66663 5.5"
			stroke="#B7B7B7"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle cx="16.9583" cy="16.9577" r="4.54167" fill="#F2F2F2" stroke="#B7B7B7" />
		<path
			d="M18.7776 15.7227L16.3065 18.1937L15.139 17.0336"
			stroke="#B7B7B7"
			strokeWidth="1.5"
			strokeLinecap="square"
		/>
	</svg>
);
