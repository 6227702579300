interface Config {
	base_url: string;
	open_replay_project_key: string;
	env: string;
	socket_connection: string;
}

const config: Config = {
	base_url: process.env.REACT_APP_BASE_URL as string,
	open_replay_project_key: process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY as string,
	env: process.env.NODE_ENV as string,
	socket_connection: process.env.REACT_APP_SOCKET_URL as string,
};

export { config };
