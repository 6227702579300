import styled from 'styled-components';

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

interface ModalTitleProps {
	steps?: number;
}

export const ModalTitle = styled.p<ModalTitleProps>`
	color: #140f33;
	font-size: 20px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: ${({ steps }) => (steps === 2 ? '0' : '8px')};
	text-align: center;
`;

interface ModalSubtitleProp {
	align?: 'start' | 'center' | 'end';
}

export const ModalSubtitle = styled.div<ModalSubtitleProp>`
	color: #646174;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	text-align: ${({ align }) => (align ? align : 'center')};

	a {
		color: ${({ theme }) => theme.colors.principal.orion};
		font-weight: 500;
		text-decoration: underline;
	}
`;

export const List = styled.ul`
	list-style: disc;
	margin-bottom: 16px;
	margin-left: 25px;
	margin-top: 4px;

	li:not(:first-child) {
		margin-top: 4px;
	}
`;

export const ModalStep = styled.p`
	color: #140f33;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.6px;
	line-height: 12px;
	margin-bottom: 2px;
	text-align: center;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

interface HintInputProp {
	error?: boolean;
}

export const HintInput = styled.p<HintInputProp>`
	color: ${({ theme, error }) => (error ? '#FF433E' : theme.colors.principal.moon)};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	width: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
`;

interface ButtonProp {
	outboundColor?: boolean;
}

export const Button = styled.button<ButtonProp>`
	align-items: center;
	background: ${({ theme, outboundColor }) =>
		outboundColor ? theme.colors.extra.saturn : theme.colors.principal.pure};
	border: ${({ outboundColor }) => (outboundColor ? '0' : '1px')} solid
		${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	color: ${({ theme, outboundColor }) =>
		outboundColor ? theme.colors.principal.pure : theme.colors.principal.vanth};
	cursor: pointer;
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	padding: 15px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const DownloadLink = styled.a`
	align-items: center;
	background: ${({ theme }) => theme.colors.extra.saturn};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	cursor: pointer;
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	padding: 15px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}
`;

export const SendEmail = styled.p`
	color: #646174;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	margin-top: 16px;
	text-align: center;
`;

interface ErrorCommon {
	error?: boolean;
}

export const TransferDetail = styled.div<ErrorCommon>`
	background: ${({ error }) => (error ? '#FFE8E8' : '#f8f8f8')};
	border-radius: 12px;
	padding: 12px;
	width: 100%;

	p {
		color: ${({ error }) => error && '#6B1B19'};
	}
`;

export const TransferDetailContainer = styled.div`
	align-items: center;
	border-bottom: 1px solid #f2f2f2;
	display: flex;
	justify-content: space-between;
	padding: 8px 0;

	&:last-child {
		border-bottom: none;
		padding: 8px 0 0;
	}

	&:first-child {
		padding-top: 0;
	}
`;

export const TransferItem = styled.p`
	color: #636172;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

export const TransferData = styled.p`
	color: #140f33;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

export const DragAndDropContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;

interface ResumeContainer {
	readonly margin?: string;
}

export const ResumeContainer = styled.div<ResumeContainer>`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: ${({ margin }) => (margin ? margin : '32px 0 0 0')};
`;

export const CheckboxContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	margin-top: 47px;

	label {
		color: #0f0f0f;
	}
`;

export const CheckText = styled.div`
	color: '#0F0F0F';
	font-size: 14px;
	line-height: 94%;
	margin-top: 6px;
`;

export const InputContainerWithError = styled.div<ErrorCommon>`
	input {
		background-color: ${({ error }) => error && '#FFE8E8'};
		outline: ${({ error }) => error && '1px solid #FF433E'};

		:focus {
			border: ${({ error }) => error && '#FF433E'};
		}
	}
`;

export const SelectAndInputContainer = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
`;
