import { ChartBar } from '../../../../components/graphs/bar';
import { CustomizedTick } from '../../../../components/graphs/components/customized-tick';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { GraphicsFormat } from '../../../../models/common-payments';
import dataLoading from '../../../dataLoading.json';

interface BarGraphProps {
	data?: GraphicsFormat[];
	barColor: string;
	barBackground?: string;
	tooltipColor: string;
}

const dataLoadingMap = dataLoading.fakeDataLoading.map((item) => ({
	x: item.x,
	y: item.y,
	x_formatted: item.x_formatted,
	y_formatted: item.y_formatted,
	y_abbr: item.y_abbr,
}));

const AmountsBarGraph = ({
	data,
	barColor,
	barBackground = '#fcfcfc',
	tooltipColor,
}: BarGraphProps) => {
	const { width } = useScreenSize();
	const hasData = data?.some((item) => +item?.y_formatted.split(' ')[0] !== 0);
	const dataSlice =
		width > 768 ? data : width > 576 ? data?.slice(data.length - 13) : data?.slice(data.length - 8);

	return hasData ? (
		<ChartBar
			data={dataSlice}
			barColor={barColor}
			barBackground={barBackground}
			tooltipColor={tooltipColor}
			customTick={(props) => <CustomizedTick {...props} position="left" symbol="$" />}
			tickCount={6}
			domain={[0, 'dataMax']}
		/>
	) : (
		<ChartBar
			data={dataLoadingMap}
			barColor={'#c6ccd0'}
			barBackground={barBackground}
			customTick={(props) => <CustomizedTick {...props} position="left" symbol="$" />}
			tooltipColor=""
			domain={[]}
		/>
	);
};

export { AmountsBarGraph };
