import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const TextareaElement = styled.textarea`
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 400;
	height: 100%;
	line-height: 12px;
	outline: none;
	padding: 6px 12px;
	resize: none;
	width: 100%;
`;
