import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { PayloadProps, track } from '../../analytics';
import { ANALYTICS } from '../../analytics/constants';
import { ConciliationIcon } from '../../assets/svg';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { Filters } from '../../models/table';
import { ConciliationServices } from '../../services/conciliation';
import { isCurrentMonth } from '../../utils/dates';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { ConciliationTable } from './components/table';
import { HeadContainer, TableFilterHeader, TitlePage } from './styles';

const customDefaultFilters: Filters = {
	...defaultFilters,
};

const Conciliation = () => {
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(customDefaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);

	const dateCustomToFilter = () => {
		const today = new Date();
		const pastDate = new Date(today.getFullYear(), today.getMonth(), 1);
		return { today, pastDate };
	};

	const { data, isFetching, isFetched, isLoading, refetch } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.conciliation.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			ConciliationServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from || dateCustomToFilter().pastDate,
						to: filters?.dateRange?.to || dateCustomToFilter().today,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(customDefaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.conciliation.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper title="Conciliación" isLoading={isLoading || isFetching}>
			<HeadContainer>
				<TitlePage>
					<ConciliationIcon /> Conciliación
				</TitlePage>
			</HeadContainer>

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			<ConciliationTable
				data={data?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				stateList={[{ status: 'success', total: total }]}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
				dateCustomToFilter={dateCustomToFilter}
				refetch={refetch}
				thisMonth={isCurrentMonth(
					filters?.dateRange?.from || dateCustomToFilter().pastDate,
					filters?.dateRange?.to || dateCustomToFilter().today,
				)}
			/>
		</ViewWrapper>
	);
};

export { Conciliation };
