interface MultiPayIconProp {
	disabled?: boolean;
}

export const MultiPayIcon = ({ disabled }: MultiPayIconProp) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="4" fill="#F2F2F2" />
		<path
			d="M18.2222 5H5.77778C4.79594 5 4 5.79594 4 6.77778V19.2222C4 20.2041 4.79594 21 5.77778 21H18.2222C19.2041 21 20 20.2041 20 19.2222V6.77778C20 5.79594 19.2041 5 18.2222 5Z"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 3V7"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 3V7"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4 10H20"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
