import { useTheme } from 'styled-components';

import { ResponseGraphics as Graphics } from '../../../../models/common-payments';
import { AmountsBarGraph } from '../amount-graphics';
import { BanksPieChart } from '../banks-pie-chart';
import {
	HeaderContainer,
	SectionContainer,
	SectionContent,
	SectionDate,
	SectionHeader,
	SectionTitle,
} from './styles';

interface EatmHeaderProps {
	data?: Graphics;
	loading?: boolean;
}

const EatmHeader = ({ data, loading }: EatmHeaderProps) => {
	const { colors } = useTheme();
	const COLORS = [colors.extra.omega, '#FFCC81', '#FFD79A', '#FFDFAF', '#FFF2DE'];

	return (
		<HeaderContainer>
			<SectionContainer>
				<SectionHeader>
					<SectionTitle>Monto recaudado</SectionTitle>
					<SectionDate>Últimas 24 horas</SectionDate>
				</SectionHeader>

				<SectionContent>
					<AmountsBarGraph data={data?.amounts} barColor="#FFCC81" tooltipColor="#e8e7eb" />
				</SectionContent>
			</SectionContainer>

			<SectionContainer>
				<SectionHeader>
					<SectionTitle>Retiros por banco</SectionTitle>
					<SectionDate>Últimas 24 horas</SectionDate>
				</SectionHeader>

				<SectionContent>
					<BanksPieChart data={data} colors={COLORS} tooltipColor="#e8e7eb" loading={loading} />
				</SectionContent>
			</SectionContainer>
		</HeaderContainer>
	);
};

export { EatmHeader };
