import { SVGProps } from 'react';

export const EqualIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4.583 14.666h12.834M4.583 7.333h12.834"
			stroke="#140F33"
			strokeWidth={1.5}
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
