import { ResponseExport, ResponseGraphics, ResponseTable } from '../models/common-payments';
import {
	CreateNewPaymentLinkParams,
	ExportDataParams,
	GetAllPaymentLinks,
	SendPaymentLinkByParams,
	SendPaymentLinkByResponse,
	SinglePaymentLinkResponse,
} from '../models/payment-links';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getGraphicsData = async (merchantCode: string) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseGraphics>('/v1/pl/graphics', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getTableData = async ({ ...rest }: GetAllPaymentLinks) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTable>('/v1/pl/table', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/pl/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const createNewPaymentLink = async ({
	merchantCode,
	amount,
	reference,
}: CreateNewPaymentLinkParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			merchant_order_id: reference,
			order_amount: amount,
		};
		const { data } = await client.post<SinglePaymentLinkResponse>('/v1/pl/new', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendPaymentLinkBy = async ({
	merchantCode,
	wayToShare,
	paymentLink,
	email,
	phone,
}: SendPaymentLinkByParams) => {
	try {
		const params = { merchant_code: merchantCode, way_of_share: wayToShare };
		const body = {
			payment_link: paymentLink,
			email,
			phone,
		};
		const { data } = await client.post<SendPaymentLinkByResponse>('/v1/pl/share-link', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

interface SendPaymentLinkSMSParams {
	merchantCode: string;
	areaCode: string;
	phoneNumber: string;
	paymentLink: string;
}

const sendPaymentLinkSMS = async ({
	merchantCode,
	areaCode,
	phoneNumber,
	paymentLink,
}: SendPaymentLinkSMSParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			area_code: areaCode,
			phone: phoneNumber,
			url: paymentLink,
		};
		const { data } = await client.post<SinglePaymentLinkResponse>('/v1/pl/send-sms', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

interface SendBatchPaymentLinksParams {
	merchantCode: string;
	file: FileList | File;
}

const sendBatchPaymentLinks = async ({ merchantCode, file }: SendBatchPaymentLinksParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/pl/upload', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'File-Extension': ext,
			},
		});

		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const PaymentLinksServices = {
	getGraphicsData,
	getTableData,
	getExportData,
	createNewPaymentLink,
	sendPaymentLinkBy,
	sendPaymentLinkSMS,
	sendBatchPaymentLinks,
};
