import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { Alert } from '../../components/alert';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useFloatingAlert } from '../../hooks/useFloatingAlert';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { PersonalizationServices } from '../../services/personalization';
import { errorFormatter } from '../../utils/errors';
import { ColorSection } from './components/color-section';
import { LogoSection } from './components/logo-section';
import { ConfirmationModal } from './components/modal';
import { PreviewSection } from './components/preview-section';
import { Container, TitlePage, Wrapper } from './style';

export interface PersonalizationState {
	newColor?: string;
	newLogo?: File;
}

const defaultState: PersonalizationState = {
	newColor: undefined,
	newLogo: undefined,
};

export interface ModalState {
	show: boolean;
	type?: 'logotipo' | 'color';
	action?: (arg: boolean) => Promise<void>;
	loading?: boolean;
}

const defaultModalState: ModalState = {
	show: false,
	type: undefined,
	action: undefined,
	loading: false,
};

const Personalization = () => {
	const { handleShowAlert } = useFloatingAlert();
	const { merchantSelected } = useMerchant();
	const { breakpoints } = useTheme();
	const { width } = useScreenSize();
	const [state, setState] = useState<PersonalizationState>(defaultState);
	const [modal, setModal] = useState<ModalState>(defaultModalState);

	const { data, isLoading, isFetching } = useQuery({
		enabled: !!merchantSelected,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.config.personalization.getInformation, merchantSelected?.code],
		queryFn: () => PersonalizationServices.getPersonalization(merchantSelected?.code as string),
		onError: (error: any) => {
			handleShowAlert({
				message: errorFormatter(error.code),
				type: 'error',
			});
		},
	});

	const getFileName = (): string => {
		if (!data?.logo) return '';
		return data?.logo?.split('/').pop() || '';
	};

	useEffect(() => {
		if (data?.color) setState({ ...state, newColor: data?.color?.toUpperCase().split('#')[1] });
	}, [data?.color]);

	const handleCloseModal = () => {
		setModal(defaultModalState);
	};

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.personalization.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<>
			{modal.show && modal.type && modal.action && (
				<ConfirmationModal
					show={modal.show}
					type={modal.type}
					onCloseModal={handleCloseModal}
					action={modal.action}
					loading={modal.loading}
				/>
			)}

			<ViewWrapper title="Personalización" isLoading={isLoading || isFetching}>
				<TitlePage>Personalización</TitlePage>

				{width <= +breakpoints.lg.split('px')[0] ? (
					<Alert
						show
						message="Para personalizar la información de tu cuenta visita el sitio web "
					/>
				) : (
					<Container>
						<Wrapper>
							<LogoSection
								fileName={getFileName()}
								state={state}
								setState={setState}
								setModal={setModal}
							/>
							<ColorSection
								color={data?.color.split('#')[1]}
								state={state}
								setState={setState}
								setModal={setModal}
							/>
						</Wrapper>

						<Wrapper>
							<PreviewSection
								color={state.newColor || data?.color}
								logoUrl={data?.logo}
								file={state.newLogo}
							/>
						</Wrapper>
					</Container>
				)}
			</ViewWrapper>
		</>
	);
};

export { Personalization };
