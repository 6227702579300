export type ErrorType =
	| 'E_LOG_UNOTFOUND'
	| 'E_LOG_MATCH'
	| 'E_PASS_UNOTFOUND'
	| 'E_PASS_CMISMATCH'
	| 'E_PASS_EXPCODE'
	| 'E_PASS_CODEMATCH'
	| 'E_PASS_LIMITEXC'
	| 'E_SESS_NOTAUTH'
	| 'E_SESS_EXPTOKEN'
	| 'E_SIGN_SEXC'
	| 'E_SIGN_FEXC'
	| 'E_SIGN_EXPVAL'
	| 'E_SIGN_PNOTFOUND'
	| 'E_SIGN_UDUP'
	| 'E_NOTAUTH'
	| 'E_MER_NOTFOUND'
	| 'E_INVKEY_EXC'
	| 'E_PAYMENTS_NOTAUTH'
	| 'E_PAYMENTS_NOTFOUND'
	| 'E_PL_TYPEXC'
	| 'E_PL_INVFORM'
	| 'E_PL_SHARE'
	| 'E_PL_SIZEXC'
	| 'E_PL_APIEXC'
	| 'E_PL_APISERV'
	| 'E_PL_NOCONT'
	| 'E_PL_NOTALLOW'
	| 'E_PL_QRERROR'
	| 'E_COLUMN_EXC'
	| 'E_CON_MERCHEXC'
	| 'E_SEARCH_REQEXC'
	| 'E_SESS_UBLOCKED'
	| 'E_SETT_INVHEX'
	| 'E_SETT_MIMEEXC'
	| 'E_SETT_LOGOSIZE'
	| 'E_SETT_LOGODIM'
	| 'E_SERV_EXC'
	| 'IM_A_TEAPOT'
	| 'E_MOBI_EXC'
	| 'E_TRANS_NOTFOUND'
	| 'E_ATM_CMISMATCH'
	| 'E_ATM_MAXAMOUNT'
	| 'E_ATM_AMOUNTEXC'
	| 'E_ATM_FORMATEXC'
	| 'E_AMOUNT_EXC'
	| 'E_OTP_INVALID'
	| 'E_OTP_ATTEMPT'
	| 'E_PYOUT_DAILYEXC'
	| 'E_PYOUT_AMOUNTEXC'
	| 'E_PYOUTS_XLSX_ERROR'
	| 'E_PYOUT_TOKENDUP'
	| 'E_PYOUT_TRACKDUP'
	| 'E_JWT_EXPIRED'
	| 'E_JWT_ERROR'
	| 'E_JWT_NOTFOUND'
	| 'E_ROW_EXC'
	| 'E_ROW_CL_EXC'
	| 'INSUFFICIENT_BALANCE'
	| 'E_ATM_MINAMOUNT_PER_USER'
	| 'E_ATM_MAXAMOUNT_PER_USER'
	| 'E_DD_UNIVERSE_NOTFOUND'
	| 'E_DD_MERCH_EXC'
	| 'E_DD_SESSEXC'
	| 'E_UNKNOWN_ERROR'
	| 'E_USERS_DEL_NOTALLOW'
	| 'E_USERS_DEL_NOTAUTH'
	| 'E_USERS_DEL_EXC'
	| 'E_USERS_DEL_SUCC'
	| 'E_NOTFOUND'
	| '-404'
	| '-501'
	| '-503'
	| '-505';

export const ErrorsMap = new Map<ErrorType, string>([
	['E_LOG_UNOTFOUND', 'Algo salió mal, vuelve a intentarlo.'],
	['E_LOG_MATCH', 'Los datos de inicio de sesión son incorrectos. Intenta nuevamente.'],
	['E_PASS_UNOTFOUND', 'Lo sentimos, no pudimos encontrar tu cuenta.'],
	['E_PASS_CMISMATCH', 'El código de verificación es incorrecto.'],
	['E_PASS_EXPCODE', 'El código de verificación ha expirado.'],
	['E_PASS_CODEMATCH', 'Las contraseñas no coinciden.'],
	['E_PASS_LIMITEXC', 'Has excedido el límite de intentos. Intenta nuevamente en una hora.'],
	['E_SIGN_SEXC', 'Los datos son incorrectos. Intenta nuevamente.'],
	['E_SIGN_FEXC', 'La información está incompleta. Intenta nuevamente.'],
	['E_SIGN_EXPVAL', 'Los datos son incorrectos. Intenta nuevamente.'],
	['E_SIGN_PNOTFOUND', 'Los datos son incorrectos. Intenta nuevamente.'],
	['E_SIGN_UDUP', 'Este usuario ya tiene una cuenta en este comercio'],
	[
		'E_MER_NOTFOUND',
		'Información no disponible. Si el error continúa, ponte en contacto con soporte.',
	],
	[
		'E_INVKEY_EXC',
		'El formato del archivo es incorrecto, verifica que no hayas modificado las cabeceras ni agregado columnas.',
	],
	[
		'E_PAYMENTS_NOTFOUND',
		'Información no disponible. Si el error continúa, ponte en contacto con soporte.',
	],
	['E_PL_TYPEXC', 'El formato del archivo es incorrecto.'],
	['E_PL_INVFORM', 'El formato del archivo es incorrecto.'],
	['E_PL_SHARE', 'Algo salió mal, vuelve a intentarlo.'],
	['E_PL_SIZEXC', 'El tamaño del archivo excede el límite permitido.'],
	['E_PL_APIEXC', 'Algo salió mal, vuelve a intentarlo.'],
	['E_PL_APISERV', 'Algo salió mal.'],
	['E_PL_NOCONT', 'Algo salió mal, vuelve a intentarlo.'],
	['E_PL_NOTALLOW', 'El formato del archivo debe ser .csv'],
	['E_PL_QRERROR', 'Algo salió mal. Genera un nuevo pago para obtener un código QR.'],
	[
		'E_CON_MERCHEXC',
		'No tienes acceso a este servicio. Ponte en contacto con soporte para más obtener más información.',
	],
	['E_SEARCH_REQEXC', 'No se permiten caracteres especiales'],
	[
		'E_SESS_UBLOCKED',
		'Tu cuenta ha sido bloqueada por falta de pago, para restablecer el servicio ponte en contacto con operacionesmexico@etpay.com',
	],
	['E_SETT_INVHEX', 'El valor hexadecimal es incorrecto.'],
	['E_SETT_MIMEEXC', 'El formato de archivo no está permitido.'],
	['E_SETT_LOGOSIZE', 'El tamaño del archivo excede el límite permitido.'],
	['E_SETT_LOGODIM', 'El tamaño del archivo excede el límite permitido.'],
	['E_SERV_EXC', 'Algo salió mal.'],
	[
		'IM_A_TEAPOT',
		'No existe información en la base de datos de los permisos relacionados a un role_id.',
	],
	['E_MOBI_EXC', 'El teléfono ingresado es incorrecto.'],
	[
		'E_TRANS_NOTFOUND',
		'Información no disponible. Si el error continúa, ponte en contacto con soporte.',
	],
	['E_ATM_CMISMATCH', 'El código de verificación es incorrecto.'],
	[
		'E_ATM_MAXAMOUNT',
		'El monto del retiro sobrepasa la cantidad de efectivo disponible. Intenta otro monto.',
	],
	['E_ATM_AMOUNTEXC', 'El monto excede el límite permitido'],
	['E_ATM_FORMATEXC', 'El monto del retiro debe ser un número entero sin decimales.'],
	['E_AMOUNT_EXC', 'El monto ser numerico, no tener comas y debe tener máximo dos decimales.'],
	['E_OTP_INVALID', 'El código de verificación es incorrecto.'],
	[
		'E_OTP_ATTEMPT',
		'Has excedido el límite de intentos de verificación para esta operación. Intenta nuevamente.',
	],
	[
		'E_PYOUT_DAILYEXC',
		'Has alcanzado el límite de operaciones diarias, ponte en contacto con soporte.',
	],
	[
		'E_PYOUT_AMOUNTEXC',
		'El monto excede el límite permitido, para modificar esta configuración ponte en contacto con soporte.',
	],
	['E_PYOUTS_XLSX_ERROR', 'El archivo contiene información con formato incorrecto.'],
	['E_PYOUT_TOKENDUP', 'El archivo contiene pagos duplicados, verifica el payment'],
	[
		'E_PYOUT_TRACKDUP',
		'El archivo contiene pagos de salida duplicados, verifica la Clave de rastreo',
	],
	['E_JWT_EXPIRED', 'El código de verificación ha expirado.'],
	['E_JWT_NOTFOUND', 'Algo salió mal. Si el error continúa, ponte en contacto con soporte.'],
	['E_ROW_EXC', 'Has excedido el límite de número de pagos de salida, intenta con menos de 50'],
	['E_ROW_CL_EXC', 'Has excedido el límite de número de pagos de salida, intenta con menos de 100'],
	['INSUFFICIENT_BALANCE', 'No cuentas con fondos suficientes para realizar la transacción'],
	['E_ATM_MAXAMOUNT_PER_USER', 'El monto es mayor al límite de retiro por usuario.'],
	['E_ATM_MINAMOUNT_PER_USER', 'El monto es menor al mínimo de retiro por usuario.'],
	['E_DD_UNIVERSE_NOTFOUND', 'En este momento no tienes suscripciones activas'],
	[
		'E_DD_MERCH_EXC',
		'Información no disponible. Si el error continúa, ponte en contacto con soporte.',
	],
	['E_DD_SESSEXC', 'Algo salió mal, vuelve a intentarlo.'],
	['E_UNKNOWN_ERROR', 'Algo salió mal, vuelve a intentarlo.'],
	['E_USERS_DEL_NOTALLOW', 'No tienes permisos para ejecutar esta acción.'],
	['E_USERS_DEL_NOTAUTH', 'No tienes permisos para ejecutar esta acción.'],
	['E_USERS_DEL_EXC', 'Algo salió mal.'],
	['E_NOTFOUND', 'La información es incorrecta, valida la información de los pagos'],
	['-501', 'El monto de la devolución parcial debe ser menor al del pago'],
	['-503', 'El pago ya fue devuelto'],
	['-404', 'El pago no fue encontrado'],
	['-505', 'La cuenta CLABE es incorrecta'],
]);

export const errorFormatter = (error: ErrorType) => {
	const errorText = ErrorsMap.get(error);
	return errorText ? errorText : 'Algo salió mal. Intenta nuevamente';
};
