import styled from 'styled-components';

export const TitlePage = styled.h1`
	font-size: 32px;
	padding-bottom: 16px;
`;

export const AlertMessage = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-family: ${({ theme }) => theme.fonts.stack};
	font-size: 16px;
	line-height: 18px;

	a {
		text-decoration: underline;
	}
`;

export const BodySection = styled.div`
	margin-top: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		width: 50%;
	}
`;

export const InputContainerGroup = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	margin-top: 16px;
`;

export const InputContainer = styled.div`
	margin-top: 16px;
`;
