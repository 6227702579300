export const ANALYTICS = {
	account_info: {
		page_view: 'account_info',
	},
	all_payments: {
		page_view: 'all_payments',
	},
	analytics: {
		page_view: 'analytics',
	},
	eatm: {
		page_view: 'eatm',
	},
	sign_in: {
		page_view: 'sign_in',
	},
	create_account: {
		page_view: 'create_account',
	},
	login: {
		page_view: 'login',
	},
	reset_password: {
		page_view: 'reset_password',
	},
	conciliation: {
		page_view: 'conciliation',
	},
	contacts: {
		page_view: 'contacts',
	},
	home: {
		page_view: 'home',
	},
	manage_plan: {
		page_view: 'manage_plan',
	},
	payment_links: {
		page_view: 'payment_links',
	},
	direct_debit_mx: {
		page_view: 'direct_debit_mx',
	},
	personalization: {
		page_view: 'personalization',
	},
	quick_transfer: {
		page_view: 'quick_transfer',
	},
	business_transfer: {
		page_view: 'business_transfer',
	},
	non_bank_cards: {
		page_view: 'non_bank_cards',
	},
	subscription: {
		page_view: 'subscription',
	},
	users: {
		page_view: 'users',
	},
	profile: {
		page_view: 'profile',
	},
};
