import { ResponseGraphics } from './common-payments';
import { AmountCollected } from './home';

export enum ResumeCardType {
	AVG_TICKET = 'Ticket promedio',
	CLIENTS = 'Clientes',
	NEW_CLIENTS = 'Nuevos clientes',
	RECURRENT_CLIENTS = 'Clientes frecuentes',
}

export type Interval =
	| 'YESTERDAY'
	| 'THIS_WEEK'
	| 'LAST_WEEK'
	| 'LAST_14_DAYS'
	| 'LAST_30_DAYS'
	| 'LAST_120_DAYS'
	| 'LAST_180_DAYS';

export interface Head {
	avg_ticket: AmountCollected;
	num_clients: AmountCollected;
	new_clients: AmountCollected;
	recurrent_clients: AmountCollected;
}

export interface GraphicsFormat {
	x: number;
	y: string | number;
	x_formatted: string;
	y_formatted: string;
	y_abbr?: string;
}

export interface StatisticsResponse {
	head: Head;
	by_banks: GraphicsFormat[];
	clients: ResponseGraphics;
}

export interface GraphicsTwoPeriods {
	group_by: 'hour' | 'day' | 'week';
	current_period_x: string;
	current_period_x_full: string;
	current_period_y: string | number;
	current_period_y_formatted: string;
	past_period_x: string;
	past_period_y: string | number;
	past_period_y_formatted: string;
}

export interface GraphicsResponse {
	avg_amount: GraphicsTwoPeriods[];
	num_trx: GraphicsTwoPeriods[];
}
