import styled from 'styled-components';

export const TableFilterHeader = styled.div`
	display: flex;
	padding-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
		position: relative;
	}
`;

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: normal;
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 12px;
`;

export const NewPaymentLinkButton = styled.button`
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border: 0;
	border-radius: 8px;
	color: white;
	cursor: pointer;
	height: 37px;
	min-width: 100px;
	outline: 0;
	padding: 0 12px;
	transition: background-color 0.2s ease-in-out;
	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.extra.crab}CC;
	}
`;

export const DragAndDropContainer = styled.div`
	margin-bottom: 32px;
	width: 100%;
`;

export const DownloadLink = styled.a`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal['pure']};
	cursor: pointer;
	display: flex;
	font-family: ${({ theme }) => theme.fonts.stack};
	height: 48px;
	justify-content: center;
	min-width: 100px;
	outline: none;
	padding: 8px 12px;
	text-align: center;
	width: 100%;

	svg {
		margin-left: 8px;
	}
`;
