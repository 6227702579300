import { useRef, useState } from 'react';

import { useClickOutside } from '../../hooks/useClickOutside';
import { getDateRange } from '../../utils/dates';
import { FilterArrowIcon } from '../table/components/date-filter/styles';
import {
	ContainerFilterTime,
	ContainerShow,
	FilterTimeInput,
	FilterTimeLabel,
	SelectOption,
	SelectOptions,
} from './styles';

interface Option<T> {
	label: string;
	value: T;
}

interface SelectRangeProps<T> {
	onSelect: (arg: T) => void;
	options: Option<T>[];
	value?: string;
}

const SelectRange = <T,>({ onSelect, options, value }: SelectRangeProps<T>) => {
	const [selected, setSelected] = useState<Option<T>>(
		() => options.find((option) => option.value === value) || options[0],
	);
	const [showOptions, setShowOptions] = useState(false);
	const elementRef = useRef<HTMLDivElement>(null);

	const handleShowOptions = () => {
		setShowOptions(!showOptions);
	};

	const handleToHideOptions = () => {
		setShowOptions(false);
	};

	useClickOutside({ elementRef, callback: handleToHideOptions });

	return (
		<ContainerFilterTime ref={elementRef}>
			<ContainerShow>
				<FilterTimeLabel onClick={handleShowOptions}>
					{selected.label}
					<FilterArrowIcon />
				</FilterTimeLabel>

				<FilterTimeInput>{getDateRange<T>(selected.value)}</FilterTimeInput>
			</ContainerShow>

			{showOptions && (
				<SelectOptions>
					{options.map((option) => (
						<SelectOption
							key={option.value as string}
							isSelected={option.value === selected?.value}
							onClick={() => {
								setSelected(option);
								onSelect(option.value as unknown as T);
								handleToHideOptions();
							}}
						>
							{option.label}
						</SelectOption>
					))}
				</SelectOptions>
			)}
		</ContainerFilterTime>
	);
};

export { SelectRange };
