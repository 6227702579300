import { ReactNode } from 'react';

import {
	CardBody,
	CardContainer,
	CardContent,
	Container,
	Description,
	Disclaimer,
	DisclaimerDescription,
	Step,
	Title,
} from './styles';

interface Card {
	title: string;
	description: string;
	step: number;
	children?: ReactNode;
	disclaimer?: boolean;
	fileUpload?: boolean;
}

const Card = ({ title, description, step, children, disclaimer, fileUpload }: Card) => {
	return (
		<>
			<Container>
				<CardContainer $disclaimer={disclaimer} fileUpload={fileUpload}>
					<CardBody>
						<Step>{step}</Step>
						<CardContent>
							<Title>{title}</Title>
							<Description step={step}>{description}</Description>
							{children}
						</CardContent>
					</CardBody>
				</CardContainer>
				{disclaimer && (
					<Disclaimer>
						<DisclaimerDescription>
							Recuerda subir la nomina con 2 días hábiles de anticipación
						</DisclaimerDescription>
					</Disclaimer>
				)}
			</Container>
		</>
	);
};

export default Card;
