import { SVGProps } from 'react';

export const BancoSantanderIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="42"
		height="42"
		viewBox="0 0 42 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect x="0.25" y="0.25" width="41.5" height="41.5" rx="5.75" fill="#D7110C" />
		<rect
			x="0.25"
			y="0.25"
			width="41.5"
			height="41.5"
			rx="5.75"
			stroke="#F2F2F2"
			strokeWidth="0.5"
		/>
		<path d="M21 5L32 32H10L21 5Z" fill="white" />
		<ellipse cx="21" cy="28.5" rx="16" ry="8.5" fill="white" />
		<path d="M15 11C19 16 23 19 23 30" stroke="#D7110C" strokeWidth="3" />
		<path
			d="M30.3212 10.09C29.0912 10.09 28.1672 9.12398 28.1672 7.89998C28.1672 6.67598 29.0912 5.70398 30.3212 5.70398C31.3172 5.70398 32.0972 6.26798 32.3552 7.12598L31.6652 7.31798C31.4552 6.72998 30.9572 6.34598 30.3212 6.34598C29.4632 6.34598 28.9172 7.04198 28.9172 7.89998C28.9172 8.76398 29.4632 9.44198 30.3212 9.44198C30.9572 9.44198 31.4552 9.06998 31.6652 8.48198L32.3552 8.67398C32.0972 9.53198 31.3172 10.09 30.3212 10.09Z"
			fill="white"
		/>
		<path
			d="M33.0966 9.99998V5.79998H33.8046V7.61198H35.8386V5.79998H36.5466V9.99998H35.8386V8.18798H33.8046V9.99998H33.0966Z"
			fill="white"
		/>
	</svg>
);
