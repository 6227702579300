import styled from 'styled-components';

export const TableFilterHeader = styled.div`
	display: flex;
	padding-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
		position: relative;
	}
`;

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: normal;
		flex-direction: column;
		gap: 16px;
	}
`;

export const AlertContainer = styled.div`
	margin-bottom: 16px;
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	gap: 12px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

interface ButtonHeadProps {
	variant: 'pure' | 'saturn';
}

export const ButtonHead = styled.button<ButtonHeadProps>`
	background: ${({ theme, variant }) =>
		(variant === 'saturn' && theme.colors.extra.saturn) ||
		(variant === 'pure' && theme.colors.principal.pure)};
	border: ${({ theme, variant }) =>
		(variant === 'saturn' && 'none') ||
		(variant === 'pure' && `1px solid ${theme.colors.principal.ceres}`)};
	border-radius: 8px;
	color: ${({ theme, variant }) =>
		(variant === 'saturn' && theme.colors.principal.pure) ||
		(variant === 'pure' && theme.colors.principal.orion)};
	cursor: pointer;
	padding: 9.5px 14px;

	&:hover {
		opacity: 0.7;
	}
`;

export const SpanAmount = styled.span`
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	text-align: left;
`;