import { ErrorMessage } from 'formik';
import styled from 'styled-components';

export const InputContainer = styled.div`
	margin-bottom: 16px;
	position: relative;
`;

interface IconContainerProps {
	readonly position: 'left' | 'right';
	onClick?: () => void;
}

export const IconContainer = styled.div<IconContainerProps>`
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
	left: ${({ position }) => (position === 'left' ? '13px' : 'unset')};
	margin: 0;
	position: absolute;
	right: ${({ position }) => (position === 'right' ? '13px' : 'unset')};
	top: 14px;
`;

interface LabelProps {
	readonly hasValue?: boolean;
	readonly focus?: boolean;
	readonly type?: string;
}

export const InputLabel = styled.label<LabelProps>`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: ${({ focus, hasValue }) => (focus || hasValue ? '12px' : '16px')};
	left: 12px;
	line-height: 12px;
	margin: auto 0;
	margin-left: ${({ type }) => (type === 'file' ? '28px' : '0px')};
	pointer-events: none;
	position: absolute;
	top: ${({ focus, hasValue }) => (focus || hasValue ? '8px' : '17px')};
	transition: all 0.3s ease;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

interface InputProps {
	readonly hasError: boolean;
	readonly hasValue?: boolean;
	readonly name?: string;
}

export const InputElement = styled.input<InputProps>`
	background-color: ${({ theme, hasValue, name }) =>
		!hasValue || name == 'Color' ? theme.colors.principal.sirius : theme.colors.principal.eris};
	border: 1px
		${({ theme, hasError }) =>
			!hasError ? theme.colors.principal.ceres : theme.colors.extra.sword}
		solid;
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.principal.orion};
	font-family: ${({ theme }) => theme.fonts.stack};
	font-size: 16px;
	height: 48px;
	outline: none;
	padding: 12px 12px 0;
	width: 100%;

	&:focus {
		border-color: ${({ theme }) => theme.colors.principal.vanth};

		~ ${InputLabel} {
			font-size: 12px;
			top: 8px;
		}
	}

	&:-webkit-autofill {
		-webkit-text-fill-color: ${({ theme }) => theme.colors.principal.orion};

		box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.principal.sirius} inset;

		~ ${InputLabel} {
			font-size: 12px;
			top: 8px;
		}
	}

	&:disabled,
	&:read-only {
		background-color: ${({ theme }) => theme.colors.principal.eris};
		cursor: default;
		pointer-events: none;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
		padding-top: 10px;
	}
`;

export const ErrorMsg = styled(ErrorMessage)`
	color: ${({ theme }) => theme.colors.extra.sword};
	display: block;
	font-size: 12px;
	line-height: 1;
	margin-top: 6px;
	text-align: left;
`;
