import { Dispatch, SetStateAction, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { Payment, PaymentStatus } from '../../../../models/common-payments';
import { ColumnInterface, Filters, Pagination, RowInterface } from '../../../../models/table';
import { NonBankCardsServices } from '../../../../services/non-bank-cards';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import {
	AmountContainer,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	Section,
	StatusContainer,
	Title,
	Value,
	VoucherButton,
	VoucherContainer,
} from './styles';

interface NonBankCardsTableProps {
	data: Payment[];
	isLoading?: boolean;
	stateList: PaymentStatus[];
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const NonBankCardTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
}: NonBankCardsTableProps) => {
	// data = [
	// 	{
	// 		reference: 'oc1234566',
	// 		productType: 'TNB',
	// 		amount: '$1.000',
	// 		bankName: 'Hites',
	// 		bankCode: 'cl_hites',
	// 		status: 'pending',
	// 		typeError: 'pending',
	// 		paymentId: 'nY0uINa2jXflhlcwSD1uBvPyRoowknvTbLpwkDw7c1mxj7Q227bOgCrpKG274CLi',
	// 		sessionToken: 'Snh8CdEqQXZBrAKSgMs1PTJM3FCVfXD4lgKmTbNRS0oKp0cZrf5GM1xAPBwcO1Zi',
	// 		createdatDate: '10/06/2024',
	// 		createdatTime: '15:45',
	// 		expiredate: '10/06/2024 15:55',
	// 		lastStep: 'tnb_processing',
	// 		idx: 0,
	// 		clabe: '',
	// 		userClabe: '',
	// 		paymentMethod: 'Hites',
	// 		row_num: 0,
	// 		rut: '',
	// 		accountType: '',
	// 		account: '',
	// 		btstep: '',
	// 		paymentConcept: '',
	// 		trackingKey: '',
	// 		codiTransferID: '',
	// 		clientName: '',
	// 		destinationClabe: '',
	// 		amountToSearch: '',
	// 		descriptionError: '',
	// 	},
	// 	{
	// 		reference: 'oc1234566',
	// 		productType: 'TNB',
	// 		amount: '$1.000',
	// 		bankName: 'Hites',
	// 		bankCode: 'cl_hites',
	// 		status: 'error',
	// 		typeError: 'bank_error',
	// 		paymentId: 'nY0uINa2jXflhlcwSD1uBvPyRoowknvTbLpwkDw7c1mxj7Q227bOgCrpKG274CLi',
	// 		sessionToken: 'Snh8CdEqQXZBrAKSgMs1PTJM3FCVfXD4lgKmTbNRS0oKp0cZrf5GM1xAPBwcO1Zi',
	// 		accountType: 'none',
	// 		rut: 'none',
	// 		createdatDate: '10/06/2024',
	// 		createdatTime: '15:45',
	// 		validatedatDate: '10/06/2024',
	// 		validatedatTime: '15:58',
	// 		expiredate: '10/06/2024 15:55',
	// 		lastStep: 'tnb_processing',
	// 		idx: 0,
	// 		clabe: '',
	// 		userClabe: '',
	// 		paymentMethod: 'Hites',
	// 		row_num: 0,
	// 		account: '',
	// 		btstep: '',
	// 		paymentConcept: '',
	// 		trackingKey: '',
	// 		codiTransferID: '',
	// 		clientName: '',
	// 		destinationClabe: '',
	// 		amountToSearch: '',
	// 		descriptionError: '',
	// 	},
	// ];
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();

	const columns: ColumnInterface<Payment>[] = [
		{
			title: 'Referencia',
			propertyName: 'reference',
			cellRender: ({ reference }) => <>{reference}</>,
		},
		{
			title: 'Monto',
			align: 'right',
			propertyName: 'amount',
			cellRender: ({ amount }) => <AmountContainer>{amount}</AmountContainer>,
		},
		{
			title: 'Medio de pago',
			propertyName: 'paymentMethod',
			cellRender: ({ bankName }) => <>{bankName}</>,
		},
		{
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<StatusContainer>
					<Status status={status} />
				</StatusContainer>
			),
		},
		{
			sortField: true,
			title: 'Fecha de creación',
			align: 'left',
			propertyName: 'createdatDate',
			cellRender: ({ createdatDate, createdatTime }) => (
				<DateAndTime date={createdatDate} time={createdatTime} />
			),
		},
	];

	const detailSection: RowInterface<Payment> = (data) => {
		const isNotPending = data.status !== 'pending';
		return (
			<DetailsContainer $hasError={!!data.typeError && isNotPending && !data.clabe}>
				{isNotPending && (
					<Section>
						{merchantSelected?.country === 'CL' &&
							data.status === 'success' &&
							data.cantidadCuotas !== undefined && (
								<>
									<Content>
										<Title>Cantidad de cuotas</Title>
										<Value>{data.cantidadCuotas}</Value>
									</Content>
									<Content>
										<Title>Código de autorización</Title>
										<Value>{data.codAutorizacion}</Value>
									</Content>
								</>
							)}
						{merchantSelected?.country === 'MX' && (
							<Content>
								<Title>Medio de pago</Title>
								<Value>{data.paymentMethod}</Value>
							</Content>
						)}
						{/* Add the error status here */}
						{merchantSelected?.country === 'CL' &&
							data.status != 'expired' &&
							data.status != 'error' &&
							data.status != 'success' && (
								<>
									<Content>
										<Title>Tipo de cuenta</Title>
										<Value>{data.accountType}</Value>
									</Content>

									<Content>
										<Title>No. de Cuenta</Title>
										<Value>{data.account}</Value>
									</Content>

									<Content>
										<Title>Paso pendiente de realizar</Title>
										<Value>{data.btstep}</Value>
									</Content>
								</>
							)}

						{merchantSelected?.country === 'MX' && data.paymentMethod === 'CoDi' && (
							<Content>
								<Title>Número de teléfono</Title>
								<Value>{data.phone}</Value>
							</Content>
						)}

						{/* {data.lastStep && data.status != 'expired' && data.status != 'error' && (
							<Content>
								<Title>Último paso</Title>
								<Value>{data.lastStep}</Value>
							</Content>
						)} */}
						{data.rut && data.rut != 'none' && (
							<Content>
								<Title>RUT</Title>
								<Value>{data.rut}</Value>
							</Content>
						)}
						{data.typeError && data.status !== 'expired' && (
							<Content>
								<Title>Tipo de error</Title>
								<Value>{data.typeError}</Value>
							</Content>
						)}

						<Content toEnd $hasError={!!data.typeError && isNotPending && !data.clabe}>
							<Title>Fecha de expiración</Title>
							<Value>{data.expiredate}</Value>
						</Content>
					</Section>
				)}

				<Section>
					<Content>
						<Title>{data.paymentId ? 'Payment ID' : 'Session Token'}</Title>
						<Value>{data.paymentId ? data.paymentId : data.sessionToken}</Value>
					</Content>

					{data.status === 'pending' && (
						<Content toEnd $hasError={!!data.typeError && !data.clabe}>
							<Title>Fecha de expiración</Title>
							<Value>{data.expiredate}</Value>
						</Content>
					)}

					{data.validatedatDate && (
						<Content>
							<Title>Fecha de validación</Title>
							<Value>
								{data.validatedatDate} {data.validatedatTime}
							</Value>
						</Content>
					)}
				</Section>

				{data.transferId && (
					<Section>
						<Content>
							<Title>Transfer ID</Title>
							<Value>{data.transferId}</Value>
						</Content>
					</Section>
				)}
			</DetailsContainer>
		);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await NonBankCardsServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from,
					to: filters?.dateRange?.to,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			<ETPayTable<Payment>
				title="Todos los cobros"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={detailSection}
				hasFilters
				hasDateFilter
				allowExport={validatePermission('TNB_EXPORT')}
				stateList={fillStatesWithZeros({ stateList })}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</>
	);
};

export { NonBankCardTable };
