import { SVGProps } from 'react';

export const Servipag = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="75"
			height="30"
			viewBox="0 0 75 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M55.108 27.7317C47.9838 27.7317 42.1875 21.9733 42.1875 14.8952C42.1875 7.81948 47.9838 2.06303 55.108 2.06303C62.2358 2.06303 68.0345 7.81948 68.0345 14.8952C68.0345 21.9733 62.2358 27.7317 55.108 27.7317Z"
				fill="#140F33"
			/>
			<path
				d="M55.1098 1.67144C47.7678 1.67144 41.7949 7.60371 41.7949 14.8952C41.7949 22.1895 47.7678 28.1233 55.1098 28.1233C62.4549 28.1233 68.4301 22.1895 68.4301 14.8952C68.4301 7.60371 62.4549 1.67144 55.1098 1.67144ZM55.1098 2.45463C62.0203 2.45463 67.6415 8.03525 67.6415 14.8952C67.6415 21.7579 62.0203 27.3401 55.1098 27.3401C48.2035 27.3401 42.5835 21.7579 42.5835 14.8952C42.5835 8.03525 48.2035 2.45463 55.1098 2.45463Z"
				fill="white"
			/>
			<path
				d="M3.27395 21.8019L3.17459 21.5567C2.31461 19.4359 1.87891 17.1952 1.87891 14.8965C1.87891 12.5967 2.31461 10.3567 3.17459 8.23898L3.27395 7.99384H44.2902L43.9523 8.58006C42.8455 10.5001 42.2603 12.6836 42.2603 14.8953C42.2603 17.1086 42.8455 19.2941 43.9523 21.2157L44.2898 21.8019H3.27395Z"
				fill="#140F33"
			/>
			<path
				d="M44.9682 7.6025H43.6078H3.53832H3.00601L2.80689 8.09278C1.92799 10.2575 1.48242 12.5464 1.48242 14.8964C1.48242 17.2455 1.92799 19.5352 2.80649 21.7027L3.00562 22.1937H3.53832H43.6078H44.9674L44.2923 21.0213C43.2194 19.1593 42.6524 17.0407 42.6524 14.8956C42.6524 12.7516 43.2194 10.6354 44.2923 8.77494L44.9682 7.6025ZM43.6078 8.38569C42.5002 10.3069 41.8638 12.528 41.8638 14.8956C41.8638 17.2655 42.5002 19.4874 43.6078 21.4105H3.53832C2.72133 19.3954 2.27103 17.1966 2.27103 14.8964C2.27103 12.5965 2.72133 10.3977 3.53832 8.38569H43.6078Z"
				fill="white"
			/>
			<path
				d="M65.9355 21.8019L66.2731 21.2157C67.3807 19.2933 67.9658 17.1078 67.9658 14.8953C67.9658 12.6855 67.3811 10.5016 66.2743 8.58006L65.9371 7.99384H71.7286L71.8284 8.23898C72.688 10.3571 73.1245 12.597 73.1245 14.8965C73.1245 17.1948 72.688 19.4359 71.8284 21.5567L71.729 21.8019H65.9355Z"
				fill="#140F33"
			/>
			<path
				d="M71.9988 7.60217H71.4665H66.6205H65.2609L65.936 8.77421C67.0085 10.6366 67.5755 12.7532 67.5755 14.8952C67.5755 17.0396 67.0081 19.1577 65.9348 21.0209L65.2598 22.193H66.6193H71.4665H71.9992L72.1979 21.7027C73.0768 19.5352 73.5224 17.2452 73.5224 14.896C73.5224 12.5461 73.0768 10.2568 72.1979 8.09244L71.9988 7.60217ZM71.4665 8.38535C72.2839 10.3974 72.7338 12.5962 72.7338 14.896C72.7338 17.1962 72.2839 19.395 71.4665 21.4098H66.6193C67.7269 19.4871 68.3641 17.2652 68.3641 14.8952C68.3641 12.5276 67.7269 10.3065 66.6205 8.38535H71.4665Z"
				fill="white"
			/>
			<path
				d="M45.7928 12.4665H46.4138C47.2458 12.4665 47.9063 12.7257 47.9063 13.5269C47.9063 14.3532 47.2458 14.6112 46.4138 14.6112H45.7928V12.4665ZM43.3809 18.8123H45.7928V16.0891H46.7265C48.9811 16.0891 50.3174 15.1489 50.3174 13.5617C50.3174 11.7968 49.0206 10.9894 46.2593 10.9894H43.3809V18.8123Z"
				fill="white"
			/>
			<path
				d="M54.8784 15.6753H52.6159L53.7716 12.6024H53.8L54.8784 15.6753ZM48.8984 18.8127H51.3451L52.0564 17.1512H55.4443L56.1229 18.8127H58.7604L55.2783 10.9899H52.3281L48.8984 18.8127Z"
				fill="white"
			/>
			<path
				d="M66.8415 18.5914C65.3159 18.9289 64.174 18.9481 63.3472 18.9481C60.9404 18.9481 58.1621 17.8994 58.1621 14.9335C58.1621 11.982 60.5532 10.8515 63.8622 10.8515C64.4591 10.8515 65.7154 10.9083 66.5915 11.2004L66.4011 12.9031C65.6503 12.5213 64.7718 12.3971 64.2016 12.3971C62.2487 12.3971 60.6454 13.1083 60.6454 14.9382C60.6454 16.4709 61.9009 17.4041 63.6575 17.4041C63.982 17.4041 64.2959 17.361 64.51 17.269V15.721H63.1027V14.2458H66.8415V18.5914Z"
				fill="white"
			/>
			<path
				d="M6.96648 16.8841C7.58395 17.1762 8.45024 17.401 9.44467 17.401C10.2096 17.401 10.9273 17.2663 10.9273 16.6397C10.9273 15.1787 6.66602 16.1001 6.66602 13.245C6.66602 11.3661 8.78619 10.8457 10.5964 10.8457C11.458 10.8457 12.3113 10.9608 12.9185 11.1386L12.7635 12.7531C12.1441 12.5057 11.4446 12.3933 10.7613 12.3933C10.1544 12.3933 9.14973 12.4403 9.14973 13.1232C9.14973 14.3916 13.4114 13.5379 13.4114 16.3691C13.4114 18.396 11.4446 18.9454 9.44467 18.9454C8.60323 18.9454 7.55951 18.833 6.75947 18.6087L6.96648 16.8841Z"
				fill="white"
			/>
			<path
				d="M14.0957 10.9866H20.4669V12.4653H16.5081V14.0774H20.2468V15.5561H16.5081V17.3308H20.6088V18.8071H14.0957V10.9866Z"
				fill="white"
			/>
			<path
				d="M23.8838 12.4651H24.5167C25.437 12.4651 26.2796 12.5975 26.2796 13.3306C26.2796 14.2171 25.2919 14.2391 24.5167 14.2391H23.8838V12.4651ZM21.4707 18.807H23.8838V15.7177H24.387C25.2817 15.7177 25.5892 16.0521 26.0797 17.3953L26.6002 18.807H29.1119L28.2295 16.6779C27.7859 15.7263 27.7358 15.1327 26.9539 14.9533V14.9314C28.1218 14.6964 28.692 14.0041 28.692 12.9566C28.692 11.6134 27.3829 10.9865 25.7765 10.9865H21.4707V18.807Z"
				fill="white"
			/>
			<path
				d="M28.7285 10.9865H31.2923L33.2981 17.1255H33.3269L35.3528 10.9865H37.8259L34.7436 18.807H31.7643L28.7285 10.9865Z"
				fill="white"
			/>
			<path d="M38.373 10.9862H40.7823V18.8068H38.373V10.9862Z" fill="white" />
		</svg>
	);
};
