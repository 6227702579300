import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { useScreenSize } from '../../../hooks/useScreenSize';
import { ResponseGraphics as Graphics } from '../../../models/common-payments';
import { TooltipPie } from '../components/pie-tooltip';

interface IGraphPiePaymentsProp {
	data?: Graphics;
	colors: string[];
	tooltipColor?: string;
}

const ChartPie = ({ data, colors, tooltipColor }: IGraphPiePaymentsProp) => {
	const emptyData = [{ name: 'none', value: 1 }];
	const shouldAnimated = data && data?.data.length > 0;
	const { width } = useScreenSize();

	const _data = data?.data.map((item) => ({
		...item,
		value: Number(item.value),
	}));

	return (
		<ResponsiveContainer width="100%" height={width > 574 ? 240 : '100%'}>
			<PieChart>
				<Pie
					dataKey="value"
					data={shouldAnimated ? _data : emptyData}
					innerRadius="55%"
					outerRadius="100%"
					isAnimationActive={shouldAnimated}
					fill="#fcfcfc"
					labelLine={false}
				>
					{data &&
						data?.data.length > 0 &&
						data?.data.map((_, i: number) => (
							<Cell key={`cell-${i}`} fill={colors[i % colors.length]} />
						))}
				</Pie>
				{data && data?.data.length > 0 && (
					<Tooltip
						content={(props) => (
							<TooltipPie {...props} total={data?.total as number} tooltipColor={tooltipColor} />
						)}
						cursor={{ fill: 'transparent' }}
						wrapperStyle={{ outline: 'none' }}
					/>
				)}
			</PieChart>
		</ResponsiveContainer>
	);
};

export { ChartPie };
