import { ButtonHTMLAttributes } from 'react';

import { ButtonContainer, PrimaryButton, SecondaryButton } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	title: string;
	loading?: boolean;
	active?: boolean;
	variant?: 'default' | 'effect';
	color?: 'primary' | 'secondary' | 'text';
}

const ETPayButton = ({
	active = true,
	title,
	loading,
	onClick,
	variant = 'default',
	color = 'primary',
	...props
}: ButtonProps) => {
	return variant === 'effect' ? (
		<ButtonContainer $loading={loading}>
			<PrimaryButton disabled={loading || props.disabled} onClick={onClick} active={active}>
				{title}
			</PrimaryButton>
		</ButtonContainer>
	) : (
		<SecondaryButton disabled={loading || props.disabled} onClick={onClick} color={color}>
			{title}
		</SecondaryButton>
	);
};

export { ETPayButton };
