import { RolesAllowed } from '../models/users';

export const FEATURES = [
	{
		name: 'Cobros',
		features: [
			'Crear enlaces de cobro',
			'Generar devoluciones',
			'Consultar información',
			'Exportar información',
		],
	},
	{
		name: 'Pagos',
		features: ['Generar pagos de salida'],
	},
	{
		name: 'Ajustes',
		features: [
			'Invitar, editar y eliminar usuarios',
			'Personalización del flujo de cobro',
			'Editar información de contactos',
			'Administrar plan',
		],
	},
];

const featuresByRole = new Map<RolesAllowed, string[]>([
	[
		'ETpay',
		[
			'Crear enlaces de cobro',
			'Generar devoluciones',
			'Consultar información',
			'Exportar información',
			'Generar pagos de salida',
			'Invitar, editar y eliminar usuarios',
			'Personalización del flujo de cobro',
			'Editar información de contactos',
			'Administrar plan',
		],
	],
	[
		'Propietario',
		[
			'Crear enlaces de cobro',
			'Generar devoluciones',
			'Consultar información',
			'Exportar información',
			'Generar pagos de salida',
			'Invitar, editar y eliminar usuarios',
			'Personalización del flujo de cobro',
			'Editar información de contactos',
			'Administrar plan',
		],
	],
	['Operaciones', ['Consultar información', 'Exportar información']],
	['Devoluciones', ['Consultar información', 'Exportar información']],
	['Conciliación', ['Consultar información', 'Exportar información']],
	['Lector', ['Consultar información']],
	['Payment Links', ['Consultar información', 'Crear enlaces de cobro']],
]);

export const hasFeaturesByRole = (roleName: RolesAllowed, feature: string) =>
	featuresByRole.get(roleName)?.includes(feature);
