import styled from 'styled-components';

export const HeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;
