import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { track } from '../../analytics';
import { ANALYTICS } from '../../analytics/constants';
import { AnalyticsIcon } from '../../assets/svg';
import { Section } from '../../components/section';
import { SelectRange } from '../../components/select-range';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { GraphicsTwoPeriods, Interval } from '../../models/analytics';
import { AnalyticsServices } from '../../services/analytics';
import { AverageAmount } from './components/average-amount';
import { AverageTicketBankChart } from './components/average-ticket-bank';
import { ClientsChart } from './components/clients-chart';
import { PaymentsCount } from './components/payments-count';
import { ResumeSection } from './components/resume';
import {
	CurrentRange,
	HeadContainer,
	PreviousRange,
	ResumeContainer,
	RightSection,
	SectionContainer,
	TitlePage,
} from './styles';

interface Options {
	label: string;
	value: Interval;
}

const OPTIONS: Options[] = [
	{
		label: 'Ayer',
		value: 'YESTERDAY',
	},
	{
		label: 'Esta semana',
		value: 'THIS_WEEK',
	},
	{
		label: 'La semana pasada',
		value: 'LAST_WEEK',
	},
	{
		label: 'Últimos 14 días',
		value: 'LAST_14_DAYS',
	},
	{
		label: 'Últimos 30 días',
		value: 'LAST_30_DAYS',
	},
	{
		label: 'Últimos 120 días',
		value: 'LAST_120_DAYS',
	},
	{
		label: 'Últimos 180 días',
		value: 'LAST_180_DAYS',
	},
];

const Analytics = () => {
	const { merchantSelected } = useMerchant();
	const [range, setRange] = useState<Interval>('LAST_30_DAYS');
	const [paymentNumbers, setPaymentNumbers] = useState<GraphicsTwoPeriods[]>([]);
	const [averageAmount, setAverageAmount] = useState<GraphicsTwoPeriods[]>([]);

	const {
		data: statistics,
		isLoading: statisticsLoading,
		isRefetching: statisticsRefetching,
	} = useQuery({
		enabled: !!merchantSelected,
		keepPreviousData: true,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.analytics.getGraphData, merchantSelected?.code, range],
		queryFn: () => AnalyticsServices.getStatistics(merchantSelected?.code as string, range),
	});

	const { isLoading: graphicsLoading, isRefetching: graphicsRefetching } = useQuery({
		enabled: !!merchantSelected,
		keepPreviousData: true,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.analytics.getGraphInfo, merchantSelected?.code, range],
		queryFn: () => AnalyticsServices.getGraphics(merchantSelected?.code as string, range),
		onSuccess: (data) => {
			const _averageAmount = data.avg_amount.map((item) => ({
				...item,
				current_period_y: +item.current_period_y,
				past_period_y: +item.past_period_y,
			}));
			setAverageAmount(_averageAmount);

			const _paymentNumbers = data.num_trx.map((item) => ({
				...item,
				current_period_y: +item.current_period_y,
				past_period_y: +item.past_period_y,
			}));
			setPaymentNumbers(_paymentNumbers);
		},
	});

	useEffect(() => {
		track({
			type: 'screen',
			pageView: ANALYTICS.analytics.page_view,
			source: 'GTM',
		});
	}, []);

	const handleSelectRange = (range: Interval) => {
		setRange(range);
	};

	return (
		<ViewWrapper
			title="Analytics"
			isLoading={statisticsLoading || statisticsRefetching || graphicsLoading || graphicsRefetching}
		>
			<HeadContainer>
				<TitlePage>
					<AnalyticsIcon /> Analytics
				</TitlePage>

				<SelectRange<Interval> onSelect={handleSelectRange} options={OPTIONS} value={range} />
			</HeadContainer>

			<ResumeContainer>
				<ResumeSection data={statistics?.head} />
			</ResumeContainer>

			<SectionContainer>
				<Section title="Ticket promedio por banco">
					<AverageTicketBankChart banks={statistics?.by_banks} loading={statisticsLoading} />
				</Section>

				<Section title="Clientes">
					<ClientsChart data={statistics?.clients} loading={statisticsLoading} />
				</Section>
			</SectionContainer>

			<Section
				title="Número de cobros"
				rightSection={
					<RightSection>
						<CurrentRange>Periodo actual</CurrentRange>
						<PreviousRange>Periodo anterior</PreviousRange>
					</RightSection>
				}
			>
				<PaymentsCount data={paymentNumbers} />
			</Section>

			<Section
				title="Monto promedio"
				rightSection={
					<RightSection>
						<CurrentRange>Periodo actual</CurrentRange>
						<PreviousRange>Periodo anterior</PreviousRange>
					</RightSection>
				}
			>
				<AverageAmount data={averageAmount} />
			</Section>
		</ViewWrapper>
	);
};

export { Analytics };
