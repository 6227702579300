import { SVGProps } from 'react';

export const FinishProcessSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="60"
		height="60"
		viewBox="0 0 60 60"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx="30" cy="30" r="30" fill="#1B9D06" />
		<path
			d="M42.5 22L25.8333 39L17.5 30.5"
			stroke="white"
			strokeWidth="8"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
