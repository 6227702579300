import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';

import { Options, Select } from '../../../../components/form/select';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { OutboundService } from '../../../../services/outbound';
import { errorFormatter } from '../../../../utils/errors';
import {
	onlyNumberRegex,
	onlyNumberRegexWithDot,
	validateEmail,
} from '../../../../utils/validations';
import { Values } from '../..';
import OutboundResume from '../outboundResume';
import SendCode from '../sendCode';
import {
	Button,
	ButtonContainer,
	HintInput,
	InputContainer,
	ModalContainer,
	ModalStep,
	ModalSubtitle,
	ModalTitle,
} from './styles';

interface ModalOutboundProp {
	onClose: () => void;
	setState: Dispatch<SetStateAction<Values>>;
	state: Values;
}

export interface Steps {
	step: number;
	errorClabe: boolean;
}

interface CustomDateTimeFormatOptions {
	day: 'numeric' | '2-digit';
	month: 'numeric' | '2-digit' | 'short' | 'long';
	year?: 'numeric' | '2-digit';
	hour: 'numeric' | '2-digit';
	minute: 'numeric' | '2-digit';
	hour12: boolean;
}
interface OptionBadge {
	value: number;
	label: string;
	customLabel: string;
	show: boolean;
}
interface TransferResumeItem {
	item: string;
	data: string;
}

const ModalOutbound = ({ onClose, state, setState }: ModalOutboundProp) => {
	const { merchantSelected } = useMerchant();
	const flowBanksOrStp = true;
	const { handleShowAlert } = useFloatingAlert();
	const [steps, setSteps] = useState<Steps>({ step: 1, errorClabe: false });
	const [isClabeError, setIsClabeError] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const optionsBadge: OptionBadge[] = [
		{
			value: 1,
			label: 'MXN',
			customLabel: 'MXN',
			show: true,
		},
		{
			value: 2,
			label: 'USD',
			customLabel: 'USD',
			show: true,
		},
		{
			value: 3,
			label: 'EUR',
			customLabel: 'EUR',
			show: true,
		},
		{
			value: 4,
			label: 'JPY',
			customLabel: 'JPY',
			show: true,
		},
		{
			value: 5,
			label: 'GBP',
			customLabel: 'GBP',
			show: true,
		},
	];
	const formatDateAndTime = () => {
		const todayDate = new Date();
		const options: CustomDateTimeFormatOptions = {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: false,
		};

		const formattedDate = todayDate.toLocaleString('es-CL', options);
		const formatDateAndTime = formattedDate.split(',');

		const date = formatDateAndTime[0].trim();
		const time = formatDateAndTime[1].trim();

		return date + ' ' + time;
	};

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegexWithDot.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const handleVerifyDisabledButton = {
		firstStep: () => {
			if (flowBanksOrStp) {
				if (
					state.amount !== '' &&
					state.concept !== '' &&
					state.beneficiary !== '' &&
					state.clabe !== '' &&
					state.clabe.length === 18 &&
					!isClabeError
				)
					return false;
			} else {
				if (
					state.amount !== '' &&
					state.currency !== '' &&
					state.concept !== '' &&
					state.beneficiary !== '' &&
					state.trackingKey !== '' &&
					state.clabe !== '' &&
					state.clabe.length === 18 &&
					!isClabeError
				)
					return false;
			}
			return true;
		},
		thirdStep: () => {
			if (state.code.length === 6) return false;
			return true;
		},
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		const regex = /^[0-9]*[.]?([0-9]{1,2})?$/;
		const conceptRegex = /^[a-zA-Z0-9 ]{0,40}$/;
		const nameRegex = /^[a-zA-Z ]{0,40}$/;
		if (name === 'beneficiary' && !nameRegex.test(value)) {
			return;
		}
		if ((name === 'concept' || name === 'trackingKey') && !conceptRegex.test(value)) {
			return;
		}
		if (name === 'amount' && !regex.test(value)) {
			return;
		}
		setState({ ...state, [name]: value });
	};

	const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
		const { type } = e;
		const { value } = e.target;
		if (type === 'focus') {
			setIsEmailValid(false);
		}

		if (type === 'blur') {
			setIsEmailValid(value.length > 0 ? !validateEmail(value) : false);
		}
	};

	const handleChangeSelect = (option: Options) => {
		const { label } = option;
		typeof label == 'string' && setState({ ...state, currency: label });
	};

	const handleContinue = async () => {
		setState({ ...state, loading: true });
		if (flowBanksOrStp) {
			try {
				const response = await OutboundService.generateOutbound({
					merchantCode: merchantSelected?.code as string,
					clabe: state.clabe,
					beneficiary: state.beneficiary,
					amount: +state.amount,
					email: state.email || undefined,
					concept: state.concept,
					reference: referenceWithDate(),
				});
				if (response.error_outbound === null) {
					setState((state) => ({
						...state,
						token: response.token,
						reference: referenceWithDate(),
						loading: false,
					}));

					setSteps((steps) => ({ ...steps, step: 3 }));
				}
			} catch (e: any) {
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
				setState((state) => ({ ...state, loading: false }));
			}
		} else {
			setState({ ...state, loading: false });
			setSteps((steps) => ({ ...steps, step: 4 }));
		}
	};

	const handleContinueSantander = async () => {
		setState({ ...state, loading: true });
		try {
			const response = await OutboundService.generateOutboundSantander({
				merchantCode: merchantSelected?.code as string,
				clabe: state.clabe,
				currency: state.currency.toLowerCase(),
				beneficiary: state.beneficiary,
				amount: +state.amount,
				trackingKey: state.trackingKey,
				concept: state.concept,
			});
			if (response.code === 'S_OK') {
				await setState((state) => ({
					...state,
					loading: false,
				}));
				handleShowAlert({
					message: 'Pago de salida completado correctamente.',
					type: 'success',
				});
				onClose();
			}
		} catch (e: any) {
			handleShowAlert({
				message: e.error.errorMsg,
				type: 'error',
			});
			setState((state) => ({ ...state, loading: false }));
		}
	};

	const handleContinueSendCode = async () => {
		setState((state) => ({ ...state, loading: true }));
		try {
			const response = await OutboundService.sendCodeOutbound({
				merchantCode: merchantSelected?.code as string,
				code: state.code,
				token: state.token,
			});

			if (response.code === 'S_OK') {
				setState((state) => ({
					...state,
					trackingKey: response?.resume.trackingKey,
					loading: false,
				}));
				setSteps({ ...steps, step: 4 });
				return;
			}

			if (response.errorOutbound.length > 0) {
				setSteps({ ...steps, errorClabe: true });
			}

			response.errorOutbound.map(
				(error: any) =>
					handleShowAlert({
						message: errorFormatter(error.code),
						type: 'error',
					}),
				setState((state) => ({ ...state, loading: false })),
			);
		} catch (e: any) {
			if (e.token) {
				setState((state) => ({ ...state, loading: false, token: e.token }));
			}

			if (e.errorOutbound) {
				handleShowAlert({
					message: errorFormatter(e.errorOutbound[0].errorCode),
					type: 'error',
				});
				setSteps({ ...steps, errorClabe: true });
				setState((state) => ({ ...state, loading: false }));
				return;
			}

			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
			setSteps({ ...steps, errorClabe: true });
			setState((state) => ({ ...state, loading: false }));
		}
	};

	const referenceWithDate = () => {
		const date = new Date();
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear().toString().slice(-2);
		return `${day}${month}${year}0`;
	};

	const calcControlDigit = (clabe: string) => {
		if (clabe.length !== 18) return false;

		const clabeWeights = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];
		const clabeList = clabe.split('');
		const clabeInt = clabeList.map((i: string) => Number(i));
		const weighted = [];

		for (let i = 0; i < 18 - 1; i++) {
			weighted.push((clabeInt[i] * clabeWeights[i]) % 10);
		}

		const summed = weighted.reduce((curr, next) => curr + next) % 10;
		const controlDigit = (10 - summed) % 10;

		return controlDigit === clabeInt[17];
	};

	const formResume: TransferResumeItem[] = [
		{
			item: 'Monto',
			data: flowBanksOrStp ? `$${state.amount}` : `${state.amount} ${state.currency}`,
		},
		{
			item: flowBanksOrStp ? 'CLABE beneficiario' : 'Número de cuenta',
			data: state.clabe,
		},
		{
			item: 'Nombre beneficiario',
			data: state.beneficiary,
		},
		{
			item: flowBanksOrStp ? 'Correo electrónico' : 'Clave de rastreo',
			data: flowBanksOrStp ? state.email : state.trackingKey,
		},
		{
			item: 'Concepto',
			data: state.concept,
		},
	];

	const transferResume: TransferResumeItem[] = [
		{
			item: 'Monto',
			data: `$${state.amount}`,
		},
		{
			item: 'CLABE beneficiario',
			data: state.clabe,
		},
		{
			item: 'Nombre beneficiario',
			data: state.beneficiary,
		},
		{
			item: 'Correo beneficiario',
			data: state.email,
		},
		{
			item: 'Concepto',
			data: state.concept,
		},
		{
			item: 'Clave de rastreo',
			data: state.trackingKey,
		},
		{
			item: 'Fecha de solicitud',
			data: formatDateAndTime(),
		},
	];

	const transferSantanderResume: TransferResumeItem[] = [
		{
			item: 'Monto',
			data: state.amount + ' ' + state.currency,
		},
		{
			item: 'Número de cuenta',
			data: state.clabe,
		},
		{
			item: 'Nombre beneficiario',
			data: state.beneficiary,
		},
		{
			item: 'Clave de rastreo',
			data: state.trackingKey,
		},
		{
			item: 'Concepto',
			data: state.concept,
		},
	];

	const handleChangeClabe = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (onlyNumberRegex.test(value)) {
			handleChangeInput(e);
			setIsClabeError(!calcControlDigit(value));
		}
	};

	const renderResume = (resume: TransferResumeItem[]) => {
		return resume.filter(
			(item) => !(item.data === '' && item.item.includes('Correo') && state.email === ''),
		);
	};

	return (
		<Modal title="Pago de salida" show type="medium" onClose={onClose}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					{(steps.step === 1 || steps.step === 2 || steps.step === 3) && (
						<ModalStep>
							PASO {steps.step} DE {flowBanksOrStp ? '3' : '2'}
						</ModalStep>
					)}
					<ModalTitle>
						{steps.step === 1 && 'Completa la información del pago'}
						{steps.step === 2 && 'Verifica la información del pago'}
						{steps.step === 3 && 'Autoriza el pago de salida'}
						{steps.step === 4 && 'En proceso'}
					</ModalTitle>
					<ModalSubtitle>
						{flowBanksOrStp &&
							steps.step === 2 &&
							'Las operaciones realizadas en días no laborales fin de semana o feriados bancarios se procesarán hasta el siguiente día hábil.'}
						{steps.step === 3 &&
							'Enviamos un código de seguridad al correo electrónico asociado a tu cuenta, ingrésalo para autorizar la operación.'}
						{steps.step === 4 &&
							'El pago de salida está en proceso de autorización bancaria, consulta su estatus en la sección “Salidas”.'}
					</ModalSubtitle>

					{steps.step === 1 && (
						<>
							<InputContainer>
								<div>
									<Input
										label="Monto"
										name="amount"
										variant="large"
										value={state.amount}
										onChange={handleChangeInput}
										prefixSymbol="$"
										onKeyDown={handleKeyDownValidateAmount}
									/>
								</div>
								{!flowBanksOrStp && (
									<div>
										<Select
											titleLabel="Divisa"
											size="large"
											defaultOption={1}
											options={optionsBadge}
											onChange={handleChangeSelect}
										></Select>
									</div>
								)}
								<div>
									<Input
										label="CLABE beneficiario (18 dígitos)"
										name="clabe"
										variant="large"
										value={state.clabe}
										onChange={handleChangeClabe}
										maxLength={18}
									/>
									{isClabeError && <HintInput error={isClabeError}>La CLABE es invalida</HintInput>}
								</div>

								<div>
									<Input
										label="Nombre del beneficiario (máx. 40 caracteres)"
										name="beneficiary"
										variant="large"
										value={state.beneficiary}
										onChange={handleChangeInput}
										maxLength={40}
									/>
								</div>

								{!flowBanksOrStp && (
									<div>
										<Input
											label="Clave de rastreo"
											name="trackingKey"
											variant="large"
											value={state.trackingKey}
											onChange={handleChangeInput}
										/>
									</div>
								)}

								<div>
									<Input
										label="Concepto (máx. 40 caracteres)"
										name="concept"
										variant="large"
										value={state.concept}
										onChange={handleChangeInput}
										maxLength={40}
									/>
								</div>

								{flowBanksOrStp && (
									<div>
										<Input
											label="Correo electrónico del beneficiario (opcional)"
											name="email"
											variant="large"
											value={state.email}
											onChange={handleChangeInput}
											onFocus={handleFocus}
											onBlur={handleFocus}
										/>
										<HintInput error={isEmailValid}>
											{!isEmailValid ? (
												<>Lo usaremos para notificar la confirmación del pago</>
											) : (
												<>Debe ser un correo electrónico válido</>
											)}
										</HintInput>
									</div>
								)}
							</InputContainer>

							<Button
								onClick={() => {
									setSteps({ ...steps, step: 2 });
								}}
								disabled={handleVerifyDisabledButton.firstStep()}
								outboundColor
							>
								Siguiente
							</Button>
						</>
					)}

					{steps.step === 2 && <OutboundResume transferResume={renderResume(formResume)} />}

					{steps.step === 2 && (
						<ButtonContainer>
							<Button
								onClick={() => {
									setSteps({ ...steps, step: 1 });
								}}
								disabled={handleVerifyDisabledButton.firstStep()}
							>
								Regresar
							</Button>
							<Button
								onClick={() => {
									flowBanksOrStp ? handleContinue() : handleContinueSantander();
								}}
								disabled={handleVerifyDisabledButton.firstStep()}
								outboundColor
							>
								{flowBanksOrStp ? 'Siguiente' : 'Confirmar'}
							</Button>
						</ButtonContainer>
					)}

					{steps.step === 3 && (
						<>
							<SendCode
								value={state.code}
								name="code"
								handleChangeInput={handleChangeInput}
								handleVerifyDisabledButton={handleVerifyDisabledButton.thirdStep}
								handleContinueSendCode={handleContinueSendCode}
								handleContinue={handleContinue}
								setSteps={setSteps}
								errorClabe={steps.errorClabe}
								setState={setState}
								state={state}
							/>
						</>
					)}

					{steps.step === 4 && (
						<OutboundResume
							transferResume={
								flowBanksOrStp
									? renderResume(transferResume)
									: renderResume(transferSantanderResume)
							}
						/>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export default ModalOutbound;
