import styled from 'styled-components';

export const MenuContainer = styled.div`
	justify-self: flex-end;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		display: none;
	}
`;

interface AvatarContainerProps {
	readonly isActive: boolean;
}

export const AvatarContainer = styled.div<AvatarContainerProps>`
	align-items: center;
	cursor: pointer;
	display: flex;
	gap: 4px;

	svg {
		transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
	}
`;

interface AvatarProps {
	letter: string;
}

export const Avatar = styled.div<AvatarProps>`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.orion};
	border-radius: 50%;
	display: flex;
	font-size: 14px;
	height: 32px;
	justify-content: center;
	width: 32px;

	&::before {
		color: ${({ theme }) => theme.colors.principal.pure};
		content: '${({ letter }) => letter}';
	}
`;

export const MenuContent = styled.ul`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-color: ${({ theme }) => theme.colors.principal.ceres};
	border-style: solid;
	border-top: none;
	border-width: 1px;
	flex-direction: column;
	position: absolute;
	right: 0;
	top: 45px;
	width: 200px;
	z-index: 1;
`;

interface MenuItemProps {
	isActive: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
	background-color: ${({ isActive, theme }) =>
		isActive ? theme.colors.principal.eris : theme.colors.principal.pure};
	cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
	padding: 8px 16px;
	pointer-events: ${({ isActive }) => (isActive ? 'none' : 'auto')};

	&:hover {
		background-color: ${({ theme }) => theme.colors.principal.eris};
	}
`;
