export const CameraIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.5 14.5367C17.5 14.8805 17.3563 15.2102 17.1006 15.4533C16.8449 15.6964 16.498 15.833 16.1364 15.833H3.86364C3.50198 15.833 3.15513 15.6964 2.8994 15.4533C2.64367 15.2102 2.5 14.8805 2.5 14.5367V6.57375C2.5 6.22995 2.64367 5.90023 2.8994 5.65713C3.15513 5.41403 3.50198 5.27745 3.86364 5.27745H6.59091L7.95455 3.33301H12.0455L13.4091 5.27745H16.1364C16.498 5.27745 16.8449 5.41403 17.1006 5.65713C17.3563 5.90023 17.5 6.22995 17.5 6.57375V14.5367Z"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
			stroke="#646174"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
