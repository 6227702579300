import { ErrorBoundary } from '../components/error-boundary';
import { DashboardLayout } from '../layout/dashboard';
import { AccountInformation } from '../views/account-info';
import { AllPayments } from '../views/all-payments';
import { Analytics } from '../views/analytics';
import { CreateAccount } from '../views/auth/create-account';
import { Login } from '../views/auth/login';
import { ResetPassword } from '../views/auth/reset-password';
import { SignUp } from '../views/auth/signup';
import { BusinessTransfer } from '../views/business-transfer';
import { Conciliation } from '../views/conciliation';
import { ConsultRefund } from '../views/consult-refund';
import { Contacts } from '../views/contacts';
import { DirectDebit } from '../views/direct-debit';
import { DirectDebitManagement } from '../views/direct-debit-management';
import { DirectDebitMx } from '../views/direct-debit-mx';
import { DirectDebitMxManagement } from '../views/direct-debit-mx-management';
import { DirectDebitRendition } from '../views/direct-debit-rendition';
import { Eatm } from '../views/eatm';
import { Home } from '../views/home';
import { ManagePlan } from '../views/manage-plan';
import { NonBankCards } from '../views/non-bank-cards';
import { Outbound } from '../views/outbound';
import { PaymentLinks } from '../views/payment-links';
import { PaymentLinksBeta } from '../views/payment-links-beta';
import { PayoutsChile } from '../views/payouts';
import { PayoutsBeneficiary } from '../views/payouts-beneficiary';
import { PayoutsDeposit } from '../views/payouts-deposit';
import { PayoutsDepositMerchants } from '../views/payouts-deposit-merchants';
import { Personalization } from '../views/personalization';
import { Profile } from '../views/profile';
import { QuickTransfer } from '../views/quick-transfer';
import { Refund } from '../views/refund';
import { Subscription } from '../views/subscription';
import { Users } from '../views/users';

const routesConfig = [
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/reset-password',
		element: <ResetPassword />,
	},
	{
		path: '/sign-up',
		element: <SignUp />,
	},
	{
		path: 'create-account',
		element: <CreateAccount />,
	},
	{
		element: <DashboardLayout />,
		path: '/',
		children: [
			{
				errorElement: <ErrorBoundary />,
				path: 'enterprise/',
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: 'all-payments',
						element: <AllPayments />,
					},
				],
			},
			{
				errorElement: <ErrorBoundary />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: 'all-payments',
						element: <AllPayments />,
					},
					{
						path: 'quick-transfer',
						element: <QuickTransfer />,
					},
					{
						path: 'payment-links',
						element: <PaymentLinks />,
					},
					{
						path: 'payment-links-multiuse',
						element: <PaymentLinksBeta />,
					},
					{
						path: 'business-transfer',
						element: <BusinessTransfer />,
					},
					{
						path: 'non-bank-cards',
						element: <NonBankCards />,
					},
					{
						path: 'conciliation',
						element: <Conciliation />,
					},
					{
						path: 'analytics',
						element: <Analytics />,
					},
					{
						path: 'eatm',
						element: <Eatm />,
					},
					{
						path: 'refunds',
						element: <Refund />,
					},
					{
						path: 'refunds-banks',
						element: <Refund />,
					},
					{
						path: 'consult-refunds',
						element: <ConsultRefund />,
					},
					{
						path: 'outbound',
						element: <Outbound />,
					},
					{
						path: 'payouts',
						children: [
							{ index: true, element: <PayoutsChile /> },
							{ path: 'beneficiary', element: <PayoutsBeneficiary /> },

							{ path: 'deposit', element: <PayoutsDeposit /> },
							{ path: 'deposit-merchants', element: <PayoutsDepositMerchants /> },
						],
					},
					{
						path: 'direct-debit-mx/',
						children: [
							{
								index: true,
								element: <DirectDebitMx />,
							},
							{
								path: 'management',
								element: <DirectDebitMxManagement />,
							},
							{
								path: 'subscriptions',
								element: <DirectDebitMx />,
							},
						],
					},
					{
						path: 'direct-debit/',
						children: [
							{
								index: true,
								element: <DirectDebit />,
							},
							{
								path: 'management',
								element: <DirectDebitManagement />,
							},
							{
								path: 'rendition',
								element: <DirectDebitRendition />,
							},
						],
					},
					{
						path: 'account-info',
						element: <AccountInformation />,
					},
					{
						path: 'users',
						element: <Users />,
					},
					{
						path: 'contacts',
						element: <Contacts />,
					},
					{
						path: 'personalization',
						element: <Personalization />,
					},

					{
						path: 'my-profile',
						element: <Profile />,
					},
					{
						path: 'subscription',
						element: <Subscription />,
					},
					{
						path: 'subscription/manage-plan',
						element: <ManagePlan />,
					},
				],
			},
		],
	},
];

export { routesConfig };
