import styled from 'styled-components';

export const TitlePage = styled.h1`
	font-size: 32px;
	font-weight: 400;
	padding-bottom: 16px;
`;

export const BodyWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
	margin-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
		flex-wrap: nowrap;
		justify-content: space-between;
	}
`;

export const CancelPlanContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const EnterpriseContainer = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.orion};
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	padding: 24px;
`;

export const EnterpriseDescription = styled.p`
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 20px;
	line-height: 28px;
`;

export const EnterpriseTitle = styled.h2`
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 42px;
	font-weight: 500;
	line-height: 50px;
	margin-bottom: 24px;
`;

export const EnterpriseWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 350px;
	width: 30%;
`;
