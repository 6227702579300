import { ReactNode, useEffect, useRef, useState } from 'react';

import { useClickOutside } from '../../../hooks/useClickOutside';
import {
	ErrorMsg,
	PrefixText,
	SelectContainer,
	SelectContainerAndTitle,
	SelectLabel,
	SelectOption,
	SelectOptions,
	SelectWrapper,
	TitleText,
} from './styles';

export interface Options {
	value: string | number;
	label: string | ReactNode;
	customLabel?: string;
	show: boolean;
}

interface SelectProps {
	options: Options[];
	name?: string;
	prefix?: string;
	orientation?: 'top' | 'bottom';
	sizeHeight?: 'min' | 'max';
	size?: 'medium' | 'large';
	hasBorder?: boolean;
	defaultOption?: number;
	onChange: (option: Options) => void;
	disabled?: boolean;
	titleLabel?: string;
}

const Select = ({
	options,
	name,
	prefix,
	onChange,
	defaultOption,
	size = 'medium',
	hasBorder = true,
	orientation = 'bottom',
	sizeHeight = 'max',
	disabled = false,
	titleLabel,
}: SelectProps) => {
	const [selectedOption, setSelectedOption] = useState<Options | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [hasError, setHasError] = useState(false);
	const elementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const defaultOptionSelect = options.find((option) => option.value === defaultOption);
		setSelectedOption(defaultOptionSelect || options[0]);
	}, [defaultOption]);

	const handleSetIsOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleSelectOption = (option: Options) => {
		setSelectedOption(option);
		onChange(option);
	};

	useClickOutside({
		elementRef,
		callback: () => {
			if (isOpen && elementRef.current && !elementRef.current.contains(document.activeElement)) {
				if (selectedOption?.value === '' && name) setHasError(true);
				else setHasError(false);
				setIsOpen(false);
			}
		},
	});

	return (
		<SelectWrapper withError={!!name}>
			{titleLabel ? (
				<SelectContainerAndTitle
					ref={elementRef}
					data-name={name}
					isOpen={isOpen}
					onClick={handleSetIsOpen}
					size={size}
					hasBorder={hasBorder}
					hasError={hasError}
					disabled={disabled}
				>
					<TitleText>{titleLabel}</TitleText>
					<SelectLabel>
						{prefix && <PrefixText>{prefix}</PrefixText>}
						{selectedOption?.customLabel || selectedOption?.label}
					</SelectLabel>

					<SelectOptions orientation={orientation} sizeHeight={sizeHeight}>
						{options.map((option) => (
							<SelectOption
								key={option.value}
								value={option.value}
								isSelected={option.value === selectedOption?.value}
								onClick={(e) => {
									if (e.currentTarget.value === selectedOption?.value) return;
									if (e.currentTarget.value === 0 && name) setHasError(true);
									else setHasError(false);
									handleSelectOption(option);
								}}
							>
								{option.label}
							</SelectOption>
						))}
					</SelectOptions>
				</SelectContainerAndTitle>
			) : (
				<SelectContainer
					ref={elementRef}
					data-name={name}
					isOpen={isOpen}
					onClick={handleSetIsOpen}
					size={size}
					hasBorder={hasBorder}
					hasError={hasError}
					disabled={disabled}
				>
					<SelectLabel>
						{prefix && <PrefixText>{prefix}</PrefixText>}
						{selectedOption?.customLabel || selectedOption?.label}
					</SelectLabel>

					<SelectOptions orientation={orientation} sizeHeight={sizeHeight}>
						{options.map((option) => (
							<SelectOption
								key={option.value}
								value={option.value}
								isSelected={option.value === selectedOption?.value}
								onClick={(e) => {
									if (e.currentTarget.value === selectedOption?.value) return;
									if (e.currentTarget.value === 0 && name) setHasError(true);
									else setHasError(false);
									handleSelectOption(option);
								}}
							>
								{option.label}
							</SelectOption>
						))}
					</SelectOptions>
				</SelectContainer>
			)}
			{hasError && <ErrorMsg>Campo requerido</ErrorMsg>}
		</SelectWrapper>
	);
};

export { Select };
