import { SVGProps } from 'react';

export const BancoInternacionalIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="42"
		height="42"
		viewBox="0 0 42 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_616_16139)">
			<rect width="42" height="42" rx="6" fill="#001A72" />
		</g>
		<rect
			x="0.25"
			y="0.25"
			width="41.5"
			height="41.5"
			rx="5.75"
			stroke="#F2F2F2"
			strokeWidth="0.5"
		/>
		<path
			d="M19.6769 35.4454C19.7607 35.4483 19.8458 35.4483 19.9311 35.4454C24.7164 35.3513 28.5031 28.6126 28.3888 20.3948C28.379 19.719 28.3459 19.0539 28.2864 18.4027C28.2817 18.3424 28.276 18.2795 28.276 18.2158C28.3016 17.624 28.5493 17.0667 28.9652 16.6663C29.3812 16.2658 29.9314 16.0549 30.4953 16.0793C30.731 16.0792 30.9655 16.1155 31.191 16.1872C34.0874 17.0874 36.0642 18.6618 36.4356 20.7066C36.4917 21.0105 36.5114 21.3207 36.4937 21.6298C35.9641 28.8283 30.0693 34.7931 22.3636 35.4426C21.9091 35.4815 21.4571 35.5005 21.0075 35.5C20.562 35.4996 20.1161 35.4812 19.6769 35.4454ZM18.6831 35.3335C11.7626 34.3557 6.19701 29.0637 5.5598 22.2641C4.86544 14.8359 10.2931 8.22774 17.9381 6.7914C18.1128 6.76333 18.2892 6.74976 18.466 6.75048C19.7838 6.7324 21.035 7.56131 22.0953 9.00093C22.5103 9.56389 22.7357 10.2555 22.7363 10.9673C22.7363 12.5479 21.6139 13.8845 20.0717 14.3151C19.5743 14.4552 19.0805 14.6052 18.5799 14.7753C14.0681 16.3102 10.4778 18.7944 8.5118 21.4436C10.5401 19.4473 13.9695 17.7221 18.1212 16.7517C18.5825 16.6446 19.0535 16.5907 19.5259 16.5908C22.6087 16.5908 25.1357 18.8258 25.3454 21.6595C25.3846 22.136 25.3948 22.6202 25.3948 23.1091C25.3956 26.1518 24.6822 29.1481 23.3173 31.8324C22.2139 34.0099 20.7378 35.3366 19.0993 35.358H19.0688C18.9398 35.3572 18.8112 35.3494 18.6831 35.3335ZM32.1733 14.5627C30.9604 14.0486 29.679 13.7348 28.3747 13.6322C27.9881 13.6071 27.6142 13.4777 27.2891 13.2567C26.964 13.0357 26.6985 12.7304 26.5185 12.3704C24.8899 8.80174 22.3439 6.52724 19.516 6.58517C19.4424 6.58517 19.3782 6.58824 19.3046 6.5944C19.4275 6.58043 19.5418 6.56857 19.6635 6.55789C27.7465 5.87588 34.9284 11.2949 36.3018 18.8525C35.6858 16.8599 34.1715 15.4329 32.1737 14.559L32.1733 14.5627Z"
			fill="white"
		/>
		<defs>
			<clipPath id="clip0_616_16139">
				<rect width="42" height="42" rx="6" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
