export const DownloadIcon = ({ color = '#fff' }: { color?: string }) => (
	<svg width="22" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19.75 11V15.8125C19.75 16.6948 19.0075 17.4167 18.1 17.4167H4.9C3.9925 17.4167 3.25 16.6948 3.25 15.8125V11"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.8335 9.16663L11.5002 12.8333L15.1668 9.16663"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M11.5 11.9167V4.58337"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
