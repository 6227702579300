import { CommonTableParams, ResponseExport } from '../models/common-payments';
import {
	PrePaymentParams,
	ResponseNewSubscription,
	ResponseTableDirectDebitRendition,
} from '../models/direct-debit';
import { ExportDataParams } from '../models/payment-links-multiuse';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getTableData = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableDirectDebitRendition>(
			'/v1/dd/renditions/table',
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/dd/rendition/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const prePayment = async ({
	merchantCode,
	rut,
	email,
	amount,
	bank_name,
	created_at,
	observation,
	status,
}: PrePaymentParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = {
			rut,
			email,
			amount,
			bank_name,
			created_at,
			observation,
			status,
		};

		const { data } = await client.post<ResponseNewSubscription>('/v1/dd/update/pre-payment', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const DirectDebitRenditionServices = {
	getTableData,
	getExportData,
	prePayment,
};
