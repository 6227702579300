import { BarChart } from 'recharts';
import styled from 'styled-components';

export const BarChartContainer = styled(BarChart)`
	margin: 0;

	svg {
		tspan {
			font-size: 10px;
			letter-spacing: -0.4px;
		}
	}
`;
