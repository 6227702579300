import styled from 'styled-components';

interface AlertSectionProps {
	readonly show?: boolean;
}

export const AlertContainer = styled.div<AlertSectionProps>`
	margin-bottom: ${({ show }) => (show ? '32px' : '0px')};
	width: 100%;
`;

export const AuthFormContainer = styled.div`
	max-width: 500px;
	width: 100%;
`;

export const FreeFirstMonthContent = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 16px;
	width: 100%;
`;

interface HeaderSectionProps {
	readonly hide?: boolean;
}

export const HeaderSection = styled.div<HeaderSectionProps>`
	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		display: ${({ hide }) => (hide ? 'none' : 'block')};
	}
`;

export const Title = styled.h1`
	line-height: 1.1;
	margin-bottom: 24px;
	text-align: center;
`;

export const Subtitle = styled.p`
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 40px;
	text-align: center;
`;

export const ResetContainer = styled.div`
	display: flex;
	justify-content: end;
	margin-bottom: 32px;
	margin-top: -8px;
	padding: 0 3px;
	position: relative;
	width: 100%;
`;

export const ResetPassword = styled.a`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 21px;
`;

export const FormGroup = styled.div`
	display: flex;
	gap: 16px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: column;
		gap: 0;
	}

	> div {
		width: 100%;
	}
`;

export const FooterText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 21px;
	margin: 16px auto 0;
	text-align: center;
	width: 100%;
`;

export const FooterLink = styled.a`
	color: ${({ theme }) => theme.colors.principal.vanth};
	margin-left: 4px;
	margin-right: 4px;
	text-decoration: underline;
`;

export const LastField = styled.div`
	margin-bottom: 32px;
	width: 100%;
`;

export const FieldFullWidth = styled.div`
	margin-bottom: 16px;
	width: 100%;
`;
