import { SVGProps } from 'react';

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3 12C3 12 6.33333 5.33334 12.1667 5.33334C18 5.33334 21.3333 12 21.3333 12C21.3333 12 18 18.6667 12.1667 18.6667C6.33333 18.6667 3 12 3 12Z"
			stroke="#646174"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.1667 14.5C13.5475 14.5 14.6667 13.3807 14.6667 12C14.6667 10.6193 13.5475 9.5 12.1667 9.5C10.786 9.5 9.66675 10.6193 9.66675 12C9.66675 13.3807 10.786 14.5 12.1667 14.5Z"
			stroke="#646174"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
