import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	margin-bottom: 24px;

	@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
		flex-direction: column-reverse;
		margin-bottom: 0;
	}
`;

export const Content = styled.div`
	margin-bottom: 20px;
	width: 100%;

	&:first-of-type {
		width: calc(75% + 64px);

		@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
			width: 100%;
		}
	}

	&:last-of-type {
		margin-left: 32px;
		width: calc(50% - 20px);

		@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
			margin-left: 0;
			width: 100%;
		}
	}
`;

export const TextDescription = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 32px;
	font-weight: 400;
	line-height: 1.24;

	@media (max-width: ${({ theme }) => theme.breakpoints.xg}) {
		font-size: 30px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
		font-size: 26px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
	}
`;

export const SpecialText = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
`;

export const TimeZoneContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: space-between;

		& > div {
			width: 100%;
		}
	}
`;

export const TimeZone = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.air};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	margin-right: 8px;
	padding: 8px 12px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-right: 0;
	}
`;
