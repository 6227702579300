import styled from 'styled-components';

export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 12px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const NewSuscriptionButton = styled.button`
	background: ${({ theme }) => theme.colors.extra.earth};
	border: 0;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	cursor: pointer;
	height: 37px;
	min-width: 100px;
	outline: 0;
	padding: 0 12px;
	transition: background-color 0.2s ease-in-out;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}

	&:hover {
		opacity: 0.7;
	}
`;

export const RightSection = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.eris};
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 12px;
	padding: 8px 12px;
`;

export const SectionContainer = styled.div`
	display: flex;
	gap: 18px;
	margin-bottom: 34px;
	width: 100%;

	> * {
		&:first-child {
			margin-bottom: 0;
			padding: 24px;
		}
	}

	.recharts-bar-rectangle:last-child > path {
		fill-opacity: 0.5;
	}
`;

export const IndicatorsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 22px;
	justify-content: space-between;
	width: 30%;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		flex-direction: row;
		gap: 16px;
		width: 100%;
	}

	> * {
		&:first-child,
		&:last-child {
			height: 100%;
			margin: 0;
			padding: 16px 12px 24px;
			width: 100%;

			div {
				font-size: 38px;
			}
		}
	}
`;
