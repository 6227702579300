import React from 'react';

interface IProps {
	active?: boolean;
}

export const EAtmIcon = ({ active = true }: IProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="4" fill={active ? '#FFBE5C' : '#B7B7B7'} />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.3201 14.3709C18.4631 14.3709 21.011 11.8229 21.011 8.67993C21.011 5.53693 18.4631 2.98901 15.3201 2.98901C12.1771 2.98901 9.62915 5.53693 9.62915 8.67993C9.62915 8.79927 9.63282 8.91775 9.64006 9.03527H14.9648V14.3599C15.0823 14.3672 15.2007 14.3709 15.3201 14.3709Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.03534 9.6315C8.91916 9.62444 8.80205 9.62085 8.68409 9.62085C5.53879 9.62085 2.98901 12.1706 2.98901 15.3159C2.98901 18.4612 5.53879 21.011 8.68409 21.011C11.8294 21.011 14.3792 18.4612 14.3792 15.3159C14.3792 15.198 14.3756 15.0809 14.3685 14.9648H9.03534V9.6315Z"
			fill="white"
		/>
	</svg>
);
