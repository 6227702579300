import { useEffect, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import {
	DirectDebitRendition,
	DirectDebitTableProps,
	ModalProps,
} from '../../../../models/direct-debit';
import { ColumnInterface, RowInterface } from '../../../../models/table';
import { DirectDebitRenditionServices } from '../../../../services/direct-debit-rendition';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import { RetryPayment } from '../modal';
import {
	AmountContainer,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	ManageButton,
	Section,
	StatusContainer,
	Title,
	Value,
} from './styles';

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const DirectDebitTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	pastDate,
	today,
}: DirectDebitTableProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const [manageState, setManageState] = useState<DirectDebitRendition[]>();
	const [manageStateSelected, setManageStateSelected] = useState<DirectDebitRendition>();
	const [showRetryPayment, setShowRetryPayment] = useState({ show: false, current: '' });

	useEffect(() => {
		const _manageState = data.filter((item) => item.status === 'management');
		setManageState(_manageState);
	}, [data]);

	const columns: ColumnInterface<DirectDebitRendition>[] = [
		{
			title: 'RUT',
			propertyName: 'rut',
			cellRender: ({ rut }) => <>{rut}</>,
		},
		{
			title: 'Monto',
			propertyName: 'amount',
			align: 'right',
			cellRender: ({ amount }) => <AmountContainer>{amount}</AmountContainer>,
		},
		{
			title: 'Correo electrónico',
			propertyName: 'email',
			cellRender: ({ email }) => <>{email}</>,
		},
		{
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<StatusContainer>
					<Status status={status} />
				</StatusContainer>
			),
		},
		{
			title: 'Banco',
			propertyName: 'bankName',
			cellRender: ({ bank_name }) => <>{bank_name !== 'DESCONOCIDO' && bank_name}</>,
		},
		{
			sortField: true,
			title: 'Fecha de cobro',
			propertyName: 'paymentAtDate',
			cellRender: ({ payment_at_date }) => <>{payment_at_date}</>,
		},
		{
			title: '',
			propertyName: '',
			cellRender: (rowData: DirectDebitRendition) => (
				<>
					{rowData.status === 'management' && (
						<ManageButton
							onClick={() => {
								setShowRetryPayment({ show: true, current: rowData.merchant_order_id });
								setManageStateSelected(rowData);
							}}
						>
							Gestionar
						</ManageButton>
					)}
				</>
			),
		},
	];

	const detailSection: RowInterface<DirectDebitRendition> = (data) => {
		const descriptionError =
			data.bank_rendition_description_error && JSON.parse(data.bank_rendition_description_error);

		return (
			<DetailsContainer>
				<Section>
					{data.status === 'error' && (
						<>
							<Content>
								<Title>Código del Rechazo</Title>
								<Value>{data.rendition_code_error}</Value>
							</Content>

							<Content>
								<Title>Descripción del fallo</Title>
								<Value>{data && descriptionError?.es}</Value>
							</Content>

							<Content toEnd>
								<Title>Fecha de reintento</Title>
								<Value>{data.retry_at}</Value>
							</Content>
						</>
					)}

					<Content>
						<Title>MerchantOrder_ID</Title>
						<Value>{data.merchant_order_id}</Value>
					</Content>

					<Content>
						<Title>Descripción</Title>
						<Value>{data.description}</Value>
					</Content>

					<Content toEnd>
						<Title>Fecha de cobro</Title>
						<Value>{`${data.payment_at_date} ${data.payment_at_time}`}</Value>
					</Content>
				</Section>
			</DetailsContainer>
		);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await DirectDebitRenditionServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from || pastDate,
					to: filters?.dateRange?.to || today,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
		setShowRetryPayment({ show: false, current: '' });
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	const formatLastSevenDays = () => {
		const today = new Date();
		const lastSevenDays = 1000 * 60 * 60 * 24 * 7;
		const pastDate = new Date(today.getTime() - lastSevenDays);
		return { today, pastDate };
	};

	const renditionStates = ['success', 'auto-management', 'manual-management', 'error'];

	const openNext = () => {
		const index = manageState?.findIndex((item) => item.token === manageStateSelected?.token);
		if (index !== undefined && manageState) setManageStateSelected(manageState[index + 1]);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>

							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			{showRetryPayment.show && (
				<RetryPayment
					onClose={handleCloseModal}
					manageState={manageStateSelected}
					openNext={openNext}
					isLast={
						!!(
							manageState &&
							manageState[manageState.length - 1].token === manageStateSelected?.token
						)
					}
				/>
			)}

			<ETPayTable<DirectDebitRendition>
				title="Todos los cobros"
				data={data || []}
				row={detailSection}
				isLoading={!!isLoading}
				columns={columns}
				hasFilters
				allowExport={validatePermission('QT_EXPORT')}
				stateList={fillStatesWithZeros({ states: renditionStates, stateList })}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				hasDateFilter
				exportAction={handleExportAction}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				statusContent={[
					['success', 'Exitosos'],
					['auto-management', 'Auto Gestión'],
					['manual-management', 'Gestión Manual'],
					['error', 'Fallidos'],
				]}
				today={formatLastSevenDays().today}
				pastDate={formatLastSevenDays().pastDate}
				statusWithCircle="manual-management"
				emptyMessage="No hay información disponible en el periodo seleccionado"
			/>
		</>
	);
};

export { DirectDebitTable };
