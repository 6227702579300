import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
`;

interface ContainerProps {
	readonly $disclaimer?: boolean;
	readonly fileUpload?: boolean;
}

export const CardContainer = styled.div<ContainerProps>`
	background: ${({ theme }) => theme.colors.principal.pure};
	border: ${({ theme }) => `1px solid ${theme.colors.principal.ceres}`};
	border-radius: ${({ $disclaimer }) => ($disclaimer ? '12px 12px 0 0' : '12px')};
	margin-bottom: ${({ fileUpload }) => fileUpload && '50px'};
	min-height: 253px;
	padding: 13px 13px 18px;
	width: 100%;
`;

export const CardBody = styled.div`
	display: flex;
	gap: 14px;
	width: 100%;
`;

export const Step = styled.span`
	background-color: #f3f6ff;
	border-radius: 100%;
	color: #4c6cd1;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	height: 25px;
	letter-spacing: 0.3px;
	padding: 6px 9px;
	width: 25px;
`;

export const CardContent = styled.div`
	width: 100%;
`;

export const Title = styled.div`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 18px;
	margin-bottom: 8px;
`;

interface DescriptionProps {
	step: number;
}

export const Description = styled.div<DescriptionProps>`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 24px;
	max-width: ${({ step }) => step === 1 && '182px'};
`;

export const Disclaimer = styled.div`
	background-color: #e9e1fb;
	border-radius: 0 0 12px 12px;
	display: flex;
	flex-direction: column;
	gap: 3px;
	padding: 18px 14px;
	width: 100%;
`;

export const DisclaimerDescription = styled.span`
	color: #602ad2;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
`;

export const ItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const Item = styled.span`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	color: ${({ theme }) => theme.colors.extra.earth};
	font-size: 16px;
	font-weight: 700;
	width: fit-content;
`;

interface DataContainer {
	itemData?: boolean;
}

export const DataContainer = styled.div<DataContainer>`
	background-color: #f3f6ff;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: ${({ itemData }) => (itemData ? '18px 13px 43px' : '14px 16px 9px')};
	width: 100%;
`;

export const FileContainer = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const NameFile = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: normal;
	overflow-wrap: anywhere;
`;

export const SizeFile = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: normal;
`;

export const DocumentDone = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	margin-top: 14px;
`;
