import { ETPayButton } from '../../../../../components/button';
import { Modal } from '../../../../../components/modal';
import { useFloatingAlert } from '../../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../../hooks/useMerchant';
import { UsersServices } from '../../../../../services/users';
import { errorFormatter } from '../../../../../utils/errors';
import { ModalContainer, ModalFooter, Subtitle, Title } from '../styles';

interface UserSelected {
	id: string;
	email: string;
}

interface RemoveUserProps {
	show: boolean;
	handleCloseModal: () => void;
	userSelected: UserSelected;
	refetch?: () => void;
}

const RemoveUser = ({ show, handleCloseModal, userSelected, refetch }: RemoveUserProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();

	const handleRemoveUser = async () => {
		try {
			await UsersServices.deleteUser({
				merchantCode: merchantSelected?.code as string,
				id: userSelected.id,
				email: userSelected.email,
			});

			handleShowAlert({
				type: 'success',
				message: 'Usuario eliminado',
			});

			refetch && refetch()
			handleCloseModal();
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	return (
		<Modal title="Eliminar usuario" show={show} onClose={handleCloseModal}>
			<ModalContainer>
				<Title>¿Estás seguro?</Title>
				<Subtitle>
					Esta acción eliminará al usuario de tu cuenta y todos tus comercios asociados.
				</Subtitle>

				<Subtitle>
					El proceso puede tardar un poco y no podrás recuperar la información de la cuenta.
				</Subtitle>

				<ModalFooter>
					<ETPayButton title="Regresar" onClick={handleCloseModal} />
					<ETPayButton title="Eliminar usuario" color="secondary" onClick={handleRemoveUser} />
				</ModalFooter>
			</ModalContainer>
		</Modal>
	);
};

export { RemoveUser };
