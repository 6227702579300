interface IconProps {
	active?: boolean;
	color?: string;
}

export const ConciliationIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 2.98096V21.0077" stroke={active ? color : '#0F0F0F'} strokeWidth="1.5" />
		<path
			d="M21.0134 11.9946L2.9867 11.9946"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
		<path
			d="M18.3733 5.62158L5.62647 18.3684"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
		<path
			d="M18.3733 18.3672L5.62647 5.62037"
			stroke={active ? color : '#0F0F0F'}
			strokeWidth="1.5"
		/>
	</svg>
);
