import { ChangeEvent } from 'react';

import { Input } from '../../../../components/input';
import { Button, ButtonContainer, InputContainer, SendEmail } from '../modal/styles';

interface SendCodeProps {
	value: string;
	handleContinueSendCode: () => void;
	handleVerifyDisabledButton: () => boolean;
	handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
	handleContinue: (step: number) => void;
	name: string;
}

const SendCode = ({
	value,
	handleContinueSendCode,
	handleVerifyDisabledButton,
	handleChangeInput,
	handleContinue,
	name,
}: SendCodeProps) => {
	return (
		<>
			<InputContainer>
				<div>
					<Input
						label="Código de verificación"
						variant="large"
						value={value}
						name={name}
						onChange={handleChangeInput}
						maxLength={6}
					></Input>
				</div>
			</InputContainer>
			<ButtonContainer>
				<Button
					onClick={() => handleContinueSendCode()}
					disabled={handleVerifyDisabledButton()}
					outboundColor
				>
					Autorizar
				</Button>
			</ButtonContainer>
			<SendEmail onClick={() => handleContinue(3)}>Reenviar código de verificación</SendEmail>
		</>
	);
};

export default SendCode;
