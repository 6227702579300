import styled from 'styled-components';

export const ExportModalContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
	width: 100%;
`;

export const ExportModalTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0 0 8px;
`;

export const ExportModalDescription = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin: 0 0 28px;
`;

export const VoucherContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const VoucherButton = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	height: 42px;
	justify-content: center;
	width: 42px;
`;

export const AmountContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 16px;
`;

export const CellContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 20px;
	justify-content: center;
`;

export const TableButton = styled.button`
	background: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	gap: 8px;
	height: 42px;
	margin-left: -17px;
	padding: 10px;
	text-align: center;

	&:hover {
		opacity: 0.7;
	}
`;

export const DropdownButton = styled(TableButton)`
	text-align: start;

	&:hover {
		opacity: 1;
	}
`;

export const TopMenuContainer = styled.div`
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	gap: 16px;
	padding: 16px;
`;

export const TopMenu = styled.div`
	border: 1px solid #e6e6e6;
	border-radius: 8px;
	display: flex;
`;

interface TopMenuProps {
	isSelected: boolean;
}

export const TopMenuOption = styled.div<TopMenuProps>`
	align-items: center;
	background: ${({ isSelected }) => (isSelected ? '#fff' : '#140f330d')};
	cursor: pointer;
	display: flex;
	gap: 8px;
	height: 38px;
	justify-content: center;
	padding: 8px 14px;

	div {
		background: ${({ isSelected }) => (isSelected ? '#FF7576' : '#fff')};
		color: ${({ isSelected }) => (isSelected ? 'white' : '#140f33')};
	}

	&:first-child {
		border-bottom-left-radius: 8px;
		border-right: 1px solid #e6e6e6;

		border-top-left-radius: 8px;
	}

	&:last-child {
		border-bottom-right-radius: 8px;
		border-left: 1px solid #e6e6e6;
		border-top-right-radius: 8px;
	}
`;

export const TopMenuNumber = styled.div`
	border-radius: 20px;
	padding-left: 8px;
	padding-right: 8px;
`;

export const LabelFailed = styled.div`
	background-color: #5c2a48;
	border-radius: 4px;
	color: white;
	padding: 6px;
	text-align: center;
	width: 60px;
`;

export const LabelCompleted = styled.div`
	background-color: #e3f8ee;
	border-radius: 4px;
	color: #140f33;
	padding: 6px;
	text-align: center;
	width: 60px;
`;
