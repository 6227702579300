interface IconProps {
	active?: boolean;
	color?: string;
}

export const TNBIcon = ({ active, color }: IconProps) => (
	<svg width="24" height="25" viewBox="0 0 20 18">
		<mask
			id="path-1-outside-1_17676_5810"
			maskUnits="userSpaceOnUse"
			x="3.07275"
			y="8.72601"
			width="14"
			height="9"
			fill="#482524"
		>
			<rect fill={active ? '#482524' : 'none'} x="3.07275" y="8.72601" width="14" height="9" />
			<path
				d="M4.92705 10.7489C4.92705 12.0689 5.46151 13.3348 6.41286 14.2682C7.3642 15.2016 8.6545 15.726 9.9999 15.726C11.3453 15.726 12.6356 15.2016 13.587 14.2682C14.5383 13.3348 15.0728 12.0689 15.0728 10.7489L9.9999 10.7489L4.92705 10.7489Z"
				fill={active ? '#482524' : 'none'}
			/>
		</mask>
		<path
			d="M4.92705 10.7489C4.92705 12.0689 5.46151 13.3348 6.41286 14.2682C7.3642 15.2016 8.6545 15.726 9.9999 15.726C11.3453 15.726 12.6356 15.2016 13.587 14.2682C14.5383 13.3348 15.0728 12.0689 15.0728 10.7489L9.9999 10.7489L4.92705 10.7489Z"
			stroke={active ? '#482524' : '#0f0f0f'}
			strokeWidth="1.5"
			fill={active ? '#482524' : 'none'}
			// mask="url(#path-1-outside-1_17676_5810)"
		/>
		<circle
			cx="13"
			cy="4.5094"
			r="2.75"
			stroke={active ? '#482524' : '#0f0f0f'}
			strokeWidth="1.5"
			fill={active ? '#482524' : 'none'}
		/>
	</svg>
);
