import styled from 'styled-components';

export const TableContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	min-height: 600px;
	overflow-x: auto;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow-x: hidden;
	}
`;

export const TableHeader = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	padding: 0 24px;
`;

export const TableTitle = styled.h2`
	font-size: 18px;
	font-weight: 400;
	padding: 24px 0;
`;

export const DateFilterExportWrapper = styled.div`
	display: flex;
	gap: 16px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		display: none;
	}
`;

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 600px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow-x: scroll;
	}
`;

export const SpinnerContent = styled.div`
	backdrop-filter: blur(10px);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
`;

export const Table = styled.table`
	border: none;
	margin: 0;
	position: relative;
	width: 100%;
`;

export const TableFilter = styled.div`
	display: flex;
	gap: 16px;
`;

export const TableFilterHeader = styled.div`
	display: flex;
	padding-bottom: 32px;
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
		position: relative;
	}
`;