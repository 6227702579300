import { ReactElement, useEffect, useState } from 'react';
import { Bar, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';

import { useScreenSize } from '../../../hooks/useScreenSize';
import { GraphicsFormat } from '../../../models/common-payments';
import { BorderBar } from '../components/border-bar';
import { GraphicsTooltip } from '../components/graphics-tooltip';
import { BarChartContainer } from './styles';

interface ChartBarProps {
	data?: GraphicsFormat[];
	barColor: string;
	barBackground?: string;
	tooltipColor: string;
	domain: AxisDomain;
	tickCount?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customTick?: (props: any) => ReactElement;
}

const ChartBar = ({
	data,
	barColor,
	barBackground,
	tooltipColor,
	domain,
	customTick,
	tickCount,
}: ChartBarProps) => {
	const [tickWidthX, setTickWidthX] = useState(1);
	const { width } = useScreenSize();

	useEffect(() => {
		if (width) {
			const newTickWidthX = width > 574 ? 100 : 1;
			setTickWidthX(newTickWidthX);
		}
	}, [width]);

	return (
		<ResponsiveContainer width="100%" height={240}>
			<BarChartContainer data={data}>
				<XAxis
					dataKey="x_formatted"
					axisLine={false}
					tickLine={false}
					interval={0}
					tickSize={10}
					tick={{ width: tickWidthX }}
				/>
				<YAxis
					width={40}
					dataKey="y"
					orientation="right"
					axisLine={false}
					tickLine={false}
					tick={customTick}
					tickCount={tickCount}
					domain={domain}
				/>
				<Tooltip
					content={(props) => (
						<GraphicsTooltip {...props} tooltipColor={tooltipColor} valueToShow="y_formatted" />
					)}
					cursor={{ fill: 'transparent' }}
					wrapperStyle={{ outline: 'none' }}
				/>
				<Bar
					dataKey="y"
					fill={barColor}
					shape={(props) => <BorderBar {...props} />}
					legendType="none"
					background={{ fill: barBackground }}
				/>
			</BarChartContainer>
		</ResponsiveContainer>
	);
};

export { ChartBar };
