import { ChangeEvent, DragEvent, Fragment, useEffect, useRef, useState } from 'react';

import { UploadIcon } from '../../assets/svg';
import { formatBytes } from '../../utils/common';
import {
	DragAndDropContainer,
	DragAndDropWrapper,
	ErrorDescription,
	ErrorFooter,
	ErrorTitle,
	FileItem,
	Input,
	Spinner,
	Subtitle,
	Title,
	TitleContainer,
} from './styles';

interface DragAndDropProps {
	id: string;
	accept: string[];
	multiple?: boolean;
	maxSize: number;
	errorFile?: boolean;
	setErrorFile?: any;
	onChange: (files: FileList | FileList[]) => void;
	downloadErrorFile?: string;
	loadFile?: boolean;
	cleanState?: boolean;
	disabled?: boolean;
}

type ErrorsAllowed = 'maxSize' | 'multiple' | 'format';

const DragAndDrop = ({
	id,
	accept,
	multiple = false,
	maxSize,
	onChange,
	errorFile,
	setErrorFile,
	downloadErrorFile,
	loadFile,
	cleanState,
	disabled,
}: DragAndDropProps) => {
	const [dragActive, setDragActive] = useState(false);
	const [error, setError] = useState<ErrorsAllowed | null>(null);
	const [isHovered, setIsHovered] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const handleDragEvent = (e: DragEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const fileExist =
			inputRef.current && inputRef.current.files && inputRef.current.files.length >= 1;
		if (
			e.type === 'dragenter' ||
			(e.type === 'dragover' && fileExist) ||
			(e.type === 'drop' && fileExist)
		) {
			setDragActive(true);
			setError(null);
			if (setErrorFile) setErrorFile(false);
		}
	};

	useEffect(() => {
		if (error !== null) setDragActive(false);
	}, [error]);

	const handleDropEvent = (e: DragEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setError(null);

		const { files } = e.dataTransfer;
		if (!multiple && files.length > 1) {
			setError('multiple');
			return;
		}

		if (files.length > 0 && files[0].size > maxSize) {
			setError('maxSize');
			return;
		}

		const filesAccepted = Array.from(files).filter((file) => {
			const fileExtension = file.name.split('.').pop();
			const acceptWithoutDot = accept.map((item) => item.split('.').pop());
			return fileExtension && acceptWithoutDot.includes(fileExtension);
		});

		if (filesAccepted.length === 0) {
			setError('format');
			return;
		}

		if (files.length > 0 && error === null) {
			if (inputRef.current) {
				inputRef.current.files = files;
				onChange(files);
			}
		}
	};

	const onHandleMouse = () => () => {
		if (error !== null) setIsHovered(!!isHovered);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		setError(null);
		if (setErrorFile) setErrorFile(false);

		if (files && files.length > 0 && files[0].size > maxSize) {
			setError('maxSize');
			return;
		}

		if (files && files.length) {
			if (inputRef.current) {
				inputRef.current.files = files;
				onChange(files);
			}
		}
		if (inputRef.current && inputRef.current.files && inputRef?.current?.files?.length >= 1)
			setDragActive(true);
	};

	const resetInputFile = () => {
		if (inputRef?.current && inputRef?.current?.value)
			return (inputRef.current.value = ''), setDragActive(false);
	};

	const handleClickToInput = () => {
		if (inputRef.current) {
			inputRef.current.click();
			resetInputFile();
		}
	};

	if (cleanState) resetInputFile();

	return (
		<>
			<DragAndDropWrapper
				onClick={handleClickToInput}
				onDragEnter={handleDragEvent}
				onDragOver={handleDragEvent}
				onDragLeave={handleDragEvent}
				onDrop={handleDropEvent}
				isDragActive={dragActive}
				hasError={error !== null}
				serverError={errorFile}
				loadFile={loadFile}
				onMouseEnter={onHandleMouse}
				onMouseLeave={() => {
					setIsHovered(false);
					setError(null);
				}}
				cleanState={cleanState}
				disabled={disabled}
			>
				<Input
					ref={inputRef}
					id={id}
					type="file"
					multiple={multiple}
					accept={accept.join(',')}
					onChange={handleChange}
				/>
				<DragAndDropContainer serverError={errorFile}>
					{loadFile ? (
						<Spinner />
					) : (
						<UploadIcon
							uploadSuccess={
								(inputRef.current &&
									inputRef.current.files &&
									!errorFile &&
									inputRef?.current?.files?.length >= 1) ||
								false
							}
							hasError={error !== null}
						/>
					)}

					<TitleContainer>
						{inputRef.current &&
						inputRef.current.files &&
						inputRef.current.files.length >= 1 &&
						error === null ? (
							<>
								<Title uploadSuccess>
									{inputRef.current.files.length === 1 && !errorFile && 'Documento listo'}
								</Title>
								{Array.from(inputRef.current.files).map((file) => (
									<Fragment key={file.name}>
										{!errorFile && <FileItem>{file.name}</FileItem>}
									</Fragment>
								))}
							</>
						) : error === null && !errorFile ? (
							<Title>
								Arrastra y suelta el documento o <span>selecciona el archivo</span>
							</Title>
						) : null}

						{error !== null && (
							<>
								<Title hasError>
									{error === 'format' && 'Archivo no soportado'}
									{error === 'maxSize' && 'Archivo muy pesado'}
								</Title>

								<Subtitle>
									{error === 'format' && `No es un formato ${accept.join(',')}`}
									{error === 'maxSize' && `Máx. soportado ${formatBytes(maxSize)}`}
								</Subtitle>
							</>
						)}

						{errorFile && (
							<Title serverError>
								Vuelve arrastrar y suelta el documento o <span>selecciona el archivo</span>
							</Title>
						)}
					</TitleContainer>
				</DragAndDropContainer>
			</DragAndDropWrapper>
			{errorFile && (
				<ErrorFooter>
					<ErrorTitle>Se han detectado errores el documento</ErrorTitle>
					<ErrorDescription href={downloadErrorFile}>Descarga para ver en detalle</ErrorDescription>
				</ErrorFooter>
			)}
		</>
	);
};

export { DragAndDrop };
