import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Input } from '../../../../components/input';
import { Values } from '../..';
import { Steps } from '../modal';
import { Button, ButtonContainer, InputContainer, SendEmail } from '../modal/styles';

interface SendCodeProps {
	value: string;
	handleContinueSendCode: () => void;
	handleVerifyDisabledButton: () => boolean;
	handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
	handleContinue: () => void;
	name: string;
	setSteps?: Dispatch<SetStateAction<Steps>>;
	errorClabe?: boolean;
	setState?: Dispatch<SetStateAction<Values>>;
	state?: Values;
}

const SendCode = ({
	value,
	handleContinueSendCode,
	handleVerifyDisabledButton,
	handleChangeInput,
	handleContinue,
	name,
	setSteps,
	errorClabe,
	setState,
	state,
}: SendCodeProps) => {
	const handleClick = () => () => {
		setState && state && setState({...state, code: '' });
		setSteps && setSteps({ step: 1, errorClabe: false });
	};

	return (
		<>
			<InputContainer>
				<div>
					<Input
						label="Código de verificación"
						variant="large"
						value={value}
						name={name}
						onChange={handleChangeInput}
						maxLength={6}
					></Input>
				</div>
			</InputContainer>
			<ButtonContainer>
				{errorClabe && <Button onClick={handleClick()}>Regresar</Button>}
				<Button
					onClick={() => handleContinueSendCode()}
					disabled={handleVerifyDisabledButton()}
					outboundColor
				>
					Autorizar
				</Button>
			</ButtonContainer>
			<SendEmail onClick={() => handleContinue()}>Reenviar código de verificación</SendEmail>
		</>
	);
};

export default SendCode;
