import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { QuickTransferIcon } from '../../assets/svg';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { QuickTransferServices } from '../../services/quick-transfer';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { QuickTransferHeader } from './components/header';
import { QuickTransferTable } from './components/table';
import { HeadContainer, TableFilterHeader, TitlePage } from './styles';

const QuickTransfer = () => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);

	const { data: graphs, isLoading: isLoadingGraph } = useQuery({
		enabled: !!merchantSelected && width > 576,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.quickTransfer.getGraphData, merchantSelected?.code],
		queryFn: () => QuickTransferServices.getGraphicsData(merchantSelected?.code as string),
	});

	const {
		data: table,
		isFetching,
		isFetched,
		isLoading: isLoadingTable,
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.quickTransfer.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			QuickTransferServices.getAllPayments({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.quick_transfer.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper title="Quick Transfer" isLoading={isLoadingTable || isLoadingTable || isFetching}>
			<HeadContainer>
				<TitlePage>
					<QuickTransferIcon /> Quick Transfer
				</TitlePage>
			</HeadContainer>

			<TableFilterHeader>
				<DateFilter
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</TableFilterHeader>

			{width > 576 && <QuickTransferHeader data={graphs} loading={isLoadingGraph} />}

			<QuickTransferTable
				data={table?.tableInfo || []}
				isLoading={isFetching && !isFetched}
				stateList={table?.typeOfStatus || []}
				filters={filters}
				onFilterChange={setFilters}
				pagination={pagination}
				setPagination={setPagination}
				totalResults={total}
			/>
		</ViewWrapper>
	);
};

export { QuickTransfer };
