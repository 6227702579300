export const DirectDebitIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="40" height="40" rx="4" fill="#426DD8" />
		<path
			d="M17.301 11.0841C15.0672 11.0841 12.8836 11.7465 11.0263 12.9875C9.16897 14.2285 7.72137 15.9924 6.86655 18.0562C6.01172 20.1199 5.78806 22.3908 6.22385 24.5816C6.65963 26.7725 7.7353 28.7849 9.31481 30.3644C10.8943 31.9439 12.9067 33.0196 15.0976 33.4553C17.2884 33.8911 19.5593 33.6675 21.623 32.8126C23.6868 31.9578 25.4507 30.5102 26.6917 28.6529C27.9327 26.7956 28.5951 24.612 28.5951 22.3782L17.301 22.3782L17.301 11.0841Z"
			fill="#FCFCFC"
		/>
		<path
			d="M33.9933 19.107C33.9933 17.4288 33.6627 15.767 33.0205 14.2165C32.3783 12.6661 31.437 11.2573 30.2503 10.0706C29.0636 8.88395 27.6548 7.94263 26.1044 7.30041C24.5539 6.65818 22.8921 6.32764 21.2139 6.32764L21.2139 19.107H33.9933Z"
			fill="#FCFCFC"
		/>
	</svg>
);
