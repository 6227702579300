import { useEffect, useState } from 'react';
import { Bar, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BorderBar } from '../../../../components/graphs/components/border-bar';
import { CustomizedTick } from '../../../../components/graphs/components/customized-tick';
import { GraphicsTooltip } from '../../../../components/graphs/components/graphics-tooltip';
import { GraphicsFormat } from '../../../../models/analytics';
import { EmptyMessage } from '../clients-chart/styles';
import { BarChartContainer } from '../resume/styles';

interface AverageTicketBankChartProps {
	banks?: GraphicsFormat[];
	loading: boolean;
}

const AverageTicketBankChart = ({ banks }: AverageTicketBankChartProps) => {
	const [data, setData] = useState<GraphicsFormat[]>([]);

	useEffect(() => {
		const data = banks?.map((item) => ({
			x: +item.x,
			x_formatted: item.x_formatted,
			y: item.y,
			y_formatted: item.y_formatted,
		}));

		const dataSorted = data?.sort((a, b) => {
			if (a.y_formatted > b.y_formatted) return 1;
			if (a.y_formatted < b.y_formatted) return -1;
			return 0;
		});

		if (dataSorted) setData(dataSorted);
	}, [banks]);

	return (
		<>
			{data.length === 0 ? (
				<EmptyMessage>No se han registrado cobros en este periodo</EmptyMessage>
			) : (
				<ResponsiveContainer width="100%" height={240}>
					<BarChartContainer data={data} layout="vertical">
						<XAxis
							type="number"
							dataKey="x"
							allowDecimals={false}
							axisLine={false}
							tickLine={false}
							domain={[0, (dataMax: number) => dataMax * 1.1]}
							tick={(props) => {
								if (props.payload && props.payload.value !== 0) {
									props.payload.value = props.payload.value.toFixed(2);
								}
								return <CustomizedTick {...props} symbol="$" />;
							}}
							interval="preserveStartEnd"
						/>
						<YAxis
							dataKey="y_formatted"
							type="category"
							axisLine={false}
							tickLine={false}
							tickMargin={16}
							width={130}
							interval={0}
							tick={(props: any) => (
								<text
									{...props}
									x={props.x}
									y={props.y}
									stroke="none"
									fill="#140F33"
									textRendering="geometricPrecision"
									fontSize={12}
									lengthAdjust="alphabetic"
									alignmentBaseline="middle"
									dominantBaseline="middle"
								>
									{props.payload.value}
								</text>
							)}
						/>
						<Tooltip
							content={(props) => (
								<GraphicsTooltip {...props} tooltipColor="#e8e7eb" valueToShow="x_formatted" />
							)}
							cursor={{ fill: 'transparent' }}
							wrapperStyle={{ outline: 'noe' }}
						/>
						<Bar
							dataKey="x"
							fill="#433F5C"
							legendType="none"
							shape={(props) => <BorderBar {...props} />}
							background={{ fill: '#F2F2F2', radius: 8 }}
						/>
					</BarChartContainer>
				</ResponsiveContainer>
			)}
		</>
	);
};
export { AverageTicketBankChart };
