import styled from 'styled-components';

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
`;

export const Type = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	letter-spacing: 0.6px;
	line-height: 12px;
	margin-bottom: 2px;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 8px;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	line-height: 18px;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 32px;
	width: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 32px;
	width: 100%;
`;

interface ButtonProp {
	readonly conciliationColor?: boolean;
}

export const Button = styled.button<ButtonProp>`
	background: ${({ theme, conciliationColor }) =>
		conciliationColor ? theme.colors.extra.canes : theme.colors.principal.pure};
	border: ${({ theme, conciliationColor }) =>
		!conciliationColor ? `1px solid ${theme.colors.principal.ceres}` : `none`};
	border-radius: 8px;
	color: ${({ theme, conciliationColor }) =>
		conciliationColor ? theme.colors.principal.pure : theme.colors.principal.vanth};
	cursor: pointer;
	font-weight: 200;
	line-height: 18px;
	padding: 15px;
	width: 100%;

	&:hover {
		opacity: 0.7;
	}

	:disabled {
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const TransferDetail = styled.div`
	background: #f8f8f8;
	border-radius: 12px;
	margin-top: 32px;
	padding: 12px;
	width: 100%;
`;

export const TransferDetailContainer = styled.div`
	align-items: center;
	border-bottom: 1px solid #f2f2f2;
	display: flex;
	justify-content: space-between;
	padding: 8px 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
		padding: 8px 0 0;
	}
`;

export const TransferItem = styled.p`
	color: #636172;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;

export const TransferData = styled.p`
	color: #140f33;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.013px;
	line-height: normal;
`;
