export const InputCrossIcon = () => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="11" cy="11" r="7.5" fill="#646174" stroke="#646174" strokeWidth="1.5" />
			<path
				d="M12.8332 9.16669L9.1665 12.8334"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.1665 9.16669L12.8332 12.8334"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
