import { ExportDataParams } from '../models/all-payments';
import { ResponseExport } from '../models/common-payments';
import {
	ExportExcel,
	GetAllRefunds,
	RefundsRequest,
	RefundsResponse,
	ResponseTable,
	ResponseTableConsult,
} from '../models/refund';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getAllRefunds = async ({ ...rest }: GetAllRefunds) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTable>('/v1/payouts/refund/returns/table', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getAllPayments = async ({ ...rest }: GetAllRefunds) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableConsult>('/v1/payouts/refund/table', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getGenerateRefund = async ({
	merchantCode,
	subType,
	paymentToken,
	email,
	concept,
	amount,
}: RefundsRequest) => {
	try {
		const params = { merchant_code: merchantCode };

		const body = [
			{
				sub_type: subType,
				payment_token: paymentToken,
				email: email,
				concept: concept,
				amount: subType === 'partial' ? amount : undefined,
			},
		];

		const bodyRefund = {
			refund_transfers: body,
		};

		const { data } = await client.post<RefundsResponse>('/v1/payouts/generate/refund', bodyRefund, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/payouts/refund/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportExcel = async ({ merchantCode, file }: ExportExcel) => {
	try {
		const params = { merchant_code: merchantCode };
		const ext = (file as File).type;
		const body = file;

		const { data } = await client.post('/v1/payouts/generate/operational-batch', body, {
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
				'file-extension': ext,
			},
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const RefundsServices = {
	getAllRefunds,
	getAllPayments,
	getGenerateRefund,
	getExportData,
	getExportExcel,
};
