import { ETPayButton } from '../../../../../components/button';
import { Modal } from '../../../../../components/modal';
import { useFloatingAlert } from '../../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../../hooks/useMerchant';
import { UsersServices } from '../../../../../services/users';
import { errorFormatter } from '../../../../../utils/errors';
import { ModalContainer, ModalFooter, Subtitle, Title } from '../styles';

interface UserSelected {
	id: string;
	roleId: number;
}

interface EditUserProps {
	show: boolean;
	handleCloseModal: () => void;
	userSelected: UserSelected;
}

const EditUser = ({ show, handleCloseModal, userSelected }: EditUserProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();

	const handleChangeRole = async () => {
		try {
			await UsersServices.changeRole({
				merchantCode: merchantSelected?.code as string,
				id: userSelected.id,
				role: userSelected.roleId,
			});
			handleShowAlert({
				type: 'success',
				message: 'Rol cambiado',
			});
			handleCloseModal();
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};
	return (
		<Modal title="Editar usuario" show={show} onClose={handleCloseModal}>
			<ModalContainer>
				<Title>¿Estás seguro?</Title>
				<Subtitle>Esta acción cambiará los permisos de esta cuenta.</Subtitle>
				<ModalFooter>
					<ETPayButton title="Regresar" onClick={handleCloseModal} />
					<ETPayButton title="Guardar" color="secondary" onClick={handleChangeRole} />
				</ModalFooter>
			</ModalContainer>
		</Modal>
	);
};

export { EditUser };
