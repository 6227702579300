import styled from 'styled-components';

interface PaginationContainerProps {
	hasSelect: boolean;
}

export const PaginationContainer = styled.div<PaginationContainerProps>`
	align-items: center;
	align-self: flex-end;
	display: flex;
	height: 60px;
	justify-content: ${({ hasSelect }) => (hasSelect ? 'space-between' : 'flex-end')};
	padding: 0 16px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: center;
		padding: 21px 47px;
	}
`;

export const SelectContainer = styled.div`
	width: fit-content;
`;

export const PaginationButtonsContainer = styled.div`
	display: flex;
	gap: 8px;
	padding-left: 24px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
	}
`;

export const PaginationButton = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	height: 32px;
	justify-content: center;
	width: 32px;

	&:disabled {
		background-color: ${({ theme }) => theme.colors.principal.ceres};
		cursor: default;
	}
`;

export const PaginationText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin: 0;
`;

export const PaginationTextNumber = styled.span`
	margin-left: 4px;

	&:not(:last-of-type) {
		margin-right: 4px;
	}
`;

export const PaginationTextContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 32px;
	}
`;
