import { PropsWithChildren, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Spinner } from '../spinner';
import { CenterContainer, LoadingContent, SpinnerContainer } from './styles';

interface ViewWrapperProps {
	title: string;
	isLoading?: boolean;
	message?: string;
}

const ViewWrapper = ({
	children,
	title,
	isLoading,
	message = 'Cargando la información',
}: PropsWithChildren<ViewWrapperProps>) => {
	useEffect(() => {
		const bodyContainer = document.querySelector('#body-container');
		if (!isLoading && bodyContainer) {
			bodyContainer.setAttribute('style', 'overflow: auto');
		} else {
			bodyContainer && bodyContainer.setAttribute('style', 'overflow: hidden');
		}
	}, [isLoading]);

	return (
		<>
			{isLoading && (
				<LoadingContent>
					<CenterContainer>
						<SpinnerContainer>
							<Spinner message={message} />
						</SpinnerContainer>
					</CenterContainer>
				</LoadingContent>
			)}
			<HelmetProvider>
				<Helmet>
					<title>{`${title} | ETpay`}</title>
				</Helmet>
				{children}
			</HelmetProvider>
		</>
	);
};

export { ViewWrapper };
