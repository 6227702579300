import { SVGProps } from 'react';

export const MoreIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11 16.5V6.417M5.5 11 11 5.5l5.5 5.5"
			stroke="#17B12A"
			strokeWidth={1.5}
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
