import { SVGProps } from 'react';

export const RightArrowIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.5 11H15.5833"
			stroke={props.color || '#646174'}
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M11 5.5L16.5 11L11 16.5"
			stroke={props.color || '#646174'}
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);
