import { useLocation } from 'react-router';

import { LoaderEtpayLogo } from '../../assets/svg/LoaderEtpayLogo';
import { RightIcon } from '../../assets/svg/RightIcon';
import {
	BreadcrumbContainer,
	LoaderContainer,
	Redirect,
	RedirectContainer,
	Title,
	TitleContainer,
} from './styles';

interface BreadcrumbProps {
	dictionary: Map<string, string>;
	isLoading?: boolean;
}

const Breadcrumb = ({ dictionary, isLoading }: BreadcrumbProps) => {
	const { pathname } = useLocation();
	const params = pathname.split('/');

	return (
		<BreadcrumbContainer>
			<TitleContainer>
				<RedirectContainer>
					{params.slice(1, -1).map((item, i) => (
						<div key={i}>
							<Redirect to={`/${item}`}>{dictionary.get(item)}</Redirect>
							<RightIcon />
						</div>
					))}
				</RedirectContainer>
				<Title>{dictionary.get(params[params.length - 1])}</Title>
			</TitleContainer>

			{isLoading && (
				<LoaderContainer>
					<LoaderEtpayLogo />
				</LoaderContainer>
			)}
		</BreadcrumbContainer>
	);
};

export { Breadcrumb };
