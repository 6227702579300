import styled from 'styled-components';

export const ModalContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const ModalBody = styled.div`
	display: grid;
	gap: 16px;
	width: 100%;
`;

export const Steps = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 8px;
	text-align: center;
`;

export const TitleSpan = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
`;

export const TitleSection = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	line-height: 12px;
	margin: 0 0 8px;
`;

export const Subtitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 32px;
	text-align: center;
`;

export const ModalFooter = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	margin-top: 32px;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

export const Button = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	outline: none;
	padding: 8px 14px;

	svg {
		margin-right: 12px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}
`;

export const ContainerInput = styled.div`
	input {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		&:read-only {
			background-color: #f7f6ff;
			border: none;
			padding-right: 70px;
		}
	}
`;

export const CopyButton = styled.span`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.crab};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.principal.pure};
	display: flex;
	font-size: 12px;
	font-weight: 400;
	height: 28px;
	justify-content: center;
	line-height: 12px;
	padding: 6px;
`;

export const TabsContainer = styled.div`
	margin-top: 32px;
	width: 100%;
`;

export const TabBody = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const QRTitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0;
	text-align: center;
`;

export const QRSubtitle = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.vanth};
	display: flex;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	margin: 0;
	text-align: center;
`;

export const QRCodeContainer = styled.div`
	background-color: #f7f7ff;
	border-radius: 12px;
	margin: 16px 0;
	padding: 23px;

	img {
		height: 114px;
		width: 114px;
	}
`;

export const ScanQRText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
`;

export const ShareView = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	width: 100%;

	button {
		height: 48px;
		width: 100%;
	}
`;

export const BackButtonContainer = styled.div`
	align-items: center;
	align-self: flex-start;
	cursor: pointer;
	display: flex;
	margin-bottom: 24px;
	width: fit-content;

	svg {
		height: 12px;
		stroke: ${({ theme }) => theme.colors.principal.vanth};
	}
`;

export const BackText = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-left: 10px;
`;

export const SendLinkButtonContainer = styled.div`
	margin-top: 24px;
	width: 100%;

	button {
		align-items: center;
		display: flex;
		height: 48px;
		width: 100%;
	}
`;

export const PaymentTypeSection = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
`;

interface HintInputProp {
	error?: boolean;
}

export const HintInput = styled.p<HintInputProp>`
	color: ${({ theme, error }) => (error ? '#FF433E' : theme.colors.principal.moon)};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	width: 100%;
`;

interface HintWithActionProps {
	showingConfig: boolean;
}

export const HintWithAction = styled.p<HintWithActionProps>`
	color: ${({ theme, showingConfig }) => theme.colors.principal[showingConfig ? 'moon' : 'vanth']};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	margin-top: 6px;
	width: 100%;

	span {
		cursor: pointer;
		margin-left: 4px;
		text-decoration: underline;
	}
`;

export const ContainerFilterTime = styled.div`
	display: grid;
	gap: 16px;
	position: relative;
`;

interface PaymentTypeContainerProps {
	isDisabled: boolean;
	isSelected: boolean;
}

export const PaymentTypeContainer = styled.div<PaymentTypeContainerProps>`
	align-items: center;
	background-color: ${({ theme, isDisabled }) =>
		theme.colors.principal[isDisabled ? 'eris' : 'pure']};
	border: 1px solid
		${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'vanth' : 'ceres']};
	border-radius: 12px;
	cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 18px 13px;
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
	position: relative;
	text-align: center;
	width: 100%;
`;

export const ComingSoonContainer = styled.div`
	align-items: center;
	background-color: #fcfcfc;
	border-radius: 3px;
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 8px;
	line-height: 12px;
	padding: 3px;
`;

export const SelectedIconContainer = styled.div`
	position: absolute;
	right: 3px;
	top: 3px;
`;

interface IconContainerProps {
	show: boolean;
	isSelected: boolean;
}

export const IconContainer = styled.div`
	align-items: center;
	border: 1px solid
		${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'orion' : 'vanth']};
	border-radius: 50%;
	display: flex;
	height: 45px;
	justify-content: center;
	opacity: ${({ show }: IconContainerProps) => (show ? 1 : 0)};
	width: 45px;
`;

interface TextsProps {
	isSelected: boolean;
}

export const PaymentTypeTitle = styled.p<TextsProps>`
	color: ${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'orion' : 'vanth']};
	font-size: 14px;
	line-height: 21px;
	margin-top: 8px;
`;

export const PaymentTypeSubTitle = styled.p<TextsProps>`
	color: ${({ theme, isSelected }) => theme.colors.principal[isSelected ? 'orion' : 'vanth']};
	font-size: 11px;
	line-height: 13px;
`;

export const InputContainer = styled.div`
	position: 'relative';
	width: '100%';
`;
