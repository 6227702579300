import styled from 'styled-components';

import { AlignType } from '../../../../models/table';

interface TableDataProps {
	readonly alignContent?: AlignType;
	readonly hasCheckbox?: boolean;
}

export const TableData = styled.td<TableDataProps>`
	font-size: 14px;
	height: 56px;
	padding: ${({ hasCheckbox }) => (hasCheckbox ? '0 0 0 16px' : '0 16px')};
	text-align: ${({ alignContent }) => alignContent};
	width: ${({ hasCheckbox }) => (hasCheckbox ? '20px' : 'auto')};
`;

interface TableRowProps {
	readonly rowChecked?: boolean;
	readonly selectExpanded?: number;
	readonly expanded?: number;
}

export const TableRow = styled.tr<TableRowProps>`
	background-color: ${({ rowChecked, theme }) =>
		rowChecked ? `${theme.colors.principal['light-alamak']} !important` : 'transparent'};

	&:nth-child(${({ selectExpanded }) => selectExpanded}) > td {
		border-bottom: ${({ expanded, theme }) =>
			expanded !== -1 ? 'none' : `1px solid  ${theme.colors.principal.ceres}`};
	}
`;

export const TableBody = styled.tbody`
	${TableData} {
		border-bottom: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	}

	${TableRow} {
		&:nth-of-type(odd) {
			background-color: ${({ theme }) => theme.colors.principal.air};
		}
	}
`;

interface ExpandedButtonProps {
	readonly isExpanded: boolean;
}

export const ExpandButton = styled.button<ExpandedButtonProps>`
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin: 0 4px 0 -8px;
	outline: none;
	padding: 0;
	transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'rotate(0deg)')};
	transition: transform 0.2s linear;
`;

export const ExpandableRow = styled.tr`
	height: max-content;
	width: 100%;
`;

export const ExpandedDataContainer = styled.div`
	align-items: center;
	display: flex;
`;
