import { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { SearchIcon } from '../../../../assets/svg';
import { InputCrossIcon } from '../../../../assets/svg/InputCrossIcon';
import { useMerchant } from '../../../../hooks/useMerchant';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { CountriesAllowed } from '../../../../models/merchant';
import { ETPayFiltersProps } from '../../../../models/table';
import { formatNumber } from '../../../../utils/numbers';
import { Select } from '../../../form/select';
import {
	Filter,
	FilterInput,
	FiltersContainer,
	FilterSection,
	FilterValue,
	Section,
} from './styles';

const ETPayFilters = ({
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	hasSearch,
	isLoading,
	customFilter,
	allowCustomFilter,
	statusContent,
	statusWithCircle,
}: ETPayFiltersProps) => {
	const { width } = useScreenSize();
	const { breakpoints } = useTheme();
	const { merchantSelected } = useMerchant();
	const [search, setSearch] = useState<string>(filters?.search || '');
	const [activeFilter, setActiveFilter] = useState<string>('all');
	const [inputFocus, setInputFocus] = useState<boolean>(false);
	const totalStatus = stateList?.reduce((acc, status) => acc + status.total, 0);
	const location = useLocation(); // TODO: Remove this in the future

	useEffect(() => {
		if (!isLoading && filters?.stateSelected) {
			setActiveFilter(filters.stateSelected);
		}
	}, [isLoading]);

	const statusMap = new Map<string, string>(
		statusContent || [
			['success', 'Exitosos'],
			['error', '	Fallidos'],
			['expired', 'Expirados'],
			[
				'pending',
				merchantSelected?.country === 'CL' || location.pathname === '/conciliation'
					? 'Pendiente'
					: 'En proceso',
			],
		],
	);

	const handleFilter = (e: MouseEvent<HTMLDivElement>) => {
		const { label } = e.currentTarget.dataset;
		if (label) setActiveFilter(label);
		if (onFilterChange && label) {
			onFilterChange({ ...filters, stateSelected: label === 'all' ? '' : label });
			setPagination && pagination && setPagination({ ...pagination, page: 1 });
		}
	};

	const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearch(value);
	};

	const handleClearSearch = () => {
		setSearch('');
		if (onFilterChange) onFilterChange({ ...filters, search: '' });
	};

	const handleGetKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (onFilterChange) {
				onFilterChange({ ...filters, search, stateSelected: '' });
				setPagination && pagination && setPagination({ ...pagination, page: 1 });
			}
			setActiveFilter('all');
		}
	};

	const isManualStatus = stateList?.filter((item) => item.status === 'manual-management' && item)[0]
		?.total;

	return (
		<FiltersContainer>
			<Section>
				{width > +breakpoints.md.split('px')[0] ? (
					<FilterSection>
						{stateList && stateList?.length > 1 && (
							<Filter
								data-label="all"
								active={activeFilter === 'all' && !!totalStatus}
								allowClick={!!totalStatus}
								onClick={handleFilter}
							>
								Todo
								<FilterValue>
									{formatNumber({
										value: totalStatus as number,
										country: merchantSelected?.country as CountriesAllowed,
									})}
								</FilterValue>
							</Filter>
						)}

						{stateList?.map((status) => (
							<Filter
								key={status.status}
								data-label={status.status}
								active={activeFilter === status.status || stateList.length === 1}
								allowClick={status.total > 0}
								onClick={(e) => {
									if (status.total > 0) handleFilter(e);
								}}
							>
								{statusMap.get(status.status)}
								<FilterValue
									statusWithCircle={
										status.status === statusWithCircle && isManualStatus && isManualStatus > 1
											? true
											: false
									}
									active={activeFilter === status.status || stateList.length === 1}
								>
									{formatNumber({
										value: status.total as number,
										country: merchantSelected?.country as CountriesAllowed,
									})}
								</FilterValue>
							</Filter>
						))}
					</FilterSection>
				) : (
					<Select
						options={[
							{ label: 'Todos', value: '', show: true },
							...(stateList?.map((status) => ({
								label: statusMap.get(status.status) as string,
								value: status.status,
								show: status.total > 0,
							})) || []),
						]}
						prefix="Estado:"
						onChange={({ value }) => {
							if (onFilterChange) {
								onFilterChange({ ...filters, stateSelected: value as string });
							}
						}}
					/>
				)}

				{allowCustomFilter && customFilter}
			</Section>
			<Section focus={inputFocus}>
				{hasSearch && (
					<FilterInput
						type="text"
						name="search"
						placeholder="Buscar"
						autoComplete="off"
						value={search}
						onChange={handleChangeInput}
						onKeyDown={handleGetKeyDown}
						leftIcon={<SearchIcon />}
						rightIcon={search && <InputCrossIcon/>}
						rightIconClick={handleClearSearch}
						onFocus={() => setInputFocus(true)}
						onBlur={() => setInputFocus(false)}
					/>
				)}
			</Section>
		</FiltersContainer>
	);
};

export { ETPayFilters };
