import styled from 'styled-components';

export const MessageText = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.moon};
	display: flex;
	font-size: 14px;
	font-weight: 400;
	height: 180px;
	justify-content: center;
	line-height: 1.2;
	text-align: center;
	width: 100%;
`;
