import { ChartPie } from '../../../../components/graphs/pie';
import { ResponseGraphics as Graphics } from '../../../../models/common-payments';
import {
	ItemLoading,
	ListLoading,
	NameLoading,
	ValueLoading,
} from '../../../all-payments/components/banks-pie-chart/styles';
import {
	ContainerWrapper,
	EmptyMessage,
	EmptyMessageWrapper,
	Item,
	List,
	Value,
	Wrapper,
} from './styles';

interface BanksPieChartProps {
	data?: Graphics;
	colors: string[];
	tooltipColor?: string;
	loading?: boolean;
}

const BanksPieChart = ({ data, colors, tooltipColor, loading }: BanksPieChartProps) => {
	const hasData = data && data.data.length > 0;

	return (
		<ContainerWrapper>
			<Wrapper>
				<ChartPie data={data} colors={colors} tooltipColor={tooltipColor} />
			</Wrapper>

			<Wrapper>
				{loading ? (
					<>
						{[...Array(5)].map((_, i) => (
							<ListLoading key={i}>
								<ItemLoading>
									<NameLoading />
									<ValueLoading />
								</ItemLoading>
							</ListLoading>
						))}
					</>
				) : hasData ? (
					data.data.map((item, index) => (
						<List key={item.name} bg={colors[index % colors.length]}>
							<Item>
								{item.name}
								<Value>{item.value}</Value>
							</Item>
						</List>
					))
				) : (
					<EmptyMessageWrapper>
						<EmptyMessage>No ha habido retiros</EmptyMessage>
					</EmptyMessageWrapper>
				)}
			</Wrapper>
		</ContainerWrapper>
	);
};

export { BanksPieChart };
