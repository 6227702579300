interface OnePayProp {
	disabled?: boolean;
}

export const OnePayIcon = ({ disabled }: OnePayProp) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="4" fill="#F2F2F2" />
		<path
			d="M7.0309 4.6233C6.9547 4.16615 7.30724 3.75 7.77069 3.75H16.2293C16.6928 3.75 17.0453 4.16615 16.9691 4.6233L15.4691 13.6233C15.4088 13.9849 15.0959 14.25 14.7293 14.25H9.27069C8.90406 14.25 8.59117 13.9849 8.5309 13.6233L7.0309 4.6233Z"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M7.6 7H4C4 8.66667 4.8 12 8 12"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M16.4 7H20C20 8.66667 19.2 12 16 12"
			stroke={disabled ? '#B7B7B7' : '#646174'}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path d="M8 20L16 20" stroke={disabled ? '#B7B7B7' : '#646174'} strokeWidth="1.5" />
		<path d="M12 20L12 14" stroke={disabled ? '#B7B7B7' : '#646174'} strokeWidth="1.5" />
	</svg>
);
