import { useEffect, useState } from 'react';

import { ETPayEmptyBodyProps } from '../../../../models/table';
import { EmptyBodyTable, EmptyBodyTableText } from './styles';

const ETPayEmptyBody = <Entity,>({
	data,
	isLoading,
	filters,
	emptyMessage,
}: ETPayEmptyBodyProps<Entity>) => {
	const [message, setMessage] = useState('');

	useEffect(() => {
		if (filters?.dateRange?.from && filters?.dateRange?.to && !!filters?.search) {
			setMessage('No se han encontrado resultados');
		} else if (filters?.dateRange?.from || filters?.dateRange?.to) {
			setMessage('No se han encontrado resultados para el rango de fechas seleccionado');
		} else if (filters?.search) {
			setMessage('No se han encontrado resultados para tu búsqueda');
		} else {
			setMessage(emptyMessage || 'No se han recibido cobros en las últimas 24 horas');
		}
	}, [data]);

	return data.length === 0 && !isLoading ? (
		<EmptyBodyTable>
			<EmptyBodyTableText>{message}</EmptyBodyTableText>
		</EmptyBodyTable>
	) : null;
};

export { ETPayEmptyBody };
