interface IProps {
	active?: boolean;
	width?: string;
	height?: string;
}

export const PaymentLinksIcon = ({ active = true, width, height }: IProps) => (
	<svg
		width={width || '85'}
		height={height || '85'}
		viewBox="0 0 85 85"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="85" height="85" rx="12" fill={active ? '#857BF9' : '#B7B7B7'} />
		<path
			d="M44.5468 73.3819C50.2366 73.3819 55.6933 71.0781 59.7165 66.9775C63.7398 62.8769 66 57.3153 66 51.5161C66 45.717 63.7398 40.1553 59.7165 36.0547C55.6933 31.9541 50.2366 29.6504 44.5468 29.6504L44.5468 51.5161L44.5468 73.3819Z"
			fill="#FCFCFC"
		/>
		<path
			d="M40.4532 11.5967C34.7634 11.5967 29.3067 13.9004 25.2835 18.001C21.2602 22.1016 19 27.6632 19 33.4624C19 39.2615 21.2602 44.8232 25.2835 48.9238C29.3067 53.0244 34.7634 55.3281 40.4532 55.3281L40.4532 33.4624L40.4532 11.5967Z"
			fill="#FCFCFC"
		/>
	</svg>
);
