import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import { ETPayButton } from '../../../../../components/button';
import { FormInput } from '../../../../../components/form/form-input';
import { Options, Select } from '../../../../../components/form/select';
import { Modal } from '../../../../../components/modal';
import { useAuth } from '../../../../../hooks/useAuth';
import { useFloatingAlert } from '../../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../../hooks/useMerchant';
import { UsersServices } from '../../../../../services/users';
import { getRoleIdByLabel, getRoleLabelById, reduceRoles } from '../../../../../utils/common';
import { errorFormatter } from '../../../../../utils/errors';
import { InviteNewUserSchema } from '../../../../../utils/validations';
import { FeaturesSection } from '../../features';
import {
	ButtonContainer,
	FeatureSectionTitle,
	FormGroup,
	MainSection,
	Subtitle,
	SummarySection,
} from '../styles';

interface NewUserModalProps {
	show: boolean;
	handleCloseModal: () => void;
	refetch?: () => void;
}

interface NewUserValues {
	name: string;
	lastName: string;
	email: string;
	companyName: string;
}

const defaultValues: NewUserValues = {
	name: '',
	lastName: '',
	email: '',
	companyName: '',
};

const NewUserModal = ({ show, handleCloseModal, refetch }: NewUserModalProps) => {
	const { user } = useAuth();
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [state, setState] = useState({ loading: false });
	const [optionsSelected, setOptionsSelected] = useState({ role: 0, merchant: 0 });

	const createMerchantOptions = () => {
		const merchantOptions: Options[] = [{ label: 'Selecciona un comercio', value: '', show: true }];
		const merchantList = [
			{
				label: merchantSelected?.name,
				value: merchantSelected?.id,
			},
		];
		return merchantOptions.concat(merchantList as Options[]);
	};

	useEffect(() => {
		setOptionsSelected({
			...optionsSelected,
			role: getRoleIdByLabel('Propietario', merchantSelected?.role_id as number),
			merchant: merchantSelected?.id as number,
		});
	}, []);

	return (
		<Modal title="Invitar usuario" show={show} onClose={handleCloseModal} type="large">
			<MainSection>
				<Subtitle>
					Le enviaremos un correo electrónico de invitación a la persona para que acceda a la
					cuenta.
				</Subtitle>

				<Formik
					initialValues={defaultValues}
					onSubmit={async (values) => {
						const { name, lastName, email } = values;
						setState({ ...state, loading: true });
						try {
							await UsersServices.inviteUser({
								merchantCode: merchantSelected?.code as string,
								names: name,
								lastNames: lastName,
								email,
								roleId: optionsSelected.role,
								merchantId: optionsSelected.merchant,
							});
							await handleShowAlert({
								message: 'La invitación se ha enviado correctamente',
								type: 'success',
							});
							refetch && refetch();
							handleCloseModal();
						} catch (error: any) {
							handleShowAlert({
								message: errorFormatter(error.code),
								type: 'error',
							});
						} finally {
							setState({ ...state, loading: false });
						}
					}}
					validationSchema={InviteNewUserSchema}
				>
					{({ isValid, dirty, validateForm }) => (
						<Form>
							<FormGroup>
								<FormInput type="text" label="Nombre(s)" name="name" />
								<FormInput type="text" label="Apellidos" name="lastName" />
							</FormGroup>

							<FormInput type="text" label="Correo electrónico" name="email" />

							<Select
								name="merchants"
								size="large"
								orientation="bottom"
								options={createMerchantOptions() || []}
								defaultOption={merchantSelected?.id}
								disabled
								onChange={() => []}
							/>
							<Select
								titleLabel="Selecciona un rol"
								name="roles"
								size="large"
								orientation="bottom"
								sizeHeight="min"
								options={reduceRoles(
									merchantSelected?.role_id as number,
									user?.email.includes('@etpay'),
								)}
								defaultOption={
									reduceRoles(
										merchantSelected?.role_id as number,
										user?.email.includes('@etpay'),
									).find((role) => role.value === merchantSelected?.role_id)?.value as number
								}
								onChange={(e) => {
									setOptionsSelected({ ...optionsSelected, role: e.value as number });
									validateForm();
								}}
							/>

							<ButtonContainer>
								<ETPayButton
									title={state.loading ? 'Enviando...' : 'Enviar invitación'}
									loading={state.loading}
									color="secondary"
									variant="effect"
									type="submit"
									disabled={
										!isValid || !dirty || !optionsSelected.role || !optionsSelected.merchant
									}
								/>
							</ButtonContainer>
						</Form>
					)}
				</Formik>
			</MainSection>

			<SummarySection>
				<FeatureSectionTitle>Este usuario tendrá permisos para:</FeatureSectionTitle>
				<FeaturesSection
					role={getRoleLabelById(
						optionsSelected.role,
						merchantSelected?.role_id as number,
						user?.email.includes('@etpay'),
					)}
				/>
			</SummarySection>
		</Modal>
	);
};

export { NewUserModal };
