import { Children, cloneElement, PropsWithChildren, ReactElement, useEffect, useRef } from 'react';

import { TimesIcon } from '../../assets/svg';
import {
	ModalBody,
	ModalBodyWrapper,
	ModalContainer,
	ModalContent,
	ModalHeader,
	ModalTitle,
	TimesContainer,
} from './styles';

export type TypeAllowed = 'small' | 'medium' | 'large';
export type VariantAllowed = 'one-layout' | 'two-layout';

interface ModalProps {
	title: string;
	show: boolean;
	type?: TypeAllowed;
	variant?: VariantAllowed;
	allowCloseModal?: boolean;
	onClose: () => void;
	children: ReactElement | ReactElement[];
}

const Modal = ({
	title,
	show,
	type = 'small',
	allowCloseModal = true,
	onClose,
	children,
}: PropsWithChildren<ModalProps>) => {
	const arrayChildren = Children.toArray(children);
	const modal = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (show) document.body.style.overflow = 'hidden';
		else document.body.style.overflow = 'auto';
	}, [show]);

	return (
		<ModalContainer>
			<ModalContent ref={modal} show={show} type={type}>
				<ModalHeader>
					<ModalTitle>{title}</ModalTitle>

					{allowCloseModal && (
						<TimesContainer onClick={() => onClose()}>
							<TimesIcon />
						</TimesContainer>
					)}
				</ModalHeader>

				<ModalBody>
					{Children.map(arrayChildren, (child) => {
						const childElement = child as ReactElement;
						return (
							<ModalBodyWrapper>
								{cloneElement(childElement, { ...childElement.props })}
							</ModalBodyWrapper>
						);
					})}
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export { Modal };
