import styled from 'styled-components';

export const AlertContainer = styled.div`
	margin-bottom: 16px;
`;

export const ResumeContainer = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
		flex-wrap: wrap;
		gap: 20px;
	}
`;

export const SectionContainer = styled.section`
	display: flex;
	gap: 32px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 0;
	}
`;
