import { useState } from 'react';

import { DownloadIcon } from '../../../../assets/svg';
import { DragAndDrop } from '../../../../components/drag-and-drop';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PaymentLinksServices } from '../../../../services/payment-links';
import { errorFormatter } from '../../../../utils/errors';
import { DownloadLink, DragAndDropContainer } from '../../styles';
import {
	Button,
	ModalContainer,
	ModalFooter,
	Steps,
	Subtitle,
	Title,
} from '../new-single-payment/styles';

interface NewBatchPaymentProps {
	show: boolean;
	onClose: () => void;
}

interface DefaultState {
	loading: boolean;
	files?: FileList | FileList[];
	buttonDisabled: boolean;
}

const defaultState: DefaultState = {
	loading: false,
	files: undefined,
	buttonDisabled: true,
};

const NewBatchPayment = ({ show, onClose }: NewBatchPaymentProps) => {
	const { urlToDownloadExample, merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [steps, setSteps] = useState(1);
	const [state, setState] = useState(defaultState);
	const [downloadLink, setDownloadLink] = useState<string>('');

	const handleCloseModal = () => {
		setState(defaultState);
		setSteps(1);
		onClose();
	};

	const handleNext = () => {
		if (steps === 2) return;
		setSteps(steps + 1);
	};

	const handleContinueAction = async () => {
		setState({ ...state, loading: true });

		try {
			if (state.files) {
				const data = await PaymentLinksServices.sendBatchPaymentLinks({
					merchantCode: merchantSelected?.code as string,
					file: state.files?.[0],
				});

				const url = window.URL.createObjectURL(new Blob([data]));
				setDownloadLink(url);
				handleNext();
			}
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		} finally {
			setState({ ...state, loading: false });
		}
	};

	const handleChange = (files: FileList | FileList[]) => {
		const hasFiles = files.length > 0;
		setState({ ...state, files, buttonDisabled: !hasFiles });
	};

	return (
		<Modal title="Enlaces de cobro masivos" show={show} onClose={handleCloseModal}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<Steps>Paso {steps} de 2</Steps>

					<Title>
						{steps === 1 && 'Sube el archivo de excel'}
						{steps === 2 && 'Descarga el archivo'}
					</Title>

					<Subtitle>
						{steps === 1 && (
							<>
								Recuerda subir el archivo con el{' '}
								<a href={urlToDownloadExample} download>
									siguiente formato
								</a>
								.
							</>
						)}
						{steps === 2 &&
							'Ya está listo el documento con todos los enlaces para que tus clientes puedan pagarte.'}
					</Subtitle>

					{steps === 1 && (
						<DragAndDropContainer>
							<DragAndDrop
								id="upload-batch-payments"
								accept={['.csv']}
								maxSize={2100000}
								onChange={handleChange}
							/>
						</DragAndDropContainer>
					)}

					<ModalFooter>
						{steps === 1 && <Button onClick={handleContinueAction}>Siguiente</Button>}

						{steps === 2 && (
							<DownloadLink href={downloadLink} download="payment-links-generated.csv">
								Descargar <DownloadIcon />
							</DownloadLink>
						)}
					</ModalFooter>
				</ModalContainer>
			)}
		</Modal>
	);
};

export { NewBatchPayment };
