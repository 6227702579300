import { useMerchant } from '../../hooks/useMerchant';
import { PrimitiveStatus, Status as IStatus } from '../../models/common-payments';
import { Label, StatusContainer } from './styles';

export type SizeAllowed = 'md' | 'sm';

interface StatusProps {
	status: IStatus;
	size?: SizeAllowed;
}

interface StatusProps {
	status: IStatus;
	size?: 'md' | 'sm';
}

interface StatusTransform {
	label: string;
	type: string;
}

const Status = ({ status, size }: StatusProps) => {
	const { merchantSelected } = useMerchant();

	const colorStatus = new Map<IStatus, StatusTransform>([
		[
			'success',
			{
				label:
					location.pathname === '/consult-refunds' || location.pathname === '/outbound'
						? 'Liquidado'
						: 'Exitoso',
				type: 'success',
			},
		],
		[
			'pending',
			{
				label:
					location.pathname === '/direct-debit/rendition' || location.pathname === '/payouts'
						? 'En proceso'
						: merchantSelected?.country === 'CL' ||
						  location.pathname === '/payment-links-multiuse' ||
						  location.pathname === '/direct-debit-mx/subscriptions'
						? 'Pendiente'
						: 'En proceso',
				type: 'pending',
			},
		],
		[
			'waiting_codi',
			{ label: merchantSelected?.country === 'CL' ? 'Pendiente' : 'En proceso', type: 'pending' },
		],
		['expired', { label: 'Expirado', type: 'expired' }],
		['timeout', { label: 'Expirado', type: 'expired' }],
		[
			'error',
			{ label: location.pathname === '/direct-debit-mx/subscriptions' ? 'Fallida' : 'Fallido', type: 'error' },
		],
		['authentication_error', { label: 'Fallido', type: 'error' }],
		['bank_error', { label: 'Fallido', type: 'error' }],
		['transaction_error', { label: 'Fallido', type: 'error' }],
		['failed', { label: 'Fallido', type: 'error' }],
		['active', { label: 'Activa', type: 'success' }],
		[
			'cancelled',
			{
				label: location.pathname === '/direct-debit-mx/subscriptions' ? 'Cancelada' : 'Cancelados',
				type: location.pathname === '/direct-debit-mx/subscriptions' ? 'expired' : 'error',
			},
		],
		['actived', { label: 'Activo', type: 'success' }],
		['finalized', { label: 'Finalizado', type: 'expired' }],
		['Capturada', { label: 'Capturada', type: 'success' }],
		['Liquidada', { label: 'Liquidada', type: 'expired' }],
		['manual_collection', { label: 'Cobro manual', type: 'success' }],
		['management', { label: 'Gestión manual', type: 'management' }],
		['pending_retry', { label: 'En reintento', type: 'sent_retry' }],
		['disabled', { label: 'Desactivada', type: 'expired' }],
		['enabled', { label: 'Activa', type: 'success' }],
		['success_merchant', { label: 'Exitoso', type: 'success' }],
		['completed', { label: 'Exitoso', type: 'success' }],
		['process', { label: 'En proceso', type: 'pending' }],
		['liquidated', { label: 'Liquidado', type: 'success' }],
		['schedule', { label: 'Programado', type: 'expired' }],
		['in_progress', { label: 'En proceso', type: 'pending' }],
		['pending_voucher', { label: 'En proceso', type: 'pending' }],
		['pending_ops', { label: 'En proceso', type: 'pending' }],
		['processing', { label: 'En proceso', type: 'pending' }],
	]);

	return (
		<StatusContainer type={(colorStatus.get(status)?.type as PrimitiveStatus) || 'pending'}>
			<Label type={(colorStatus.get(status)?.type as PrimitiveStatus) || 'pending'} size={size}>
				{colorStatus.get(status)?.label}
			</Label>
		</StatusContainer>
	);
};

export { Status };
