import { Dispatch, SetStateAction, useState } from 'react';

import { EyeIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { RefundsConsultData } from '../../../../models/refund';
import {
	ColumnInterface,
	Filters,
	FilterStatus,
	Pagination,
	RowInterface,
} from '../../../../models/table';
import { RefundsServices } from '../../../../services/refund';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import {
	AmountContainer,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	Section,
	StatusContainer,
	Title,
	Value,
	VoucherButton,
	VoucherContainer,
} from './styles';

interface RefundsTableProps {
	data: RefundsConsultData[];
	isLoading?: boolean;
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	today?: Date;
	pastDate?: Date;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	stateList: FilterStatus[];
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const RefundsTable = ({
	data,
	isLoading,
	today,
	pastDate,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	stateList,
}: RefundsTableProps) => {
	const { merchantSelected } = useMerchant();
	const flowBanksOrStp = true;
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();

	const getColumns = () => {
		const columns: ColumnInterface<RefundsConsultData>[] = [
			{
				sortField: true,
				title: 'Clave de rastreo',
				propertyName: 'trackingKey',
				cellRender: ({ trackingKey }) => <>{trackingKey}</>,
			},
			{
				title: 'CLABE beneficiario',
				propertyName: 'paymentConcept',
				cellRender: ({ beneficiaryAccount }) => <>{beneficiaryAccount}</>,
			},
			{
				sortField: true,
				title: 'Monto devuelto',
				align: 'right',
				propertyName: 'payoutAmount',
				cellRender: ({ payoutAmount }) => <AmountContainer>{payoutAmount}</AmountContainer>,
			},
			{
				sortField: true,
				title: 'Banco',
				propertyName: 'targetBank',
				cellRender: ({ targetBank }) => <>{targetBank}</>,
			},
			{
				title: 'Estatus',
				align: 'center',
				propertyName: 'status',
				cellRender: ({ finalstatus }) => (
					<StatusContainer>
						<Status status={finalstatus} />
					</StatusContainer>
				),
			},
			{
				sortField: true,
				title: 'Fecha de solicitud',
				propertyName: 'createdatDate',
				cellRender: ({ createdatDate, createdatTime }) => (
					<DateAndTime date={createdatDate} time={createdatTime} />
				),
			},
			{
				title: 'Fecha de devolución',
				propertyName: 'payoutDate',
				cellRender: ({ payoutDate, payoutTime }) => (
					<DateAndTime date={payoutDate} time={payoutTime} />
				),
			},
			{
				title: 'Comprobante',
				align: 'center',
				hide: merchantSelected?.country !== 'MX',
				propertyName: 'urlCEP',
				cellRender: ({ urlCEP }) => (
					<VoucherContainer>
						{urlCEP && (
							<VoucherButton onClick={() => window.open(urlCEP)}>
								<EyeIcon />
							</VoucherButton>
						)}
					</VoucherContainer>
				),
			},
		];
		if (!flowBanksOrStp) {
			const columnsBanks: ColumnInterface<RefundsConsultData>[] = [
				{
					sortField: true,
					title: 'Clave de rastreo',
					propertyName: 'trackingKey',
					cellRender: ({ trackingKey }) => <>{trackingKey}</>,
				},
				{
					sortField: true,
					title: 'CLABE beneficiario',
					propertyName: 'beneficiaryAccount',
					cellRender: ({ beneficiaryAccount }) => <>{beneficiaryAccount}</>,
				},
				{
					sortField: true,
					title: 'Beneficiario',
					propertyName: 'beneficiaryAccount',
					cellRender: ({ beneficiaryName }) => <>{beneficiaryName}</>,
				},
				{
					sortField: true,
					title: 'Monto devuelto',
					align: 'right',
					propertyName: 'payoutAmount',
					cellRender: ({ payoutAmount }) => <AmountContainer>{payoutAmount}</AmountContainer>,
				},
				{
					sortField: true,
					title: 'Banco',
					propertyName: 'targetBank',
					cellRender: ({ targetBank }) => <>{targetBank}</>,
				},
				{
					sortField: true,
					title: 'Fecha',
					propertyName: 'createdatDate',
					cellRender: ({ createdatDate, createdatTime }) => (
						<DateAndTime date={createdatDate} time={createdatTime} />
					),
				},
			];
			return columnsBanks;
		}
		return columns;
	};

	const columns = getColumns();

	const detailSection: RowInterface<RefundsConsultData> = (data) => {
		const isError = data.finalstatus === 'error';

		return (
			<DetailsContainer>
				<Section>
					<Content>
						<Title>Concepto</Title>
						<Value>{data.paymentConcept}</Value>
					</Content>
					<Content>
						<Title>Tipo de devolución</Title>
						<Value>{data.payoutSubType === 'full' ? 'Total' : 'Parcial'}</Value>
					</Content>
					<Content>
						<Title>Correo electrónico del beneficiario</Title>
						<Value>{data.email}</Value>
					</Content>

					{isError && (
						<Content>
							<Title>Detalle del error</Title>
							<Value>{data.detail}</Value>
						</Content>
					)}
				</Section>

				<Section>
					<Content>
						<Title>Payment ID</Title>
						<Value>{data.paymentToken}</Value>
					</Content>

					{!isError && (
						<Content>
							<Title>Referencia del pago</Title>
							<Value>{data.reference}</Value>
						</Content>
					)}
				</Section>
			</DetailsContainer>
		);
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await RefundsServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from || pastDate,
					to: filters?.dateRange?.to || today,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const consultRefundStates = ['liquidated', 'pending', 'error'];
	const refundStatesBanks = ['liquidated'];

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			<ETPayTable<RefundsConsultData>
				title="Todas las devoluciones"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={flowBanksOrStp ? detailSection : undefined}
				hasFilters
				hasDateFilter
				allowExport={validatePermission('REFUNDS_EXPORT')}
				exportAction={handleExportAction}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				hasPagination
				today={today}
				pastDate={pastDate}
				totalResults={totalResults}
				pagination={pagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				setPagination={setPagination}
				stateList={fillStatesWithZeros({
					states: flowBanksOrStp ? consultRefundStates : refundStatesBanks,
					stateList,
				})}
				statusContent={
					flowBanksOrStp
						? [
								['liquidated', 'Liquidados'],
								['pending', 'En proceso'],
								['error', 'Fallidos'],
						  ]
						: [['liquidated', 'Liquidados']]
				}
			/>
		</>
	);
};

export { RefundsTable };
