import styled, { css, keyframes } from 'styled-components';

const animation = () => css`
	animation: ${rotate} 1.3s ease-out infinite;
`;

const rotate = keyframes`
  70% {
    transform: rotate(390deg);
  }
	
  100% {
    transform: rotate(330deg);
  }
`;

export const SpinnerContainer = styled.div`
	align-items: center;
	display: flex;
	filter: blur(5);
	height: 100%;
	justify-content: center;
	width: 100%;

	svg {
		${animation};
		height: 48px;
		margin-bottom: 16px;
		width: 48px;
	}
`;

export const SpinnerWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 100%;
	width: 328px;
`;

export const Text = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 18px;
	font-weight: 400;
	line-height: 1.2;
	text-align: center;
`;
