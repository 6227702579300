import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
	100% {
		filter: blur(5);
    transform: rotate(1turn);
  }
`;

const animation = () => css`
	animation: ${rotate} 2s linear infinite;
`;

interface ButtonContainerProps {
	readonly $loading?: boolean;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
	align-items: center;
	background: none;
	border: none;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	outline: none;
	overflow: hidden;
	padding: 3px;
	position: relative;
	width: 100%;

	&::before {
		${({ $loading }) => $loading && animation};
	}

	${({ $loading }) =>
		$loading &&
		`
		border: none;
		
    &::before {
      background-color: none;
			background-image: 
        linear-gradient(transparent, transparent), 
        linear-gradient(transparent, transparent), 
        linear-gradient(transparent, transparent), 
        linear-gradient(#D5F4E6, #D5F4E6);
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      content: '';
      height: 500px;
      position: absolute;
      width: 500px;
  `}
`;

interface PrimaryButtonProps {
	readonly active?: boolean;
}

export const PrimaryButton = styled.button<PrimaryButtonProps>`
	background-color: ${({ active, theme }) =>
		active ? theme.colors.principal.alamak : theme.colors.principal.eris};
	border: none;
	border-radius: 8px;
	color: ${({ active, theme }) =>
		active ? theme.colors.principal.orion : theme.colors.principal.moon};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fonts.stack};
	font-size: 16px;
	font-style: normal;
	height: 48px;
	padding: 3px;
	text-align: center;
	transition: opacity 0.2s ease;
	width: 100%;
	z-index: 2;

	&:hover,
	&:focus {
		opacity: 0.7;
	}

	&:disabled {
		background-color: ${({ theme }) => theme.colors.principal.eris};
		color: ${({ theme }) => theme.colors.principal.orion};
		cursor: default;
		pointer-events: none;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

interface SecondaryButtonProps {
	readonly color: 'primary' | 'secondary' | 'text';
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
	align-items: center;
	background-color: ${({ theme, color }) =>
		color === 'text'
			? 'transparent'
			: theme.colors.principal[color === 'secondary' ? 'orion' : 'pure']};
	border: ${({ color }) => (color !== 'text' ? '1px' : '0')} solid
		${({ theme, color }) => theme.colors.principal[color === 'primary' ? 'ceres' : 'orion']};
	border-radius: 8px;
	color: ${({ theme, color }) => theme.colors.principal[color === 'secondary' ? 'pure' : 'vanth']};
	cursor: pointer;
	display: flex;
	justify-content: center;
	min-width: 80px;
	outline: none;
	padding: 8px 12px;
	transition: opacity 0.2s ease;

	&:hover,
	&:focus {
		opacity: 0.7;
	}

	&:disabled {
		background-color: ${({ theme }) => theme.colors.principal.ceres} !important;
		border-color: ${({ theme }) => theme.colors.principal.ceres} !important;
		color: ${({ theme }) => theme.colors.principal.moon} !important;
		cursor: default;
		pointer-events: none;
	}
`;
