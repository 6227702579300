import { Card, Spinner, Status, StatusContainer, Title } from './styles';

interface CardStatusProps {
	title: string;
	status: string;
}

const CardStatus = ({ title, status }: CardStatusProps) => {
	return (
		<Card>
			<Title>{title}</Title>
			<StatusContainer>
				<Status>{status === 'in_progress' && 'En proceso'}</Status>
				<Spinner></Spinner>
			</StatusContainer>
		</Card>
	);
};

export { CardStatus };
