import { SVGProps } from 'react';

export const TrophyIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="29"
		height="30"
		viewBox="0 0 29 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.36103 5.33733C8.35341 5.29161 8.38867 5.25 8.43501 5.25H20.2304C20.2767 5.25 20.312 5.29161 20.3043 5.33733L18.2738 17.5207C18.2678 17.5568 18.2365 17.5833 18.1998 17.5833H10.4656C10.4289 17.5833 10.3976 17.5568 10.3916 17.5207L8.36103 5.33733Z"
			stroke={props.color || '#646174'}
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M9.07734 9.02779H4.77734C4.77734 11.0185 5.7329 15 9.55512 15"
			stroke={props.color || '#646174'}
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M19.5887 9.02779H23.8887C23.8887 11.0185 22.9331 15 19.1109 15"
			stroke={props.color || '#646174'}
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path d="M9.55518 24.5556H19.1107" stroke={props.color || '#646174'} strokeWidth="2" />
		<path d="M14.333 24.5556V17.3889" stroke={props.color || '#646174'} strokeWidth="2" />
	</svg>
);
