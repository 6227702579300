import styled from 'styled-components';

export const SectionContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 12px;
	margin-bottom: 32px;
	padding: 16px 16px 32px;
	width: 100%;

	.recharts-bar-rectangle:last-child > rect {
		fill-opacity: 0.5;
	}
`;

export const SectionHeader = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	position: relative;
`;

export const SectionTitle = styled.h2`
	font-size: 18px;
	font-weight: 400;
`;
