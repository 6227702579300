import { ChangeEvent, useState } from 'react';

import { DownloadIcon } from '../../../../assets/svg';
import { DragAndDrop } from '../../../../components/drag-and-drop';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { CountriesAllowed } from '../../../../models/merchant';
import { PayoutsService } from '../../../../services/payouts';
import { fullDateFormat } from '../../../../utils/dates';
import { errorFormatter } from '../../../../utils/errors';
import { formatNumber } from '../../../../utils/numbers';
import {
	Button,
	ButtonContainer,
	DownloadLink,
	DragAndDropContainer,
	List,
	ModalContainer,
	ModalStep,
	ModalSubtitle,
	ModalTitle,
	ResumeContainer,
} from '../modal/styles';
import OutboundResume from '../outboundResume';
import SendCode from '../sendCode';

interface NewBatchPaymentProps {
	show: boolean;
	onClose: () => void;
}

interface DefaultState {
	loading: boolean;
	files?: FileList | FileList[];
	buttonDisabled: boolean;
	code: string;
	token: string;
	totalAmount: number;
	quantityAmount: number;
	requestDate: string;
	status: string;
	maximumBalance?: boolean;
}

const defaultState: DefaultState = {
	loading: false,
	files: undefined,
	buttonDisabled: true,
	code: '',
	token: '',
	requestDate: '',
	status: '',
	totalAmount: 0,
	quantityAmount: 0,
	maximumBalance: false,
};

interface ErrorState {
	quantityPayment: string;
	totalAmount: string;
	csv: string;
}

const errorState: ErrorState = {
	quantityPayment: '',
	totalAmount: '',
	csv: '',
};

interface TransferResumeItem {
	item: string;
	data: string;
}

const NewBatchPayment = ({ show, onClose }: NewBatchPaymentProps) => {
	const { user } = useAuth();
	const {
		merchantSelected,
		urlToDownloadExamplePayoutsChile,
		urlToDownloadExamplePayoutsSbifChile,
	} = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [steps, setSteps] = useState(1);
	const [state, setState] = useState(defaultState);
	const [error, setError] = useState(errorState);

	const handleCloseModal = () => {
		setState(defaultState);
		setSteps(1);
		onClose();
	};

	const handleContinueAction = async () => {
		setState({ ...state, loading: true });
		try {
			if (!state.files) {
				setState({ ...state, loading: false });
				return;
			}

			const data = await PayoutsService.sendBatchOutbound({
				merchantCode: merchantSelected?.code as string,
				file: state.files[0],
			});

			setSteps(2);

			if (data.code === 'E_PAYMENTS_NOTFOUND') {
				const newBlob = new Blob([data.csv]);
				const url = window.URL.createObjectURL(newBlob);
				setError({
					quantityPayment: data.resume.totalTransfersWithError,
					totalAmount: data.resume.amount,
					csv: url,
				});
				setSteps(5);
				return;
			}

			const requestDate = new Date();

			setState((prevState) => ({
				...prevState,
				totalAmount: data.resume.amount,
				quantityAmount: data.resume.totalTransfersSuccess,
				requestDate: fullDateFormat(String(requestDate)),
			}));
			if (steps === 3) {
				return setSteps(3);
			}
			setSteps(2);
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		} finally {
			setState((prevState) => ({ ...prevState, loading: false }));
		}
	};

	const handleGetCode = async () => {
		setState({ ...state, loading: true });
		try {
			const data = await PayoutsService.getCodeOutbound({
				merchantCode: merchantSelected?.code as string,
			});
			setState({ ...state, token: data.token, loading: false });
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		} finally {
			setState((prevState) => ({ ...prevState, loading: false }));
		}
	};

	const handleContinueSendCode = async () => {
		setState({ ...state, loading: true });
		try {
			const response = await PayoutsService.sendCodeOutbound({
				merchantCode: merchantSelected?.code as string,
				code: state.code,
				token: state.token,
			});

			setState((state) => ({
				...state,
				loading: false,
				quantityAmount: response.resume.totalTransfers,
				totalAmount: response.resume.amount,
				requestDate: response.resume.requestDate,
				status: response.resume.status,
			}));
			setState({ ...state, loading: false });
			setSteps(4);
		} catch (e: any) {
			if (e.code === 'E_OTP_INVALID' || e.code === 'E_JWT_EXPIRED') {
				setSteps(3);
				setState({ ...state, loading: false });
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
				return;
			} else if (e.code === 'INSUFFICIENT_BALANCE') {
				setState({ ...state, maximumBalance: true });
				setSteps(6);
				errorData.map((item) => item.data === 'Saldo insuficiente');
			}

			if (e.code !== 'INSUFFICIENT_BALANCE') {
				setSteps(3);
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
				setState({ ...state, loading: false });
			}
		}
	};

	const handleChange = (files: FileList | FileList[]) => {
		const hasFiles = files.length > 0;
		setState({ ...state, files, buttonDisabled: !hasFiles });
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleVerifyDisabledButton = () => {
		if (state.files === undefined) return true;
		return false;
	};

	const resumeData = [
		{
			item: 'Número de pagos de salida',
			data: state.quantityAmount,
		},
		{
			item: 'Monto total',
			data: `$${formatNumber({
				value: state.totalAmount as number,
				country: merchantSelected?.country as CountriesAllowed,
			})}`,
		},
	];

	const processData = [
		{
			item: 'Número de pagos de salida',
			data: state.quantityAmount,
		},
		{
			item: 'Monto total',
			data: `$${formatNumber({
				value: state.totalAmount as number,
				country: merchantSelected?.country as CountriesAllowed,
			})}`,
		},
		{
			item: 'Fecha de solicitud',
			data: state.requestDate,
		},
	];

	const errorData = [
		{
			item: 'Número de pagos con error',
			data: error.quantityPayment,
		},
		{
			item: 'Monto total pagos con error',
			data: `$${formatNumber({
				value: +error.totalAmount as number,
				country: merchantSelected?.country as CountriesAllowed,
			})}`,
		},
	];

	const errorBalance = [
		{
			item: 'Error encontrado',
			data: 'Saldo insuficiente',
		},
	];

	const codeResume: TransferResumeItem[] = [
		{
			item: 'Correo enviado a',
			data: user?.email ? user.email : '',
		},
	];

	return (
		<Modal title="Pagos de salida masivos" show={show} type="medium" onClose={handleCloseModal}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					{(steps === 1 || steps === 2 || steps === 3) && <ModalStep>PASO {steps} DE 3</ModalStep>}
					<ModalTitle steps={steps}>
						{steps === 1 && 'Sube la información de los pagos'}
						{steps === 2 && 'Verifica la información de los pagos'}
						{steps === 3 && 'Autoriza los pagos de salida'}
						{steps === 4 && 'En proceso'}
						{(steps === 5 || steps === 6) && 'Error en la solicitud'}
					</ModalTitle>
					<ModalSubtitle align={steps === 1 ? 'start' : 'center'}>
						{steps === 1 && (
							<>
								<a href={urlToDownloadExamplePayoutsChile} download>
									Descarga aquí la plantilla
								</a>{' '}
								y rellena la información de los pagos:
								<List>
									<li>
										<strong>RUT </strong>del beneficiario (formato sin puntos y{' '}
										<strong style={{ textDecoration: 'underline' }}>con guión</strong>)
									</li>
									<li>
										<strong>Nombre </strong>del beneficiario
									</li>
									<li>
										<strong>Correo electrónico </strong>del beneficiario
									</li>
									<li>
										<strong>Banco </strong>del beneficiario{' '}
										<a href={urlToDownloadExamplePayoutsSbifChile} download>
											(descargar la lista de bancos sbif){' '}
										</a>
									</li>
									<li>
										<strong>Número de cuenta </strong>del beneficiario
									</li>
									<li>
										<strong>Monto: </strong>el importe del pago (solo números)
									</li>
									<li>
										<strong>Mensaje: </strong>detalla el pago para identificarlo
									</li>
									<li>
										<strong>Referencia única: </strong>para identificar el pago
									</li>
								</List>
								<span>
									No edites los nombres de los encabezados de las columnas; esto hará que el archivo
									sea ilegible. Cuando hayas rellenado la plantilla, guárdala en formato .CSV y sube
									el archivo.
								</span>
							</>
						)}
						{steps === 3 &&
							'Enviamos un código de seguridad al correo electrónico asociado a tu cuenta, ingrésalo para autorizar la operación.'}
						{steps === 4 &&
							'Los pagos de salida están en proceso de autorización bancaria, consulta su estatus en la sección “Salidas”.'}
						{(steps === 5 || steps === 6) &&
							'Detectamos un error en la información de los pagos de salida, descarga el archivo y corrige los errores.'}
					</ModalSubtitle>

					{steps === 1 && (
						<>
							<DragAndDropContainer>
								<DragAndDrop
									id="upload-batch-payments"
									accept={['.csv']}
									maxSize={2100000}
									onChange={handleChange}
								/>
							</DragAndDropContainer>
							{!state.buttonDisabled && (
								<Button
									onClick={() => handleContinueAction()}
									outboundColor
									disabled={handleVerifyDisabledButton()}
								>
									Siguiente
								</Button>
							)}
						</>
					)}

					{steps === 2 && (
						<>
							<ResumeContainer>
								<OutboundResume transferResume={resumeData} />
							</ResumeContainer>
							<ButtonContainer>
								<Button
									onClick={() => {
										setState({ ...state, buttonDisabled: true });
										setSteps(1);
									}}
								>
									Regresar
								</Button>
								<Button
									outboundColor
									onClick={() => {
										setSteps(3);
										handleGetCode();
									}}
								>
									Siguiente
								</Button>
							</ButtonContainer>
						</>
					)}

					{steps === 3 && (
						<ResumeContainer>
							<OutboundResume transferResume={codeResume} />
							<SendCode
								value={state.code}
								name="code"
								handleChangeInput={handleChangeInput}
								handleContinueSendCode={() => handleContinueSendCode()}
								handleContinue={handleGetCode}
								handleVerifyDisabledButton={handleVerifyDisabledButton}
							/>
						</ResumeContainer>
					)}

					{steps === 4 && (
						<ResumeContainer>
							<OutboundResume transferResume={processData} />
						</ResumeContainer>
					)}

					{steps === 5 && (
						<>
							<ResumeContainer>
								<OutboundResume transferResume={errorData} error />
							</ResumeContainer>
							<ButtonContainer>
								<Button
									onClick={() => {
										setState({ ...state, buttonDisabled: true, code: '' });
										setSteps(1);
									}}
								>
									Regresar
								</Button>
								<DownloadLink href={error.csv} download="outbound-error.csv">
									<DownloadIcon />
									Ver errores
								</DownloadLink>
							</ButtonContainer>
						</>
					)}

					{steps === 6 && (
						<>
							<ResumeContainer>
								<OutboundResume transferResume={errorBalance} error />
							</ResumeContainer>
							<ButtonContainer>
								<Button
									onClick={() => {
										setState({ ...state, buttonDisabled: true, code: '' });
										setSteps(1);
									}}
								>
									Regresar
								</Button>
							</ButtonContainer>
						</>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export { NewBatchPayment };
