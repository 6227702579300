import {
	Bar,
	ComposedChart,
	Legend,
	Line,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { CustomizedTick } from '../../../../components/graphs/components/customized-tick';
import TooltipComposedChart from './components/tooltip';
import { LegendContainer, LegendIcon, LegendItem } from './styles';

const renderLegend = (props: LegendProps) => {
	const { payload } = props;

	return (
		<LegendContainer>
			{payload?.map((entry, index) => (
				<LegendItem key={`item-${index}`} color={entry.color}>
					<LegendIcon color={entry.color || ''} />
				</LegendItem>
			))}
		</LegendContainer>
	);
};

export interface GraphicsFormat {
	x: string;
	y: string | number;
	x_formatted: string;
	y_formatted: string;
	y_amount: number;
}

interface DirectDebitChartProps {
	data?: GraphicsFormat[];
}

const DirectDebitChart = ({ data }: DirectDebitChartProps) => {
	return (
		<ResponsiveContainer width="100%" height={223}>
			<ComposedChart data={data} margin={{ left: -30, right: -10 }}>
				<Tooltip
					content={(props: TooltipProps<ValueType, NameType>) => {
						const options = [
							{
								label: 'Monto Recaudado',
								value: `$${props?.payload?.map((item) => item.payload.y_amount)[1]}`,
								color: props?.payload?.map((item) => item.color)[1],
								month: props?.payload?.map((item) => item.payload.x_formatted_full)[0],
							},
							{
								label: 'Suscripciones Activas',
								value: props?.payload?.map((item) => item.payload.y_formatted)[0],
								color: props?.payload?.map((item) => item.color)[0],
							},
						];
						return <TooltipComposedChart {...props} options={options} />;
					}}
				/>
				<XAxis dataKey="x" axisLine={false} tickLine={false} tickMargin={20} fontSize={10} />
				<YAxis
					dataKey="y_formatted"
					yAxisId="right"
					orientation="right"
					axisLine={false}
					tickLine={false}
					fontSize={10}
					tickMargin={18}
				/>
				<YAxis
					dataKey="y_amount"
					yAxisId="left"
					orientation="left"
					axisLine={false}
					tickLine={false}
					fontSize={10}
					tick={(props) => <CustomizedTick {...props} position="left" symbol="$" />}
					tickMargin={18}
				/>

				<Legend content={renderLegend} />
				<Bar
					yAxisId="right"
					dataKey="y_formatted"
					barSize={73}
					fill="#94B2FB"
					radius={9}
					background={{ fill: '#FCFCFC', radius: 6 }}
				/>
				<Line
					yAxisId="left"
					type="basis"
					dataKey="y_amount"
					stroke="#4C6CD1"
					strokeWidth={9}
					dot={false}
				/>
			</ComposedChart>
		</ResponsiveContainer>
	);
};

export { DirectDebitChart };
