import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	text-align: left;
	width: 100%;
`;

export const Date = styled.p`
	font-size: 14px;
	margin: 0;
`;

export const Time = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	margin: 0;
`;
