import { CommonTableParams, ResponseExport } from '../models/common-payments';
import {
	DirectDebitGraphicsResponse,
	NewSubscriptionParams,
	ResponseNewSubscription,
	ResponseStatistics,
	ResponseTableDirectDebit,
	SendEmailParams,
} from '../models/direct-debit';
import { ExportDataParams } from '../models/payment-links-multiuse';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getTableData = async ({ ...rest }: CommonTableParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTableDirectDebit>('/v1/dd/subscriptions/table', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getGraphicsData = async ({ merchantCode }: NewSubscriptionParams) => {
	try {
		const params = {
			merchant_code: merchantCode,
		};
		const { data } = await client.get<DirectDebitGraphicsResponse>(
			'/v1/dd/subscriptions/graphics',
			{
				params,
			},
		);
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getStatistics = async ({ merchantCode }: NewSubscriptionParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseStatistics>('/v1/dd/subscriptions/statistics', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/dd/subscriptions/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const newSubscription = async ({ merchantCode }: NewSubscriptionParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseNewSubscription>('/v1/dd/subscriptions/new', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const sendSubscriptionByEmail = async ({ merchantCode, url, email, logo }: SendEmailParams) => {
	try {
		const params = {
			merchant_code: merchantCode,
		};
		const body = {
			url,
			email,
			logo,
		};
		const { data } = await client.post('/v1/dd/subscription/share-link', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const DirectDebitServices = {
	getTableData,
	getStatistics,
	getExportData,
	getGraphicsData,
	newSubscription,
	sendSubscriptionByEmail,
};
