import { FilterStatus } from '../models/table';

interface FillStatesWithZerosProps {
	states?: string[];
	stateList: FilterStatus[];
}

export const allDefaultStates = ['success', 'pending', 'expired', 'error'];
export const fillStatesWithZeros = ({
	states = allDefaultStates,
	stateList,
}: FillStatesWithZerosProps): FilterStatus[] => {
	return states.map((status) => ({
		status: status,
		total: stateList.find((payment) => payment.status === status)?.total ?? 0,
	}));
};
