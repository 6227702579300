import { ResponseExport, ResponseGraphics, ResponseTable } from '../models/common-payments';
import {
	CashAllowedResponse,
	ExportDataParams,
	GetEatmParams,
	NewWithdrawalParams,
	NewWithdrawalResponse,
	ResendCode,
	UpdateCashAllowedParams,
	VerifyCodeParams,
	VerifyCodeResponse,
	VerifyWithdrawal,
} from '../models/eatm';
import { client } from '../utils/api';
import { exportParamsConstructor, tableParamsConstructor } from '../utils/common';

const getGraphicsData = async (merchantCode: string) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<ResponseGraphics>('/v1/eatm/graphics', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getTableData = async ({ ...rest }: GetEatmParams) => {
	try {
		const params = tableParamsConstructor(rest);
		const { data } = await client.get<ResponseTable>('/v1/eatm/table', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getExportData = async ({ ...rest }: ExportDataParams) => {
	try {
		const params = exportParamsConstructor(rest);
		const { data } = await client.get<ResponseExport>('/v1/eatm/export', { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getCashAllowed = async (merchantCode: string) => {
	try {
		const params = { merchant_code: merchantCode };
		const { data } = await client.get<CashAllowedResponse>('/v1/eatm/available-cash', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const updateCashAllowed = async ({ merchantCode, cashAllowed, maxCashAllowed }: UpdateCashAllowedParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { new_amount: cashAllowed, max_wdrl: maxCashAllowed };
		const { data } = await client.patch('/v1/eatm/available-cash', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const withdrawal = async ({ merchantCode, amount, phoneNumber }: NewWithdrawalParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { order_amount: amount, user_id: phoneNumber };
		const { data } = await client.post<NewWithdrawalResponse>('/v1/eatm/new-withdrawal', body, {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const verifyCode = async ({ merchantCode, sessionToken, code }: VerifyCodeParams) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { session_token: sessionToken, code };
		const { data } = await client.post<VerifyCodeResponse>('/v1/eatm/validate', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const resendCode = async ({ merchantCode, sessionToken }: ResendCode) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { session_token: sessionToken };
		const { data } = await client.post('/v1/eatm/resend-code', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const verifyWithdrawal = async ({ merchantCode, sessionToken, verification }: VerifyWithdrawal) => {
	try {
		const params = { merchant_code: merchantCode };
		const body = { session_token: sessionToken, verified: verification };
		const { data } = await client.patch('/v1/eatm/confirm-withdrawal', body, { params });
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const EatmServices = {
	getGraphicsData,
	getTableData,
	getExportData,
	getCashAllowed,
	updateCashAllowed,
	withdrawal,
	verifyCode,
	resendCode,
	verifyWithdrawal,
};
