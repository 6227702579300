import { Dispatch, SetStateAction, useState } from 'react';

import { DownloadIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { Payment, PaymentStatus } from '../../../../models/common-payments';
import { ColumnInterface, Filters, Pagination, RowInterface } from '../../../../models/table';
import { EatmServices } from '../../../../services/eatm';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import {
	AmountContainer,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	Section,
	StatusContainer,
	Title,
	Value,
	VoucherContainer,
} from './styles';

interface QuickTransferTableProps {
	data: Payment[];
	isLoading?: boolean;
	stateList: PaymentStatus[];
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	showModalAction: ({ token, amount }: { token: string; amount: string }) => void;
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const EatmTransferTable = ({
	data,
	isLoading,
	stateList,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	showModalAction,
}: QuickTransferTableProps) => {
	const { merchantSelected } = useMerchant();
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const { handleShowAlert } = useFloatingAlert();

	const columns: ColumnInterface<Payment>[] = [
		{
			title: 'Referencia',
			propertyName: 'reference',
			cellRender: ({ reference }) => <>{reference.split('-')[0]}</>,
		},
		{
			title: merchantSelected?.country === 'MX' ? 'CLABE' : 'RUT',
			propertyName: merchantSelected?.country === 'MX' ? 'user_clabe' : 'rut',
			cellRender: ({ userClabe, rut }) => <>{userClabe ?? rut}</>,
		},
		{
			sortField: true,
			title: 'Monto',
			align: 'right',
			propertyName: 'amount',
			cellRender: ({ amount }) => <AmountContainer>{amount}</AmountContainer>,
		},
		{
			sortField: true,
			title: 'Banco',
			propertyName: 'bankName',
			cellRender: ({ bankName }) => (
				<>{bankName === 'DESCONOCIDO' && merchantSelected?.country === 'MX' ? null : bankName}</>
			),
		},
		{
			title: 'Estatus',
			align: 'center',
			propertyName: 'status',
			cellRender: ({ status }) => (
				<StatusContainer>
					<Status status={status} />
				</StatusContainer>
			),
		},
		{
			sortField: true,
			title: 'Fecha de creación',
			propertyName: 'createdatDate',
			cellRender: ({ createdatDate, createdatTime }) => (
				<DateAndTime date={createdatDate} time={createdatTime} />
			),
		},
		{
			title: 'Comprobante',
			hide: merchantSelected?.country === 'CL',
			propertyName: 'validationMailPdf',
			cellRender: ({ validationMailPdf }) => (
				<>
					{validationMailPdf && (
						<VoucherContainer onClick={() => window.open(validationMailPdf)}>
							<DownloadIcon color="#646174" />
						</VoucherContainer>
					)}
				</>
			),
		},
		{
			title: '',
			propertyName: 'verifyCode',
			cellRender: ({ verifyCode, sessionToken, amountWFee }) => (
				<>
					{verifyCode && sessionToken && amountWFee && (
						<ETPayButton
							title="Verificar"
							color="secondary"
							onClick={() => showModalAction({ token: sessionToken, amount: amountWFee })}
						/>
					)}
				</>
			),
		},
	];

	const detailSection: RowInterface<Payment> = (data) => {
		const isNotPending = data.status !== 'pending';

		return (
			<DetailsContainer $hasError={!!data.typeError && isNotPending && !data.clabe}>
				{isNotPending && (
					<Section>
						{merchantSelected?.country === 'MX' && (
							<Content>
								<Title>Medio de pago</Title>
								<Value>{data.paymentMethod}</Value>
							</Content>
						)}

						{merchantSelected?.country === 'CL' && (
							<>
								<Content>
									<Title>Tipo de cuenta</Title>
									<Value>{data.accountType}</Value>
								</Content>

								<Content>
									<Title>No. de Cuenta</Title>
									<Value>{data.account}</Value>
								</Content>
							</>
						)}

						{merchantSelected?.country === 'MX' && data.paymentMethod === 'CoDi' && (
							<Content>
								<Title>Número de teléfono</Title>
								<Value>{data.phone}</Value>
							</Content>
						)}

						{merchantSelected?.country === 'MX' &&
							(data.status === 'expired' || data.status === 'error') && (
								<Content>
									<Title>Último paso</Title>
									<Value>{data.lastStep}</Value>
								</Content>
							)}

						{data.typeError && data.status !== 'expired' && (
							<Content>
								<Title>Tipo de error</Title>
								<Value>{data.typeError}</Value>
							</Content>
						)}
						{data.status != 'error' && (
							<Content toEnd $hasError={!!data.typeError && isNotPending && !data.clabe}>
								<Title>Fecha de expiración</Title>
								<Value>{data.expiredate}</Value>
							</Content>
						)}
					</Section>
				)}

				<Section>
					{data.status === 'pending' && merchantSelected?.country === 'MX' && (
						<>
							<Content>
								<Title>Medio de pago</Title>
								<Value>{data.paymentMethod}</Value>
							</Content>

							<Content>
								<Title>Correo electronico</Title>
								<Value>{data.email}</Value>
							</Content>

							<Content>
								<Title>Fecha de expiración</Title>
								<Value>{data.expiredate}</Value>
							</Content>

							{data.validatedatDate && (
								<Content toEnd>
									<Title>Fecha de validación</Title>
									<Value>
										{data.validatedatDate} {data.validatedatTime}
									</Value>
								</Content>
							)}

							{data.lastStep && (
								<Content>
									<Title>Último paso</Title>
									<Value>{data.lastStep}</Value>
								</Content>
							)}
						</>
					)}
				</Section>

				<Section>
					<Content>
						<Title>{data.status === 'success' ? 'Payment ID' : 'Session Token'}</Title>
						<Value>{data.status === 'success' ? data.paymentId : data.sessionToken}</Value>
					</Content>
					{data.status === 'error' && (
						<Content toEnd $hasError={!!data.typeError && isNotPending && !data.clabe}>
							<Title>Fecha de expiración</Title>
							<Value>{data.expiredate}</Value>
						</Content>
					)}

					{data.validatedatDate && (
						<Content toEnd>
							<Title>Fecha de validación</Title>
							<Value>
								{data.validatedatDate} {data.validatedatTime}
							</Value>
						</Content>
					)}
				</Section>
			</DetailsContainer>
		);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await EatmServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from,
					to: filters?.dateRange?.to,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
	};

	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			<ETPayTable<Payment>
				title="Todos los retiros"
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={detailSection}
				hasFilters
				hasDateFilter
				allowExport
				stateList={fillStatesWithZeros({ stateList })}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				exportAction={handleExportAction}
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</>
	);
};

export { EatmTransferTable };
