import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const BreadcrumbContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

export const RedirectContainer = styled.div`
	align-items: center;
	display: flex;

	div {
		align-items: center;
		display: flex;

		svg {
			height: 14px;
			width: 14px;
		}
	}
`;

export const Redirect = styled(Link)`
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	font-size: 12px;
	line-height: 12px;
`;

export const Title = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 18px;
	font-weight: 400;
	letter-spacing: -0.014px;
	line-height: normal;
`;

export const TitleContainer = styled.div``;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div`
	svg {
		animation: ${spin} 1.5s linear infinite;
		height: 35px;
	}
`;
