import { Form, Formik } from 'formik';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ANALYTICS } from '../../../analytics/constants';
import { PayloadProps, track } from '../../../analytics/index';
import ETPayBG from '../../../assets/images/etpay-bg-01.jpg';
import { Alert, AlertProps } from '../../../components/alert';
import { ETPayButton } from '../../../components/button';
import { FormInput } from '../../../components/form/form-input';
import { Select } from '../../../components/form/select';
import { AuthLayout } from '../../../layout/auth';
import { PlanAllowed } from '../../../models/plans';
import { AuthServices } from '../../../services/auth';
import { errorFormatter } from '../../../utils/errors';
import { onlyNumberRegex, signUpSchema } from '../../../utils/validations';
import {
	AlertContainer,
	AuthFormContainer,
	FooterLink,
	FooterText,
	FormGroup,
	HeaderSection,
	LastField,
	Subtitle,
	Title,
} from '../styles';

type Views = 'form' | 'success';

const defaultValues = {
	name: '',
	lastName: '',
	email: '',
	phone: '',
	companyName: '',
};

const CompanyPaymentsOptions = [
	{ label: '¿Cuántos pagos registra tu empresa al mes?', value: '', show: true },
	{
		label: 'Voy arrancando con mi negocio. ¡Etpay te ayuda con ello!',
		value: 1,
		show: true,
	},
	{ label: 'Menos de 1.000 pagos', value: 2, show: true },
	{ label: 'Entre 1.000 y 5.000 pagos', value: 3, show: true },
	{ label: 'Entre 5.000 y 50.000 pagos', value: 4, show: true },
	{ label: 'Más de 50.000 pagos', value: 5, show: true },
];

const CountriesOptions = [
	{ label: 'País de operaciones', value: '', show: true },
	{ label: 'Chile', value: 1, show: true },
	{ label: 'México', value: 2, show: true },
];

const defaultAlert: AlertProps = {
	show: false,
	message: '',
	type: 'error',
};

const SignUp = () => {
	const [searchParams] = useSearchParams();
	const [views, setViews] = useState<Views>('form');
	const [state, setState] = useState({ loading: false });
	const [alert, setAlert] = useState(defaultAlert);
	const [countrySelected, setCountrySelected] = useState(0);
	const [paymentSelection, setPaymentSelection] = useState(0);
	const planSelected = searchParams.get('plan_selected') as PlanAllowed;

	const handleGoToETPay = () => {
		window.location.href = 'https://www.etpay.com';
	};

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (
			!onlyNumberRegex.test(e.key) &&
			e.key !== 'Backspace' &&
			e.key !== 'Delete' &&
			e.key !== 'ArrowLeft' &&
			e.key !== 'ArrowRight'
		) {
			e.preventDefault();
		}
	};

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.sign_in.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<AuthLayout
			tabTitle="Regístrate"
			image={ETPayBG}
			buttonTitle="¿Ya tienes una cuenta?"
			link="/login"
			linkLabel="Inicia sesión"
			hasContext={false}
		>
			<AlertContainer show={alert.show}>
				<Alert show={alert.show} message={alert.message} hasIcon type={alert.type} />
			</AlertContainer>

			<AuthFormContainer>
				{views === 'form' && (
					<>
						<HeaderSection hide={!planSelected}>
							<Title>Mejora tus ventas</Title>
							<Subtitle>
								Completa este formulario y nos pondremos en contacto contigo a la brevedad.
							</Subtitle>
						</HeaderSection>

						<Formik
							initialValues={defaultValues}
							onSubmit={async (values) => {
								setState({ loading: true });
								setAlert(defaultAlert);
								try {
									await AuthServices.signUp({
										...values,
										countrySelected,
										paymentSelection,
										planSelected,
									});
									setViews('success');
								} catch (e: any) {
									setAlert({
										show: true,
										message: errorFormatter(e.code),
										type: 'error',
									});
								} finally {
									setState({ loading: false });
								}
							}}
							validationSchema={signUpSchema}
						>
							{({ isValid, dirty, validateForm }) => (
								<Form>
									<FormGroup>
										<FormInput type="text" label="Nombre(s)" name="name" />
										<FormInput type="text" label="Apellidos" name="lastName" />
									</FormGroup>

									<FormGroup>
										<FormInput type="text" label="Correo" name="email" />
										<FormInput
											type="text"
											label="Número de teléfono"
											onKeyDown={handleKeyDownValidateAmount}
											name="phone"
										/>
									</FormGroup>

									<FormGroup>
										<FormInput type="text" label="Nombre de la empresa" name="companyName" />
										<Select
											name="countrySelected"
											size="large"
											orientation="bottom"
											options={CountriesOptions}
											onChange={(e) => {
												setCountrySelected(e.value as number);
												validateForm();
											}}
										/>
									</FormGroup>

									<LastField>
										<Select
											name="paymentSelection"
											size="large"
											orientation="bottom"
											options={CompanyPaymentsOptions}
											onChange={(e) => {
												setPaymentSelection(e.value as number);
												validateForm();
											}}
										/>
									</LastField>

									<ETPayButton
										title={state.loading ? 'Enviando...' : 'Enviar'}
										loading={state.loading}
										variant="effect"
										disabled={!isValid || !dirty || !paymentSelection || !countrySelected}
										type="submit"
									/>
								</Form>
							)}
						</Formik>
						<FooterText>
							Al hacer clic en “Enviar”, aceptas los
							<FooterLink
								href="https://www.etpay.com/terms-conditions"
								target="_blank"
								rel="noreferrer noopener"
							>
								Términos y Condiciones
							</FooterLink>
							y la
							<FooterLink
								href="https://www.etpay.com/privacy-policy"
								target="_blank"
								rel="noreferrer noopener"
							>
								Política de Privacidad
							</FooterLink>
							de ETpay.
						</FooterText>
					</>
				)}

				{views === 'success' && (
					<>
						<Title>Solicitud recibida</Title>
						<Subtitle>
							Nuestro equipo de ventas se pondrá en contacto contigo a la brevedad para darte más
							información.
						</Subtitle>

						<ETPayButton
							title="Regresar a la página principal"
							variant="effect"
							type="submit"
							onClick={handleGoToETPay}
						/>
					</>
				)}
			</AuthFormContainer>
		</AuthLayout>
	);
};

export { SignUp };
