import { MessageText } from './styles';

interface EmptyDataMessageProps {
	msg?: string;
}

const defaultText = 'No se han registrado pagos';

const EmptyDataMessage = ({ msg = defaultText }: EmptyDataMessageProps) => {
	return <MessageText>{msg}</MessageText>;
};

export { EmptyDataMessage };
