import { GraphicsResponse, Interval, StatisticsResponse } from '../models/analytics';
import { client } from '../utils/api';

const getStatistics = async (merchantCode: string, interval: Interval) => {
	const params = { merchant_code: merchantCode, interval };

	try {
		const { data } = await client.get<StatisticsResponse>('/v1/analytics/statistics', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

const getGraphics = async (merchantCode: string, interval: Interval) => {
	const params = { merchant_code: merchantCode, interval };

	try {
		const { data } = await client.get<GraphicsResponse>('/v1/analytics/graphics', {
			params,
		});
		return data;
	} catch (error: any) {
		throw error.response.data;
	}
};

export const AnalyticsServices = {
	getStatistics,
	getGraphics,
};
