import { Dispatch, SetStateAction, useState } from 'react';
import { QueryObserverResult } from 'react-query';

import { PaymentLinksIcon, QuickTransferIcon } from '../../../../assets/svg';
import { ETPayButton } from '../../../../components/button';
import { DateAndTime } from '../../../../components/date-and-time';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Status } from '../../../../components/status';
import { ETPayTable } from '../../../../components/table';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { RefundsData } from '../../../../models/refund';
import {
	ColumnInterface,
	Filters,
	FilterStatus,
	Pagination,
	RowInterface,
} from '../../../../models/table';
import { RefundsServices } from '../../../../services/refund';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { fillStatesWithZeros } from '../../../../utils/states';
import { RefundsModal } from '../modal';
import {
	AmountContainer,
	Content,
	DetailsContainer,
	ExportModalContent,
	ExportModalDescription,
	ExportModalTitle,
	ProductContainer,
	Section,
	Title,
	Value,
} from './styles';

interface RefundsTableProps {
	data: RefundsData[];
	isLoading?: boolean;
	filters: Filters;
	onFilterChange: Dispatch<SetStateAction<Filters>>;
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	totalResults?: number;
	setSelectedRowData: Dispatch<SetStateAction<RefundsData | undefined>>;
	selectedRowData: RefundsData | undefined;
	setShowAlert: (value: boolean) => void;
	showAlert: boolean;
	refetch: () => Promise<QueryObserverResult<any, unknown>>;
	stateList: FilterStatus[];
}

interface ModalProps {
	loader: boolean;
	show: boolean;
	url: string;
}

const defaultModal: ModalProps = {
	loader: false,
	show: false,
	url: '',
};

const RefundsTable = ({
	data,
	isLoading,
	filters,
	onFilterChange,
	pagination,
	setPagination,
	totalResults,
	setSelectedRowData,
	selectedRowData,
	setShowAlert,
	refetch,
	stateList,
}: RefundsTableProps) => {
	const { merchantSelected } = useMerchant();
	const flowBanksOrStp = true;
	const [modal, setModal] = useState<ModalProps>(defaultModal);
	const [showModal, setShowModal] = useState(false);
	const { handleShowAlert } = useFloatingAlert();

	const handleClick = (rowData: RefundsData) => () => {
		setSelectedRowData(rowData);
		setShowModal(true);
	};

	const onClose = () => {
		setShowModal(false);
		refetch();
	};

	const getColumns = () => {
		const columns: ColumnInterface<RefundsData>[] = [
			{
				sortField: true,
				title: 'Referencia',
				propertyName: 'reference',
				cellRender: ({ reference }) => <>{reference}</>,
			},
			{
				title: 'Producto',
				propertyName: 'productType',
				cellRender: ({ productType }) => (
					<>
						<ProductContainer>
							{productType === 'QT' || productType === 'QM' ? (
								<QuickTransferIcon width="24" height="24" />
							) : (
								<PaymentLinksIcon width="24" height="24" />
							)}
						</ProductContainer>
					</>
				),
			},
			{
				sortField: true,
				title: 'CLABE',
				propertyName: 'clabe',
				cellRender: ({ userClabe }) => <>{userClabe}</>,
			},
			{
				sortField: true,
				title: 'Monto',
				align: 'right',
				propertyName: 'amount',
				cellRender: ({ amount }) => <AmountContainer>{amount}</AmountContainer>,
			},
			{
				sortField: true,
				title: 'Banco',
				propertyName: 'bankName',
				cellRender: ({ bankName }) => <>{bankName}</>,
			},
			{
				title: 'Estatus',
				propertyName: 'status',
				cellRender: ({ status }) => status && <Status status={status} />,
			},
			{
				sortField: true,
				title: 'Fecha de creación',
				propertyName: 'createdatDate',
				cellRender: ({ createdatDate, createdatTime }) => (
					<DateAndTime date={createdatDate} time={createdatTime} />
				),
			},
			{
				title: '',
				propertyName: 'button',
				cellRender: (rowData: RefundsData) => (
					<>
						{(rowData.performReturn || rowData.status === 'error') &&
							merchantSelected?.role_id === 2 && (
								<ETPayButton title="Devolver" onClick={handleClick(rowData)} />
							)}
					</>
				),
			},
		];

		if (!flowBanksOrStp) {
			const columnsBanks: ColumnInterface<RefundsData>[] = [
				{
					sortField: true,
					title: 'Clave de rastreo',
					propertyName: 'trakingKey',
					cellRender: ({ trakingKey }) => <>{trakingKey}</>,
				},
				{
					sortField: true,
					title: 'CLABE beneficiario',
					propertyName: 'clabe',
					cellRender: ({ userClabe }) => <>{userClabe}</>,
				},
				{
					sortField: true,
					title: 'Beneficiario',
					propertyName: 'clientname',
					cellRender: ({ clientname }) => <>{clientname}</>,
				},
				{
					sortField: true,
					title: 'Monto devuelto',
					align: 'right',
					propertyName: 'amount',
					cellRender: ({ amount }) => <AmountContainer>{amount}</AmountContainer>,
				},
				{
					sortField: true,
					title: 'Banco',
					propertyName: 'bankName',
					cellRender: ({ bankName }) => <>{bankName}</>,
				},
				{
					sortField: true,
					title: 'Fecha',
					propertyName: 'createdatDate',
					cellRender: ({ createdatDate, createdatTime }) => (
						<DateAndTime date={createdatDate} time={createdatTime} />
					),
				},
			];
			return columnsBanks;
		}
		return columns;
	};

	const columns = getColumns();

	const detailSection: RowInterface<RefundsData> = (data) => {
		return (
			<DetailsContainer>
				<Section>
					<Content>
						<Title>Medio de pago</Title>
						<Value>{data.paymentMethod}</Value>
					</Content>
					{merchantSelected?.country === 'MX' && data.paymentMethod === 'SPEI' && (
						<Content>
							<Title>Correo electrónico</Title>
							<Value>{data.uncensoredEmail}</Value>
						</Content>
					)}

					{merchantSelected?.country === 'MX' && data.paymentMethod === 'CoDi' && (
						<Content>
							<Title>Número de teléfono</Title>
							<Value>{data.uncensoredPhone}</Value>
						</Content>
					)}

					<Content toEnd>
						<Title>Fecha de expiración</Title>
						<Value>{data.expiredate}</Value>
					</Content>
				</Section>
				<Section>
					<Content>
						<Title>Payment ID</Title>
						<Value>{data.paymentId}</Value>
					</Content>
					<Content>
						<Title>Fecha de validación</Title>
						<Value>
							{data.validatedatDate} {data.validatedatTime}
						</Value>
					</Content>
				</Section>
			</DetailsContainer>
		);
	};

	const handleExportAction = async () => {
		setModal({ ...modal, loader: true, show: true });

		try {
			const response = await RefundsServices.getExportData({
				merchantCode: merchantSelected?.code as string,
				dateRange: {
					from: filters?.dateRange?.from,
					to: filters?.dateRange?.to,
				},
				search: filters?.search,
				stateSelected: filters?.stateSelected,
			});

			if (response.url) setModal({ loader: false, show: true, url: response.url });
		} catch (e: any) {
			handleShowAlert({
				type: 'error',
				message: errorFormatter(e.code),
			});
		}
		return;
	};
	const handleCloseModal = () => {
		setModal(defaultModal);
	};

	const handleDownloadData = () => {
		if (modal.url) window.location.assign(modal.url);
		setModal(defaultModal);
	};

	const refundStates = ['success'];
	const refundStatesBanks = ['liquidated'];

	return (
		<>
			{modal.show && (
				<Modal
					title="Exportar"
					show={modal.show}
					onClose={handleCloseModal}
					allowCloseModal={!modal.loader}
				>
					{modal.loader ? (
						<Spinner />
					) : (
						<ExportModalContent>
							<ExportModalTitle>Tu reporte está listo</ExportModalTitle>

							<ExportModalDescription>
								Hemos terminado de recopilar los datos de tu reporte. Haz clic en el botón para
								descargarlo.
							</ExportModalDescription>
							<ETPayButton
								title="Descargar reporte"
								variant="effect"
								onClick={handleDownloadData}
							/>
						</ExportModalContent>
					)}
				</Modal>
			)}

			{showModal && (
				<RefundsModal
					show={showModal}
					onClose={onClose}
					selectedRowData={selectedRowData}
					setShowAlert={setShowAlert}
				/>
			)}

			<ETPayTable<RefundsData>
				title={flowBanksOrStp ? 'Todos los cobros' : 'Todas las devoluciones'}
				data={data}
				isLoading={!!isLoading}
				columns={columns}
				row={flowBanksOrStp ? detailSection : undefined}
				hasFilters
				hasDateFilter
				allowExport={validatePermission('REFUNDS_EXPORT')}
				exportAction={handleExportAction}
				filters={filters}
				onFilterChange={onFilterChange}
				hasSearch
				hasPagination
				totalResults={totalResults}
				pagination={pagination}
				setPagination={setPagination}
				emptyMessage="No hay información disponible en el periodo seleccionado"
				stateList={fillStatesWithZeros({
					states: flowBanksOrStp ? refundStates : refundStatesBanks,
					stateList,
				})}
				statusContent={flowBanksOrStp ? [['success', 'Exitosos']] : [['liquidated', 'Liquidados']]}
			/>
		</>
	);
};

export { RefundsTable };
