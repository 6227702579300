import styled from 'styled-components';

export const DropdownContainer = styled.div`
	position: relative;
	width: 1px;
`;

export const DropdownList = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	border-radius: 8px;
	box-shadow: 0 4px 6px -2px rgba(20 15 51 /3%), 0 12px 16px -4px rgba(20 15 51 /8%);
	min-width: 160px;
	position: absolute;
	right: -40px;
	top: 10px;
	user-select: none;
	z-index: 5;
`;

interface DropdownItem {
	readonly colorCustom?: string;
}

export const DropdownItem = styled.div<DropdownItem>`
	color: ${({ theme, colorCustom }) => (colorCustom ? colorCustom : theme.colors.principal.orion)};
	cursor: pointer;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 12px;
	width: 100%;

	&:first-child {
		margin-top: 4px;
	}

	&:last-child {
		margin-bottom: 4px;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.principal.ceres};
	}
`;
