import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { Alert } from '../../components/alert';
import { Input } from '../../components/input';
import { ManagePlan } from '../../components/manage-plan';
import { Section } from '../../components/section';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { BillingData } from '../../models/plans';
import { SubscriptionService } from '../../services/subscription';
import { AlertMessage, BodySection, InputContainer, TitlePage } from './styles';

const defaultBillingData: BillingData = {
	business_name: '',
	email: '',
	postal_code: '',
	rut: '',
	tax_regime: '',
};

const Subscription = () => {
	const { merchantSelected } = useMerchant();
	const [billingData, setBillingData] = useState<BillingData>(defaultBillingData);
	const navigate = useNavigate();

	const { data, isLoading, isFetching } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.config.subscription.getMyCurrentPlan, merchantSelected?.code],
		queryFn: () => SubscriptionService.getCurrentPlan(merchantSelected?.code as string),
		onSuccess: (data) => {
			setBillingData(data.billing);
		},
	});

	const email =
		merchantSelected?.country === 'MX' ? 'operacionesmexico@etpay.com' : ' operaciones@etpay.com';

	const goToManagePlanView = () => {
		navigate('/subscription/manage-plan');
	};

	const basePrice = data?.plan?.price?.split('$')[1] as string;

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.subscription.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);
	return (
		<ViewWrapper title="Suscripción" isLoading={isLoading || isFetching}>
			<TitlePage>Suscripción</TitlePage>

			<ManagePlan
				boxes={[
					{ title: 'Tu plan', description: data?.plan?.title as string },
					{
						title: 'Precio base',
						description: basePrice ? `$${basePrice}` : '-',
					},
					{
						title: 'Siguiente pago',
						description: data?.plan?.next_pay as string,
					},
				]}
				buttonAction={goToManagePlanView}
			/>

			<Alert
				show
				hasIcon
				message={
					<AlertMessage>
						Para actualizar la información de tu cuenta comunícate con nuestro equipo de soporte{' '}
						<a href={`mailto:${email}`}>{email}</a>.
					</AlertMessage>
				}
			/>

			<BodySection>
				<Section title="Datos de facturación">
					<InputContainer>
						<Input
							label="Razón social"
							variant="large"
							value={billingData?.business_name}
							readOnly
						/>
					</InputContainer>

					<InputContainer>
						<Input
							label={merchantSelected?.country === 'MX' ? 'RFC' : 'RUT'}
							variant="large"
							value={billingData?.rut}
							readOnly
						/>
					</InputContainer>
					{merchantSelected?.country === 'MX' && (
						<InputContainer>
							<Input
								label="Régimen tributario"
								variant="large"
								value={billingData?.tax_regime}
								readOnly
							/>
						</InputContainer>
					)}
					<InputContainer>
						<Input
							label="Código postal"
							variant="large"
							value={billingData?.postal_code}
							readOnly
						/>
					</InputContainer>
					<InputContainer>
						<Input label="Correo electrónico" variant="large" value={billingData?.email} readOnly />
					</InputContainer>
				</Section>
			</BodySection>
		</ViewWrapper>
	);
};

export { Subscription };
