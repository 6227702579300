import styled from 'styled-components';

export const ModalBody = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: center;
`;

export const ModalTitle = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 18px;
	letter-spacing: -0.014px;
	line-height: 21px;
`;

export const ModalDate = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 14px;
	line-height: 94%;
`;
export const HeadContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const TitlePage = styled.h1`
	align-items: baseline;
	color: ${({ theme }) => theme.colors.principal.orion};
	display: flex;
	font-size: 32px;
	font-weight: 500;
	line-height: 1.1;

	svg {
		height: 40px;
		margin-right: 16px;
		width: 40px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 14px;
	width: 100%;
`;

export const SelectContainer = styled.div`
	margin-bottom: 32px;
	max-width: 360px;
`;

export const Label = styled.p`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	line-height: 12px;
	margin: 0 0 8px;
`;

export const CardGroup = styled.div`
	display: flex;
	gap: 24px;
`;

export const ButtonContainer = styled.section`
	bottom: 21px;
	position: absolute;
	right: 24px;
`;

export const Button = styled.button`
	background-color: ${({ theme }) => theme.colors.extra.earth};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 16px;
	margin-top: 12px;
	padding: 10px 36px;
	text-align: center;
	width: 100%;

	&:disabled {
		cursor: default;
		opacity: 0.2;
		pointer-events: none;
	}

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`;

export const DownloadLink = styled.a`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.extra.earth};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.pure};
	display: flex;
	font-size: 16px;
	gap: 8px;
	justify-content: center;
	margin-top: 12px;
	padding: 8px;
	text-align: center;
	text-decoration: none;
	width: 100%;

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`;
