import { useEffect, useRef, useState } from 'react';

import { SearchIcon } from '../../assets/svg';
import { DeletedIcon } from '../../assets/svg/DeletedIcon';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Input } from '../input';
import { Separator } from '../separator';
import { DropdownContainer, Item, List } from './styles';

interface Options {
	label: string;
	value: string;
	show: boolean;
	icon: JSX.Element;
}

interface DropdownProps {
	options?: Options[];
	onChange?: (option: Options) => void;
	state: string;
	clearState?: () => void;
	disabled?: boolean;
}

const Dropdown = ({ options, onChange, state, clearState, disabled = false }: DropdownProps) => {
	const elementRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState(state);
	const [showCrossIcon, setShowCrossIcon] = useState(false);
	const [selected, setSelected] = useState(false);

	const filteredOptions = options?.filter((option: Options) =>
		option.label.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	useClickOutside({ elementRef, callback: () => setIsOpen(false) });

	const handleSelectOption = (option: Options) => {
		setSearchTerm(option.label);
		onChange && onChange(option);
		setIsOpen(false);
		setSelected(false);
	};

	useEffect(() => {
		searchTerm !== '' ? setShowCrossIcon(true) : setShowCrossIcon(false);
		searchTerm !== '' && setIsOpen(true);
		if (searchTerm === '') {
			setSelected(false);
			setIsOpen(false);
			clearState && clearState();
		}

		if (
			filteredOptions?.filter((item) => item)[0]?.label?.toLowerCase() !== searchTerm?.toLowerCase()
		) {
			clearState && clearState();
		} else {
			setIsOpen(false);
		}
	}, [searchTerm]);

	useEffect(() => {
		filteredOptions?.length === 0 && setIsOpen(false);
	}, [filteredOptions]);

	useEffect(() => {
		selected && setIsOpen(false);
	}, [selected]);

	return (
		<DropdownContainer ref={elementRef} isOpen={isOpen} disabled={disabled}>
			<Input
				type="text"
				label="Banco"
				placeholder="Buscar"
				variant="large"
				value={searchTerm}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				onChange={(e) => {
					setSearchTerm(e.target.value);
				}}
				leftIcon={<SearchIcon style={{ marginTop: '8px' }} color="red" />}
				rightIcon={
					<DeletedIcon
						style={{
							marginTop: '5px',
							display: `${showCrossIcon && !disabled ? 'initial' : 'none'}`,
						}}
					/>
				}
				rightIconClick={() => setSearchTerm('')}
				labelFocus
				disabled={disabled}
			/>

			{isOpen && !disabled && (
				<List>
					{filteredOptions?.map((option: Options, i: number) => (
						<>
							<div key={i} style={{ padding: '5px 3px' }}>
								<Item
									onClick={() => {
										handleSelectOption(option);
										setSelected(true);
										setIsOpen(false);
									}}
								>
									{option.icon}
									<span className="dropdown-item">
										{option.label
											.split(new RegExp(`(${searchTerm})`, 'gi'))
											.map((text: string, i: number) => (
												<span
													key={i}
													style={{
														color:
															text.toLowerCase() === searchTerm.toLowerCase()
																? '#140F33'
																: '#B7B7B7',
													}}
												>
													{text}
												</span>
											))}
									</span>
								</Item>
							</div>
							<Separator />
						</>
					))}
				</List>
			)}
		</DropdownContainer>
	);
};

export default Dropdown;
