import { PlanCheckIcon } from '../../assets/svg';
import { Plan, PlanAllowed } from '../../models/plans';
import { ETPayButton } from '../button';
import {
	BodyContainer,
	ButtonContainer,
	Feature,
	Features,
	FeaturesTitle,
	HeaderContainer,
	PlanContainer,
	PlanPriceContainer,
	PlanSubtitle,
	PlanTitle,
	Price,
	UnitPrice,
} from './styles';

interface PlanSectionProps {
	planName: PlanAllowed;
	data?: Plan[];
	showModal: () => void;
}

const PlanSection = ({ planName, data, showModal }: PlanSectionProps) => {
	const planToShow = data?.find((plan) => plan.title === planName);

	return (
		<PlanContainer planSelected={!!planToShow?.selected}>
			<HeaderContainer>
				<PlanTitle>{planName}</PlanTitle>
				<PlanPriceContainer>
					<Price>{planToShow?.price}</Price>
					<UnitPrice>{planToShow?.price_unit}</UnitPrice>
				</PlanPriceContainer>
				{planToShow?.description && <PlanSubtitle>{planToShow?.description}</PlanSubtitle>}
			</HeaderContainer>

			<BodyContainer>
				<ButtonContainer>
					<ETPayButton
						title={planToShow?.selected ? 'Plan actual' : 'Contactar con ventas'}
						variant="default"
						color="secondary"
						disabled={!!planToShow?.selected}
						onClick={showModal}
					/>
				</ButtonContainer>

				<Features>
					{planToShow?.features?.map(({ title, items }: any) => (
						<div key={title}>
							<FeaturesTitle>{title}</FeaturesTitle>
							{items.map((ele: string, i: number) => {
								const [hasFeature, feature] = ele.split(':');
								return (
									<Feature key={i} isAvailable={hasFeature === 'true'}>
										{hasFeature === 'true' && <PlanCheckIcon />}
										<span>{feature}</span>
									</Feature>
								);
							})}
						</div>
					))}
				</Features>
				<PlanSubtitle>{planToShow?.subtitle}</PlanSubtitle>
			</BodyContainer>
		</PlanContainer>
	);
};

export { PlanSection };
