import styled from 'styled-components';

export const ManagePlanContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.orion};
	border-radius: 12px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-bottom: 40px;
	padding: 24px 0;
`;

export const Wrapper = styled.div`
	border-right: 1px solid white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 16px;

	&:last-child {
		border: none;
		padding-right: 16px;
	}
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 12px;
	font-weight: 400;
`;

export const Detail = styled.p`
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 20px;
	font-weight: 400;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;
