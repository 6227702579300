import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ANALYTICS } from '../../analytics/constants';
import { PayloadProps, track } from '../../analytics/index';
import { PaymentLinksIcon } from '../../assets/svg';
import { ETPayButton } from '../../components/button';
import { DateFilter } from '../../components/table/components/date-filter';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useMerchant } from '../../hooks/useMerchant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { PaymentLinksServices } from '../../services/payment-links';
import { validatePermission } from '../../utils/common';
import { defaultFilters, defaultPagination } from '../../utils/defaults';
import { PaymentLinkHeader } from './components/header';
import { NewBatchPayment } from './components/new-batch-payment';
import { NewSinglePayment } from './components/new-single-payment';
import { PaymentLinkTable } from './components/table';
import {
	ButtonsContainer,
	HeadContainer,
	NewPaymentLinkButton,
	TableFilterHeader,
	TitlePage,
} from './styles';

type PaymentLink = 'single' | 'batch';

interface ModalPaymentLink {
	show: boolean;
	type?: PaymentLink;
}

const defaultModalPaymentLink: ModalPaymentLink = {
	show: false,
	type: undefined,
};

const PaymentLinks = () => {
	const { width } = useScreenSize();
	const { merchantSelected } = useMerchant();
	const [filters, setFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState(defaultPagination);
	const [total, setTotal] = useState(0);
	const [modal, setModal] = useState(defaultModalPaymentLink);

	const { data: graphs, isLoading: isLoadingGraph } = useQuery({
		enabled: !!merchantSelected && width > 576,
		useErrorBoundary: true,
		queryKey: [CONSTANTS.services.paymentLinks.getGraphData, merchantSelected?.code],
		queryFn: () => PaymentLinksServices.getGraphicsData(merchantSelected?.code as string),
	});

	const {
		data: table,
		isFetching,
		isFetched,
		isLoading: isLoadingTable
	} = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [
			CONSTANTS.services.paymentLinks.getTableInfo,
			merchantSelected?.code,
			pagination,
			filters,
		],
		queryFn: () =>
			PaymentLinksServices.getTableData({
				merchantCode: merchantSelected?.code as string,
				pagination,
				filters: {
					...filters,
					dateRange: {
						from: filters?.dateRange?.from,
						to: filters?.dateRange?.to,
					},
				},
			}),
		onSuccess: (data) => {
			if (data) setTotal(data.total);
		},
	});

	useEffect(() => {
		setFilters(defaultFilters);
		setPagination(defaultPagination);
	}, [merchantSelected]);

	const onClose = () => {
		setModal(defaultModalPaymentLink);
	};

	const handleShowModal = (type: PaymentLink) => {
		setModal({ show: true, type });
	};

	useEffect(() => {
		const trackInfo: PayloadProps = {
			type: 'screen',
			pageView: ANALYTICS.payment_links.page_view,
			source: 'GTM',
		};
		track(trackInfo);
	}, []);

	return (
		<ViewWrapper title="Payment Links" isLoading={isLoadingGraph || isLoadingTable || isFetching}>
			<>
				{modal.show && modal.type === 'single' && validatePermission('PL_NEW') && (
					<NewSinglePayment show={modal.show} onClose={onClose} />
				)}
				{modal.show && modal.type === 'batch' && validatePermission('PL_CREATE_BATCH') && (
					<NewBatchPayment show={modal.show} onClose={onClose} />
				)}

				<HeadContainer>
					<TitlePage>
						<PaymentLinksIcon /> Payment Links
					</TitlePage>

					<ButtonsContainer>
						{width > 576 && (
							<ETPayButton
								title="Enlaces de cobro masivos"
								onClick={() => handleShowModal('batch')}
							/>
						)}

						<NewPaymentLinkButton onClick={() => handleShowModal('single')}>
							Nuevo cobro
						</NewPaymentLinkButton>
					</ButtonsContainer>
				</HeadContainer>

				<TableFilterHeader>
					<DateFilter
						filters={filters}
						onFilterChange={setFilters}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</TableFilterHeader>

				{width > 576 && <PaymentLinkHeader data={graphs} loading={isLoadingGraph} />}

				<PaymentLinkTable
					data={table?.tableInfo || []}
					isLoading={isFetching && !isFetched}
					stateList={table?.typeOfStatus || []}
					filters={filters}
					onFilterChange={setFilters}
					pagination={pagination}
					setPagination={setPagination}
					totalResults={total}
				/>
			</>
		</ViewWrapper>
	);
};

export { PaymentLinks };
