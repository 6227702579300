import styled from 'styled-components';

import SemiCircle from '../../assets/images/grain-texture.png';
import { ETPayLogo } from '../../assets/svg';

export const WithoutContextWrapper = styled.div`
	background-color: #130f33;
	background-image: url(${SemiCircle});
	background-position-x: 20vw;
	background-repeat: no-repeat;
	background-size: cover;
	color: ${({ theme }) => theme.colors.principal.pure};
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	overflow-x: hidden;
	padding: 0 46px;
	width: 50%;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding-top: 30px;
		width: 100%;
	}
`;

export const WithoutContextHeader = styled.div`
	max-width: 560px;
`;

export const ButtonCallToAction = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.principal.alamak};
	border: none;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.principal.orion};
	cursor: pointer;
	display: flex;
	display: none;
	font-size: 16px;
	font-weight: 500;
	justify-content: center;
	line-height: 24px;
	margin-top: 16px;
	min-height: 52px;
	padding: 8px 14px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		display: block;
	}
`;

export const Title = styled.h1`
	color: ${({ theme }) => theme.colors.principal.pure};
	font-size: 42px;
	font-weight: 500;
	letter-spacing: -0.08%;
	line-height: 50px;
	margin-bottom: 32px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: 21px;
		line-height: 25px;
		margin-bottom: 16px;
	}
`;

export const Description = styled.p`
	font-size: 20px;
	line-height: 28px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: 14px;
		line-height: 21px;
	}
`;

export const WithoutContextFeatures = styled.div`
	font-size: 16px;
	letter-spacing: -0.08%;
	line-height: 24px;
	margin-top: 60px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-top: 40px;
	}
`;

export const FeaturesTitle = styled.p``;

export const FeaturesList = styled.ul`
	margin-top: 6px;
`;

export const FeaturesListItem = styled.li`
	align-items: center;
	display: flex;
	gap: 8px;

	div {
		height: 24px;
		width: 24px;
	}

	&:not(:last-child) {
		margin-bottom: 6px;
	}
`;

export const WithoutContextFooter = styled.div`
	font-size: 16px;
	letter-spacing: -0.08%;
	line-height: 24px;
	margin-top: 60px;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-top: 32px;
	}
`;

export const FooterTitle = styled.p`
	margin-bottom: 21px;
`;

export const FooterContainer = styled.div`
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(3, 1fr);

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

interface AuthLayoutContainerProps {
	readonly backgroundImage: string;
	readonly hasContext: boolean;
}

export const AuthLayoutContainer = styled.div<AuthLayoutContainerProps>`
	background-size: contain;
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		border-radius: 0;
		flex-direction: column;
		width: 100%;
	}

	${({ backgroundImage, hasContext }) =>
		hasContext &&
		`
	background-image: url(${backgroundImage});
	background-position: left;
	background-repeat: no-repeat;
	position: relative;

	`}
`;

export const AuthLayoutWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-radius: 24px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: calc(50% + 27px);

	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		border-radius: 0;
		min-height: 100vh;
		position: inherit;
		width: 100%;
	}
`;

export const AuthLayoutContent = styled.div`
	height: calc(100% - 80px);
	padding: 40px 30px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		height: auto;
		min-height: calc(100% - 40px);
		padding: 20px;
	}
`;

interface AuthLayoutHeaderProps {
	readonly hasContext: boolean;
}

export const AuthLayoutHeader = styled.div<AuthLayoutHeaderProps>`
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;

	${({ theme, hasContext }) =>
		!hasContext &&
		`
		background-color: ${theme.colors.principal.pure};
		
		@media (max-width: ${theme.breakpoints.sm}) {
			left: 0;
			padding: 25px 24px;
			position: fixed;
			top: 0;
		}
	`}
`;

export const AuthLayoutLogo = styled(ETPayLogo)`
	width: 120px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100px;
	}
`;

export const AuthLayoutButtonsHeader = styled.div`
	align-items: center;
	display: flex;
`;

export const AuthLayoutButtonsP = styled.p`
	font-size: 16px;
	margin-right: 20px;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
	}
`;

export const AuthLayoutForm = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	max-width: 500px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-top: 0;
	}
`;
