import styled, { keyframes } from 'styled-components';

interface DragAndDropProps {
	readonly isDragActive: boolean;
	readonly hasError: boolean;
	readonly serverError?: boolean;
	readonly loadFile?: boolean;
	readonly cleanState?: boolean;
	readonly disabled?: boolean;
}
export const DragAndDropWrapper = styled.form<DragAndDropProps>`
	align-items: center;
	background-color: ${({ theme, isDragActive, hasError, serverError, cleanState }) =>
		(hasError && '#FFE8E8') || ((isDragActive && !serverError && !cleanState) ? '#F3F6FF' : theme.colors.principal.sirius)};
	border: 2px ${({ hasError, isDragActive, serverError, loadFile, cleanState }) => ((serverError && 'dashed') || ((hasError || loadFile) && 'none') || (isDragActive && !cleanState ? 'solid' : 'dashed'))} ${({ theme, hasError, isDragActive, serverError, cleanState }) => (serverError && theme.colors.principal.ceres) || (hasError && 'red') || (isDragActive && !cleanState ? theme.colors.extra.earth : theme.colors.principal.ceres)};
	border-bottom: ${({ serverError }) => serverError && 'none'};
	border-radius: ${({ serverError }) => (serverError ? '12px 12px 0 0' : '8px')};
	cursor: ${({disabled}) => disabled ? " not-allowed" : "pointer"};;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: ${({ serverError, loadFile }) =>  serverError ? '15.5px 18px' : loadFile ? '44px 41px' :  '25.5px 40px' };
	pointer-events: ${({disabled}) => disabled && "none"};
	position: relative;
	width: 100%;

	&:hover {
		background-color: ${({ hasError, serverError }) => !hasError && !serverError && '#f3f6ff'};
		border-color: ${({ theme, serverError  }) => !serverError && theme.colors.extra.earth};
	}

`;

export const Input = styled.input`
	display: none;
`;

interface DragAndDropContainerProp {
	readonly serverError?: boolean;
}

export const DragAndDropContainer = styled.div<DragAndDropContainerProp>`
	align-items: center;
	display: flex;
	flex-direction: ${({ serverError }) => (serverError ? 'row' : 'column')};
	gap: ${({ serverError }) => (serverError ? '14px' : '0px')};

	svg {
		width: ${({ serverError }) => serverError && '84px'};
	}

	&:hover {
		flex-direction: ${({ serverError }) => serverError && 'row'};
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

interface TitleProp {
	readonly uploadSuccess?: boolean;
	readonly hasError?: boolean;
	readonly serverError?: boolean;
}

export const Title = styled.p<TitleProp>`
	color: ${({ theme, uploadSuccess, hasError }) =>
		(hasError && theme.colors.principal.orion) ||
		(uploadSuccess ? theme.colors.principal.orion : theme.colors.principal.vanth)};
	font-size: 12px;
	font-weight: 500;
	line-height: normal;
	margin-top: ${({serverError}) => !serverError && '10px' };
	text-align: ${({ serverError }) => (serverError ? 'start' : 'center')};

	span {
		color: ${({ theme }) => theme.colors.extra.earth};
		text-decoration: underline;
	}
`;

export const Subtitle = styled.p<TitleProp>`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	text-align: center;
`;

export const FileItem = styled.span`
	color: ${({ theme }) => theme.colors.principal.vanth};
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	width: 100%;
`;

export const ErrorFooter = styled.div`
	background-color: #ffe8e8;
	border-radius: 0 0 12px 12px;
	display: flex;
	flex-direction: column;
	padding: 8px 18px;
	width: 100%;
`;

export const ErrorTitle = styled.span`
	color: ${({ theme }) => theme.colors.principal.orion};
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
`;

export const ErrorDescription = styled.a`
	color: ${({ theme }) => theme.colors.principal.vanth};
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3px;
	text-decoration-line: underline;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	animation: ${spin} 1s linear infinite;
	animation-delay: -0.5s;
	border: 3px solid ${({ theme }) => theme.colors.principal.ceres};
	border-left-color: ${({ theme }) => theme.colors.principal.vanth};
	border-radius: 100%;
	height: 20px;
	width: 20px;
`;