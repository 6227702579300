import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTheme } from 'styled-components';

import { EmailIcon, LeftAngleIcon, SMSIcon, WhatsAppIcon } from '../../../../../assets/svg';
import { Input } from '../../../../../components/input';
import { useFloatingAlert } from '../../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../../hooks/useMerchant';
import { PaymentLinksMultiuseServices } from '../../../../../services/payment-links-multiuse';
import { getPrefixPhoneCountryCode } from '../../../../../utils/common';
import { onlyNumberRegex, validateEmail } from '../../../../../utils/validations';
import {
	BackButtonContainer,
	BackText,
	Button,
	HintInput,
	SendLinkButtonContainer,
	ShareView,
	TabBody,
} from '../styles';

type LinkStepsAllowed = 'principal' | 'email' | 'whatsapp' | 'sms';

interface SendLinkByValues {
	email: string;
	whatsapp: string;
	sms: string;
	loading: boolean;
}

const defaultSendLinkBy: SendLinkByValues = {
	email: '',
	whatsapp: '',
	sms: '',
	loading: false,
};

interface SendLinkProps {
	paymentLinkUrl: string;
	id: string;
}

const SendLinkTab = ({ id, paymentLinkUrl }: SendLinkProps) => {
	const { colors } = useTheme();
	const { handleShowAlert } = useFloatingAlert();
	const { merchantSelected } = useMerchant();
	const [sendLinkStep, setSendLinkStep] = useState<LinkStepsAllowed>('principal');
	const [sendLinkBy, setSendLinkBy] = useState<SendLinkByValues>(defaultSendLinkBy);
	const [isEmailValid, setIsEmailValid] = useState(false);

	const handleChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (name !== 'email' && /^\d{0,10}$/.test(value)) {
			setSendLinkBy({ ...sendLinkBy, [name]: value });
		} else if (name === 'email') {
			setIsEmailValid(validateEmail(value));
		}
		setSendLinkBy({ ...sendLinkBy, [name]: value });
	};

	const handleSendLinkByEmail = async () => {
		setSendLinkBy({ ...sendLinkBy, loading: true });

		try {
			await PaymentLinksMultiuseServices.sendPaymentMultiuseLinkBy({
				merchantCode: merchantSelected?.code as string,
				presessionToken: paymentLinkUrl.split('/').slice(-1)[0],
				wayToShare: 'email',
				email: sendLinkBy.email,
			});
			handleShowAlert({ message: 'Enlace de pago enviado', type: 'success' });
			handlePreviousStep();
		} catch (error) {
			handleShowAlert({
				message: 'No pudimos enviar el enlace de pago',
				type: 'error',
			});
		} finally {
			setSendLinkBy({ ...sendLinkBy, loading: false });
		}
	};

	const handleSendLinkByWsp = async () => {
		setSendLinkBy({ ...sendLinkBy, loading: true });

		try {
			const { msg } = await PaymentLinksMultiuseServices.sendPaymentMultiuseLinkBy({
				merchantCode: merchantSelected?.code as string,
				presessionToken: paymentLinkUrl.split('/').slice(-1)[0],
				wayToShare: 'wapp',
				phone: `${getPrefixPhoneCountryCode(merchantSelected?.country as string)}${
					sendLinkBy.whatsapp
				}`,
			});
			window.open(msg);
		} catch (error) {
			handleShowAlert({
				message: 'No pudimos enviar el enlace de pago',
				type: 'error',
			});
		} finally {
			setSendLinkBy({ ...sendLinkBy, loading: false });
		}
	};

	const handleSendLinkBySMS = async () => {
		setSendLinkBy({ ...sendLinkBy, loading: true });

		try {
			await PaymentLinksMultiuseServices.sendPaymentLinkMultiuseSMS({
				merchantCode: merchantSelected?.code as string,
				presessionToken: paymentLinkUrl.split('/').slice(-1)[0],
				phoneNumber: '52' + sendLinkBy.sms,
			});
			handleShowAlert({ message: 'Enlace de pago enviado', type: 'success' });
			handlePreviousStep();
		} catch (error) {
			handleShowAlert({
				message: 'No pudimos enviar el enlace de pago',
				type: 'error',
			});
		} finally {
			setSendLinkBy({ ...sendLinkBy, loading: false });
		}
	};

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (
			!onlyNumberRegex.test(e.key) &&
			e.key !== 'Backspace' &&
			e.key !== 'Delete' &&
			e.key !== 'ArrowLeft' &&
			e.key !== 'ArrowRight' &&
			e.key !== 'V' &&
			!e.ctrlKey
		) {
			e.preventDefault();
		}
	};

	const handlePreviousStep = () => {
		setSendLinkStep('principal');
		setSendLinkBy(defaultSendLinkBy);
	};

	const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
		const { type } = e;
		const { value } = e.target;

		if (type === 'blur') {
			setIsEmailValid(value.length > 0 ? validateEmail(value) : false);
		}
	};

	return (
		<>
			<TabBody id={id}>
				{sendLinkStep !== 'principal' && (
					<BackButtonContainer onClick={handlePreviousStep}>
						<LeftAngleIcon />
						<BackText>Regresar</BackText>
					</BackButtonContainer>
				)}

				{sendLinkStep === 'principal' && (
					<ShareView>
						<Button
							onClick={() => setSendLinkStep('email')}
							style={{ justifyContent: 'flex-start' }}
						>
							<EmailIcon color={colors.principal.pure} /> Correo electrónico
						</Button>

						<Button
							onClick={() => setSendLinkStep('whatsapp')}
							style={{ justifyContent: 'flex-start' }}
						>
							<WhatsAppIcon color={colors.principal.pure} /> WhatsApp
						</Button>

						{merchantSelected?.country === 'MX' && (
							<Button
								onClick={() => setSendLinkStep('sms')}
								style={{ justifyContent: 'flex-start' }}
							>
								<SMSIcon /> Mensaje de texto
							</Button>
						)}
					</ShareView>
				)}

				{sendLinkStep === 'email' && (
					<>
						<Input
							label="Correo electrónico"
							name="email"
							value={sendLinkBy.email}
							onChange={handleChangeInput}
							variant="large"
							onBlur={handleFocus}
						/>
						{!isEmailValid && sendLinkBy.email.length > 3 && (
							<HintInput error={!isEmailValid}>Debe ser un correo electrónico válido</HintInput>
						)}
						<SendLinkButtonContainer>
							<Button
								onClick={handleSendLinkByEmail}
								disabled={sendLinkBy.loading || !isEmailValid}
							>
								{sendLinkBy.loading ? 'Enviando...' : 'Enviar'}
							</Button>
						</SendLinkButtonContainer>
					</>
				)}

				{sendLinkStep === 'whatsapp' && (
					<>
						<Input
							label="Número celular"
							name="whatsapp"
							value={sendLinkBy.whatsapp}
							onChange={handleChangeInput}
							onKeyDown={handleKeyDownValidateAmount}
							prefixSymbol={getPrefixPhoneCountryCode(merchantSelected?.country)}
							variant="large"
						/>
						<SendLinkButtonContainer>
							<Button
								onClick={handleSendLinkByWsp}
								disabled={sendLinkBy.loading || sendLinkBy.whatsapp.length !== 10}
							>
								{sendLinkBy.loading ? 'Enviando...' : 'Enviar'}
							</Button>
						</SendLinkButtonContainer>
					</>
				)}

				{sendLinkStep === 'sms' && (
					<>
						<Input
							label="Número celular"
							name="sms"
							value={sendLinkBy.sms}
							onChange={handleChangeInput}
							onKeyDown={handleKeyDownValidateAmount}
							prefixSymbol={getPrefixPhoneCountryCode(merchantSelected?.country)}
							variant="large"
						/>
						<SendLinkButtonContainer>
							<Button
								onClick={handleSendLinkBySMS}
								disabled={sendLinkBy.loading || sendLinkBy.sms.length !== 10}
							>
								{sendLinkBy.loading ? 'Enviando...' : 'Enviar'}
							</Button>
						</SendLinkButtonContainer>
					</>
				)}
			</TabBody>
		</>
	);
};

export { SendLinkTab };
