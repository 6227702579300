import styled from 'styled-components';

import { ArrowIcon as Arrow } from '../../../../assets/svg';

export const ArrowIcon = styled(Arrow)`
	height: 12px;
	margin-left: 5px;
	width: 12px;
`;

interface IMerchantListContainerProps {
	readonly isOpen: boolean;
}

export const MerchantListContainer = styled.div<IMerchantListContainerProps>`
	height: 100%;
	position: relative;
`;

interface TitleContainerProps {
	readonly isOpen: boolean;
}

export const TitleContainer = styled.div<TitleContainerProps>`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 60px;

	${ArrowIcon} {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
	}
`;

export const MerchantTitleContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;

export const LogoContainer = styled.div`
	display: flex;
	max-height: 50px;
	max-width: 140px;
`;

export const Logo = styled.img`
	max-width: 100%;
	object-fit: contain;
`;

export const MerchantName = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.principal.abell};
	display: flex;
	font-family: ${({ theme }) => theme.fonts.medium};
	font-size: 20px;
	line-height: 1.1;

	&::first-letter {
		text-transform: uppercase;
	}
`;

interface IMerchantListContentProps {
	readonly isOpen: boolean;
}

export const MerchantListContent = styled.nav<IMerchantListContentProps>`
	background-color: ${({ theme }) => theme.colors.principal.air};
	border: 1px solid ${({ theme }) => theme.colors.principal.ceres};
	bottom: 0;
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	left: -24px;
	max-height: calc(45px * 8);
	min-width: 224px;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	transform: translateY(100%);
	z-index: 5;
`;

export const EnterpriseName = styled.p`
	font-size: 11px;
	padding: 5px 0 5px 5px;
`;

interface MerchantItemProps {
	readonly isSelected: boolean;
}

export const MerchantItem = styled.li<MerchantItemProps>`
	background-color: ${({ isSelected, theme }) =>
		isSelected ? theme.colors.principal.ceres : 'initial'};
	cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
	font-size: 14px;
	padding: 5px 15px;
	pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'initial')};

	&::first-letter {
		text-transform: uppercase;
	}

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.principal.ceres};
	}
`;

interface ShowAllProps {
	readonly isSelected: boolean;
}

export const ShowAll = styled.p<ShowAllProps>`
	background-color: ${({ isSelected, theme }) =>
		isSelected ? theme.colors.principal.ceres : 'initial'};
	cursor: pointer;
	font-size: 14px;
	padding: 5px 15px;
	pointer-events: initial;

	&::first-letter {
		text-transform: uppercase;
	}

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.principal.ceres};
	}
`;

export const FilterContainer = styled.div`
	margin: 0 auto;
	padding: 24px 24px 16px;
`;
