import { ResponseGraphics as Graphics } from '../../../../models/common-payments';
import { AmountsBarGraph } from '../amount-graphic';
import { BanksPieChart } from '../banks-pie-chart';
import {
	HeaderContainer,
	SectionContainer,
	SectionContent,
	SectionDate,
	SectionHeader,
	SectionTitle,
} from './styles';

interface QuickTransferHeaderProps {
	data?: Graphics;
	loading?: boolean;
}

const NonBankCardHeader = ({ data, loading }: QuickTransferHeaderProps) => {
	const COLORS = ['#482524', '#825B5A', '#917c7c', '#b6a8a7', '#dad3d3'];
	return (
		<HeaderContainer>
			<SectionContainer>
				<SectionHeader>
					<SectionTitle>Monto recaudado</SectionTitle>
					<SectionDate>Últimas 24 horas</SectionDate>
				</SectionHeader>

				<SectionContent>
					<AmountsBarGraph data={data?.amounts} barColor="#482524" tooltipColor="#e8e7eb" />
				</SectionContent>
			</SectionContainer>

			<SectionContainer>
				<SectionHeader>
					<SectionTitle>Cobros exitosos por banco</SectionTitle>
					<SectionDate>Últimas 24 horas</SectionDate>
				</SectionHeader>

				<SectionContent>
					<BanksPieChart data={data} colors={COLORS} tooltipColor="#e8e7eb" loading={loading} />
				</SectionContent>
			</SectionContainer>
		</HeaderContainer>
	);
};

export { NonBankCardHeader };
