import { MerchantBaseInterface } from '../../models/merchant';
import { MerchantActions } from './actions';

export interface ReducerActionsInterface {
	type: MerchantActions;
	payload: MerchantBaseInterface;
}

const initialState: MerchantBaseInterface = {};

export const merchantReducer = (
	state = initialState,
	action: ReducerActionsInterface,
): MerchantBaseInterface => {
	switch (action.type) {
		case MerchantActions.GET_MERCHANT || MerchantActions.UPDATE_ROLE:
			return { ...state, merchants: action.payload.merchants };
		case MerchantActions.GET_ENTERPRISE:
			return { ...state, enterprises: action.payload.enterprises };
		case MerchantActions.SELECT_MERCHANT || MerchantActions.UPDATE_ROLE_CURRENT_MERCHANT:
			return {
				...state,
				merchantSelected: action.payload.merchantSelected,
				isEnterpriseSelected: action.payload.isEnterpriseSelected,
			};
		case MerchantActions.SELECT_VIEW_ALL:
			return { ...state, isEnterpriseSelected: action.payload.isEnterpriseSelected };
		case MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE:
			return { ...state, urlToDownloadExample: action.payload.urlToDownloadExample };
		case MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS:
			return { ...state, urlToDownloadExamplePayouts: action.payload.urlToDownloadExamplePayouts };
		case MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS_CHILE:
			return {
				...state,
				urlToDownloadExamplePayoutsChile: action.payload.urlToDownloadExamplePayoutsChile,
			};
		case MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_PAYOUTS_SBIF_CHILE:
			return {
				...state,
				urlToDownloadExamplePayoutsSbifChile: action.payload.urlToDownloadExamplePayoutsSbifChile,
			};
		case MerchantActions.SET_URL_TO_DOWNLOAD_EXAMPLE_DIRECT_DEBIT_MX:
			return {
				...state,
				directDebitBatchMx: action.payload.directDebitBatchMx,
			};
		case MerchantActions.RESET_STATE:
			return { ...initialState };
		default:
			return state;
	}
};
