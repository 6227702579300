import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ScrollRestoration } from 'react-router-dom';

import { ETPayIcon } from '../../assets/svg';
import { FloatingAlert } from '../../components/floating-alert';
import { useAuth } from '../../hooks/useAuth';
import { useFloatingAlert } from '../../hooks/useFloatingAlert';
import { useMerchant } from '../../hooks/useMerchant';
import { ProcessType } from '../../models/sockets';
import { WebSockets } from '../../services/sockets';
import { Burger } from './components/Burger';
import { Menu } from './components/Menu';
import { MerchantList } from './components/MerchantList';
import { Sidebar } from './components/Sidebar';
import { BodyContainer, Container, Header, MainSection, SidebarContainer } from './styles';
import { LogoContainer } from './styles';

const DashboardLayout = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const { user } = useAuth();
	const { handleUpdateRole, isEnterpriseSelected } = useMerchant();
	const navigate = useNavigate();
	const { handleShowAlert } = useFloatingAlert();

	useEffect(() => {
		if (!WebSockets.connected()) WebSockets.init();
	}, [WebSockets]);

	useEffect(() => {
		if (!user) navigate('/login');
	}, [user]);

	useEffect(() => {
		if (isEnterpriseSelected) {
			navigate('/enterprise');
		}
	}, [isEnterpriseSelected]);

	const handleToggle = () => {
		setMenuOpen(!menuOpen);
	};

	const handleCloseMenu = () => {
		setMenuOpen(false);
	};

	WebSockets.subscribe(({ action, data }) => {
		if (action === ProcessType.UPDATE_ROLE) {
			const { role_id, merchant_code } = data;
			handleUpdateRole(role_id, merchant_code);
			handleShowAlert({
				type: 'success',
				message: 'Tu rol ha sido cambiado.',
			});
			navigate(isEnterpriseSelected ? '/enterprise' : '/');
		}
	});

	return (
		<>
			<FloatingAlert />
			<Container>
				<Header>
					<MerchantList isOpen={menuOpen} />

					<LogoContainer>
						<ETPayIcon />
					</LogoContainer>

					<Menu />

					<Burger
						menuOpen={menuOpen}
						handleToggle={handleToggle}
						handleCloseMenu={handleCloseMenu}
					/>
				</Header>

				<MainSection>
					<SidebarContainer $isOpen={menuOpen}>
						<Sidebar closeMenu={handleCloseMenu} />
					</SidebarContainer>

					<BodyContainer id="body-container">
						<ScrollRestoration />
						<Outlet />
					</BodyContainer>
				</MainSection>
			</Container>
		</>
	);
};

export { DashboardLayout };
