import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { track } from '../../analytics';
import { ANALYTICS } from '../../analytics/constants';
import { ETPayButton } from '../../components/button';
import { Input } from '../../components/input';
import { Section } from '../../components/section';
import { ViewWrapper } from '../../components/view-wrapper';
import { CONSTANTS } from '../../config/constants';
import { useAuth } from '../../hooks/useAuth';
import { useFloatingAlert } from '../../hooks/useFloatingAlert';
import { useMerchant } from '../../hooks/useMerchant';
import { Profile as ProfileInterface } from '../../models/profile';
import { ProfileServices } from '../../services/profile';
import { getRoleLabelById } from '../../utils/common';
import { errorFormatter } from '../../utils/errors';
import {
	BodySection,
	ButtonContainer,
	HeadContainer,
	InputContainer,
	InputContainerGroup,
	RestorePassword,
	TitlePage,
} from './styles';

const Profile = () => {
	const { merchantSelected } = useMerchant();
	const [state, setState] = useState<ProfileInterface>();
	const { handleShowAlert } = useFloatingAlert();
	const navigate = useNavigate();
	const [showButton, setShowButton] = useState(false);
	const { user, handleLogout } = useAuth();

	const { data } = useQuery({
		enabled: !!merchantSelected,
		useErrorBoundary: true,
		keepPreviousData: true,
		queryKey: [CONSTANTS.services.profile.getInformation, merchantSelected?.code],
		queryFn: () => ProfileServices.getProfileInformation(merchantSelected?.code as string),
		onSuccess: (data) => {
			setState(data);
		},
	});

	const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (state) setState({ ...state, [name]: value });
	};

	const handleSaveContact = async () => {
		try {
			await ProfileServices.updateInfo({
				merchantCode: merchantSelected?.code as string,
				profile: state as ProfileInterface,
			});
			handleShowAlert({
				message: 'Perfil actualizado correctamente',
				type: 'success',
			});
		} catch (e: any) {
			handleShowAlert({
				message: errorFormatter(e.code),
				type: 'error',
			});
		}
	};

	const handleResetPassword = async () => {
		await handleLogout();
		navigate('/reset-password');
	};

	useEffect(() => {
		track({
			type: 'screen',
			pageView: ANALYTICS.profile.page_view,
			source: 'GTM',
		});
	}, []);

	useEffect(() => {
		if (JSON.stringify(state) !== JSON.stringify(data)) setShowButton(true);
		else setShowButton(false);
	}, [state]);

	return (
		<ViewWrapper title="Mi perfil">
			<HeadContainer>
				<TitlePage>Mi Perfil</TitlePage>
			</HeadContainer>

			<BodySection>
				<Section title="Información personal">
					<InputContainer>
						<InputContainerGroup>
							<Input
								label="Nombre(s)"
								name="names"
								variant="large"
								onChange={handleChangeValue}
								value={state?.names}
							/>

							<Input
								label="Apellidos"
								name="lastnames"
								variant="large"
								onChange={handleChangeValue}
								value={state?.lastnames}
							/>
						</InputContainerGroup>
					</InputContainer>

					<InputContainer>
						<Input label="Correo electrónico" variant="large" value={user?.email} readOnly />
					</InputContainer>

					<InputContainer>
						<Input
							label="Rol"
							variant="large"
							value={getRoleLabelById(
								merchantSelected?.role_id as number,
								merchantSelected?.role_id as number,
							)}
							readOnly
						/>
					</InputContainer>

					<InputContainer>
						<Input
							type="password"
							label="Contraseña"
							variant="large"
							value="**************"
							readOnly
						/>
						<RestorePassword onClick={handleResetPassword}>Restablecer contraseña</RestorePassword>
					</InputContainer>

					<ButtonContainer>
						<ETPayButton
							title="Guardar"
							color="secondary"
							disabled={!showButton}
							onClick={handleSaveContact}
						/>
					</ButtonContainer>
				</Section>
			</BodySection>
		</ViewWrapper>
	);
};

export { Profile };
