import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0%{
    left: 0;
    right: 90%;
    width: 10%;
  }

  10% {
    left: 0;
    right: 75%;
    width: 25%;
  }

  90% {
    left: 75%;
    right: 0;
    width: 25%;
  }

  100% {
    left: 100%;
    right: 0;
    width: 0;
  }
`;

export const ContainerProgress = styled.div`
	background-color: ${({ theme }) => theme.colors.principal.pure};
	border-radius: 12px;
	height: 7px;
	margin-top: 18px;
	position: relative;
	width: 100%;
`;

export const Progress = styled.div`
	animation: ${loading} 2s cubic-bezier(0.45, 0, 1, 1) infinite;
	background-color: #72dbaa;
	border-radius: 12px;
	height: 100%;
	position: relative;
`;
