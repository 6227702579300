import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { ETPayButton } from '../../../../components/button';
import { Input } from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { Spinner } from '../../../../components/spinner';
import { Tabs } from '../../../../components/tabs';
import { useFloatingAlert } from '../../../../hooks/useFloatingAlert';
import { useMerchant } from '../../../../hooks/useMerchant';
import { PaymentLinksServices } from '../../../../services/payment-links';
import { validatePermission } from '../../../../utils/common';
import { errorFormatter } from '../../../../utils/errors';
import { onlyNumberRegex } from '../../../../utils/validations';
import { QRTab } from './components/qr-tab';
import { SendLinkTab } from './components/send-link-tab';
import {
	Button,
	ContainerInput,
	CopyButton,
	ModalContainer,
	ModalFooter,
	Steps,
	Subtitle,
	TabsContainer,
	Title,
} from './styles';

interface NewSinglePaymentProps {
	show: boolean;
	onClose: () => void;
}

interface Values {
	amount: string;
	reference: string;
	buttonDisabled: boolean;
	loading: boolean;
}

const defaultValues: Values = {
	amount: '',
	reference: '',
	buttonDisabled: true,
	loading: false,
};

type CopyText = 'Copiar' | 'Copiado';

interface Response {
	pl_qr: string;
	pl_url: string;
}

const defaultResponse: Response = { pl_qr: '', pl_url: '' };

const NewSinglePayment = ({ show, onClose }: NewSinglePaymentProps) => {
	const { merchantSelected } = useMerchant();
	const { handleShowAlert } = useFloatingAlert();
	const [steps, setSteps] = useState<number>(1);
	const [state, setState] = useState<Values>(defaultValues);
	const [copyText, setCopyText] = useState<CopyText>('Copiar');
	const [response, setResponse] = useState<Response>(defaultResponse);

	const handleCloseModal = () => {
		setState(defaultValues);
		setSteps(1);
		onClose();
	};

	const handleNext = () => {
		if (steps === 3) return;
		setSteps(steps + 1);
	};

	const handleBack = () => {
		if (steps === 1) return;
		setSteps(steps - 1);
	};

	const handleContinueAction = async () => {
		if (steps === 1) handleNext();
		if (steps === 2) {
			try {
				setState({ ...state, loading: true });
				const { pl_qr, pl_url } = await PaymentLinksServices.createNewPaymentLink({
					merchantCode: merchantSelected?.code as string,
					amount: state.amount.replaceAll(',', ''),
					reference: state.reference,
				});
				setResponse({ pl_qr, pl_url });
				handleNext();
			} catch (e: any) {
				handleShowAlert({
					message: errorFormatter(e.code),
					type: 'error',
				});
			} finally {
				setState({ ...state, loading: false });
			}
		}
	};

	const handleVerifyDisabledButton = () => {
		if (steps === 1 && state.amount !== '') return false;
		if (steps === 1 && state.amount === '') return true;
		if (steps === 2 && state.reference !== '') return false;
		if (steps === 2 && state.reference === '') return true;
		return false;
	};

	useEffect(() => {
		const buttonDisabled = handleVerifyDisabledButton();
		setState({ ...state, buttonDisabled });
	}, [state.amount, state.reference, steps]);

	const handleKeyDownValidateAmount = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!onlyNumberRegex.test(e.key) && e.key !== 'Backspace') {
			e.preventDefault();
		}
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	return (
		<Modal title="Nuevo cobro" show={show} onClose={handleCloseModal}>
			{state.loading ? (
				<Spinner />
			) : (
				<ModalContainer>
					<Steps>Paso {steps} de 3</Steps>

					<Title>
						{steps === 1 && 'Ingresa el monto'}
						{steps === 2 && 'Ingresa la referencia'}
						{steps === 3 && 'Comparte con tu cliente'}
					</Title>

					<Subtitle>
						{steps === 1 && 'El link de cobro se generará por esta cantidad.'}
						{steps === 2 &&
							'La referencia te permite identificar a qué o a quién corresponde un cobro.'}
						{steps === 3 && 'Muestra el código QR o comparte el enlace de cobro con tu cliente.'}
					</Subtitle>

					{steps === 1 && (
						<Input
							label="Monto"
							name="amount"
							variant="large"
							value={state.amount}
							prefixSymbol="$"
							onChange={handleChangeInput}
							onKeyDown={handleKeyDownValidateAmount}
						/>
					)}

					{steps === 2 && (
						<Input
							label="Referencia"
							name="reference"
							value={state.reference}
							onChange={handleChangeInput}
							variant="large"
						/>
					)}

					{steps === 3 && (
						<>
							<ContainerInput>
								<Input
									label="Enlace de pago"
									name="paymentLink"
									defaultValue={response.pl_url}
									variant="large"
									readOnly
									rightIcon={<CopyButton>{copyText}</CopyButton>}
									rightIconClick={async () => {
										await navigator.clipboard.writeText(response.pl_url);
										setCopyText('Copiado');
										setTimeout(() => setCopyText('Copiar'), 2500);
									}}
								/>
							</ContainerInput>

							<TabsContainer>
								<Tabs
									tabs={[
										{
											reference: 'send-link',
											title: 'Enviar enlace',
											show: validatePermission('PL_SEND_SMS'),
										},
										{
											reference: 'qr',
											title: 'Código QR',
											show: validatePermission('PL_SHARE_LINK'),
										},
									]}
									defaultReferenceSelected="qr"
								>
									<SendLinkTab id="send-link" paymentLinkUrl={response.pl_url} />
									<QRTab
										id="qr"
										amount={state.amount}
										merchantName={merchantSelected?.name as string}
										paymentLinkUrl={response.pl_qr}
									/>
								</Tabs>
							</TabsContainer>
						</>
					)}

					{steps !== 3 && (
						<ModalFooter>
							{steps !== 1 && <ETPayButton title="Regresar" onClick={handleBack} />}
							<Button onClick={handleContinueAction} disabled={state.buttonDisabled}>
								{steps === 1 ? 'Siguiente' : 'Generar Cobro'}
							</Button>
						</ModalFooter>
					)}
				</ModalContainer>
			)}
		</Modal>
	);
};

export { NewSinglePayment };
